import styled from "styled-components";

export { ColumnDisplay, RowDisplay } from "theme/global-style";

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 100%;
  max-width: 520px;
  margin-top: 15px;
`;

export const ProgressBarName = styled.span`
  font-size: 21px;
  line-height: 26px;
  font-weight: 400;

  color: ${({ theme }) => theme.gunmetal()};
`;

export const ProgressBarPercent = styled.span`
  font-size: 18px;
  font-weight: 500;

  color: ${({ theme }) => theme.gunmetal()};
`;
