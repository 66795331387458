import { ICharitiesSearchSelectors } from "./charities-search.type";

const charitiesSearchSelectors: ICharitiesSearchSelectors = {
  selectInProgress: (state) => state.charitiesSearch.inProgress,
  selectIsFetched: (state) => state.charitiesSearch.isFetched,
  selectError: (state) => state.charitiesSearch.error,
  selectPage: (state) => state.charitiesSearch.page,
  selectDataByCategory: (state, category) => state.charitiesSearch?.data?.[category] ?? [],
  selectData: (state) => state.charitiesSearch?.data,
  selectMatches: (state) => state.charitiesSearch?.matches,
  selectIsSearched: (state) => state.charitiesSearch?.isSearched,
  selectIsEndReached: (state) => state.charitiesSearch?.isEndReached,
  selectQ: (state) => state.charitiesSearch?.q,
  selectLookingFor: (state) => state.charitiesSearch?.lookingFor,
  selectState: (state) => state.charitiesSearch?.state,
  selectUNGoal: (state) => state.charitiesSearch?.goal,
};

export default charitiesSearchSelectors;
