import {
  ArrowLeftIcon,
  CalendarIcon,
  ChecklistIcon,
  FileIcon,
  GlobeIcon,
  LocationIcon,
  PeopleIcon,
  SearchIcon,
} from "@primer/octicons-react";
import PrimaryButton from "components/buttons/primary";
import ExternalLink from "components/external-link";
import FixedHeader from "components/fixed-header";
import Tag from "components/tag";
import { CharityIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import authSelectors from "store/auth/auth.selector";
import companySelectors from "store/company/company.selector";
import {
  hideFullScreenLoader,
  showFullScreenLoader,
  updateDialogState,
} from "store/ui-components/ui-components.actions";
import userSelectors from "store/user/user.selector";
import {
  fetchVolunteerEvent,
  fetchVolunteerEventAttending,
  requestVolunteerEventIntro,
} from "store/volunteer-event-details/volunteer-event-details.action";
import volunteerEventDetailsSelectors from "store/volunteer-event-details/volunteer-event-details.selector";
import colors from "theme/colors";
import { DONATION_METHODS, GRANT_REG } from "utils/constants";
import {
  camelCaseText,
  formatDate,
  formatTime,
  getLocationString,
  uriEncode,
  verifyImg,
} from "utils/helper";
import routes from "utils/routes";
import { v4 as uuidv4 } from "uuid";

import * as S from "./volunteer-details.styled";

const VolunteerEventDetailsPage: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const isGlobal = location.state?.isGlobal || false;

  const volunteerEvent = useSelector(volunteerEventDetailsSelectors.selectData);
  const volunteerEventError = useSelector(volunteerEventDetailsSelectors.selectError);
  const inProgress = useSelector(volunteerEventDetailsSelectors.selectInProgress);

  const companyName = useSelector(companySelectors.selectName);

  const role = useSelector(authSelectors.selectRole);
  const user = useSelector(userSelectors.selectData);

  const { id } = params;
  const partnerDonationId = uuidv4();

  const [imgValid, setImgValid] = React.useState<boolean>(true);
  const ein = volunteerEvent?.charity?.ein.replace("-", "");

  const successUrl = `${uriEncode(window.__RUNTIME_CONFIG__.REACT_APP_URL)}${
    routes.EVERY_DOT_ORG.DONATION_SUCCESS
  }/${companyName}/${partnerDonationId}`.replaceAll("//", "/");

  const exitUrl = `${uriEncode(window.__RUNTIME_CONFIG__.REACT_APP_URL)}${
    routes.HOME
  }/${ein}`.replaceAll("//", "/");

  const webhookToken = window.__RUNTIME_CONFIG__.EVERY_DOT_ORG_WEBHOOK_TOKEN;

  const donationUrl = `${window.__RUNTIME_CONFIG__.EVERY_DOT_ORG_DONATE_LINK}${ein}?first_name=${user?.firstName}&last_name=${user?.lastName}&email=${user?.email}&webhook_token=${webhookToken}&partner_donation_id=${partnerDonationId}&method=${DONATION_METHODS}%2Cbank&success_url=${successUrl}&exit_url=${exitUrl}&frequency=ONCE&theme_color=6185E2#donate`;

  React.useEffect(() => {
    verifyImg(volunteerEvent?.charity?.logoUrl, setImgValid);
  }, [volunteerEvent?.charity?.logoUrl]);

  React.useEffect(() => {
    if (id && id !== volunteerEvent?.id) {
      dispatch(fetchVolunteerEvent.request({ id, isGlobal }));
      if (role !== "charity") {
        dispatch(fetchVolunteerEventAttending.request({ id }));
      }
    }
  }, [id]);

  React.useEffect(() => {
    if (inProgress) {
      dispatch(showFullScreenLoader());
    } else {
      dispatch(hideFullScreenLoader());
    }
  }, [inProgress]);

  const handleDonateClick = (): void => {
    window.location.href = donationUrl;
  };

  const onRequestIntro = (): void => {
    dispatch(requestVolunteerEventIntro.request({ id: volunteerEvent?.id }));
  };

  return (
    <S.VolunteerEventViewContainer gridGap={38} justifyContent="flex-start">
      <FixedHeader name={companyName} />
      <S.VolunteerEventViewInnerContainer
        alignItems="flex-start"
        justifyContent="flex-start"
        gridGap={42}
      >
        {role !== "charity" && (
          <S.RowDisplayFullWidth justifyContent="space-between">
            <S.ClickableWrapper onClick={() => navigate(-1)}>
              <S.RowDisplay gridGap={18}>
                <ArrowLeftIcon size={25} />
                <S.DetailsText>Back</S.DetailsText>
              </S.RowDisplay>
            </S.ClickableWrapper>
          </S.RowDisplayFullWidth>
        )}

        {!volunteerEventError ? (
          <>
            <S.ColumnDisplay alignItems="flex-start" justifyContent="flex-start" gridGap={4}>
              <S.GradientHeadingExtended>
                {camelCaseText(volunteerEvent?.name)}
              </S.GradientHeadingExtended>
            </S.ColumnDisplay>

            <S.ContentWrapper gridGap={40}>
              <S.FormsContainer gridGap={20}>
                <S.LeftContentContainer gridGap={40}>
                  <S.MissionStatementContainer alignItems="flex-start" justifyContent="flex-start">
                    <S.ColumnDisplay
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      gridGap={18}
                    >
                      <S.MissionStatementHeading>event description</S.MissionStatementHeading>
                      <S.MissionStatementParagraph>
                        {volunteerEvent?.description}
                      </S.MissionStatementParagraph>

                      <S.Separator height={32} />

                      {volunteerEvent?.location && (
                        <>
                          <S.MissionStatementHeading>event location</S.MissionStatementHeading>
                          <S.LocationContainer>
                            <LocationIcon fill={colors.blackCoral()} />
                            {getLocationString(volunteerEvent)}
                          </S.LocationContainer>
                        </>
                      )}

                      <S.MissionStatementParagraph>
                        {volunteerEvent?.note}
                      </S.MissionStatementParagraph>
                    </S.ColumnDisplay>
                  </S.MissionStatementContainer>
                </S.LeftContentContainer>
                <S.VolunteerEventInfoContainer alignItems="flex-start">
                  <S.ColumnDisplay gridGap={20} alignItems="flex-start" style={{ width: "100%" }}>
                    <S.VolunteerEventDetailsText>Event Details</S.VolunteerEventDetailsText>

                    <S.RowDisplay gridGap={16}>
                      <CalendarIcon size={16} />
                      <S.VolunteerEventInfoText>
                        <S.VolunteerEventInfoTextBold>
                          {role !== "charity" && volunteerEvent?.status === "ACTIVE"
                            ? "Date: "
                            : "Time: "}
                        </S.VolunteerEventInfoTextBold>
                        {role !== "charity" && volunteerEvent?.status === "ACTIVE"
                          ? formatDate(volunteerEvent.startDateTime, "MM/DD/YY")
                          : volunteerEvent?.eventTime}
                      </S.VolunteerEventInfoText>
                    </S.RowDisplay>

                    {role !== "charity" && volunteerEvent?.status === "ACTIVE" && (
                      <S.RowDisplay gridGap={16}>
                        <CalendarIcon size={16} />
                        <S.VolunteerEventInfoText>
                          <S.VolunteerEventInfoTextBold>Time: </S.VolunteerEventInfoTextBold>
                          {`${formatTime(volunteerEvent?.startDateTime)} - ${formatTime(
                            volunteerEvent?.endDateTime,
                          )}`}
                        </S.VolunteerEventInfoText>
                      </S.RowDisplay>
                    )}

                    <S.RowDisplay gridGap={16}>
                      <PeopleIcon size={16} />
                      <S.VolunteerEventInfoText>
                        <S.VolunteerEventInfoTextBold>
                          Volunteers Needed:{" "}
                        </S.VolunteerEventInfoTextBold>
                        {role !== "charity" && volunteerEvent?.status === "ACTIVE"
                          ? volunteerEvent?.availableSpots
                          : volunteerEvent?.volunteersNeeded}
                      </S.VolunteerEventInfoText>
                    </S.RowDisplay>

                    {!(role !== "charity" && volunteerEvent?.status === "ACTIVE") && (
                      <S.RowDisplay gridGap={16}>
                        <FileIcon size={16} />
                        <S.VolunteerEventInfoText>
                          <S.VolunteerEventInfoTextBold>
                            Grant Required:{" "}
                          </S.VolunteerEventInfoTextBold>
                          {volunteerEvent?.grant}
                        </S.VolunteerEventInfoText>
                      </S.RowDisplay>
                    )}

                    {!(role !== "charity" && volunteerEvent?.status === "ACTIVE") && (
                      <S.RowDisplay gridGap={16}>
                        <FileIcon size={16} />
                        <S.VolunteerEventInfoText>
                          <S.VolunteerEventInfoTextBold>
                            Grant Amount:{" "}
                          </S.VolunteerEventInfoTextBold>
                          {volunteerEvent?.grantAmount
                            ? GRANT_REG.test(volunteerEvent?.grantAmount.toLocaleString())
                              ? `$${volunteerEvent?.grantAmount?.toLocaleString()}`
                              : `${volunteerEvent?.grantAmount?.toLocaleString()}`
                            : "Not Required"}
                        </S.VolunteerEventInfoText>
                      </S.RowDisplay>
                    )}

                    {/* <S.RowDisplay gridGap={16}>
                      <ChecklistIcon size={16} />
                      <S.VolunteerEventInfoText>
                        <S.VolunteerEventInfoTextBold>
                          Waiver Required:{" "}
                        </S.VolunteerEventInfoTextBold>
                        {volunteerEvent?.waiver ? "Yes" : "No"}
                      </S.VolunteerEventInfoText>
                    </S.RowDisplay> */}

                    {role !== "charity" &&
                      volunteerEvent?.status !== "AVAILABLE" &&
                      volunteerEvent?.status !== "PENDING" && (
                        <PrimaryButton
                          label={!volunteerEvent?.attends ? "Sign Up" : "Cancel sign up"}
                          onClick={() =>
                            dispatch(
                              updateDialogState({
                                key: "VOLUNTEER_EVENT_SIGNUP",
                                isOpened: true,
                                properties: { id },
                              }),
                            )
                          }
                          sx={{
                            width: "180px",
                            maxWidth: "100%",
                            height: "40px",
                            alignSelf: "center",
                          }}
                          isLoading={inProgress}
                        />
                      )}

                    {volunteerEvent?.atCapacity && (
                      <S.EventFullBanner>Event Full</S.EventFullBanner>
                    )}

                    {role === "admin" && volunteerEvent?.status === "AVAILABLE" && (
                      <PrimaryButton
                        label="Request Intro"
                        onClick={onRequestIntro}
                        sx={{
                          width: "180px",
                          maxWidth: "100%",
                          height: "40px",
                          alignSelf: "center",
                        }}
                        isLoading={inProgress}
                      />
                    )}
                  </S.ColumnDisplay>
                </S.VolunteerEventInfoContainer>
              </S.FormsContainer>

              <S.BottomContentContainer
                alignItems="flex-start"
                justifyContent="flex-start"
                gridGap={20}
              >
                <S.ColumnDisplay alignItems="flex-start" justifyContent="flex-start" gridGap={4}>
                  <S.GradientHeadingExtended>
                    {`About ${camelCaseText(volunteerEvent?.charity?.name)}`}
                  </S.GradientHeadingExtended>
                </S.ColumnDisplay>
                <S.BottomFormContainer
                  justifyContent="space-between"
                  alignItems="flex-start"
                  flexWrap
                  gridGap={20}
                >
                  <S.BottomFormItem alignItems="flex-start" gridGap={8}>
                    {volunteerEvent?.charity?.logoUrl && imgValid ? (
                      <S.VolunteerEventImg src={volunteerEvent?.charity?.logoUrl} />
                    ) : (
                      <CharityIcon />
                    )}
                    {volunteerEvent?.charity?.irsCategory && (
                      <Tag text={volunteerEvent?.charity?.irsCategory} />
                    )}
                    {volunteerEvent?.charity?.irsSubcategory && (
                      <Tag text={volunteerEvent?.charity?.irsSubcategory} />
                    )}
                    {role !== "charity" && (
                      <PrimaryButton
                        label="Donate"
                        onClick={handleDonateClick}
                        sx={{
                          width: "147px",
                          maxWidth: "100%",
                          height: "32px",
                          alignSelf: "center",
                          marginTop: "20px",
                          textTransform: "uppercase",
                        }}
                      />
                    )}
                  </S.BottomFormItem>

                  <S.BottomFormItem width="30%" gridGap={8}>
                    <S.MissionStatementHeading>mission statement</S.MissionStatementHeading>
                    <S.MissionStatementParagraph style={{ flex: 1, display: "flex" }}>
                      {volunteerEvent?.charity?.missionStatement}
                    </S.MissionStatementParagraph>
                  </S.BottomFormItem>

                  <S.BottomFormItem gridGap={8}>
                    <S.MissionStatementHeading>organization details</S.MissionStatementHeading>
                    <S.RowDisplay gridGap={16}>
                      <S.MissionStatementParagraph>
                        <GlobeIcon size={16} />
                      </S.MissionStatementParagraph>

                      {volunteerEvent?.charity?.websiteUrl ? (
                        <ExternalLink
                          href={volunteerEvent?.charity?.websiteUrl.toLowerCase()}
                          text={volunteerEvent?.charity?.websiteUrl.toLowerCase()}
                        />
                      ) : (
                        <S.MissionStatementParagraph>
                          No website provided.
                        </S.MissionStatementParagraph>
                      )}
                    </S.RowDisplay>
                  </S.BottomFormItem>
                </S.BottomFormContainer>
              </S.BottomContentContainer>
            </S.ContentWrapper>
          </>
        ) : (
          <S.EmptyContainer gridGap={32}>
            <SearchIcon size={64} />
            <S.EmptyHeading>Volunteer event could not be found</S.EmptyHeading>
            <S.EmptySubheading>
              Requested event does not exist or is deleted from our database.
            </S.EmptySubheading>
          </S.EmptyContainer>
        )}

        <div style={{ width: "50px", height: "80px" }} />
      </S.VolunteerEventViewInnerContainer>
    </S.VolunteerEventViewContainer>
  );
};

VolunteerEventDetailsPage.defaultProps = {
  isGlobal: false,
};

export default VolunteerEventDetailsPage;
