import * as C from "./charities-search.constants";
import * as T from "./charities-search.type";

export const searchCharities = {
  request: (payload: T.ISearchCharitiesRequest["payload"]): T.ISearchCharitiesRequest => ({
    type: C.SEARCH_CHARITIES,
    payload,
  }),
  success: (payload: T.ISearchCharitiesSuccess["payload"]): T.ISearchCharitiesSuccess => ({
    type: C.SEARCH_CHARITIES_SUCCESS,
    payload,
  }),
  failure: (): T.ISearchCharitiesFailure => ({
    type: C.SEARCH_CHARITIES_FAILURE,
  }),
};

export const showMoreSearchedCharities = {
  request: (): T.IShowMoreSearchedCharitiesRequest => ({
    type: C.SHOW_MORE_SEARCHED_CHARITIES,
  }),
  success: (
    payload: T.IShowMoreSearchedCharitiesSuccess["payload"],
  ): T.IShowMoreSearchedCharitiesSuccess => ({
    type: C.SHOW_MORE_SEARCHED_CHARITIES_SUCCESS,
    payload,
  }),
  failure: (): T.IShowMoreSearchedCharitiesFailure => ({
    type: C.SHOW_MORE_SEARCHED_CHARITIES_FAILURE,
  }),
};

export const discardSearchResults = (): T.IDiscardSearchResults => ({
  type: C.DISCARD_SEARCH_RESULTS,
});
