import * as C from "./company-past-volunteer-events.constants";
import * as T from "./company-past-volunteer-events.type";

export const fetchPastCompanyVolunteerEvents = {
  request: (payload: T.IFetchPastVolunteerEvents["payload"]): T.IFetchPastVolunteerEvents => ({
    type: C.FETCH_PAST_COMPANY_VOLUNTEER_EVENTS,
    payload,
  }),
  success: (
    payload: T.IFetchPastVolunteerEventsSuccess["payload"],
  ): T.IFetchPastVolunteerEventsSuccess => ({
    type: C.FETCH_PAST_COMPANY_VOLUNTEER_EVENTS_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchPastVolunteerEventsFailure => ({
    type: C.FETCH_PAST_COMPANY_VOLUNTEER_EVENTS_FAILURE,
  }),
};

export const refreshPastCompanyVolunteerEvents = {
  request: (payload: T.IRefreshPastVolunteerEvents["payload"]): T.IRefreshPastVolunteerEvents => ({
    type: C.REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS,
    payload,
  }),
  success: (
    payload: T.IRefreshPastVolunteerEventsSuccess["payload"],
  ): T.IRefreshPastVolunteerEventsSuccess => ({
    type: C.REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS_SUCCESS,
    payload,
  }),
  failure: (): T.IRefreshPastVolunteerEventsFailure => ({
    type: C.REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS_FAILURE,
  }),
};
