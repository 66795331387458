import { ProgressBar } from "@primer/react";
import React from "react";
import colors from "theme/colors";

import * as S from "./analytic-progress-bar.styled";

interface IAnalyticProgressBar {
  text: string;
  percent: number;
}

const AnalyticProgressBar: React.FC<IAnalyticProgressBar> = ({ text, percent }): JSX.Element => {
  const validPercent = Math.min(100, Math.max(0, percent));

  return (
    <S.ColumnDisplay alignItems="flex-start" width="100%">
      <S.ProgressBarContainer>
        <S.ProgressBarName>{text}</S.ProgressBarName>
        <S.ProgressBarPercent>{`${validPercent}%`}</S.ProgressBarPercent>
      </S.ProgressBarContainer>
      <ProgressBar
        sx={{ width: "100%", maxWidth: "520px" }}
        progress={percent}
        bg={colors.unitedNationsBlue()}
      />
    </S.ColumnDisplay>
  );
};

export default AnalyticProgressBar;
