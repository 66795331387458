import EveryDotOrgSearchInput from "components/everydotorg-search";
import FixedHeader from "components/fixed-header";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import companySelectors from "store/company/company.selector";
import HorizontalDraggableScroll from "templates/horizontal-draggable-scroll";
import { EVERY_DOT_ORG_CATEGORIES } from "utils/content";
import { genRandomString, uriEncode } from "utils/helper";
import routes from "utils/routes";

import * as S from "./categories.styled";

const EveryDotOrgCategoriesPage: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();

  const companyName = useSelector(companySelectors.selectName);

  return (
    <S.CategoriesContainer justifyContent="flex-start">
      <S.TopContainer alignItems="flex-start" justifyContent="flex-start">
        <FixedHeader name={companyName} />
        <S.Separator height={40} />
        <S.CenteredCategoriesContainer
          alignItems="flex-start"
          gridGap={40}
          justifyContent="flex-start"
        >
          <S.GradientHeadingStyled fontSize={32}>
            Find Nonprofits to support
          </S.GradientHeadingStyled>
          <EveryDotOrgSearchInput />
        </S.CenteredCategoriesContainer>
      </S.TopContainer>
      <S.ColumnDisplay alignItems="flex-start" width="100%">
        <S.Separator height={60} />
        <S.CenteredCategoriesContainer alignItems="flex-start" width="100%">
          <S.BrowseByCategoryText>Browse by category</S.BrowseByCategoryText>
          <S.Separator height={30} />
          <S.Divider isTransparent />
        </S.CenteredCategoriesContainer>

        <S.Separator height={30} />

        <S.CenteredCategoriesContainer
          alignItems="flex-start"
          width="100%"
          justifyContent="flex-start"
        >
          {Object.keys(EVERY_DOT_ORG_CATEGORIES).map((item) => (
            <S.CategoriesRow key={genRandomString()} justifyContent="flex-start" width="100%">
              <S.HighLevelCategoryHeading>{item}</S.HighLevelCategoryHeading>

              <S.CategoriesScrollableRow justifyContent="flex-start">
                <HorizontalDraggableScroll alignItems="flex-start">
                  <S.RowDisplay width="100%" justifyContent="flex-start" gridGap={8}>
                    {EVERY_DOT_ORG_CATEGORIES[item].map((category) => (
                      <S.CategoryContiner
                        key={genRandomString()}
                        onClick={() =>
                          navigate(
                            `${routes.EVERY_DOT_ORG.BROWSE}/${uriEncode(item)}/${category.alias}`,
                          )
                        }
                      >
                        <S.CategoryIcon>{category.icon}</S.CategoryIcon>{" "}
                        <S.CategoryText>{category.text}</S.CategoryText>
                      </S.CategoryContiner>
                    ))}
                  </S.RowDisplay>
                </HorizontalDraggableScroll>
              </S.CategoriesScrollableRow>
            </S.CategoriesRow>
          ))}
          <S.Divider />
          <S.Separator height={40} />
          <S.ColumnDisplay width="100%" style={{ opacity: 0.5 }}>
            <LogoAndCompany companyName={companyName} showVersion={false} />
          </S.ColumnDisplay>
          <S.Separator height={30} />
        </S.CenteredCategoriesContainer>
      </S.ColumnDisplay>
    </S.CategoriesContainer>
  );
};

export default EveryDotOrgCategoriesPage;
