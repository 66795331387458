import { CalendarIcon, EyeClosedIcon, EyeIcon, QuestionIcon } from "@primer/octicons-react";
import { FormControl, Text, TextInput as PrimerInput, Tooltip } from "@primer/react";
import React from "react";
import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import colors from "theme/colors";
import breakpoints from "utils/breakpoints";

import * as T from "./input.type";

const Input: React.FC<T.IInputProps> = React.forwardRef<any, T.IInputProps>(
  (
    {
      topLabel,
      bottomLabel,
      placeholder,
      isRequired,
      errorMessage,
      control,
      type = "text",
      name,
      sx,
      autoFocus,
      size,
      leadingVisual,
      isReadOnly = false,
      onFocus,
      onBlurCallback,
      onEnter,
      onChange,
      multiline,
      isOptionalVisible = true,
      isDisabled = false,
      tooltip,
    },
    ref,
  ): JSX.Element => {
    const [isPasswordHidden, hidePassword] = React.useState<boolean>(true);

    const isMobile = useMediaQuery({
      query: `(max-width: ${breakpoints.iPhoneXR.x}px)`,
    });

    const handleKeyDown = (event) => {
      if (event.key === "Enter" && !!onEnter) {
        onEnter();
      }
    };

    const inputElement = multiline ? "textarea" : "input";

    return (
      <FormControl sx={{ position: "relative", width: "100%" }}>
        <FormControl.Label sx={{ fontSize: 15, color: colors.gunmetal() }}>
          {topLabel}
          {topLabel !== null && (
            <>
              {isRequired ? (
                <span style={{ color: colors.bordo() }}>{" *"}</span>
              ) : (
                isOptionalVisible && (
                  <span style={{ fontStyle: "italic", fontWeight: "400" }}>{" (optional)"}</span>
                )
              )}
            </>
          )}
        </FormControl.Label>
        {control ? (
          <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange: formChange, onBlur } }) => (
              <PrimerInput
                as={inputElement}
                sx={{
                  width: 391,
                  minWidth: isMobile ? "100%" : 322,
                  height: multiline ? "93px" : "53px",
                  color: colors.gunmetal(),
                  fontSize: 16,
                  fontWeight: "600",
                  boxSizing: "border-box",
                  ...sx,
                  overflow: "hidden",
                  paddingLeft: "8px",
                  paddingRight: tooltip ? "52px" : "32px",
                  opacity: isReadOnly || isDisabled ? 0.5 : 1,
                  backgroundColor: isDisabled ? colors.disabledGray(0.2) : colors.white(),
                }}
                readOnly={isReadOnly}
                leadingVisual={leadingVisual}
                disabled={isDisabled}
                autoFocus={autoFocus}
                onFocus={onFocus}
                type={type === "password" && !isPasswordHidden ? "text" : type}
                value={value}
                placeholder={placeholder}
                size={size || "large"}
                validationStatus={errorMessage ? "error" : null}
                onBlur={() => {
                  onBlur();
                  onBlurCallback?.();
                }}
                ref={ref}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  formChange(e);
                  if (onChange) {
                    onChange(e.target.value);
                  }
                }}
                {...(multiline && {
                  style: { resize: "none" },
                })}
                {...(type === "date" && {
                  trailingAction: (
                    <PrimerInput.Action
                      onClick={() => {
                        if (type === "date") {
                          // @ts-ignore
                          ref?.current?.showPicker();
                        }
                      }}
                      icon={CalendarIcon}
                      sx={{ marginLeft: -60, padding: "8px" }}
                    />
                  ),
                })}
                {...(type === "password" && {
                  trailingAction: (
                    <PrimerInput.Action
                      onClick={() => hidePassword(!isPasswordHidden)}
                      icon={isPasswordHidden ? EyeIcon : EyeClosedIcon}
                      sx={{ marginLeft: -60, padding: "8px" }}
                    />
                  ),
                })}
                {...(tooltip && {
                  trailingAction: (
                    <Tooltip
                      text={tooltip}
                      direction="sw"
                      sx={{
                        position: "absolute",
                        right: "12px",
                        bottom: "12px",
                        fontSize: 12,
                        borderRadius: 6,
                      }}
                    >
                      <QuestionIcon size={24} fill={colors.blackCoral()} />
                    </Tooltip>
                  ),
                })}
              />
            )}
          />
        ) : (
          <PrimerInput
            sx={{
              width: 391,
              height: 53,
              boxSizing: "border-box",
              ...sx,
              overflow: "hidden",
              paddingLeft: "8px",
              paddingRight: tooltip ? "52px" : "32px",
              opacity: isReadOnly || isDisabled ? 0.5 : 1,
              backgroundColor: isDisabled ? colors.disabledGray(0.2) : colors.white(),
            }}
            type={type === "password" && !isPasswordHidden ? "text" : type}
            placeholder={placeholder}
            validationStatus={errorMessage ? "error" : null}
            ref={ref}
            autoFocus={autoFocus}
            leadingVisual={leadingVisual}
            onKeyDown={handleKeyDown}
            onFocus={onFocus}
            size={size || "large"}
            readOnly={isReadOnly}
            disabled={isDisabled}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value);
              }
            }}
            {...(type === "date" && {
              trailingAction: (
                <PrimerInput.Action
                  onClick={() => {
                    if (type === "date") {
                      // @ts-ignore
                      ref?.current?.showPicker();
                    }
                  }}
                  icon={CalendarIcon}
                  sx={{ marginLeft: -60, padding: "8px" }}
                />
              ),
            })}
            {...(type === "password" && {
              trailingAction: (
                <PrimerInput.Action
                  onClick={() => hidePassword(!isPasswordHidden)}
                  icon={isPasswordHidden ? EyeIcon : EyeClosedIcon}
                  sx={{ marginLeft: -60, padding: "8px" }}
                />
              ),
            })}
            {...(tooltip && {
              trailingAction: (
                <Tooltip text={tooltip}>
                  <PrimerInput.Action icon={QuestionIcon} sx={{ marginLeft: -28, padding: 0 }} />
                </Tooltip>
              ),
            })}
          />
        )}

        {(errorMessage ?? bottomLabel) && (
          <Text sx={{ fontSize: 12, color: !errorMessage ? colors.gunmetal() : colors.bordo() }}>
            {errorMessage ?? bottomLabel}
          </Text>
        )}
      </FormControl>
    );
  },
);

export default Input;
