import LogoAndCompany from "components/logo-and-company";
import NavigationMenu from "components/navigation-menu";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import authSelectors from "store/auth/auth.selector";
import { LAUNCH_DARKLY_FLAGS } from "utils/constants";
import {
  CHARITY_PROFILE_MENU,
  EMPLOYEE_BURGER_MENU,
  MANAGE_PEOPLE_MENU,
  PROFILE_MENU,
} from "utils/content";

import * as S from "./fixed-header.style";
import * as T from "./fixed-header.type";

const FixedHeader: React.FC<T.IFixedHeaderProps> = ({ name = null }): JSX.Element => {
  const role = useSelector(authSelectors.selectRole);
  const flags = useFlags();

  const isMobile = useMediaQuery({
    query: `(max-width: ${S.MOBILE_BREAKPOINT}px)`,
  });

  const isMobileXS = useMediaQuery({
    query: `(max-width: ${S.MOBILE_SMALL_BREAKPOINT}px)`,
  });

  const token = useSelector(authSelectors.selectToken);
  const isUnauthorised = !token;

  const menu = role === "admin" ? MANAGE_PEOPLE_MENU : EMPLOYEE_BURGER_MENU;
  const burgerMenu = !flags?.[LAUNCH_DARKLY_FLAGS?.findCharities]
    ? menu.filter((item) => item.id !== LAUNCH_DARKLY_FLAGS?.findCharities)
    : menu;

  return (
    <S.FixedHeaderContainer>
      {isUnauthorised ? (
        <S.FixedHeaderInnerContainer justifyContent="flex-start">
          <LogoAndCompany
            fontSize={18}
            companyName={!isMobileXS ? name ?? null : null}
            showVersion={false}
            isFullWidth
          />
        </S.FixedHeaderInnerContainer>
      ) : (
        <S.FixedHeaderInnerContainer alignItems="center" justifyContent="space-between">
          {role !== "charity" && <NavigationMenu items={burgerMenu} width={292} isLeft={false} />}
          <LogoAndCompany
            companyName={!isMobileXS ? name ?? null : null}
            fontSize={18}
            showVersion={false}
            isCentered={role !== "charity"}
            isFullWidth
          />
          <NavigationMenu
            items={role === "charity" ? CHARITY_PROFILE_MENU : PROFILE_MENU}
            width={186}
            showName={!isMobile}
            isLeft
            isAvatarDropdown
          />
        </S.FixedHeaderInnerContainer>
      )}
    </S.FixedHeaderContainer>
  );
};

export default FixedHeader;
