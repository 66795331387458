import * as C from "./event-check-in.constants";
import * as T from "./event-check-in.type";

export const checkIntoVolunteerScheduledEvent = {
  request: (payload: T.ICheckIntoVolunteerEvent["payload"]): T.ICheckIntoVolunteerEvent => ({
    type: C.CHECK_INTO_VOLUNTEER_SCHEDULED_EVENT,
    payload,
  }),
  success: (
    payload: T.ICheckIntoVolunteerEventSuccess["payload"],
  ): T.ICheckIntoVolunteerEventSuccess => ({
    type: C.CHECK_INTO_VOLUNTEER_SCHEDULED_EVENT_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.ICheckIntoVolunteerEventFailure["payload"],
  ): T.ICheckIntoVolunteerEventFailure => ({
    type: C.CHECK_INTO_VOLUNTEER_SCHEDULED_EVENT_FAILURE,
    payload,
  }),
};
