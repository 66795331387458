import "./index.css";

import * as FullStory from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import NavigateSetter from "router/history/navigate-setter";
import { IS_PROD } from "utils/constants";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

FullStory.init({ orgId: window.__RUNTIME_CONFIG__.FULL_STORY_ORG_ID });

const initSentry = (): void => {
  if (IS_PROD) {
    Sentry.init({
      dsn: window.__RUNTIME_CONFIG__.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      environment: `FE ${IS_PROD ? "production" : "stage"}`,
      tracesSampleRate: 1.0,
      normalizeDepth: 4,
    });
  }
};

initSentry();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <NavigateSetter />
    <App />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
