export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const EDIT_EMPLOYEE_DETAILS = "EDIT_EMPLOYEE_DETAILS";
export const EDIT_EMPLOYEE_DETAILS_SUCCESS = "EDIT_EMPLOYEE_DETAILS_SUCCESS";
export const EDIT_EMPLOYEE_DETAILS_FAILURE = "EDIT_EMPLOYEE_DETAILS_FAILURE";

export const UPDATE_INTERESTS = "UPDATE_INTERESTS";
export const UPDATE_INTERESTS_SUCCESS = "UPDATE_INTERESTS_SUCCESS";
export const UPDATE_INTERESTS_FAILURE = "UPDATE_INTERESTS_FAILURE";

export const TOGGLE_GIFT_MATCH_NOTIFICATION = "TOGGLE_GIFT_MATCH_NOTIFICATION";
export const TOGGLE_GIFT_MATCH_NOTIFICATION_SUCCESS = "TOGGLE_GIFT_MATCH_NOTIFICATION_SUCCESS";
export const TOGGLE_GIFT_MATCH_NOTIFICATION_FAILURE = "TOGGLE_GIFT_MATCH_NOTIFICATION_FAILURE";
