import * as C from "./auth.constants";
import * as T from "./auth.type";

export const storeAuthToken = (payload: T.IStoreToken["payload"]): T.IStoreToken => ({
  type: C.STORE_AUTH_TOKEN,
  payload,
});

export const signInCredentials = {
  request: (payload: T.ISignInCredentials["payload"]): T.ISignInCredentials => ({
    type: C.SIGN_IN_CREDENTIALS,
    payload,
  }),
  success: (payload: T.ISignInCredentialsSuccess["payload"]): T.ISignInCredentialsSuccess => ({
    type: C.SIGN_IN_CREDENTIALS_SUCCESS,
    payload,
  }),
  failure: (): T.ISignInCredentialsFailure => ({
    type: C.SIGN_IN_CREDENTIALS_FAILURE,
  }),
};

export const signInCode = {
  request: (payload: T.ISignInCode["payload"]): T.ISignInCode => ({
    type: C.SIGN_IN_CODE,
    payload,
  }),
  success: (payload: T.ISignInCodeSuccess["payload"]): T.ISignInCodeSuccess => ({
    type: C.SIGN_IN_CODE_SUCCESS,
    payload,
  }),
  failure: (): T.ISignInCodeFailure => ({
    type: C.SIGN_IN_CODE_FAILURE,
  }),
};

export const sendCodeEmployee = {
  request: (payload: T.ISendCodeEmployee["payload"]): T.ISendCodeEmployee => ({
    type: C.SEND_CODE_EMPLOYEE,
    payload,
  }),
  success: (payload: T.ISendCodeEmployeeSuccess["payload"]): T.ISendCodeEmployeeSuccess => ({
    type: C.SEND_CODE_EMPLOYEE_SUCCESS,
    payload,
  }),
  failure: (): T.ISendCodeEmployeeFailure => ({
    type: C.SEND_CODE_EMPLOYEE_FAILURE,
  }),
};

export const resetPassword = {
  request: (payload: T.IResetPassword["payload"]): T.IResetPassword => ({
    type: C.RESET_PASSWORD,
    payload,
  }),
  success: (): T.IResetPasswordSuccess => ({
    type: C.RESET_PASSWORD_SUCCESS,
  }),
  failure: (): T.IResetPasswordFailure => ({
    type: C.RESET_PASSWORD_FAILURE,
  }),
};

export const setNewPassword = {
  request: (payload: T.ISetNewPassword["payload"]): T.ISetNewPassword => ({
    type: C.SET_NEW_PASSWORD,
    payload,
  }),
  success: (): T.ISetNewPasswordSuccess => ({
    type: C.SET_NEW_PASSWORD_SUCCESS,
  }),
  failure: (): T.ISetNewPasswordFailure => ({
    type: C.SET_NEW_PASSWORD_FAILURE,
  }),
};

export const resendCode = {
  request: (payload: T.IResendCode["payload"]): T.IResendCode => ({
    type: C.RESEND_CODE,
    payload,
  }),
  success: (): T.IResendCodeSuccess => ({
    type: C.RESEND_CODE_SUCCESS,
  }),
  failure: (): T.IResendCodeFailure => ({
    type: C.RESEND_CODE_FAILURE,
  }),
};

export const resendVerificationEmail = {
  request: (payload: T.IResendVerificationLink["payload"]): T.IResendVerificationLink => ({
    type: C.RESEND_VERIFICATION_EMAIL,
    payload,
  }),
  success: (): T.IResendVerificationLinkSuccess => ({
    type: C.RESEND_VERIFICATION_EMAIL_SUCCESS,
  }),
  failure: (): T.IResendVerificationLinkFailure => ({
    type: C.RESEND_VERIFICATION_EMAIL_FAILURE,
  }),
};

export const logout = (): T.ILogout => ({
  type: C.LOGOUT,
});
