import { Spinner } from "@primer/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { LogoOvalIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearClientSecret, setupPaymentMethod } from "store/payment/payment.action";
import paymentSelectors from "store/payment/payment.selector";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import colors from "theme/colors";

import AddPaymentMethodForm from "./add-payment-method-form";
import * as S from "./connect-stripe.styled";

const stripePromise = loadStripe(window.__RUNTIME_CONFIG__.STRIPE_PUBLISHABLE_KEY);

const ConnectPaymentMethodDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const clientSecret = useSelector(paymentSelectors.selectClientSecret);

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, "SETUP_PAYMENT_METHOD"),
  );

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: "SETUP_PAYMENT_METHOD", isOpened: false }));
  };

  React.useEffect(() => {
    if (!isOpened) {
      dispatch(clearClientSecret());
    } else {
      dispatch(setupPaymentMethod.request());
    }
  }, [isOpened]);

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper
        onClick={(e) => e.stopPropagation()}
        gridGap={0}
        justifyContent="flex-start"
      >
        <S.AbsoluteLogoWrapper>
          <LogoOvalIcon />
        </S.AbsoluteLogoWrapper>
        <S.DialogInnerWrapper padding={40} style={{ background: colors.aliceBlue() }}>
          {clientSecret ? (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <AddPaymentMethodForm clientSecret={clientSecret} />
            </Elements>
          ) : (
            <Spinner />
          )}
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default ConnectPaymentMethodDialog;
