import { SearchIcon } from "@primer/octicons-react";
import React from "react";
import { Controller } from "react-hook-form";

import { ICustomInputProps } from "./custom.search-input";
import * as S from "./custom-search-input.styled";

const CustomSearchInput: React.FC<ICustomInputProps> = ({
  control,
  name,
  label,
  error,
  onChange,
  onSubmit,
}): JSX.Element => {
  const [isFocused, focus] = React.useState<boolean>(false);

  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <S.SearchInputContainer>
      <S.SearchInputFrame tabIndex={0} isFocused={isFocused} onBlur={() => focus(false)}>
        <SearchIcon fill="rgba(178, 192, 227, 1)" size={18} />
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange: formChange } }) => (
            <S.TransparentInput
              ref={ref}
              type="text"
              maxLength={50}
              value={value}
              onChange={(e) => {
                formChange(e);
                onChange(e.target.value);
              }}
              placeholder={label || "Search..."}
              onFocus={() => focus(true)}
              onKeyPress={(e) => {
                const val = (e.target as HTMLInputElement).value;
                if (e.key === "Enter" && onSubmit) {
                  e.preventDefault();
                  onSubmit(val);
                }
              }}
            />
          )}
        />
      </S.SearchInputFrame>
      <S.InputError>{error}</S.InputError>
    </S.SearchInputContainer>
  );
};

export default CustomSearchInput;
