import * as C from "./volunteer-event-details.constants";
import * as T from "./volunteer-event-details.type";

export const fetchVolunteerEvent = {
  request: (payload: T.IFetchVolunteerEvent["payload"]): T.IFetchVolunteerEvent => ({
    type: C.FETCH_VOLUNTEER_EVENT,
    payload,
  }),
  success: (payload: T.IFetchVolunteerEventSuccess["payload"]): T.IFetchVolunteerEventSuccess => ({
    type: C.FETCH_VOLUNTEER_EVENT_SUCCESS,
    payload,
  }),
  failure: (payload: T.IFetchVolunteerEventFailure["payload"]): T.IFetchVolunteerEventFailure => ({
    type: C.FETCH_VOLUNTEER_EVENT_FAILURE,
    payload,
  }),
};

export const attendVolunteerEvent = {
  request: (payload: T.IAttendVolunteerEvent["payload"]): T.IAttendVolunteerEvent => ({
    type: C.ATTEND_VOLUNTEER_EVENT,
    payload,
  }),
  success: (
    payload: T.IAttendVolunteerEventSuccess["payload"],
  ): T.IAttendVolunteerEventSuccess => ({
    type: C.ATTEND_VOLUNTEER_EVENT_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.IAttendVolunteerEventFailure["payload"],
  ): T.IAttendVolunteerEventFailure => ({
    type: C.ATTEND_VOLUNTEER_EVENT_FAILURE,
    payload,
  }),
};

export const unattendVolunteerEvent = {
  request: (payload: T.IUnattendVolunteerEvent["payload"]): T.IUnattendVolunteerEvent => ({
    type: C.UNATTEND_VOLUNTEER_EVENT,
    payload,
  }),
  success: (
    payload: T.IUnattendVolunteerEventSuccess["payload"],
  ): T.IUnattendVolunteerEventSuccess => ({
    type: C.UNATTEND_VOLUNTEER_EVENT_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.IUnattendVolunteerEventFailure["payload"],
  ): T.IUnattendVolunteerEventFailure => ({
    type: C.UNATTEND_VOLUNTEER_EVENT_FAILURE,
    payload,
  }),
};

export const requestVolunteerEventIntro = {
  request: (payload: T.IRequestVolunteerEventIntro["payload"]): T.IRequestVolunteerEventIntro => ({
    type: C.REQUEST_VOLUNTEER_EVENT_INTRO,
    payload,
  }),
  success: (
    payload: T.IRequestVolunteerEventIntroSuccess["payload"],
  ): T.IRequestVolunteerEventIntroSuccess => ({
    type: C.REQUEST_VOLUNTEER_EVENT_INTRO_SUCCESS,
    payload,
  }),
  failure: (): T.IRequestVolunteerEventIntroFailure => ({
    type: C.REQUEST_VOLUNTEER_EVENT_INTRO_FAILURE,
  }),
};

export const fetchVolunteerEventAttending = {
  request: (
    payload: T.IFetchVolunteerEventAttending["payload"],
  ): T.IFetchVolunteerEventAttending => ({
    type: C.FETCH_VOLUNTEER_EVENT_ATTENDING,
    payload,
  }),
  success: (
    payload: T.IFetchVolunteerEventAttendingSuccess["payload"],
  ): T.IFetchVolunteerEventAttendingSuccess => ({
    type: C.FETCH_VOLUNTEER_EVENT_ATTENDING_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.IFetchVolunteerEventAttendingFailure["payload"],
  ): T.IFetchVolunteerEventAttendingFailure => ({
    type: C.FETCH_VOLUNTEER_EVENT_ATTENDING_FAILURE,
    payload,
  }),
};
