import { Autocomplete, FormControl, Text } from "@primer/react";
import * as T from "components/input/input.type";
import React, { useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import colors from "theme/colors";
import { mapStateCodesToSelectItems } from "utils/mapper";
import { STATE_CODES } from "utils/state-codes";

const MAX_DROPDOWN_VALUES = 5;

const StateAutocompleteInput: React.FC<T.IInputProps> = React.forwardRef<any, T.IInputProps>(
  (
    {
      bottomLabel,
      placeholder,
      errorMessage,
      control,
      type = "text",
      name,
      sx,
      autoFocus,
      size,
      leadingVisual,
      isReadOnly = false,
      onFocus,
      onBlurCallback,
      onEnter,
      isDisabled = false,
    },
    ref,
  ): JSX.Element => {
    const [filteredOptions, setFilteredOptions] = useState([]);

    const handleKeyDown = (event) => {
      if (event.key === "Enter" && !!onEnter) {
        onEnter();
      }
    };

    const stateOptions = useMemo(() => mapStateCodesToSelectItems(STATE_CODES), []);

    const handleOnChange = (e, formChange): void => {
      const { value } = e.target;
      const lowerCaseValue = value.toLowerCase();
      const filtered = stateOptions.filter(
        (option) =>
          option.text.toLowerCase().includes(lowerCaseValue) ||
          option.value.toLowerCase().includes(lowerCaseValue),
      );

      setFilteredOptions(filtered);

      formChange(e);
    };

    return (
      <FormControl>
        <FormControl.Label visuallyHidden sx={{ fontSize: 15, color: colors.gunmetal() }}>
          {name}
        </FormControl.Label>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange: formChange, onBlur } }) => (
            <Autocomplete>
              <Autocomplete.Input
                sx={{
                  width: 191,
                  minWidth: 288,
                  height: "48px",
                  color: colors.gunmetal(),
                  fontSize: 16,
                  fontWeight: "600",
                  boxSizing: "border-box",
                  ...sx,
                  paddingRight: "32px",
                  opacity: isReadOnly || isDisabled ? 0.5 : 1,
                  backgroundColor: isDisabled ? colors.disabledGray(0.2) : colors.white(),
                }}
                leadingVisual={leadingVisual}
                autoFocus={autoFocus}
                onFocus={onFocus}
                type={type}
                value={value}
                placeholder={placeholder}
                size={size || "large"}
                validationStatus={errorMessage ? "error" : null}
                onBlur={() => {
                  onBlur();
                  onBlurCallback?.();
                }}
                ref={ref}
                onKeyDown={handleKeyDown}
                onChange={(e) => handleOnChange(e, formChange)}
              />
              {filteredOptions.length > 0 && (
                <Autocomplete.Overlay width="medium">
                  <Autocomplete.Menu
                    items={
                      filteredOptions.length > 0
                        ? filteredOptions.slice(0, MAX_DROPDOWN_VALUES)
                        : stateOptions.slice(0, MAX_DROPDOWN_VALUES)
                    }
                    selectedItemIds={[]}
                    selectionVariant="single"
                    onSelectedChange={(selectedItem) =>
                      selectedItem && formChange(selectedItem[0]?.text)
                    }
                    emptyStateText="No location suggestions found."
                  />
                </Autocomplete.Overlay>
              )}
            </Autocomplete>
          )}
        />
        {(errorMessage ?? bottomLabel) && (
          <Text sx={{ fontSize: 12, color: !errorMessage ? colors.gunmetal() : colors.bordo() }}>
            {errorMessage ?? bottomLabel}
          </Text>
        )}
      </FormControl>
    );
  },
);

export default StateAutocompleteInput;
