import * as C from "./every-org-search.constants";
import * as T from "./every-org-search.type";

export const searchEveryOrgCharities = {
  request: (payload: T.ISearchEveryOrgCharities["payload"]): T.ISearchEveryOrgCharities => ({
    type: C.SEARCH_EVERY_ORG_CHARITIES,
    payload,
  }),
  success: (
    payload: T.ISearchEveryOrgCharitiesSuccess["payload"],
  ): T.ISearchEveryOrgCharitiesSuccess => ({
    type: C.SEARCH_EVERY_ORG_CHARITIES_SUCCESS,
    payload,
  }),
  failure: (): T.ISearchEveryOrgCharitiesFailure => ({
    type: C.SEARCH_EVERY_ORG_CHARITIES_FAILURE,
  }),
};

export const resetSearchTerm = (): T.IResetSearchTerm => ({
  type: C.RESET_SEARCH_TERM,
});

export const displaySearchDropdown = (
  payload: T.IDisplaySearchDropdown["payload"],
): T.IDisplaySearchDropdown => ({
  type: C.DISPLAY_SEARCH_DROPDOWN,
  payload,
});
