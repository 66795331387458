import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { EVERY_ORG_CHARITIES_SEARCH_LIMIT } from "utils/constants";

import * as C from "./every-org-search.constants";
import * as T from "./every-org-search.type";

const defaultDataStore = {
  data: [],
  page: 0,
  isFetched: false,
  isEndReached: false,
  inProgress: false,
};

export const everyOrgSearchInitial: T.IEveryOrgSearchState = {
  screen: defaultDataStore,
  dropdown: defaultDataStore,
  displayDropdown: false,
  searchTerm: "",
  error: null,
};

const everyOrgSearchReducer = (
  state: T.IEveryOrgSearchState,
  action: T.EveryOrgSearchAction | ILogout,
): T.IEveryOrgSearchState => {
  if (!state) {
    return everyOrgSearchInitial;
  }

  switch (action.type) {
    case C.SEARCH_EVERY_ORG_CHARITIES:
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
        dropdown: {
          ...state.dropdown,
          inProgress: !!action.payload.searchTerm.length,
        },
        screen: {
          ...state.screen,
          inProgress: action.payload.showOnPage,
        },
      };
    case C.SEARCH_EVERY_ORG_CHARITIES_SUCCESS:
      return {
        ...state,
        dropdown: {
          ...state.dropdown,
          data: !action.payload.searchTerm?.length ? state.dropdown.data : action.payload.data,
          isFetched: true,
          isEndReached: action.payload.data.length < EVERY_ORG_CHARITIES_SEARCH_LIMIT,
          inProgress: false,
        },
        screen: !action.payload.showOnPage
          ? state.screen
          : {
              ...state.screen,
              data: action.payload.data,
              isFetched: true,
              isEndReached: action.payload.data.length < EVERY_ORG_CHARITIES_SEARCH_LIMIT,
              inProgress: false,
            },
      };
    case C.SEARCH_EVERY_ORG_CHARITIES_FAILURE:
      return {
        ...state,
        dropdown: {
          ...state.dropdown,
          inProgress: false,
        },
        screen: {
          ...state.screen,
          inProgress: false,
        },
      };
    case C.DISPLAY_SEARCH_DROPDOWN:
      return {
        ...state,
        displayDropdown: action.payload,
      };
    case C.RESET_SEARCH_TERM:
      return {
        ...state,
        dropdown: { ...defaultDataStore, isFetched: true },
        searchTerm: "",
        displayDropdown: false,
      };
    case LOGOUT:
      return everyOrgSearchInitial;
    default:
      return state;
  }
};

export default everyOrgSearchReducer;
