import styled from "styled-components";

import { RowDisplay } from "../dialogs.styled";

export * from "../dialogs.styled";

export const AbsoluteLogoWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: -70px;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const DonateHeading = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const DonateSlot = styled(RowDisplay)`
  width: 146px;
  height: 53px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.grayX11()};
  border-radius: 6px;
  cursor: pointer;
  color: ${({ theme }) => theme.gunmetal()};
  position: relative;
`;

export const Dollar = styled.div`
  font-size: 12px;
  align-self: start;
  margin-top: 14px;
`;

export const DonateSlotAmount = styled.div`
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;

export const DonateSlotSelected = styled(DonateSlot)`
  border: 2px solid ${({ theme }) => theme.hanBlue()};
  background: ${({ theme }) => theme.hanBlue(0.2)};
  color: ${({ theme }) => theme.hanBlue()};
  font-weight: 600;
`;

export const TermsOfServiceLink = styled.a`
  color: ${({ theme }) => theme.trueBlue()};
`;
