import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import breakpoints from "utils/breakpoints";

export { ColumnDisplay, RowDisplay } from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPadPro.y;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 45px 0px rgba(21, 41, 93, 0.1);
  padding: 60px;
  flex: 1;

  border-radius: 40px;
  max-width: 659px;
  width: calc(50% - 20px);
  min-width: calc(50% - 20px);
  box-sizing: border-box;
  background: ${({ theme }) => theme.white()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 8px;
    align-items: center;
    gap: 20px;
  }

  @media all and (max-width: ${breakpoints.iPadMini.x}px) {
    min-width: 300px;
  }

  @media all and (max-width: ${breakpoints.iPhone8.x}px) {
    min-width: calc(100% - 8px) !important;
    max-width: calc(100% - 8px) !important;
  }
`;

export const BarChartContainer = styled(Bar)`
  padding: 0;
  max-width: 573px;
`;

export const BarChartTitle = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: ${({ theme }) => theme.gunmetal()};
  margin-bottom: 32px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 80%;
    margin-top: 20px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: "space-around;
  flex-direction: row;
`;
