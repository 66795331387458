import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import * as C from "./manage-people.constants";
import * as T from "./manage-people.type";

export const managePeopleInitial: T.IManagePeopleState = {
  admins: {
    page: 1,
    data: [],
    isFetched: false,
    isEndReached: false,
    inProgress: false,
  },
  employees: {
    page: 1,
    data: [],
    isFetched: false,
    isEndReached: false,
    inProgress: false,
  },
  numAdmins: 0,
  numEmployees: 0,
  isFetched: false,
  inProgress: false,
  error: null,
  statistics: {
    inProgress: false,
    isFetched: false,
    totalUsers: 0,
    totalVerifiedUsers: 0,
    totalVerifiedPercentage: 0,
    totalInteractedUsers: 0,
    totalInteractedPercentage: 0,
  },
};

const managePeopleReducer = (
  state: T.IManagePeopleState,
  action: T.ManagePeopleAction | ILogout,
): T.IManagePeopleState => {
  if (!state) {
    return managePeopleInitial;
  }

  switch (action.type) {
    case C.GET_ADMIN_USERS:
    case C.GET_EMPLOYEE_USERS:
      return {
        ...state,
        inProgress: true,
      };
    case C.GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
        admins: {
          ...state.admins,
          ...action.payload,
          isFetched: true,
          inProgress: false,
        },
      };
    case C.GET_EMPLOYEE_USERS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
        employees: {
          ...state.employees,
          ...action.payload,
          isFetched: true,
          inProgress: false,
        },
      };
    case C.GET_ADMIN_USERS_FAILURE:
    case C.GET_EMPLOYEE_USERS_FAILURE:
      return {
        ...state,
        isFetched: true,
        inProgress: false,
      };
    case C.GET_USERS_BY_PAGE:
      return {
        ...state,
        [action.payload.role]: {
          ...state[action.payload.role],
          page: action.payload.page,
          inProgress: true,
        },
      };
    case C.GET_USERS_BY_PAGE_SUCCESS:
      return {
        ...state,
        [action.payload.role]: {
          ...state[action.payload.role],
          ...action.payload.response,
          inProgress: false,
        },
      };
    case C.UPDATE_USER_ROLE_SUCCESS:
    case C.REMOVE_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case C.GET_USER_STATISTICS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          inProgress: true,
          isFetched: false,
        },
      };
    case C.GET_USER_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: {
          inProgress: false,
          isFetched: true,
          ...action.payload,
        },
      };
    case C.GET_USER_STATISTICS_FAILURE:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          inProgress: false,
          isFetched: true,
        },
      };
    case LOGOUT:
      return managePeopleInitial;
    default:
      return state;
  }
};

export default managePeopleReducer;
