export const UNHANDLED_CODES = ["INVALID_DATA_SENT", "SOMETHING_WENT_WRONG"];

export const MAX_TABLE_COLUMNS = 10;

export const SPECIAL_CHARACTERS_REGEX = /[^a-zA-Z0-9 ]/g;

export const LETTER_FIRST_REGEX = /^[a-zA-Z]+[a-zA-Z\d]*['-][a-zA-Z\d]*$/;

export const PHONE_NUMBER_REG = /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const EMAIL_REG = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const DOMAIN_REG = /^@([\w-]+\.)+[\w-]{2,4}$/g;

export const LINKEDIN_URL_REG = /(?:(?:https):\/\/)?(?:www.)?(?:linkedin.com)\/([A-Za-z0-9-_]+)/im;

export const LOWER_CASE_LETTERS_AND_DIGITS_REG = /^[a-z0-9]*$/;

export const NAME_REG = /^[a-zA-Z]+[a-zA-Z\s'-]*$/;

export const EIN_NUMBER_REG = /^\d{2}-\d{7}$/;

export const GRANT_REG = /^[-+]?\d+$/;

export const SUPPORT_EMAIL = "dev@fundwurx.com";

export const TERMS_OF_SERVICE_LINK = "https://www.fundwurx.com/terms-of-service";

export const PRIVACY_POLICY_LINK = "https://www.fundwurx.com/privacy-policy";

export const DEBOUNCE_TIME = 1000; // 1s

export const CHARITY_PROP_MISSING =
  "This organization has not currently provided Fundwurx with this data.";

export const MAX_CHARITIES_LENGTH = 7;

export const TWO_DECIMALS_REG = /^-?[0-9]+(?:\.[0-9]{1,2})?$/;

export const NOT_APPLICABLE_VAL = "Not Applicable";

export const MAX_LAYOUT_WIDTH = 1708;

export const YEAR_MILLS = 31556952000; // 1 year
export const DAY_MILLS = 86400000; // 1 day

export const MIN_DATE = new Date(Date.now() - YEAR_MILLS * 100 - DAY_MILLS);

export const MAX_DATE = new Date(Date.now());

export const CHARITY_MAILTO = `mailto:dev@fundwurx.com?subject=Refer a charity&body=If you'd like to refer a charity please fill in the details and send this email to us.
%0D%0A
%0D%0ACharity name:
%0D%0ALocation:`;

export const MIN_DONATION = 1;

export const MAX_DONATION = 10000;

export const FEE_PERCENTAGE = 0.037;

export const MAX_PROMOTED_CHARITIES = 10;
export const MAX_PROMOTED_EVENTS = 10;

export const VOLUNTEER_EVENTS_PER_PAGE = 10;

export const MAX_ALLOWED_FILE_SIZE_MB = 15;
export const MAX_ALLOWED_FILE_SIZE = MAX_ALLOWED_FILE_SIZE_MB * 1024 * 1024;

export const EVERY_ORG_CHARITIES_LIMIT = 20;
export const EVERY_ORG_CHARITIES_SEARCH_LIMIT = 50;

export const DEFAULT_ADD_EVENT_CAL = "KS662173";

export const LAUNCH_DARKLY_FLAGS = {
  findCharities: "findCharities",
};

export const IS_PROD = !window.__RUNTIME_CONFIG__.REACT_APP_URL?.includes("stage");

export const DATE_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "LT";

export const MAX_Q_LENGTH = 50;
export const MIN_Q_LENGTH = 3;

export const MAX_SELECTED_INTERESTS = 5;
export const MAX_LINES_FOR_READ_MORE = 12;

export const DONATION_METHODS = "card,paypal,venmo";
