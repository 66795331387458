import { IGiftMatchingSelectors } from "./gift-matching.type";

const giftMatchSelectors: IGiftMatchingSelectors = {
  selectData: (state) => state.giftMatch.data,
  selectInProgress: (state) => state.giftMatch.inProgress,
  selectError: (state) => state.giftMatch.error,
  selectIsFetched: (state) => state.giftMatch.isFetched,
  selectToggleState: (state) => state.giftMatch.data?.active ?? false,
  selectMaxSum: (state) => state.giftMatch.data?.maxSum ?? 0,
  selectMinSum: (state) => state.giftMatch.data?.minSum ?? 0,
};

export default giftMatchSelectors;
