export const OK = 200;
export const ACCEPTED = 202;
export const DELETED = 204;
export const FOUND = 302;
export const BAD_REQUEST = 400;
export const NOT_FOUND = 402;
export const INTERNAL_SERVER_ERROR = 500;
export const BAD_GATEWAY = 502;
export const FEATURE_NOT_IMPLEMENTED = 501;

export type StatusCode =
  | typeof ACCEPTED
  | typeof DELETED
  | typeof FOUND
  | typeof BAD_REQUEST
  | typeof NOT_FOUND
  | typeof INTERNAL_SERVER_ERROR
  | typeof BAD_GATEWAY;
