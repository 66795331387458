import { ThemeProvider as PrimerThemeProvider } from "@primer/react";
import Dialogs from "components/dialogs";
import FullScreenLoader from "components/full-screen-loader";
import Toast from "components/toast";
import React from "react";
import AppRouter from "router";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import colors from "theme/colors";
import { GlobalStyles } from "theme/global-style";

const RootContainer: React.FC<{}> = (): JSX.Element => (
  <StyledThemeProvider theme={colors}>
    <PrimerThemeProvider>
      <GlobalStyles />
      <Toast />
      <FullScreenLoader />
      <Dialogs />
      <AppRouter />
    </PrimerThemeProvider>
  </StyledThemeProvider>
);

export default RootContainer;
