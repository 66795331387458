import * as api from "api/volunteer";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";

import {
  fetchMoreVolunteerScheduledEvents,
  fetchVolunteerScheduledEvents,
} from "./volunteer-scheduled.action";
import * as C from "./volunteer-scheduled.constants";
import * as T from "./volunteer-scheduled.type";

export const fetchScheduledVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IFetchVolunteerScheduledEvents>,
) =>
  action$.pipe(
    ofType(C.FETCH_VOLUNTEER_SCHEDULED_EVENTS),
    mergeMap((action) =>
      from(api.getScheduledVolunteerEvents(action.payload.params, action.payload.status)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(
              fetchVolunteerScheduledEvents.success({
                params: response.data,
                status: action.payload.status,
              }),
            );
          }
          return of(
            handleError({ action, error: null }),
            fetchVolunteerScheduledEvents.failure({ params: null, status: action.payload.status }),
          );
        }),
        catchError((error) =>
          of(
            fetchVolunteerScheduledEvents.failure({ params: null, status: action.payload.status }),
            handleError({ action, error }),
          ),
        ),
      ),
    ),
  );

export const fetchMoreScheduledVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IFetchMoreVolunteerScheduledEvents>,
) =>
  action$.pipe(
    ofType(C.FETCH_MORE_VOLUNTEER_SCHEDULED_EVENTS),
    mergeMap((action) =>
      from(api.getScheduledVolunteerEvents(action.payload.params, action.payload.status)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(
              fetchMoreVolunteerScheduledEvents.success({
                params: response.data,
                status: action.payload.status,
              }),
            );
          }
          return of(
            handleError({ action, error: null }),
            fetchMoreVolunteerScheduledEvents.failure({
              params: null,
              status: action.payload.status,
            }),
          );
        }),
        catchError((error) =>
          of(
            fetchMoreVolunteerScheduledEvents.failure({
              params: null,
              status: action.payload.status,
            }),
            handleError({ action, error }),
          ),
        ),
      ),
    ),
  );

export default combineEpics(
  fetchScheduledVolunteerEventsEpic,
  fetchMoreScheduledVolunteerEventsEpic,
);
