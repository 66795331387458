import * as api from "api/volunteer";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";

import {
  fetchPastCompanyVolunteerEvents,
  refreshPastCompanyVolunteerEvents,
} from "./company-past-volunteer-events.action";
import * as C from "./company-past-volunteer-events.constants";
import * as T from "./company-past-volunteer-events.type";

export const fetchCompanyPastVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IFetchPastVolunteerEvents>,
) =>
  action$.pipe(
    ofType(C.FETCH_PAST_COMPANY_VOLUNTEER_EVENTS),
    mergeMap((action) =>
      from(api.getScheduledVolunteerEvents(action.payload.params, action.payload.status)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchPastCompanyVolunteerEvents.success(response.data));
          }
          return of(
            handleError({ action, error: null }),
            fetchPastCompanyVolunteerEvents.failure(),
          );
        }),
        catchError((error) =>
          of(fetchPastCompanyVolunteerEvents.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export const refreshCompanyPastVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IRefreshPastVolunteerEvents>,
) =>
  action$.pipe(
    ofType(C.REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS),
    mergeMap((action) =>
      from(api.getScheduledVolunteerEvents(action.payload.params, action.payload.status)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(refreshPastCompanyVolunteerEvents.success(response.data));
          }
          return of(
            handleError({ action, error: null }),
            refreshPastCompanyVolunteerEvents.failure(),
          );
        }),
        catchError((error) =>
          of(refreshPastCompanyVolunteerEvents.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export default combineEpics(
  fetchCompanyPastVolunteerEventsEpic,
  refreshCompanyPastVolunteerEventsEpic,
);
