import * as api from "api/volunteer";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";

import { fetchVolunteerEvents, refreshVolunteerEvents } from "./company-volunteer-events.action";
import * as C from "./company-volunteer-events.constants";
import * as T from "./company-volunteer-events.type";

export const fetchCompanyVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IFetchVolunteerEvents>,
) =>
  action$.pipe(
    ofType(C.FETCH_COMPANY_VOLUNTEER_EVENTS),
    mergeMap((action) =>
      from(api.getVolunteerEvents(action.payload)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchVolunteerEvents.success(response.data));
          }
          return of(handleError({ action, error: null }), fetchVolunteerEvents.failure());
        }),
        catchError((error) => of(fetchVolunteerEvents.failure(), handleError({ action, error }))),
      ),
    ),
  );

export const refreshCompanyVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IRefreshVolunteerEvents>,
) =>
  action$.pipe(
    ofType(C.REFRESH_COMPANY_VOLUNTEER_EVENTS),
    mergeMap((action) =>
      from(api.getVolunteerEvents(action.payload)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(refreshVolunteerEvents.success(response.data));
          }
          return of(handleError({ action, error: null }), refreshVolunteerEvents.failure());
        }),
        catchError((error) => of(refreshVolunteerEvents.failure(), handleError({ action, error }))),
      ),
    ),
  );

export default combineEpics(fetchCompanyVolunteerEventsEpic, refreshCompanyVolunteerEventsEpic);
