import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/buttons/primary";
import ControlledSelect from "components/controlled-select";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAnalyticDashboardSummary,
  getCharitiesSupported,
  getDashboardSummary,
  getDonationsAnalytics,
  getGiftMatchingAnalytics,
  getVolunteeringAnalytics,
} from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import { genListFromInterval, genRandomString, getCurrYear } from "utils/helper";
import * as yup from "yup";

import * as S from "../dialogs.styled";

const FIRST_YEAR = 2023;
const CURRENT_YEAR = getCurrYear();
const ALL_YEARS = genListFromInterval(FIRST_YEAR, CURRENT_YEAR);
const MODAL_KEY = "CSR_YEAR_MODAL";

const schema = yup.object().shape({
  year: yup.number().required("Please fill in all data."),
});

const MetricsAndAnalyticsDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, MODAL_KEY),
  );

  const companyName = useSelector(companySelectors.selectName);

  const { handleSubmit, control } = useForm({
    defaultValues: { year: CURRENT_YEAR },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: MODAL_KEY, isOpened: false }));
  };

  const onSubmit = ({ year }): void => {
    closeDialog();
    navigate(`company/dashboard/${year}`);
    dispatch(getDashboardSummary.request({ year }));
    dispatch(getAnalyticDashboardSummary.request({ year }));
    dispatch(getCharitiesSupported.request({ year }));
    dispatch(getDonationsAnalytics.request({ year }));
    dispatch(getGiftMatchingAnalytics.request({ year }));
    dispatch(getVolunteeringAnalytics.request({ year }));
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper>
          <S.DialogHeading fontSize={28}>
            {`${companyName}'s CSR `}Metrics & Analytics
          </S.DialogHeading>
          <S.Separator height={32} />
          <S.DialogSubheading>Please select the year you want to see data for</S.DialogSubheading>
          <S.Separator height={32} />
          <ControlledSelect
            name="year"
            items={ALL_YEARS.map((item) => ({
              id: genRandomString(),
              text: `${item}`,
              value: `${item}`,
            }))}
            control={control}
            sx={{ width: "100%", minWidth: "100%" }}
          />
          <S.Separator height={52} />
          <PrimaryButton
            label="Select"
            sx={{ width: "100%", minWidth: "100%" }}
            onClick={handleSubmit(onSubmit)}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default MetricsAndAnalyticsDialog;
