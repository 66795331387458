import { yupResolver } from "@hookform/resolvers/yup";
import { AlertIcon, TabExternalIcon, TableIcon } from "@primer/octicons-react";
import { Pagination, Spinner } from "@primer/react";
import GiftMatchStatusText from "components/gift-match-status";
import React from "react";
import Highlighter from "react-highlight-words";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getDonations } from "store/donations/donations.action";
import { DonationsTableSortBy, IGetDonationsRequest } from "store/donations/donations.type";
import { getW9FormLink } from "store/gift-matching/gift-matching.action";
import colors from "theme/colors";
import { Order } from "types/common";
import { GiftMatchTableSort } from "types/content";
import { GiftMatch } from "types/gift-match";
import { MAX_DATE, MAX_TABLE_COLUMNS, MIN_DATE } from "utils/constants";
import { GIFT_MATCHING_TABLE_HEADING } from "utils/content";
import { formatDate, genRandomString } from "utils/helper";
import * as yup from "yup";

import CustomSearchInput from "../../../../components/custom-search-input/custom-search-input";
import * as S from "../gift-matching.styled";
import { IGiftMatchingTableProps } from "../gift-matching.type";
import CustomDatePicker from "./custom-date-picker";
import GiftMatchReceiptUrl from "./gift-match-receipt-link";

const schema = yup.object().shape(
  {
    q: yup.string().optional().nullable().default(null),
    startDate: yup
      .date()
      .nullable()
      .optional()
      .when("endDate", (endDate, _schema) => {
        if (endDate?.toString()?.length > 0) {
          return _schema
            .required("Please select start date.")
            .min(MIN_DATE, "Please select valid date.")
            .max(MAX_DATE, "Please select valid date.")
            .typeError("Please enter valid date.");
        }
        return _schema
          .nullable()
          .notRequired()
          .default(() => null);
      })
      .when({
        is: (date) => date?.toString()?.length > 0,
        then: (_schema) =>
          _schema
            .min(MIN_DATE, "Please select valid date.")
            .max(MAX_DATE, "Please select valid date.")
            .typeError("Please enter valid date."),
      }),
    endDate: yup
      .date()
      .nullable()
      .optional()
      .when("startDate", (startDate, _schema) => {
        if (startDate?.toString()?.length > 0) {
          return _schema
            .min(yup.ref("startDate"), "End date can't be before start date.")
            .max(MAX_DATE, "Please select valid date.")
            .required("Please fill in all the data.")
            .typeError("Please enter valid date.");
        }
        return _schema
          .nullable()
          .notRequired()
          .default(() => null);
      }),
  },
  [["startDate", "endDate"]],
);

const highlightStyle = { fontWeight: "700", background: colors.transparent };

const GiftMatchingTable: React.FC<IGiftMatchingTableProps> = ({
  items,
  totalItemsNum,
  displayPagination,
  currentPage,
  inProgress,
  tableType,
}): JSX.Element => {
  const dispatch = useDispatch();
  const tableHeadingRef = React.useRef<HTMLTableRowElement>(null);

  const [sortedBy, sortBy] = React.useState<DonationsTableSortBy>(null);
  const [searchedText, searchText] = React.useState<string>("");
  const [dateSelected, selectDate] = React.useState<boolean>(false);
  const [tableRows, setTableRows] = React.useState<GiftMatchTableSort[]>(
    GIFT_MATCHING_TABLE_HEADING,
  );

  const [pickerFocused, focusPicker] = React.useState<boolean>(false);

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const highlightedText = searchedText?.length < 3 ? "" : searchedText;

  const onFormClick = (donation: GiftMatch): void => {
    if (!donation.charityId && donation.ein) {
      dispatch(getW9FormLink.request({ ein: donation.ein }));
    } else if (donation.charityId && !donation.ein) {
      dispatch(getW9FormLink.request({ charityId: donation.charityId }));
    }
  };

  const {
    formState: { errors },
    handleSubmit,
    resetField,
    getValues,
    setValue,
    control,
  } = useForm({
    defaultValues: { startDate: null, endDate: null, q: "" },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (payload: Partial<IGetDonationsRequest["payload"]>): void => {
    dispatch(
      getDonations.request({
        table: tableType,
        page: 1,
        sortBy: sortedBy ?? null,
        order: tableRows.find((item) => item.sortBy === sortedBy)?.orderBy ?? null,
        ...payload,
        q: payload?.q?.length > 2 ? payload?.q : null,
        startDate: payload?.startDate ? formatDate(payload?.startDate) : null,
        endDate: payload?.endDate ? formatDate(payload?.endDate) : null,
      }),
    );
  };

  const onSubmitCallback = (payload: Partial<IGetDonationsRequest["payload"]>) =>
    handleSubmit((params) =>
      onSubmit({ ...(params as IGetDonationsRequest["payload"]), ...payload }),
    );

  const onSort = (item: GiftMatchTableSort) => {
    if (item.sortBy) {
      sortBy(item?.sortBy);
      if (item.sortBy === sortedBy) {
        const mappedRows = tableRows.map((row) =>
          row.sortBy === item.sortBy
            ? { ...row, orderBy: (row.orderBy === "asc" ? "desc" : "asc") as Order }
            : row,
        );
        setTableRows(mappedRows);
        const order = mappedRows.find((row) => row.sortBy === sortedBy)?.orderBy ?? "asc";
        onSubmitCallback({ sortBy: item.sortBy, order })();
      } else {
        const order = tableRows.find((row) => row.sortBy === sortedBy)?.orderBy ?? "asc";
        onSubmitCallback({ sortBy: item.sortBy, order })();
      }
    }
  };

  const onResetFilters = (): void => {
    sortBy(null);
    searchText("");
    selectDate(false);
    resetField("q");
    resetField("startDate");
    resetField("endDate");
    setTableRows(GIFT_MATCHING_TABLE_HEADING);
    onSubmitCallback({ page: 1, q: "", table: tableType })();
  };

  React.useEffect(() => {
    onResetFilters();
  }, []);

  return (
    <>
      <S.SearchAndFilterContainer>
        <S.SearchAndFilterInnerContainer justifyContent="flex-start" gridGap={32} flexWrap>
          <CustomSearchInput
            control={control}
            name="q"
            label="Search by employee or charity"
            error={errors?.q?.message}
            onChange={(q) => {
              searchText(q);
              onSubmitCallback({ q })();
            }}
          />

          <S.RowDisplay
            gridGap={22}
            flexWrap
            style={{ maxWidth: "100%" }}
            justifyContent="flex-start"
          >
            <S.DateRangeLabel isFocused={pickerFocused}>Pick a Date</S.DateRangeLabel>
            <S.RowDisplay
              gridGap={22}
              justifyContent="flex-start"
              style={{ maxWidth: "100%" }}
              flexWrap
            >
              <CustomDatePicker
                label="Starting"
                control={control}
                name="startDate"
                error={errors?.startDate?.message as string}
                onFocus={() => focusPicker(true)}
                onBlur={() => focusPicker(false)}
                onChange={(startDate) => {
                  if (!getValues("endDate")) {
                    resetField("endDate");
                    setValue("endDate", MAX_DATE);
                    onSubmitCallback({ startDate, endDate: MAX_DATE.toString() })();
                    selectDate(true);
                  } else {
                    onSubmitCallback({ startDate })();
                    selectDate(true);
                  }
                }}
              />
              <CustomDatePicker
                label="Ending"
                control={control}
                name="endDate"
                error={errors?.endDate?.message as string}
                onFocus={() => focusPicker(true)}
                onBlur={() => focusPicker(false)}
                onChange={(endDate) => {
                  onSubmitCallback({ endDate })();
                  selectDate(true);
                }}
              />
              {(errors?.startDate?.message ?? errors?.endDate?.message) && (
                <S.CustomInputError gridGap={4}>
                  <AlertIcon />
                  {(errors?.startDate?.message ?? errors?.endDate?.message) as string}
                </S.CustomInputError>
              )}
              <S.ResetFilters
                isDisabled={!(!!sortedBy || !!searchedText?.length || !!dateSelected)}
                onClick={
                  !!sortedBy || !!searchedText?.length || !!dateSelected ? onResetFilters : () => {}
                }
              >
                Reset filters
              </S.ResetFilters>
            </S.RowDisplay>
          </S.RowDisplay>
        </S.SearchAndFilterInnerContainer>
      </S.SearchAndFilterContainer>
      {totalItemsNum > 0 ? (
        <S.ColumnDisplay
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ maxWidth: "100%", overflowX: "auto" }}
        >
          {isTablet ? (
            <S.ColumnDisplay gridGap={16}>
              {items.map((donation) => (
                <S.Table.Wrapper key={genRandomString()}>
                  <S.Table.Body>
                    <S.Table.Row ref={tableHeadingRef}>
                      <S.Table.Heading key={genRandomString()}>
                        {tableRows[0]?.label}
                      </S.Table.Heading>
                      <S.Table.Data
                        hideBorder={items.length >= MAX_TABLE_COLUMNS}
                        isFirstElem
                        showTopBorder
                      >
                        <Highlighter
                          searchWords={[highlightedText]}
                          autoEscape
                          textToHighlight={donation?.firstName}
                          highlightStyle={highlightStyle}
                        />
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()} hideRadius>
                        {tableRows[1]?.label}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                        <Highlighter
                          searchWords={[highlightedText]}
                          autoEscape
                          textToHighlight={donation?.lastName}
                          highlightStyle={highlightStyle}
                        />
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()} hideRadius>
                        {tableRows[3]?.label}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                        <Highlighter
                          searchWords={[highlightedText]}
                          autoEscape
                          textToHighlight={donation?.charityName}
                          highlightStyle={highlightStyle}
                        />
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()} hideRadius>
                        {tableRows[2]?.label}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                        {!donation?.charityId && !donation.ein ? (
                          <S.LinkText isDisabled>Form</S.LinkText>
                        ) : (
                          <S.RowDisplay justifyContent="flex-start" gridGap={6}>
                            <TabExternalIcon />
                            <S.LinkText onClick={() => onFormClick(donation)}>Form</S.LinkText>
                          </S.RowDisplay>
                        )}
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()} hideRadius>
                        {tableRows[4]?.label}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                        {formatDate(donation?.date)}
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()} hideRadius>
                        {tableRows[5]?.label}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                        {`$${donation?.usage}`}
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()} hideRadius>
                        {tableRows[6]?.label}
                      </S.Table.Heading>
                      <S.Table.Data
                        hideBorder={items.length >= MAX_TABLE_COLUMNS}
                        style={{ color: "rgba(94, 107, 122, 1)" }}
                      >
                        {`$${donation?.amount?.toFixed(2)}`}
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()} hideRadius>
                        {tableRows[6]?.label}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                        <GiftMatchReceiptUrl donation={donation} isTablet={isTablet} />
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()} hideRadius>
                        {tableRows[7]?.label}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                        <GiftMatchStatusText donation={donation} isTablet={isTablet} isAdminView />
                      </S.Table.Data>
                    </S.Table.Row>
                  </S.Table.Body>
                </S.Table.Wrapper>
              ))}
            </S.ColumnDisplay>
          ) : (
            <S.Table.Wrapper>
              <S.Table.Body>
                <S.Table.Row ref={tableHeadingRef}>
                  {tableRows?.map((item) => (
                    <S.Table.Heading
                      key={genRandomString()}
                      style={{ textAlign: "center", cursor: item?.sortBy ? "pointer" : "auto" }}
                      onClick={() => onSort(item)}
                    >
                      <S.RowDisplay gridGap={8}>
                        {item?.label}
                        {item?.sortBy && item?.renderIcon(item.orderBy)}
                      </S.RowDisplay>
                    </S.Table.Heading>
                  ))}
                </S.Table.Row>

                {items.map((donation, i) => (
                  <S.Table.Row key={genRandomString()}>
                    <S.Table.Data
                      hideBorder={items.length >= MAX_TABLE_COLUMNS}
                      isLastElem={i === items.length - 1}
                      columnSelected={sortedBy === "firstName"}
                    >
                      <Highlighter
                        searchWords={[highlightedText]}
                        autoEscape
                        textToHighlight={donation?.firstName}
                        highlightStyle={highlightStyle}
                      />
                    </S.Table.Data>
                    <S.Table.Data
                      isLastElem={i === items.length - 1}
                      columnSelected={sortedBy === "lastName"}
                    >
                      <Highlighter
                        searchWords={[highlightedText]}
                        autoEscape
                        textToHighlight={donation?.lastName}
                        highlightStyle={highlightStyle}
                      />
                    </S.Table.Data>
                    <S.Table.Data
                      style={{ color: "rgba(94, 107, 122, 1)", fontWeight: "300" }}
                      isLastElem={i === items.length - 1}
                      columnSelected={sortedBy === "charityName"}
                    >
                      <Highlighter
                        searchWords={[highlightedText]}
                        autoEscape
                        textToHighlight={donation?.charityName}
                        highlightStyle={highlightStyle}
                      />
                    </S.Table.Data>
                    <S.Table.Data style={{ width: 114 }}>
                      {!donation?.charityId && !donation.ein ? (
                        <S.LinkText isDisabled>Form</S.LinkText>
                      ) : (
                        <S.RowDisplay alignItems="center" justifyContent="center" gridGap={6}>
                          <TabExternalIcon />
                          <S.LinkText onClick={() => onFormClick(donation)}>Form</S.LinkText>
                        </S.RowDisplay>
                      )}
                    </S.Table.Data>

                    <S.Table.Data
                      style={{
                        textAlign: "center",
                        color: "rgba(94, 107, 122, 1)",
                        fontWeight: "300",
                      }}
                      isLastElem={i === items.length - 1}
                      columnSelected={sortedBy === "date"}
                    >
                      {formatDate(donation?.date)}
                    </S.Table.Data>
                    <S.Table.Data
                      style={{
                        textAlign: "center",
                        color: "rgba(94, 107, 122, 1)",
                        fontWeight: "300",
                      }}
                      isLastElem={i === items.length - 1}
                      columnSelected={sortedBy === "date"}
                    >
                      {`$${donation?.usage}`}
                    </S.Table.Data>
                    <S.Table.Data
                      style={{
                        textAlign: "right",
                        color: "rgba(94, 107, 122, 1)",
                        fontWeight: "300",
                      }}
                      isLastElem={i === items.length - 1}
                      columnSelected={sortedBy === "amount"}
                    >{`$${donation?.amount?.toFixed(2)}`}</S.Table.Data>
                    <S.Table.Data style={{ textAlign: "center" }}>
                      <GiftMatchReceiptUrl donation={donation} isTablet={isTablet} />
                    </S.Table.Data>
                    <S.Table.Data style={{ maxWidth: 228 }}>
                      <GiftMatchStatusText donation={donation} isTablet={isTablet} isAdminView />
                    </S.Table.Data>
                  </S.Table.Row>
                ))}
              </S.Table.Body>
            </S.Table.Wrapper>
          )}

          {displayPagination && (
            <S.PaginationWrapper>
              <Pagination
                pageCount={Math.ceil(totalItemsNum / MAX_TABLE_COLUMNS)}
                currentPage={currentPage}
                showPages={!isTablet}
                onPageChange={(e, n) => {
                  e.preventDefault();
                  if (n !== currentPage) {
                    onSubmitCallback({ page: n })();
                  }
                }}
              />
            </S.PaginationWrapper>
          )}
        </S.ColumnDisplay>
      ) : (
        <S.ColumnDisplay
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ width: "100%" }}
        >
          <S.RowDisplay
            alignItems="center"
            justifyContent="space-between"
            style={{ width: "100%" }}
            flexWrap
          >
            <S.DonationsMatchingForm style={{ height: 150, minHeight: 150 }}>
              {inProgress ? (
                <Spinner />
              ) : (
                <S.NoDataText gridGap={16}>
                  <TableIcon size={48} />
                  No data to display.
                </S.NoDataText>
              )}
            </S.DonationsMatchingForm>
          </S.RowDisplay>
        </S.ColumnDisplay>
      )}
    </>
  );
};

export default GiftMatchingTable;
