import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import * as C from "./charities-search.constants";
import * as T from "./charities-search.type";

export const charitiesSearchInitial: T.ICharitiesSeaarchState = {
  data: [],
  page: 1,
  matches: 0,
  q: null,
  goal: null,
  state: null,
  lookingFor: null,
  isSearched: false,
  isEndReached: false,
  isFetched: false,
  inProgress: false,
  error: null,
};

const charitiesSearchReducer = (
  state: T.ICharitiesSeaarchState,
  action: T.CharitiesSearchAction | ILogout,
): T.ICharitiesSeaarchState => {
  if (!state) {
    return charitiesSearchInitial;
  }

  switch (action.type) {
    case C.SEARCH_CHARITIES:
      return {
        ...charitiesSearchInitial,
        ...action.payload,
        page: 1,
        inProgress: true,
      };
    case C.SHOW_MORE_SEARCHED_CHARITIES:
      return {
        ...state,
        inProgress: true,
      };
    case C.SEARCH_CHARITIES_SUCCESS:
      return {
        ...state,
        page: state.page + 1,
        data: action.payload?.charities,
        matches: action.payload.matches,
        isEndReached: action.payload.matches === action.payload?.charities?.length,
        inProgress: false,
        isSearched: true,
      };
    case C.SHOW_MORE_SEARCHED_CHARITIES_SUCCESS:
      return {
        ...state,
        page: state.page + 1,
        isEndReached: state.matches === [...state.data, ...action.payload].length,
        data: [...state.data, ...action.payload],
        inProgress: false,
        isSearched: true,
      };
    case C.SEARCH_CHARITIES_FAILURE:
    case C.SHOW_MORE_SEARCHED_CHARITIES_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.DISCARD_SEARCH_RESULTS:
    case LOGOUT:
      return charitiesSearchInitial;
    default:
      return state;
  }
};

export default charitiesSearchReducer;
