import * as api from "api/volunteer";
import { combineEpics, ofType } from "redux-observable";
import CustomHistory from "router/history";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { ACCEPTED } from "utils/status-code";

import { checkIntoVolunteerScheduledEvent } from "./event-check-in.action";
import * as C from "./event-check-in.constants";
import * as T from "./event-check-in.type";

export const checkIntoVolunteerEventEpic: AppEpic = (
  action$: Observable<T.ICheckIntoVolunteerEvent>,
) =>
  action$.pipe(
    ofType(C.CHECK_INTO_VOLUNTEER_SCHEDULED_EVENT),
    mergeMap((action) =>
      from(api.checkIntoVolunteerEvent(action.payload.id, action.payload.email)).pipe(
        mergeMap((response) => {
          if (response === ACCEPTED) {
            if (action.payload.companyName) {
              CustomHistory.navigate(`${action.payload.companyName}/check-in-success`);
            }
            return of(checkIntoVolunteerScheduledEvent.success(action.payload));
          }
          return of(
            handleError({ action, error: null }),
            checkIntoVolunteerScheduledEvent.failure(action.payload),
          );
        }),
        catchError((error) =>
          of(
            checkIntoVolunteerScheduledEvent.failure(action.payload),
            handleError({ action, error }),
          ),
        ),
      ),
    ),
  );

export default combineEpics(checkIntoVolunteerEventEpic);
