import { IPaymentSelectors } from "./payment.type";

const paymentSelectors: IPaymentSelectors = {
  selectClientSecret: (state) => state.payment.clientSecret,
  selectDonationAmount: (state) => state.payment.donatedAmount,
  selectInProgress: (state) => state.payment.inProgress,
  selectError: (state) => state.payment.error,
};

export default paymentSelectors;
