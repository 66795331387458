import PrimaryButton from "components/buttons/primary";
import FilePicker from "components/file-picker";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCompanyLogo } from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";

import * as S from "../dialogs.styled";

const DIALOG_KEY = "UPLOAD_COMPANY_LOGO";
const MAX_ALLOWED_LOGO_SIZE_MB = 2 * 1024 * 1024; // 2 MB

const UploadCompanyLogoDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const [logo, setLogo] = React.useState<File>(null);
  const [submitted, submit] = React.useState<boolean>(false);

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, DIALOG_KEY),
  );

  const inProgress = useSelector(companySelectors.selectInProgress);

  React.useEffect(() => {
    if (!isOpened) {
      submit(false);
      setLogo(null);
    }
  }, [isOpened]);

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
  };

  const onSubmit = (): void => {
    submit(true);
    if (logo) {
      dispatch(updateCompanyLogo.request({ logo }));
    }
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={60}>
          <S.GradientHeading fontSize={28}>Upload Company Logo</S.GradientHeading>
          <S.Separator height={30} />
          <S.ColumnDisplay gridGap={20} justifyContent="flex-start">
            <S.DialogSubheading>Please add your company logo to the homepage.</S.DialogSubheading>
            <S.Separator height={10} />
            <FilePicker
              file={logo}
              setFile={setLogo}
              buttonLabel="Upload a logo"
              supportedTypes="image/png, image/jpg, image/jpeg, image/bmp"
              pickerLabel="No file chosen."
              isErrorMode={submitted && !logo}
              maxFileSize={MAX_ALLOWED_LOGO_SIZE_MB}
            />
            <S.Separator height={10} />
            <S.DialogLabel fontSize={14}>
              Maximum size is 2MB. We recommend size 100x100px up to 500x500px
            </S.DialogLabel>
          </S.ColumnDisplay>

          <S.Separator height={50} />
          <PrimaryButton
            label="Submit"
            onClick={onSubmit}
            isLoading={inProgress}
            sx={{ width: "100%", maxWidth: "391px", alignSelf: "center" }}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default UploadCompanyLogoDialog;
