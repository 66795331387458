import SecondaryButton from "components/buttons/secondary";
import { CharityIcon, QRIcon } from "icons";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import { VolunteerEventItem } from "types/volunteer";
import { formatDate, formatTime, verifyImg } from "utils/helper";
import routes from "utils/routes";

import * as S from "./volunteer-event-card.styled";

export interface IVolunteerEventCardProps {
  isBigCard?: boolean;
  isAdminView?: boolean;
}

const btnStyle = {
  maxWidth: "100%",
  width: "230px",
  alignSelf: "center",
};

const VolunteerEventCard: React.FC<VolunteerEventItem & IVolunteerEventCardProps> = ({
  id,
  isAdminView,
  name,
  isBigCard = false,
  charityLogoUrl,
  startDateTime,
  endDateTime,
  status,
  charityName,
  attending,
  atCapacity,
}): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [imgValid, setImgValid] = React.useState<boolean>(true);

  const onCardClick = (): void => {
    navigate(`${routes.VOLUNTEER.EVENT}/${id}`);
  };

  const viewSignUps = (): void => {
    navigate(`${routes.VOLUNTEER.EVENT_ATTENDEES}/${id}/1`);
  };

  React.useEffect(() => {
    verifyImg(charityLogoUrl, setImgValid);
  }, [charityLogoUrl]);

  return (
    <S.VolunteerEventCardContainer isBigCard={isBigCard}>
      <S.VolunteerEventCardGradientContainer />
      <S.VolunteerEventCardInnerContainer
        alignItems="flex-start"
        justifyContent="space-between"
        isBigCard={isBigCard}
      >
        <S.RowDisplay justifyContent="space-between" alignItems="flex-start" width="100%">
          <S.ColumnDisplay alignItems="flex-start">
            {charityLogoUrl && imgValid ? (
              <S.VolunteerEventImg src={charityLogoUrl} alt="" />
            ) : (
              <CharityIcon height={44} />
            )}
            <S.Separator height={12} />
            <S.VolunteerEventName>{name}</S.VolunteerEventName>
          </S.ColumnDisplay>
          {isAdminView && status === "ACTIVE" && (
            <S.QRContainer
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  updateDialogState({
                    key: "QR_CODE",
                    isOpened: true,
                    properties: {
                      id,
                      charityName,
                      eventName: name,
                      date: formatDate(startDateTime, "MMM Do, YYYY"),
                      startTime: formatTime(startDateTime),
                      endTime: formatTime(endDateTime),
                    },
                  }),
                );
              }}
            >
              <QRIcon width={28} height={28} />
              QR Code
            </S.QRContainer>
          )}
        </S.RowDisplay>
        <S.Separator height={30} />
        <S.Separator height={6} />
        {startDateTime && (
          <S.VolunteerEventAddress>
            {`${formatDate(startDateTime, "MMM Do, YYYY")} at ${formatTime(startDateTime)}`}
          </S.VolunteerEventAddress>
        )}
        <S.Separator height={30} />
        {atCapacity && <S.EventBannerFull>Event Full</S.EventBannerFull>}

        <S.ColumnDisplay gridGap={8} style={btnStyle}>
          <SecondaryButton
            label={`View Event & ${!attending ? "" : "Cancel "}Sign-up`}
            onClick={onCardClick}
            sx={btnStyle}
          />
          {isAdminView && (
            <SecondaryButton label="View sign ups" onClick={viewSignUps} sx={btnStyle} />
          )}
        </S.ColumnDisplay>
      </S.VolunteerEventCardInnerContainer>
    </S.VolunteerEventCardContainer>
  );
};

VolunteerEventCard.defaultProps = {
  isBigCard: false,
  isAdminView: false,
};

export default VolunteerEventCard;
