import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import { sendEmailMessageToAttendees } from "store/volunteer-attendees/volunteer-attendees.action";
import * as yup from "yup";

import * as S from "../dialogs.styled";

const DIALOG_KEY = "MESSAGE_EMPLOYEES";
const MAX_CHARACTERS = 500;
const MIN_CHARACTERS = 2;

const messageSchema = yup.object().shape({
  messageText: yup
    .string()
    .required("Please fill message field.")
    .min(MIN_CHARACTERS, `Please enter at least ${MIN_CHARACTERS} characters.`)
    .max(MAX_CHARACTERS, `Please enter ${MAX_CHARACTERS} or less characters.`),
});

const MessageEmployeesDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, DIALOG_KEY),
  );
  const properties = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogProperties(state, DIALOG_KEY),
  );
  const id = properties?.id || "";

  const {
    formState: { errors },
    handleSubmit,
    resetField,
    control,
  } = useForm({
    defaultValues: { messageText: "" },
    resolver: yupResolver(messageSchema),
    mode: "onTouched",
  });

  React.useEffect(() => {
    if (isOpened) {
      resetField("messageText");
    }
  }, [isOpened]);

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
  };

  const onSubmit = ({ messageText }): void => {
    if (id && messageText) {
      dispatch(sendEmailMessageToAttendees.request({ id, text: messageText }));
    }
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={66}>
          <S.GradientHeading fontSize={28}>Message Employees Signed Up</S.GradientHeading>
          <S.Separator height={58} />
          <Input
            topLabel="Contact all employees registered for this event:"
            sx={{ width: "100%", minHeight: "156px" }}
            control={control}
            isOptionalVisible={false}
            multiline
            errorMessage={errors?.messageText?.message}
            name="messageText"
          />
          <S.Separator height={52} />
          <PrimaryButton
            label="Send"
            sx={{ width: "100%", maxWidth: "395px" }}
            onClick={handleSubmit(onSubmit)}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default MessageEmployeesDialog;
