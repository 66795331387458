import { ArrowLeftIcon, MailIcon, PencilIcon, PlusIcon } from "@primer/octicons-react";
import { Spinner } from "@primer/react";
import GrayButton from "components/buttons/gray";
import SmallButton from "components/buttons/small";
import FixedHeader from "components/fixed-header";
import Tag from "components/tag";
import { EmptyInboxIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import companySelectors from "store/company/company.selector";
import { fetchUserDashboardSummary } from "store/employee-dashboard-summary/employee-dashboard-summary.action";
import employeeDashboardSummarySelectors from "store/employee-dashboard-summary/employee-dashboard-summary.selector";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import userSelectors from "store/user/user.selector";
import { fetchUserDonations } from "store/user-donations/user-donations.action";
import userDonationsSelectors from "store/user-donations/user-donations.selector";
import colors from "theme/colors";
import { CHARITY_MAILTO, MAX_TABLE_COLUMNS } from "utils/constants";
import routes from "utils/routes";

import * as S from "./dashboard.styled";
import RecentActivityTable from "./recent-activity-table";

const EmployeeDashboard: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const companyName = useSelector(companySelectors.selectName);
  const employee = useSelector(userSelectors.selectData);

  const interests = employee.interests ?? [];

  const donations = useSelector(userDonationsSelectors.selectData);
  const totalDonationsNum = useSelector(userDonationsSelectors.selectResults);
  const page = useSelector(userDonationsSelectors.selectPage);
  const inProgress = useSelector(userDonationsSelectors.selectInProgress);
  const isFetched = useSelector(userDonationsSelectors.selectIsFetched);

  const summary = useSelector(employeeDashboardSummarySelectors.selectData);
  const summaryProgress = useSelector(employeeDashboardSummarySelectors.selectInProgress);

  React.useEffect(() => {
    dispatch(fetchUserDonations.request({ page: 1 }));
    dispatch(fetchUserDashboardSummary.request());
  }, []);

  return (
    <S.EmployeeDashboardContainer justifyContent="flex-start">
      <FixedHeader name={companyName} />
      <S.EmployeeDashboardInnerContainer justifyContent="flex-start" gridGap={28}>
        <S.RowDisplayFullWidth justifyContent="space-between" gridGap={20}>
          <div />
          <GrayButton
            label="Refer a charity"
            onClick={(e) => {
              window.location.href = CHARITY_MAILTO;
              e.preventDefault();
            }}
            sx={{ width: "173px" }}
            leadingIcon={MailIcon}
          />
        </S.RowDisplayFullWidth>

        <S.DashboardWhiteFrame justifyContent="space-between" alignItems="center" gridGap={40}>
          <S.DashboardWhiteFrameFlexContainer style={{ gridColumnGap: 120, gridRowGap: 40 }}>
            <S.ColumnDisplay gridGap={20}>
              <S.EmployeeName>{`${employee?.firstName} ${employee?.lastName}`}</S.EmployeeName>
              <S.EmployeeRole>{employee?.department ?? "Marketing & Sales"}</S.EmployeeRole>
              <S.CustomButton
                label="Edit"
                onClick={() => dispatch(updateDialogState({ key: "EDIT_PROFILE", isOpened: true }))}
                leadingIcon={PencilIcon}
              />
              <S.CustomButton
                label={employee?.interests?.length !== 0 ? "Edit Interests" : "Add Interests"}
                onClick={() =>
                  dispatch(updateDialogState({ key: "UPDATE_INTERESTS", isOpened: true }))
                }
                leadingIcon={PlusIcon}
              />
              <S.UserInterestsContainer>
                {interests.map((interest) => (
                  <Tag
                    text={interest}
                    isChecked={false}
                    isGradient={false}
                    style={{
                      margin: "1.5px",
                      color: colors.unitedNationsBlue(),
                      background: colors.ghostWhite(),
                    }}
                  />
                ))}
              </S.UserInterestsContainer>
            </S.ColumnDisplay>

            <S.RowDisplay flexWrap style={{ flex: 1 }} justifyContent="space-around" gridGap={20}>
              <S.EmployeeDashboardBubble gridGap={8}>
                <S.EmployeeDashboardNumber color="rgba(116, 233, 219, 1)">
                  {summaryProgress ? <Spinner /> : <>{summary.numCharities}</>}
                </S.EmployeeDashboardNumber>
                <S.EmployeeDashboardLabel>Charities supported</S.EmployeeDashboardLabel>
              </S.EmployeeDashboardBubble>

              <S.EmployeeDashboardBubble gridGap={8}>
                <S.EmployeeDashboardNumber
                  color={colors.unitedNationsBlue()}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <span style={{ fontSize: 20, fontWeight: "400", marginRight: 4 }}>$</span>
                  {summaryProgress ? <Spinner /> : <>{summary.totalDonationValue || 0}</>}
                </S.EmployeeDashboardNumber>
                <S.EmployeeDashboardLabel>Total amount donated</S.EmployeeDashboardLabel>
                <S.EmployeeDashboardBottomLabel>(on Fundwurx)</S.EmployeeDashboardBottomLabel>
              </S.EmployeeDashboardBubble>

              <S.EmployeeDashboardBubble gridGap={8}>
                <S.EmployeeDashboardNumber
                  color="rgba(116, 233, 219, 1)"
                  style={{ fontWeight: "800" }}
                >
                  {summaryProgress ? (
                    <Spinner />
                  ) : (
                    <>
                      <span style={{ fontWeight: "400" }}>$</span>
                      {`${summary.totalMatchedValue}/${summary.companyGiftMatchLimit}`}
                    </>
                  )}
                </S.EmployeeDashboardNumber>
                <S.EmployeeDashboardLabel>Gift Match Usage</S.EmployeeDashboardLabel>
              </S.EmployeeDashboardBubble>
            </S.RowDisplay>
          </S.DashboardWhiteFrameFlexContainer>
        </S.DashboardWhiteFrame>

        <S.DashboardWhiteFrame justifyContent="flex-start" alignItems="flex-start" gridGap={20}>
          {!totalDonationsNum && (
            <S.ColumnDisplay width="100%" gridGap={16} alignItems="flex-start">
              <S.RecentActivityHeading>Donation activity</S.RecentActivityHeading>
              <SmallButton
                label="Request off platform gift match"
                sx={{ borderRadius: 26, color: colors.white(), background: "#6185E2", height: 32 }}
                onClick={() =>
                  dispatch(updateDialogState({ key: "OFF_PLATFORM_DONATION", isOpened: true }))
                }
              />
            </S.ColumnDisplay>
          )}
          {inProgress && !isFetched ? (
            <S.ColumnDisplay style={{ width: "100%", height: 400 }}>
              <Spinner />
            </S.ColumnDisplay>
          ) : (
            <>
              {!totalDonationsNum ? (
                <S.EmptyContainer gridGap={24}>
                  <EmptyInboxIcon />
                  <S.EmptyHeading>You don’t have any recent activities.</S.EmptyHeading>
                  <S.EmptySubheading>
                    Once you make your first donation to a charity, it will immediately appear here
                    on the list.
                  </S.EmptySubheading>
                </S.EmptyContainer>
              ) : (
                <>
                  <S.RecentActivityHeading>Donation activity</S.RecentActivityHeading>
                  <SmallButton
                    label="Request off platform gift match"
                    sx={{ borderRadius: 26, color: colors.unitedNationsBlue(), height: 32 }}
                    onClick={() =>
                      dispatch(updateDialogState({ key: "OFF_PLATFORM_DONATION", isOpened: true }))
                    }
                  />
                  <RecentActivityTable
                    items={donations}
                    totalItemsNum={totalDonationsNum}
                    displayPagination={totalDonationsNum > MAX_TABLE_COLUMNS}
                    currentPage={page}
                    onLoadMore={(n) => dispatch(fetchUserDonations.request({ page: n }))}
                  />
                </>
              )}
            </>
          )}
        </S.DashboardWhiteFrame>
      </S.EmployeeDashboardInnerContainer>
    </S.EmployeeDashboardContainer>
  );
};

export default EmployeeDashboard;
