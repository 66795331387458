export const FETCH_VOLUNTEER_EVENT = "FETCH_VOLUNTEER_EVENT";
export const FETCH_VOLUNTEER_EVENT_SUCCESS = "FETCH_VOLUNTEER_EVENT_SUCCESS";
export const FETCH_VOLUNTEER_EVENT_FAILURE = "FETCH_VOLUNTEER_EVENT_FAILURE";

export const ATTEND_VOLUNTEER_EVENT = "ATTEND_VOLUNTEER_EVENT";
export const ATTEND_VOLUNTEER_EVENT_SUCCESS = "ATTEND_VOLUNTEER_EVENT_SUCCESS";
export const ATTEND_VOLUNTEER_EVENT_FAILURE = "ATTEND_VOLUNTEER_EVENT_FAILURE";

export const UNATTEND_VOLUNTEER_EVENT = "UNATTEND_VOLUNTEER_EVENT";
export const UNATTEND_VOLUNTEER_EVENT_SUCCESS = "UNATTEND_VOLUNTEER_EVENT_SUCCESS";
export const UNATTEND_VOLUNTEER_EVENT_FAILURE = "UNATTEND_VOLUNTEER_EVENT_FAILURE";

export const REQUEST_VOLUNTEER_EVENT_INTRO = "REQUEST_VOLUNTEER_EVENT_INTRO";
export const REQUEST_VOLUNTEER_EVENT_INTRO_SUCCESS = "REQUEST_VOLUNTEER_EVENT_INTRO_SUCCESS";
export const REQUEST_VOLUNTEER_EVENT_INTRO_FAILURE = "REQUEST_VOLUNTEER_EVENT_INTRO_FAILURE";

export const FETCH_VOLUNTEER_EVENT_ATTENDING = "FETCH_VOLUNTEER_EVENT_ATTENDING";
export const FETCH_VOLUNTEER_EVENT_ATTENDING_SUCCESS = "FETCH_VOLUNTEER_EVENT_ATTENDING_SUCCESS";
export const FETCH_VOLUNTEER_EVENT_ATTENDING_FAILURE = "FETCH_VOLUNTEER_EVENT_ATTENDING_FAILURE";
