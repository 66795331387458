import { cacheSlice } from "store/store.cache";

import { IUserState } from "./user.type";

export const cacheUser = (initialState: IUserState, state: IUserState): IUserState => {
  const cachedState: IUserState = {
    ...initialState,
    isCached: true,
    data: state.data,
  };

  cacheSlice("user", cachedState);

  return {
    ...state,
    isCached: true,
  };
};

export const clearUserCache = (initialState: IUserState): IUserState => {
  cacheSlice("user", initialState);
  return initialState;
};
