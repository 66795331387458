import PrimaryButton from "components/buttons/primary";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useSelector } from "react-redux";
import charitySelectors from "store/charity/charity.selector";
import colors from "theme/colors";
import { contactSupport, getErrorCode, getErrorMessage } from "utils/helper";

import * as S from "../charity-auth.styled";

const CharityVerificationFailedPage: React.FC<{}> = (): JSX.Element => {
  const error = useSelector(charitySelectors.selectError);

  const errorMessage = getErrorMessage(error);
  const errorCode = getErrorCode(error);

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <div />
      <S.FormWrapper>
        <S.HeadingBlack>Charity verification failed</S.HeadingBlack>
        <S.Separator height={28} />
        <S.Paragraph color={colors.gunmetal()} fontSize={16}>
          {errorCode === "CHARITY_NOT_APPLICABLE_ERROR" ? (
            errorMessage
          ) : (
            <>
              We are sorry but we could not validate your
              <br />
              nonprofit organization.
            </>
          )}
        </S.Paragraph>
        <S.Separator height={75} />
        <S.FadedRedArea gridGap={16}>
          <S.BordoText gridGap={12}>
            <div>
              {errorCode === "CHARITY_NOT_APPLICABLE_ERROR"
                ? "Not applicable"
                : "Not in good standing"}
            </div>
            <div style={{ fontSize: 28 }}>🛑</div>
          </S.BordoText>
          {errorCode === "CHARITY_STANDING_ERROR" && (
            <S.ErrorText color={colors.gunmetal()} fontSize={16}>
              {errorMessage}
            </S.ErrorText>
          )}
        </S.FadedRedArea>
        <S.Separator height={55} />
        <PrimaryButton
          label="Contact Support"
          sx={{ width: "100%", minWidth: "100%" }}
          onClick={contactSupport}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default CharityVerificationFailedPage;
