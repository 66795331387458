import { IToken } from "types/auth";
import { ServerResponse } from "types/common";
import { User, UserDetailsPayload, UserInterestsPayload } from "types/user";
import {
  UserDonationPayload,
  UserDonationResponse,
  UserDonationsSummary,
} from "types/user-donation";
import { genUrlParams } from "utils/helper";

import client from "./client";

export const verifyEmail = async (body: IToken): Promise<ServerResponse<boolean>> =>
  client.post(genUrlParams("/user/verifyEmail", body));

export const fetchUser = async (): Promise<ServerResponse<ServerResponse<User>>> =>
  client.get("/user");

export const editEmployeeDetails = async (
  body: UserDetailsPayload,
): Promise<ServerResponse<User>> => (await client.put("/user", body)).data;

export const updateInterests = async (body: UserInterestsPayload): Promise<ServerResponse<User>> =>
  (await client.put("/user/interests", body)).data;

export const fetchUserDonations = async (
  payload: UserDonationPayload,
): Promise<ServerResponse<UserDonationResponse>> =>
  (await client.get(genUrlParams("/user/donations", payload))).data;

export const fetchEmployeeDashboardSummary = async (): Promise<
  ServerResponse<UserDonationsSummary>
> => (await client.get("/user/donations/summary")).data;

export const toggleGiftMatchNotification = async (active: boolean): Promise<ServerResponse<User>> =>
  (await client.patch("/user/notifications/giftMatch", { active })).data;
