import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { INITIALIZE_FINCH_SUCCESS } from "store/finch/finch.constants";
import { IInitializeFinchSuccess } from "store/finch/finch.type";
import { SETUP_PAYMENT_METHOD_SUCCESS } from "store/payment/payment.constants";
import { ISetupPaymentMethodSuccess } from "store/payment/payment.type";

import { cacheComapny, clearComapnyCache } from "./company.cache";
import * as C from "./company.constants";
import * as T from "./company.type";

export const companyInitial: T.ICompanyState = {
  inProgress: false,
  isCached: false,
  error: null,
  data: {
    id: null,
    name: null,
    domain: null,
    createdAt: null,
    updatedAt: null,
    bankAccountVerified: false,
    finchKeyAdded: false,
    creator: null,
    logoUrl: null,
    calendarId: null,
  },
  dto: null,
  summary: null,
  interests: null,
  charitiesSupported: null,
  donationAnalytics: null,
  giftMatchingAnalytics: null,
  volunteeringAnalytics: null,
};

const cache = (state: T.ICompanyState) => cacheComapny(companyInitial, state);

const companyReducer = (
  state: T.ICompanyState,
  action: T.CompanyAction | ISetupPaymentMethodSuccess | IInitializeFinchSuccess | ILogout,
): T.ICompanyState => {
  if (!state) {
    return companyInitial;
  }

  switch (action.type) {
    case C.CONFIRM_REGISTRATION:
    case C.CHECK_COMPANY_INFO:
    case C.REGISTER_COMPANY:
    case C.REGISTER_EMPLOYEE:
    case C.GET_COMPANY_BY_ID:
      return {
        ...state,
        inProgress: true,
      };
    case C.CHECK_COMPANY_EXISTS:
      return {
        ...state,
        inProgress: true,
        data: null,
      };
    case C.CONFIRM_REGISTRATION_SUCCESS:
    case C.REGISTER_COMPANY_SUCCESS:
    case C.REGISTER_EMPLOYEE_SUCCESS:
    case C.CHECK_COMPANY_INFO_SUCCESS:
    case C.CONFIRM_REGISTRATION_FAILURE:
    case C.REGISTER_COMPANY_FAILURE:
    case C.REGISTER_EMPLOYEE_FAILURE:
    case C.CHECK_COMPANY_INFO_FAILURE:
    case C.GET_COMPANY_BY_ID_FAILURE:
    case C.REMOVE_FROM_COMPANY_WALL_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.GET_COMPANY_BY_ID_SUCCESS:
      return cache({
        ...state,
        inProgress: false,
        data: {
          ...action.payload,
          logoUrl: action.payload.logoUrl ? `${action.payload.logoUrl}?date=${new Date()}` : null,
        },
      });
    case SETUP_PAYMENT_METHOD_SUCCESS:
      return cache({
        ...state,
        data: {
          ...state.data,
          bankAccountVerified: true,
        },
      });
    case INITIALIZE_FINCH_SUCCESS:
      return { ...state, data: { ...state.data, finchKeyAdded: true } };
    case C.GET_DASHBOARD_SUMMARY:
    case C.GET_ANALYTIC_DASHBOARD_SUMMARY:
    case C.GET_EMPLOYEE_INTERESTS:
    case C.GET_CHARITIES_SUPPORTED:
    case C.GET_DONATION_ANALYTICS:
    case C.GET_GIFT_MATCHING_ANALYTICS:
    case C.GET_VOLUNTEERING_ANALYTICS:
      return {
        ...state,
        inProgress: true,
      };
    case C.GET_DASHBOARD_SUMMARY_SUCCESS:
    case C.GET_ANALYTIC_DASHBOARD_SUMMARY_SUCCESS:
      return cache({
        ...state,
        summary: { ...state.summary, ...action.payload },
        inProgress: false,
      });
    case C.GET_EMPLOYEE_INTERESTS_SUCCESS:
      return cache({
        ...state,
        interests: action.payload,
        inProgress: false,
      });
    case C.GET_CHARITIES_SUPPORTED_SUCCESS:
      return cache({
        ...state,
        charitiesSupported: action.payload,
        inProgress: false,
      });
    case C.GET_DONATION_ANALYTICS_SUCCESS:
      return cache({
        ...state,
        donationAnalytics: action.payload,
        inProgress: false,
      });
    case C.GET_GIFT_MATCHING_ANALYTICS_SUCCESS:
      return cache({
        ...state,
        giftMatchingAnalytics: action.payload,
        inProgress: false,
      });
    case C.GET_VOLUNTEERING_ANALYTICS_SUCCESS:
      return cache({
        ...state,
        volunteeringAnalytics: action.payload,
        inProgress: false,
      });
    case C.GET_DASHBOARD_SUMMARY_FAILURE:
    case C.GET_ANALYTIC_DASHBOARD_SUMMARY_FAILURE:
    case C.GET_EMPLOYEE_INTERESTS_FAILURE:
    case C.GET_CHARITIES_SUPPORTED_FAILURE:
    case C.GET_DONATION_ANALYTICS_FAILURE:
    case C.GET_GIFT_MATCHING_ANALYTICS_FAILURE:
    case C.GET_VOLUNTEERING_ANALYTICS_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.CHECK_COMPANY_EXISTS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: action.payload
          ? {
              ...state.data,
              id: action.payload.id,
              domain: action.payload.domain,
              name: action.payload.name,
              logoUrl: action.payload.logoUrl
                ? `${action.payload.logoUrl}?date=${new Date()}`
                : null,
            }
          : null,
      };
    case C.CHECK_COMPANY_EXISTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        data: null,
      };
    case C.UPDATE_COMPANY_LOGO:
      return {
        ...state,
        inProgress: true,
      };
    case C.UPDATE_COMPANY_LOGO_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.UPDATE_COMPANY_LOGO_SUCCESS:
      return cache({
        ...state,
        inProgress: false,
        data: {
          ...state.data,
          logoUrl: `${action.payload.data}?date=${new Date()}`,
        },
      });
    case C.DELETE_COMPANY_LOGO:
      return cache({
        ...state,
        data: {
          ...state.data,
          logoUrl: null,
        },
      });
    case C.SAVE_REGISTRATION_DTO:
      return {
        ...state,
        dto: {
          ...state.dto,
          ...action.payload,
        },
      };
    case C.RESET_REGISTRATION_DTO:
      return {
        ...state,
        dto: null,
      };
    case LOGOUT:
      return {
        ...clearComapnyCache(companyInitial),
        data: { ...companyInitial.data, name: state.data?.name ?? "" },
      };
    default:
      return state;
  }
};

export default companyReducer;
