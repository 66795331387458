import styled from "styled-components";
import { RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export const TABLET_BREAKPOINT = breakpoints.desktopS.x;
export const MOBILE_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;
export const MOBILE_SMALL_BREAKPOINT = breakpoints.iPhone5.y;

export const FixedHeaderContainer = styled.div`
  max-width: 1708px;
  min-width: 300px;
  height: 81px;
  max-height: 81px;
  box-sizing: border-box;
  width: calc(100vw - 2 * 20px);
  align-self: center;
  border-radius: 40px;
  box-shadow: 0px 4px 45px rgba(21, 41, 93, 0.1);
  box-sizing: border-box;
  background: ${({ theme }) => theme.white()};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  flex: 1;

  @media all and (max-width: ${MOBILE_SMALL_BREAKPOINT}px) {
    width: 100vw;
    border-radius: 0px;
    margin: 0px;
  }
`;

export const FixedHeaderInnerContainer = styled(RowDisplay)`
  padding: 20px 140px;
  box-sizing: border-box;
  width: 100%;

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 20px 40px;
  }
`;
