import styled from "styled-components";
import { ColumnDisplay, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export { ClickableWrapper, ColumnDisplay, RowDisplay } from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const NavigationMenuWrapper = styled(ColumnDisplay)`
  position: relative;
  z-index: 3;
`;

export const InitialsWrapper = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.aliceBlue()};
  border: 1px solid ${({ theme }) => theme.unitedNationsBlue(0.25)};
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.unitedNationsBlue()};
`;

export const DropdownWrapper = styled.div<{ width: number; isLeft: boolean }>`
  height: auto;
  margin-top: 45px;
  display: flex;
  top: 0;
  position: absolute;
  box-shadow: 0px 5px 20px 0px #424a534d;
  border-radius: 6px;
  background: ${({ theme }) => theme.white()};
  min-width: ${({ width }) => width}px;
  max-width: 100%;
  overflow: hidden;
  ${({ isLeft }) => (isLeft ? "right" : "left")}: 0;
`;

export const DropdownItem = styled(RowDisplay)`
  width: 100%;
  height: auto;
  padding: 16px;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;

  &:hover {
    background: ${({ theme }) => theme.lightSilver(0.3)};
  }
`;
