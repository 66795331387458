import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import { cacheUserDonations, clearUserDonationsCache } from "./user-donations.cache";
import * as C from "./user-donations.constants";
import * as T from "./user-donations.type";

export const userDonationsInitial: T.IUserDonationsState = {
  data: [],
  inProgress: false,
  isFetched: false,
  isCached: false,
  isEndReached: false,
  page: 0,
  results: 0,
  error: null,
};

const cache = (state: T.IUserDonationsState) => cacheUserDonations(userDonationsInitial, state);

const userDonationsReducer = (
  state: T.IUserDonationsState,
  action: T.UserDonationsAction | ILogout,
): T.IUserDonationsState => {
  if (!state) {
    return userDonationsInitial;
  }

  switch (action.type) {
    case C.FETCH_USER_DONATIONS:
      return {
        ...state,
        page: action.payload.page,
        inProgress: true,
      };
    case C.FETCH_USER_DONATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.donations,
        page: action.payload.page,
        results: action.payload.results,
        inProgress: false,
        isFetched: true,
      };

    case C.FETCH_USER_DONATIONS_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case LOGOUT:
      return clearUserDonationsCache(userDonationsInitial);
    default:
      return state;
  }
};

export default userDonationsReducer;
