import React from "react";
import { Charity } from "types/charity";

import CharityAdminCard from "./admin";
import * as T from "./charity-card.type";
import CharityEmployeeCard from "./employee";

const CharityCard: React.FC<Charity & T.ICharityCardProps> = (props): JSX.Element =>
  props?.isAdminView ? <CharityAdminCard {...props} /> : <CharityEmployeeCard {...props} />;

export default CharityCard;
