import { Autocomplete, FormControl, Text } from "@primer/react";
import * as T from "components/input/input.type";
import React, { useMemo } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Controller } from "react-hook-form";
import colors from "theme/colors";

const AddressAutocompleteInput: React.FC<T.IInputProps> = React.forwardRef<any, T.IInputProps>(
  (
    {
      topLabel,
      bottomLabel,
      placeholder,
      isRequired,
      errorMessage,
      control,
      type = "text",
      name,
      sx,
      autoFocus,
      size,
      leadingVisual,
      isReadOnly = false,
      onFocus,
      onBlurCallback,
      onEnter,
      isOptionalVisible = true,
      isDisabled = false,
    },
    ref,
  ): JSX.Element => {
    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
      apiKey: window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_PLACES_API_KEY,
    });

    const handleKeyDown = (event) => {
      if (event.key === "Enter" && !!onEnter) {
        onEnter();
      }
    };

    const handleOnChange = (e, formChange): void => {
      getPlacePredictions({ input: e.target.value, componentRestrictions: { country: "US" } });
      formChange(e);
    };

    const menuItems = useMemo(
      () =>
        placePredictions.map((prediction, index) => ({
          id: index.toString(),
          value: prediction.description,
          text: prediction.description,
        })),
      [placePredictions],
    );

    return (
      <FormControl sx={{ width: "100%" }}>
        <FormControl.Label sx={{ fontSize: 15, color: colors.gunmetal() }}>
          {topLabel}
          {topLabel !== null && (
            <>
              {isRequired ? (
                <span style={{ color: colors.bordo() }}>{" *"}</span>
              ) : (
                isOptionalVisible && (
                  <span style={{ fontStyle: "italic", fontWeight: "400" }}>{" (optional)"}</span>
                )
              )}
            </>
          )}
        </FormControl.Label>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange: formChange, onBlur } }) => (
            <Autocomplete>
              <Autocomplete.Input
                sx={{
                  width: 391,
                  minWidth: 322,
                  height: "53px",
                  color: colors.gunmetal(),
                  fontSize: 16,
                  fontWeight: "600",
                  boxSizing: "border-box",
                  ...sx,
                  paddingLeft: "8px",
                  paddingRight: "32px",
                  opacity: isReadOnly || isDisabled ? 0.5 : 1,
                  backgroundColor: isDisabled ? colors.disabledGray(0.2) : colors.white(),
                }}
                leadingVisual={leadingVisual}
                autoFocus={autoFocus}
                onFocus={onFocus}
                type={type}
                value={value}
                placeholder={placeholder}
                size={size || "large"}
                validationStatus={errorMessage ? "error" : null}
                onBlur={() => {
                  onBlur();
                  onBlurCallback?.();
                }}
                ref={ref}
                onKeyDown={handleKeyDown}
                onChange={(e) => handleOnChange(e, formChange)}
                data-testid="autocompleteInput"
              />
              {value.length > 0 && (
                <Autocomplete.Overlay portalContainerName="outerContainer" width="medium">
                  <Autocomplete.Menu
                    items={menuItems}
                    selectedItemIds={[]}
                    aria-labelledby="autocompleteLabel"
                    loading={isPlacePredictionsLoading}
                    selectionVariant="single"
                    onSelectedChange={(selectedItem) =>
                      selectedItem && formChange(selectedItem[0]?.value)
                    }
                    emptyStateText="No location suggestions found."
                  />
                </Autocomplete.Overlay>
              )}
            </Autocomplete>
          )}
        />
        {(errorMessage ?? bottomLabel) && (
          <Text sx={{ fontSize: 12, color: !errorMessage ? colors.gunmetal() : colors.bordo() }}>
            {errorMessage ?? bottomLabel}
          </Text>
        )}
      </FormControl>
    );
  },
);

export default AddressAutocompleteInput;
