import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { MAX_PROMOTED_EVENTS } from "utils/constants";

import * as C from "./volunteer-scheduled.constants";
import * as T from "./volunteer-scheduled.type";

const defaultDataState = {
  data: [],
  inProgress: false,
  isFetched: false,
  isEndReached: false,
  page: 0,
  results: 0,
  error: null,
};

export const volunteerScheduledInitial: T.IVolunteerScheduledState = {
  data: {
    ACTIVE: defaultDataState,
    PENDING: defaultDataState,
    "ACTIVE/PAST": defaultDataState,
  },
  filters: {
    grant: null,
    city: "",
    state: null,
    groupSize: [],
    location: [],
  },
};

const volunteerScheduledReducer = (
  state: T.IVolunteerScheduledState,
  action: T.VolunteerScheduledAction | ILogout,
): T.IVolunteerScheduledState => {
  if (!state) {
    return volunteerScheduledInitial;
  }

  switch (action.type) {
    case C.FETCH_VOLUNTEER_SCHEDULED_EVENTS:
    case C.FETCH_MORE_VOLUNTEER_SCHEDULED_EVENTS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.status]: {
            ...state.data[action.payload.status],
            page: action.payload.params?.page ?? 1,
            inProgress: true,
            isFetched: true,
          },
        },
      };
    case C.FETCH_VOLUNTEER_SCHEDULED_EVENTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.status]: {
            data: action.payload.params.data,
            page: action.payload.params.page,
            results: action.payload.params.results,
            inProgress: false,
            isEndReached: Object.keys(action.payload.params.data).length < MAX_PROMOTED_EVENTS,
          },
        },
      };
    case C.FETCH_MORE_VOLUNTEER_SCHEDULED_EVENTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.status]: {
            ...state.data[action.payload.status],
            data: [...state.data[action.payload.status].data, ...action.payload.params.data], // Append new data
            page: action.payload.params.page,
            results: action.payload.params.results,
            inProgress: false,
            isEndReached: Object.keys(action.payload.params.data).length < MAX_PROMOTED_EVENTS,
          },
        },
      };
    case C.FETCH_VOLUNTEER_SCHEDULED_EVENTS_FAILURE:
    case C.FETCH_MORE_VOLUNTEER_SCHEDULED_EVENTS_FAILURE:
      return {
        ...state,
        [action.payload.status]: {
          ...state.data[action.payload.status],
          inProgress: false,
          page: 1,
        },
      };
    case LOGOUT:
      return volunteerScheduledInitial;
    default:
      return state;
  }
};

export default volunteerScheduledReducer;
