import { combineEpics, ofType } from "redux-observable";
import { Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { logout } from "store/auth/auth.action";
import { AppEpic } from "store/store.state";
import { showToast } from "store/ui-components/ui-components.actions";
import { mapErrorCode } from "utils/error-mapper";
import { getErrorCode } from "utils/helper";
import { FEATURE_NOT_IMPLEMENTED } from "utils/status-code";

import * as C from "./error-handler.constants";
import * as T from "./error-handler.type";

const handleErrorEpic: AppEpic = (action$: Observable<T.IHandleServerError>) =>
  action$.pipe(
    ofType(C.HANDLE_ERROR),
    mergeMap((action) => {
      const code = getErrorCode(action.payload.error);
      const { status } = action.payload.error.response;
      if (code) {
        if (code === "INVALID_ACCESS_TOKEN") {
          localStorage.clear();
          return of(logout());
        }
        return of(showToast({ type: "failure", message: mapErrorCode(code) }));
      }
      if (status === FEATURE_NOT_IMPLEMENTED) {
        return of(
          showToast({
            type: "failure",
            message: "Sorry, this feature is currently unavailable. Please try again later.",
          }),
        );
      }

      return of(showToast({ type: "failure", message: "Something went wrong." }));
    }),
  );

export default combineEpics(handleErrorEpic);
