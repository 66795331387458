import { yupResolver } from "@hookform/resolvers/yup";
import { SearchIcon } from "@primer/octicons-react";
import PrimaryButton from "components/buttons/primary";
import CheckboxInline from "components/checkbox-inline";
import ControlledSelect from "components/controlled-select";
import FilterSearch from "components/filter-search";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  discardSearchResults,
  searchCharities,
} from "store/charities-search/charities-search.action";
import { IAppState } from "store/store.state";
import {
  updateCheckboxState,
  updateDropdownState,
  updateInputState,
} from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import colors from "theme/colors";
import { UN_GOAL_LIST, US_STATES_LIST } from "utils/content";
import { genRandomString } from "utils/helper";
import * as yup from "yup";

import * as S from "../charity-grid.styled";

const schema = yup.object().shape({
  q: yup
    .string()
    .trim()
    .notRequired()
    .test("q", "Please enter between 3 and 50 characters.", (value) =>
      value
        ? yup
            .string()
            .min(3, "Please enter at least 3 characters.")
            .max(50, "Please enter 50 or less characters.")
            .isValidSync(value)
        : true,
    ),
  unGoal: yup.string().trim().notRequired(),
  state: yup.string().trim().notRequired(),
});

const FilterAndSearchCharities: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const isDesktopSmall = useMediaQuery({
    query: `(max-width: ${S.PC_SMALL_BREAKPOINT}px)`,
  });

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({
    defaultValues: { q: "", unGoal: "", state: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const unGoalStored = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDropdownState(state, "UN_GOAL"),
  );

  const stateStored = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDropdownState(state, "STATE"),
  );

  const qStored = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectInputState(state, "SEARCH_CHARITIES"),
  );

  const checkboxStored = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectCheckboxState(state, "LOOKING_FOR"),
  );

  const [lookingFor, setLookingFor] = React.useState<string[]>(checkboxStored ?? []);
  const unGoalOptions = UN_GOAL_LIST.map((item, i) => ({
    ...item,
    isSelected: i === 0 || item.value === unGoalStored?.value,
  }));

  const stateList = US_STATES_LIST.map((item) => ({
    text: item.name,
    value: item.name,
    id: genRandomString(),
    isSelected: item.name === stateStored?.value,
  }));

  React.useEffect(() => {
    if (unGoalStored?.value?.length) {
      setValue("unGoal", unGoalStored?.value);
    }
  }, [unGoalStored?.value]);

  React.useEffect(() => {
    if (stateStored?.value?.length) {
      setValue("state", stateStored?.value);
    }
  }, [stateStored?.value]);

  const onOptionCheck = (value: string): void => {
    const updatedVal = lookingFor.includes(value)
      ? lookingFor.filter((item) => item !== value)
      : [...lookingFor, value];
    setLookingFor(updatedVal);
  };

  const checkboxGroupItems = [
    {
      id: genRandomString(),
      value: "Donations",
      title: "Donations",
      isSelected: lookingFor.includes("Donations"),
      onSelect: () => onOptionCheck("Donations"),
    },
    {
      id: genRandomString(),
      value: "Board",
      title: "Board",
      isSelected: lookingFor.includes("Board"),
      onSelect: () => onOptionCheck("Board"),
    },
    {
      id: genRandomString(),
      value: "Volunteers",
      title: "Volunteers",
      isSelected: lookingFor.includes("Volunteers"),
      onSelect: () => onOptionCheck("Volunteers"),
    },
  ];

  const onSearch = ({ q, unGoal, state }): void => {
    const lookingForParsed =
      lookingFor.length === 1
        ? lookingFor.join("")
        : lookingFor.map((item, i) => (i === 0 ? `${item}` : `&lookingFor=${item}`)).join("");
    const unGoalParsed = UN_GOAL_LIST.findIndex((item) => item.text === unGoal);
    const stateParsed = US_STATES_LIST.find((item) => item.name === state)?.abbreviation;
    const searchParams = {
      q,
      goal: unGoalParsed,
      state: stateParsed,
      lookingFor: lookingForParsed,
    };
    if (!q?.length) {
      delete searchParams.q;
    }
    if (!state?.length) {
      delete searchParams.state;
    }
    if (!unGoal?.length) {
      delete searchParams.goal;
    }
    if (!lookingFor?.length) {
      delete searchParams.lookingFor;
    }
    if (Object.keys(searchParams)?.length > 0) {
      dispatch(searchCharities.request(searchParams));
      dispatch(updateCheckboxState({ key: "LOOKING_FOR", values: lookingFor }));
      dispatch(updateDropdownState({ key: "STATE", value: state }));
      dispatch(updateDropdownState({ key: "UN_GOAL", value: unGoal }));
      dispatch(updateInputState({ key: "SEARCH_CHARITIES", value: q }));
    }
  };

  const clearFilters = (): void => {
    dispatch(discardSearchResults());
    dispatch(updateCheckboxState({ key: "LOOKING_FOR", values: [] }));
    dispatch(updateDropdownState({ key: "STATE", value: "" }));
    dispatch(updateDropdownState({ key: "UN_GOAL", value: "" }));
    dispatch(updateInputState({ key: "SEARCH_CHARITIES", value: "" }));

    setValue("q", "");
    setValue("state", "");
    setValue("unGoal", "");
    setLookingFor([]);
  };

  return (
    <S.RowDisplay
      gridGap={8}
      flexWrap
      style={{ maxWidth: "100%", width: "100%" }}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <S.ColumnDisplay
        gridGap={16}
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ maxWidth: "100%" }}
      >
        <FilterSearch
          filters={[]}
          searchResults={[]}
          control={control}
          name="q"
          errorMessage={errors?.q?.message || null}
          sx={{ maxWidth: "100%", width: "100%" }}
          size="medium"
          filtersDisabled
          initialValue={qStored?.value}
          placeholder="Search by Charity or Cause"
        />

        <S.RowDisplay
          alignItems="flex-start"
          justifyContent="flex-start"
          gridGap={8}
          flexWrap
          style={{ maxWidth: "100%" }}
        >
          <CheckboxInline items={checkboxGroupItems} fontSize={12} padding={6} />
          <ControlledSelect
            items={unGoalOptions}
            control={control}
            sx={{ width: "260px", maxWidth: "100%" }}
            name="unGoal"
            size="medium"
            placeholder="Select UN Goal"
          />
          <ControlledSelect
            items={stateList}
            control={control}
            sx={{ width: "260px", maxWidth: "100%" }}
            name="state"
            size="medium"
            placeholder="Select State"
          />
        </S.RowDisplay>
      </S.ColumnDisplay>

      <S.ColumnDisplay style={{ alignSelf: "end" }}>
        <S.RowDisplay gridGap={24} flexWrap>
          <PrimaryButton
            label="Reset filters"
            onClick={clearFilters}
            sx={{
              width: "50px",
              height: "34px",
              borderRadius: "6px",
              background: "transparent",
              border: "none",
              boxShadow: "none",
              color: colors.bordo(),
              ":hover": {
                background: "transparent",
                border: "none",
                boxShadow: "none",
                color: colors.bordo(),
              },
            }}
          />
          <PrimaryButton
            label="Search"
            onClick={handleSubmit(onSearch)}
            sx={{ height: isDesktopSmall ? "34px" : "48px", borderRadius: "6px", width: "191px" }}
            leadingIcon={SearchIcon}
          />
        </S.RowDisplay>
      </S.ColumnDisplay>
    </S.RowDisplay>
  );
};

export default FilterAndSearchCharities;
