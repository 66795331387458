import * as Sentry from "@sentry/react";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import { Provider } from "react-redux";
import RootContainer from "root-container";
import store from "store";

const App: React.FC = (): JSX.Element => (
  <Provider store={store}>
    <RootContainer />
  </Provider>
);

const LDProvider = withLDProvider({
  clientSideID: window.__RUNTIME_CONFIG__.LAUNCH_DARKLY_CLIENT_SIDE_ID,
})(Sentry.withProfiler(App));

export default LDProvider;
