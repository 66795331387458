import * as T from "types/auth";
import { IEmployeeLoginProps } from "types/auth";
import { ServerResponse } from "types/common";

import client from "./client";

export const signInCredentials = async (
  body: T.ISignInCredentialsProps,
): Promise<ServerResponse<T.ISignInCredentialsResponse>> =>
  client.post("/auth/loginCredentials", body);

export const signInCode = async (
  body: T.ISignInCodeProps,
): Promise<ServerResponse<ServerResponse<string>>> => client.post("/auth/loginCode", body);

export const sendCodeEmployee = async (
  companyName: string,
  body: IEmployeeLoginProps,
): Promise<ServerResponse<boolean>> => client.post(`/company/${companyName}/employee/login`, body);

export const resetPassword = async (email: string): Promise<ServerResponse<boolean>> =>
  client.post("/auth/resetPassword", { email });

export const setNewPassword = async (
  body: T.ISetNewPasswordProps,
): Promise<ServerResponse<boolean>> => client.post("/auth/setNewPassword", body);

export const resendCode = async (email: string): Promise<ServerResponse<boolean>> =>
  client.post("/auth/resendCode", { email });

export const resendVerificationEmail = async (email: string): Promise<number> =>
  (await client.post("/auth/resendVerificationEmail", { email })).status;
