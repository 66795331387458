import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  ClickableWrapper,
  ColumnDisplay,
  GradientHeading,
  LinkText,
  Paragraph,
  RowDisplay,
  Separator,
  Tag,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPadMini.x;

export const PC_SMALL_BREAKPOINT = breakpoints.desktopM.x;

export const MOBILE_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const CategoriesContainer = styled(ColumnDisplay)`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${({ theme }) => theme.white()};
`;

export const TopContainer = styled(ColumnDisplay)`
  width: 100%;
  background: ${({ theme }) => theme.aliceBlue()};
  padding-bottom: 50px;
  box-sizing: border-box;
`;

export const CenteredCategoriesContainer = styled(ColumnDisplay)<{ width?: string }>`
  width: ${({ width }) => width ?? "calc(100% - 108px)"};
  padding-left: 5.613vw;
  box-sizing: border-box;

  @media all and (min-width: 1600px) {
    padding-left: 97px;
    width: 100%;
    box-sizing: border-box;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: ${({ width }) =>
      width ?? "calc(100% - 2 * 24px); padding-left: 16px; padding-right: 16px;"};
  }
`;

export const SearchInputRow = styled(RowDisplay)`
  position: relative;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    grid-gap: 16px;
  }
`;

export const CategoriesRow = styled(RowDisplay)`
  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0px;
    margin: 0px;
    padding-bottom: 24px;
  }
`;

export const CategoriesScrollableRow = styled(RowDisplay)`
  max-width: calc(100% - 134px);

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const GradientHeadingStyled = styled(GradientHeading)`
  margin: 0px;
  padding: 0px;
`;

export const BrowseByCategoryText = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(90, 107, 143, 1);
  text-transform: uppercase;
`;

export const HighLevelCategoryHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(90, 107, 143, 0.6);
  text-transform: uppercase;
  max-width: 134px;
  width: 134px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const CategoryText = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const CategoryContiner = styled(RowDisplay)`
  height: auto;
  min-height: 51px;
  padding: 12px 32px 12px 32px;
  box-sizing: border-box;
  border-radius: 20px;
  background: ${({ theme }) => theme.white()};
  cursor: pointer;
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);
  text-align: center;

  &:hover {
    background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    min-height: 60px;
    box-shadow: 0px 8px 12px 0px rgba(66, 74, 83, 0.12);
  }

  &:hover ${CategoryText} {
    background: ${({ theme }) => theme.white()};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

export const CategoryIcon = styled.div`
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0em;
`;

export const GradientWrapper = styled.div`
  background: linear-gradient(90deg, rgba(51, 234, 241, 0.5) 0%, rgba(86, 145, 234, 0.5) 100%);
  padding: 5px;
  border-radius: 6px 0px 0px 6px;
  max-width: 863px;
  width: calc(100% - 191px);
  height: 66px;
  box-sizing: border-box;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    border-radius: 6px;
  }
`;

export const InputContainer = styled(RowDisplay)`
  width: 100%;
  height: 56px;
  flex: 1;
  padding 16px;
  box-sizing: border-box;
  border-radius: 6px;
  background: ${({ theme }) => theme.white()};
`;

export const SearchInput = styled.input`
  display: flex;
  flex: 1;
  outline: none;
  border: none;

  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.charcoalGray(0.7)};

  &::placeholder {
    opacity: 0.5;
  }
`;

export const SearchDropdown = styled(RowDisplay)`
  position: absolute;
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);
  background: ${({ theme }) => theme.white()};
  max-height: 300px;
  overflow: auto;
  width: 100%;
  max-width: 862px;
  padding: 16px;
  box-sizing: border-box;
  top: 0;
  margin-top: 70px;
  border-radius: 12px;
`;

export const SearchItem = styled(RowDisplay)`
  background: ${({ theme }) => theme.white()};
  border-radius: 40px;
  box-sizing: border-box;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.grayX11(0.2)};
  }
`;

export const SearchItemLogo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  object-fit: contain;
`;

export const SearchItemLogoWrapper = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.grayX11(0.5)};
  width: 30px;
  height: 30px;
  border-radius: 30px;
`;

export const SearchLoadingWrapper = styled(ColumnDisplay)`
  width: 100%;
  height: 200px;
`;

export const Divider = styled.div<{ isTransparent?: boolean }>`
  width: calc(100% - 5.613vw);
  height: 1px;
  padding-right: 5.613vw;
  box-sizing: border-box;
  background: ${({ isTransparent }) =>
    isTransparent ? "rgba(242, 242, 242, 1)" : "rgba(130, 145, 173, 1)"};

  @media all and (max-width: 1600px) {
    padding-left: 97px;
    box-sizing: border-box;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: calc(100% - 2 * 24px);
    min-width: 300px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
