import { DonationsTable, DonationsTableSearchParams } from "store/donations/donations.type";
import { ServerResponse } from "types/common";
import * as T from "types/gift-match";
import { genUrlParams, uriEncode } from "utils/helper";

import client from "./client";

export const matchDonation = async (
  companyName: string,
  body: T.MatchDonationRequestDto,
): Promise<number> =>
  (await client.post(`/giftMatch/${uriEncode(companyName)}/donations/match`, body)).status;

export const matchEveryDonation = async (
  companyName: string,
  body: T.MatchEveryDonationRequestDto,
): Promise<number> =>
  (await client.post(`/giftMatch/${uriEncode(companyName)}/donations/matchEvery`, body)).status;

export const respondToGiftMatch = async (
  companyName: string,
  body: T.RespondToGiftMatchRequestDto,
): Promise<ServerResponse<string>> =>
  (await client.post(`/giftMatch/${uriEncode(companyName)}/donations/response`, body)).data;

export const getDonations = async (
  companyName: string,
  table: DonationsTable,
  page: number,
  searchParams: DonationsTableSearchParams,
): Promise<ServerResponse<T.GiftMatchResponse>> =>
  (
    await client.get(
      genUrlParams(`/giftMatch/${uriEncode(companyName)}/donations/${table}`, {
        page,
        ...searchParams,
      }),
    )
  ).data;
