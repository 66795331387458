import styled from "styled-components";
import { ColumnDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export { ColumnDisplay, RowDisplay } from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.desktopS.x;
export const MOBILE_BREAKPOINT = breakpoints.iPhone13Pro.x;

export const EditButtonWrapper = styled(ColumnDisplay)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const EditButtonDropdownWrapper = styled(ColumnDisplay)`
  position: relative;
`;

export const EditDropdown = styled.div<{ isOpen: boolean }>`
  left: 0;
  top: 0;
  margin-top: 40px;
  width: 217px;
  height: auto;
  max-height: 300px;
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.2);
  position: absolute;
  border-radius: 8px;
  overflow: auto;
  z-index: 2;
  ${({ isOpen }) => !isOpen && "display: none;"}
`;

export const EditDropdownItem = styled.div<{ isDisabled?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  padding: 14px;

  font-weight: 500;
  line-height: 20px;
  font-size: 18px;
  color: ${({ theme }) => theme.charcoalGray()};

  ${({ isDisabled }) => (!isDisabled ? "cursor: pointer" : "opacity: 0.5")};

  ${({ isDisabled, theme }) =>
    !isDisabled &&
    `
  &:hover {
    background: ${theme.unitedNationsBlue()};
    color: ${theme.white()};
  }
  `};
`;
