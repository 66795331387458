import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import * as C from "./every-org.constants";
import * as T from "./every-org.type";

export const everyOrgInitial: T.IEveryOrgState = {
  data: [],
  subCategory: null,
  page: 1,
  inProgress: false,
  isFetched: false,
  isEndReached: false,
  error: null,
  details: null,
};

const everyOrgReducer = (
  state: T.IEveryOrgState,
  action: T.EveryOrgAction | ILogout,
): T.IEveryOrgState => {
  if (!state) {
    return everyOrgInitial;
  }

  switch (action.type) {
    case C.GET_EVERY_ORG_CHARITIES:
      return {
        ...state,
        subCategory: action.payload.cause,
        data: action.payload.page === 1 ? [] : state.data,
        isFetched: action.payload.page > 1,
        inProgress: true,
      };
    case C.GET_EVERY_ORG_CHARITIES_SUCCESS:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
        isEndReached: action.payload.charities.length < 20,
        page: action.payload.page,
        data:
          action.payload.page === 1
            ? action.payload.charities
            : [...state.data, ...action.payload.charities],
      };
    case C.GET_EVERY_ORG_CHARITIES_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.GET_EVERY_ORG_CHARITY_DETAILS:
      return {
        ...state,
        details: null,
        inProgress: true,
      };
    case C.GET_EVERY_ORG_CHARITY_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
        inProgress: false,
      };
    case C.GET_EVERY_ORG_CHARITY_DETAILS_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.CHANGE_EVERY_ORG_DETAILS: {
      const updatedData = state.data.map((item) => {
        if (item.slug === action.payload.slug) {
          return {
            ...item,
            backed: action.payload.backed,
          };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData,
        details: { ...state.details, backed: action.payload.backed },
      };
    }
    case LOGOUT:
      return everyOrgInitial;
    default:
      return state;
  }
};

export default everyOrgReducer;
