import React, { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

import * as S from "./horizontal-draggable-scroll.styled";

const HorizontalDraggableScroll: React.FC<{
  children: JSX.Element;
  alignItems?: "flex-start" | "flex-end";
  paddingLeft?: number;
}> = ({ children, alignItems, paddingLeft }): JSX.Element => {
  const ref = useRef<HTMLDivElement>();
  const { events } = useDraggable(ref);

  return (
    <S.HorizontalScrollContainer
      justifyContent="flex-start"
      alignItems={alignItems ?? "flex-start"}
      paddingLeft={paddingLeft}
      {...events}
      ref={ref}
    >
      <S.RowDisplay gridGap={40} flexWrap={false} alignItems="stretch">
        {children}
      </S.RowDisplay>
    </S.HorizontalScrollContainer>
  );
};

HorizontalDraggableScroll.defaultProps = {
  alignItems: "flex-start",
  paddingLeft: null,
};

export default HorizontalDraggableScroll;
