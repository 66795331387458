import { CheckCircleIcon } from "@primer/octicons-react";
import { Text } from "@primer/react";
import React from "react";
import colors from "theme/colors";

import * as S from "./tag.styled";
import * as T from "./tag.type";

const Tag: React.FC<T.ITagProps> = ({
  text,
  isChecked = true,
  style,
  isGradient = false,
}): JSX.Element => (
  <S.TagWrapper gridGap={6} style={style} isGradient={isGradient}>
    {isChecked && <CheckCircleIcon fill={colors.charcoalGray()} />}
    <S.TagText
      style={{
        color: isGradient ? colors.white() : colors.charcoalGray(),
        ...style,
      }}
    >
      {text}
    </S.TagText>
  </S.TagWrapper>
);

export default Tag;
