import { OrganizationIcon, PersonIcon } from "@primer/octicons-react";
import { ButtonGroup } from "@primer/react";
import GrayButton from "components/buttons/gray";
import FixedHeader from "components/fixed-header";
import React from "react";
import colors from "theme/colors";
import { EmployeeTabType } from "types/charity";

import * as S from "../charity-grid.styled";
import * as T from "../charity-grid.type";
import FilterAndSearchCharities from "./filter-search-charities";

const EmployeeControls: React.FC<T.IControlsProps<EmployeeTabType>> = ({
  selectedTab,
  selectTab,
  companyName,
}): JSX.Element => (
  <>
    <S.TopContainer>
      <S.TopInnerContainer>
        <FixedHeader name={companyName} />
        <S.TabContainer
          justifyContent={selectedTab === "company" ? "space-between" : "flex-end"}
          alignItems="flex-start"
          gridGap={20}
          flexWrap
        >
          {selectedTab === "company" && (
            <S.ColumnDisplay alignItems="flex-start" justifyContent="flex-start">
              <S.SectionGradientHeading
                hideMargins
              >{`Charities promoted by ${companyName}`}</S.SectionGradientHeading>
              <S.Separator height={8} />
              <S.SectionGradientSubHeading hideMargins>8 charities</S.SectionGradientSubHeading>
            </S.ColumnDisplay>
          )}

          <ButtonGroup sx={S.TAB_GROUP_STYLE}>
            <GrayButton
              label="You"
              onClick={() => selectTab("you")}
              leadingIcon={PersonIcon}
              sx={{
                ...(selectedTab === "you" ? S.SELECTED_TAB_STYLE : S.UNSELECTED_TAB_STYLE),
                height: "45px",
                width: "104px",
              }}
            />
            <GrayButton
              label="Company"
              onClick={() => selectTab("company")}
              leadingIcon={OrganizationIcon}
              sx={{
                ...(selectedTab === "company" ? S.SELECTED_TAB_STYLE : S.UNSELECTED_TAB_STYLE),
                height: "45px",
                width: "155px",
              }}
            />
          </ButtonGroup>
        </S.TabContainer>
      </S.TopInnerContainer>
    </S.TopContainer>

    {selectedTab === "you" && (
      <>
        <S.TopContainerGradientSeparator />
        <S.GradientContainerWrapper>
          <S.GradientContainerWhiteWrapper>
            <S.GradientContainer gridGap={32}>
              <S.GradientContainerHeading>
                Find a charity that aligns with your passions and values.
              </S.GradientContainerHeading>
              <FilterAndSearchCharities />
            </S.GradientContainer>
          </S.GradientContainerWhiteWrapper>
        </S.GradientContainerWrapper>
      </>
    )}

    {selectedTab === "company" && (
      <S.ColumnDisplay
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{
          width: "100%",
          ...(selectedTab === "company" && { background: colors.aliceBlue() }),
        }}
      >
        <S.Separator height={75} />
      </S.ColumnDisplay>
    )}
  </>
);

export default EmployeeControls;
