import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BackIndicator,
  ColumnDisplay,
  LinkText,
  NoDataForm,
  NoDataText,
  RowDisplay,
  SearchAndFilterContainer,
  SearchAndFilterInnerContainer,
  Separator,
  Table,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPhone8.y;

export const VolunteerEventAttendeesPageWrapper = styled(ColumnDisplay)`
  height: 100vh;
  max-height: 100vh;
  flex: 1;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0px 150px 85px 150px;
  box-sizing: border-box;
  padding-bottom: 85px;
  overflow-y: scroll;
  background: ${({ theme }) => theme.aliceBlue()};

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 15px 20px 85px 20px;
  }
`;

export const GradientHeadingExtended = styled(GradientHeading)`
  margin: 0;
  max-width: 100%;
  text-align: left;
`;

export const TotalUsersText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const PaginationWrapper = styled(RowDisplay)`
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.white()};
  height: 56px;
  border: 1px solid ${({ theme }) => theme.gunmetal(0.1)};
  border-radius: 0px 0px 12px 12px;
  margin-top: 3px;

  a,
  span {
    display: unset !important;
    padding: 8px 16px;
  }
`;
