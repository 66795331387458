import { LogoIcon } from "icons";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { capitalizeFirstLetter } from "utils/helper";

import packageJson from "../../../package.json";
import * as S from "./logo-and-company.style";
import * as T from "./logo-and-company.type";

const LogoAndCompany: React.FC<T.ILogoAndCompany> = ({
  companyName,
  fontSize,
  showMargin,
  isFullWidth = false,
  showVersion = true,
  isCentered = false,
  mobileFontSize = undefined,
}): JSX.Element => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  return (
    <S.ColumnDisplay
      gridGap={4}
      alignItems={isCentered ? "center" : isFullWidth ? "flex-start" : "center"}
      style={
        showMargin
          ? { marginBottom: "35px", flex: isFullWidth ? 1 : 0 }
          : { flex: isFullWidth ? 1 : 0 }
      }
    >
      <S.BottomWrapper gridGap={8}>
        <LogoIcon width={isTablet ? 24 : 33} />
        <S.LogoText
          fontSize={fontSize ?? 18}
          mobileFontSize={mobileFontSize}
          isFullWidth={isFullWidth}
        >
          {companyName ? "Fundwurx for" : "Fundwurx"}
          {companyName && (
            <S.CompanyNameText fontSize={fontSize ?? 18} mobileFontSize={mobileFontSize}>
              &nbsp;{capitalizeFirstLetter(companyName)}
            </S.CompanyNameText>
          )}
        </S.LogoText>
      </S.BottomWrapper>
      {showVersion && <S.Paragraph fontSize={10}>{`version: ${packageJson?.version}`}</S.Paragraph>}
    </S.ColumnDisplay>
  );
};

export default LogoAndCompany;
