import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { MAX_PROMOTED_CHARITIES } from "utils/constants";

import * as C from "./charities.constants";
import * as T from "./charities.type";

export const charityListInitial: T.ICharityListState = {
  inProgress: false,
  loadingSections: [],
  isFetched: [],
  error: null,
  isWallEndReached: false,
  data: {
    wall: {},
    add_charity: {},
    you: {},
    company: {},
  },
};

const charityListReducer = (
  state: T.ICharityListState,
  action: T.CharityListAction | ILogout,
): T.ICharityListState => {
  if (!state) {
    return charityListInitial;
  }

  switch (action.type) {
    case C.ADD_TO_COMPANY_WALL:
    case C.REMOVE_FROM_COMPANY_WALL:
    case C.ADD_EVERYORG_TO_COMPANY_WALL:
    case C.REMOVE_EVERYORG_FROM_COMPANY_WALL:
      return {
        ...state,
        inProgress: true,
      };
    case C.GET_CHARITIES:
    case C.GET_MORE_WALL_CHARITIES:
      return {
        ...state,
        inProgress: true,
        isFetched: [...state.isFetched, action.payload.tab],
      };
    case C.ADD_EVERYORG_TO_COMPANY_WALL_SUCCESS:
    case C.REMOVE_EVERYORG_FROM_COMPANY_WALL_SUCCESS:
      return {
        ...state,
        inProgress: false,
        isWallEndReached: !(Object.keys(state.data.wall).length < MAX_PROMOTED_CHARITIES),
      };
    case C.ADD_TO_COMPANY_WALL_SUCCESS: {
      return {
        ...state,
        inProgress: false,
        data: {
          ...state.data,
          wall: state.isFetched?.includes("wall")
            ? {
                ...state.data.wall,
                [action.payload.irsCategory]: {
                  ...state.data.wall?.[action.payload.irsCategory],
                  data: [
                    ...(state.data?.wall?.[action.payload.irsCategory]?.data ?? []),
                    state?.data?.add_charity?.[action.payload.irsCategory]?.data?.find(
                      (item) => item.id === action.payload.id,
                    ),
                  ],
                },
              }
            : state.data.wall,
          add_charity: {
            ...state.data.add_charity,
            [action.payload.irsCategory]: {
              ...state.data.add_charity?.[action.payload.irsCategory],
              data: (state.data?.add_charity?.[action.payload.irsCategory]?.data ?? [])?.filter(
                (item) => item.id !== action.payload.id,
              ),
            },
          },
        },
      };
    }
    case C.REMOVE_FROM_COMPANY_WALL_SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: {
          ...state.data,
          add_charity: {
            ...state.data.add_charity,
            [action.payload.irsCategory]: {
              ...state.data.add_charity?.[action.payload.irsCategory],
              data: [
                ...(state.data?.add_charity?.[action.payload.irsCategory]?.data ?? []),
                state?.data?.wall?.[action.payload.irsCategory]?.data?.find(
                  (item) => item?.id === action.payload.id,
                ),
              ],
            },
          },
          wall: {
            ...state.data.wall,
            [action.payload.irsCategory]: {
              ...state.data.wall?.[action.payload.irsCategory],
              data: state.data?.wall?.[action.payload.irsCategory]?.data?.filter(
                (item) => item?.id !== action.payload.id,
              ),
            },
          },
        },
      };
    case C.ADD_TO_COMPANY_WALL_FAILURE:
    case C.REMOVE_FROM_COMPANY_WALL_FAILURE:
    case C.ADD_EVERYORG_TO_COMPANY_WALL_FAILURE:
    case C.REMOVE_EVERYORG_FROM_COMPANY_WALL_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.GET_MORE_CHARITIES:
      return {
        ...state,
        loadingSections: [...state.loadingSections, action.payload.category],
      };
    case C.GET_MORE_CHARITIES_FAILURE:
      return {
        ...state,
        loadingSections: state.loadingSections.filter((key) => key !== action.payload.category),
      };
    case C.GET_MORE_CHARITIES_SUCCESS:
      return {
        ...state,
        inProgress: false,
        loadingSections: [],
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...state.data[action.payload.tab],
            ...Object.keys(action.payload.data || {})
              .map((key) => {
                const data = action.payload.data[key] ?? [];
                return {
                  ...state.data[action.payload.tab],
                  [key]: {
                    data,
                    isEndReached: data?.length < MAX_PROMOTED_CHARITIES,
                    lastId: data[data.length - 1]?.id ?? null,
                  },
                };
              })
              .reduce((prev, curr) => Object.assign(prev, curr), {}),
          },
        },
      };
    case C.GET_CHARITIES_SUCCESS:
      return {
        ...state,
        inProgress: false,
        loadingSections: [],
        isWallEndReached: Object.keys(action.payload.data).length < MAX_PROMOTED_CHARITIES,
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...Object.keys(action.payload.data || {}).reduce((prev, key) => {
              const data = action.payload.data[key] ?? [];
              return {
                ...prev,
                [key]: {
                  data,
                  isEndReached: data?.length < MAX_PROMOTED_CHARITIES,
                  lastId: data[data.length - 1]?.id ?? null,
                },
              };
            }, {}),
          },
        },
      };
    case C.GET_MORE_WALL_CHARITIES_SUCCESS: {
      const existingSectionData = state.data[action.payload.tab];

      const maxKey = Object.keys(existingSectionData).reduce((max, key) => {
        const currentKey = parseInt(key, 10);
        return currentKey > max ? currentKey : max;
      }, 0);

      const updatedData = Object.keys(action.payload.data || {}).reduce((prev, key, index) => {
        const data = action.payload.data[key] ?? [];
        const currentKey = maxKey + index + 1;
        return {
          ...prev,
          [currentKey]: {
            data,
            isEndReached: data.length < MAX_PROMOTED_CHARITIES,
            lastId: data.length > 0 ? data[data.length - 1]?.id : null,
          },
        };
      }, {});

      return {
        ...state,
        inProgress: false,
        loadingSections: [],
        isWallEndReached: Object.keys(updatedData).length < MAX_PROMOTED_CHARITIES,
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...existingSectionData,
            ...updatedData,
          },
        },
      };
    }
    case C.GET_CHARITIES_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case LOGOUT:
      return charityListInitial;
    default:
      return state;
  }
};

export default charityListReducer;
