import * as C from "./company-active-volunteer-events.constants";
import * as T from "./company-active-volunteer-events.type";

export const fetchActiveCompanyVolunteerEvents = {
  request: (payload: T.IFetchActiveVolunteerEvents["payload"]): T.IFetchActiveVolunteerEvents => ({
    type: C.FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS,
    payload,
  }),
  success: (
    payload: T.IFetchActiveVolunteerEventsSuccess["payload"],
  ): T.IFetchActiveVolunteerEventsSuccess => ({
    type: C.FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchActiveVolunteerEventsFailure => ({
    type: C.FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_FAILURE,
  }),
};

export const refreshActiveCompanyVolunteerEvents = {
  request: (
    payload: T.IRefreshActiveVolunteerEvents["payload"],
  ): T.IRefreshActiveVolunteerEvents => ({
    type: C.REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS,
    payload,
  }),
  success: (
    payload: T.IRefreshActiveVolunteerEventsSuccess["payload"],
  ): T.IRefreshActiveVolunteerEventsSuccess => ({
    type: C.REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_SUCCESS,
    payload,
  }),
  failure: (): T.IRefreshActiveVolunteerEventsFailure => ({
    type: C.REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_FAILURE,
  }),
};
