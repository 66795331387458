import { LOGOUT, SIGN_IN_CODE_SUCCESS } from "store/auth/auth.constants";
import { ILogout, ISignInCodeSuccess } from "store/auth/auth.type";

import { cacheRegistration, clearRegistrationCache } from "./registration.cache";
import * as C from "./registration.constants";
import * as T from "./registration.type";

export const registrationInitial: T.IRegistrationState = {
  isCached: false,
  inProgress: false,
  error: null,
  companyDto: null,
  employeeDto: null,
  adminDto: null,
};

const cache = (state: T.IRegistrationState) => cacheRegistration(registrationInitial, state);

const registrationReducer = (
  state: T.IRegistrationState,
  action: T.RegistrationAction | ISignInCodeSuccess | ILogout,
): T.IRegistrationState => {
  if (!state) {
    return registrationInitial;
  }

  switch (action.type) {
    case C.STORE_COMPANY_DTO:
      return {
        ...state,
        companyDto: {
          ...state.companyDto,
          ...action.payload,
        },
      };
    case C.STORE_EMPLOYEE_DTO:
      return cache({
        ...state,
        employeeDto: {
          ...state.employeeDto,
          ...action.payload,
        },
      });
    case SIGN_IN_CODE_SUCCESS:
      return cache({
        ...state,
        employeeDto: registrationInitial.employeeDto,
      });
    case C.STORE_ADMIN_DTO:
      return {
        ...state,
        adminDto: {
          ...state.adminDto,
          ...action.payload,
        },
      };
    case LOGOUT:
      return clearRegistrationCache(registrationInitial);
    default:
      return state;
  }
};

export default registrationReducer;
