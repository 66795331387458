import { IEveryOrgSearchSelectors } from "./every-org-search.type";

const everyOrgSearchSelectors: IEveryOrgSearchSelectors = {
  selectData: (state, store) => state.everyOrgSearch[store].data,
  selectIsFetched: (state, store) => state.everyOrgSearch[store].isFetched,
  selectPage: (state, store) => state.everyOrgSearch[store].page,
  selectIsEndReached: (state, store) => state.everyOrgSearch[store].isEndReached,
  selectSearchedTerm: (state) => state.everyOrgSearch.searchTerm,
  selectDropdownDisplayed: (state) => state.everyOrgSearch.displayDropdown,
  selectInProgress: (state, store) => state.everyOrgSearch[store].inProgress,
  selectError: (state) => state.everyOrgSearch.error,
};

export default everyOrgSearchSelectors;
