import { InfoIcon, XIcon } from "@primer/octicons-react";
import { Flash, StyledOcticon, Text } from "@primer/react";
import React from "react";
import { useDispatch } from "react-redux";
import { hideToast } from "store/ui-components/ui-components.actions";
import colors from "theme/colors";

import * as S from "../toast.style";
import * as T from "../toast.type";

const ToastFailure: React.FC<T.IToastProps> = ({ message, isShown }): JSX.Element => {
  const dispatch = useDispatch();

  return isShown ? (
    <Flash
      variant="danger"
      sx={{
        width: "auto",
        maxWidth: "calc(100% - 2 * 40px)",
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <S.RowDisplay style={{ maxWidth: "100%" }}>
        <StyledOcticon icon={InfoIcon} fill={colors.bordo()} />
        <Text sx={{ wordBreak: "break-word", maxWidth: "100%" }}>{message}</Text>
      </S.RowDisplay>
      <S.ClickableWrapper onClick={() => dispatch(hideToast())} style={{ paddingLeft: "16px" }}>
        <StyledOcticon icon={XIcon} />
      </S.ClickableWrapper>
    </Flash>
  ) : null;
};

export default ToastFailure;
