import LogoAndCompany from "components/logo-and-company";
import { LogoWhiteIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import authSelectors from "store/auth/auth.selector";
import { checkCompanyExists } from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import { showFullScreenLoader } from "store/ui-components/ui-components.actions";
import { capitalizeFirstLetter } from "utils/helper";

import * as S from "./auth-form.styled";

interface IAuthFormTemplate {
  children: JSX.Element;
}

const AuthFormTemplate: React.FC<IAuthFormTemplate> = ({ children }): JSX.Element => {
  const dispatch = useDispatch();
  const params = useParams();

  const { companyName: urlCompanyName } = params;

  const lastCompanyCheck = useSelector(authSelectors.selectLastCompanyCheck);

  const companyName = useSelector(companySelectors.selectName);
  const logo = useSelector(companySelectors.selectLogoUrl);

  const isCharityAuth = urlCompanyName === "charity";

  React.useEffect(() => {
    if (lastCompanyCheck !== urlCompanyName && !isCharityAuth) {
      dispatch(showFullScreenLoader());
      dispatch(checkCompanyExists.request({ name: urlCompanyName }));
    }
  }, []);

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const isTabletL = useMediaQuery({
    query: `(max-width: ${S.TABLET_L_BREAKPOINT}px)`,
  });

  const elementSX: React.CSSProperties = {
    width: !isTablet ? 391 : 280,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const gapBig = isTabletL ? 40 : 60;

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      {!isTablet && <div />}
      <S.DoubleFormWrapper
        justifyContent="space-between"
        alignItems="flex-start"
        gridGap={isTablet ? 16 : 0}
      >
        {isTablet ? (
          <>
            <LogoAndCompany companyName={isCharityAuth ? null : companyName} showMargin />
            <S.MobileFormContainer alignItems="center" justifyContent="center">
              <S.CoverFrame alignItems="flex-start" justifyContent="center">
                <S.CoverFrameInnerContent alignItems="flex-start" justifyContent="center">
                  <S.WelcomeText>Welcome to</S.WelcomeText>
                  <S.Separator height={8} />
                  <S.HeadingText>Fundwurx</S.HeadingText>
                  <S.Separator height={24} />
                  <S.LineSeparator />
                  <S.Separator height={16} />
                  <S.ParagraphWhite style={{ maxWidth: "371px" }}>
                    Thank you for choosing Fundwurx as your partner in driving positive social
                    impact!
                  </S.ParagraphWhite>
                </S.CoverFrameInnerContent>
              </S.CoverFrame>
              <S.CoverFrameAbsolute animationDuration={1} iterationCount={2} />

              {/* <LogoMockedIcon /> */}
              <S.Separator height={gapBig} />
              <S.ColumnDisplay
                alignItems="flex-start"
                justifyContent="flex-start"
                style={elementSX}
                gridGap={16}
              >
                {!isCharityAuth && (
                  <>
                    {logo && <S.CompanyLogo src={logo} />}
                    <S.LabelBold>Fundwurx for</S.LabelBold>
                    <S.GradientHeadingExtended fontSize={48}>
                      {capitalizeFirstLetter(companyName)}
                    </S.GradientHeadingExtended>
                  </>
                )}
                {children}
              </S.ColumnDisplay>
            </S.MobileFormContainer>
          </>
        ) : (
          <>
            <S.FormWrapper
              alignItems="flex-start"
              justifyContent="flex-start"
              style={{ height: "100%" }}
            >
              <S.Separator height={gapBig} />
              {!isCharityAuth ? (
                <S.ColumnDisplay alignItems="flex-start" gridGap={16} width="100%">
                  {logo && <S.CompanyLogo src={logo} />}
                  <S.LabelBold>Fundwurx for</S.LabelBold>
                  <S.GradientHeadingExtended fontSize={48}>
                    {capitalizeFirstLetter(companyName)}
                  </S.GradientHeadingExtended>
                </S.ColumnDisplay>
              ) : (
                <S.LabelBold>Fundwurx</S.LabelBold>
              )}

              {children}
            </S.FormWrapper>

            <S.CoverFrame alignItems="flex-start" justifyContent="center">
              <S.CoverFrameInnerContent alignItems="flex-start" justifyContent="center">
                <S.WelcomeText>Welcome to</S.WelcomeText>
                <S.Separator height={8} />
                <S.HeadingText>Fundwurx</S.HeadingText>
                <S.Separator height={24} />
                <S.LineSeparator />
                <S.Separator height={16} />
                <S.ParagraphWhite style={{ maxWidth: "361px" }}>
                  Thank you for choosing Fundwurx as your partner in driving positive social impact!
                </S.ParagraphWhite>
              </S.CoverFrameInnerContent>
              <S.LogoWrapper>
                <LogoWhiteIcon />
              </S.LogoWrapper>
            </S.CoverFrame>
            <S.CoverFrameAbsolute animationDuration={1} iterationCount={2} />
          </>
        )}
      </S.DoubleFormWrapper>
      {!isTablet && <LogoAndCompany companyName={companyName} showMargin />}
    </S.BubbleBackground>
  );
};

export default AuthFormTemplate;
