import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";

import ToastFailure from "./failure";
import ToastSuccess from "./success";
import * as S from "./toast.style";

const TOAST_TIMEOUT = 7500;

const Toast: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const { isShown, type, message } = useSelector(uiComponentsSelectors.selectToastState);

  React.useEffect(() => {
    if (isShown) {
      setTimeout(() => {
        dispatch(hideToast());
      }, TOAST_TIMEOUT);
    }
  }, [isShown]);

  return isShown ? (
    <S.ToastWrapper>
      {type === "success" ? (
        <ToastSuccess message={message} isShown={isShown} />
      ) : (
        <ToastFailure message={message} isShown={isShown} />
      )}
    </S.ToastWrapper>
  ) : null;
};

export default Toast;
