import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "store/auth/auth.selector";
import { fetchUser } from "store/user/user.action";
import { AuthRole } from "types/user";
import { LAUNCH_DARKLY_FLAGS } from "utils/constants";
import routes from "utils/routes";

import AuthNavigator from "./auth";
import CharityAdminNavigator from "./charity-admin";
import CompanyAdminNavigator from "./company-admin";
import EmployeeNavigator from "./employee";

const mapNavigationStack = (routesToHide: string[]): Record<AuthRole, JSX.Element> => ({
  admin: <CompanyAdminNavigator routesToHide={routesToHide} />,
  employee: <EmployeeNavigator routesToHide={routesToHide} />,
  charity: <CharityAdminNavigator routesToHide={routesToHide} />,
});

const AppRouter: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const ldClient = useLDClient();
  const flags = useFlags();

  const token = useSelector(authSelectors.selectToken);
  const role = useSelector(authSelectors.selectRole);

  React.useEffect(() => {
    ldClient?.identify({ key: window.__RUNTIME_CONFIG__.LAUNCH_DARKLY_SDK_KEY });
  }, []);

  const routesToHide = !flags?.[LAUNCH_DARKLY_FLAGS?.findCharities] ? [routes.FIND_CHARITIES] : [];

  React.useEffect(() => {
    if (token) {
      dispatch(fetchUser.request());
    }
  }, [token]);

  return !token ? <AuthNavigator /> : mapNavigationStack(routesToHide)[role];
};

export default AppRouter;
