import { UploadIcon } from "@primer/octicons-react";
import GrayButton from "components/buttons/gray";
import React from "react";
import colors from "theme/colors";

import * as S from "./file-picker.styled";
import * as T from "./file-picker.type";

const GrayButtonSx = {
  color: colors.charcoalGray(),
  fontWeight: "600",
  width: "195px",
  height: "32px",
  borderRadius: 6,
  ":disabled": {
    color: `${colors.charcoalGray(0.5)} !important`,
  },
};

const FilePicker: React.FC<T.IFilePickerProps> = ({
  supportedTypes,
  maxFileSize,
  buttonLabel,
  isErrorMode,
  file,
  pickerLabel = "No file chosen.",
  uploadedUrl,
  setFile,
  isDisabled = false,
}): JSX.Element => {
  const [isFileLarge, setLargeFile] = React.useState<boolean>(false);
  const fileUploaderRef = React.useRef<HTMLInputElement>(null);

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.[0]) {
      const fileUploaded = e.target.files[0];
      if (fileUploaded.size > maxFileSize) {
        setLargeFile(true);
        setFile(null);
      } else {
        setLargeFile(false);
        setFile(fileUploaded);
      }
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileUploaderRef}
        onChange={onFileSelect}
        accept={supportedTypes}
        style={{ display: "none" }}
        disabled={isDisabled}
      />
      <S.FileUploadWrapper
        justifyContent="flex-start"
        gridGap={8}
        flexWrap
        isErrorMode={isErrorMode ?? false}
      >
        <GrayButton
          label={!file ? buttonLabel : "Replace document"}
          onClick={() => fileUploaderRef?.current?.click()}
          isDisabled={isDisabled}
          leadingIcon={UploadIcon}
          sx={GrayButtonSx}
        />
        <S.NoFileChoosenText
          isErrorMode={isFileLarge || isErrorMode}
          isDisabled={isDisabled}
          isUrlView={!!uploadedUrl}
          onClick={() => !!uploadedUrl && window.open(uploadedUrl, "_blank")}
        >
          {!uploadedUrl
            ? file?.name ??
              (isFileLarge ? `Max file size is ${maxFileSize / (1024 * 1024)}MB.` : pickerLabel)
            : "View waiver"}
        </S.NoFileChoosenText>
      </S.FileUploadWrapper>
    </>
  );
};

export default FilePicker;
