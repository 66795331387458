import styled from "styled-components";
import { RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ColumnDisplay,
  GradientHeading,
  Paragraph,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const BottomWrapper = styled(RowDisplay)``;

export const LogoText = styled.div<{
  fontSize: number;
  isFullWidth?: boolean;
  mobileFontSize?: number;
}>`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 28px;
  color: ${({ theme }) => theme.spaceCodet()};
  overflow: hidden;
  flex: 1;
  ${({ isFullWidth }) => isFullWidth && "min-width: 100px;"}
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: ${({ mobileFontSize }) => (mobileFontSize ? `${mobileFontSize}px` : "10.7513px")};
    line-height: 17px;
  }
`;

export const CompanyNameText = styled.span<{ fontSize: number; mobileFontSize?: number }>`
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize}px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: ${({ mobileFontSize }) => (mobileFontSize ? `${mobileFontSize}px` : "10.7513px")};
    line-height: 17px;
  }
`;
