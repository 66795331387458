import * as api from "api/company";
import { combineEpics, ofType } from "redux-observable";
import CustomHistory from "router/history";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { storeCompanyDTO } from "store/registration/registration.action";
import registrationSelectors from "store/registration/registration.selector";
import { AppEpic } from "store/store.state";
import {
  hideFullScreenLoader,
  showToast,
  updateDialogState,
} from "store/ui-components/ui-components.actions";
import { getCurrYear } from "utils/helper";
import routes from "utils/routes";
import { ACCEPTED, OK } from "utils/status-code";

import {
  checkCompanyExists,
  checkCompanyInfo,
  confirmFinchRegistration,
  confirmRegistration,
  deleteCompanyLogo,
  getAnalyticDashboardSummary,
  getCharitiesSupported,
  getCompanyById,
  getDashboardSummary,
  getDonationsAnalytics,
  getEmployeeInterests,
  getGiftMatchingAnalytics,
  getVolunteeringAnalytics,
  registerCompany,
  registerEmployee,
  resetRegistrationDTO,
  updateCompanyLogo,
} from "./company.action";
import * as C from "./company.constants";
import * as T from "./company.type";

const checkCompanyInfoEpic: AppEpic = (action$: Observable<T.ICheckCompanyInfoRequest>) =>
  action$.pipe(
    ofType(C.CHECK_COMPANY_INFO),
    mergeMap((action) =>
      from(api.checkCompanyInfo(action.payload)).pipe(
        mergeMap((response) => {
          if (response.data) {
            if (response.data.data.domainExists) {
              return of(showToast({ message: "Given domain already exists.", type: "failure" }));
            }
            if (response.data.data.nameExists) {
              return of(
                showToast({ message: "Given name already exists.", type: "failure" }),
                checkCompanyInfo.failure(),
              );
            }
            if (response.data.data.domainGeneric) {
              return of(
                showToast({ message: "Generic domains are not allowed.", type: "failure" }),
                checkCompanyInfo.failure(),
              );
            }
            CustomHistory.navigate(routes.COMPANY.CREATE_ACCOUNT);
            return of(
              checkCompanyInfo.success(response.data),
              storeCompanyDTO(action.payload),
              checkCompanyInfo.failure(),
            );
          }
          return of(handleError({ action, error: null }), checkCompanyInfo.failure());
        }),
        catchError((error) => of(checkCompanyInfo.failure(), handleError({ action, error }))),
      ),
    ),
  );

const registerCompanyEpic: AppEpic = (action$: Observable<T.IRegisterCompanyRequest>, state$) =>
  action$.pipe(
    ofType(C.REGISTER_COMPANY),
    mergeMap((action) => {
      const data = registrationSelectors.selectCompanyDTO(state$?.value);
      return from(api.registerCompany({ ...data, ...action.payload })).pipe(
        mergeMap((response) => {
          if (response.data) {
            CustomHistory.navigate(routes.EMAIL_SENT);
            return of(registerCompany.success(response.data));
          }
          return of(registerCompany.failure(action.payload));
        }),
        catchError((error) =>
          of(registerCompany.failure(action.payload), handleError({ action, error })),
        ),
      );
    }),
  );

const checkCompanyExistsEpic: AppEpic = (action$: Observable<T.ICheckCompanyExistsRequest>) =>
  action$.pipe(
    ofType(C.CHECK_COMPANY_EXISTS),
    mergeMap((action) =>
      from(api.checkCompanyExists(action.payload.name)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(hideFullScreenLoader(), checkCompanyExists.success(response.data));
          }
          CustomHistory.navigate(routes.COMPANY_NOT_FOUND);
          return of(hideFullScreenLoader(), checkCompanyExists.success(null));
        }),
        catchError((error) =>
          of(hideFullScreenLoader(), checkCompanyExists.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

const registerEmployeeEpic: AppEpic = (action$: Observable<T.IRegisterEmployeeRequest>, state$) =>
  action$.pipe(
    ofType(C.REGISTER_EMPLOYEE),
    mergeMap((action) => {
      const data = registrationSelectors.selectEmployeeDTO(state$?.value);
      return from(
        api.registerEmployee(action.payload.companyName, { ...data, ...action.payload.body }),
      ).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(
              registerEmployee.success(action.payload),
              showToast({ message: "Validation email has been sent.", type: "success" }),
            );
          }
          return of(registerEmployee.failure(action.payload));
        }),
        catchError((error) =>
          of(registerEmployee.failure(action.payload), handleError({ action, error })),
        ),
      );
    }),
  );

const confirmRegistrationEpic: AppEpic = (action$: Observable<T.IConfirmRegistrationRequest>) =>
  action$.pipe(
    ofType(C.CONFIRM_REGISTRATION),
    mergeMap((action) =>
      from(api.confirmRegistration(action.payload.companyName, action.payload.dto)).pipe(
        mergeMap((response) => {
          if (response.data) {
            if (action.payload.role === "admin") {
              CustomHistory.navigate(`/${action.payload.companyName}${routes.COMPANY.LOGIN}`);
            } else {
              CustomHistory.navigate(`/${action.payload.companyName}${routes.EMPLOYEE.LOGIN}`);
            }

            return of(
              showToast({ type: "success", message: "Registration complete." }),
              resetRegistrationDTO(),
              confirmRegistration.success(response.data),
            );
          }
          return of(handleError({ action, error: null }), confirmRegistration.failure());
        }),
        catchError((error) => of(confirmRegistration.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getCompanyByIdEpic: AppEpic = (action$: Observable<T.IGetCompanyByIdRequest>) =>
  action$.pipe(
    ofType(C.GET_COMPANY_BY_ID),
    mergeMap((action) =>
      from(api.getCompanyById(action.payload)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getCompanyById.success(response.data));
          }
          return of(getCompanyById.failure());
        }),
        catchError((error) => of(getCompanyById.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getDashboardSummaryEpic: AppEpic = (action$: Observable<T.IGetDashboardSummary>) =>
  action$.pipe(
    ofType(C.GET_DASHBOARD_SUMMARY),
    mergeMap((action) =>
      from(api.getCompanyDashboard(action.payload?.year ?? getCurrYear())).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getDashboardSummary.success(response.data));
          }
          return of(getDashboardSummary.failure());
        }),
        catchError((error) => of(getDashboardSummary.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getAnalyticDashboardSummaryEpic: AppEpic = (
  action$: Observable<T.IGetAnalyticDashboardSummary>,
) =>
  action$.pipe(
    ofType(C.GET_ANALYTIC_DASHBOARD_SUMMARY),
    mergeMap((action) =>
      from(api.getCompanyAnalyticsImpact(action.payload?.year ?? getCurrYear())).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getAnalyticDashboardSummary.success(response.data));
          }
          return of(getAnalyticDashboardSummary.failure());
        }),
        catchError((error) =>
          of(getAnalyticDashboardSummary.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

const getEmployeeInterestsEpic: AppEpic = (action$: Observable<T.IGetEmployeeInterests>) =>
  action$.pipe(
    ofType(C.GET_EMPLOYEE_INTERESTS),
    mergeMap((action) =>
      from(api.getEmployeeInterests()).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getEmployeeInterests.success(response.data));
          }
          return of(getEmployeeInterests.failure());
        }),
        catchError((error) => of(getEmployeeInterests.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getCharitiesSupportedEpic: AppEpic = (action$: Observable<T.IGetCharitiesSupported>) =>
  action$.pipe(
    ofType(C.GET_CHARITIES_SUPPORTED),
    mergeMap((action) =>
      from(api.getCharitiesSupported(action.payload?.year ?? getCurrYear())).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getCharitiesSupported.success(response.data));
          }
          return of(getCharitiesSupported.failure());
        }),
        catchError((error) => of(getCharitiesSupported.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getDonationsEpic: AppEpic = (action$: Observable<T.IGetDonationsAnalytics>) =>
  action$.pipe(
    ofType(C.GET_DONATION_ANALYTICS),
    mergeMap((action) =>
      from(api.getDonations(action.payload?.year ?? getCurrYear())).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getDonationsAnalytics.success(response.data));
          }
          return of(getDonationsAnalytics.failure());
        }),
        catchError((error) => of(getDonationsAnalytics.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getGiftMatchingAnalyticsEpic: AppEpic = (action$: Observable<T.IGetGiftMatchingAnalytics>) =>
  action$.pipe(
    ofType(C.GET_GIFT_MATCHING_ANALYTICS),
    mergeMap((action) =>
      from(api.getGiftMatchingAnalytics(action.payload?.year ?? getCurrYear())).pipe(
        mergeMap((response) => {
          if (response.status === 200) {
            return of(getGiftMatchingAnalytics.success(response.data.data));
          }
          return of(getGiftMatchingAnalytics.failure());
        }),
        catchError((error) =>
          of(getGiftMatchingAnalytics.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

const getVolunteeringAnalyticsEpic: AppEpic = (action$: Observable<T.IGetVolunteeringAnalytics>) =>
  action$.pipe(
    ofType(C.GET_VOLUNTEERING_ANALYTICS),
    mergeMap((action) =>
      from(api.getVolunteeringAnalytics(action.payload?.year ?? getCurrYear())).pipe(
        mergeMap((response) => {
          if (response.status === 200) {
            return of(getVolunteeringAnalytics.success(response.data.data));
          }
          return of(getVolunteeringAnalytics.failure());
        }),
        catchError((error) =>
          of(getVolunteeringAnalytics.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

const updateCompanyLogoEpic: AppEpic = (action$: Observable<T.IUpdateCompanyLogo>) =>
  action$.pipe(
    ofType(C.UPDATE_COMPANY_LOGO),
    mergeMap((action) => {
      const formData = new FormData();
      formData.append("logo", action.payload.logo);
      return from(api.upateCompanyLogo(formData)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(
              updateDialogState({ key: "UPLOAD_COMPANY_LOGO", isOpened: false }),
              updateCompanyLogo.success(response),
            );
          }
          return of(
            updateCompanyLogo.failure(),
            updateDialogState({ key: "UPLOAD_COMPANY_LOGO", isOpened: false }),
          );
        }),
        catchError((error) =>
          of(
            updateCompanyLogo.failure(),
            updateDialogState({ key: "UPLOAD_COMPANY_LOGO", isOpened: false }),
            handleError({ action, error }),
          ),
        ),
      );
    }),
  );

const deleteCompanyLogoEpic: AppEpic = (action$: Observable<T.IDeleteCompanyLogo>) =>
  action$.pipe(
    ofType(C.DELETE_COMPANY_LOGO),
    mergeMap((action) =>
      from(api.deleteCompanyLogo()).pipe(
        mergeMap((response) => {
          if (response === ACCEPTED) {
            return of(deleteCompanyLogo.success());
          }
          return of(deleteCompanyLogo.failure());
        }),
        catchError((error) => of(deleteCompanyLogo.failure(), handleError({ action, error }))),
      ),
    ),
  );

const confirmFinchRegistrationEpic: AppEpic = (action$: Observable<T.IConfirmFinchRegiatration>) =>
  action$.pipe(
    ofType(C.CONFIRM_FINCH_REGISTRATION),
    mergeMap((action) =>
      from(api.confirmFinchRegistration(action.payload)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            CustomHistory.navigate(`/${action.payload.companyName}${routes.EMPLOYEE.LOGIN}`);
            return of(confirmFinchRegistration.success());
          }
          return of(confirmFinchRegistration.failure());
        }),
        catchError((error) =>
          of(confirmFinchRegistration.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export default combineEpics(
  checkCompanyInfoEpic,
  registerCompanyEpic,
  registerEmployeeEpic,
  confirmRegistrationEpic,
  getCompanyByIdEpic,
  getDashboardSummaryEpic,
  checkCompanyExistsEpic,
  updateCompanyLogoEpic,
  deleteCompanyLogoEpic,
  confirmFinchRegistrationEpic,
  getAnalyticDashboardSummaryEpic,
  getEmployeeInterestsEpic,
  getCharitiesSupportedEpic,
  getDonationsEpic,
  getGiftMatchingAnalyticsEpic,
  getVolunteeringAnalyticsEpic,
);
