import { yupResolver } from "@hookform/resolvers/yup";
import { registerPortalRoot, Spinner } from "@primer/react";
import AddressAutocompleteInput from "components/address-autocomplete-input";
import SecondaryButton from "components/buttons/secondary";
import ControlledSelect from "components/controlled-select";
import Input from "components/input";
import InputDatePicker from "components/input-date-picker";
import { XIcon } from "icons";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import volunteerEventDetailsSelectors from "store/volunteer-event-details/volunteer-event-details.selector";
import {
  postCustomVolunteerEvent,
  resetCustomVolunteerEventDto,
  saveCustomVolunteerEventDto,
} from "store/volunteer-events-charity/volunteer-events-charity.action";
import volunteerSelectors from "store/volunteer-events-charity/volunteer-events-charity.selector";
import colors from "theme/colors";
import { CustomVolunteerEventDto } from "types/volunteer";
import { TIME_ZONES } from "utils/content";
import { formatDate } from "utils/helper";
import { CUSTOM_VOLUNTEER_EVENT_SCHEMA_2 } from "utils/validation";

import * as S from "../dialogs.styled";

const CONFITMATION_MODAL_KEY = "CLOSE_POST_CHARITY_EVENT";
const STEP_2_MODAL_KEY = "POST_CUSTOM_VOLUNTEER_EVENT_STEP_2";
const buttonProps = { width: "184px", height: "50px" };

const defaultValues = {
  address: "",
  date: "",
  startTime: "",
  endTime: "",
  timezone: "",
  availableSpots: "",
};

const PostCustomVolunteerEventStep2Dialog: React.FC = () => {
  const dispatch = useDispatch();
  const prevOpenedDialog = useSelector(uiComponentsSelectors.selectPreviouslyOpenedDialog);
  const storedData = useSelector(volunteerSelectors.selectCustomVolunteerEventDto);
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, STEP_2_MODAL_KEY),
  );
  const inProgress = useSelector(volunteerEventDetailsSelectors.selectInProgress);

  const persistData =
    (prevOpenedDialog === CONFITMATION_MODAL_KEY ||
      prevOpenedDialog === "POST_CUSTOM_VOLUNTEER_EVENT_STEP_1") &&
    !!storedData;

  const [dateSelected, selectDate] = React.useState<string>("");
  const [mounted, setMounted] = React.useState<boolean>(false);
  const persistedData = storedData
    ? {
        address: storedData.address || "",
        date: storedData?.date,
        startTime: storedData?.startTime,
        endTime: storedData?.endTime,
        timezone: storedData?.timezone,
        availableSpots: storedData?.availableSpots,
      }
    : defaultValues;

  const volunteerSlotsRef = React.useRef<HTMLInputElement>(null);
  const startTimeRef = React.useRef<HTMLInputElement>(null);
  const endTimeRef = React.useRef<HTMLInputElement>(null);
  const addressRef = React.useRef<HTMLInputElement>(null);
  const outerContainerRef = React.useRef<HTMLDivElement>(null);

  const {
    control,
    handleSubmit,
    getValues,
    clearErrors,
    resetField,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: persistData ? persistedData : defaultValues,
    resolver: yupResolver(CUSTOM_VOLUNTEER_EVENT_SCHEMA_2),
    mode: "onTouched",
  });

  const resetFields = () => {
    const fieldsToReset: Array<
      "address" | "date" | "startTime" | "endTime" | "timezone" | "availableSpots"
    > = ["address", "date", "startTime", "endTime", "timezone", "availableSpots"];
    reset();
    fieldsToReset.forEach((field) => resetField(field));
    fieldsToReset.forEach((field) => clearErrors(field));
  };

  React.useEffect(() => {
    if (isOpened && !persistData) {
      resetFields();
    }
  }, [isOpened]);

  React.useEffect(() => {
    if (storedData?.date) {
      selectDate(storedData?.date);
    }
  }, [persistData]);

  React.useEffect(() => {
    // Primer hack: added for opening autocomplete in dialogs
    if (outerContainerRef.current instanceof HTMLElement) {
      registerPortalRoot(outerContainerRef.current, "outerContainer");
      setMounted(true);
    } else {
      setMounted(false);
    }
  }, [isOpened]);

  const closeDialog = (): void => {
    const dto: Partial<CustomVolunteerEventDto> = {
      address: getValues("address"),
      date: getValues("date"),
      startTime: getValues("startTime"),
      endTime: getValues("endTime"),
      timezone: getValues("timezone"),
      availableSpots: getValues("availableSpots"),
    };
    dispatch(saveCustomVolunteerEventDto(dto));
    dispatch(updateDialogState({ key: STEP_2_MODAL_KEY, isOpened: false }));
    dispatch(updateDialogState({ key: "CLOSE_POST_CHARITY_EVENT", isOpened: true }));
  };

  const onSubmit = ({ address, startTime, endTime, timezone, availableSpots }): void => {
    const dto: Partial<CustomVolunteerEventDto> = {
      address,
      date: formatDate(dateSelected, "MM-DD-YYYY"),
      startTime: startTime.toUpperCase(),
      endTime: endTime.toUpperCase(),
      timezone,
      availableSpots,
    };

    dispatch(postCustomVolunteerEvent.request(dto));
    dispatch(updateDialogState({ key: STEP_2_MODAL_KEY, isOpened: false }));
    dispatch(resetCustomVolunteerEventDto());
    setTimeout(() => resetFields(), 3000);
  };

  const handleBack = (): void => {
    const dto: Partial<CustomVolunteerEventDto> = {
      address: getValues("address"),
      date: getValues("date"),
      startTime: getValues("startTime"),
      endTime: getValues("endTime"),
      timezone: getValues("timezone"),
      availableSpots: getValues("availableSpots"),
    };
    dispatch(saveCustomVolunteerEventDto(dto));
    dispatch(updateDialogState({ key: STEP_2_MODAL_KEY, isOpened: false }));
    dispatch(updateDialogState({ key: "POST_CUSTOM_VOLUNTEER_EVENT_STEP_1", isOpened: true }));
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20} ref={outerContainerRef}>
          <S.CloseIconContainer>
            <S.CloseIconButton type="button" onClick={closeDialog}>
              <XIcon />
            </S.CloseIconButton>
          </S.CloseIconContainer>
          <S.GradientHeading fontSize={28}>Post Volunteer Event</S.GradientHeading>
          <S.Separator height={40} />
          <S.ColumnDisplay
            gridGap={30}
            style={{ maxWidth: "395px", width: "100%", boxSizing: "border-box" }}
          >
            {mounted ? (
              <AddressAutocompleteInput
                ref={addressRef}
                name="address"
                control={control}
                topLabel="Address of the event"
                placeholder="Street Address, City, State, ZIP Code"
                onEnter={handleSubmit(onSubmit)}
                errorMessage={errors?.address?.message}
                isOptionalVisible={false}
                isDisabled={false}
              />
            ) : null}
            <InputDatePicker
              control={control}
              isRequired
              topLabel="Date of the event"
              label=""
              name="date"
              error={errors?.date?.message as string}
              onChange={(date) => {
                selectDate(date);
              }}
            />
            <Input
              ref={startTimeRef}
              name="startTime"
              topLabel="Start time"
              isRequired
              placeholder="05:00 AM"
              control={control}
              sx={{ width: "100%", alignSelf: "center" }}
              onEnter={handleSubmit(onSubmit)}
              isOptionalVisible={false}
              errorMessage={errors?.startTime?.message}
            />
            <Input
              ref={endTimeRef}
              name="endTime"
              topLabel="End time"
              isRequired
              placeholder="11:00 PM"
              control={control}
              sx={{ width: "100%", alignSelf: "center" }}
              onEnter={handleSubmit(onSubmit)}
              isOptionalVisible={false}
              errorMessage={errors?.endTime?.message}
            />
            <ControlledSelect
              items={TIME_ZONES}
              control={control}
              label="Time Zone"
              sx={{ width: "100%", minWidth: "100%" }}
              name="timezone"
              required
              placeholder="Select option"
              errorMessage={errors?.timezone?.message}
            />
            <Input
              ref={volunteerSlotsRef}
              name="availableSpots"
              topLabel="Maximum number of volunteers"
              isRequired
              placeholder="10"
              control={control}
              sx={{ width: "100%", alignSelf: "center" }}
              onEnter={handleSubmit(onSubmit)}
              isOptionalVisible={false}
              errorMessage={errors?.availableSpots?.message}
            />
          </S.ColumnDisplay>
          <S.Separator height={50} />
          <S.RowDisplay gridGap={16}>
            <SecondaryButton label="Back" sx={buttonProps} onClick={handleBack} />
            <SecondaryButton
              label="Finish"
              sx={{
                ...buttonProps,
                background: colors.unitedNationsBlue(),
                color: colors.white(),
              }}
              onClick={handleSubmit(onSubmit)}
              isLoading={inProgress}
            />
          </S.RowDisplay>
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default PostCustomVolunteerEventStep2Dialog;
