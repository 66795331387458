import { PlusCircleIcon } from "@primer/octicons-react";
import { WINDOW } from "@sentry/react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PrimaryButton from "components/buttons/primary";
import React from "react";
import { useDispatch } from "react-redux";
import { showToast } from "store/ui-components/ui-components.actions";
import routes from "utils/routes";

import * as S from "./donate.styled";

const PaymentForm: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [inProgress, setInProgress] = React.useState<boolean>(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInProgress(true);
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${WINDOW.__RUNTIME_CONFIG__.REACT_APP_URL}${routes.DONATION.SUCCESS}`,
      },
    });
    event.preventDefault();
    setInProgress(false);
    if (error) {
      dispatch(showToast({ message: error.message, type: "failure" }));
    }
  };

  return (
    <>
      <S.Separator height={40} />
      <PaymentElement />
      <S.Separator height={40} />
      <PrimaryButton
        onClick={handleSubmit}
        label="Submit donation"
        leadingIcon={PlusCircleIcon}
        isLoading={inProgress}
      />
    </>
  );
};

export default PaymentForm;
