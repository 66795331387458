import { Charity, UNGoal } from "types/charity";
import { SelectItem } from "types/content";
import { EveryCharity, SearchedEveryCharity } from "types/every";
import { VolunteerEvent, VolunteerEventItem } from "types/volunteer";

import { genRandomString } from "./helper";
import { StateCodesType } from "./state-codes";

export const mapEveryCharityToCharity = (item: EveryCharity): Charity => ({
  id: item.slug,
  address: item.location,
  contactEmail: null,
  ein: item.ein,
  name: item.name,
  bankNumber: null,
  lookingFor: [],
  supportStatement: null,
  personalStatement: null,
  missionStatement: null,
  goals: [],
  createdAt: null,
  updatedAt: null,
  irsCategory: null,
  irsSubcategory: null,
  logoUrl: item.logoUrl,
  totalAssets: null,
  totalExpenses: null,
  totalRevenue: null,
  websiteUrl: null,
  mostRecentTaxFiling: null,
  backed: false,
  onboardingFinished: true,
  bankAccountVerified: true,
  documentsVerified: true,
});

export const mapSearchedCharityToCharity = (item: SearchedEveryCharity): Charity => ({
  id: item.slug,
  address: item.location,
  contactEmail: null,
  ein: null,
  name: item.name,
  bankNumber: null,
  lookingFor: [],
  supportStatement: null,
  personalStatement: null,
  missionStatement: null,
  goals: item.tags as UNGoal[],
  createdAt: null,
  updatedAt: null,
  irsCategory: null,
  irsSubcategory: null,
  logoUrl: item.logoUrl,
  totalAssets: null,
  totalExpenses: null,
  totalRevenue: null,
  websiteUrl: null,
  mostRecentTaxFiling: null,
  backed: false,
  onboardingFinished: true,
  bankAccountVerified: true,
  documentsVerified: true,
});

export const mapVolunteerEventToListEvent = (payload: VolunteerEvent): VolunteerEventItem => ({
  ...payload,
  id: payload.id,
  name: payload.name,
  status: payload.status,
  charityName: payload.charity?.name,
  charityLocation: payload.charity?.address,
  charityLogoUrl: payload.charity.logoUrl,
  createdAt: payload.createdAt,
  updatedAt: payload.updatedAt,
  startDateTime: payload.startDateTime,
  endDateTime: payload.endDateTime,
  attending: false,
});

export const mapStateCodesToSelectItems = (stateCodes: StateCodesType[]): SelectItem[] =>
  stateCodes.map((item: StateCodesType) => ({
    id: genRandomString(),
    text: item.name,
    value: item.abbreviation,
  }));
