import styled from "styled-components";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ColumnDisplay,
  FormWrapper,
  GradientHeading,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const EmailText = styled.span`
  color: ${({ theme }) => theme.unitedNationsBlue()};
`;

export const ResendCodeText = styled(EmailText)`
  color: ${({ theme }) => theme.unitedNationsBlue()};
  cursor: pointer;
`;

export const DonationsCompleteText = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${({ theme }) => theme.metallicSilver()};
`;

export const DonationsCompleteParagraph = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.15px;
  text-align: center;
  color: ${({ theme }) => theme.metallicSilver()};
`;

export const DonationsCompleteParagraphBold = styled.span`
  font-weight: 700;
`;
