export const FETCH_COMPANY_VOLUNTEER_EVENTS = "FETCH_COMPANY_VOLUNTEER_EVENTS";
export const FETCH_COMPANY_VOLUNTEER_EVENTS_SUCCESS = "FETCH_COMPANY_VOLUNTEER_EVENTS_SUCCESS";
export const FETCH_COMPANY_VOLUNTEER_EVENTS_FAILURE = "FETCH_COMPANY_VOLUNTEER_EVENTS_FAILURE";

export const REFRESH_COMPANY_VOLUNTEER_EVENTS = "REFRESH_COMPANY_VOLUNTEER_EVENTS";
export const REFRESH_COMPANY_VOLUNTEER_EVENTS_SUCCESS = "REFRESH_COMPANY_VOLUNTEER_EVENTS_SUCCESS";
export const REFRESH_COMPANY_VOLUNTEER_EVENTS_FAILURE = "REFRESH_COMPANY_VOLUNTEER_EVENTS_FAILURE";

export const SAVE_VOLUNTEER_EVENT_DTO = "SAVE_VOLUNTEER_EVENT_DTO";
export const SET_VOLUNTEER_EVENT_FILTERS = "SET_VOLUNTEER_EVENT_FILTERS";
