import { cacheSlice } from "store/store.cache";

import { IDonationsState } from "./donations.type";

export const cacheDonations = (
  initialState: IDonationsState,
  state: IDonationsState,
): IDonationsState => {
  const cachedState: IDonationsState = {
    ...initialState,
    isCached: true,
    pending: state.pending,
    fulfilled: state.fulfilled,
  };

  cacheSlice("donations", cachedState);

  return {
    ...state,
    isCached: true,
  };
};

export const clearDonationsCache = (initialState: IDonationsState): IDonationsState => {
  cacheSlice("donations", initialState);
  return initialState;
};
