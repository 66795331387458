import { useLocation, useNavigate, useParams } from "react-router-dom";

import CustomHistory from ".";

const NavigateSetter = () => {
  CustomHistory.navigate = useNavigate();
  CustomHistory.location = useLocation();
  CustomHistory.params = useParams();
  return null;
};

export default NavigateSetter;
