export const FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE =
  "FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE";
export const FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_SUCCESS =
  "FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_SUCCESS";
export const FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_FAILURE =
  "FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_FAILURE";

export const FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO =
  "FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO";
export const FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO_SUCCESS =
  "FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO_SUCCESS";
export const FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO_FAILURE =
  "FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO_FAILURE";

export const MESSAGE_ATTENDEES = "MESSAGE_ATTENDEES";
export const MESSAGE_ATTENDEES_SUCCESS = "MESSAGE_ATTENDEES_SUCCESS";
export const MESSAGE_ATTENDEES_FAILURE = "MESSAGE_ATTENDEES_FAILURE";
