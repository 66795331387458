import styled from "styled-components";
import { RowDisplay } from "theme/global-style";

export {
  ColumnDisplay,
  LinkText,
  PaginationWrapper,
  RowDisplay,
  Separator,
  Table,
} from "theme/global-style";

export const StatusText = styled(RowDisplay)<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
`;
