import styled from "styled-components";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ColumnDisplay,
  FormWrapper,
  GradientHeading,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const EmailText = styled.span`
  color: ${({ theme }) => theme.unitedNationsBlue()};
`;

export const ResendCodeText = styled(EmailText)`
  color: ${({ theme }) => theme.unitedNationsBlue()};
  cursor: pointer;
`;
