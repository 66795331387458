import styled from "styled-components";
import { ColumnDisplay, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";
import { MAX_LINES_FOR_READ_MORE } from "utils/constants";

export {
  BackIndicator,
  BubbleBackground,
  ClickableWrapper,
  ColumnDisplay,
  FormWrapper,
  LinkWrapper,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.desktopS.x;
export const MOBILE_BREAKPOINT = 600;

export const CharityViewContainer = styled(ColumnDisplay)`
  flex: 1;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${({ theme }) => theme.aliceBlue()};
`;

export const CharityViewInnerWrapper = styled(RowDisplay)`
  flex: 1;
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box;
  padding: 20px;
`;

export const CharityContentContainer = styled(ColumnDisplay)<{
  maxWidth: number;
  hidePadding?: boolean;
}>`
  height: 100%;
  min-width: 500px;
  max-width: ${({ maxWidth }) => maxWidth}px;
  background: ${({ theme }) => theme.white()};
  box-sizing: border-box;
  border-radius: 12px;
  ${({ hidePadding }) => !hidePadding && "padding: 100px 60px 60px 60px"};
  overflow: hidden;

  @media all and (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 300px;
    ${({ hidePadding }) => !hidePadding && "padding: 20px"};
  }
`;

export const CharityLogo = styled.img`
  width: 75px;
  height: auto;
  object-fit: contain;
  display: flex;
`;

export const CharityName = styled.div`
  font-size: 45px;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 0em;
  display: flex;
  height: auto;
  max-width: calc(100% - 140px);
  min-width: 200px;
  flex-shrink: 1;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const ContentDevider = styled.div`
  background: ${({ theme }) => theme.gunmetal(0.1)};
  height: 1px;
  width: 100%;
`;

export const GradientTagWrapper = styled.div`
  background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
  padding: 1px;
  border-radius: 100px;
  max-width: 100%;
`;

export const GradientTag = styled.div`
  background: ${({ theme }) => theme.white()};
  padding: 16px;
  min-width: 20px;
  border-radius: 100px;
  word-break: break-word;
  text-align: center;
  max-width: 100%;
`;

export const GradientTagText = styled.div`
  background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  word-break: break-word;
`;

export const CharityDetailsHeading = styled(RowDisplay)`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: rgba(120, 133, 137, 0.7);
  align-self: start;
  text-transform: uppercase;
`;

export const CharityCoverWrapper = styled.div`
  width: 100%;
  height: 369px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.white()};
`;

export const CharityCover = styled.img`
  height: 100%;
  width: auto; /* Maintain aspect ratio */
  object-fit: contain;
  display: block;
`;

export const CharityTextContainer = styled(ColumnDisplay)`
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
  padding-top: 45px;
  padding-bottom: 28px;

  @media all and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 20px;
  }
`;

export const CharityAbout = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: ${({ theme }) => theme.blackCoral()};
  margin-bottom: 30px;
`;

export const CharityDetails = styled.div<{ isExpanded: boolean }>`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.blackCoral()};
  ${({ isExpanded }) =>
    !isExpanded &&
    `
  display: -webkit-box;
  -webkit-line-clamp: ${MAX_LINES_FOR_READ_MORE};
  -webkit-box-orient: vertical;
  overflow: hidden;
  `}
`;

export const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.gunmetal(0.9)};
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  text-decoration: underline;
  margin-top: 38px;

  &:hover {
    color: ${({ theme }) => theme.gunmetal()};
  }
`;

export const OrganizationInfo = styled(RowDisplay)<{ isClickable?: boolean }>`
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  word-break: break-word;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 8px;
  color: ${({ theme }) => theme.blackCoral()};

  cursor: ${({ isClickable }) => isClickable && "pointer"};
`;
