import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/buttons/primary";
import FilePicker from "components/file-picker";
import Input from "components/input";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { submitOffPlatformDonation } from "store/gift-matching/gift-matching.action";
import giftMatchSelectors from "store/gift-matching/gift-matching.selector";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import {
  EIN_NUMBER_REG,
  MAX_ALLOWED_FILE_SIZE,
  MAX_DONATION,
  MIN_DONATION,
  TWO_DECIMALS_REG,
} from "utils/constants";
import * as yup from "yup";

import * as S from "../dialogs.styled";

const schema = yup.object().shape({
  amount: yup
    .number()
    .required("Please enter desired donation amount.")
    .typeError("Please enter valid number.")
    .min(MIN_DONATION, "Minimum amount is 10$.")
    .max(MAX_DONATION, "Maximum amount is 10,000$.")
    .test(
      "is-decimal",
      "Please round donation to 2 decimals.",
      (value) => !!`${value}`.match(TWO_DECIMALS_REG),
    ),
  ein: yup
    .string()
    .trim()
    .matches(EIN_NUMBER_REG, "Please enter valid EIN number.")
    .required("Please fill in all data."),
});

const OffPlatformDonationDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const [receipt, setReceipt] = React.useState<File>(null);
  const [submitted, submit] = React.useState<boolean>(false);

  const amountRef = React.useRef<HTMLInputElement>(null);
  const einRef = React.useRef<HTMLInputElement>(null);

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, "OFF_PLATFORM_DONATION"),
  );

  const inProgress = useSelector(giftMatchSelectors.selectInProgress);

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: { amount: "", ein: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  React.useEffect(() => {
    if (!isOpened) {
      submit(false);
      setReceipt(null);
      resetField("amount");
      resetField("ein");
      clearErrors("amount");
      clearErrors("ein");
    }
  }, [isOpened]);

  const onEinChange = (val: string) => {
    if (val?.length > 2 && !val.includes("-")) {
      setValue("ein", `${val.slice(0, 2)}-${val?.slice(2, val.length) ?? ""}`);
    }
  };

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: "OFF_PLATFORM_DONATION", isOpened: false }));
  };

  const onSubmit = ({ amount, ein }): void => {
    submit(true);
    if (receipt) {
      dispatch(submitOffPlatformDonation.request({ amount, ein, receipt }));
    }
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={40}>
          <S.GradientHeading fontSize={28}>Enter Donation Details</S.GradientHeading>
          <S.Separator height={40} />
          <S.ColumnDisplay gridGap={20} justifyContent="flex-start">
            <S.DialogSubheadingBold>Upload Donation Receipt</S.DialogSubheadingBold>
            <FilePicker
              file={receipt}
              setFile={setReceipt}
              buttonLabel="Donation Receipt"
              supportedTypes="application/pdf"
              pickerLabel="No file chosen."
              isErrorMode={submitted && !receipt}
              maxFileSize={MAX_ALLOWED_FILE_SIZE}
            />
            <S.DialogLabel>Upload a PDF of your donation receiept</S.DialogLabel>
          </S.ColumnDisplay>
          <S.Separator height={30} />
          <S.DialogBlueSeparator />
          <S.Separator height={30} />

          <S.ColumnDisplay
            gridGap={30}
            style={{ maxWidth: "428px", width: "100%", boxSizing: "border-box" }}
          >
            <Input
              ref={amountRef}
              name="amount"
              topLabel="Donation Amount"
              bottomLabel="Enter the amount donated as it appears on your receipt."
              sx={{ width: "100%", maxWidth: "428px", alignSelf: "center", minWidth: 200 }}
              onEnter={() => einRef?.current?.focus()}
              isRequired
              control={control}
              errorMessage={errors?.amount?.message}
            />
            <Input
              ref={einRef}
              name="ein"
              placeholder="12-3456789"
              control={control}
              onChange={onEinChange}
              topLabel="Nonprofit EIN Number"
              bottomLabel="Enter the EIN number of the nonprofit."
              sx={{ width: "100%", maxWidth: "428px", alignSelf: "center", minWidth: 200 }}
              onEnter={handleSubmit(onSubmit)}
              isRequired
              errorMessage={errors?.ein?.message}
            />
          </S.ColumnDisplay>
          <S.Separator height={50} />
          <PrimaryButton
            label="Submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={inProgress}
            sx={{ width: "100%", maxWidth: "391px", alignSelf: "center" }}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default OffPlatformDonationDialog;
