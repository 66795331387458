import SecondaryButton from "components/buttons/secondary";
import FixedHeader from "components/fixed-header";
import LogoAndCompany from "components/logo-and-company";
import { ArrowDownGradientIcon, CheckInVawyCircleIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useSearchParams } from "react-router-dom";
import authSelectors from "store/auth/auth.selector";
import charitySelectors from "store/charity/charity.selector";
import companySelectors from "store/company/company.selector";
import { matchDonation } from "store/donations/donations.action";
import { fetchUserDashboardSummary } from "store/employee-dashboard-summary/employee-dashboard-summary.action";
import employeeDashboardSummarySelectors from "store/employee-dashboard-summary/employee-dashboard-summary.selector";
import { getCompanyGiftMatch } from "store/gift-matching/gift-matching.action";
import giftMatchSelectors from "store/gift-matching/gift-matching.selector";
import paymentSelectors from "store/payment/payment.selector";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import routes from "utils/routes";

import * as S from "./payment-success.styled";

const DonationSucceedPage: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams("");
  const paymentId = searchParams.get("payment_intent");

  const companyName = useSelector(companySelectors.selectName);
  const charity = useSelector(charitySelectors.selectData);

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const summary = useSelector(employeeDashboardSummarySelectors.selectData);

  const giftMatchInfo = useSelector(giftMatchSelectors.selectData);
  const isGiftMatchFetched = useSelector(giftMatchSelectors.selectIsFetched);

  const donatedAmount = useSelector(paymentSelectors.selectDonationAmount);

  const isApplicable =
    !giftMatchInfo ||
    (paymentId &&
      giftMatchInfo?.active &&
      giftMatchInfo?.minSum <= donatedAmount &&
      giftMatchInfo?.maxSum >= donatedAmount &&
      (summary?.totalMatchedValue ?? 0) + donatedAmount <= giftMatchInfo.maxSum);

  React.useEffect(() => {
    if (!isGiftMatchFetched) {
      dispatch(getCompanyGiftMatch.request());
    }
  }, [isGiftMatchFetched]);

  React.useEffect(() => {
    dispatch(fetchUserDashboardSummary.request());
  }, []);

  React.useEffect(() => {
    dispatch(updateDialogState({ key: "DONATE", isOpened: false }));
  }, []);

  const onDonationMatch = (): void => {
    dispatch(matchDonation.request({ paymentId }));
  };

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <FixedHeader name={companyName} />
      <S.FormWrapper>
        <S.RowDisplay gridGap={16}>
          <CheckInVawyCircleIcon />
          <S.DonationsCompleteText>Donation Complete</S.DonationsCompleteText>
        </S.RowDisplay>

        <S.Separator height={20} />
        <S.DonationsCompleteParagraph>
          Your donation to{" "}
          <S.DonationsCompleteParagraphBold>{charity?.name ?? ""}</S.DonationsCompleteParagraphBold>{" "}
          has been successfully processed.
        </S.DonationsCompleteParagraph>
        {isApplicable ? (
          <>
            <S.Separator height={20} />
            <ArrowDownGradientIcon />
            <S.Separator height={40} />

            <S.GradientHeading>Would you like to submit a gift match request?</S.GradientHeading>
            <S.Separator height={40} />
            <S.RowDisplay gridGap={24}>
              <SecondaryButton
                label="Yes"
                onClick={onDonationMatch}
                sx={{ width: isTablet ? "100px" : "184px" }}
              />
              <SecondaryButton
                label="No"
                onClick={() => navigate(routes.FIND_CHARITIES)}
                sx={{ width: isTablet ? "100px" : "184px" }}
              />
            </S.RowDisplay>
          </>
        ) : (
          <>
            <S.Separator height={40} />
            <SecondaryButton
              label="Return to Homepage"
              onClick={() => navigate(routes.FIND_CHARITIES)}
              sx={{ width: isTablet ? "100px" : "184px" }}
            />
          </>
        )}
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default DonationSucceedPage;
