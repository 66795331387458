export const REGISTER_CHARITY = "REGISTER_CHARITY";
export const REGISTER_CHARITY_SUCCESS = "REGISTER_CHARITY_SUCCESS";
export const REGISTER_CHARITY_FAILURE = "REGISTER_CHARITY_FAILURE";

export const CONFIRM_CHARITY_REGISTRATION = "CONFIRM_CHARITY_REGISTRATION";
export const CONFIRM_CHARITY_REGISTRATION_SUCCESS = "CONFIRM_CHARITY_REGISTRATION_SUCCESS";
export const CONFIRM_CHARITY_REGISTRATION_FAILURE = "CONFIRM_CHARITY_REGISTRATION_FAILURE";

export const CONFIRM_CHARITY_ADMIN_REGISTRATION = "CONFIRM_CHARITY_ADMIN_REGISTRATION";
export const CONFIRM_CHARITY_ADMIN_REGISTRATION_SUCCESS =
  "CONFIRM_CHARITY_ADMIN_REGISTRATION_SUCCESS";
export const CONFIRM_CHARITY_ADMIN_REGISTRATION_FAILURE =
  "CONFIRM_CHARITY_ADMIN_REGISTRATION_FAILURE";

export const SET_CHARITY_DETAILS = "SET_CHARITY_DETAILS";
export const SET_CHARITY_DETAILS_SUCCESS = "SET_CHARITY_DETAILS_SUCCESS";
export const SET_CHARITY_DETAILS_FAILURE = "SET_CHARITY_DETAILS_FAILURE";

export const CHANGE_CHARITY_MISSION = "CHANGE_CHARITY_MISSION";
export const CHANGE_CHARITY_MISSION_SUCCESS = "CHANGE_CHARITY_MISSION_SUCCESS";
export const CHANGE_CHARITY_MISSION_FAILURE = "CHANGE_CHARITY_MISSION_FAILURE";

export const GET_CHARITY_BY_ID = "GET_CHARITY_BY_ID";
export const GET_CHARITY_BY_ID_SUCCESS = "GET_CHARITY_BY_ID_SUCCESS";
export const GET_CHARITY_BY_ID_FAILURE = "GET_CHARITY_BY_ID_FAILURE";

export const UPDATE_CHARITY_UN_GOALS = "UPDATE_CHARITY_UN_GOALS";
export const UPDATE_CHARITY_UN_GOALS_SUCCESS = "UPDATE_CHARITY_UN_GOALS_SUCCESS";
export const UPDATE_CHARITY_UN_GOALS_FAILURE = "UPDATE_CHARITY_UN_GOALS_FAILURE";

export const INVITE_CHARITY_ADMIN = "INVITE_CHARITY_ADMIN";
export const INVITE_CHARITY_ADMIN_SUCCESS = "INVITE_CHARITY_ADMIN_SUCCESS";
export const INVITE_CHARITY_ADMIN_FAILURE = "INVITE_CHARITY_ADMIN_FAILURE";
