import PrimaryButton from "components/buttons/primary";
import SecondaryButton from "components/buttons/secondary";
import ControlledSelect from "components/controlled-select";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import companySelectors from "store/company/company.selector";
import { removeUser } from "store/manage-people/manage-people.action";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import colors from "theme/colors";

import * as S from "../dialogs.styled";

const RemoveEmployeeDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, "REMOVE_EMPLOYEE"),
  );

  const properties = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogProperties(state, "REMOVE_EMPLOYEE"),
  );

  const companyName = useSelector(companySelectors.selectName);

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: "REMOVE_EMPLOYEE", isOpened: false }));
  };

  const onConfirm = (): void => {
    dispatch(removeUser.request({ companyName, userId: properties?.userId }));
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper>
          <S.GradientHeading fontSize={28}>
            Are you sure you want to
            <br /> remove employee?
          </S.GradientHeading>
          <S.Separator height={58} />
          <S.RowDisplay gridGap={16}>
            <SecondaryButton
              label="Cancel"
              sx={{ width: "184px", height: "50px" }}
              onClick={closeDialog}
            />
            <SecondaryButton
              label="Remove"
              sx={{ width: "184px", height: "50px", color: colors.bordo() }}
              onClick={onConfirm}
            />
          </S.RowDisplay>
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default RemoveEmployeeDialog;
