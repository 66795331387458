import * as api from "api/company";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { OK } from "utils/status-code";

import { getAdvancedAnalytics } from "./advanced-analytics.action";
import * as C from "./advanced-analytics.constants";
import * as T from "./advanced-analytics.type";

const getAdvancedAnalyticsEpic: AppEpic = (action$: Observable<T.IGetAdvancedAnalytics>) =>
  action$.pipe(
    ofType(C.GET_ADVANCED_ANALYTICS),
    mergeMap((action) =>
      from(api.getAdvancedAnalytics(action.payload)).pipe(
        mergeMap((response) => {
          if (response.status === OK) {
            return of(
              getAdvancedAnalytics.success({
                ...action.payload,
                ...{ response: response.data.data?.[Object.keys(response.data.data)[0]] },
              }),
            );
          }
          return of(getAdvancedAnalytics.failure());
        }),
        catchError((error) => of(getAdvancedAnalytics.failure(), handleError({ action, error }))),
      ),
    ),
  );

export default combineEpics(getAdvancedAnalyticsEpic);
