import { CHECK_COMPANY_EXISTS } from "store/company/company.constants";
import { ICheckCompanyExistsRequest } from "store/company/company.type";

import { cacheAuth, clearAuthCache } from "./auth.cache";
import * as C from "./auth.constants";
import * as T from "./auth.type";

export const authInitial: T.IAuthState = {
  isCached: false,
  inProgress: false,
  error: null,
  token: null,
  role: null,
  lastCompanyCheck: null,
};

const cache = (state: T.IAuthState) => cacheAuth(authInitial, state);

const authReducer = (
  state: T.IAuthState,
  action: T.AuthAction | ICheckCompanyExistsRequest,
): T.IAuthState => {
  if (!state) {
    return authInitial;
  }

  switch (action.type) {
    case C.SIGN_IN_CREDENTIALS:
    case C.SEND_CODE_EMPLOYEE:
    case C.SIGN_IN_CODE:
    case C.RESEND_CODE:
    case C.RESET_PASSWORD:
    case C.SET_NEW_PASSWORD:
    case C.RESEND_VERIFICATION_EMAIL:
      return {
        ...state,
        inProgress: true,
      };
    case C.SIGN_IN_CREDENTIALS_SUCCESS:
    case C.SIGN_IN_CODE_SUCCESS:
      return cache({
        ...state,
        ...action.payload,
        inProgress: false,
      });
    case C.SIGN_IN_CREDENTIALS_FAILURE:
    case C.SEND_CODE_EMPLOYEE_FAILURE:
    case C.SEND_CODE_EMPLOYEE_SUCCESS:
    case C.SIGN_IN_CODE_FAILURE:
    case C.RESEND_CODE_SUCCESS:
    case C.RESET_PASSWORD_SUCCESS:
    case C.SET_NEW_PASSWORD_SUCCESS:
    case C.RESEND_CODE_FAILURE:
    case C.RESET_PASSWORD_FAILURE:
    case C.SET_NEW_PASSWORD_FAILURE:
    case C.RESEND_VERIFICATION_EMAIL_SUCCESS:
    case C.RESEND_VERIFICATION_EMAIL_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.STORE_AUTH_TOKEN:
      return cache({ ...state, token: action.payload });
    case CHECK_COMPANY_EXISTS:
      return {
        ...state,
        lastCompanyCheck: action.payload.name,
      };
    case C.LOGOUT:
      return { ...clearAuthCache(authInitial), role: state.role };
    default:
      return state;
  }
};

export default authReducer;
