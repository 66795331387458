import axios from "axios";
import { ServerResponse } from "types/common";
import {
  EveryCharity,
  EveryCharityDetails,
  EveryGetCharitiesRequest,
  SearchedEveryCharity,
} from "types/every";
import { EVERY_ORG_CHARITIES_LIMIT, EVERY_ORG_CHARITIES_SEARCH_LIMIT } from "utils/constants";
import { genUrlParams } from "utils/helper";

import client from "./client";

export const getEveryCharities = async (
  props: EveryGetCharitiesRequest,
): Promise<ServerResponse<{ charities: EveryCharity[] }>> =>
  (await client.get(genUrlParams("/every/charities", props))).data;

export const getEveryCharityDetails = async (
  slug: string,
): Promise<ServerResponse<EveryCharityDetails>> =>
  (await client.get(genUrlParams("/every/charity/details", { slug }))).data;

export const searchEveryDotOrgCharities = async (
  searchTerm: string,
): Promise<{ nonprofits: SearchedEveryCharity[] }> =>
  (
    await axios.get(
      `https://partners.every.org/v0.2/search/${searchTerm}?apiKey=${window.__RUNTIME_CONFIG__.EVERY_DOT_ORG_API_KEY}&take=${EVERY_ORG_CHARITIES_SEARCH_LIMIT}`,
    )
  ).data;
