import { yupResolver } from "@hookform/resolvers/yup";
import SecondaryButton from "components/buttons/secondary";
import ControlledSelect from "components/controlled-select";
import Input from "components/input";
import InputDatePicker from "components/input-date-picker";
import { XIcon } from "icons";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { activatePendingCompanyVolunteerEvent } from "store/company-pending-volunteer-events/company-pending-volunteer-events.action";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import volunteerSelectors from "store/volunteer-events-charity/volunteer-events-charity.selector";
import colors from "theme/colors";
import { TIME_ZONES } from "utils/content";
import { ACTIVE_EVENT_SCHEMA } from "utils/validation";

import * as S from "../dialogs.styled";

const DIALOG_KEY = "ACTIVATE_EVENT";
const buttonProps = { maxWidth: "395px", height: "50px" };
const defaultValues = { date: "", startTime: "", endTime: "", timezone: "", availableSpots: "" };

const ActivateEventDialog: React.FC = () => {
  const dispatch = useDispatch();
  const [dateSelected, selectDate] = React.useState<string>("");

  const volunteerSlotsRef = React.useRef<HTMLInputElement>(null);
  const startTimeRef = React.useRef<HTMLInputElement>(null);
  const endTimeRef = React.useRef<HTMLInputElement>(null);

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, DIALOG_KEY),
  );

  const properties = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogProperties(state, DIALOG_KEY),
  );
  const eventId = properties?.id || "";

  const inProgress = useSelector(volunteerSelectors.selectInProgress);

  const {
    control,
    handleSubmit,
    clearErrors,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(ACTIVE_EVENT_SCHEMA),
    mode: "onTouched",
  });

  React.useEffect(() => {
    if (isOpened) {
      const fieldsToReset: Array<"date" | "startTime" | "endTime" | "timezone" | "availableSpots"> =
        ["date", "startTime", "endTime", "timezone", "availableSpots"];
      fieldsToReset.forEach((field) => resetField(field));
      fieldsToReset.forEach((field) => clearErrors(field));
    }
  }, [isOpened]);

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
  };

  const onSubmit = ({ startTime, endTime, timezone, availableSpots }): void => {
    if (eventId) {
      dispatch(
        activatePendingCompanyVolunteerEvent.request({
          id: eventId,
          date: dateSelected,
          startTime: startTime.toUpperCase(),
          endTime: endTime.toUpperCase(),
          timezone,
          availableSpots,
        }),
      );
      closeDialog();
    }
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20}>
          <S.CloseIconContainer>
            <S.CloseIconButton type="button" onClick={closeDialog}>
              <XIcon />
            </S.CloseIconButton>
          </S.CloseIconContainer>
          <S.GradientHeading fontSize={28}>Add Volunteer Day Details</S.GradientHeading>
          <S.Separator height={40} />
          <S.ColumnDisplay
            gridGap={30}
            style={{ maxWidth: "395px", width: "100%", boxSizing: "border-box" }}
          >
            <InputDatePicker
              label=""
              control={control}
              name="date"
              error={errors?.date?.message as string}
              onChange={(date) => {
                selectDate(date);
              }}
            />
            <Input
              ref={startTimeRef}
              name="startTime"
              topLabel="Start time"
              isRequired
              placeholder="05:00 AM"
              control={control}
              sx={{ width: "100%", alignSelf: "center" }}
              onEnter={handleSubmit(onSubmit)}
              isOptionalVisible={false}
              errorMessage={errors?.startTime?.message}
            />
            <Input
              ref={endTimeRef}
              name="endTime"
              topLabel="End time"
              isRequired
              placeholder="11:00 PM"
              control={control}
              sx={{ width: "100%", alignSelf: "center" }}
              onEnter={handleSubmit(onSubmit)}
              isOptionalVisible={false}
              errorMessage={errors?.endTime?.message}
            />
            <ControlledSelect
              items={TIME_ZONES}
              control={control}
              label="Time Zone"
              sx={{ width: "100%", minWidth: "100%" }}
              name="timezone"
              required
              placeholder="Select option"
              errorMessage={errors?.timezone?.message}
            />
            <Input
              ref={volunteerSlotsRef}
              name="availableSpots"
              topLabel="Maximum number of volunteers"
              isRequired
              placeholder="10"
              control={control}
              sx={{ width: "100%", alignSelf: "center" }}
              onEnter={handleSubmit(onSubmit)}
              isOptionalVisible={false}
              errorMessage={errors?.availableSpots?.message}
            />
          </S.ColumnDisplay>
          <S.Separator height={50} />
          <SecondaryButton
            label="Publish Event"
            sx={{ ...buttonProps, background: colors.unitedNationsBlue(), color: colors.white() }}
            onClick={handleSubmit(onSubmit)}
            isLoading={inProgress}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default ActivateEventDialog;
