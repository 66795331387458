import { CheckCircleIcon, CircleIcon } from "@primer/octicons-react";
import React from "react";
import { useMediaQuery } from "react-responsive";
import colors from "theme/colors";

import * as S from "./checkbox-inline.styled";
import * as T from "./checkbox-inline.type";

const CheckboxInline: React.FC<T.ICheckboxInlineProps> = ({
  items,
  fontSize,
  padding,
  isErrorMode = false,
  isDisabled = false,
}): JSX.Element => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const ICON_SIZE = isTablet ? 10 : 16;

  return (
    <S.RowDisplay>
      {items.map((item, i) => (
        <S.ChipStyled
          padding={padding}
          isDisabled={isDisabled}
          key={item.id}
          isSelected={item.isSelected}
          type={i === 0 ? "left" : i === items.length - 1 ? "right" : "middle"}
          gridGap={4}
          onClick={() => !isDisabled && item.onSelect(item.value)}
          isErrorMode={isErrorMode}
        >
          {!isTablet && (
            <>
              {item.isSelected ? (
                <CheckCircleIcon size={ICON_SIZE} fill={colors.unitedNationsBlue()} />
              ) : (
                <CircleIcon size={ICON_SIZE} fill={colors.mantee()} />
              )}
            </>
          )}
          <S.ChipText isSelected={item.isSelected} fontSize={fontSize}>
            {item.title}
          </S.ChipText>
        </S.ChipStyled>
      ))}
    </S.RowDisplay>
  );
};

export default CheckboxInline;
