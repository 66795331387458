import styled from "styled-components";
import { RowDisplay } from "theme/global-style";

export { ColumnDisplay, RowDisplay } from "theme/global-style";

export const DateInputFrame = styled(RowDisplay)<{ isFocused?: boolean; isInvalid: boolean }>`
  width: 391px;
  min-width: 322px;
  height: 53px;
  box-sizing: border-box;
  padding: 9px 16px 9px 16px;
  border: 1px solid
    ${({ isFocused, isInvalid, theme }) =>
      !isFocused && !isInvalid
        ? "rgba(208, 215, 222, 1)"
        : isInvalid
        ? theme.bordo()
        : theme.lightSilver()};
  cursor: pointer;
  grid-gap: 8px;
  border-radius: 6px;
`;

export const DateRangeLabel = styled.div<{ isFocused?: boolean }>`
  color: ${({ isFocused }) => (isFocused ? "rgba(97, 133, 226, 1)" : "rgba(120, 133, 137, 1)")};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

export const DateInputLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const TransparentInput = styled.input<{ isHidden?: boolean }>`
  border: none;
  outline: none;
  ${({ isHidden }) => (isHidden ? "height: 0px; width: 0px;" : "width: 90%;")}
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.charcoalGray()};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.charcoalGray(0.3)};
  }
`;

export const CustomInputError = styled(RowDisplay)<{ fontSize?: number }>`
  font-size: ${({ fontSize = 12 }) => fontSize}px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.bordo()};
`;

export const DateFormatted = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: color: ${({ theme }) => theme.gunmetal()};
`;

export const ResetFilters = styled.div<{ isDisabled: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  color: ${({ isDisabled, theme }) => (isDisabled ? "rgba(94, 107, 122, 0.2)" : theme.bordo())};
`;

export const DatePickerTopLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: color: ${({ theme }) => theme.gunmetal()};
  marginBottom: 4px;
`;
