import { SearchIcon, XIcon } from "@primer/octicons-react";
import { Spinner } from "@primer/react";
import { CharityIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  displaySearchDropdown,
  resetSearchTerm,
  searchEveryOrgCharities,
} from "store/every-org-search/every-org-search.action";
import everyOrgSearchSelectors from "store/every-org-search/every-org-search.selector";
import { IAppState } from "store/store.state";
import colors from "theme/colors";
import { genRandomString } from "utils/helper";
import routes from "utils/routes";

import * as S from "./everydotorg-search.styled";

const EveryDotOrgSearchInput: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchedCharities = useSelector((state: IAppState) =>
    everyOrgSearchSelectors.selectData(state, "dropdown"),
  );
  const searchTerm = useSelector(everyOrgSearchSelectors.selectSearchedTerm);
  const isSearching = useSelector((state: IAppState) =>
    everyOrgSearchSelectors.selectInProgress(state, "dropdown"),
  );
  const isDropdownOpen = useSelector(everyOrgSearchSelectors.selectDropdownDisplayed);

  const [searchValue, setSearchValue] = React.useState<string>(searchTerm);

  React.useEffect(() => {
    if (searchTerm !== searchValue) {
      setSearchValue(searchTerm);
    }
  }, [searchTerm]);

  const closeDropdown = (): void => {
    dispatch(displaySearchDropdown(false));
  };

  const onSearchChange = (value: string): void => {
    setSearchValue(value);
    dispatch(searchEveryOrgCharities.request({ searchTerm: value, showOnPage: false }));
  };

  const onSlugSelect = (slug: string): void => {
    navigate(`${routes.EVERY_DOT_ORG.CHARITY_DETAILS}/${slug}`);
    closeDropdown();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") {
      closeDropdown();
      navigate(`${routes.EVERY_DOT_ORG.SEARCH}/${searchTerm}`);
    }
  };

  return (
    <S.SearchInputRow width="100%" justifyContent="flex-start" style={{ zIndex: 1 }}>
      {searchTerm?.length > 0 && isDropdownOpen && (
        <S.SearchDropdown justifyContent="flex-start" alignItems="flex-start" flexWrap>
          {isSearching ? (
            <S.SearchLoadingWrapper>
              <Spinner />
            </S.SearchLoadingWrapper>
          ) : (
            <>
              {searchedCharities?.map((item) => (
                <S.SearchItem
                  key={genRandomString()}
                  justifyContent="flex-start"
                  gridGap={8}
                  onClick={(e) => onSlugSelect(item.slug)}
                >
                  {item.logoUrl ? (
                    <S.SearchItemLogo src={item.logoUrl} />
                  ) : (
                    <S.SearchItemLogoWrapper>
                      <CharityIcon height={20} width={20} />
                    </S.SearchItemLogoWrapper>
                  )}
                  <S.SearchedText>{item.name}</S.SearchedText>
                </S.SearchItem>
              ))}
            </>
          )}
        </S.SearchDropdown>
      )}

      <S.GradientWrapper>
        <S.InputContainer justifyContent="flex-start" gridGap={16}>
          <SearchIcon fill={colors.unitedNationsBlue()} size={30} />
          <S.SearchInput
            placeholder="Search by name, EIN, location or keywords"
            value={searchValue}
            onChange={(e) => {
              if (e.target.value?.length > 0 && !isDropdownOpen) {
                dispatch(displaySearchDropdown(true));
              }
              onSearchChange(e.target.value);
            }}
            onKeyDown={onKeyDown}
          />
          <S.ClickableWrapper onClick={() => dispatch(resetSearchTerm())}>
            <XIcon fill={colors.unitedNationsBlue()} size={30} />
          </S.ClickableWrapper>
        </S.InputContainer>
      </S.GradientWrapper>
    </S.SearchInputRow>
  );
};

export default EveryDotOrgSearchInput;
