import { Button, Spinner } from "@primer/react";
import React from "react";
import colors from "theme/colors";

import { IButtonProps } from "../button.type";

const SmallButton: React.FC<IButtonProps> = ({
  onClick,
  isLoading,
  isDisabled,
  label,
  sx = null,
  leadingIcon,
  trailingIcon,
}): JSX.Element => (
  <Button
    onClick={onClick}
    disabled={isDisabled || isLoading}
    variant="outline"
    leadingIcon={leadingIcon}
    trailingIcon={trailingIcon}
    sx={{
      color: colors.charcoalGray(),
      background: colors.white(),
      height: 42,
      border: 0,
      opacity: isDisabled || isLoading ? 0.5 : 1,
      boxShadow: "0px 1px 3px 0px rgba(27, 31, 36, 0.12), 0px 8px 24px 0px rgba(66, 74, 83, 0.12)",
      ":hover": {
        color: colors.charcoalGray(),
        background: colors.ghostWhite(),
      },
      ...sx,
    }}
  >
    {isLoading ? <Spinner size="small" sx={{ marginTop: "4px" }} /> : label}
  </Button>
);

export default SmallButton;
