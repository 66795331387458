import LogoAndCompany from "components/logo-and-company";
import { LogoWhiteIcon } from "icons";
import React from "react";
import { useMediaQuery } from "react-responsive";

import * as S from "./charity-auth-form.styled";

interface IAuthFormTemplate {
  children: JSX.Element;
}

const CharityAuthFormTemplate: React.FC<IAuthFormTemplate> = ({ children }): JSX.Element => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const isTabletL = useMediaQuery({
    query: `(max-width: ${S.TABLET_L_BREAKPOINT}px)`,
  });

  const elementSX: React.CSSProperties = {
    width: !isTablet ? 391 : 280,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const gapBig = isTabletL ? 40 : 60;

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      {!isTablet && <div />}
      <S.DoubleFormWrapper
        justifyContent="space-between"
        alignItems="flex-start"
        gridGap={isTablet ? 16 : 0}
      >
        {isTablet ? (
          <>
            <LogoAndCompany companyName={null} showMargin />
            <S.MobileFormContainer alignItems="center" justifyContent="center">
              <S.CoverFrame alignItems="flex-start" justifyContent="center">
                <S.CoverFrameInnerContent alignItems="flex-start" justifyContent="center">
                  <S.WelcomeText>Welcome to</S.WelcomeText>
                  <S.Separator height={8} />
                  <S.HeadingText>Fundwurx</S.HeadingText>
                  <S.Separator height={24} />
                  <S.LineSeparator />
                  <S.Separator height={16} />
                  <S.ParagraphWhite style={{ maxWidth: "371px" }}>
                    We are committed to helping you advance your mission forward!
                  </S.ParagraphWhite>
                </S.CoverFrameInnerContent>
              </S.CoverFrame>
              <S.CoverFrameAbsolute animationDuration={1} iterationCount={2} />

              {/* <LogoMockedIcon /> */}
              <S.Separator height={gapBig} />
              <S.ColumnDisplay
                alignItems="flex-start"
                justifyContent="flex-start"
                style={elementSX}
                gridGap={16}
              >
                <S.LabelBold>Fundwurx for</S.LabelBold>
                <S.GradientHeadingExtended fontSize={48}>Nonprofits</S.GradientHeadingExtended>
                {children}
              </S.ColumnDisplay>
            </S.MobileFormContainer>
          </>
        ) : (
          <>
            <S.FormWrapper
              alignItems="flex-start"
              justifyContent="flex-start"
              style={{ height: "100%" }}
            >
              <S.Separator height={gapBig} />
              <S.LabelBold>Fundwurx for</S.LabelBold>
              <S.GradientHeadingExtended fontSize={48}>Nonprofits</S.GradientHeadingExtended>
              {children}
            </S.FormWrapper>

            <S.CoverFrame alignItems="flex-start" justifyContent="center">
              <S.CoverFrameInnerContent alignItems="flex-start" justifyContent="center">
                <S.WelcomeText>Welcome to</S.WelcomeText>
                <S.Separator height={8} />
                <S.HeadingText>Fundwurx</S.HeadingText>
                <S.Separator height={24} />
                <S.LineSeparator />
                <S.Separator height={16} />
                <S.ParagraphWhite style={{ maxWidth: "361px" }}>
                  We are committed to helping you advance your mission forward!
                </S.ParagraphWhite>
              </S.CoverFrameInnerContent>
              <S.LogoWrapper>
                <LogoWhiteIcon />
              </S.LogoWrapper>
            </S.CoverFrame>
            <S.CoverFrameAbsolute animationDuration={1} iterationCount={2} />
          </>
        )}
      </S.DoubleFormWrapper>
      {!isTablet && <LogoAndCompany companyName={null} showMargin />}
    </S.BubbleBackground>
  );
};

export default CharityAuthFormTemplate;
