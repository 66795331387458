import axios from "axios";
import storageKeys from "utils/storage-keys";

const client = axios.create({
  baseURL: window.__RUNTIME_CONFIG__.REACT_APP_API_URL,
  headers: {
    Authorization: localStorage.getItem(storageKeys.AUTH_TOKEN),
  },
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(storageKeys.AUTH_TOKEN);
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default client;
