import SecondaryButton from "components/buttons/secondary";
import { CharityIcon } from "icons";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import colors from "theme/colors";
import { VolunteerEventItem } from "types/volunteer";
import { formatDate, formatTime, verifyImg } from "utils/helper";
import routes from "utils/routes";

import * as S from "./company-volunteer-event.styled";

const btnStyle = {
  maxWidth: "100%",
  width: "216px",
  alignSelf: "center",
};

const CompanyEventCard: React.FC<VolunteerEventItem> = ({
  charityLocation,
  name,
  id,
  charityLogoUrl,
  status,
  endDateTime,
}): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isPastEventCard = moment(endDateTime).isBefore(moment());

  const [imgValid, setImgValid] = React.useState<boolean>(true);

  React.useEffect(() => {
    verifyImg(charityLogoUrl, setImgValid);
  }, [charityLogoUrl]);

  const viewSignUps = (): void => {
    navigate(`${routes.VOLUNTEER.EVENT_ATTENDEES}/${id}/1`);
  };

  return (
    <S.EventCardContainer
      onClick={() =>
        status !== "PENDING" &&
        !isPastEventCard &&
        navigate(`${routes.VOLUNTEER.EVENT}/${id}`, { state: { isGlobal: true } })
      }
    >
      <S.EventCardGradientContainer />
      <S.EventCardInnerContainer alignItems="flex-start" justifyContent="space-between">
        {charityLogoUrl && imgValid ? (
          <S.CharityImg src={charityLogoUrl} alt="" />
        ) : (
          <CharityIcon />
        )}
        <S.Separator height={12} />
        <S.EventName>{name}</S.EventName>
        <S.Separator height={12} />
        <S.EventAddress>{charityLocation}</S.EventAddress>
        {isPastEventCard && (
          <S.EventDate> {`${formatDate(endDateTime, "MMM Do, YYYY")}`}</S.EventDate>
        )}
        <S.Separator height={30} />

        {status === "PENDING" && (
          <S.ColumnDisplay gridGap={8} style={btnStyle}>
            <SecondaryButton
              label="Switch to Active Event"
              onClick={() =>
                dispatch(
                  updateDialogState({
                    key: "ACTIVATE_EVENT",
                    isOpened: true,
                    properties: { id },
                  }),
                )
              }
              sx={btnStyle}
            />
            <SecondaryButton
              label="Cancel"
              onClick={() =>
                dispatch(
                  updateDialogState({
                    key: "CANCEL_EVENT",
                    isOpened: true,
                    properties: { id },
                  }),
                )
              }
              sx={{
                ...btnStyle,
                color: colors.bordo(),
                ":hover": { background: colors.white(), color: colors.bordo() },
              }}
            />
          </S.ColumnDisplay>
        )}
        {isPastEventCard && (
          <SecondaryButton label="View Sign-ups" onClick={viewSignUps} sx={btnStyle} />
        )}
      </S.EventCardInnerContainer>
    </S.EventCardContainer>
  );
};

export default CompanyEventCard;
