import * as C from "./company-donations.constants";
import * as T from "./company-donations.type";

export const getCompanyDonations = {
  request: (payload: T.IGetCompanyDonations["payload"]): T.IGetCompanyDonations => ({
    type: C.GET_COMPANY_DONATIONS,
    payload,
  }),
  success: (payload: T.IGetCompanyDonationsSuccess["payload"]): T.IGetCompanyDonationsSuccess => ({
    type: C.GET_COMPANY_DONATIONS_SUCCESS,
    payload,
  }),
  failure: (): T.IGetCompanyDonationsFailure => ({
    type: C.GET_COMPANY_DONATIONS_FAILURE,
  }),
};
