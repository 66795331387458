import { OrganizationIcon, PersonIcon } from "@primer/octicons-react";
import { ButtonGroup } from "@primer/react";
import GrayButton from "components/buttons/gray";
import FixedHeader from "components/fixed-header";
import React from "react";
import { AdminTabType } from "types/charity";

import * as S from "../charity-grid.styled";
import * as T from "../charity-grid.type";
import FilterAndSearchCharities from "./filter-search-charities";

const AdminControls: React.FC<T.IControlsProps<AdminTabType>> = ({
  selectedTab,
  selectTab,
  companyName,
  charitiesNum,
}): JSX.Element => (
  <>
    <S.TopContainer>
      <S.TopInnerContainer>
        <FixedHeader name={companyName} />
        <S.TabContainer
          justifyContent="space-between"
          alignItems="flex-start"
          gridGap={20}
          flexWrap
        >
          <S.SectionGradientHeading hideMargins style={{ margin: 0 }}>
            {selectedTab === "add_charity"
              ? "Add company charities"
              : `Charities promoted by ${companyName}`}
            {selectedTab === "wall" && (
              <>
                <S.Separator height={8} />{" "}
                <S.SectionGradientSubHeading hideMargins style={{ margin: 0 }}>
                  {`${charitiesNum || 0} charities`}
                </S.SectionGradientSubHeading>
              </>
            )}
          </S.SectionGradientHeading>

          <ButtonGroup sx={S.TAB_GROUP_STYLE}>
            <GrayButton
              label="Add charities"
              onClick={() => selectTab("add_charity")}
              leadingIcon={PersonIcon}
              sx={{
                ...(selectedTab === "add_charity" ? S.SELECTED_TAB_STYLE : S.UNSELECTED_TAB_STYLE),
                height: "45px",
                width: "181px",
              }}
            />
            <GrayButton
              label="Wall"
              onClick={() => selectTab("wall")}
              leadingIcon={OrganizationIcon}
              sx={{
                ...(selectedTab === "wall" ? S.SELECTED_TAB_STYLE : S.UNSELECTED_TAB_STYLE),
                height: "45px",
                width: "110px",
              }}
            />
          </ButtonGroup>
        </S.TabContainer>

        <S.Separator height={48} />
        {selectedTab === "add_charity" && (
          <S.AdminControlsContainer alignItems="flex-start" justifyContent="flex-start">
            <FilterAndSearchCharities />
          </S.AdminControlsContainer>
        )}
      </S.TopInnerContainer>
    </S.TopContainer>
  </>
);

export default AdminControls;
