import { ICharityListSelectors } from "./charities.type";

const CharityListSelectors: ICharityListSelectors = {
  selectInProgress: (state) => state.charityList.inProgress,
  selectLoadingSections: (state) => state.charityList.loadingSections,
  selectIsFetched: (state) => state.charityList.isFetched,
  selectError: (state) => state.charityList.error,
  selectIsWallEndReached: (state) => state.charityList.isWallEndReached,
  selectData: (state, tab) => state.charityList?.data?.[tab],
};

export default CharityListSelectors;
