import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowLeftIcon } from "@primer/octicons-react";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { signInCredentials } from "store/auth/auth.action";
import authSelectors from "store/auth/auth.selector";
import AuthFormTemplate from "templates/auth-form";
import { EMAIL_REG } from "utils/constants";
import routes from "utils/routes";
import * as yup from "yup";

import * as S from "./company-admin-login.styled";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .matches(EMAIL_REG, "E-mail is not valid."),
  password: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .min(8, "Password must have at least 8 characters."),
});

const CompanyAdminLoginPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const params = useParams();

  const { companyName } = params;

  const isCharityAuth = companyName === "charity";

  const inProgress = useSelector(authSelectors.selectInProgress);

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${S.MOBILE_BREAKPOINT}px)`,
  });

  const elementSX: React.CSSProperties = {
    width: 280,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const elementSXDesktop: React.CSSProperties = {
    width: 391,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const gapBig = isTablet ? 0 : 30;

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ email, password }) => {
    dispatch(signInCredentials.request({ email, password }));
  };

  return (
    <AuthFormTemplate>
      <>
        <S.Separator height={gapBig} />
        {!isCharityAuth && (
          <S.ClickableWrapper onClick={() => navigate(`/${companyName}${routes.LANDING}`)}>
            <S.RowDisplay gridGap={16}>
              <S.LabelGray>
                <ArrowLeftIcon size={24} />
              </S.LabelGray>
              <S.LabelGray>Admin login</S.LabelGray>
            </S.RowDisplay>
          </S.ClickableWrapper>
        )}

        <S.Separator height={30} />
        <S.ColumnDisplay
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ width: "100%" }}
        >
          <Input
            topLabel="E-mail"
            placeholder="admin@company.com"
            isRequired
            sx={isMobile ? { ...elementSX, minWidth: "100%" } : elementSXDesktop}
            control={control}
            ref={emailRef}
            onEnter={() => passwordRef?.current?.focus()}
            errorMessage={errors?.email?.message}
            name="email"
          />
          <S.Separator height={20} />
          <Input
            topLabel="Password"
            placeholder="Enter password"
            isRequired
            sx={isMobile ? { ...elementSX, minWidth: "100%" } : elementSXDesktop}
            type="password"
            control={control}
            ref={passwordRef}
            errorMessage={errors?.password?.message}
            name="password"
            onEnter={handleSubmit(onSubmit)}
          />
          <S.Separator height={10} />
          <S.Paragraph
            fontSize={12}
            style={
              isMobile
                ? { ...elementSX, minWidth: "100%", textAlign: "start" }
                : { ...elementSXDesktop, textAlign: "start" }
            }
          >
            Forgot password?{" "}
            <span>
              <S.LinkText onClick={() => navigate(routes.COMPANY.RECOVER_PASSWORD)} fontSize={12}>
                Reset it here.
              </S.LinkText>
            </span>
          </S.Paragraph>
          <S.Separator height={30} />
          <PrimaryButton
            label="Login"
            sx={isMobile ? { ...elementSX, minWidth: "100%" } : elementSXDesktop}
            isLoading={inProgress}
            onClick={handleSubmit(onSubmit)}
          />

          <S.Separator height={isTablet ? 42 : 0} />
        </S.ColumnDisplay>
      </>
    </AuthFormTemplate>
  );
};

export default CompanyAdminLoginPage;
