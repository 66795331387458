import styled from "styled-components";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ColumnDisplay,
  FormWrapper,
  GradientHeading,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const IconText = styled.span`
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;

  background: transparent;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-text-fill-color: ${({ theme }) => theme.gunmetal()};
  text-fill-color: ${({ theme }) => theme.gunmetal()};
`;
