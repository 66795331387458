export const GET_PAYMENT_INTENT = "GET_PAYMENT_INTENT";
export const GET_PAYMENT_INTENT_SUCCESS = "GET_PAYMENT_INTENT_SUCCESS";
export const GET_PAYMENT_INTENT_FAILURE = "GET_PAYMENT_INTENT_FAILURE";

export const SETUP_PAYMENT_METHOD = "SETUP_PAYMENT_METHOD";
export const SETUP_PAYMENT_METHOD_SUCCESS = "SETUP_PAYMENT_METHOD_SUCCESS";
export const SETUP_PAYMENT_METHOD_FAILURE = "SETUP_PAYMENT_METHOD_FAILURE";

export const DONATION_SUCCESS = "DONATION_SUCCESS";

export const CLEAR_CLIENT_SECRET = "CLEAR_CLIENT_SECRET";
