import { PlusCircleIcon } from "@primer/octicons-react";
import { WINDOW } from "@sentry/react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PrimaryButton from "components/buttons/primary";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import paymentSelectors from "store/payment/payment.selector";
import { showToast, updateDialogState } from "store/ui-components/ui-components.actions";
import userSelectors from "store/user/user.selector";
import routes from "utils/routes";

import * as S from "./connect-stripe.styled";

interface IAddPaymentMethodForm {
  clientSecret: string;
}

const AddPaymentMethodForm: React.FC<IAddPaymentMethodForm> = ({ clientSecret }): JSX.Element => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const inProgress = useSelector(paymentSelectors.selectInProgress);
  const user = useSelector(userSelectors.selectData);

  const confirmationForm: HTMLFormElement = document.getElementById(
    "confirmation-form",
  ) as HTMLFormElement;

  React.useEffect(() => {
    if (confirmationForm) {
      confirmationForm?.addEventListener("submit", (ev) => {
        ev.preventDefault();
        stripe.confirmUsBankAccountPayment(clientSecret).then(({ paymentIntent, error }) => {
          if (error) {
            dispatch(
              showToast({
                message: error?.message,
                type: "failure",
              }),
            );
          } else if (paymentIntent.status === "requires_payment_method") {
            dispatch(
              showToast({
                message: "Confirmation failed. Attempt again with a different payment method.",
                type: "failure",
              }),
            );
          } else if (paymentIntent.status === "processing") {
            dispatch(
              showToast({
                message: "Confirmation succeeded! The account will be debited.",
                type: "success",
              }),
            );
          } else if (paymentIntent.next_action?.type === "verify_with_microdeposits") {
            dispatch(
              showToast({
                message: `The account needs to be verified via microdeposits. Check mail at: ${user?.email}`,
                type: "success",
              }),
            );
            window.open(paymentIntent.next_action.redirect_to_url?.url, "__blnk");
          }
        });
      });
    }
  }, [confirmationForm]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${WINDOW.__RUNTIME_CONFIG__.REACT_APP_URL}${routes.COMPANY.GIFT_MATCHING}`,
      },
      redirect: "if_required",
    });
    event.preventDefault();
    dispatch(showToast({ message: "Bank account successfully connected.", type: "success" }));
    dispatch(updateDialogState({ key: "SETUP_PAYMENT_METHOD", isOpened: false }));
    if (error) {
      dispatch(showToast({ message: error?.message, type: "failure" }));
    }
  };

  return (
    <>
      <S.Separator height={40} />
      <PaymentElement />
      <S.Separator height={40} />
      <PrimaryButton onClick={handleSubmit} label="Connect account" isLoading={inProgress} />
    </>
  );
};

export default AddPaymentMethodForm;
