import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { resendVerificationEmail } from "store/auth/auth.action";
import authSelectors from "store/auth/auth.selector";
import { checkCompanyExists, registerEmployee } from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import { showFullScreenLoader } from "store/ui-components/ui-components.actions";
import { EMAIL_REG, PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from "utils/constants";
import * as yup from "yup";

import * as S from "./register.styled";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REG, "E-mail is not valid.")
    .required("Work e-mail is required."),
});

const EmployeeRegister: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const params = useParams();

  const [tosAccepted, acceptTos] = React.useState<boolean>(false);

  const { companyName } = params;

  const inProgress = useSelector(companySelectors.selectInProgress);

  const lastCompanyCheck = useSelector(authSelectors.selectLastCompanyCheck);

  React.useEffect(() => {
    if (lastCompanyCheck !== companyName) {
      dispatch(showFullScreenLoader());
      dispatch(checkCompanyExists.request({ name: companyName }));
    }
  }, []);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ email }) => {
    if (tosAccepted) {
      dispatch(registerEmployee.request({ companyName, body: { email } }));
    }
  };

  const onResendVerificationLink = ({ email }) => {
    dispatch(resendVerificationEmail.request({ email }));
  };

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      {!isTablet && <div />}
      <S.DoubleFormWrapper
        justifyContent="space-between"
        alignItems="flex-start"
        gridGap={isTablet ? 16 : 0}
      >
        {isTablet && <LogoAndCompany companyName={companyName} showMargin />}
        {isTablet ? (
          <S.MobileFormContainer alignItems="center" justifyContent="center">
            <S.CoverFrame alignItems="flex-start" justifyContent="center">
              <S.CoverFrameInnerContent alignItems="flex-start" justifyContent="center">
                <S.WelcomeText>Welcome to</S.WelcomeText>
                <S.Separator height={8} />
                <S.HeadingText>Fundwurx</S.HeadingText>
                <S.Separator height={24} />
                <S.LineSeparator />
                <S.Separator height={16} />
                <S.ParagraphWhite style={{ maxWidth: "371px" }}>
                  Please put in your e-mail so we can send you the validation link!
                </S.ParagraphWhite>
              </S.CoverFrameInnerContent>
            </S.CoverFrame>
            <S.Separator height={50} />
            <Input
              control={control}
              isRequired
              sx={{ width: 322, maxWidth: "100%" }}
              name="email"
              topLabel="Work e-mail"
              bottomLabel="Please add your work e-mail."
              errorMessage={errors?.email?.message}
              onEnter={handleSubmit(onSubmit)}
            />
            <S.Separator height={60} />
            <S.RowDisplay
              gridGap={8}
              style={{ width: 322, maxWidth: "100%" }}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Checkbox
                checked={tosAccepted}
                onChange={() => acceptTos(!tosAccepted)}
                style={{ display: "flex" }}
              />
              <S.Paragraph
                fontSize={13.5}
                style={{ width: 391, maxWidth: "calc(100% - 2 * 22px)" }}
                textAlign="start"
              >
                I certify that I have read and agree with the Fundwurx{" "}
                <span>
                  <S.TermsOfServiceLink href={TERMS_OF_SERVICE_LINK} target="_blank">
                    terms of service
                  </S.TermsOfServiceLink>{" "}
                </span>
                and{" "}
                <span>
                  <S.TermsOfServiceLink href={PRIVACY_POLICY_LINK} target="_blank">
                    privacy policy
                  </S.TermsOfServiceLink>{" "}
                </span>
              </S.Paragraph>
            </S.RowDisplay>
            <S.Separator height={60} />
            <S.ColumnDisplay width="100%" gridGap={20}>
              <PrimaryButton
                label="Send"
                isLoading={inProgress}
                onClick={handleSubmit(onSubmit)}
                sx={{ width: 322, maxWidth: "100%" }}
              />
              <S.Paragraph textAlign="start" style={{ width: 322, maxWidth: "100%" }} fontSize={12}>
                Did your verification link expire?{" "}
                <S.ResendVerificationLink onClick={handleSubmit(onResendVerificationLink)}>
                  Resend verification link
                </S.ResendVerificationLink>{" "}
              </S.Paragraph>
            </S.ColumnDisplay>
          </S.MobileFormContainer>
        ) : (
          <>
            <S.FormWrapper
              alignItems="flex-start"
              justifyContent="space-between"
              style={{ height: "100%" }}
            >
              <LogoAndCompany companyName={companyName} showVersion={false} />
              <S.ColumnDisplay alignItems="flex-start" gridGap={50}>
                <Input
                  control={control}
                  isRequired
                  sx={{ width: 391, maxWidth: "calc(100% - 2 * 22px)", boxSizing: "border-box" }}
                  name="email"
                  topLabel="Work e-mail"
                  bottomLabel="Please add your work e-mail."
                  errorMessage={errors?.email?.message}
                  onEnter={handleSubmit(onSubmit)}
                />
                <S.RowDisplay
                  gridGap={8}
                  style={{ width: "100%" }}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Checkbox
                    checked={tosAccepted}
                    onChange={() => acceptTos(!tosAccepted)}
                    style={{ display: "flex" }}
                  />
                  <S.Paragraph
                    fontSize={13.5}
                    style={{ width: 391, maxWidth: "calc(100% - 2 * 22px)" }}
                    textAlign="start"
                  >
                    I certify that I have read and agree with the Fundwurx <br />
                    <span>
                      <S.TermsOfServiceLink href={TERMS_OF_SERVICE_LINK} target="_blank">
                        terms of service
                      </S.TermsOfServiceLink>{" "}
                    </span>
                    and{" "}
                    <span>
                      <S.TermsOfServiceLink href={PRIVACY_POLICY_LINK} target="_blank">
                        privacy policy
                      </S.TermsOfServiceLink>{" "}
                    </span>
                  </S.Paragraph>
                </S.RowDisplay>
                <S.ColumnDisplay width="100%" gridGap={20}>
                  <PrimaryButton
                    label="Send"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={inProgress}
                    isDisabled={!tosAccepted}
                    sx={{ width: 391, maxWidth: "calc(100% - 2 * 22px)" }}
                  />
                  <S.Paragraph
                    style={{ width: 391, maxWidth: "calc(100% - 2 * 22px)" }}
                    textAlign="start"
                    fontSize={12}
                  >
                    Did your verification link expire?{" "}
                    <S.ResendVerificationLink onClick={handleSubmit(onResendVerificationLink)}>
                      Resend verification link
                    </S.ResendVerificationLink>
                  </S.Paragraph>
                </S.ColumnDisplay>
              </S.ColumnDisplay>
              <div />
            </S.FormWrapper>

            <S.CoverFrame alignItems="flex-start" justifyContent="center">
              <S.CoverFrameInnerContent alignItems="flex-start" justifyContent="center">
                <S.WelcomeText>Welcome to</S.WelcomeText>
                <S.Separator height={8} />
                <S.HeadingText>Fundwurx</S.HeadingText>
                <S.Separator height={24} />
                <S.LineSeparator />
                <S.Separator height={16} />
                <S.ParagraphWhite style={{ maxWidth: "361px" }}>
                  Please put in your e-mail so we can send you the validation link!
                </S.ParagraphWhite>
              </S.CoverFrameInnerContent>
            </S.CoverFrame>
          </>
        )}
      </S.DoubleFormWrapper>
      {!isTablet && <LogoAndCompany companyName={companyName} showMargin />}
    </S.BubbleBackground>
  );
};

export default EmployeeRegister;
