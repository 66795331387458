import PrimaryButton from "components/buttons/primary";
import SecondaryButton from "components/buttons/secondary";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { respondToDonation } from "store/donations/donations.action";
import donationsSelectors from "store/donations/donations.selector";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";

import * as S from "../dialogs.styled";

const DIALOG_KEY = "APPROVE_DONATION";

const ApproveDonationDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, DIALOG_KEY),
  );

  const inProgress = useSelector((state: IAppState) => donationsSelectors.selectInProgress(state));

  const properties = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogProperties(state, DIALOG_KEY),
  );

  const closeDialog = (): void => {
    if (!inProgress) {
      dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
    }
  };

  const onApprove = (): void => {
    dispatch(respondToDonation.request({ id: properties?.id, approved: true }));
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper>
          <S.GradientHeading fontSize={28}>
            Are you sure you want to approve this match?
          </S.GradientHeading>
          <S.Separator height={58} />
          <S.RowDisplay gridGap={20}>
            <SecondaryButton
              label="Cancel"
              sx={{ width: "100%", minWidth: "100%" }}
              onClick={closeDialog}
              isLoading={inProgress}
            />
            <PrimaryButton
              label="Approve"
              sx={{ width: "100%", minWidth: "100%" }}
              onClick={onApprove}
              isLoading={inProgress}
            />
          </S.RowDisplay>
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default ApproveDonationDialog;
