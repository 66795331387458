import { IEveryOrgSelectors } from "./every-org.type";

const everyOrgSelectors: IEveryOrgSelectors = {
  selectData: (state) => state.everyOrg.data,
  selectSubCategory: (state) => state.everyOrg.subCategory,
  selectCharityBySlug: (state, slug) => state.everyOrg.data?.find((item) => item.slug === slug),
  selectCharityDetails: (state) => state.everyOrg.details,
  selectPage: (state) => state.everyOrg.page,
  selectIsFetched: (state) => state.everyOrg.isFetched,
  selectInProgress: (state) => state.everyOrg.inProgress,
  selectIsEndReached: (state) => state.everyOrg.isEndReached,
  selectError: (state) => state.everyOrg.error,
};

export default everyOrgSelectors;
