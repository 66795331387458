import { cacheSlice } from "store/store.cache";

import { IAuthState } from "./auth.type";

export const cacheAuth = (initialState: IAuthState, state: IAuthState): IAuthState => {
  const cachedState: IAuthState = {
    ...initialState,
    isCached: true,
    token: state.token,
    role: state.role,
  };

  cacheSlice("auth", cachedState);

  return {
    ...state,
    isCached: true,
  };
};

export const clearAuthCache = (initialState: IAuthState): IAuthState => {
  cacheSlice("auth", initialState);
  return initialState;
};
