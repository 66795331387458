import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { checkCompanyInfo } from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import { DOMAIN_REG, NAME_REG, SPECIAL_CHARACTERS_REGEX } from "utils/constants";
import * as yup from "yup";

import * as S from "./registration.styled";

const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .matches(NAME_REG, "Name must be valid.")
    .min(3, "Name must contain at least 3 characters."),
  domain: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .matches(DOMAIN_REG, "Domain is not valid.")
    .min(3, "Domain must contain at least 3 characters."),
});

const CompanyRegistration: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const [companyDomain, setDomain] = React.useState<string>("com");
  const [companyDomainEdited, editDomain] = React.useState<boolean>(false);
  const [companyName, setName] = React.useState<string>("");

  const nameRef = React.useRef(null);
  const domainRef = React.useRef(null);

  const inProgress = useSelector(companySelectors.selectInProgress);

  const {
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    control,
  } = useForm({
    defaultValues: { name: "", domain: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onNameChange = (val: string): void => {
    if (val?.trim()?.length) {
      if (!companyDomainEdited) {
        setValue(
          "domain",
          `@${val
            .toLowerCase()
            .replaceAll(" ", "")
            .replace(SPECIAL_CHARACTERS_REGEX, "")}.${companyDomain}`,
        );
      }
      setName(`@${val.toLowerCase().replaceAll(" ", "")}`);
    } else if (!companyDomainEdited) {
      setValue("domain", "");
    }
  };

  const onDomainFocus = (): void => {
    if (!getValues("domain")?.length && getValues("name")?.length) {
      const domain = `${companyName}.com`;
      setDomain(domain);
      setValue("domain", domain);
    }
  };

  const onSubmit = ({ name, domain }) => {
    dispatch(checkCompanyInfo.request({ name, domain }));
  };

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <div />
      <S.FormWrapper>
        <S.GradientHeading>Create company account</S.GradientHeading>
        <S.Separator height={54} />

        <Input
          ref={nameRef}
          sx={{ width: "100%", minWidth: "100%" }}
          topLabel="What is the name of your company"
          isRequired
          control={control}
          onChange={onNameChange}
          name="name"
          onEnter={() => domainRef?.current?.focus()}
          errorMessage={errors?.name?.message}
          bottomLabel="This name will appear on your account and what employees will see."
        />

        <S.Separator height={40} />
        <Input
          ref={domainRef}
          sx={{ width: "100%", minWidth: "100%" }}
          topLabel="Company e-mail domain"
          isRequired
          errorMessage={errors?.domain?.message}
          placeholder="@company.com"
          bottomLabel="The company domain will be used when employees register."
          control={control}
          name="domain"
          onChange={() => editDomain(true)}
          onFocus={onDomainFocus}
          onEnter={handleSubmit(onSubmit)}
        />

        <S.Separator height={70} />
        <PrimaryButton
          label="Next"
          onClick={handleSubmit(onSubmit)}
          isLoading={inProgress}
          sx={{ width: "100%", minWidth: "100%" }}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default CompanyRegistration;
