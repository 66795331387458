import SecondaryButton from "components/buttons/secondary";
import { XIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";

import * as S from "../dialogs.styled";

const PostEventThanks: React.FC = () => {
  const dispatch = useDispatch();

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, "POST_EVENT_THANKS"),
  );

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: "POST_EVENT_THANKS", isOpened: false }));
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20}>
          <S.CloseIconContainer>
            <S.CloseIconButton type="button" onClick={closeDialog}>
              <XIcon />
            </S.CloseIconButton>
          </S.CloseIconContainer>
          <S.GradientHeading fontSize={28}>
            Thank you for
            <br />
            posting an event!
          </S.GradientHeading>
          <S.DialogSubheading marginTop={30} marginBottom={10} textOpacity={0.5}>
            Our team will review your post and
            <br />
            approve it shortly
          </S.DialogSubheading>
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default PostEventThanks;
