import ConfirmCharityAdminPage from "pages/admin/auth/confirm-charity-admin";
import CreateCompanyAccount from "pages/admin/auth/create-account";
import EmailSent from "pages/admin/auth/e-mail-sent";
import EmailValidated from "pages/admin/auth/e-mail-validated";
import EmailValidatedSuperAdmin from "pages/admin/auth/email-validated-super-admin";
import RecoverPasswordPage from "pages/admin/auth/recover-password";
import RecoverPasswordValidationPage from "pages/admin/auth/recover-password-validation";
import CompanyRegistration from "pages/admin/auth/registration";
import SetNewPasswordPage from "pages/admin/auth/set-new-password";
import CharityLandingPage from "pages/auth/charity-landing";
import CompanyAdminLoginPage from "pages/auth/company-admin-login";
import EmployeeVerificationPage from "pages/auth/employee-code-verification";
import EmployeeLoginPage from "pages/auth/employee-login";
import LandingPage from "pages/auth/landing";
import RedirectRoute from "pages/auth/redirect-route";
import CharityVerificationFailedPage from "pages/charity/auth/charity-verification-failed";
import CharityVerifiedPage from "pages/charity/auth/charity-verified";
import RegisterCharityPage from "pages/charity/auth/register-charity";
import VerifyIdentityPage from "pages/charity/auth/verify-identity";
import WelcomeFinchPage from "pages/charity/auth/welcome-finch";
import CharityLoginPage from "pages/charity/login";
import CheckInPage from "pages/check-in";
import CheckInSuccessPage from "pages/check-in-success";
import CompanyNotFoundPage from "pages/company-not-found";
import CreateEmployeeAccount from "pages/employee/auth/create-account";
import OnboardingInterests from "pages/employee/auth/onboarding-interests";
import EmployeeRegister from "pages/employee/auth/register";
import NotFoundPage from "pages/not-found";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import companySelectors from "store/company/company.selector";
import { uriEncode } from "utils/helper";
import routes from "utils/routes";

const AuthNavigator: React.FC<{}> = (): JSX.Element => {
  const companyName = useSelector(companySelectors.selectName);

  return (
    <Routes>
      {/* company-auth */}
      <Route path={`/:companyName${routes.LANDING}`} element={<LandingPage />} />
      <Route path={`/:companyName${routes.COMPANY.LOGIN}`} element={<CompanyAdminLoginPage />} />
      <Route path={routes.COMPANY.REGISTER} element={<CompanyRegistration />} />
      <Route path={routes.COMPANY.CREATE_ACCOUNT} element={<CreateCompanyAccount />} />
      <Route path={routes.EMAIL_SENT} element={<EmailSent />} />
      <Route path={routes.COMPANY.EMAIL_VALIDATED} element={<EmailValidated />} />
      <Route
        path={routes.COMPANY.EMAIL_VALIDATED_SUPER_ADMIN}
        element={<EmailValidatedSuperAdmin />}
      />
      <Route path={routes.COMPANY.RECOVER_PASSWORD} element={<RecoverPasswordPage />} />
      <Route
        path={routes.COMPANY.SET_NEW_PASSWORD}
        element={<SetNewPasswordPage flow="company" />}
      />
      <Route
        path={routes.CHARITY.SET_NEW_PASSWORD}
        element={<SetNewPasswordPage flow="charity" />}
      />
      <Route
        path={routes.COMPANY.RECOVER_PASSWORD_VALIDATION}
        element={<RecoverPasswordValidationPage />}
      />
      {/* employee-auth */}
      <Route path={`/:companyName${routes.EMPLOYEE.LOGIN}`} element={<EmployeeLoginPage />} />
      <Route path={`/:companyName${routes.EMPLOYEE.REGISTER}`} element={<EmployeeRegister />} />
      <Route path={`${routes.EMPLOYEE.CREATE_ACCOUNT}`} element={<CreateEmployeeAccount />} />
      <Route path={`${routes.EMPLOYEE.ONBOARDING_INTERESTS}`} element={<OnboardingInterests />} />
      <Route
        path={`/:companyName${routes.EMPLOYEE.EMAIL_VERIFICATION}`}
        element={<EmployeeVerificationPage />}
      />
      <Route path={routes.EMPLOYEE.WELCOME_FINCH} element={<WelcomeFinchPage />} />
      {/* charity-auth */}
      <Route path={routes.CHARITY.LANDING} element={<CharityLandingPage />} />
      <Route path={routes.CHARITY.LOGIN} element={<CharityLoginPage />} />
      <Route path={routes.CHARITY.REGISTER} element={<RegisterCharityPage />} />
      <Route
        path={routes.CHARITY.VERIFICATION_FAILED}
        element={<CharityVerificationFailedPage />}
      />
      <Route path={routes.CHARITY.VERIFICATION_SUCESS} element={<CharityVerifiedPage />} />
      <Route path={routes.CHARITY.VERIFY_IDENTITY} element={<VerifyIdentityPage />} />
      <Route
        path={routes.CHARITY.CONFIRM_ADMIN_REGISTRATION}
        element={<ConfirmCharityAdminPage />}
      />

      <Route
        path={routes.OLD.EMPLOYEE_LOGIN}
        element={<RedirectRoute redirectTo={routes.EMPLOYEE.LOGIN} />}
      />
      <Route
        path={routes.OLD.EMPLOYEE_REGISTER}
        element={<RedirectRoute redirectTo={routes.EMPLOYEE.REGISTER} />}
      />
      <Route path={routes.COMPANY_NOT_FOUND} element={<CompanyNotFoundPage />} />
      <Route path={routes.NOT_FOUND} element={<NotFoundPage />} />

      <Route path={routes.OLD.ADMIN_LOGIN} element={<Navigate to={routes.COMPANY_NOT_FOUND} />} />
      <Route
        path={routes.OLD.ADMIN_REGISTER}
        element={<Navigate to={routes.COMPANY_NOT_FOUND} />}
      />

      <Route
        path={routes.CHARITY.VERIFICATION_FAILED}
        element={<CharityVerificationFailedPage />}
      />
      <Route path={routes.CHECK_IN} element={<CheckInPage />} />
      <Route path={routes.CHECK_IN_SUCCESS} element={<CheckInSuccessPage />} />
      <Route
        path={routes.OTHER}
        element={
          <Navigate
            to={companyName ? `/${uriEncode(companyName)}${routes.LANDING}` : routes.NOT_FOUND}
          />
        }
      />
    </Routes>
  );
};

export default AuthNavigator;
