import styled from "styled-components";

export const LegendContainer = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "column"};
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  flex: 1;

  flex-wrap: wrap;
  padding-left: 25px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 16px;
`;

export const LegendColor = styled.div<{ size; color: string }>`
  width: ${(props) => props.size || "21px"};
  height: ${(props) => props.size || "21px"};
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 16px;
`;

export const LegendLabel = styled.span<{ fontSize?: string | number; color?: string }>`
  font-size: ${({ fontSize }) => fontSize || "18px"};
  font-family: "Inter", sans-serif;
  color: ${({ color, theme }) => color || theme.gunmetal()};
  white-space: pre-line;
  flex-shrink: unset !important;
`;
