import { yupResolver } from "@hookform/resolvers/yup";
import { CheckIcon, PencilIcon } from "@primer/octicons-react";
import { ToggleSwitch } from "@primer/react";
import SecondaryButton from "components/buttons/secondary";
import SmallButton from "components/buttons/small";
import ControlledSelect from "components/controlled-select";
import FixedHeader from "components/fixed-header";
import Input from "components/input";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import companySelectors from "store/company/company.selector";
import { getDonations } from "store/donations/donations.action";
import donationsSelectors from "store/donations/donations.selector";
import { DonationsTable } from "store/donations/donations.type";
import {
  getCompanyGiftMatch,
  setCompanyGiftMatch,
  toggleGiftMatch,
} from "store/gift-matching/gift-matching.action";
import giftMatchSelectors from "store/gift-matching/gift-matching.selector";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import { toggleGiftMatchNotification } from "store/user/user.action";
import userSelectors from "store/user/user.selector";
import colors from "theme/colors";
import { MAX_DONATION, MAX_TABLE_COLUMNS, MIN_DONATION, TWO_DECIMALS_REG } from "utils/constants";
import { genRandomString } from "utils/helper";
import * as yup from "yup";

import GiftMatchingTable from "./components/gift-matching-table";
import * as S from "./gift-matching.styled";

const TABLES_LIST: { text: string; table: DonationsTable }[] = [
  { text: "Requested matches", table: "pending" },
  { text: "Off platform requests", table: "pendingOffPlatform" },
  { text: "Fulfilled", table: "fulfilled" },
];

const schema = yup.object().shape({
  min: yup
    .number()
    .required("Please enter desired minimum amount.")
    .typeError("Please enter valid number.")
    .min(MIN_DONATION, "Minimum amount is 1$.")
    .max(MAX_DONATION, "Maximum amount is 10,000$.")
    .test(
      "is-decimal",
      "Please round donation to 2 decimals.",
      (value) => !!`${value}`.match(TWO_DECIMALS_REG),
    ),
  max: yup
    .number()
    .required("Please enter desired minimum amount.")
    .typeError("Please enter valid number.")
    .min(MIN_DONATION, "Minimum amount is 1$.")
    .max(MAX_DONATION, "Maximum amount is 10,000$.")
    .test(
      "is-decimal",
      "Please round donation to 2 decimals.",
      (value) => !!`${value}`.match(TWO_DECIMALS_REG),
    ),
});

const GiftMatchingPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const [isEditMode, setEditMode] = React.useState<boolean>(false);
  const [selectedTable, selectTable] = React.useState<DonationsTable>("pending");

  const selectItems = TABLES_LIST.map((item) => ({
    id: genRandomString(),
    text: item.text,
    value: item.table,
  }));

  const companyName = useSelector(companySelectors.selectName);
  const bankAccountVerified = useSelector(companySelectors.selectBankAccountVerified);

  const toggleState = useSelector(giftMatchSelectors.selectToggleState);
  const isFetched = useSelector(giftMatchSelectors.selectIsFetched);
  const inProgress = useSelector(giftMatchSelectors.selectInProgress);
  const minSum = useSelector(giftMatchSelectors.selectMinSum);
  const maxSum = useSelector(giftMatchSelectors.selectMaxSum);

  const notificationEnabled = useSelector(userSelectors.selectNotificationEnabled);

  const pendingOffPlatformDonations = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsList(state, "pendingOffPlatform"),
  );
  const pendingOffPlatformDonationsTotalNum = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsResults(state, "pendingOffPlatform"),
  );
  const pendingOffPlatformDonationsPage = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsPage(state, "pendingOffPlatform"),
  );
  const pendingOffPlatformDonationsCriteria = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsCriteria(state, "pendingOffPlatform"),
  );

  const pendingDonations = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsList(state, "pending"),
  );
  const pendingDonationsTotalNum = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsResults(state, "pending"),
  );
  const pendingDonationsPage = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsPage(state, "pending"),
  );
  const pendingDonationsCriteria = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsCriteria(state, "pending"),
  );

  const fulfilledDonations = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsList(state, "fulfilled"),
  );
  const fulfilledDonationsTotalNum = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsResults(state, "fulfilled"),
  );
  const fulfilledDonationsPage = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsPage(state, "fulfilled"),
  );
  const fulfilledDonationsCriteria = useSelector((state: IAppState) =>
    donationsSelectors.selectDonationsCriteria(state, "fulfilled"),
  );

  const minRef = React.useRef<HTMLInputElement>(null);
  const maxRef = React.useRef<HTMLInputElement>(null);

  const defaultValues = {
    min: minSum ?? 0,
    max: maxSum ?? 0,
  };

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ min, max }): void => {
    setEditMode(false);
    if (min !== minSum || max !== maxSum) {
      dispatch(setCompanyGiftMatch.request({ minSum: min, maxSum: max }));
    }
  };

  React.useEffect(() => {
    dispatch(getCompanyGiftMatch.request());
    dispatch(getDonations.request({ page: 1, table: "fulfilled", ...fulfilledDonationsCriteria }));
    dispatch(getDonations.request({ page: 1, table: "pending", ...pendingDonationsCriteria }));
    dispatch(
      getDonations.request({
        page: 1,
        table: "pendingOffPlatform",
        ...pendingOffPlatformDonationsCriteria,
      }),
    );
  }, []);

  React.useEffect(() => {
    if (isFetched) {
      setValue("min", minSum);
      setValue("max", maxSum);
    }
  }, [isFetched]);

  return (
    <S.GiftMatchingPageWrapper justifyContent="flex-start">
      <FixedHeader name={companyName} />
      <S.GiftMatchingPageInnerWrapper>
        <S.Separator height={53} />
        <S.MatchingProgramInnerWrapper alignItems="flex-start">
          <S.DonationsMatchingForm alignItems="flex-start" justifyContent="flex-start" gridGap={22}>
            <S.RowDisplay width="100%" justifyContent="space-between" gridGap={22} flexWrap>
              <S.RowDisplay
                gridGap={22}
                flexWrap
                style={{ maxWidth: "100%" }}
                justifyContent="flex-start"
              >
                <S.GradientHeadingExtended>Donation Matching</S.GradientHeadingExtended>
                {isFetched && (
                  <ToggleSwitch
                    aria-labelledby={genRandomString()}
                    defaultChecked={toggleState}
                    disabled={!bankAccountVerified}
                    onChange={() => dispatch(toggleGiftMatch.request())}
                    statusLabelPosition="end"
                  />
                )}
              </S.RowDisplay>
              <SecondaryButton
                label={!bankAccountVerified ? "Connect bank account" : "Bank account connected"}
                isDisabled={bankAccountVerified}
                onClick={() =>
                  dispatch(updateDialogState({ key: "SETUP_PAYMENT_METHOD", isOpened: true }))
                }
                sx={{ width: "219px", height: "44px" }}
              />
            </S.RowDisplay>

            <S.HorizontalSeparator />
            <S.DonationsMatchingHeading style={{ opacity: toggleState ? 1 : 0.5 }}>
              Company Gift Matching Criteria
            </S.DonationsMatchingHeading>

            <S.DonationsMatchingInnerForm
              alignItems="center"
              justifyContent="flex-start"
              gridGap={32}
              flexWrap
              isDisabled={!toggleState}
            >
              <S.InputWrapper>
                <Input
                  topLabel="Donation minimum"
                  isRequired
                  control={control}
                  errorMessage={errors?.min?.message}
                  // onChange={(val) => setValue("min", `${val}$`)}
                  name="min"
                  bottomLabel="The minimum amount required to honor a single donation request"
                  sx={{ maxWidth: "428px", width: "100%", minWidth: "200px" }}
                  isReadOnly={!isEditMode}
                  ref={minRef}
                  onEnter={() => maxRef?.current?.focus()}
                />
              </S.InputWrapper>

              <S.InputWrapper>
                <Input
                  topLabel="Yearly maximum"
                  control={control}
                  name="max"
                  errorMessage={errors?.max?.message}
                  isReadOnly={!isEditMode}
                  isRequired
                  bottomLabel="The maximum amount allocated per employee per year"
                  sx={{ maxWidth: "428px", width: "100%", minWidth: "200px" }}
                  ref={maxRef}
                  onEnter={handleSubmit(onSubmit)}
                />
              </S.InputWrapper>
              {isEditMode ? (
                <SmallButton
                  label="Save"
                  leadingIcon={CheckIcon}
                  onClick={handleSubmit(onSubmit)}
                  sx={{ height: "32px", background: colors.trueBlue(0.9), color: colors.white() }}
                />
              ) : (
                <SmallButton
                  label="Edit"
                  leadingIcon={PencilIcon}
                  onClick={() => setEditMode(true)}
                  sx={{ height: "32px" }}
                />
              )}
            </S.DonationsMatchingInnerForm>

            <S.HorizontalSeparator />
            <S.RowDisplay
              gridGap={22}
              flexWrap
              style={{ maxWidth: "100%" }}
              justifyContent="flex-start"
            >
              <S.DonationsMatchingHeading style={{ opacity: toggleState ? 1 : 0.5 }}>
                Notify me of gift match requests
              </S.DonationsMatchingHeading>
              {isFetched && (
                <ToggleSwitch
                  aria-labelledby={genRandomString()}
                  defaultChecked={notificationEnabled}
                  disabled={!bankAccountVerified}
                  onChange={() =>
                    dispatch(toggleGiftMatchNotification.request(!notificationEnabled))
                  }
                  statusLabelPosition="end"
                />
              )}
            </S.RowDisplay>
          </S.DonationsMatchingForm>
        </S.MatchingProgramInnerWrapper>

        <S.Separator height={44} />
        {isTablet ? (
          <>
            <ControlledSelect
              items={selectItems}
              name="table"
              sx={{ width: "100%", maxWidth: "620px", height: "20px", alignSelf: "start" }}
              onSelect={(val) => selectTable(val as DonationsTable)}
            />
            <S.Separator height={30} />
          </>
        ) : (
          <S.RowDisplay width="100%" justifyContent="flex-start" style={{ overflow: "auto" }}>
            {TABLES_LIST.map((item, i) => (
              <S.TabSwitch
                key={genRandomString()}
                isSelected={item.table === selectedTable}
                isFirst={i === 0}
                isLast={i === TABLES_LIST.length - 1}
                onClick={() => selectTable(item.table)}
              >
                <S.TabSwitchText isSelected={item.table === selectedTable}>
                  {item.text}
                </S.TabSwitchText>
              </S.TabSwitch>
            ))}
          </S.RowDisplay>
        )}

        <S.Separator height={44} />

        {selectedTable === "pending" && (
          <GiftMatchingTable
            title="requested matches"
            inProgress={inProgress && !isFetched}
            items={pendingDonations}
            totalItemsNum={pendingDonationsTotalNum}
            currentPage={pendingDonationsPage}
            displayPagination={
              pendingDonationsTotalNum > MAX_TABLE_COLUMNS ||
              (pendingDonationsTotalNum > MAX_TABLE_COLUMNS &&
                pendingDonations.length < MAX_TABLE_COLUMNS)
            }
            tableType="pending"
          />
        )}
        {selectedTable === "pendingOffPlatform" && (
          <GiftMatchingTable
            title="off-platform matches"
            inProgress={inProgress && !isFetched}
            isOffPlatform
            items={pendingOffPlatformDonations}
            totalItemsNum={pendingOffPlatformDonationsTotalNum}
            currentPage={pendingOffPlatformDonationsPage}
            displayPagination={
              pendingOffPlatformDonationsTotalNum > MAX_TABLE_COLUMNS ||
              (pendingOffPlatformDonationsTotalNum > MAX_TABLE_COLUMNS &&
                pendingOffPlatformDonations.length < MAX_TABLE_COLUMNS)
            }
            tableType="pendingOffPlatform"
          />
        )}
        {selectedTable === "fulfilled" && (
          <GiftMatchingTable
            title="fulfilled"
            inProgress={inProgress && !isFetched}
            items={fulfilledDonations}
            totalItemsNum={fulfilledDonationsTotalNum}
            currentPage={fulfilledDonationsPage}
            displayPagination={
              fulfilledDonationsTotalNum > MAX_TABLE_COLUMNS ||
              (fulfilledDonationsTotalNum > MAX_TABLE_COLUMNS &&
                fulfilledDonations.length < MAX_TABLE_COLUMNS)
            }
            tableType="fulfilled"
          >
            <S.RowDisplay alignItems="center" justifyContent="flex-start" gridGap={40} />
          </GiftMatchingTable>
        )}
      </S.GiftMatchingPageInnerWrapper>
    </S.GiftMatchingPageWrapper>
  );
};

export default GiftMatchingPage;
