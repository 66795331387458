import moment from "moment";
import { GRANT_OPTIONS, GRANT_STATUS_OPTION } from "types/volunteer";
import * as yup from "yup";

import { EIN_NUMBER_REG, EMAIL_REG } from "./constants";

const MAX_TITLE_CHARS = 256;
const MAX_DESC_CHARS = 500;
const MAX_NOTE_CHARS = 500;
const MAX_IMPACT_CHARS = 200;

export const genVolunteerEventSchema = (selectedGrantOption: GRANT_OPTIONS) =>
  yup.object().shape(
    {
      title: yup
        .string()
        .required("Please enter a title for the volunteer event.")
        .min(3, "Please enter at least 3 characters.")
        .max(MAX_TITLE_CHARS, `Please enter ${MAX_TITLE_CHARS} or less characters.`),
      description: yup
        .string()
        .required("Please enter a description for the volunteer event.")
        .min(3, "Please enter at least 3 characters.")
        .max(MAX_DESC_CHARS, `Please enter ${MAX_DESC_CHARS} or less characters.`),
      location: yup.string().required("Please select an option."),
      address: yup.string().when("location", (location, _schema) => {
        const isVirtualOrCompanyOffice =
          (location as string[])?.includes("Virtual") ||
          (location as string[])?.includes("Company Office") ||
          (location as string[])?.includes("Virtual or Company Office");
        if (isVirtualOrCompanyOffice) {
          return _schema.nullable().notRequired().optional();
        }
        return _schema
          .required("Please enter a valid address.")
          .min(3, "Please enter a valid address.")
          .max(200, "Please enter a valid address.");
      }),
      grantAmount:
        selectedGrantOption === GRANT_STATUS_OPTION.NO
          ? yup.string().optional().notRequired()
          : null,
      note: yup
        .string()
        .trim()
        .notRequired()
        .test("note", `Please enter between 3 and ${MAX_NOTE_CHARS} characters.`, (value) =>
          value
            ? yup
                .string()
                .min(3, "Please enter at least 3 characters.")
                .max(MAX_NOTE_CHARS, `Please enter ${MAX_NOTE_CHARS} or less characters.`)
                .isValidSync(value)
            : true,
        ),
    },
    [["address", "location"]],
  );

export const POST_CHARITY_SCHEMA_STEP2 = yup.object().shape({
  volunteersNeeded: yup.string().required("Please select an option."),
  eventTime: yup.string().required("Please select an option."),
  impact: yup
    .string()
    .max(MAX_IMPACT_CHARS, `Please enter ${MAX_IMPACT_CHARS} or less characters.`),
  contactEmail: yup
    .string()
    .trim()
    .nullable()
    .notRequired()
    .optional()
    .test("contactEmail", "E-mail is not valid.", (value) =>
      value ? yup.string().matches(EMAIL_REG, "E-mail is not valid.").isValidSync(value) : true,
    ),
});

const validFormatList = ["h:mma", "hh:mma", "h:mmA", "hh:mmA", "h:mm A", "hh:mm A"];

const timeFormatValidator = yup
  .string()
  .test(
    "valid-time-format",
    "Invalid time format. Please use hh:mm AM or hh:mm PM format.",
    (value) => moment(value, validFormatList, true).isValid(),
  );

export const ACTIVE_EVENT_SCHEMA = yup.object().shape({
  date: yup.string().required("Please add a date."),
  startTime: timeFormatValidator.required("Please select a start time."),
  endTime: timeFormatValidator.test(
    "is-after-start",
    "End time must be after start time",
    (endTime, { parent }) => {
      const { startTime } = parent;
      if (!startTime || !endTime) return false;

      const start = moment(startTime, validFormatList, true);
      const end = moment(endTime, validFormatList, true);

      return end.isAfter(start);
    },
  ),
  timezone: yup.string().required("Please select a time zone."),
  availableSpots: yup
    .mixed()
    .test("is-valid-number", "Please enter a valid number of volunteers.", (value) => {
      if (!value) return false;
      return !Number.isNaN(Number(value));
    })
    .test("is-integer", "The number of volunteers must be an integer.", (value) => {
      if (!value) return false;
      return Number.isInteger(Number(value));
    })
    .test("is-positive", "The number of volunteers must be a positive number.", (value) => {
      if (!value) return false;
      return value > 0;
    })
    .required("Please enter the maximum number of volunteers."),
});

export const CUSTOM_VOLUNTEER_EVENT_SCHEMA_1 = yup.object().shape({
  charityName: yup
    .string()
    .required("Please enter a charity name.")
    .min(3, "Please enter at least 3 characters.")
    .max(MAX_TITLE_CHARS, `Please enter ${MAX_TITLE_CHARS} or less characters.`),
  ein: yup
    .string()
    .trim()
    .matches(EIN_NUMBER_REG, "Please enter a valid EIN number.")
    .required("Please fill in all data."),
  eventName: yup
    .string()
    .required("Please enter a title for the volunteer event.")
    .min(3, "Please enter at least 3 characters."),
  description: yup
    .string()
    .required("Please enter a description for the volunteer event.")
    .min(3, "Please enter at least 3 characters.")
    .max(MAX_DESC_CHARS, `Please enter ${MAX_DESC_CHARS} or less characters.`),
  note: yup
    .string()
    .trim()
    .notRequired()
    .test("note", `Please enter between 3 and ${MAX_NOTE_CHARS} characters.`, (value) =>
      value
        ? yup
            .string()
            .min(3, "Please enter at least 3 characters.")
            .max(MAX_NOTE_CHARS, `Please enter ${MAX_NOTE_CHARS} or less characters.`)
            .isValidSync(value)
        : true,
    ),
});

export const CUSTOM_VOLUNTEER_EVENT_SCHEMA_2 = yup.object().shape({
  address: yup.string().notRequired(),
  date: yup.string().required("Please add a date."),
  startTime: timeFormatValidator.required("Please select a start time."),
  endTime: timeFormatValidator.test(
    "is-after-start",
    "End time must be after start time",
    (endTime, { parent }) => {
      const { startTime } = parent;
      if (!startTime || !endTime) return false;

      const start = moment(startTime, validFormatList, true);
      const end = moment(endTime, validFormatList, true);

      return end.isAfter(start);
    },
  ),
  timezone: yup.string().required("Please select a time zone."),
  availableSpots: yup
    .mixed()
    .test("is-valid-number", "Please enter a valid number of volunteers.", (value) => {
      if (!value) return false;
      return !Number.isNaN(Number(value));
    })
    .test("is-integer", "The number of volunteers must be an integer.", (value) => {
      if (!value) return false;
      return Number.isInteger(Number(value));
    })
    .test("is-positive", "The number of volunteers must be a positive number.", (value) => {
      if (!value) return false;
      return value > 0;
    })
    .required("Please enter the maximum number of volunteers."),
});
