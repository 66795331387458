import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { registerCompany } from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import registrationSelectors from "store/registration/registration.selector";
import colors from "theme/colors";
import { EMAIL_REG } from "utils/constants";
import * as yup from "yup";

import * as S from "./create-account.styled";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REG, "E-mail is not valid.")
    .required("Please fill in all data."),
  password: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .min(8, "Password must have at least 8 characters."),
  repeatedPassword: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

const CreateCompanyAccount: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const repeatedPasswordRef = React.useRef(null);

  const companyData = useSelector(registrationSelectors.selectCompanyDTO);
  const domain = companyData?.domain;

  const inProgress = useSelector(companySelectors.selectInProgress);

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({
    defaultValues: { email: domain ?? "", password: "", repeatedPassword: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ email, password }) => {
    dispatch(registerCompany.request({ superAdminEmail: email, superAdminPassword: password }));
  };

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <div />
      <S.FormWrapper>
        <S.GradientHeading>Create company account</S.GradientHeading>
        <S.Separator height={48} />

        <Text sx={{ textAlign: "center", fontSize: 16, color: colors.gunmetal() }}>
          Please enter your work email address to be able to create a company account!
        </Text>

        <S.Separator height={70} />

        <Input
          topLabel="E-mail"
          bottomLabel="Please enter your work email address."
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          ref={emailRef}
          onFocus={() => {
            emailRef?.current?.setSelectionRange(0, 1);
          }}
          onChange={(val) => {
            const email = val.split("@")[0];
            if (email) {
              setValue("email", `${email}${domain}`);
            } else {
              setValue("email", domain);
            }
          }}
          onEnter={() => passwordRef?.current?.focus()}
          errorMessage={errors?.email?.message}
          name="email"
        />
        <S.Separator height={40} />
        <Input
          topLabel="Password"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          type="password"
          control={control}
          ref={passwordRef}
          errorMessage={errors?.password?.message}
          name="password"
          onEnter={() => repeatedPasswordRef?.current?.focus()}
        />
        <S.Separator height={40} />
        <Input
          topLabel="Repeat password"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          type="password"
          ref={repeatedPasswordRef}
          errorMessage={errors?.repeatedPassword?.message}
          name="repeatedPassword"
          onEnter={handleSubmit(onSubmit)}
        />
        <S.Separator height={40} />
        <PrimaryButton
          label="Register"
          onClick={handleSubmit(onSubmit)}
          sx={{ width: "100%", minWidth: "100%" }}
          isLoading={inProgress}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default CreateCompanyAccount;
