import styled from "styled-components";

export { ColumnDisplay, Paragraph } from "theme/global-style";

export const ProgressBarWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  border-radius: 6px;
  background: ${({ theme }) => theme.unitedNationsBlue(0.2)};
  height: 10px;
  max-width: 100%;
  overflow: hidden;
`;

export const ProgressBarIndicator = styled.div<{ width: number; duration: number }>`
  width: ${({ width }) => width}px;
  border-radius: 6px;
  background: ${({ theme }) => theme.unitedNationsBlue()};
  height: 10px;
  max-width: 100%;
  animation-name: progress-animation;
  animation-duration: ${({ duration }) => duration}ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes progress-animation {
    from {
      transform: translateX(${({ width }) => -width}px);
    }
    to {
      transform: translateX(${({ width }) => 2 * width}px);
    }
  }
`;
