import { ArrowLeftIcon } from "@primer/octicons-react";
import { Spinner } from "@primer/react";
import FixedHeader from "components/fixed-header";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import companySelectors from "store/company/company.selector";
import {
  fetchVolunteerEventAttendance,
  fetchVolunteerEventAttendanceInfo,
} from "store/volunteer-attendees/volunteer-attendees.action";
import volunteerAttendeesSelectors from "store/volunteer-attendees/volunteer-attendees.selector";
import colors from "theme/colors";
import { MAX_TABLE_COLUMNS } from "utils/constants";

import VolunteerEventAttendeesTable from "./components/table-display";
import * as S from "./volunteer-event-attendees.styled";

const VolunteerEventAttendeesPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, page } = useParams();

  const companyName = useSelector(companySelectors.selectName);
  const event = useSelector(volunteerAttendeesSelectors.selectEvent);
  const attendees = useSelector(volunteerAttendeesSelectors.selectData);
  const results = useSelector(volunteerAttendeesSelectors.selectResults);
  const inProgress = useSelector(volunteerAttendeesSelectors.selectInProgress);

  React.useEffect(() => {
    dispatch(fetchVolunteerEventAttendanceInfo.request({ id }));
    dispatch(fetchVolunteerEventAttendance.request({ id, page: page ? Number(page) : 1 }));
  }, [id, page]);

  return (
    <S.VolunteerEventAttendeesPageWrapper alignItems="center" justifyContent="flex-start">
      <FixedHeader name={companyName} />
      <S.ColumnDisplay>
        <S.Separator height={80} />
        {inProgress ? (
          <S.ColumnDisplay style={{ width: "100%", height: "60%" }}>
            <Spinner />
          </S.ColumnDisplay>
        ) : (
          <>
            <S.BackIndicator color={colors.gunmetal()} onClick={() => navigate(-1)}>
              <ArrowLeftIcon size={25} />
              <div>Back</div>
            </S.BackIndicator>
            <VolunteerEventAttendeesTable
              eventId={id}
              eventStatus={moment(event?.endDateTime).isBefore(moment()) ? "PAST" : event?.status}
              title={`Volunteers for ${event?.name} with ${event?.charity?.name}`}
              items={attendees}
              totalItemsNum={results}
              maxItemsNum={event?.availableSpots}
              currentPage={page ? Number(page) : 1}
              displayPagination={results > MAX_TABLE_COLUMNS}
            />
            <S.Separator height={60} />
          </>
        )}
      </S.ColumnDisplay>
    </S.VolunteerEventAttendeesPageWrapper>
  );
};

export default VolunteerEventAttendeesPage;
