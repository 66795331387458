import SecondaryButton from "components/buttons/secondary";
import { XIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import {
  resetDialogStateHistory,
  updateDialogState,
} from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import {
  resetCustomVolunteerEventDto,
  resetVolunteerEventDto,
} from "store/volunteer-events-charity/volunteer-events-charity.action";
import colors from "theme/colors";

import * as S from "../dialogs.styled";

const DIALOG_KEY = "CLOSE_POST_CHARITY_EVENT";
const buttonProps = { width: "184px", height: "50px" };

const ClosePostCharityModal: React.FC = () => {
  const dispatch = useDispatch();

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, DIALOG_KEY),
  );

  const prevOpenedDialog = useSelector(uiComponentsSelectors.selectPreviouslyOpenedDialog);

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
    dispatch(resetDialogStateHistory());
    dispatch(resetVolunteerEventDto());
    dispatch(resetCustomVolunteerEventDto());
  };

  const onNo = (): void => {
    dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
    dispatch(updateDialogState({ key: prevOpenedDialog, isOpened: true }));
  };

  return isOpened ? (
    <S.DialogBackground>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20}>
          <S.CloseIconContainer>
            <S.CloseIconButton type="button" onClick={closeDialog}>
              <XIcon />
            </S.CloseIconButton>
          </S.CloseIconContainer>
          <S.GradientHeading fontSize={28}>
            Are you sure you
            <br /> want to proceed?
          </S.GradientHeading>
          <S.DialogSubheading marginTop={40} marginBottom={40}>
            Please be aware that any data you&apos;ve entered will <br /> be lost if you proceed
            with closing.
          </S.DialogSubheading>
          <S.RowDisplay gridGap={16}>
            <SecondaryButton label="Yes" sx={buttonProps} onClick={closeDialog} />
            <SecondaryButton
              label="No"
              sx={{ ...buttonProps, color: colors.bordo() }}
              onClick={onNo}
            />
          </S.RowDisplay>
          <S.Separator height={20} />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default ClosePostCharityModal;
