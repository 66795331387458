import styled from "styled-components";
import { RowDisplay } from "theme/global-style";

export const SearchInputContainer = styled.div`
  flex: 1;
  max-width: 600px;
`;

export const SearchInputFrame = styled(RowDisplay)<{ isFocused?: boolean }>`
  flex: 1;
  max-width: 600px;
  min-width: 220px;
  box-sizing: border-box;
  padding: 9px 16px 9px 16px;
  border: 1px solid
    ${({ isFocused }) => (!isFocused ? "rgba(208, 215, 222, 1)" : "rgba(80, 135, 231, 1)")};
  grid-gap: 8px;
  border-radius: 6px;
  justify-content: flex-start;
`;

export const InputError = styled.div<{ fontSize?: number }>`
  font-size: ${({ fontSize = 12 }) => fontSize}px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.bordo()};
`;

export const TransparentInput = styled.input<{ isHidden?: boolean }>`
  border: none;
  outline: none;
  ${({ isHidden }) => (isHidden ? "height: 0px; width: 0px;" : "width: 90%;")}
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.charcoalGray()};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.charcoalGray(0.3)};
  }
`;
