import { IVolunteerAttendeesSelectors } from "./volunteer-attendees.type";

const volunteerAttendeesSelectors: IVolunteerAttendeesSelectors = {
  selectData: (state) => state.volunteerAttendees.data,
  selectEvent: (state) => state.volunteerAttendees.event,
  selectInProgress: (state) => state.volunteerAttendees.inProgress,
  selectEndReached: (state) => state.volunteerAttendees.isEndReached,
  selectIsFetched: (state) => state.volunteerAttendees.isFetched,
  selectPage: (state) => state.volunteerAttendees.page,
  selectResults: (state) => state.volunteerAttendees.results,
  selectError: (state) => state.volunteerAttendees.error,
};

export default volunteerAttendeesSelectors;
