import styled from "styled-components";
import { Paragraph, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const TagWrapper = styled(RowDisplay)<{ isGradient?: boolean }>`
  background: ${({ theme, isGradient }) =>
    isGradient ? "linear-gradient(180deg, #58ACF7 0%, #4E7FE3 100%)" : theme.paleRobinEggBlue()};
  padding: 8px 14px;
  border-radius: 100px;
  max-width: 100%;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-shrink: 1;
  }
`;

export const TagText = styled(Paragraph)`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  color: ${({ theme }) => theme.charcoalGray()};
  word-break: break-word;
  max-width: 100%;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 10px;
    max-width: 100%;
    word-break: break-word;
  }
`;
