import * as C from "./manage-people.constants";
import * as T from "./manage-people.type";

export const getAdminUsers = {
  request: (payload: T.IGetAdminUsers["payload"]): T.IGetAdminUsers => ({
    type: C.GET_ADMIN_USERS,
    payload,
  }),
  success: (payload: T.IGetAdminUsersSuccess["payload"]): T.IGetAdminUsersSuccess => ({
    type: C.GET_ADMIN_USERS_SUCCESS,
    payload,
  }),
  failure: (): T.IGetAdminUsersFailure => ({
    type: C.GET_ADMIN_USERS_FAILURE,
  }),
};

export const getEmployeeUsers = {
  request: (payload: T.IGetEmployeeUsers["payload"]): T.IGetEmployeeUsers => ({
    type: C.GET_EMPLOYEE_USERS,
    payload,
  }),
  success: (payload: T.IGetEmployeeUsersSuccess["payload"]): T.IGetEmployeeUsersSuccess => ({
    type: C.GET_EMPLOYEE_USERS_SUCCESS,
    payload,
  }),
  failure: (): T.IGetEmployeeUsersFailure => ({
    type: C.GET_EMPLOYEE_USERS_FAILURE,
  }),
};

export const getUsersByPage = {
  request: (payload: T.IGetUsersByPage["payload"]): T.IGetUsersByPage => ({
    type: C.GET_USERS_BY_PAGE,
    payload,
  }),
  success: (payload: T.IGetUsersByPageSuccess["payload"]): T.IGetUsersByPageSuccess => ({
    type: C.GET_USERS_BY_PAGE_SUCCESS,
    payload,
  }),
  failure: (): T.IGetUsersByPageFailure => ({
    type: C.GET_USERS_BY_PAGE_FAILURE,
  }),
};

export const updateUserRole = {
  request: (payload: T.IUpdateUserRole["payload"]): T.IUpdateUserRole => ({
    type: C.UPDATE_USER_ROLE,
    payload,
  }),
  success: (payload: T.IUpdateUserRoleSuccess["payload"]): T.IUpdateUserRoleSuccess => ({
    type: C.UPDATE_USER_ROLE_SUCCESS,
    payload,
  }),
  failure: (): T.IUpdateUserRoleFailure => ({
    type: C.UPDATE_USER_ROLE_FAILURE,
  }),
};

export const removeUser = {
  request: (payload: T.IRemoveUser["payload"]): T.IRemoveUser => ({
    type: C.REMOVE_USER,
    payload,
  }),
  success: (payload: T.IRemoveUserSuccess["payload"]): T.IRemoveUserSuccess => ({
    type: C.REMOVE_USER_SUCCESS,
    payload,
  }),
  failure: (): T.IRemoveUserFailure => ({
    type: C.REMOVE_USER_FAILURE,
  }),
};

export const inviteUser = {
  request: (payload: T.IInviteUser["payload"]): T.IInviteUser => ({
    type: C.INVITE_USER,
    payload,
  }),
  success: (): T.IInviteUserSuccess => ({
    type: C.INVITE_USER_SUCCESS,
  }),
  failure: (): T.IInviteUserFailure => ({
    type: C.INVITE_USER_FAILURE,
  }),
};

export const getUserStatistics = {
  request: (): T.IGetUserStatistics => ({
    type: C.GET_USER_STATISTICS,
  }),
  success: (payload: T.IGetUserStatisticsSuccess["payload"]): T.IGetUserStatisticsSuccess => ({
    type: C.GET_USER_STATISTICS_SUCCESS,
    payload,
  }),
  failure: (): T.IGetUserStatisticsFailure => ({
    type: C.GET_USER_STATISTICS_FAILURE,
  }),
};
