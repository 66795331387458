import { ArrowLeftIcon, GlobeIcon, LocationIcon } from "@primer/octicons-react";
import PrimaryButton from "components/buttons/primary";
import SecondaryButton from "components/buttons/secondary";
import FixedHeader from "components/fixed-header";
import Tag from "components/tag";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import authSelectors from "store/auth/auth.selector";
import {
  addEveryOrgToCharityList,
  removeEveryOrgFromCharityList,
} from "store/charities/charities.action";
import companySelectors from "store/company/company.selector";
import { getEveryOrgCharityDetails } from "store/every-org/every-org.action";
import everyOrgSelectors from "store/every-org/every-org.selector";
import { showFullScreenLoader } from "store/ui-components/ui-components.actions";
import userSelectors from "store/user/user.selector";
import colors from "theme/colors";
import { DISBURSEMENT_TYPE } from "types/every";
import { DONATION_METHODS, MAX_LINES_FOR_READ_MORE } from "utils/constants";
import { uriEncode } from "utils/helper";
import routes from "utils/routes";
import { v4 as uuidv4 } from "uuid";

import * as S from "./charity-view.styled";

const EveryOrgCharityDetailsPage: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  const backFromHome = location.state?.backFromHome;

  const [partnerDonationId] = useState<string>(uuidv4());
  const [coverLoading, loadCover] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const { slug } = params;

  const charityDetails = useSelector(everyOrgSelectors.selectCharityDetails);
  const companyName = useSelector(companySelectors.selectName);
  const user = useSelector(userSelectors.selectData);
  const role = useSelector(authSelectors.selectRole);

  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (slug && charityDetails?.primarySlug !== slug) {
      loadCover(true);
      dispatch(showFullScreenLoader());
      dispatch(getEveryOrgCharityDetails.request({ slug }));
    }
  }, [slug]);

  useEffect(() => {
    if (descriptionRef.current) {
      const lineHeight = parseFloat(getComputedStyle(descriptionRef.current).lineHeight);
      const height = descriptionRef.current.scrollHeight;
      const lines = height / lineHeight;
      setIsTruncated(lines > MAX_LINES_FOR_READ_MORE);
    }
  }, [charityDetails?.descriptionLong]);

  const successUrl = `${uriEncode(window.__RUNTIME_CONFIG__.REACT_APP_URL)}${
    routes.EVERY_DOT_ORG.DONATION_SUCCESS
  }/${companyName}/${partnerDonationId}`.replaceAll("//", "/");

  const exitUrl = `${uriEncode(window.__RUNTIME_CONFIG__.REACT_APP_URL)}${
    routes.EVERY_DOT_ORG.CHARITY_DETAILS
  }/${slug}`.replaceAll("//", "/");

  const webhookToken = window.__RUNTIME_CONFIG__.EVERY_DOT_ORG_WEBHOOK_TOKEN;

  const donationUrl = `${window.__RUNTIME_CONFIG__.EVERY_DOT_ORG_DONATE_LINK}${
    charityDetails?.ein ? charityDetails?.ein?.replaceAll("-", "") : charityDetails?.primarySlug
  }?first_name=${user?.firstName}&last_name=${user?.lastName}&email=${
    user?.email
  }&webhook_token=${webhookToken}&partner_donation_id=${partnerDonationId}&method=${DONATION_METHODS}%2Cbank&success_url=${successUrl}&exit_url=${exitUrl}&frequency=ONCE&theme_color=6185E2#donate`;

  React.useEffect(() => {
    const storedReactRoute = localStorage.getItem("previousReactRoute");
    if (storedReactRoute) {
      localStorage.removeItem("previousReactRoute");
    }
  }, []);

  const getDisbursementDescription = (type: DISBURSEMENT_TYPE) => {
    switch (type) {
      case "STRIPE_CONNECT":
        return "Funds are sent weekly";
      case "PAYPAL_GRANTS":
      case "NFG_BATCH_FILE":
        return "Funds are sent monthly";
      case "MANUAL":
        return "Funds are sent intermittently";
      default:
        return "";
    }
  };

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDonationClick = () => {
    // Store the current location before navigating to an external link
    localStorage.setItem("previousReactRoute", JSON.stringify(location));
    window.open(donationUrl);
  };

  const handleGoBack = () => {
    const storedReactRoute = localStorage.getItem("previousReactRoute");
    if (storedReactRoute) {
      navigate(-2);
    } else {
      navigate(-1);
    }
  };

  return (
    <S.CharityViewContainer gridGap={38} justifyContent="flex-start">
      <FixedHeader name={companyName} />

      <S.Separator height={20} />
      <S.CharityViewInnerWrapper flexWrap gridGap={20} alignItems="flex-start">
        <S.ColumnDisplay style={{ flex: 1 }}>
          <S.BackIndicator onClick={handleGoBack}>
            <ArrowLeftIcon size={25} />
            <div>Back {backFromHome ? "" : "to exploring"}</div>
          </S.BackIndicator>

          <S.CharityContentContainer
            maxWidth={669}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <S.RowDisplay alignItems="flex-start" gridGap={60} flexWrap>
              <S.CharityLogo src={charityDetails?.logoUrl} />
              <S.CharityName>{charityDetails?.name}</S.CharityName>
            </S.RowDisplay>
            <S.Separator height={40} />
            <S.ContentDevider />
            <S.Separator height={40} />
            <S.RowDisplay gridGap={16} flexWrap justifyContent="flex-start" width="100%">
              {charityDetails?.nteeCodeMeaning?.majorMeaning && (
                <S.GradientTagWrapper>
                  <S.GradientTag>
                    <S.GradientTagText>
                      {charityDetails?.nteeCodeMeaning?.majorMeaning}
                    </S.GradientTagText>
                  </S.GradientTag>
                </S.GradientTagWrapper>
              )}
              {charityDetails?.nteeCodeMeaning?.decileMeaning && (
                <S.GradientTagWrapper>
                  <S.GradientTag>
                    <S.GradientTagText>
                      {charityDetails?.nteeCodeMeaning?.decileMeaning}
                    </S.GradientTagText>
                  </S.GradientTag>
                </S.GradientTagWrapper>
              )}
            </S.RowDisplay>
            {(charityDetails?.ein || charityDetails?.locationAddress) && (
              <>
                <S.Separator height={40} />
                <S.CharityDetailsHeading>organization details</S.CharityDetailsHeading>
                <S.Separator height={40} />
              </>
            )}

            <S.ColumnDisplay alignItems="flex-start" gridGap={20} width="100%">
              {charityDetails?.ein && (
                <S.OrganizationInfo>{`EIN: ${charityDetails?.ein}`}</S.OrganizationInfo>
              )}

              {charityDetails?.locationAddress && (
                <S.OrganizationInfo>
                  <span>
                    <LocationIcon size={24} />
                  </span>
                  {charityDetails?.locationAddress}
                </S.OrganizationInfo>
              )}

              {charityDetails?.websiteUrl && (
                <S.OrganizationInfo
                  isClickable
                  onClick={() =>
                    window.open(charityDetails.websiteUrl, "_blank", "noopener,noreferrer")
                  }
                >
                  <span>
                    <GlobeIcon size={24} />
                  </span>
                  {charityDetails?.websiteUrl}
                </S.OrganizationInfo>
              )}
              {charityDetails?.disbursementType && (
                <Tag
                  text={getDisbursementDescription(charityDetails?.disbursementType)}
                  isChecked={false}
                  style={
                    charityDetails?.disbursementType === "STRIPE_CONNECT"
                      ? { color: colors.green(), background: "#E3FBF8", fontSize: "16px" }
                      : {
                          color: colors.unitedNationsBlue(0.9),
                          background: "#DFE7F9",
                          fontSize: "16px",
                        }
                  }
                />
              )}
            </S.ColumnDisplay>
            <S.Separator height={75} />
            <PrimaryButton
              label="DONATE"
              onClick={handleDonationClick}
              sx={{
                width: "100%",
                maxWidth: "100%",
                height: "72px",
                fontSize: 28,
                padding: "0px",
              }}
            />
            {role === "admin" && (
              <SecondaryButton
                label={charityDetails?.backed ? "Remove from company wall" : "Add to company wall"}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!charityDetails?.backed) {
                    dispatch(
                      addEveryOrgToCharityList.request({ slug: charityDetails.primarySlug }),
                    );
                  } else {
                    dispatch(
                      removeEveryOrgFromCharityList.request({
                        slug: charityDetails.primarySlug,
                      }),
                    );
                  }
                }}
                sx={{ width: "100%", marginTop: "20px" }}
              />
            )}
          </S.CharityContentContainer>
        </S.ColumnDisplay>
        {(charityDetails?.coverImageUrl || charityDetails?.descriptionLong) && (
          <S.CharityContentContainer
            maxWidth={689}
            alignItems="flex-start"
            justifyContent="flex-start"
            hidePadding
          >
            <S.CharityCoverWrapper>
              <S.CharityCover
                src={charityDetails?.coverImageUrl}
                onLoad={() => loadCover(false)}
                style={{ opacity: coverLoading ? 0 : 1 }}
              />
            </S.CharityCoverWrapper>
            <S.CharityTextContainer alignItems="flex-start" gridGap={0}>
              <S.CharityAbout>about non-profit</S.CharityAbout>
              <S.CharityDetails ref={descriptionRef} isExpanded={isExpanded}>
                {charityDetails?.descriptionLong || "There is no description provided."}
              </S.CharityDetails>
              {isTruncated && (
                <S.ReadMoreButton onClick={toggleReadMore}>
                  {isExpanded ? "Read Less" : "Read More"}
                </S.ReadMoreButton>
              )}
            </S.CharityTextContainer>
          </S.CharityContentContainer>
        )}
      </S.CharityViewInnerWrapper>
    </S.CharityViewContainer>
  );
};

export default EveryOrgCharityDetailsPage;
