import FixedHeader from "components/fixed-header";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";

import * as S from "./check-in-success.styled";

const CheckInSuccessPage: React.FC<{}> = (): JSX.Element => {
  const params = useParams();

  const isMobile = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const { companyName } = params;

  return (
    <S.BubbleBackground justifyContent={isMobile ? "center" : "space-between"} gridGap={16}>
      {!isMobile ? <FixedHeader name={companyName || ""} /> : <S.Separator height={1} />}
      <S.FormWrapperWithPadding>
        <S.ColumnDisplay width="100%" gridGap={16}>
          {isMobile && (
            <S.RowDisplay>
              <LogoAndCompany
                mobileFontSize={14}
                companyName={companyName || null}
                showVersion={false}
                isFullWidth
              />
            </S.RowDisplay>
          )}
          <S.RowDisplay gridGap={isMobile ? 1 : 3}>
            <S.CheckInGradientHeading>You are checked-in&nbsp;</S.CheckInGradientHeading>🎉
          </S.RowDisplay>
          <S.Separator height={isMobile ? 0 : 10} />
          <S.CheckInParagraph>Happy Volunteering!</S.CheckInParagraph>
          <S.Separator height={isMobile ? 1 : 10} />
        </S.ColumnDisplay>
      </S.FormWrapperWithPadding>
      {!isMobile && <LogoAndCompany companyName={companyName || ""} showMargin />}
    </S.BubbleBackground>
  );
};

export default CheckInSuccessPage;
