import PrimaryButton from "components/buttons/primary";
import SecondaryButton from "components/buttons/secondary";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import CharityAuthFormTemplate from "templates/charity-auth-form";
import { contactSupport } from "utils/helper";
import routes from "utils/routes";

import * as S from "./charity-landing.styled";

const CharityLandingPage: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();

  const { companyName } = params;

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const elementSX: React.CSSProperties = {
    width: !isTablet ? 391 : 280,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const gapBig = isTablet ? 40 : 60;

  return (
    <CharityAuthFormTemplate>
      <>
        <S.Separator height={gapBig} />
        <S.LabelGray>Select to proceed:</S.LabelGray>
        <S.Separator height={gapBig} />
        <PrimaryButton
          label="Login"
          onClick={() => navigate(routes.CHARITY.LOGIN)}
          sx={elementSX}
        />
        <S.Separator height={20} />
        <S.LabelFaded width={391} style={elementSX}>
          or
        </S.LabelFaded>
        <S.Separator height={20} />
        <SecondaryButton
          label="Register my organization"
          onClick={() => navigate(routes.CHARITY.REGISTER)}
          sx={elementSX}
        />
        <S.Separator height={12} />
        <S.Paragraph fontSize={12} style={elementSX}>
          Are you having difficulties to proceed? {isTablet && <br />}
          <span>
            <S.LinkText onClick={contactSupport} fontSize={12}>
              Contact support here.
            </S.LinkText>
          </span>
        </S.Paragraph>
      </>
    </CharityAuthFormTemplate>
  );
};

export default CharityLandingPage;
