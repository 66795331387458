import * as api from "api/donations";
import { combineEpics, ofType } from "redux-observable";
import CustomHistory from "router/history";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import companySelectors from "store/company/company.selector";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { showToast, updateDialogState } from "store/ui-components/ui-components.actions";
import routes from "utils/routes";
import { ACCEPTED } from "utils/status-code";

import {
  getDonations,
  matchDonation,
  matchEveryDonation,
  respondToDonation,
} from "./donations.action";
import * as C from "./donations.constants";
import donationsSelectors from "./donations.selector";
import * as T from "./donations.type";

const getDonationsEpic: AppEpic = (action$: Observable<T.IGetDonationsRequest>, state$) =>
  action$.pipe(
    ofType(C.GET_DONATIONS),
    mergeMap((action) => {
      const companyName = companySelectors.selectName(state$?.value);
      return from(
        api.getDonations(companyName, action.payload.table, action.payload.page, {
          q: action.payload.q,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          sortBy: action.payload.sortBy,
          order: action.payload.order,
        }),
      ).pipe(
        mergeMap((response) => {
          if (response) {
            const donations = response.data.donations.map((item) => ({
              ...item,
              date: action.payload.table === "fulfilled" ? item.updatedAt : item.createdAt,
            }));
            return of(
              getDonations.success({
                response: { ...response.data, donations },
                table: action.payload.table,
                ...action.payload,
              }),
            );
          }
          return of(getDonations.failure({ table: action.payload.table }));
        }),
        catchError((error) =>
          of(getDonations.failure({ table: action.payload.table }), handleError({ action, error })),
        ),
      );
    }),
  );

const matchDonationEpic: AppEpic = (action$: Observable<T.IMatchDonationRequest>, state$) =>
  action$.pipe(
    ofType(C.MATCH_DONATION),
    mergeMap((action) => {
      const companyName = companySelectors.selectName(state$?.value);
      return from(api.matchDonation(companyName, action.payload)).pipe(
        mergeMap((response) => {
          if (response === 202) {
            CustomHistory.navigate(routes.FIND_CHARITIES);
            return of(
              matchDonation.success(),
              showToast({ message: "Donation submitted for matching.", type: "success" }),
            );
          }

          return of(matchDonation.failure());
        }),
        catchError((error) => {
          if (error.response.data.error.code === "GIFT_MATCH_NOT_APPLICABLE") {
            CustomHistory.navigate(`${routes.DONATION.GIFT_MATCHING_FAILED}/${companyName}`);
          } else {
            return of(matchDonation.failure(), handleError({ action, error }));
          }

          return of(matchDonation.failure());
        }),
      );
    }),
  );

const matchEveryDonationEpic: AppEpic = (action$: Observable<T.IMatchEveryDonationRequest>) =>
  action$.pipe(
    ofType(C.MATCH_EVERY_DONATION),
    mergeMap((action) =>
      from(api.matchEveryDonation(action.payload.companyName, action.payload.body)).pipe(
        mergeMap((response) => {
          if (response === ACCEPTED) {
            CustomHistory.navigate(routes.FIND_CHARITIES, { replace: true });
            return of(
              matchEveryDonation.success(),
              showToast({ message: "Donation submitted for matching.", type: "success" }),
            );
          }
          return of(matchDonation.failure());
        }),
        catchError((error) => {
          if (error.response.data.error.code === "GIFT_MATCH_NOT_APPLICABLE") {
            CustomHistory.navigate(
              `${routes.DONATION.GIFT_MATCHING_FAILED}/${action.payload.companyName}`,
            );
          } else {
            return of(matchDonation.failure(), handleError({ action, error }));
          }

          return of(matchEveryDonation.failure());
        }),
      ),
    ),
  );

const respondToDonationEpic: AppEpic = (action$: Observable<T.IRespondToDonationRequest>, state$) =>
  action$.pipe(
    ofType(C.RESPOND_TO_DONATION),
    mergeMap((action) => {
      const companyName = companySelectors.selectName(state$?.value);
      return from(api.respondToGiftMatch(companyName, action.payload)).pipe(
        mergeMap((response) => {
          if (response.data) {
            const loadedPendingDonations = donationsSelectors.selectDonationsList(
              state$.value,
              "pending",
            );
            const pendingDonationsTotalNum = donationsSelectors.selectDonationsResults(
              state$.value,
              "pending",
            );
            const pendingDonationsCriteria = donationsSelectors.selectDonationsCriteria(
              state$.value,
              "pending",
            );
            const loadMoreDonations =
              loadedPendingDonations?.length <= 1 &&
              pendingDonationsTotalNum > loadedPendingDonations.length - 1;
            const actions = loadMoreDonations
              ? [
                  respondToDonation.success(action.payload),
                  updateDialogState({ key: "APPROVE_DONATION", isOpened: false }),
                  getDonations.request({ page: 1, table: "pending", ...pendingDonationsCriteria }),
                ]
              : [
                  respondToDonation.success(action.payload),
                  updateDialogState({ key: "APPROVE_DONATION", isOpened: false }),
                ];
            return of(...actions, showToast({ message: response.data, type: "success" }));
          }
          return of(respondToDonation.failure());
        }),
        catchError((error) => of(respondToDonation.failure(), handleError({ action, error }))),
      );
    }),
  );

export default combineEpics(
  getDonationsEpic,
  matchDonationEpic,
  matchEveryDonationEpic,
  respondToDonationEpic,
);
