import { cacheSlice } from "store/store.cache";

import { IUserDonationsState } from "./user-donations.type";

export const cacheUserDonations = (
  initialState: IUserDonationsState,
  state: IUserDonationsState,
): IUserDonationsState => {
  const cachedState: IUserDonationsState = {
    ...initialState,
    isCached: true,
    data: state.data,
  };

  cacheSlice("userDonations", cachedState);

  return {
    ...state,
    isCached: true,
  };
};

export const clearUserDonationsCache = (initialState: IUserDonationsState): IUserDonationsState => {
  cacheSlice("userDonations", initialState);
  return initialState;
};
