import * as api from "api/volunteer";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import {
  fetchActiveCompanyVolunteerEvents,
  refreshActiveCompanyVolunteerEvents,
} from "store/company-active-volunteer-events/company-active-volunteer-events.action";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { showToast, updateDialogState } from "store/ui-components/ui-components.actions";
import { fetchVolunteerEvent } from "store/volunteer-event-details/volunteer-event-details.action";

import {
  editVolunteerEvent,
  fetchCharityVolunteerEvents,
  fetchVolunteerEvents,
  postCustomVolunteerEvent,
  postVolunteerEvent,
} from "./volunteer-events-charity.action";
import * as C from "./volunteer-events-charity.constants";
import volunteerSelectors from "./volunteer-events-charity.selector";
import * as T from "./volunteer-events-charity.type";

export const fetchVolunteerEventsEpic: AppEpic = (action$: Observable<T.IFetchVolunteerEvents>) =>
  action$.pipe(
    ofType(C.FETCH_VOLUNTEER_EVENTS),
    mergeMap((action) =>
      from(api.getVolunteerEvents(action.payload)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchVolunteerEvents.success(response.data));
          }
          return of(handleError({ action, error: null }), fetchVolunteerEvents.failure());
        }),
        catchError((error) => of(fetchVolunteerEvents.failure(), handleError({ action, error }))),
      ),
    ),
  );

const postVolunteerEventEpic: AppEpic = (action$: Observable<T.IPostVolunteerEvent>, state$) =>
  action$.pipe(
    ofType(C.POST_VOLUNTEER_EVENT),
    mergeMap((action) => {
      const dto = volunteerSelectors.selectDto(state$.value);
      const formData = new FormData();
      const volunteerEvent = { ...action.payload, ...dto };
      Object.keys(volunteerEvent).forEach((value) => {
        if (!!value && !!volunteerEvent[value]) {
          formData.append(
            value,
            value === "waiver" ? volunteerEvent[value] : volunteerEvent[value]?.toString(),
          );
        }
      });
      return from(api.postVolunteerEvent(formData)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(
              postVolunteerEvent.success(response.data),
              updateDialogState({ key: "POST_CHARITY_EVENT_STEP_2", isOpened: false }),
              updateDialogState({ key: "POST_EVENT_THANKS", isOpened: true }),
            );
          }
          return of(
            postVolunteerEvent.failure(),
            showToast({ type: "failure", message: "Something went wrong." }),
          );
        }),
        catchError((error) => of(postVolunteerEvent.failure(), handleError({ action, error }))),
      );
    }),
  );

const postCustomVolunteerEventEpic: AppEpic = (
  action$: Observable<T.IPostCustomVolunteerEvent>,
  state$,
) =>
  action$.pipe(
    ofType(C.POST_CUSTOM_VOLUNTEER_EVENT),
    mergeMap((action) => {
      const dto = volunteerSelectors.selectCustomVolunteerEventDto(state$.value);
      const customVolunteerEvent = { ...dto, ...action.payload };
      return from(api.postCustomVolunteerEvent(customVolunteerEvent)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(
              postCustomVolunteerEvent.success(response.data),
              updateDialogState({ key: "POST_CUSTOM_VOLUNTEER_EVENT_STEP_2", isOpened: false }),
              updateDialogState({ key: "POST_EVENT_THANKS", isOpened: true }),
              refreshActiveCompanyVolunteerEvents.request({
                params: { page: 1 },
                status: "ACTIVE",
              }),
            );
          }
          return of(
            postCustomVolunteerEvent.failure(),
            showToast({ type: "failure", message: "Something went wrong." }),
          );
        }),
        catchError((error) =>
          of(postCustomVolunteerEvent.failure(), handleError({ action, error })),
        ),
      );
    }),
  );

const editVolunteerEventEpic: AppEpic = (action$: Observable<T.IEditVolunteerEvent>, state$) =>
  action$.pipe(
    ofType(C.EDIT_VOLUNTEER_EVENT),
    mergeMap((action) => {
      const dto = volunteerSelectors.selectDto(state$.value);
      const formData = new FormData();
      const volunteerEvent = { ...dto, ...action.payload };
      Object.keys(volunteerEvent).forEach((value) => {
        if (!!value && !!volunteerEvent[value]) {
          formData.append(
            value,
            value === "waiver" ? volunteerEvent[value] : volunteerEvent[value]?.toString(),
          );
        }
      });
      return from(api.editVolunteerEvent(action.payload?.id, formData)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(
              editVolunteerEvent.success(response.data),
              updateDialogState({ key: "POST_CHARITY_EVENT_STEP_2", isOpened: false }),
              showToast({ type: "success", message: "Event is successfully updated." }),
              fetchVolunteerEvent.request({ id: action.payload?.id }),
            );
          }
          return of(
            editVolunteerEvent.failure(),
            showToast({ type: "failure", message: "Something went wrong." }),
          );
        }),
        catchError((error) => of(editVolunteerEvent.failure(), handleError({ action, error }))),
      );
    }),
  );

export const fetchCharityVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IFetchCharityVolunteerEvents>,
) =>
  action$.pipe(
    ofType(C.FETCH_CHARITY_VOLUNTEER_EVENTS),
    mergeMap((action) =>
      from(api.getCharityVolunteerEvents()).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchCharityVolunteerEvents.success(response.data));
          }
          return of(handleError({ action, error: null }), fetchCharityVolunteerEvents.failure());
        }),
        catchError((error) =>
          of(fetchCharityVolunteerEvents.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export default combineEpics(
  fetchVolunteerEventsEpic,
  postVolunteerEventEpic,
  editVolunteerEventEpic,
  fetchCharityVolunteerEventsEpic,
  postCustomVolunteerEventEpic,
);
