import { ServerResponse } from "types/common";
import { CleintSecretDto, CreatePaymentIntentDTO } from "types/payment";

import client from "./client";

export const getPaymentIntent = async (
  body: CreatePaymentIntentDTO,
): Promise<ServerResponse<CleintSecretDto>> =>
  (await client.post("/payments/paymentIntents", body)).data;

export const setupPaymentMethod = async (): Promise<ServerResponse<CleintSecretDto>> =>
  (await client.post("/payments/setupPaymentMethod")).data;
