import styled from "styled-components";
import { RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ColumnDisplay,
  GradientHeading,
  Paragraph,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const ProgressCounterWrapper = styled(RowDisplay)`
  margin-top: 100px;
  margin-bottom: 20px;
  grid-gap: 4px;
  flex-shrink: 0;
`;

export const ProgressCounter = styled.div<{ isActive: boolean }>`
  background: ${({ isActive, theme }) =>
    isActive ? theme.unitedNationsBlue() : "rgba(219, 225, 232, 1)"};
  height: 5px;
  width: 127px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 60px;
    width: 92px;
  }
`;

export const ProgressCounterText = styled.div<{ isActive: boolean }>`
  color: ${({ isActive, theme }) =>
    isActive ? theme.unitedNationsBlue() : "rgba(219, 225, 232, 1)"};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15000000596046448px;
  word-break: break-word;
  max-width: 127px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 92px;
  }
`;
