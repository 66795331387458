import { IDonationsSelectors } from "./donations.type";

const donationsSelectors: IDonationsSelectors = {
  selectDonations: (state, table) => state.donations[table],
  selectDonationsList: (state, table) => state.donations[table].data,
  selectDonationsFetched: (state, table) => state.donations[table].isFetched,
  selectDonationsProgress: (state, table) => state.donations[table].inProgress,
  selectDonationsResults: (state, table) => state.donations[table].results,
  selectDonationsEndReached: (state, table) => state.donations[table].isEndReached,
  selectDonationsPage: (state, table) => state.donations[table].page,
  selectDonationsCriteria: (state, table) => ({
    q: state.donations[table].q,
    startDate: state.donations[table].startDate,
    endDate: state.donations[table].endDate,
    sortBy: state.donations[table].sortBy,
    order: state.donations[table].order,
  }),
  selectInProgress: (state) => state.donations.inProgress,
  selectError: (state) => state.donations.error,
  selectIsFetched: (state) => state.donations.isFetched,
};

export default donationsSelectors;
