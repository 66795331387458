import * as api from "api/company";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { showToast, updateDialogState } from "store/ui-components/ui-components.actions";
import { MAX_TABLE_COLUMNS } from "utils/constants";

import {
  getAdminUsers,
  getEmployeeUsers,
  getUsersByPage,
  getUserStatistics,
  inviteUser,
  removeUser,
  updateUserRole,
} from "./manage-people.action";
import * as C from "./manage-people.constants";
import * as T from "./manage-people.type";

const getAdminUsersEpic: AppEpic = (action$: Observable<T.IGetAdminUsers>) =>
  action$.pipe(
    ofType(C.GET_ADMIN_USERS),
    mergeMap((action) =>
      from(api.getUsers({ ...action.payload, page: 1, type: "admins" })).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getAdminUsers.success(response.data));
          }
          return of(getAdminUsers.failure());
        }),
        catchError((error) => of(getAdminUsers.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getEmployeeUsersEpic: AppEpic = (action$: Observable<T.IGetEmployeeUsers>) =>
  action$.pipe(
    ofType(C.GET_EMPLOYEE_USERS),
    mergeMap((action) =>
      from(api.getUsers({ ...action.payload, page: 1, type: "employees" })).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getEmployeeUsers.success(response.data));
          }
          return of(getEmployeeUsers.failure());
        }),
        catchError((error) => of(getEmployeeUsers.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getUsersByPageEpic: AppEpic = (action$: Observable<T.IGetUsersByPage>) =>
  action$.pipe(
    ofType(C.GET_USERS_BY_PAGE),
    mergeMap((action) =>
      from(
        api.getUsers({
          page: action.payload.page,
          type: action.payload.role,
        }),
      ).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getUsersByPage.success({ ...action.payload, response: response.data }));
          }
          return of(getUsersByPage.failure());
        }),
        catchError((error) => of(getUsersByPage.failure(), handleError({ action, error }))),
      ),
    ),
  );

const updateUserRoleEpic: AppEpic = (action$: Observable<T.IUpdateUserRole>, $state) =>
  action$.pipe(
    ofType(C.UPDATE_USER_ROLE),
    mergeMap((action) =>
      from(
        api.updateUserRole(action.payload.companyName, action.payload.userId, action.payload.role),
      ).pipe(
        mergeMap(() => {
          const state = $state.value.managePeople;
          const editedUser = {
            ...(state.employees.data.find((item) => item.id === action.payload.userId) ??
              state.admins.data.find((item) => item.id === action.payload.userId)),
            role: action.payload.role,
          };
          const _state = {
            ...state,
            admins: {
              ...state.admins,
              data:
                action.payload.role !== "EMPLOYEE"
                  ? [
                      ...state.admins.data.filter((item) => item.id !== action.payload.userId),
                      editedUser,
                    ]
                  : state.admins.data?.filter((item) => item.id !== action.payload.userId),
            },
            employees: {
              ...state.employees,
              data:
                action.payload.role !== "EMPLOYEE"
                  ? state.employees?.data.filter((item) => item.id !== action.payload.userId)
                  : [...state.employees.data, editedUser],
            },
          };

          const actions: any[] = [
            updateUserRole.success(
              _state.admins.data.length < state.admins.data.length
                ? {
                    ..._state,
                  }
                : _state.employees.data.length < state.employees.data.length
                ? {
                    ..._state,
                  }
                : {
                    ..._state,
                  },
            ),
            updateDialogState({ key: "EDIT_ROLE", isOpened: false }),
            showToast({ message: "Role updated sucessfully!", type: "success" }),
          ];
          if (
            _state.admins.data.length < MAX_TABLE_COLUMNS &&
            _state.numAdmins > state.admins.data.length
          ) {
            actions.push(getUsersByPage.request({ page: _state.admins.page, role: "admins" }));
          } else if (
            _state.employees.data.length < MAX_TABLE_COLUMNS &&
            _state.numEmployees > state.employees.data.length
          ) {
            actions.push(
              getUsersByPage.request({ page: _state.employees.page, role: "employees" }),
            );
          }
          return of(...actions);
        }),
        catchError((error) => of(updateUserRole.failure(), handleError({ action, error }))),
      ),
    ),
  );

const removeUserEpic: AppEpic = (action$: Observable<T.IRemoveUser>, $state) =>
  action$.pipe(
    ofType(C.REMOVE_USER),
    mergeMap((action) =>
      from(api.removeUser(action.payload.companyName, action.payload.userId)).pipe(
        mergeMap(() => {
          const state = $state.value.managePeople;
          const _state = {
            ...state,
            employees: {
              ...state.employees,
              data: state.employees.data.filter((item) => item.id !== action.payload.userId),
            },
            admins: {
              ...state.admins,
              data: state.admins.data.filter((item) => item.id !== action.payload.userId),
            },
          };
          const payload = {
            ..._state,
            numAdmins:
              state.admins.data.length > _state.admins.data.length
                ? state.numAdmins - 1
                : state.numAdmins,
            numEmployees:
              state.employees.data.length > _state.employees.data.length
                ? state.numEmployees - 1
                : state.numEmployees,
          };
          const actions: any[] = [
            removeUser.success(payload),
            updateDialogState({ key: "REMOVE_EMPLOYEE", isOpened: false }),
            showToast({ message: "User removed sucessfully.", type: "success" }),
          ];
          if (
            _state.admins.data.length < MAX_TABLE_COLUMNS &&
            _state.numAdmins > state.admins.data.length
          ) {
            actions.push(getUsersByPage.request({ page: _state.admins.page, role: "admins" }));
          } else if (
            _state.employees.data.length < MAX_TABLE_COLUMNS &&
            _state.numEmployees > state.employees.data.length
          ) {
            actions.push(
              getUsersByPage.request({ page: _state.employees.page, role: "employees" }),
            );
          }
          return of(...actions);
        }),
        catchError((error) => of(removeUser.failure(), handleError({ action, error }))),
      ),
    ),
  );

const inviteUserEpic: AppEpic = (action$: Observable<T.IInviteUser>) =>
  action$.pipe(
    ofType(C.INVITE_USER),
    mergeMap((action) =>
      from(api.inviteUser(action.payload)).pipe(
        mergeMap((response) => {
          if (response) {
            return of(
              inviteUser.success(),
              showToast({ message: "User invited successfully!", type: "success" }),
              action.payload.role === "EMPLOYEE"
                ? updateDialogState({ key: "ADD_EMPLOYEE", isOpened: false })
                : updateDialogState({ key: "INVITE_ADMIN", isOpened: false }),
            );
          }
          return of(inviteUser.failure());
        }),
        catchError((error) => of(inviteUser.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getUserStatisticsEpic: AppEpic = (action$: Observable<T.IGetUserStatistics>) =>
  action$.pipe(
    ofType(C.GET_USER_STATISTICS),
    mergeMap((action) =>
      from(api.getUserStatistics()).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getUserStatistics.success(response.data));
          }
          return of(getUserStatistics.failure());
        }),
        catchError((error) => of(getUserStatistics.failure(), handleError({ action, error }))),
      ),
    ),
  );

export default combineEpics(
  getAdminUsersEpic,
  getEmployeeUsersEpic,
  updateUserRoleEpic,
  removeUserEpic,
  inviteUserEpic,
  getUsersByPageEpic,
  getUserStatisticsEpic,
);
