import ActivateEventDialog from "components/dialogs/activate-event";
import AddEmployeeDialog from "components/dialogs/add-employee";
import ApproveDonationDialog from "components/dialogs/approve-donation";
import CancelEventDialog from "components/dialogs/cancel-event";
import ClosePostCharityModal from "components/dialogs/close-post-charity-modal";
import ConnectPaymentMethodDialog from "components/dialogs/connect-stripe";
import DeclineDonationDialog from "components/dialogs/decline-donation";
import DonateDialog from "components/dialogs/donate";
import EditProfileDialog from "components/dialogs/edit-profile";
import EditRoleDialog from "components/dialogs/edit-role";
import InviteAdminDialog from "components/dialogs/invite-admin";
import InviteAdminsDialog from "components/dialogs/invite-admins";
import MessageEmployeesDialog from "components/dialogs/message-employees";
import MetricsAndAnalyticsDialog from "components/dialogs/metrics-and-analytics";
import OffPlatformDonationDialog from "components/dialogs/off-platform-donation";
import PostVolunteerEventStep1Dialog from "components/dialogs/post-charity-event/step1";
import PostVolunteerEventStep2Dialog from "components/dialogs/post-charity-event/step2";
import PostCustomVolunteerEventStep1Dialog from "components/dialogs/post-custom-volunteer-event/step1";
import PostCustomVolunteerEventStep2Dialog from "components/dialogs/post-custom-volunteer-event/step2";
import PostEventInstructionsDialog from "components/dialogs/post-event-instructions";
import PostEventThanks from "components/dialogs/post-event-thanks/post-event-thanks";
import QRCodeDialog from "components/dialogs/qr-code";
import RemoveEmployeeDialog from "components/dialogs/remove-employee-confirmation";
import RequestedIntroDialog from "components/dialogs/requested-intro";
import UpdateInterestsDialog from "components/dialogs/update-interests";
import UploadCompanyLogoDialog from "components/dialogs/upload-company-logo";
import VolunteerEventSignUpDialog from "components/dialogs/volunteer-event-signup";
import React from "react";

const Dialogs: React.FC = (): JSX.Element => (
  <>
    <AddEmployeeDialog />
    <EditRoleDialog />
    <InviteAdminDialog />
    <RemoveEmployeeDialog />
    <DonateDialog />
    <ConnectPaymentMethodDialog />
    <ApproveDonationDialog />
    <DeclineDonationDialog />
    <EditProfileDialog />
    <OffPlatformDonationDialog />
    <MetricsAndAnalyticsDialog />
    <UpdateInterestsDialog />
    <UploadCompanyLogoDialog />
    <VolunteerEventSignUpDialog />
    <PostVolunteerEventStep1Dialog />
    <PostVolunteerEventStep2Dialog />
    <ClosePostCharityModal />
    <PostEventThanks />
    <RequestedIntroDialog />
    <CancelEventDialog />
    <PostEventInstructionsDialog />
    <ActivateEventDialog />
    <QRCodeDialog />
    <MessageEmployeesDialog />
    <InviteAdminsDialog />
    <PostCustomVolunteerEventStep1Dialog />
    <PostCustomVolunteerEventStep2Dialog />
  </>
);

export default Dialogs;
