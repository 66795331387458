export const MATCH_DONATION = "MATCH_DONATION";
export const MATCH_DONATION_SUCCESS = "MATCH_DONATION_SUCCESS";
export const MATCH_DONATION_FAILURE = "MATCH_DONATION_FAILURE";

export const MATCH_EVERY_DONATION = "MATCH_EVERY_DONATION";
export const MATCH_EVERY_DONATION_SUCCESS = "MATCH_EVERY_DONATION_SUCCESS";
export const MATCH_EVERY_DONATION_FAILURE = "MATCH_EVERY_DONATION_FAILURE";

export const RESPOND_TO_DONATION = "RESPOND_TO_DONATION";
export const RESPOND_TO_DONATION_SUCCESS = "RESPOND_TO_DONATION_SUCCESS";
export const RESPOND_TO_DONATION_FAILURE = "RESPOND_TO_DONATION_FAILURE";

export const GET_DONATIONS = "GET_DONATIONS";
export const GET_DONATIONS_SUCCESS = "GET_DONATIONS_SUCCESS";
export const GET_DONATIONS_FAILURE = "GET_DONATIONS_FAILURE";
