import styled from "styled-components";
import {
  AnalyticPaddingContainer,
  ColumnDisplay,
  GradientHeading,
  RowDisplay,
} from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  AnalyticPaddingContainer,
  ColumnDisplay,
  GradientHeading,
  LineSeparator,
  LinkText,
  RowDisplay,
  Separator,
  Table,
  TabSwitch,
  TabSwitchText,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPhone8.y;
export const MOBILE_BREAKPOINT = breakpoints.iPadPro.x;

export const DashboardPageWrapper = styled(ColumnDisplay)`
  flex: 1;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0px 150px 35px 150px;
  box-sizing: border-box;
  overflow-y: scroll;
  background: ${({ theme }) => theme.aliceBlue()};

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 0px 20px 85px 20px;
  }
`;

export const DashboardAnalyticContainer = styled(AnalyticPaddingContainer)`
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
`;

export const DashboardInnerContainer = styled(ColumnDisplay)`
  max-width: 1431px;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
`;

export const DashboardHeading = styled(GradientHeading)`
  margin: 0px;

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    font-size: 24px;
    word-break: break-word;
    text-align: start;
  }
`;

export const DashboardFormContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  display: block;
  width: 100%;
  border-radius: 8px;
  padding: 30px;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);
  min-width: 280px;

  color: ${({ theme }) => theme.gunmetal(0.75)};
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 20px;
  }
`;

export const ViewAllButton = styled(ColumnDisplay)`
  background: rgba(97, 133, 226, 0.2);
  color: ${({ theme }) => theme.charcoalGray()};
  width: 95px;
  height: 30px;
  padding: 0px 7px 0px 3px;
  border-radius: 38px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
  cursor: pointer;
`;

export const BorderedContainer = styled.div<{
  justifyContent?: string;
  maxWidth?: string;
  gap?: string;
}>`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.separatorGray()};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  flex: 1;
  max-width: ${({ maxWidth }) => maxWidth || "unset"};
  gap: ${({ gap }) => gap || "80px"};

  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};

  @media all and (max-width: ${breakpoints.iPhone5.y}px) {
    gap: 40px;
  }
`;

export const BorderedContainerTitle = styled.span`
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;

  color: ${({ theme }) => theme.gunmetal(0.8)};
`;

export const BorderedContainerParagraph = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;

  font-weight: 400;
  font-size: 15px;
  line-height: 17px;

  color: ${({ theme }) => theme.blackCoral()};
`;

export const CharityDonation = styled(RowDisplay)`
  padding-bottom: 5px;
  margin-top: 10px;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;

  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.grey(0.5)};
`;

export const CharityDonationTitle = styled(GradientHeading)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;

  margin: 0;

  @media all and (max-width: ${breakpoints.iPhone5.y}px) {
    font-size: 14px;
  }
`;

export const CharityDonationDollars = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;

  color: ${({ theme }) => theme.gunmetal()};

  @media all and (max-width: ${breakpoints.iPhone5.y}px) {
    font-size: 14px;
  }
`;

export const InterestsAndDonationsContainer = styled.div`
  background: ${({ theme }) => theme.white()};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  gap: 30px;
  border-radius: 8px;
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);

  @media all and (max-width: ${breakpoints.samsungGalaxyTab10.x}px) {
    flex-direction: column;
  }
`;

export const AdvancedAnalyticsContainer = styled.div`
  background: ${({ theme }) => theme.white()};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  gap: 30px;
  border-radius: 8px;
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);

  @media all and (max-width: ${breakpoints.samsungGalaxyTab10.x}px) {
    flex-direction: column;
  }
`;

export const AdvancedAnalyticsTitle = styled.span`
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;

  color: ${({ theme }) => theme.waikawaGrey()};

  @media all and (max-width: ${breakpoints.iPhone5.y}px) {
    margin-top: 30px;
    font-size: 14px;
  }
`;

export const GraphRowContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
`;

export const EmployeesNumberContainer = styled.div`
  max-width: 181px;
  border: 1px solid rgba(233, 235, 236, 1);
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 14px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0px 2px 15px 0px #0000000d;

  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: rgba(87, 96, 106, 1);
  font-size: 14px;
  line-height: 17px;
`;

export const EmployeesNumberText = styled.div`
  margin-top: 10px;
`;
