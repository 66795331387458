import { CalendarIcon } from "@primer/octicons-react";
import { ICustomInputProps } from "components/custom-search-input/custom.search-input";
import React from "react";
import { Controller } from "react-hook-form";
import colors from "theme/colors";
import { formatDate } from "utils/helper";

import * as S from "../gift-matching.styled";

const CustomDatePicker: React.FC<ICustomInputProps> = ({
  control,
  name,
  error,
  label,
  onChange,
  onFocus,
  onBlur,
}): JSX.Element => {
  const [isFocused, focus] = React.useState<boolean>(false);

  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange: formChange } }) => (
        <S.ColumnDisplay
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ position: "relative" }}
        >
          <S.DateInputFrame
            justifyContent="space-between"
            isInvalid={!!error}
            isFocused={isFocused}
            tabIndex={0}
            onBlur={() => {
              focus(false);
              onBlur?.();
            }}
            onClick={() => {
              ref?.current?.showPicker();
              focus(true);
              onFocus?.();
            }}
          >
            <S.RowDisplay gridGap={8}>
              <S.DateInputLabel>{label}</S.DateInputLabel>
              <S.DateFormatted>{value ? formatDate(value) : "MM/DD/YYY"}</S.DateFormatted>
            </S.RowDisplay>

            <CalendarIcon
              fill={!error?.length ? "rgba(178, 192, 227, 1)" : colors.bordo(0.2)}
              size={18}
            />
          </S.DateInputFrame>

          <S.TransparentInput
            ref={ref}
            type="date"
            value={value ?? ""}
            isHidden
            onChange={(e) => {
              formChange(e);
              onChange(e.target.value);
            }}
            onFocus={() => {
              focus(true);
              onFocus?.();
            }}
            onBlur={() => {
              focus(false);
              onBlur?.();
            }}
          />
        </S.ColumnDisplay>
      )}
    />
  );
};

export default CustomDatePicker;
