import { CalendarIcon, InfoIcon } from "@primer/octicons-react";
import { Tooltip } from "@primer/react";
import AnalyticProgressBar from "components/analytic-progress-bar";
import BarChart from "components/bar-chart.js";
import SmallButton from "components/buttons/small";
import ControlledSelect from "components/controlled-select";
import FixedHeader from "components/fixed-header";
import LogoAndCompany from "components/logo-and-company";
import PieChart from "components/pie-chart";
import { ChampIcon, EmployeesGreenIcon, EmployeesPurpleIcon, FundwurxLogoIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { getAdvancedAnalytics } from "store/advanced-analytics/advanced-analytics.action";
import advancedAnalyticsSelectors from "store/advanced-analytics/advanced-analytics.selector";
import {
  getAnalyticDashboardSummary,
  getCharitiesSupported,
  getDonationsAnalytics,
  getEmployeeInterests,
  getGiftMatchingAnalytics,
  getVolunteeringAnalytics,
} from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import colors from "theme/colors";
import { AdvancedAnalyticsGroup } from "types/company";
import breakpoints from "utils/breakpoints";
import {
  ADVANCED_ANALYTICS,
  AdvancedAnalyticsEmployeesText,
  AdvancedAnalyticsTitles,
} from "utils/content";
import { cutDecimals, genRandomString, getCurrYear } from "utils/helper";
import routes from "utils/routes";

import AnalyticDisplay from "../manage-people/components/analytic-display";
import * as S from "./dashboard.styled";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { year: yearParam } = params;
  const year = Number(yearParam) || getCurrYear();

  const companyName = useSelector(companySelectors.selectName);
  const dashboardSummary = useSelector(companySelectors.selectDashboardSummary);
  const employeeInterests = useSelector(companySelectors.selectEmployeeInterests) || [];
  const charitiesSupported = useSelector(companySelectors.selectCharitiesSupported);
  const donationAnalytics = useSelector(companySelectors.selectDonationAnalytics);
  const giftMatchingAnalytics = useSelector(companySelectors.selectGiftMatchingAnalytics);
  const volunteeringAnalytics = useSelector(companySelectors.selectVolunteeringAnalytics);
  const graphData = useSelector(advancedAnalyticsSelectors.selectGraphData);
  const finchKeyAdded = useSelector(companySelectors.selectFinchKeyAdded);

  const [selectedTab, setSelectedTab] = React.useState("Basic Analytics");
  const [selectedGraphType, setSelectedGraphType] = React.useState(ADVANCED_ANALYTICS[0].value);
  const [advancedAnalyticsTitle, setAdvancedAnalyticsTitle] = React.useState<string>(
    AdvancedAnalyticsTitles.DONATIONS,
  );
  const [advancedAnalyticsEmployeesText, setAdvancedAnalyticsEmployeesText] =
    React.useState<string>(AdvancedAnalyticsEmployeesText.DONATIONS);

  const [advancedAnalyticsEmployeesNumber, setAdvancedAnalyticsEmployeesNumber] =
    React.useState<number>(donationAnalytics?.totalEmployeesDonated);

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.iPadPro.x}px)` });

  const openCSRDialog = () => {
    dispatch(updateDialogState({ key: "CSR_YEAR_MODAL", isOpened: true }));
  };

  const fetchDonationsAdvancedAnalytics = (): void => {
    const props = {
      group: "donations" as AdvancedAnalyticsGroup,
      props: { year },
    };
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "generations" }));
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "locations" }));
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "timeAtCompany" }));
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "departments" }));
  };

  const fetchGiftMatchingAdvancedAnalytics = (): void => {
    const props = {
      group: "giftMatching" as AdvancedAnalyticsGroup,
      props: { year },
    };
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "generations" }));
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "locations" }));
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "timeAtCompany" }));
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "departments" }));
  };

  const fetchVolunteeringAdvancedAnalytics = (): void => {
    const props = {
      group: "volunteering" as AdvancedAnalyticsGroup,
      props: { year },
    };
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "generations" }));
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "locations" }));
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "timeAtCompany" }));
    dispatch(getAdvancedAnalytics.request({ ...props, analyticsType: "departments" }));
  };

  React.useEffect(() => {
    if (year) {
      dispatch(getAnalyticDashboardSummary.request({ year }));
      dispatch(getEmployeeInterests.request());
      dispatch(getCharitiesSupported.request({ year }));
      dispatch(getDonationsAnalytics.request({ year }));
      dispatch(getGiftMatchingAnalytics.request({ year }));
      dispatch(getVolunteeringAnalytics.request({ year }));

      // ADVANCED ANALYTICS INITIAL - DONATIONS
      fetchDonationsAdvancedAnalytics();
    }
  }, []);

  React.useEffect(() => {
    setAdvancedAnalyticsEmployeesNumber(donationAnalytics?.totalEmployeesDonated);
  }, [donationAnalytics?.totalEmployeesDonated]);

  const DASHBOARD_TABS = ["Basic Analytics", "Advanced Analytics"];

  const handleGeneratingGraphs = () => {
    if (selectedGraphType === ADVANCED_ANALYTICS[0].value) {
      // DONATIONS
      setAdvancedAnalyticsTitle(AdvancedAnalyticsTitles.DONATIONS);
      setAdvancedAnalyticsEmployeesText(AdvancedAnalyticsEmployeesText.DONATIONS);
      setAdvancedAnalyticsEmployeesNumber(donationAnalytics?.totalEmployeesDonated);
      fetchDonationsAdvancedAnalytics();
    } else if (selectedGraphType === ADVANCED_ANALYTICS[1].value) {
      // GIFT MATCHES
      setAdvancedAnalyticsTitle(AdvancedAnalyticsTitles.GIFT_MATCHES);
      setAdvancedAnalyticsEmployeesText(AdvancedAnalyticsEmployeesText.GIFT_MATCHES);
      setAdvancedAnalyticsEmployeesNumber(giftMatchingAnalytics.totalEmployeesMatched);
      fetchGiftMatchingAdvancedAnalytics();
    } else if (selectedGraphType === ADVANCED_ANALYTICS[2].value) {
      // VOLUNTEERING
      setAdvancedAnalyticsTitle(AdvancedAnalyticsTitles.VOLUNTEERING);
      setAdvancedAnalyticsEmployeesText(AdvancedAnalyticsEmployeesText.VOLUNTEERING);
      setAdvancedAnalyticsEmployeesNumber(volunteeringAnalytics.totalEmployeesVolunteered);
      fetchVolunteeringAdvancedAnalytics();
    }
  };

  return (
    <S.DashboardPageWrapper justifyContent="flex-start" gridGap={60}>
      <FixedHeader name={companyName} />
      <S.DashboardInnerContainer justifyContent="flex-start" alignItems="flex-start" gridGap={40}>
        <S.RowDisplay width="100%" justifyContent="space-between" gridGap={16} flexWrap>
          <S.DashboardHeading style={{ maxWidth: "100%" }}>Dashboard</S.DashboardHeading>
          <SmallButton
            label="Select a year to preview"
            onClick={openCSRDialog}
            leadingIcon={CalendarIcon}
            sx={{ borderRadius: 26, color: colors.unitedNationsBlue() }}
          />
        </S.RowDisplay>

        <S.DashboardFormContainer alignItems="flex-start" justifyContent="flex-start" gridGap={40}>
          IMPACT METRICS
          <S.DashboardAnalyticContainer>
            <AnalyticDisplay
              paragraph="Total Dollars Donated"
              color={colors.lightGreen()}
              number={`$${cutDecimals(dashboardSummary?.totalDollarsDonated)}`}
              infoText={
                "A grand total of the dollars donated\nby employees and matched by the company\nfor both on & off platform gift matches"
              }
            >
              <EmployeesGreenIcon />
            </AnalyticDisplay>
            <AnalyticDisplay
              paragraph="Total Volunteers Hours"
              color={colors.lightPurple()}
              number={dashboardSummary?.totalVolunteerHours}
              infoText={
                "A grand total of the accumulated hours\nemployees volunteered for based on \nthe number of employees who signed up, the\nduration of the event, and the number of events"
              }
            >
              <EmployeesPurpleIcon />
            </AnalyticDisplay>
            <AnalyticDisplay
              paragraph="Total Nonprofits Supported"
              number={dashboardSummary.totalSupportedCharities}
              infoText={
                "The number of unique nonprofits\nthe company/employees have supported\nfrom giving & volunteering\n(any past event with at least 1 employee signed up)"
              }
            >
              <FundwurxLogoIcon />
            </AnalyticDisplay>
          </S.DashboardAnalyticContainer>
        </S.DashboardFormContainer>

        {finchKeyAdded && (
          <S.RowDisplay
            width="100%"
            justifyContent="flex-start"
            flexWrap
            style={{ overflow: "auto" }}
          >
            {DASHBOARD_TABS.map((item, i) => (
              <S.TabSwitch
                key={genRandomString()}
                isSelected={item === selectedTab}
                isFirst={i === 0}
                isLast={i === DASHBOARD_TABS.length - 1}
                onClick={() => setSelectedTab(item)}
              >
                <S.TabSwitchText isSelected={item === selectedTab}>{item}</S.TabSwitchText>
              </S.TabSwitch>
            ))}
          </S.RowDisplay>
        )}

        <S.LineSeparator opacity={0.5} color="#8291AD" />

        {selectedTab === "Basic Analytics" && (
          <>
            <S.InterestsAndDonationsContainer>
              <S.BorderedContainer
                justifyContent="unset"
                maxWidth={isMobile ? "unset" : "520px"}
                gap="150px"
              >
                <S.BorderedContainerTitle>
                  Employee Interests
                  <Tooltip
                    sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                    text={
                      "Aggregated top interests from staff who\nhave added categories to their personal profile"
                    }
                  >
                    <InfoIcon size={20} />
                  </Tooltip>
                </S.BorderedContainerTitle>
                <S.ColumnDisplay alignItems="flex-start">
                  <S.BorderedContainerParagraph>
                    Top 10 Interests of employees
                  </S.BorderedContainerParagraph>
                  {!!employeeInterests?.length &&
                    employeeInterests?.map(({ interest, percent }) => (
                      <React.Fragment key={interest}>
                        <AnalyticProgressBar text={interest} percent={percent} />
                      </React.Fragment>
                    ))}
                </S.ColumnDisplay>
              </S.BorderedContainer>

              <S.BorderedContainer>
                <S.BorderedContainerTitle>
                  Nonprofits supported By Donations & Matches
                </S.BorderedContainerTitle>
                <AnalyticDisplay
                  paragraph="Total nonprofits that received funds from donations and matches"
                  number={charitiesSupported?.numCharitiesSupported}
                  fullWidth
                >
                  <ChampIcon />
                </AnalyticDisplay>
                <S.ColumnDisplay alignItems="flex-start">
                  <S.BorderedContainerParagraph>
                    Top 10 organizations who received funds
                    <Tooltip
                      sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                      text={
                        "Data below shows the top 10 organizations who received the most\nfunding from both employee donations and corporate matches if offered by company"
                      }
                    >
                      <InfoIcon size={14} />
                    </Tooltip>
                  </S.BorderedContainerParagraph>
                  {Object.entries(charitiesSupported?.leaderboard || {})?.map((item) => (
                    <S.CharityDonation key={`${item[0] + item[1]}`}>
                      <S.CharityDonationTitle>{item[0]}</S.CharityDonationTitle>
                      <S.CharityDonationDollars>${cutDecimals(item[1])}</S.CharityDonationDollars>
                    </S.CharityDonation>
                  ))}
                </S.ColumnDisplay>
              </S.BorderedContainer>
            </S.InterestsAndDonationsContainer>

            <S.DashboardFormContainer
              alignItems="flex-start"
              justifyContent="flex-start"
              gridGap={40}
            >
              DONATION ANALYTICS
              <S.DashboardAnalyticContainer>
                <AnalyticDisplay
                  paragraph="Total # of donations made"
                  number={donationAnalytics?.totalDonationsMade || 0}
                  fontWeight="700"
                  percent="View All"
                  onPercentClick={() => navigate(routes.COMPANY.ALL_DONATIONS)}
                />
                <AnalyticDisplay
                  paragraph="Total dollars donated by employees"
                  number={`$${cutDecimals(donationAnalytics?.totalDollarsDonated)}`}
                  fontWeight="700"
                />
                <AnalyticDisplay
                  paragraph="Average donation size"
                  number={`$${cutDecimals(donationAnalytics?.averageDonationSize)}`}
                  fontWeight="700"
                />
                <AnalyticDisplay
                  paragraph="Total # of employees who donated"
                  number={donationAnalytics?.totalEmployeesDonated || 0}
                  fontWeight="700"
                  percent={
                    donationAnalytics?.percentOfStaff &&
                    `${donationAnalytics?.percentOfStaff}% of staff`
                  }
                  percentTextColor={colors.green()}
                  percentBackgroundColor={colors.lightGreen(0.2)}
                />
              </S.DashboardAnalyticContainer>
            </S.DashboardFormContainer>

            {giftMatchingAnalytics?.totalDonationsMatched > 0 && (
              <S.DashboardFormContainer
                alignItems="flex-start"
                justifyContent="flex-start"
                gridGap={40}
              >
                GIFT MATCHING ANALYTICS
                <S.DashboardAnalyticContainer>
                  <AnalyticDisplay
                    paragraph="Total # of donations matched by company"
                    number={giftMatchingAnalytics?.totalDonationsMatched}
                    fontWeight="700"
                    percent={
                      giftMatchingAnalytics?.percentOnPlatform &&
                      `${giftMatchingAnalytics?.percentOnPlatform}% on platform`
                    }
                    percentTextColor={colors.green()}
                    percentBackgroundColor={colors.lightGreen(0.2)}
                    secondPercent={
                      giftMatchingAnalytics?.percentOffPlatform &&
                      `${giftMatchingAnalytics?.percentOffPlatform}% off platform`
                    }
                    secondPercentTextColor={colors.green()}
                    secondPercentBackgroundColor={colors.lightGreen(0.2)}
                    fullWidth
                    width="100%"
                    numberFontSize="28px"
                  />
                  <AnalyticDisplay
                    paragraph="Total dollars matched by company"
                    number={`$${cutDecimals(giftMatchingAnalytics?.totalDollarsMatched)}`}
                    fontWeight="700"
                    fullWidth
                    width="100%"
                    numberFontSize="28px"
                  />
                </S.DashboardAnalyticContainer>
              </S.DashboardFormContainer>
            )}

            {volunteeringAnalytics?.totalVolunteerEvents > 0 && (
              <S.DashboardFormContainer
                alignItems="flex-start"
                justifyContent="flex-start"
                gridGap={40}
              >
                VOLUNTEERING ANALYTICS
                <S.DashboardAnalyticContainer>
                  <AnalyticDisplay
                    paragraph="Total Volunteer Events"
                    number={volunteeringAnalytics?.totalVolunteerEvents}
                    fontWeight="700"
                    numberFontSize="28px"
                  />
                  <AnalyticDisplay
                    paragraph="Average Percentage of Spots Filled"
                    number={`${volunteeringAnalytics?.averagePercentageFilled}%`}
                    fontWeight="700"
                    numberFontSize="28px"
                    infoText={
                      "The average percent\nof possible spots that are filled\nfor a volunteer event"
                    }
                  />
                  <AnalyticDisplay
                    paragraph="Average Percentage Check-in Rate"
                    number={`${volunteeringAnalytics?.averagePercentageCheckIns}%`}
                    fontWeight="700"
                    numberFontSize="28px"
                    infoText={
                      "The average of percent\nof employees who\nchecked-in to an event\nusing the mobile\nQR code capability"
                    }
                  />
                  <AnalyticDisplay
                    paragraph="Total # of employees who volunteered"
                    number={volunteeringAnalytics?.totalEmployeesVolunteered}
                    fontWeight="700"
                    numberFontSize="28px"
                  />
                </S.DashboardAnalyticContainer>
              </S.DashboardFormContainer>
            )}
          </>
        )}

        {selectedTab === "Advanced Analytics" && (
          <S.AdvancedAnalyticsContainer>
            <S.RowDisplay justifyContent="flex-start" alignItems="center" gridGap={32} flexWrap>
              <ControlledSelect
                items={ADVANCED_ANALYTICS}
                label=""
                sx={{ width: "100%", maxWidth: "563px" }}
                size="small"
                name="role"
                onSelect={(value) => {
                  setSelectedGraphType(value);
                }}
              />
              <SmallButton
                label="Generate Graphs"
                onClick={handleGeneratingGraphs}
                sx={{
                  borderRadius: 26,
                  border: `1px solid ${colors.disabledGray()}`,
                  background: colors.ghostWhite(),
                  color: colors.unitedNationsBlue(),
                }}
              />
            </S.RowDisplay>
            <S.EmployeesNumberContainer>
              <S.GradientHeading>{advancedAnalyticsEmployeesNumber}</S.GradientHeading> <br />
              <S.EmployeesNumberText>{advancedAnalyticsEmployeesText}</S.EmployeesNumberText>
            </S.EmployeesNumberContainer>
            <S.AdvancedAnalyticsTitle>{advancedAnalyticsTitle}</S.AdvancedAnalyticsTitle>
            <S.GraphRowContainer>
              {!!graphData.departments && (
                <PieChart title="By Department" data={graphData.departments} />
              )}
              {!!graphData.locations && (
                <BarChart title="By Office Location" data={graphData.locations} />
              )}
              {!!graphData.timeAtCompany && (
                <BarChart title="By Time Worked at Company" data={graphData.timeAtCompany} />
              )}
              {!!graphData.generations && <BarChart title="By Age" data={graphData.generations} />}
            </S.GraphRowContainer>
          </S.AdvancedAnalyticsContainer>
        )}

        <S.LineSeparator opacity={0.5} color="#8291AD" />
      </S.DashboardInnerContainer>

      <S.ColumnDisplay width="100%" style={{ opacity: 0.5 }}>
        <LogoAndCompany companyName={companyName} showVersion={false} />
      </S.ColumnDisplay>
    </S.DashboardPageWrapper>
  );
};

export default DashboardPage;
