import { yupResolver } from "@hookform/resolvers/yup";
import { CheckCircleIcon } from "@primer/octicons-react";
import { Checkbox } from "@primer/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import { LogoOvalIcon } from "icons";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import charitySelectors from "store/charity/charity.selector";
import { clearClientSecret, createPaymentIntent } from "store/payment/payment.action";
import paymentSelectors from "store/payment/payment.selector";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import colors from "theme/colors";
import { FEE_PERCENTAGE, MAX_DONATION, MIN_DONATION, TWO_DECIMALS_REG } from "utils/constants";
import { genRandomString, roundNumber } from "utils/helper";
import * as yup from "yup";

import * as S from "./donate.styled";
import PaymentForm from "./payment-form";

const DONATION_AMOUNTS = [10, 20, 50, 75, 100, 150];
const DEFAULT_AMOUNT = DONATION_AMOUNTS[0];

const stripePromise = loadStripe(window.__RUNTIME_CONFIG__.STRIPE_PUBLISHABLE_KEY);

const schema = yup.object().shape({
  amount: yup
    .number()
    .required("Please enter desired donation amount.")
    .typeError("Please enter valid number.")
    .min(MIN_DONATION, "Minimum amount is 10$.")
    .max(MAX_DONATION, "Maximum amount is 10,000$.")
    .test(
      "is-decimal",
      "Please round donation to 2 decimals.",
      (value) => !!`${value}`.match(TWO_DECIMALS_REG),
    ),
  note: yup
    .string()
    .trim()
    .notRequired()
    .test("note", "Please enter between 3 and 50 characters.", (value) =>
      value
        ? yup
            .string()
            .min(3, "Please enter at least 3 characters.")
            .max(50, "Please enter 50 or less characters.")
            .isValidSync(value)
        : true,
    ),
});

const calculateExpensesAndFees = (value: number): number =>
  Math.round(((value + Number.EPSILON) / (1 - FEE_PERCENTAGE)) * 100) / 100;

const DonateDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const [selectedSlot, selectSlot] = React.useState<number>(DEFAULT_AMOUNT);
  const [feeAccepted, acceptFee] = React.useState<boolean>(false);
  const [fee, setFee] = React.useState<number>(
    Number(roundNumber(calculateExpensesAndFees(DEFAULT_AMOUNT))),
  );
  const [totalAmount, setTotalAmount] = React.useState<number>(DEFAULT_AMOUNT);

  const clientSecret = useSelector(paymentSelectors.selectClientSecret);
  const inProgress = useSelector(paymentSelectors.selectInProgress);

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, "DONATE"),
  );

  const charity = useSelector(charitySelectors.selectData);
  const charityName = charity?.name || "";

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: "DONATE", isOpened: false }));
  };

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
    control,
  } = useForm({
    defaultValues: { amount: selectedSlot, note: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  React.useEffect(() => {
    if (!isOpened) {
      clearErrors("amount");
      selectSlot(DEFAULT_AMOUNT);
      setValue("amount", DEFAULT_AMOUNT);
      setFee(Number(roundNumber(calculateExpensesAndFees(DEFAULT_AMOUNT) - DEFAULT_AMOUNT)));
      setTotalAmount(DEFAULT_AMOUNT);
      dispatch(clearClientSecret());
    }
  }, [isOpened]);

  const onDonate = ({ amount, note }): void => {
    if (amount) {
      dispatch(
        createPaymentIntent.request(
          note?.length > 0
            ? { charityId: charity?.id, amount: totalAmount, memo: note }
            : { charityId: charity?.id, amount: totalAmount },
        ),
      );
    }
  };

  const onProcessFee = () => {
    const isAccepted = !feeAccepted;
    acceptFee(isAccepted);
    if (isAccepted) {
      setTotalAmount(totalAmount + fee);
    } else {
      setTotalAmount(totalAmount - fee);
    }
  };

  const calculateDonations = (value: number): void => {
    if (value >= MIN_DONATION && value <= MAX_DONATION) {
      const fee_ = Number(roundNumber(calculateExpensesAndFees(value) - value));
      const totalAmount_ = fee_ + value;
      setFee(fee_);
      setTotalAmount(feeAccepted ? totalAmount_ : value);
    }
  };

  const onDonationInputChange = (val: string) => {
    if (!Number.isNaN(val)) {
      const value = Number(val);
      selectSlot(value);
      calculateDonations(value);
    }
  };

  const onDonateSlotClick = (amount: number): void => {
    setValue("amount", amount);
    selectSlot(amount);
    calculateDonations(amount);
    setTimeout(() => {
      clearErrors("amount");
    }, 50);
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper
        onClick={(e) => e.stopPropagation()}
        gridGap={0}
        justifyContent="flex-start"
      >
        <S.AbsoluteLogoWrapper>
          <LogoOvalIcon />
        </S.AbsoluteLogoWrapper>
        <S.DialogInnerWrapper padding={40} style={{ background: colors.aliceBlue() }}>
          {!clientSecret ? (
            <>
              <S.Separator height={60} />
              <S.DonateHeading>{`Donate to ${charityName}`}</S.DonateHeading>
              <S.Separator height={40} />
              <S.RowDisplay flexWrap gridGap={16}>
                {DONATION_AMOUNTS?.map((amount) =>
                  selectedSlot === amount ? (
                    <S.DonateSlotSelected key={genRandomString()}>
                      <CheckCircleIcon />
                      <S.Separator width={8} />
                      <S.RowDisplay height="100%">
                        <S.Dollar>$</S.Dollar>
                        <S.DonateSlotAmount>{amount}</S.DonateSlotAmount>
                      </S.RowDisplay>
                    </S.DonateSlotSelected>
                  ) : (
                    <S.DonateSlot key={genRandomString()} onClick={() => onDonateSlotClick(amount)}>
                      <S.Dollar>$</S.Dollar>
                      <S.DonateSlotAmount>{amount}</S.DonateSlotAmount>
                    </S.DonateSlot>
                  ),
                )}
              </S.RowDisplay>
              <S.Separator height={60} />
              <Input
                topLabel="Enter donation amount in US dollars"
                sx={{ width: "100%", maxWidth: "464px", alignSelf: "center" }}
                onEnter={handleSubmit(onDonate)}
                isRequired
                control={control}
                name="amount"
                onChange={onDonationInputChange}
                errorMessage={errors?.amount?.message}
              />
              <S.Separator height={20} />
              <S.RowDisplay
                gridGap={8}
                style={{ width: "100%", marginLeft: 16, opacity: fee > 0 ? 1 : 0 }}
                justifyContent="flex-start"
              >
                <Checkbox
                  checked={feeAccepted}
                  onChange={onProcessFee}
                  style={{ display: "flex" }}
                />
                <S.Paragraph
                  style={{ width: "calc(100% - 16px)", maxWidth: 310 }}
                  textAlign="start"
                >
                  I agree to cover <span style={{ fontWeight: "bold" }}>{`$${fee}`}</span> in
                  processing fees
                </S.Paragraph>
              </S.RowDisplay>
              <S.Separator height={40} />

              <Input
                topLabel="Note"
                sx={{ width: "100%", maxWidth: "464px", alignSelf: "center" }}
                onEnter={handleSubmit(onDonate)}
                control={control}
                name="note"
                errorMessage={errors?.note?.message}
              />

              <S.Separator height={30} />
              <PrimaryButton
                onClick={handleSubmit(onDonate)}
                label={`Donate $${totalAmount}`}
                isLoading={inProgress}
                sx={{ width: "100%", maxWidth: "464px" }}
              />
            </>
          ) : (
            <>
              <S.Separator height={20} />
              <S.DonateHeading>Make a secure donation</S.DonateHeading>
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentForm />
              </Elements>
            </>
          )}
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default DonateDialog;
