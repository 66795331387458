import styled from "styled-components";

export { ColumnDisplay, ErrorMessage, Paragraph, RowDisplay } from "theme/global-style";

export const SearchDropdownStyled = styled.div`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 8px 40px rgba(66, 74, 83, 0.35), 0px 1px 6px rgba(27, 31, 36, 0.12);
  border-radius: 22px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const SearchResult = styled.div`
  padding: 24px 38px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.gunmetal(0.2)};
  cursor: pointer;

  font-style: normal;
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.spaceCodet()};

  &:hover {
    background: ${({ theme }) => theme.ghostWhite()};
  }
`;
