import { TriangleDownIcon } from "@primer/octicons-react";
import { Text } from "@primer/react";
import { AvatarIcon, HamburgerIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import userSelectors from "store/user/user.selector";

import * as S from "./navigation-menu.style";
import * as T from "./navigation-menu.type";

const NavigationMenu: React.FC<T.INavigationMenuProps> = ({
  items,
  isAvatarDropdown,
  showName,
  width,
  isLeft,
}): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectData);

  const [isDropdownDisplayed, displayDropdown] = React.useState<boolean>(false);

  return (
    <S.NavigationMenuWrapper onBlur={() => displayDropdown(false)} tabIndex={0}>
      {isAvatarDropdown ? (
        <S.ClickableWrapper onClick={() => displayDropdown(!isDropdownDisplayed)}>
          <S.RowDisplay gridGap={8}>
            {showName && <Text>{user?.firstName ?? ""}</Text>}
            {user?.id ? (
              <S.InitialsWrapper>{`${(user?.firstName ?? "")[0]}${
                (user?.lastName ?? "")[0]
              }`}</S.InitialsWrapper>
            ) : (
              <AvatarIcon />
            )}
            <TriangleDownIcon />
          </S.RowDisplay>
        </S.ClickableWrapper>
      ) : (
        <S.ClickableWrapper onClick={() => displayDropdown(!isDropdownDisplayed)}>
          <HamburgerIcon />
        </S.ClickableWrapper>
      )}

      {isDropdownDisplayed && (
        <S.DropdownWrapper width={width} isLeft={isLeft}>
          <S.ColumnDisplay alignItems="flex-start" style={{ width: "100%" }}>
            {items.map((item) => (
              <S.DropdownItem
                key={item.id}
                onClick={() => item.onClick(dispatch)}
                gridGap={16}
                justifyContent="flex-start"
              >
                <div style={{ display: "flex" }}>{item.icon}</div>
                <div style={{ display: "flex" }}>{item.text}</div>
              </S.DropdownItem>
            ))}
          </S.ColumnDisplay>
        </S.DropdownWrapper>
      )}
    </S.NavigationMenuWrapper>
  );
};

export default NavigationMenu;
