import PrimaryButton from "components/buttons/primary";
import FixedHeader from "components/fixed-header";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { confirmCharityAdminRegistration } from "store/charity/charity.action";

import * as S from "./confirm-charity-admin.styled";

const ConfirmCharityAdminPage: React.FC<{}> = (): JSX.Element => {
  const { companyName } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const dispatch = useDispatch();

  const handleConfirmAccount = (): void => {
    dispatch(
      confirmCharityAdminRegistration.request({
        token,
      }),
    );
  };

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <FixedHeader name={companyName} />
      <S.FormWrapper>
        <S.GradientHeading fontSize={32}>Welcome to Fundwurx</S.GradientHeading>
        <S.Separator height={40} />
        <S.ConfirmCharityRegistrationParagraph fontSize={16}>
          Please confirm this invitation in order to verify your
          <br />
          account and email. Once confirmed, you&apos;ll gain access to
          <br />
          the platform.
        </S.ConfirmCharityRegistrationParagraph>

        <S.Separator height={20} />
        <PrimaryButton
          label="Confirm Account"
          onClick={handleConfirmAccount}
          sx={{ width: "100%", maxWidth: 391 }}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default ConfirmCharityAdminPage;
