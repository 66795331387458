import FixedHeader from "components/fixed-header";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import companySelectors from "store/company/company.selector";
import { DEFAULT_ADD_EVENT_CAL } from "utils/constants";

import * as S from "./calendar.styled";

const loadCal = () => {
  const e = document.createElement("script");
  e.type = "text/javascript";
  e.async = true;
  e.src = "https://cdn.addevent.com/libs/cal/js/cal.embed.t1.init.js";
  e.className = "ae-emd-script";
  document.getElementsByTagName("body")[0].appendChild(e);
};

const CalendarPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const companyName = useSelector(companySelectors.selectName);
  const companyCalendarId = useSelector(companySelectors.selectCalendar);

  const combinedCal = companyCalendarId
    ? `${companyCalendarId}-${DEFAULT_ADD_EVENT_CAL}`
    : DEFAULT_ADD_EVENT_CAL;
  const mainCal = companyCalendarId || DEFAULT_ADD_EVENT_CAL;

  loadCal();
  return (
    <S.CalendarPageWrapper justifyContent="flex-start" gridGap={60}>
      <FixedHeader name={companyName} />
      <S.CalendarInnerContainer justifyContent="flex-start" alignItems="flex-start" gridGap={40}>
        <div
          style={{ width: "100%" }}
          className="ae-emd-cal"
          data-calendar={mainCal}
          data-calendars={combinedCal}
          data-calendars-selected={combinedCal}
          data-configure="true"
          data-title=""
          data-title-show="true"
          data-today="true"
          data-datenav="true"
          data-date="true"
          data-monthweektoggle="true"
          data-subscribebtn="true"
          data-swimonth="true"
          data-swiweek="true"
          data-swischedule="true"
          data-print="true"
          data-timezone="true"
          data-defaultview="month"
          data-firstday="1"
          data-datetimeformat="1"
        />
      </S.CalendarInnerContainer>
    </S.CalendarPageWrapper>
  );
};

export default CalendarPage;
