import styled from "styled-components";
import colors from "theme/colors";
import { ColumnDisplay, GradientHeading } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  ColumnDisplay,
  GradientHeading,
  LinkText,
  Paragraph,
  RowDisplay,
  Separator,
  TagContainer,
  TagText,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const DialogBackground = styled(ColumnDisplay)`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 300px;
  z-index: 4;
  background: ${({ theme }) => theme.richBlack(0.3)};
`;

export const DialogScrollWrapper = styled(ColumnDisplay)`
  max-width: calc(100% - 2 * 24px);
  max-height: calc(100% - 2 * 24px);
  overflow-y: auto;
  display: unset;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const DialogInnerWrapper = styled(ColumnDisplay)<{ padding?: number; paddingTop?: number }>`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 4px 45px rgba(21, 41, 93, 0.1);
  border-radius: 40px;
  width: 573px;
  max-width: calc(100% - 2 * 8px);
  box-sizing: border-box;
  padding: ${({ padding = 91 }) => padding}px;
  padding-top: ${({ paddingTop = 20 }) => paddingTop}px;
  padding-bottom: 48px;
  display: flex;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 40px 22px;
  }
`;

export const DialogInnerWrapperWide = styled(ColumnDisplay)<{ width?: string; padding?: number }>`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 4px 45px rgba(21, 41, 93, 0.1);
  border-radius: 40px;
  width: ${({ width }) => width || "1000px"};
  max-width: calc(100% - 2 * 8px);
  box-sizing: border-box;
  padding: ${({ padding = 91 }) => padding}px;
  padding-bottom: 48px;
  display: flex;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 40px 22px;
  }
`;

export const DialogSubheading = styled.div<{
  marginTop?: number;
  marginBottom?: number;
  textOpacity?: number;
}>`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme, textOpacity }) => theme.gunmetal(textOpacity || 1)};
  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  margin-bottom: ${({ marginBottom = 0 }) => marginBottom}px;
`;

export const DialogSubheadingBold = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
  text-align: center;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const DialogLabel = styled.div<{ fontSize?: number }>`
  font-size: ${({ fontSize = 12 }) => fontSize}px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.quartz()};
`;

export const DialogLabelWarning = styled.div<{ fontSize?: number }>`
  font-size: ${({ fontSize = 12 }) => fontSize}px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.warningRed()};
`;

export const InterestContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
`;

export const DialogBlueSeparator = styled.div`
  background: ${({ theme }) => theme.unitedNationsBlue()};
  width: 100%;
  height: 4px;
  box-sizing: border-box;
`;

export const DialogHeading = styled(GradientHeading)`
  margin: 0px;
  padding: 0px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 24px;
  }
`;

export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  width: 100%;
`;

export const CloseIconButton = styled.button`
  background: ${({ theme }) => theme.white()};
  border-width: 0;
  border-color: ${({ theme }) => theme.white()};
  cursor: pointer;
`;

export const InnerContainer = styled.div`
  padding-left: 58px;
  padding-right: 58px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const BlueText = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${colors.unitedNationsBlue()};
`;
