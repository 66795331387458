import { cacheSlice } from "store/store.cache";

import { IPaymentState } from "./payment.type";

export const cachePayment = (initialState: IPaymentState, state: IPaymentState): IPaymentState => {
  const cachedState: IPaymentState = {
    ...initialState,
    donatedAmount: state.donatedAmount,
  };

  cacheSlice("payment", cachedState);

  return state;
};

export const clearPaymentCache = (initialState: IPaymentState): IPaymentState => {
  cacheSlice("payment", initialState);
  return initialState;
};
