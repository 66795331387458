import { ICompanyVolunteerEventsSelectors } from "./company-volunteer-events.type";

const companyVolunteerEventsSelectors: ICompanyVolunteerEventsSelectors = {
  selectData: (state) => state.companyVolunteerEvents.data,
  selectDto: (state) => state.companyVolunteerEvents.dto,
  selectInProgress: (state) => state.companyVolunteerEvents.inProgress,
  selectEndReached: (state) => state.companyVolunteerEvents.isEndReached,
  selectIsFetched: (state) => state.companyVolunteerEvents.isFetched,
  selectPage: (state) => state.companyVolunteerEvents.page,
  selectFilters: (state) => state.companyVolunteerEvents.filters,
  selectResults: (state) => state.companyVolunteerEvents.results,
  selectError: (state) => state.companyVolunteerEvents.error,
};

export default companyVolunteerEventsSelectors;
