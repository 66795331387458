import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import LogoAndCompany from "components/logo-and-company";
import NavigationProgressTracker from "components/navigation-progress-tracker";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";
import { confirmCharityRegistration } from "store/charity/charity.action";
import charitySelectors from "store/charity/charity.selector";
import colors from "theme/colors";
import { NAME_REG, TERMS_OF_SERVICE_LINK } from "utils/constants";
import { CHARITY_REGISTRATION_PAGES } from "utils/content";
import * as yup from "yup";

import * as S from "../charity-auth.styled";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .min(2, "First name must have at least 2 characters.")
    .matches(NAME_REG, "Please enter valid name.")
    .required("Please fill in all the data.")
    .max(50, "First name must have maximally 50 characters."),
  lastName: yup
    .string()
    .trim()
    .matches(NAME_REG, "Please enter valid last name.")
    .min(2, "Last name must have at least 2 characters.")
    .required("Please fill in all the data.")
    .max(50, "Last name must have maximally 50 characters."),
});

const VerifyIdentityPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const phoneRef = React.useRef(null);
  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);

  const inProgress = useSelector(charitySelectors.selectInProgress);

  const [tosAccepted, acceptTos] = React.useState<boolean>(false);
  const [claimAccepted, acceptClaim] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { firstName: "", lastName: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ firstName, lastName }) => {
    if (tosAccepted && claimAccepted) {
      dispatch(
        confirmCharityRegistration.request({
          firstName,
          lastName,
          token,
        }),
      );
    }
  };

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <NavigationProgressTracker items={CHARITY_REGISTRATION_PAGES} activeItemIndex={2} />
      <S.FormWrapper>
        <S.GradientHeading>Personal Information</S.GradientHeading>
        <S.Separator height={32} />
        <S.Paragraph color={colors.gunmetal()}>
          We’ve successfully validated your email! Please enter {isTablet && <br />} some basic data
          about yourself.
        </S.Paragraph>
        <S.Separator height={40} />
        <Input
          topLabel="First Name"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          ref={firstNameRef}
          onEnter={() => lastNameRef?.current?.focus()}
          errorMessage={errors?.firstName?.message}
          name="firstName"
        />
        <S.Separator height={30} />
        <Input
          topLabel="Last Name"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          ref={lastNameRef}
          onEnter={() => phoneRef?.current?.focus()}
          errorMessage={errors?.lastName?.message}
          name="lastName"
        />

        <S.Separator height={30} />
        <S.ColumnDisplay
          gridGap={32}
          style={{
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <S.RowDisplay gridGap={8} style={{ width: "100%" }} justifyContent="flex-start">
            <Checkbox
              checked={tosAccepted}
              onChange={() => acceptTos(!tosAccepted)}
              style={{ display: "flex" }}
            />
            <S.Paragraph style={{ width: "calc(100% - 16px)", maxWidth: 310 }} textAlign="start">
              I accept the Fundwurx{" "}
              <span>
                <S.TermsOfServiceLink href={TERMS_OF_SERVICE_LINK} target="_blank">
                  terms of service
                </S.TermsOfServiceLink>{" "}
              </span>
            </S.Paragraph>
          </S.RowDisplay>

          <S.RowDisplay
            gridGap={8}
            style={{ width: "100%" }}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Checkbox
              checked={claimAccepted}
              onChange={() => acceptClaim(!claimAccepted)}
              style={{ display: "flex" }}
            />
            <S.Paragraph style={{ width: "100%" }} textAlign="start">
              I hereby certify, under penalty of perjury, that I am an authorized representative of
              the non-profit and the information I am providing, including bank account information,
              is true and correct to the best of my knowledge.
            </S.Paragraph>
          </S.RowDisplay>
        </S.ColumnDisplay>

        <S.Separator height={56} />
        <PrimaryButton
          label="Finish"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!tosAccepted || !claimAccepted}
          sx={{ width: "100%", minWidth: "100%" }}
          isLoading={inProgress}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default VerifyIdentityPage;
