import moment from "moment";
import { ErrorCode, IAppError } from "types/error";
import { VolunteerEvent } from "types/volunteer";

import { DATE_FORMAT, SUPPORT_EMAIL, TIME_FORMAT } from "./constants";

export const genRandomString = (): string =>
  (Math.random() + 1).toString(36).slice(0, 9) + (Math.random() + 1).toString(36).slice(0, 9);

export const capitalizeFirstLetter = (text: string): string =>
  (text ?? "").charAt(0).toUpperCase() + (text ?? "").slice(1);

export const camelCaseText = (text: string): string =>
  (text ?? "")
    .toLowerCase()
    .split(" ")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");

export const removeProperty = <T>(obj: T, key: string): T => {
  Object.entries(obj).forEach((value) => {
    const [i, _] = value;
    if (!obj?.[i]) return;
    if (typeof obj[i] === "object") {
      removeProperty(obj[i], key);
    } else if (i.includes(key)) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });
  return obj;
};

export const uriEncode = (uri: string): string =>
  uri ? uri.toLowerCase().replaceAll(" ", "%20").replaceAll("://", "%3A%2F%2F") : "";

export const uriDecode = (uri: string): string =>
  uri ? uri.toLowerCase().replaceAll("%20", " ") : "";

export function genUrlParams(baseUrl: string, obj: any) {
  const query = Object.entries(obj)
    .filter(
      ([_, value]) =>
        value !== null && value !== undefined && !(Array.isArray(value) && value.length === 0),
    )
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
          .join("&");
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value as string | number)}`;
    })
    .join("&");

  return query ? `${baseUrl}?${query}` : baseUrl;
}

export const constrainText = (text: string, limit: number): string =>
  text.trim().length <= limit + 3 ? text : `${text.trim().slice(0, limit)}...`;

export const getErrorCode = (error: IAppError): ErrorCode => error?.response?.data?.error?.code;

export const getErrorMessage = (error: IAppError): string => error?.response?.data?.error?.message;

export const contactSupport = (e: any): void => {
  e.preventDefault();
  window.location.href = `mailto:${SUPPORT_EMAIL}`;
};

export const normalizeLink = (link: string): string =>
  (link ?? "").startsWith("http") ? link : `https://${link}`;

export const formatDate = (date: string, format: string = DATE_FORMAT): string =>
  moment(date).format(format);

export const formatTime = (date: string, format: string = TIME_FORMAT): string =>
  moment(date).format(format);

export const verifyImg = (imgSrc: string, callback: (val: boolean) => void): void => {
  const image = new Image();
  image.onload = () => {
    if (image.width > 0) {
      callback(true);
    }
  };
  image.onerror = () => {
    callback(false);
  };
  image.src = imgSrc;
};

export const roundNumber = (val: number): string => Number((val ?? 0).toFixed(2)).toLocaleString();

export const cutDecimals = (val: number): string => Math.trunc(Number(val ?? 0)).toLocaleString();

export const isString = (val: string | number): boolean => typeof val === "string";

export const genListFromInterval = (start: number, end: number, step: number = 1): number[] => {
  const arr = [];
  for (let n = start; n <= end; n += step) {
    arr.push(n);
  }
  return arr;
};

export const getCurrYear = (): number => moment().year();

export const getLocationString = (volunteerEvent: VolunteerEvent) => {
  const locations = volunteerEvent.location || [];
  const hasVirtual = locations.includes("VIRTUAL");
  const hasCompanyOffice = locations.includes("COMPANY_OFFICE");

  if (hasVirtual && hasCompanyOffice) {
    return "This event takes place at the company offices or it's a virtual event.";
  }
  if (hasVirtual) {
    return "This event is online only.";
  }
  if (hasCompanyOffice) {
    return "This event takes place at the company offices.";
  }
  return volunteerEvent?.address || "";
};
