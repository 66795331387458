import { cacheSlice } from "store/store.cache";

import { IGiftMatchState } from "./gift-matching.type";

export const cacheGiftMatch = (
  initialState: IGiftMatchState,
  state: IGiftMatchState,
): IGiftMatchState => {
  const cachedState: IGiftMatchState = {
    ...initialState,
    isCached: true,
    data: state.data,
  };

  cacheSlice("giftMatch", cachedState);

  return {
    ...state,
    isCached: true,
  };
};

export const cleariftMatchCache = (initialState: IGiftMatchState): IGiftMatchState => {
  cacheSlice("giftMatch", initialState);
  return initialState;
};
