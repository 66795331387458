export const TOGGLE_GIFT_MATCH = "TOGGLE_GIFT_MATCH";
export const TOGGLE_GIFT_MATCH_SUCCESS = "TOGGLE_GIFT_MATCH_SUCCESS";
export const TOGGLE_GIFT_MATCH_FAILURE = "TOGGLE_GIFT_MATCH_FAILURE";

export const GET_COMPANY_GIFT_MATCH = "GET_COMPANY_GIFT_MATCHING";
export const GET_COMPANY_GIFT_MATCH_SUCCESS = "GET_COMPANY_GIFT_MATCHING_SUCCESS";
export const GET_COMPANY_GIFT_MATCH_FAILURE = "GET_COMPANY_GIFT_MATCHING_FAILURE";

export const SET_COMPANY_GIFT_MATCH = "SET_COMPANY_GIFT_MATCHING";
export const SET_COMPANY_GIFT_MATCH_SUCCESS = "SET_COMPANY_GIFT_MATCHING_SUCCESS";
export const SET_COMPANY_GIFT_MATCH_FAILURE = "SET_COMPANY_GIFT_MATCHING_FAILURE";

export const GET_W9_FORM_LINK = "GET_W9_FORM_LINK";
export const GET_W9_FORM_LINK_SUCCESS = "GET_W9_FORM_LINK_SUCCESS";
export const GET_W9_FORM_LINK_FAILURE = "GET_W9_FORM_LINK_FAILURE";

export const SUBMIT_OFF_PLATFORM_DONATION = "SUBMIT_OFF_PLATFORM_DONATION";
export const SUBMIT_OFF_PLATFORM_DONATION_SUCCESS = "SUBMIT_OFF_PLATFORM_DONATION_SUCCESS";
export const SUBMIT_OFF_PLATFORM_DONATION_FAILURE = "SUBMIT_OFF_PLATFORM_DONATION_FAILURE";

export const GET_TAX_DOCUMENT = "GET_TAX_DOCUMENT";
export const GET_TAX_DOCUMENT_SUCCESS = "GET_TAX_DOCUMENT_SUCCESS";
export const GET_TAX_DOCUMENT_FAILURE = "GET_TAX_DOCUMENT_FAILURE";
