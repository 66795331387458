import { Text } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import colors from "theme/colors";
import routes from "utils/routes";

import * as S from "./e-mail-validation.styled";

const EmailSent: React.FC<{}> = (): JSX.Element => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <div />
      <S.FormWrapper>
        <S.RowDisplay>
          <S.GradientHeading>Email sent!</S.GradientHeading>
        </S.RowDisplay>

        <S.Separator height={40} />

        <Text sx={{ textAlign: "center", fontSize: 16, color: colors.gunmetal() }}>
          An email has been sent. Please follow the link {!isTablet ? <br /> : <>&nbsp;</>}
          in the email.
        </Text>

        <S.Separator height={40} />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default EmailSent;
