import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, Radio, RadioGroup } from "@primer/react";
import SecondaryButton from "components/buttons/secondary";
import ControlledSelect from "components/controlled-select";
import FilePicker from "components/file-picker";
import Input from "components/input";
import { XIcon } from "icons";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import {
  editVolunteerEvent,
  postVolunteerEvent,
  resetVolunteerEventDto,
  saveVolunteerEventDto,
} from "store/volunteer-events-charity/volunteer-events-charity.action";
import volunteerSelectors from "store/volunteer-events-charity/volunteer-events-charity.selector";
import colors from "theme/colors";
import { VolunteerEventDto } from "types/volunteer";
import { MAX_ALLOWED_FILE_SIZE } from "utils/constants";
import { VOLUNTEER_NUMBER, VOLUNTEER_TIME_FLEXIBILITY } from "utils/content";
import { POST_CHARITY_SCHEMA_STEP2 } from "utils/validation";

import * as S from "../dialogs.styled";

const CONFITMATION_MODAL_KEY = "CLOSE_POST_CHARITY_EVENT";
const STEP_1_MODAL_KEY = "POST_CHARITY_EVENT_STEP_1";
const MODAL_KEY = "POST_CHARITY_EVENT_STEP_2";
const buttonProps = { width: "184px", height: "50px" };
const defaultValues = { volunteersNeeded: "", eventTime: "", impact: "", contactEmail: "" };

const PostVolunteerEventStep2Dialog: React.FC = () => {
  const dispatch = useDispatch();
  const [isWeiverRequired, setIsWaiverRequired] = React.useState<boolean>(false);
  const [waiver, setWaiver] = React.useState<File>(null);
  const [submitted, submit] = React.useState<boolean>(false);

  const impactRef = React.useRef<HTMLInputElement>(null);
  const emailRef = React.useRef<HTMLInputElement>(null);

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, MODAL_KEY),
  );
  const properties = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogProperties(state, "POST_CHARITY_EVENT_STEP_2"),
  );

  const editData = properties?.editData;

  const inProgress = useSelector(volunteerSelectors.selectInProgress);

  const prevOpenedDialog = useSelector(uiComponentsSelectors.selectPreviouslyOpenedDialog);

  const storedData = useSelector(volunteerSelectors.selectDto);

  const persistData =
    (prevOpenedDialog === CONFITMATION_MODAL_KEY || prevOpenedDialog === STEP_1_MODAL_KEY) &&
    !!storedData &&
    storedData.volunteersNeeded;

  const persistedData = persistData
    ? {
        volunteersNeeded: storedData.volunteersNeeded,
        eventTime: storedData?.eventTime,
        impact: storedData?.impactMetric,
        contactEmail: storedData?.contactEmail,
      }
    : defaultValues;

  const {
    control,
    handleSubmit,
    clearErrors,
    getValues,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: prevOpenedDialog === CONFITMATION_MODAL_KEY ? persistedData : defaultValues,
    resolver: yupResolver(POST_CHARITY_SCHEMA_STEP2),
    mode: "onTouched",
  });

  const resetFields = (): void => {
    setWaiver(null);
    const fieldsToReset: Array<"volunteersNeeded" | "eventTime" | "impact" | "contactEmail"> = [
      "volunteersNeeded",
      "eventTime",
      "impact",
      "contactEmail",
    ];
    fieldsToReset.forEach((field) => resetField(field));
    fieldsToReset.forEach((field) => clearErrors(field));
    setIsWaiverRequired(false);
    setWaiver(null);
    submit(false);
  };

  React.useEffect(() => {
    if (isOpened && !persistData) {
      resetFields();
    } else if (persistData) {
      setIsWaiverRequired(!!waiver);
    }
  }, [isOpened]);

  React.useEffect(() => {
    if (isOpened && !!editData) {
      setValue("volunteersNeeded", editData.volunteersNeeded);
      setValue("eventTime", editData.eventTime);
      setValue("impact", editData.impactMetric || "");
      setValue("contactEmail", editData.contactEmail || "");
      setIsWaiverRequired(!!editData.waiver);
      setWaiver(editData.waiverUrl);
    }
  }, [editData, isOpened]);

  const closeDialog = (): void => {
    const dto: Partial<VolunteerEventDto> = {
      volunteersNeeded: getValues("volunteersNeeded"),
      eventTime: getValues("eventTime"),
      impactMetric: getValues("impact"),
      contactEmail: getValues("contactEmail"),
      waiver: isWeiverRequired ? waiver : null,
    };
    dispatch(saveVolunteerEventDto(dto));
    dispatch(updateDialogState({ key: MODAL_KEY, isOpened: false }));
    dispatch(updateDialogState({ key: "CLOSE_POST_CHARITY_EVENT", isOpened: true }));
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsWaiverRequired(event.target.value === "yes");
    if (event.target.value === "no") {
      setWaiver(null);
    }
  };

  const handleBack = (): void => {
    dispatch(updateDialogState({ key: "POST_CHARITY_EVENT_STEP_2", isOpened: false }));
    dispatch(updateDialogState({ key: "POST_CHARITY_EVENT_STEP_1", isOpened: true }));
  };

  const onSubmit = ({ volunteersNeeded, eventTime, impact, contactEmail }): void => {
    submit(true);
    if ((isWeiverRequired && waiver) || !isWeiverRequired) {
      const dto: Partial<VolunteerEventDto> = {
        volunteersNeeded,
        eventTime,
        impactMetric: impact,
        contactEmail,
        waiver: isWeiverRequired ? waiver : null,
      };
      if (editData) {
        dispatch(editVolunteerEvent.request({ ...dto, id: editData.id }));
      } else {
        dispatch(postVolunteerEvent.request(dto));
      }

      dispatch(resetVolunteerEventDto());
      setTimeout(() => resetFields(), 3000);
    }
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20}>
          <S.CloseIconContainer>
            <S.CloseIconButton type="button" onClick={closeDialog}>
              <XIcon />
            </S.CloseIconButton>
          </S.CloseIconContainer>
          <S.GradientHeading fontSize={28}>
            {editData ? "Edit" : "Post"} a Group Volunteering
            <br />
            Day Request
          </S.GradientHeading>
          <S.Separator height={40} />
          <S.ColumnDisplay
            gridGap={30}
            style={{ maxWidth: "395px", width: "100%", boxSizing: "border-box" }}
          >
            <ControlledSelect
              items={VOLUNTEER_NUMBER}
              control={control}
              label="# of volunteers needed?"
              sx={{ width: "100%", minWidth: "100%" }}
              name="volunteersNeeded"
              required
              placeholder="Select a number"
              errorMessage={errors?.volunteersNeeded?.message}
            />
            <ControlledSelect
              items={VOLUNTEER_TIME_FLEXIBILITY}
              control={control}
              label="When is the event?"
              sx={{ width: "100%", minWidth: "100%" }}
              name="eventTime"
              required
              placeholder="Select option"
              errorMessage={errors?.eventTime?.message}
            />
            <Input
              ref={impactRef}
              name="impact"
              topLabel="What is the impact of this event?"
              placeholder="1 box of food packed feeds 5 people"
              control={control}
              sx={{ width: "100%", alignSelf: "center" }}
              onEnter={handleSubmit(onSubmit)}
              isOptionalVisible={false}
              errorMessage={errors?.impact?.message}
              tooltip={
                "Please enter a quantifiable metric\n to best measure the impact of the event.\n E.g. 1 workshop helps 10 students"
              }
            />
            <Input
              ref={emailRef}
              name="contactEmail"
              topLabel="Email of Volunteer Coordinator"
              placeholder="Enter best email for volunteer inquiries"
              control={control}
              sx={{ width: "100%", alignSelf: "center" }}
              onEnter={handleSubmit(onSubmit)}
              isOptionalVisible={false}
              errorMessage={errors?.contactEmail?.message}
            />
            <div style={{ width: "100%" }}>
              <RadioGroup name="RequireAWaiver" sx={{ display: "flex", alignItems: "flex-start" }}>
                <RadioGroup.Label
                  sx={{
                    fontSize: 15,
                    color: colors.gunmetal(),
                    marginBottom: "20px",
                    fontWeight: "600",
                  }}
                >
                  Does this event require a waiver?
                  <span style={{ color: colors.bordo() }}>{" *"}</span>
                </RadioGroup.Label>
                <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                  <FormControl>
                    <Radio
                      value="yes"
                      sx={{ borderColor: colors.lightSilver() }}
                      checked={isWeiverRequired}
                      onChange={handleRadioChange}
                    />
                    <FormControl.Label>Yes</FormControl.Label>
                  </FormControl>
                  <FormControl>
                    <Radio
                      value="no"
                      sx={{ borderColor: colors.lightSilver() }}
                      checked={!isWeiverRequired}
                      onChange={handleRadioChange}
                    />
                    <FormControl.Label>No</FormControl.Label>
                  </FormControl>
                </div>
              </RadioGroup>
            </div>
            <FilePicker
              file={waiver}
              setFile={setWaiver}
              buttonLabel="Upload a waiver"
              supportedTypes="application/pdf"
              pickerLabel="No file chosen."
              uploadedUrl={editData?.waiverUrl || undefined}
              isErrorMode={submitted && !waiver && isWeiverRequired}
              maxFileSize={MAX_ALLOWED_FILE_SIZE}
              isDisabled={!isWeiverRequired}
            />
          </S.ColumnDisplay>
          <S.Separator height={50} />
          <S.RowDisplay gridGap={16}>
            <SecondaryButton label="Back" sx={buttonProps} onClick={handleBack} />
            <SecondaryButton
              label="Finish"
              sx={{ ...buttonProps, background: colors.unitedNationsBlue(), color: colors.white() }}
              onClick={handleSubmit(onSubmit)}
              isLoading={inProgress}
            />
          </S.RowDisplay>
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default PostVolunteerEventStep2Dialog;
