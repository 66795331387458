import CharityHomePage from "pages/charity/home";
import CheckInPage from "pages/check-in";
import CheckInSuccessPage from "pages/check-in-success";
import NotFoundPage from "pages/not-found";
import VolunteerEventDetailsPage from "pages/volunteer/details";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "utils/routes";

interface ICharityAdminNavigator {
  routesToHide: string[];
}

const CharityAdminNavigator: React.FC<ICharityAdminNavigator> = ({ routesToHide }): JSX.Element => (
  <Routes>
    <Route path={routes.CHARITY.HOME} element={<CharityHomePage />} />
    <Route path={routes.NOT_FOUND} element={<NotFoundPage />} />
    <Route path={`${routes.VOLUNTEER.EVENT}/:id`} element={<VolunteerEventDetailsPage />} />

    <Route path={routes.CHARITY.LOGIN} element={<Navigate to={routes.HOME} />} />
    <Route path={routes.OTHER} element={<Navigate to={routes.HOME} />} />
    <Route path={routes.CHECK_IN} element={<CheckInPage />} />
    <Route path={routes.CHECK_IN_SUCCESS} element={<CheckInSuccessPage />} />
  </Routes>
);

export default CharityAdminNavigator;
