import * as C from "./advanced-analytics.constants";
import * as T from "./advanced-analytics.type";

export const getAdvancedAnalytics = {
  request: (payload?: T.IGetAdvancedAnalytics["payload"]): T.IGetAdvancedAnalytics => ({
    type: C.GET_ADVANCED_ANALYTICS,
    payload,
  }),
  success: (
    payload: T.IGetAdvancedAnalyticsSuccess["payload"],
  ): T.IGetAdvancedAnalyticsSuccess => ({
    type: C.GET_ADVANCED_ANALYTICS_SUCCESS,
    payload,
  }),
  failure: (): T.IGetAdvancedAnalyticsFailure => ({
    type: C.GET_ADVANCED_ANALYTICS_FAILURE,
  }),
};

export const saveSelectedGraphData = (
  payload: T.ISelectAdvancedAnalytics["payload"],
): T.ISelectAdvancedAnalytics => ({
  payload,
  type: C.SELECT_ADVANCED_ANALYTICS,
});
