import { ReactComponent as A } from "assets/icons/a.svg";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowDownGradient } from "assets/icons/arrow-down-gradient.svg";
import { ReactComponent as Avatar } from "assets/icons/avatar.svg";
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import { ReactComponent as CalendarCheck } from "assets/icons/calendar-check.svg";
import { ReactComponent as CalendarMenu } from "assets/icons/calendar-menu.svg";
import { ReactComponent as Champ } from "assets/icons/champ.svg";
import { ReactComponent as Charity } from "assets/icons/charity-icon.svg";
import { ReactComponent as CheckInVawyCircle } from "assets/icons/check-vawy-circle.svg";
import { ReactComponent as DateSort } from "assets/icons/date-sort.svg";
import { ReactComponent as Donations } from "assets/icons/donations.svg";
import { ReactComponent as EmployeesGreen } from "assets/icons/employees-green.svg";
import { ReactComponent as EmployeesPurple } from "assets/icons/employees-purple.svg";
import { ReactComponent as EmptyInbox } from "assets/icons/empty-inbox.svg";
import { ReactComponent as FourSquares } from "assets/icons/four-squares.svg";
import { ReactComponent as FundwurxLogoBlue } from "assets/icons/fundwurx-logo-blue.svg";
import { ReactComponent as Hamburger } from "assets/icons/hamburger.svg";
import { ReactComponent as HamburgerWhite } from "assets/icons/hamburger-white.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as LogoMocked } from "assets/icons/logo-mocked.svg";
import { ReactComponent as LogoOval } from "assets/icons/logo-oval.svg";
import { ReactComponent as LogoWhite } from "assets/icons/logo-white.svg";
import { ReactComponent as DonoutGraph } from "assets/icons/mocked/dount.svg";
import { ReactComponent as DonoutLegend } from "assets/icons/mocked/dount-legend.svg";
import { ReactComponent as PillarGraph } from "assets/icons/mocked/pillar.svg";
import { ReactComponent as PillarLegend } from "assets/icons/mocked/pillar-legend.svg";
import { ReactComponent as PapersMatch } from "assets/icons/papers-match.svg";
import { ReactComponent as People } from "assets/icons/people.svg";
import { ReactComponent as QR } from "assets/icons/qr.svg";
import { ReactComponent as Volunteering } from "assets/icons/volunteering.svg";
import { ReactComponent as X } from "assets/icons/x.svg";
import { ReactComponent as Z } from "assets/icons/z.svg";
import React from "react";

type IconProps = React.SVGProps<SVGSVGElement>;

export const LogoIcon = (props: IconProps): JSX.Element => <Logo {...props} />;

export const LogoOvalIcon = (props: IconProps): JSX.Element => <LogoOval {...props} />;

export const AvatarIcon = (props: IconProps): JSX.Element => <Avatar {...props} />;

export const FourSquaresIcon = (props: IconProps): JSX.Element => <FourSquares {...props} />;

export const PapersMatchIcon = (props: IconProps): JSX.Element => <PapersMatch {...props} />;

export const HamburgerIcon = (props: IconProps): JSX.Element => <Hamburger {...props} />;

export const HamburgerWhiteIcon = (props: IconProps): JSX.Element => <HamburgerWhite {...props} />;

export const CalendarIcon = (props: IconProps): JSX.Element => <Calendar {...props} />;

export const CalendarCheckIcon = (props: IconProps): JSX.Element => <CalendarCheck {...props} />;

export const CharityIcon = (props: IconProps): JSX.Element => <Charity {...props} />;

export const CheckInVawyCircleIcon = (props: IconProps): JSX.Element => (
  <CheckInVawyCircle {...props} />
);

export const ArrowDownGradientIcon = (props: IconProps): JSX.Element => (
  <ArrowDownGradient {...props} />
);

export const EmptyInboxIcon = (props: IconProps): JSX.Element => <EmptyInbox {...props} />;

export const DateSortIcon = (props: IconProps): JSX.Element => <DateSort {...props} />;

export const AIcon = (props: IconProps): JSX.Element => <A {...props} />;

export const ZIcon = (props: IconProps): JSX.Element => <Z {...props} />;

export const LogoMockedIcon = (props: IconProps): JSX.Element => <LogoMocked {...props} />;

export const LogoWhiteIcon = (props: IconProps): JSX.Element => <LogoWhite {...props} />;

export const DonutGraphMockedIcon = (props: IconProps): JSX.Element => <DonoutGraph {...props} />;

export const PillarGraphMockedIcon = (props: IconProps): JSX.Element => <PillarGraph {...props} />;

export const DonutLegendMockedIcon = (props: IconProps): JSX.Element => <DonoutLegend {...props} />;

export const PillarLegendMockedIcon = (props: IconProps): JSX.Element => (
  <PillarLegend {...props} />
);

export const XIcon = (props: IconProps): JSX.Element => <X {...props} />;

export const DonationsIcon = (props: IconProps): JSX.Element => <Donations {...props} />;

export const VolunteeringIcon = (props: IconProps): JSX.Element => <Volunteering {...props} />;

export const PeopleMenuIcon = (props: IconProps): JSX.Element => <People {...props} />;

export const CalendarMenuIcon = (props: IconProps): JSX.Element => <CalendarMenu {...props} />;

export const QRIcon = (props: IconProps): JSX.Element => <QR {...props} />;

export const ArrowDownIcon = (props: IconProps): JSX.Element => <ArrowDown {...props} />;

export const EmployeesGreenIcon = (props: IconProps): JSX.Element => <EmployeesGreen {...props} />;

export const EmployeesPurpleIcon = (props: IconProps): JSX.Element => (
  <EmployeesPurple {...props} />
);

export const FundwurxLogoIcon = (props: IconProps): JSX.Element => <FundwurxLogoBlue {...props} />;

export const ChampIcon = (props: IconProps): JSX.Element => <Champ {...props} />;
