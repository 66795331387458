import { cacheSlice } from "store/store.cache";

import { ICompanyState } from "./company.type";

export const cacheComapny = (initialState: ICompanyState, state: ICompanyState): ICompanyState => {
  const cachedState: ICompanyState = {
    ...initialState,
    isCached: true,
    data: state.data,
    summary: state.summary,
  };

  cacheSlice("company", cachedState);

  return {
    ...state,
    isCached: true,
  };
};

export const clearComapnyCache = (initialState: ICompanyState): ICompanyState => {
  cacheSlice("company", initialState);
  return initialState;
};
