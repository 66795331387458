import * as api from "api/every";
import { combineEpics, ofType } from "redux-observable";
import CustomHistory from "router/history";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { hideFullScreenLoader } from "store/ui-components/ui-components.actions";
import routes from "utils/routes";

import { getEveryOrgCharities, getEveryOrgCharityDetails } from "./every-org.action";
import * as C from "./every-org.constants";
import * as T from "./every-org.type";

const getEveryOrgCharitiesEpic: AppEpic = (action$: Observable<T.IGetEveryOrgCharities>) =>
  action$.pipe(
    ofType(C.GET_EVERY_ORG_CHARITIES),
    mergeMap((action) =>
      from(api.getEveryCharities(action.payload)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(
              getEveryOrgCharities.success({
                page: action.payload.page,
                charities: response.data.charities,
              }),
            );
          }
          return of(getEveryOrgCharities.failure());
        }),
        catchError((error) => of(getEveryOrgCharities.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getEveryOrgCharityDetailsEpic: AppEpic = (
  action$: Observable<T.IGetEveryOrgCharityDetails>,
) =>
  action$.pipe(
    ofType(C.GET_EVERY_ORG_CHARITY_DETAILS),
    mergeMap((action) =>
      from(api.getEveryCharityDetails(action.payload.slug)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(getEveryOrgCharityDetails.success(response.data), hideFullScreenLoader());
          }
          return of(getEveryOrgCharityDetails.failure(), hideFullScreenLoader());
        }),
        catchError((error) => {
          CustomHistory.navigate(routes.NOT_FOUND);
          hideFullScreenLoader();

          return of(getEveryOrgCharityDetails.failure(), handleError({ action, error }));
        }),
      ),
    ),
  );

export default combineEpics(getEveryOrgCharitiesEpic, getEveryOrgCharityDetailsEpic);
