export const UPDATE_DIALOG_STATE = "UPDATE_MODAL_STATE";

export const RESET_DIALOG_STATE_HISTORY = "RESET_DIALOG_STATE_HISTORY";

export const UPDATE_TOAST_STATE = "UPDATE_TOAST_STATE";

export const UPDATE_LOADER_STATE = "UPDATE_LOADER_STATE";

export const UPDATE_DROPDOWN_STATE = "UPDATE_DROPDOWN_STATE";

export const UPDATE_INPUT_STATE = "UPDATE_INPUT_STATE";

export const UPDATE_CHECKBOX_STATE = "UPDATE_CHECKBOX_STATE";
