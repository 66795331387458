export const FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS = "FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS";
export const FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS_SUCCESS =
  "FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS_SUCCESS";
export const FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS_FAILURE =
  "FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS_FAILURE";

export const REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS = "REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS";
export const REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS_SUCCESS =
  "REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS_SUCCESS";
export const REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS_FAILURE =
  "REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS_FAILURE";

export const CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT = "CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT";
export const CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT_SUCCESS =
  "CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT_SUCCESS";
export const CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT_FAILURE =
  "CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT_FAILURE";

export const ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT = "ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT";
export const ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT_SUCCESS =
  "ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT_SUCCESS";
export const ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT_FAILURE =
  "ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT_FAILURE";
