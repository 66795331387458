import { TableIcon } from "@primer/octicons-react";
import { Pagination } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import authSelectors from "store/auth/auth.selector";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import { MAX_TABLE_COLUMNS } from "utils/constants";
import { VOLUNTEER_EVENT_ATTENDEES_HEADINGS } from "utils/content";
import { genRandomString } from "utils/helper";
import routes from "utils/routes";

import * as S from "../volunteer-event-attendees.styled";
import { IVolunteerEventAttendeesTableProps } from "../volunteer-event-attendees.type";

const VolunteerEventAttendeesTable: React.FC<IVolunteerEventAttendeesTableProps> = ({
  eventId,
  eventStatus,
  title,
  items,
  totalItemsNum,
  maxItemsNum,
  displayPagination,
  currentPage,
}): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });
  const role = useSelector(authSelectors.selectRole);

  const renderFilter = (): JSX.Element => (
    <S.SearchAndFilterContainer>
      <S.SearchAndFilterInnerContainer justifyContent="space-between" gridGap={0} flexWrap>
        <S.RowDisplay>
          <S.TotalUsersText>{`Total Signups: ${totalItemsNum}/${maxItemsNum}`}</S.TotalUsersText>
        </S.RowDisplay>
        {totalItemsNum > 0 && eventStatus === "ACTIVE" && role === "admin" && (
          <PrimaryButton
            sx={{ height: 40, width: 213 }}
            label="Message Employees"
            onClick={() =>
              dispatch(
                updateDialogState({
                  key: "MESSAGE_EMPLOYEES",
                  isOpened: true,
                  properties: { id: eventId },
                }),
              )
            }
          />
        )}
        {/* <PrimaryButton sx={{ height: 40, width: 138 }} label="Export List" onClick={() => {}} /> */}
      </S.SearchAndFilterInnerContainer>
    </S.SearchAndFilterContainer>
  );

  return (
    <S.ColumnDisplay>
      <S.RowDisplay
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%" }}
        flexWrap
      >
        <S.GradientHeadingExtended>{title}</S.GradientHeadingExtended>
      </S.RowDisplay>
      <S.Separator height={40} />
      {totalItemsNum > 0 ? (
        <S.ColumnDisplay alignItems="flex-start" justifyContent="flex-start">
          {renderFilter()}
          {isTablet ? (
            <S.ColumnDisplay gridGap={0}>
              {items.map((user) => (
                <S.Table.Wrapper key={genRandomString()}>
                  <S.Table.Body>
                    <S.Table.Row>
                      <S.Table.Heading style={{ textAlign: "center" }} key={genRandomString()}>
                        {VOLUNTEER_EVENT_ATTENDEES_HEADINGS[0]}
                      </S.Table.Heading>
                      <S.Table.Data
                        isCentered
                        hideBorder={!displayPagination}
                        isFirstElem
                        showTopBorder
                      >
                        {user?.captain && "⭐"} {user?.firstName || "Not Provided"}
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading
                        style={{ textAlign: "center" }}
                        key={genRandomString()}
                        hideRadius
                      >
                        {VOLUNTEER_EVENT_ATTENDEES_HEADINGS[1]}
                      </S.Table.Heading>
                      <S.Table.Data isCentered hideBorder={displayPagination}>
                        {user?.lastName || "Not Provided"}
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading
                        style={{ textAlign: "center" }}
                        key={genRandomString()}
                        hideRadius
                      >
                        {VOLUNTEER_EVENT_ATTENDEES_HEADINGS[2]}
                      </S.Table.Heading>
                      <S.Table.Data isCentered hideBorder={displayPagination}>
                        {user?.email || "Not Provided"}
                      </S.Table.Data>
                    </S.Table.Row>
                  </S.Table.Body>
                </S.Table.Wrapper>
              ))}
            </S.ColumnDisplay>
          ) : (
            <S.Table.Wrapper>
              <S.Table.Body>
                <S.Table.Row>
                  {VOLUNTEER_EVENT_ATTENDEES_HEADINGS.map((heading) => (
                    <S.Table.Heading style={{ textAlign: "center" }} key={genRandomString()}>
                      {heading}
                    </S.Table.Heading>
                  ))}
                </S.Table.Row>

                {items.map((user) => (
                  <S.Table.Row key={genRandomString()}>
                    <S.Table.Data hideBorder={displayPagination}>
                      {user?.captain && "⭐"} {user?.firstName || "Not Provided"}
                    </S.Table.Data>
                    <S.Table.Data>{user?.lastName || "Not Provided"}</S.Table.Data>
                    <S.Table.Data>{user?.email || "Not Provided"}</S.Table.Data>
                  </S.Table.Row>
                ))}
              </S.Table.Body>
            </S.Table.Wrapper>
          )}

          {displayPagination && (
            <S.PaginationWrapper>
              <Pagination
                pageCount={Math.ceil(totalItemsNum / MAX_TABLE_COLUMNS)}
                currentPage={currentPage}
                onPageChange={(e, n) => {
                  e.preventDefault();
                  navigate(`${routes.VOLUNTEER.EVENT_ATTENDEES}/${eventId}/${n}`);
                }}
              />
            </S.PaginationWrapper>
          )}
        </S.ColumnDisplay>
      ) : (
        <>
          {renderFilter()}
          <S.NoDataForm style={{ height: 150, minHeight: 150 }}>
            <S.NoDataText gridGap={16}>
              <TableIcon size={48} />
              No data to display.
            </S.NoDataText>
          </S.NoDataForm>
        </>
      )}
    </S.ColumnDisplay>
  );
};

export default VolunteerEventAttendeesTable;
