import SecondaryButton from "components/buttons/secondary";
import FixedHeader from "components/fixed-header";
import LogoAndCompany from "components/logo-and-company";
import { ArrowDownGradientIcon, CheckInVawyCircleIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { matchEveryDonation } from "store/donations/donations.action";
import donationsSelectors from "store/donations/donations.selector";
import { getCompanyGiftMatch } from "store/gift-matching/gift-matching.action";
import giftMatchSelectors from "store/gift-matching/gift-matching.selector";
import routes from "utils/routes";

import * as S from "./donation-success.styled";

const EveryDotOrgDonationSucceedPage: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, companyName } = useParams();

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const giftMatchInfo = useSelector(giftMatchSelectors.selectToggleState);
  const isGiftMatchFetched = useSelector(giftMatchSelectors.selectIsFetched);
  const inProgress = useSelector(donationsSelectors.selectInProgress);

  React.useEffect(() => {
    if (!isGiftMatchFetched) {
      dispatch(getCompanyGiftMatch.request());
    }
  }, [isGiftMatchFetched]);

  const onDonationMatch = (): void => {
    dispatch(matchEveryDonation.request({ companyName, body: { partnerDonationId: id } }));
  };

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <FixedHeader name={companyName} />
      <S.FormWrapper>
        <S.RowDisplay gridGap={16}>
          <CheckInVawyCircleIcon />
          <S.DonationsCompleteText>Donation Complete</S.DonationsCompleteText>
        </S.RowDisplay>

        <S.Separator height={20} />
        <S.DonationsCompleteParagraph>
          Your donation from every.org has been successfully processed.
        </S.DonationsCompleteParagraph>

        {giftMatchInfo ? (
          <>
            <S.Separator height={20} />
            <ArrowDownGradientIcon />
            <S.Separator height={40} />

            <S.GradientHeading>Would you like to submit a gift match request?</S.GradientHeading>
            <S.Separator height={40} />
            <S.RowDisplay gridGap={24}>
              <SecondaryButton
                label="Yes"
                onClick={onDonationMatch}
                sx={{ width: isTablet ? "100px" : "184px" }}
                isLoading={inProgress}
              />
              <SecondaryButton
                label="No"
                onClick={() => navigate(routes.HOME)}
                sx={{ width: isTablet ? "100px" : "184px" }}
                isLoading={inProgress}
              />
            </S.RowDisplay>
            <S.Separator height={40} />
            <S.DonationsCompleteParagraph fontSize={12}>
              Disclaimer: Your employer has the right to decline your request and will evaluate your
              request under their terms and conditions.
            </S.DonationsCompleteParagraph>

            <S.Separator height={20} />
          </>
        ) : (
          <>
            <S.Separator height={40} />
            <SecondaryButton
              label="Return to Homepage"
              onClick={() => navigate(routes.HOME)}
              sx={{ width: isTablet ? "100px" : "184px" }}
              isLoading={inProgress}
            />
          </>
        )}
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default EveryDotOrgDonationSucceedPage;
