import CharityDetailsPage from "pages/charity/details";
import FindCharitiesPage from "pages/charity/find";
import CheckInPage from "pages/check-in";
import CheckInSuccessPage from "pages/check-in-success";
import EmployeeDashboard from "pages/employee/dasboard";
import EveryDotOrgBrowseCharities from "pages/everydotorg/browse";
import EveryDotOrgCategoriesPage from "pages/everydotorg/categories";
import EveryOrgCharityDetailsPage from "pages/everydotorg/charity-view";
import EveryDotOrgDonationSucceedPage from "pages/everydotorg/donation-success";
import EveryDotOrgSearchCharities from "pages/everydotorg/search";
import HomePage from "pages/home";
import NotFoundPage from "pages/not-found";
import DonationSucceedPage from "pages/payment/payment-success";
import VolunteerEventDetailsPage from "pages/volunteer/details";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "utils/routes";

interface IEmployeeNavigator {
  routesToHide: string[];
}

const EmployeeNavigator: React.FC<IEmployeeNavigator> = ({ routesToHide }): JSX.Element => (
  <Routes>
    <Route path={routes.HOME} element={<HomePage />} />
    {!routesToHide?.includes(routes.FIND_CHARITIES) && (
      <Route path={routes.FIND_CHARITIES} element={<FindCharitiesPage isAdminView={false} />} />
    )}
    <Route path={`${routes.CHARITY.DETAILS}/:charityId`} element={<CharityDetailsPage />} />
    <Route path={routes.DONATION.SUCCESS} element={<DonationSucceedPage />} />
    <Route path={routes.EMPLOYEE.DASHBOARD} element={<EmployeeDashboard />} />
    <Route path={routes.EVERY_DOT_ORG.CATEGORIES} element={<EveryDotOrgCategoriesPage />} />
    <Route
      path={`${routes.EVERY_DOT_ORG.BROWSE}/:category/:subcategory`}
      element={<EveryDotOrgBrowseCharities />}
    />
    <Route
      path={`${routes.EVERY_DOT_ORG.CHARITY_DETAILS}/:slug`}
      element={<EveryOrgCharityDetailsPage />}
    />
    <Route
      path={`${routes.EVERY_DOT_ORG.SEARCH}/:searchTerm`}
      element={<EveryDotOrgSearchCharities />}
    />
    <Route path={`${routes.VOLUNTEER.EVENT}/:id`} element={<VolunteerEventDetailsPage />} />
    <Route
      path={`${routes.EVERY_DOT_ORG.DONATION_SUCCESS}/:companyName/:id`}
      element={<EveryDotOrgDonationSucceedPage />}
    />
    <Route path={routes.CHECK_IN} element={<CheckInPage />} />
    <Route path={routes.CHECK_IN_SUCCESS} element={<CheckInSuccessPage />} />

    <Route path={routes.NOT_FOUND} element={<NotFoundPage />} />
    <Route path={routes.OTHER} element={<Navigate to={routes.HOME} />} />
  </Routes>
);

export default EmployeeNavigator;
