import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import * as C from "./company-donations.constants";
import * as T from "./company-donations.type";

export const companyDonationsInitial: T.ICompanyDonationsState = {
  data: [],
  page: 1,
  results: 0,
  isEndReached: false,
  isFetched: false,
  inProgress: false,
  error: null,
};

const companyDonationsReducer = (
  state: T.ICompanyDonationsState,
  action: T.CompanyDonationsAction | ILogout,
): T.ICompanyDonationsState => {
  if (!state) {
    return companyDonationsInitial;
  }

  switch (action.type) {
    case C.GET_COMPANY_DONATIONS:
      return {
        ...state,
        page: action.payload.page,
        inProgress: true,
      };
    case C.GET_COMPANY_DONATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.donations,
        results: action.payload.results,
        page: action.payload.page,
        isEndReached: action.payload.results === action.payload?.donations?.length,
        inProgress: false,
        isFetched: true,
      };
    case C.GET_COMPANY_DONATIONS_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case LOGOUT:
      return companyDonationsInitial;
    default:
      return state;
  }
};

export default companyDonationsReducer;
