import { TabExternalIcon } from "@primer/octicons-react";
import { Pagination } from "@primer/react";
import GiftMatchStatusText from "components/gift-match-status";
import React from "react";
import { useMediaQuery } from "react-responsive";
import colors from "theme/colors";
import { UserDonation } from "types/user-donation";
import { MAX_TABLE_COLUMNS } from "utils/constants";
import { GIFT_MATCHING_ACTIVITY_TABLE_HEADING } from "utils/content";
import { formatDate, genRandomString } from "utils/helper";

import * as S from "../dashboard.styled";

interface IRecentActivityTable {
  items: UserDonation[];
  totalItemsNum: number;
  displayPagination: boolean;
  currentPage: number;
  onLoadMore: (n: number) => void;
}

const RecentActivityTable: React.FC<IRecentActivityTable> = ({
  items,
  totalItemsNum,
  displayPagination,
  currentPage,
  onLoadMore,
}): JSX.Element => {
  const tableHeadingRef = React.useRef<HTMLTableRowElement>(null);

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  React.useEffect(() => {
    if (tableHeadingRef?.current) {
      tableHeadingRef?.current?.scrollIntoView({ block: "center", inline: "start" });
    }
  }, [currentPage]);

  return totalItemsNum > 0 ? (
    <S.ColumnDisplay alignItems="flex-start" justifyContent="flex-start">
      {isTablet ? (
        <S.ColumnDisplay gridGap={16}>
          {items.map((donation) => (
            <S.Table.Wrapper key={genRandomString()}>
              <S.Table.Body>
                <S.Table.Row ref={tableHeadingRef}>
                  <S.Table.Heading key={genRandomString()}>
                    {GIFT_MATCHING_ACTIVITY_TABLE_HEADING[0]}
                  </S.Table.Heading>
                  <S.Table.Data
                    hideBorder={items.length >= MAX_TABLE_COLUMNS}
                    isFirstElem
                    showTopBorder
                  >
                    {donation?.charityName}
                  </S.Table.Data>
                </S.Table.Row>

                <S.Table.Row>
                  <S.Table.Heading key={genRandomString()} hideRadius>
                    {GIFT_MATCHING_ACTIVITY_TABLE_HEADING[1]}
                  </S.Table.Heading>
                  <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                    {formatDate(donation?.date)}
                  </S.Table.Data>
                </S.Table.Row>

                <S.Table.Row>
                  <S.Table.Heading key={genRandomString()} hideRadius>
                    {GIFT_MATCHING_ACTIVITY_TABLE_HEADING[2]}
                  </S.Table.Heading>
                  <S.Table.Data
                    hideBorder={items.length >= MAX_TABLE_COLUMNS}
                    style={{ textAlign: "center" }}
                  >
                    {`$${donation?.amount?.toLocaleString()}`}
                  </S.Table.Data>
                </S.Table.Row>

                <S.Table.Row>
                  <S.Table.Heading key={genRandomString()} hideRadius>
                    {GIFT_MATCHING_ACTIVITY_TABLE_HEADING[3]}
                  </S.Table.Heading>
                  <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                    <S.RowDisplay justifyContent="flex-start" gridGap={6}>
                      <TabExternalIcon fill={colors.unitedNationsBlue()} />
                      <S.LinkText>View Receipt</S.LinkText>
                    </S.RowDisplay>
                  </S.Table.Data>
                </S.Table.Row>

                <S.Table.Row>
                  <S.Table.Heading key={genRandomString()} hideRadius>
                    {GIFT_MATCHING_ACTIVITY_TABLE_HEADING[4]}
                  </S.Table.Heading>
                  <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                    <GiftMatchStatusText
                      donation={{ ...donation, status: donation.giftMatchDonationStatus }}
                      isTablet={isTablet}
                      isAdminView={false}
                    />
                  </S.Table.Data>
                </S.Table.Row>
              </S.Table.Body>
            </S.Table.Wrapper>
          ))}
        </S.ColumnDisplay>
      ) : (
        <S.Table.Wrapper>
          <S.Table.Body>
            <S.Table.Row ref={tableHeadingRef}>
              {GIFT_MATCHING_ACTIVITY_TABLE_HEADING.map((item, i) => (
                <S.Table.Heading
                  key={genRandomString()}
                  style={{ textAlign: i === 0 ? "left" : "center" }}
                >
                  {item}
                </S.Table.Heading>
              ))}
            </S.Table.Row>

            {items.map((donation) => (
              <S.Table.Row key={genRandomString()}>
                <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                  {donation?.charityName}
                </S.Table.Data>
                <S.Table.Data>{formatDate(donation?.date)}</S.Table.Data>
                <S.Table.Data
                  style={{ textAlign: "right" }}
                >{`$${donation?.amount?.toLocaleString()}`}</S.Table.Data>
                <S.Table.Data>
                  <S.RowDisplay justifyContent="center" gridGap={6}>
                    <TabExternalIcon fill={colors.unitedNationsBlue()} />
                    <S.LinkText href={donation?.receiptUrl}>View Receipt</S.LinkText>
                  </S.RowDisplay>
                </S.Table.Data>
                <S.Table.Data hideBorder={items.length >= MAX_TABLE_COLUMNS}>
                  <S.RowDisplay justifyContent="center">
                    <GiftMatchStatusText
                      donation={{ ...donation, status: donation.giftMatchDonationStatus }}
                      isTablet={isTablet}
                      isAdminView={false}
                    />
                  </S.RowDisplay>
                </S.Table.Data>
              </S.Table.Row>
            ))}
          </S.Table.Body>
        </S.Table.Wrapper>
      )}

      {displayPagination && (
        <S.PaginationWrapper hideBorder>
          <Pagination
            pageCount={Math.ceil(totalItemsNum / MAX_TABLE_COLUMNS)}
            currentPage={currentPage}
            showPages={!isTablet}
            onPageChange={(e, n) => {
              e.preventDefault();
              if (n !== currentPage) {
                onLoadMore(n);
              }
            }}
          />
        </S.PaginationWrapper>
      )}
    </S.ColumnDisplay>
  ) : (
    <S.ColumnDisplay alignItems="flex-start" justifyContent="flex-start" style={{ width: "100%" }}>
      <S.RowDisplay
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%" }}
        flexWrap
      />
    </S.ColumnDisplay>
  );
};

export default RecentActivityTable;
