import * as api from "api/gift-match";
import { redirectToReceipt, redirectToW9 } from "api/gift-match";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import companySelectors from "store/company/company.selector";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { showToast, updateDialogState } from "store/ui-components/ui-components.actions";
import { ACCEPTED } from "utils/status-code";

import {
  getCompanyGiftMatch,
  getW9FormLink,
  setCompanyGiftMatch,
  submitOffPlatformDonation,
  toggleGiftMatch,
} from "./gift-matching.action";
import * as C from "./gift-matching.constants";
import giftMatchSelectors from "./gift-matching.selector";
import * as T from "./gift-matching.type";

const toggleGiftMatchEpic: AppEpic = (action$: Observable<T.IToggleGiftMatchRequest>, state$) =>
  action$.pipe(
    ofType(C.TOGGLE_GIFT_MATCH),
    mergeMap((action) => {
      const companyName = companySelectors.selectName(state$?.value);
      const isActive = giftMatchSelectors.selectToggleState(state$?.value);
      return from(api.toggleGiftMatch(companyName, isActive)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(toggleGiftMatch.success());
          }
          return of(toggleGiftMatch.failure());
        }),
        catchError((error) => of(toggleGiftMatch.failure(), handleError({ action, error }))),
      );
    }),
  );

const getCompanyGiftMatchEpic: AppEpic = (
  action$: Observable<T.IGetCompanyGiftMatchRequest>,
  state$,
) =>
  action$.pipe(
    ofType(C.GET_COMPANY_GIFT_MATCH),
    mergeMap((action) => {
      const companyName = companySelectors.selectName(state$?.value);
      return from(api.getCompanyGiftMatch(companyName)).pipe(
        mergeMap((response) => {
          if (response) {
            return of(getCompanyGiftMatch.success(response.data.data));
          }
          return of(getCompanyGiftMatch.failure());
        }),
        catchError((error) => of(getCompanyGiftMatch.failure(), handleError({ action, error }))),
      );
    }),
  );

const setCompanyGiftMatchEpic: AppEpic = (
  action$: Observable<T.ISetCompanyGiftMatchRequest>,
  state$,
) =>
  action$.pipe(
    ofType(C.SET_COMPANY_GIFT_MATCH),
    mergeMap((action) => {
      const companyName = companySelectors.selectName(state$?.value);
      return from(api.setCompanyGiftMatch(companyName, action.payload)).pipe(
        mergeMap((response) => {
          if (response === 200) {
            return of(setCompanyGiftMatch.success(action.payload));
          }
          return of(setCompanyGiftMatch.failure());
        }),
        catchError((error) => of(setCompanyGiftMatch.failure(), handleError({ action, error }))),
      );
    }),
  );

const getW9FormLinkEpic: AppEpic = (action$: Observable<T.IGetW9FormLinkRequest>) =>
  action$.pipe(
    ofType(C.GET_W9_FORM_LINK),
    mergeMap((action) =>
      from(redirectToW9(action.payload)).pipe(
        mergeMap((response) => {
          if (response.data) {
            window.open(response.data, "_blank");
            return of(getW9FormLink.success(response.data));
          }
          return of(getW9FormLink.failure());
        }),
        catchError((error) => of(getW9FormLink.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getTaxDocumentEpic: AppEpic = (action$: Observable<T.IGetTaxDocument>) =>
  action$.pipe(
    ofType(C.GET_TAX_DOCUMENT),
    mergeMap((action) =>
      from(redirectToReceipt(action.payload.donationId)).pipe(
        mergeMap((response) => {
          if (response.data) {
            window.open(response.data, "_blank");
            return of(getW9FormLink.success(response.data));
          }
          return of(getW9FormLink.failure());
        }),
        catchError((error) => of(getW9FormLink.failure(), handleError({ action, error }))),
      ),
    ),
  );

const submitOffPlatformDonationEpic: AppEpic = (
  action$: Observable<T.ISubmitOffPlatformDonation>,
) =>
  action$.pipe(
    ofType(C.SUBMIT_OFF_PLATFORM_DONATION),
    mergeMap((action) => {
      const formData = new FormData();
      formData.append("amount", action.payload.amount?.toString());
      formData.append("ein", action.payload.ein);
      formData.append("receipt", action.payload.receipt);
      return from(api.submitOffPlatformDonation(formData)).pipe(
        mergeMap((response) => {
          if (response === ACCEPTED) {
            return of(
              submitOffPlatformDonation.success(),
              updateDialogState({ key: "OFF_PLATFORM_DONATION", isOpened: false }),
              showToast({ message: "Donation submitted for gift match", type: "success" }),
            );
          }
          return of(
            submitOffPlatformDonation.failure(),
            showToast({ type: "failure", message: "Something went wrong." }),
            updateDialogState({ key: "OFF_PLATFORM_DONATION", isOpened: false }),
          );
        }),
        catchError((error) =>
          of(submitOffPlatformDonation.failure(), handleError({ action, error })),
        ),
      );
    }),
  );

export default combineEpics(
  toggleGiftMatchEpic,
  getCompanyGiftMatchEpic,
  setCompanyGiftMatchEpic,
  getW9FormLinkEpic,
  submitOffPlatformDonationEpic,
  getTaxDocumentEpic,
);
