import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { MAX_TABLE_COLUMNS } from "utils/constants";

import { clearDonationsCache } from "./donations.cache";
import * as C from "./donations.constants";
import * as T from "./donations.type";

const initialTable: T.IDonationsState["pending"] = {
  data: [],
  page: 1,
  isFetched: false,
  isEndReached: false,
  inProgress: false,
  results: 0,
  q: null,
  startDate: null,
  endDate: null,
  sortBy: null,
  order: null,
};

export const donationsInitial: T.IDonationsState = {
  inProgress: false,
  isCached: false,
  isFetched: false,
  error: null,
  pending: initialTable,
  pendingOffPlatform: initialTable,
  fulfilled: initialTable,
};

const donationsReducer = (
  state: T.IDonationsState,
  action: T.DonationsAction | ILogout,
): T.IDonationsState => {
  if (!state) {
    return donationsInitial;
  }

  switch (action.type) {
    case C.MATCH_DONATION:
    case C.RESPOND_TO_DONATION:
    case C.MATCH_EVERY_DONATION:
      return {
        ...state,
        inProgress: true,
      };
    case C.GET_DONATIONS:
      return {
        ...state,
        [action.payload.table]: {
          ...state[action.payload.table],
          inProgress: true,
          page: action.payload.page,
        },
      };
    case C.GET_DONATIONS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        [action.payload.table]: {
          ...state[action.payload.table],
          inProgress: false,
          data: action.payload.response.donations,
          results: action.payload.response.results,
          isEndReached:
            action.payload.response.donations.length +
              MAX_TABLE_COLUMNS * (state.pending.page - 1) ===
            action.payload.response.results,
          isFetched: true,
        },
      };
    case C.GET_DONATIONS_FAILURE:
      return {
        ...state,
        [action.payload.table]: {
          ...state[action.payload.table],
          inProgress: false,
        },
      };
    case C.RESPOND_TO_DONATION_SUCCESS: {
      const manualDonation = state.pendingOffPlatform.data.find(
        (item) => item.id === action.payload.id,
      );
      const giftMatch =
        state.pending.data.find((item) => item.id === action.payload.id) ?? manualDonation;
      return {
        ...state,
        inProgress: false,
        pendingOffPlatform: {
          ...state.pendingOffPlatform,
          data: state.pendingOffPlatform.data.filter((item) => item.id !== action.payload.id),
          results: state.pendingOffPlatform.results - 1,
        },
        pending: {
          ...state.pending,
          data: state.pending.data.filter((item) => item.id !== action.payload.id),
          results: state.pending.results - 1,
        },
        fulfilled: {
          ...state.fulfilled,
          results: state.fulfilled.results + 1,
          data: [
            {
              ...giftMatch,
              status: action.payload.approved
                ? manualDonation
                  ? "FULFILLED_MANUALLY"
                  : "PROCESSING"
                : "DECLINED",
            },
            ...state.fulfilled.data,
          ],
        },
      };
    }
    case C.MATCH_EVERY_DONATION_SUCCESS:
    case C.MATCH_EVERY_DONATION_FAILURE:
    case C.RESPOND_TO_DONATION_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case LOGOUT:
      return clearDonationsCache(donationsInitial);
    default:
      return state;
  }
};

export default donationsReducer;
