import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowLeftIcon } from "@primer/octicons-react";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { resendVerificationEmail, sendCodeEmployee } from "store/auth/auth.action";
import authSelectors from "store/auth/auth.selector";
import AuthFormTemplate from "templates/auth-form";
import { EMAIL_REG } from "utils/constants";
import routes from "utils/routes";
import * as yup from "yup";

import * as S from "./employee-login.styled";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REG, "E-mail is not valid.")
    .required("Work e-mail is required."),
});

const EmployeeLoginPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailRef = React.useRef(null);
  const params = useParams();

  const { companyName } = params;

  const inProgress = useSelector(authSelectors.selectInProgress);

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${S.MOBILE_BREAKPOINT}px)`,
  });

  const elementSX: React.CSSProperties = {
    width: 280,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const elementSXDesktop: React.CSSProperties = {
    width: 391,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const gapBig = isTablet ? 0 : 30;

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ email }) => {
    dispatch(sendCodeEmployee.request({ email, companyName }));
  };

  const onResendVerificationLink = ({ email }) => {
    dispatch(resendVerificationEmail.request({ email }));
  };

  return (
    <AuthFormTemplate>
      <>
        <S.Separator height={gapBig} />
        <S.ClickableWrapper onClick={() => navigate(`/${companyName}${routes.LANDING}`)}>
          <S.RowDisplay gridGap={16}>
            <S.LabelGray>
              <ArrowLeftIcon size={24} />
            </S.LabelGray>
            <S.LabelGray>Employee login</S.LabelGray>
          </S.RowDisplay>
        </S.ClickableWrapper>

        <S.Separator height={30} />
        <S.ColumnDisplay
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ width: "100%" }}
        >
          <Input
            topLabel="Work e-mail"
            isRequired
            sx={isMobile ? { ...elementSX, minWidth: "100%" } : elementSXDesktop}
            control={control}
            ref={emailRef}
            errorMessage={errors?.email?.message}
            onEnter={handleSubmit(onSubmit)}
            name="email"
          />
          <S.Separator height={4} />
          <S.Paragraph textAlign="start" fontSize={12}>
            Did your verification link expire?{" "}
            <S.ResendVerificationLink onClick={handleSubmit(onResendVerificationLink)}>
              Resend verification link
            </S.ResendVerificationLink>
          </S.Paragraph>
          <S.Separator height={30} />
          <PrimaryButton
            label="Next"
            sx={isMobile ? { ...elementSX, minWidth: "100%" } : elementSXDesktop}
            isLoading={inProgress}
            onClick={handleSubmit(onSubmit)}
          />
          <S.Separator height={16} />
          {/* <S.ParagraphExtended
            fontSize={12}
            style={{
              ...(isMobile
                ? {
                    ...elementSX,
                    minWidth: "100%",
                  }
                : elementSXDesktop),
            }}
          >
            <span>Don’t have an account yet?</span>
            <span>
              &nbsp;
              <S.LinkText
                fontSize={12}
                onClick={() => navigate(`/${companyName}${routes.EMPLOYEE.REGISTER}`)}
              >
                Create one by clicking here!
              </S.LinkText>
            </span>
          </S.ParagraphExtended> */}
          <S.Separator height={isTablet ? 60 : 0} />
        </S.ColumnDisplay>
      </>
    </AuthFormTemplate>
  );
};

export default EmployeeLoginPage;
