import * as C from "./company-volunteer-events.constants";
import * as T from "./company-volunteer-events.type";

export const fetchVolunteerEvents = {
  request: (payload: T.IFetchVolunteerEvents["payload"]): T.IFetchVolunteerEvents => ({
    type: C.FETCH_COMPANY_VOLUNTEER_EVENTS,
    payload,
  }),
  success: (
    payload: T.IFetchVolunteerEventsSuccess["payload"],
  ): T.IFetchVolunteerEventsSuccess => ({
    type: C.FETCH_COMPANY_VOLUNTEER_EVENTS_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchVolunteerEventsFailure => ({
    type: C.FETCH_COMPANY_VOLUNTEER_EVENTS_FAILURE,
  }),
};

export const refreshVolunteerEvents = {
  request: (payload: T.IRefreshVolunteerEvents["payload"]): T.IRefreshVolunteerEvents => ({
    type: C.REFRESH_COMPANY_VOLUNTEER_EVENTS,
    payload,
  }),
  success: (
    payload: T.IFetchVolunteerEventsSuccess["payload"],
  ): T.IRefreshVolunteerEventsSuccess => ({
    type: C.REFRESH_COMPANY_VOLUNTEER_EVENTS_SUCCESS,
    payload,
  }),
  failure: (): T.IRefreshVolunteerEventsFailure => ({
    type: C.REFRESH_COMPANY_VOLUNTEER_EVENTS_FAILURE,
  }),
};

export const saveVolunteerEventDto = (
  payload: T.ISaveVolunteerEventDto["payload"],
): T.ISaveVolunteerEventDto => ({
  payload,
  type: C.SAVE_VOLUNTEER_EVENT_DTO,
});

export const setVolunteerEventFilters = (filters) => ({
  payload: filters,
  type: C.SET_VOLUNTEER_EVENT_FILTERS,
});
