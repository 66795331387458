import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "store/auth/auth.action";
import authSelectors from "store/auth/auth.selector";
import colors from "theme/colors";
import { EMAIL_REG } from "utils/constants";
import * as yup from "yup";

import * as S from "./recover-password.styled";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .matches(EMAIL_REG, "E-mail is not valid."),
});

const RecoverPasswordPage: React.FC<{}> = (): JSX.Element => {
  const emailRef = React.useRef(null);
  const dispatch = useDispatch();

  const inProgress = useSelector(authSelectors.selectInProgress);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ email }) => {
    dispatch(resetPassword.request({ email }));
  };

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <div />
      <S.FormWrapper>
        <S.GradientHeading>Recover password</S.GradientHeading>
        <S.Separator height={32} />
        <Text sx={{ textAlign: "center", fontSize: 16, color: colors.gunmetal() }}>
          Please enter your work email.
        </Text>

        <S.Separator height={34} />
        <Input
          topLabel="E-mail"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          ref={emailRef}
          onEnter={handleSubmit(onSubmit)}
          errorMessage={errors?.email?.message}
          name="email"
        />
        <S.Separator height={34} />

        <PrimaryButton
          label="E-mail me a recovery link"
          onClick={handleSubmit(onSubmit)}
          sx={{ width: "100%", minWidth: "100%" }}
          isLoading={inProgress}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin showVersion />
    </S.BubbleBackground>
  );
};

export default RecoverPasswordPage;
