import {
  ChevronDownIcon,
  ChevronUpIcon,
  GlobeIcon,
  LocationIcon,
  PencilIcon,
  SearchIcon,
} from "@primer/octicons-react";
import PrimaryButton from "components/buttons/primary";
import SecondaryButton from "components/buttons/secondary";
import DropdownCheckbox from "components/dropdown-checkbox";
import EventCard from "components/event-card";
import ExternalLink from "components/external-link";
import FixedHeader from "components/fixed-header";
import PostEventCard from "components/post-event-card";
import Tag from "components/tag";
import { CharityIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import authSelectors from "store/auth/auth.selector";
import {
  changeCharityMission,
  getCharityById,
  updateCharityUNGoals,
} from "store/charity/charity.action";
import charitySelectors from "store/charity/charity.selector";
import companySelectors from "store/company/company.selector";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import volunteerSelectors from "store/volunteer-events-charity/volunteer-events-charity.selector";
import colors from "theme/colors";
import { CheckboxItem } from "types/content";
import { VolunteerEventItem } from "types/volunteer";
import { UN_GOAL_LIST } from "utils/content";
import { camelCaseText, genRandomString, verifyImg } from "utils/helper";

import * as S from "./charity-details.styled";

const MAX_UN_GOALS = 5;
const NON_APPLICABLE = UN_GOAL_LIST[UN_GOAL_LIST.length - 1].value;
const UN_GOALS_LIST_PARSED = UN_GOAL_LIST.map((item) => ({ ...item, isSelected: false }));
const MAX_CHAR = 200;

interface ICharityDetailsPage {
  charityId: string;
}

const CharityDetailsTemplate: React.FC<ICharityDetailsPage> = ({ charityId }): JSX.Element => {
  const dispatch = useDispatch();

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const charity = useSelector(charitySelectors.selectData);

  const charityError = useSelector(charitySelectors.selectError);

  const companyName = useSelector(companySelectors.selectName);

  const role = useSelector(authSelectors.selectRole);

  const volunteerEvents = useSelector(volunteerSelectors.selectData);

  const logoName = role === "charity" ? charity?.name : companyName;
  const isTextLong = !!charity?.supportStatement || !!charity?.personalStatement;

  const [unGoals, setUNGoals] = React.useState<CheckboxItem[]>(
    UN_GOALS_LIST_PARSED.filter((item) => item.value !== NON_APPLICABLE),
  );
  const [moreDisplayed, displayMore] = React.useState<boolean>(isTextLong);
  const [isUNGoalsDropdownOpen, openUNGoalsDropdown] = React.useState<boolean>(false);
  const [imgValid, setImgValid] = React.useState<boolean>(true);
  const [missionStatement, setMissionStatement] = React.useState<string>(
    charity?.missionStatement ?? "",
  );
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const handleMissionStatementChange = (e) => {
    setMissionStatement(e.target.value);
  };

  const handleMissionStatementButton = () => {
    setIsEditing(!isEditing);

    if (isEditing && missionStatement !== charity?.missionStatement) {
      dispatch(changeCharityMission.request({ missionStatement }));
    }
  };

  const loadUNGoals = (): void => {
    const selectedGoals = charity?.goals ?? [];
    if (selectedGoals?.length) {
      setUNGoals(
        unGoals.map((goal) =>
          selectedGoals.find((item) => item === goal.value)
            ? { ...goal, isSelected: true }
            : { ...goal, isSelected: false },
        ),
      );
    }
  };

  React.useEffect(() => {
    verifyImg(charity?.logoUrl, setImgValid);
  }, [charity?.logoUrl]);

  React.useEffect(() => {
    loadUNGoals();
  }, [charity?.goals]);

  React.useEffect(() => {
    if (charityId && charityId !== charity?.id) {
      dispatch(getCharityById.request(charityId));
    }
  }, [charityId]);

  const onUNGoalsSubmit = (): void => {
    const allGoals = unGoals;
    openUNGoalsDropdown(false);
    dispatch(
      updateCharityUNGoals.request({
        goals: allGoals.filter((item) => item.isSelected).map((item) => item.value) || [],
      }),
    );
  };

  const onUNGoalsCancel = (): void => {
    loadUNGoals();
    openUNGoalsDropdown(false);
  };

  const onUNGoalSelect = (value: string): void => {
    const isNotApplicable = NON_APPLICABLE === value;
    const isSelected = unGoals.find((item) => item.value === value)?.isSelected ?? false;
    const selectedGoalsNum = unGoals.filter((item) => item.isSelected).length;

    // there can be only one not applicable goal
    if (isNotApplicable) {
      setUNGoals(unGoals.map((item) => ({ ...item, isSelected: false })));
      return;
    }

    // there can be up to 3 selected un goals
    if (selectedGoalsNum < MAX_UN_GOALS || (selectedGoalsNum <= MAX_UN_GOALS && isSelected)) {
      setUNGoals(
        unGoals.map((item) =>
          item.value === value ? { ...item, isSelected: !item.isSelected } : item,
        ),
      );
    }
  };

  const handleEditVolunteerEvent = (volunteerEvent: VolunteerEventItem): void => {
    dispatch(
      updateDialogState({
        key: "POST_CHARITY_EVENT_STEP_1",
        isOpened: true,
        properties: { event: volunteerEvent },
      }),
    );
  };

  return (
    <S.CharityViewContainer gridGap={38} justifyContent="flex-start">
      <FixedHeader name={logoName} />
      <S.CharityViewInnerContainer alignItems="flex-start" justifyContent="flex-start" gridGap={42}>
        {!charityError ? (
          <>
            <S.ColumnDisplay
              width="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              gridGap={4}
            >
              <S.GradientHeadingExtended>{camelCaseText(charity?.name)}</S.GradientHeadingExtended>
              {role === "charity" && (
                <PrimaryButton
                  sx={{ maxWidth: "197px", marginLeft: "auto" }}
                  onClick={() =>
                    dispatch(updateDialogState({ key: "INVITE_ADMINS", isOpened: true }))
                  }
                  label="Invite Admins"
                />
              )}
            </S.ColumnDisplay>

            <S.FormsContainer gridGap={40}>
              <S.LeftContentContainer gridGap={40}>
                <S.MissionStatementContainer alignItems="flex-start" justifyContent="flex-start">
                  <S.ColumnDisplay
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gridGap={18}
                    width="100%"
                  >
                    <S.RowDisplayFullWidth justifyContent="space-between" gridGap={16} flexWrap>
                      {charity?.logoUrl && imgValid ? (
                        <S.CharityImg src={charity?.logoUrl} />
                      ) : (
                        <CharityIcon />
                      )}
                      <S.RowDisplay
                        gridGap={4}
                        flexWrap
                        style={{ maxWidth: "100%" }}
                        alignItems="flex-start"
                        justifyContent="flex-start"
                      >
                        {charity?.irsCategory && <Tag text={charity?.irsCategory} />}
                        {charity?.irsSubcategory && <Tag text={charity?.irsSubcategory} />}
                      </S.RowDisplay>
                    </S.RowDisplayFullWidth>

                    <S.RowDisplayFullWidth justifyContent="flex-start" gridGap={32} flexWrap>
                      <S.RowDisplay gridGap={16}>
                        <S.CharityInfoTextBold>
                          <GlobeIcon size={16} />
                        </S.CharityInfoTextBold>

                        {charity?.websiteUrl ? (
                          <ExternalLink
                            href={charity?.websiteUrl.toLowerCase()}
                            text={charity?.websiteUrl.toLowerCase()}
                          />
                        ) : (
                          <S.CharityInfoTextBold>No website provided.</S.CharityInfoTextBold>
                        )}
                      </S.RowDisplay>

                      <S.RowDisplay gridGap={16}>
                        <LocationIcon size={16} />
                        {charity?.address ? (
                          <S.CharityInfoTextBold>{charity?.address}</S.CharityInfoTextBold>
                        ) : (
                          <S.CharityInfoTextBold>No address provided.</S.CharityInfoTextBold>
                        )}
                      </S.RowDisplay>
                    </S.RowDisplayFullWidth>

                    <S.Separator height={16} />
                    <S.MissionStatementHeading>
                      Mission Statement
                      {role === "charity" && (
                        <S.MissionStatementButton
                          type="button"
                          onClick={handleMissionStatementButton}
                          isSave={isEditing}
                        >
                          {!isEditing && <PencilIcon />}
                          {isEditing ? "Save" : "Edit Text"}
                        </S.MissionStatementButton>
                      )}
                    </S.MissionStatementHeading>
                    {role === "charity" ? (
                      isEditing ? (
                        <S.MissionStatementTextarea
                          value={missionStatement}
                          onChange={handleMissionStatementChange}
                          contentEditable={isEditing}
                          autoFocus
                          maxLength={MAX_CHAR}
                        />
                      ) : (
                        <S.MissionStatementParagraph>
                          {missionStatement}
                        </S.MissionStatementParagraph>
                      )
                    ) : (
                      <S.MissionStatementParagraph>
                        {charity?.missionStatement ?? ""}
                      </S.MissionStatementParagraph>
                    )}

                    {!(isTextLong && moreDisplayed) && (
                      <>
                        {charity?.supportStatement && (
                          <>
                            <S.Separator height={50} />
                            <S.MissionStatementHeading>
                              Key programming funds will support:
                            </S.MissionStatementHeading>
                            <S.MissionStatementParagraph>
                              {charity?.supportStatement}
                            </S.MissionStatementParagraph>
                          </>
                        )}

                        {charity?.personalStatement && (
                          <>
                            <S.Separator height={50} />
                            <S.MissionStatementHeading>
                              {`Why ${charity?.name}?`}
                            </S.MissionStatementHeading>
                            <S.MissionStatementParagraph>
                              {charity?.personalStatement}
                            </S.MissionStatementParagraph>
                          </>
                        )}
                      </>
                    )}
                    {isTextLong && (
                      <S.ShowMoreLink
                        onClick={() => displayMore(!moreDisplayed)}
                        alignItems="center"
                        justifyContent="center"
                        gridGap={13}
                      >
                        <div>{moreDisplayed ? "Read more" : "Read less"}</div>
                        {moreDisplayed ? <ChevronDownIcon /> : <ChevronUpIcon />}
                      </S.ShowMoreLink>
                    )}
                  </S.ColumnDisplay>
                </S.MissionStatementContainer>
              </S.LeftContentContainer>

              <S.RightFormsContainer gridGap={20}>
                <S.CharityInfoContainer alignItems="flex-start" gridGap={16}>
                  <S.ColumnDisplay gridGap={20} alignItems="flex-start" style={{ width: "100%" }}>
                    <S.CharityDetailsText
                      textTransform="none"
                      textColor={colors.black()}
                      opacity={1}
                    >
                      UN Goals
                    </S.CharityDetailsText>
                    <S.RowDisplay width="100%" flexWrap gridGap={4} justifyContent="flex-start">
                      {(charity?.goals || [])?.map((item) => (
                        <S.UNGoalGradientContainer key={genRandomString()}>
                          {item}
                        </S.UNGoalGradientContainer>
                      ))}
                    </S.RowDisplay>
                    {role === "charity" && (
                      <>
                        <S.ColumnDisplay
                          gridGap={4}
                          alignItems="flex-start"
                          style={{ width: "100%" }}
                        >
                          <S.DropdownLabelBold>Add UN Goals</S.DropdownLabelBold>
                          <DropdownCheckbox
                            data={unGoals}
                            placeholder="Select UN Goals"
                            height={300}
                            onSelect={onUNGoalSelect}
                            displayHandler={{
                              isOpened: isUNGoalsDropdownOpen,
                              open: openUNGoalsDropdown,
                            }}
                            footer={
                              <S.DropdownFooterWrapper alignItems="flex-end">
                                <S.RowDisplay gridGap={16}>
                                  <S.ConfirmDropdownButton onClick={onUNGoalsCancel} isCancel>
                                    Cancel
                                  </S.ConfirmDropdownButton>
                                  <S.ConfirmDropdownButton onClick={onUNGoalsSubmit}>
                                    Confirm
                                  </S.ConfirmDropdownButton>
                                </S.RowDisplay>
                              </S.DropdownFooterWrapper>
                            }
                          />
                        </S.ColumnDisplay>
                        <S.DropdownLabel>{`Please select up to ${MAX_UN_GOALS} UN goals that your organization supports`}</S.DropdownLabel>
                      </>
                    )}
                  </S.ColumnDisplay>
                </S.CharityInfoContainer>
                {(role === "employee" || role === "admin") && (
                  <S.CharityInfoContainer alignItems="center" gridGap={20}>
                    <S.CharityDetailsText
                      textTransform="none"
                      textColor={colors.black()}
                      opacity={1}
                    >
                      Make a donation
                    </S.CharityDetailsText>
                    <PrimaryButton
                      label="Donate"
                      onClick={() => dispatch(updateDialogState({ key: "DONATE", isOpened: true }))}
                      sx={{
                        width: "100%",
                        maxWidth: "147px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    />
                  </S.CharityInfoContainer>
                )}
                {role === "charity" && (
                  <S.CharityInfoContainer alignItems="flex-start" gridGap={20}>
                    <S.CharityDetailsText
                      textTransform="none"
                      textColor={colors.black()}
                      opacity={1}
                    >
                      Donation Disbursements
                    </S.CharityDetailsText>

                    <S.FormParagraph>
                      Fundwurx partners with Every.org to process all the donations to charities
                      from our site. In order to receive funds sooner and eliminate any disbursement
                      fees we ask all organizations to set up direct deposit.
                    </S.FormParagraph>

                    <SecondaryButton
                      label="Go to Every.org to receive funds quicker"
                      onClick={() =>
                        window.open(window.__RUNTIME_CONFIG__.EVERY_DOT_ORG_GET_STARTED_LINK)
                      }
                      sx={{
                        width: "100%",
                        minWidth: "250px",
                        wordBreak: "break-word",
                        overflow: "hidden",
                        maxWidth: "100%",
                        boxSizing: "border-box",
                        fontSize: isTablet ? "12px" : "16px",
                      }}
                    />
                  </S.CharityInfoContainer>
                )}
              </S.RightFormsContainer>
            </S.FormsContainer>
            {role === "charity" && (
              <>
                <S.GradientHeadingExtended fontSize={35}>
                  Volunteer Opportunities
                </S.GradientHeadingExtended>
                <S.VolonteerPostContainer flexWrap gridGap={30} justifyContent="flex-start">
                  <PostEventCard />
                  {volunteerEvents.map((event) => (
                    <EventCard
                      id={event.id}
                      name={event.name}
                      key={event.id}
                      onEditEvent={() => handleEditVolunteerEvent(event)}
                    />
                  ))}
                </S.VolonteerPostContainer>
              </>
            )}
          </>
        ) : (
          <S.EmptyContainer gridGap={32}>
            <SearchIcon size={64} />
            <S.EmptyHeading>Charity could not be found</S.EmptyHeading>
            <S.EmptySubheading>
              Requested charity does not exist or is deleted from our database.
            </S.EmptySubheading>
          </S.EmptyContainer>
        )}

        <div style={{ width: "50px", height: "80px" }} />
      </S.CharityViewInnerContainer>
    </S.CharityViewContainer>
  );
};

export default CharityDetailsTemplate;
