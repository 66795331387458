import styled from "styled-components";
import { ColumnDisplay } from "theme/global-style";

export {
  BubbleBackground,
  DivLinkText,
  GradientHeading,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const HeaderWrapper = styled.div`
  width: calc(100% - 2 * 10px);
`;

export const NotFoundContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  max-width: 1128px;
  width: calc(100% - 4 * 24px);
  min-height: 640px;
  bix-sixing: border-box;
  padding: 24px;
  box-shadow: 0px 4px 45px rgba(21, 41, 93, 0.1);
  border-radius: 40px;
`;

export const NotFoundHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.charcoalGray()};
`;

export const NotFoundParagraph = styled(ColumnDisplay)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.charcoalGray()};
`;
