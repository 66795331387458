import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import * as C from "./advanced-analytics.constants";
import * as T from "./advanced-analytics.type";

const analyticsInitialData = {
  locations: {},
  departments: {},
  generations: {},
  timeAtCompany: {},
};

export const advancedAnalyticsInitial: T.IAdvancedAnalyticsState = {
  inProgress: false,
  isCached: false,
  error: null,
  advancedAnalytics: {
    volunteering: analyticsInitialData,
    giftMatching: analyticsInitialData,
    donations: analyticsInitialData,
  },
  selectedGraphData: {
    departments: {},
    locations: {},
    timeAtCompany: {},
    generations: {},
  },
};

const advancedAnalyticsReducer = (
  state: T.IAdvancedAnalyticsState,
  action: T.AdvancedAnalyticsAction | ILogout,
): T.IAdvancedAnalyticsState => {
  if (!state) {
    return advancedAnalyticsInitial;
  }

  switch (action.type) {
    case C.GET_ADVANCED_ANALYTICS:
      return {
        ...state,
        inProgress: true,
      };
    case C.GET_ADVANCED_ANALYTICS_SUCCESS: {
      const data =
        action.payload.analyticsType === "departments"
          ? Object.fromEntries(
              Object.entries(action.payload.response || {}).filter(([_, value]) => value > 0),
            )
          : action.payload.response;

      return {
        ...state,
        inProgress: false,
        advancedAnalytics: {
          ...state.advancedAnalytics,
          [action.payload.group]: {
            ...state.advancedAnalytics[action.payload.group],
            [action.payload.analyticsType]: data,
          },
        },
        selectedGraphData: {
          ...state.selectedGraphData,
          [action.payload.analyticsType]: data,
        },
      };
    }
    case C.GET_ADVANCED_ANALYTICS_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case LOGOUT:
      return advancedAnalyticsInitial;
    default:
      return state;
  }
};

export default advancedAnalyticsReducer;
