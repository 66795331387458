import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  ColumnDisplay,
  DivLinkText,
  LinkText,
  PaginationWrapper,
  RowDisplay,
  SearchAndFilterContainer,
  SearchAndFilterInnerContainer,
  Separator,
  Table,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPhone8.y;

export const GiftMatchingPageWrapper = styled(ColumnDisplay)`
  height: 100vh;
  max-height: 100vh;
  flex: 1;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0px 0px;
  box-sizing: border-box;
  padding-bottom: 85px;
  overflow-y: scroll;
  background: ${({ theme }) => theme.aliceBlue()};

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 0px 20px 85px 20px;
  }
`;

export const GiftMatchingPageInnerWrapper = styled(ColumnDisplay)`
  width: calc(100% - 2 * 24px);
  max-width: 1708px;
  min-width: 300px;
`;

export const GradientHeadingExtended = styled(GradientHeading)`
  margin: 0;
`;

export const TableHeading = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.blackCoral(0.6)};
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const StatusText = styled(RowDisplay)<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

export const MatchingProgramInnerWrapper = styled(ColumnDisplay)`
  width: 100%;
`;

export const DonationsMatchingSubheading = styled(ColumnDisplay)`
  color: ${({ theme }) => theme.gunmetal()};
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-transform: uppercase;
`;

export const DonationsMatchingForm = styled(ColumnDisplay)<{ isDisabled?: boolean }>`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.white()};
  ${({ isDisabled }) => isDisabled && "pointer-events: none;"};
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);
  border-radius: 0px 0px 12px 12px;
  padding: 40px;
  box-sizing: border-box;
`;

export const DonationsMatchingHeading = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(90, 107, 143, 1);
  text-transform: uppercase;
`;

export const HorizontalSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(242, 242, 242, 1);
`;

export const NoDataText = styled(ColumnDisplay)`
  color: ${({ theme }) => theme.gunmetal(0.5)};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
`;

export const DonationsMatchingInnerForm = styled(RowDisplay)<{ isDisabled: boolean }>`
  max-width: 100%;
  width: 100%;
  pointer-events: ${({ isDisabled }) => (!isDisabled ? "all" : "none")};
  opacity: ${({ isDisabled }) => (!isDisabled ? "1" : "0.5")};
`;

export const InputWrapper = styled.div`
  max-width: 428px;
  width: 100%;
  min-width: 200px;
`;

export const DonationsMatchingFormHeading = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.blackCoral(0.5)};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TotalMatchesText = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  color: ${({ theme }) => theme.gunmetal()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const TotalMatchesCount = styled.div`
  font-size: 28px;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.unitedNationsBlue()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 22px;
    line-height: 20px;
  }
`;

export const VerifiedText = styled(RowDisplay)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.amazon()};
`;

export const TabSwitch = styled.div<{ isFirst?: boolean; isLast?: boolean; isSelected?: boolean }>`
  background: ${({ isSelected, theme }) => (isSelected ? "rgba(97, 133, 226, 1)" : theme.white())};
  color: ${({ isSelected, theme }) => (isSelected ? "rgba(97, 133, 226, 1)" : theme.white())};
  border: 1px solid
    ${({ isSelected }) => (isSelected ? "rgba(97, 133, 226, 1)" : "rgba(224, 224, 224, 1)")};
  box-sizing: border-box;
  ${({ isFirst }) => isFirst && "border-top-left-radius: 6px; border-bottom-left-radius: 6px;"}
  ${({ isLast }) => isLast && "border-top-right-radius: 6px; border-bottom-right-radius: 6px;"};
  padding: 8px 32px 8px 32px;
  cursor: pointer;
  text-align: center;
`;

export const TabSwitchText = styled.div<{ isSelected?: boolean }>`
  color: ${({ isSelected, theme }) => (isSelected ? theme.white() : theme.charcoalGray())};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

export const DateInputFrame = styled(RowDisplay)<{ isFocused?: boolean; isInvalid: boolean }>`
  width: 220px;
  box-sizing: border-box;
  padding: 9px 16px 9px 16px;
  border: 1px solid
    ${({ isFocused, isInvalid, theme }) =>
      !isFocused && !isInvalid
        ? "rgba(208, 215, 222, 1)"
        : isInvalid
        ? theme.bordo()
        : "rgba(80, 135, 231, 1)"};
  cursor: pointer;
  grid-gap: 8px;
  border-radius: 6px;
`;

export const DateRangeLabel = styled.div<{ isFocused?: boolean }>`
  color: ${({ isFocused }) => (isFocused ? "rgba(97, 133, 226, 1)" : "rgba(120, 133, 137, 1)")};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

export const DateInputLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: rgba(120, 133, 137, 1);
`;

export const TransparentInput = styled.input<{ isHidden?: boolean }>`
  border: none;
  outline: none;
  ${({ isHidden }) => (isHidden ? "height: 0px; width: 0px;" : "width: 90%;")}
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.charcoalGray()};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.charcoalGray(0.3)};
  }
`;

export const CustomInputError = styled(RowDisplay)<{ fontSize?: number }>`
  font-size: ${({ fontSize = 12 }) => fontSize}px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.bordo()};
`;

export const DateFormatted = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: rgba(94, 107, 122, 0.3);
`;

export const ResetFilters = styled.div<{ isDisabled: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  color: ${({ isDisabled, theme }) => (isDisabled ? "rgba(94, 107, 122, 0.2)" : theme.bordo())};
`;
