import * as api from "api/volunteer";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";

import {
  fetchActiveCompanyVolunteerEvents,
  refreshActiveCompanyVolunteerEvents,
} from "./company-active-volunteer-events.action";
import * as C from "./company-active-volunteer-events.constants";
import * as T from "./company-active-volunteer-events.type";

export const fetchCompanyActiveVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IFetchActiveVolunteerEvents>,
) =>
  action$.pipe(
    ofType(C.FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS),
    mergeMap((action) =>
      from(api.getScheduledVolunteerEvents(action.payload.params, action.payload.status)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchActiveCompanyVolunteerEvents.success(response.data));
          }
          return of(
            handleError({ action, error: null }),
            fetchActiveCompanyVolunteerEvents.failure(),
          );
        }),
        catchError((error) =>
          of(fetchActiveCompanyVolunteerEvents.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export const refreshCompanyActiveVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IRefreshActiveVolunteerEvents>,
) =>
  action$.pipe(
    ofType(C.REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS),
    mergeMap((action) =>
      from(api.getScheduledVolunteerEvents(action.payload.params, action.payload.status)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(refreshActiveCompanyVolunteerEvents.success(response.data));
          }
          return of(
            handleError({ action, error: null }),
            refreshActiveCompanyVolunteerEvents.failure(),
          );
        }),
        catchError((error) =>
          of(refreshActiveCompanyVolunteerEvents.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export default combineEpics(
  fetchCompanyActiveVolunteerEventsEpic,
  refreshCompanyActiveVolunteerEventsEpic,
);
