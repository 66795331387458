import { Checkbox } from "@primer/react";
import SecondaryButton from "components/buttons/secondary";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import {
  attendVolunteerEvent,
  unattendVolunteerEvent,
} from "store/volunteer-event-details/volunteer-event-details.action";
import colors from "theme/colors";

import * as S from "../dialogs.styled";

const DIALOG_KEY = "VOLUNTEER_EVENT_SIGNUP";
const buttonProps = { width: "184px", height: "50px" };

const VolunteerEventSignUpDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, DIALOG_KEY),
  );
  const isAttending = useSelector((state: IAppState) => state.volunteerEventDetails?.data?.attends);

  const properties = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogProperties(state, DIALOG_KEY),
  );

  const [isCaptain, setIsCaptain] = React.useState<boolean>(false);

  const onClose = (): void => {
    dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
    setIsCaptain(false);
  };

  const onYes = (): void => {
    if (isAttending) {
      dispatch(unattendVolunteerEvent.request({ id: properties?.id }));
    } else {
      dispatch(attendVolunteerEvent.request({ id: properties?.id as string, captain: isCaptain }));
    }
    onClose();
  };

  return isOpened ? (
    <S.DialogBackground onClick={onClose}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={40} style={{ paddingTop: 60 }}>
          <S.GradientHeading fontSize={28}>
            {`Do you want to ${
              isAttending ? "cancel your sign-up" : "sign-up"
            } for this volunteer event?`}
          </S.GradientHeading>
          <S.Separator height={58} />
          <S.RowDisplay gridGap={16} flexWrap>
            <SecondaryButton label="Yes" sx={buttonProps} onClick={onYes} />
            <SecondaryButton
              label="No"
              sx={{
                ...buttonProps,
                color: colors.bordo(),
                ":hover": {
                  color: colors.bordo(),
                  background: colors.white(),
                },
              }}
              onClick={onClose}
            />
          </S.RowDisplay>
          {!isAttending && (
            <S.RowDisplay
              gridGap={21}
              style={{ width: "100%", justifyContent: "center", marginTop: 32 }}
              justifyContent="flex-start"
            >
              <Checkbox
                checked={isCaptain}
                onChange={() => setIsCaptain(!isCaptain)}
                style={{ display: "flex" }}
              />
              <S.Paragraph fontSize={16} style={{ width: "92%", maxWidth: 375 }} textAlign="start">
                Are you interested in serving as a captain/leader for this event? If YES, please
                check the box.
              </S.Paragraph>
            </S.RowDisplay>
          )}
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default VolunteerEventSignUpDialog;
