import { ArrowDownIcon } from "icons";
import styled from "styled-components";

export const SelectContainer = styled.div`
  position: relative;
  width: 288px;
`;

export const SelectedValue = styled.div`
  padding: 13px;
  padding-right: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PlaceholderText = styled.div`
  width: 100%;
  font-weight: 500;
  color: ${({ theme }) => theme.grey(0.7)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectedValueText = styled.div`
  width: 100%;
  font-weight: 500;
  color: ${({ theme }) => theme.gunmetal()};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectedNumber = styled.span`
  color: ${({ theme }) => theme.unitedNationsBlue(0.7)};
`;

export const SelectList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.lightSilver()};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.white()};
  z-index: 1;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.charcoalGray()};

  &:hover {
    background-color: ${({ theme }) => theme.lightSilver(0.3)};
  }
`;

export const Checkbox = styled.div<{ checked: boolean }>`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: ${({ checked }) => (checked ? "#007bff" : "#fff")};

  &::after {
    content: "${({ checked }) => (checked ? "✓" : "")}";
    font-size: 18px;
    color: ${({ checked }) => (checked ? "#fff" : "transparent")};
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 16px;
  }
`;

export const ArrowIcon = styled(ArrowDownIcon)`
  width: 12px;
  height: 6px;
  align-self: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const TextButton = styled.div<{ color?: string }>`
  font-size: 14px;
  line-height: 24px;

  cursor: pointer;
  color: ${({ color, theme }) => color || theme.unitedNationsBlue()};
`;

export const RelativeContainer = styled.div`
  position: relative;
`;
