import React from "react";

import * as S from "./custom-graph-legend.styled";

export interface LegendItem {
  label: string;
  color: string;
}

const CustomLegend: React.FC<{
  legendData: LegendItem[];
  flexDirection?: string;
  fontSize?: number | string;
  color?: string;
  dotSize?: number | string;
}> = ({ legendData, flexDirection, fontSize, color, dotSize }) => (
  <S.LegendContainer flexDirection={flexDirection}>
    {legendData.map((item, index) => (
      <S.LegendItem key={index}>
        <S.LegendColor size={dotSize} color={item.color} />
        <S.LegendLabel fontSize={fontSize} color={color}>
          {item.label}
        </S.LegendLabel>
      </S.LegendItem>
    ))}
  </S.LegendContainer>
);

CustomLegend.defaultProps = {
  flexDirection: "column",
  fontSize: "18px",
  color: undefined,
  dotSize: undefined,
};

export default CustomLegend;
