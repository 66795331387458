import { ICompanyActiveVolunteerEventsSelectors } from "./company-active-volunteer-events.type";

const companyActiveVolunteerEventsSelectors: ICompanyActiveVolunteerEventsSelectors = {
  selectData: (state) => state.companyActiveVolunteerEvents.data,
  selectDto: (state) => state.companyActiveVolunteerEvents.dto,
  selectInProgress: (state) => state.companyActiveVolunteerEvents.inProgress,
  selectEndReached: (state) => state.companyActiveVolunteerEvents.isEndReached,
  selectIsFetched: (state) => state.companyActiveVolunteerEvents.isFetched,
  selectPage: (state) => state.companyActiveVolunteerEvents.page,
  selectResults: (state) => state.companyActiveVolunteerEvents.results,
  selectError: (state) => state.companyActiveVolunteerEvents.error,
};

export default companyActiveVolunteerEventsSelectors;
