export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  const color = `#${Array.from({ length: 6 }, () => letters[Math.floor(Math.random() * 16)]).join(
    "",
  )}`;
  return color;
};

export const generateDatasetColors = (labels: string[]) => {
  const predefinedColors = ["#4B72DA", "#8DE3D9", "#A2C6EA", "#59AEF9", "#5496EE", "#1E353B"];
  const colors = labels
    .slice(0, 6)
    .map((_, index) => predefinedColors[index % predefinedColors.length]);

  if (labels.length > 6) {
    const randomColors = Array.from({ length: labels.length - 6 }, () => generateRandomColor());
    return [...colors, ...randomColors];
  }

  return colors;
};
