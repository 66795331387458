import { cacheSlice } from "store/store.cache";

import { ICharityState } from "./charity.type";

export const cacheCharity = (initialState: ICharityState, state: ICharityState): ICharityState => {
  const cachedState: ICharityState = {
    ...initialState,
    isCached: true,
    data: state.data,
  };

  cacheSlice("charity", cachedState);

  return {
    ...state,
    isCached: true,
  };
};

export const clearCharityCache = (initialState: ICharityState): ICharityState => {
  cacheSlice("charity", initialState);
  return initialState;
};
