import { ICompanyDonationsSelectors } from "./company-donations.type";

const companyDonationsSelectors: ICompanyDonationsSelectors = {
  selectInProgress: (state) => state.companyDonations.inProgress,
  selectIsFetched: (state) => state.companyDonations.isFetched,
  selectError: (state) => state.companyDonations.error,
  selectPage: (state) => state.companyDonations.page,
  selectData: (state) => state.companyDonations?.data,
  selectResults: (state) => state.companyDonations?.results,
  selectIsEndReached: (state) => state.companyDonations?.isEndReached,
};

export default companyDonationsSelectors;
