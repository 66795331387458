import { CalendarIcon } from "icons";
import styled from "styled-components";
import { RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export { RowDisplay, Separator, Tag } from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.desktopM.x;

export const VolunteerEventCardContainer = styled(RowDisplay)`
  width: 260px;
  height: 237px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white()};
  padding: 8px;
  box-sizing: border-box;

  justify-content: space-evenly;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 200px;
    height: 180px;
  }
`;

export const CalendarIconStyled = styled(CalendarIcon)`
  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 74px;
    height: 74px;
  }
`;
