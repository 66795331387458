import { Text } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import SmallButton from "components/buttons/small";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import authSelectors from "store/auth/auth.selector";
import { confirmFinchRegistration, confirmRegistration } from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import colors from "theme/colors";
import { SelectedInterestStyles } from "theme/global-style";
import { MAX_SELECTED_INTERESTS } from "utils/constants";
import { INTERESTS_LIST } from "utils/content";

import * as S from "./onboarding-interests.styled";

const OnboardingInterests: React.FC<{}> = (): JSX.Element => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const isFinch = location?.state?.isFinch || false;
  const { token } = useParams();
  const { companyName } = params;

  const inProgress = useSelector(authSelectors.selectInProgress);
  const dto = useSelector(companySelectors.selectOnboardingDto);

  const [interests, setInterests] = React.useState<Array<string>>([]);
  const [showWarning, setShowWarning] = React.useState(false);

  const selectInterest = (interest: string): void => {
    if (interests.includes(interest)) {
      if (showWarning && interests.length <= MAX_SELECTED_INTERESTS) {
        setShowWarning(false);
      }
      setInterests(interests.filter((i) => i !== interest));
    } else {
      if (interests.length >= MAX_SELECTED_INTERESTS) {
        setShowWarning(true);
        return;
      }
      setInterests([...interests, interest]);
    }
  };

  const handleFinish = () => {
    if (isFinch) {
      dispatch(
        confirmFinchRegistration.request({ companyName, token, interests: interests || [] }),
      );
    } else {
      dispatch(
        confirmRegistration.request({
          role: "employee",
          companyName,
          dto: {
            firstName: dto?.firstName || "",
            lastName: dto?.lastName || "",
            department: dto?.department,
            token,
            interests: interests || [],
          },
        }),
      );
    }
  };

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <S.Separator height={100} />
      <S.InterestsFormContainer>
        <S.GradientHeading>Add interests</S.GradientHeading>
        <S.Separator height={16} />
        <Text
          sx={{
            textAlign: "center",
            lineHeight: "24px",
            fontSize: "16px",
            color: colors.gunmetal(),
          }}
        >
          {showWarning ? (
            <S.DialogLabelWarning>
              Maximum amount of interests that can be selected is {MAX_SELECTED_INTERESTS}
            </S.DialogLabelWarning>
          ) : (
            <>Please select up to {MAX_SELECTED_INTERESTS} interest</>
          )}
        </Text>

        <S.Separator height={35} />
        <S.InterestContainer>
          {INTERESTS_LIST.map((interest) => (
            <SmallButton
              label={interest}
              onClick={() => selectInterest(interest)}
              sx={interests.includes(interest) ? SelectedInterestStyles : null}
            />
          ))}
        </S.InterestContainer>
        <S.Separator height={35} />
        <PrimaryButton
          label="Finish"
          onClick={handleFinish}
          isLoading={inProgress}
          sx={{ width: "100%", maxWidth: "391px" }}
        />
        <S.Separator height={20} />
        <S.SkipText onClick={handleFinish}>Skip for now</S.SkipText>
      </S.InterestsFormContainer>
      <S.Separator height={40} />
      <LogoAndCompany companyName={companyName} showMargin showVersion={false} />
    </S.BubbleBackground>
  );
};

export default OnboardingInterests;
