import background1 from "assets/images/cover-1.png";
import background2 from "assets/images/cover-2.png";
import background3 from "assets/images/cover-3.png";
import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ColumnDisplay,
  GradientHeading,
  LinkText,
  Paragraph,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;
export const TABLET_L_BREAKPOINT = breakpoints.samsungGalaxyTab10.y;

const backgrounds = {
  1: background1,
  2: background2,
  3: background3,
};

export const DoubleFormWrapper = styled(RowDisplay)`
  width: 1129px;
  max-width: calc(100% - 16px);
  max-height: 740px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  box-sizing: border-box;
  border-radius: 50px;
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 4px 45px rgba(33, 53, 102, 0.1);
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column-reverse;
    align-items: center;
    min-width: 320px;
    max-width: calc(100% - 2 * 12px);
    width: 366px;
    padding: 50px 22px 38px 22px;
    padding-bottom: 0px;
    margin: 0px;
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
    height: 100vh;
    max-height: 100vh;
  }
`;

export const CoverFrameAnimation = styled(ColumnDisplay)<{
  animationDuration?: number;
  iterationCount?: number;
}>`
  animation-name: fade-animation;
  animation-duration: ${({ animationDuration = 15 }) => animationDuration}s;
  animation-iteration-count: ${({ iterationCount }) => iterationCount ?? "infinite"};
  animation-direction: forward;

  @keyframes fade-animation {
    0% {
      background-image: url(${backgrounds[1]});
    }

    26.6% {
      background-image: url(${backgrounds[1]});
    }

    33.3% {
      background-image: url(${backgrounds[2]});
    }

    60% {
      background-image: url(${backgrounds[2]});
    }

    66.6% {
      background-image: url(${backgrounds[3]});
    }

    93.3% {
      background-image: url(${backgrounds[3]});
    }

    100% {
      background-image: url(${backgrounds[1]});
    }
  }
`;

export const CoverFrame = styled(CoverFrameAnimation)`
  max-width: 50%;
  aspect-ratio: 540 / 694;
  max-height: calc(100% - 2 * 23px);
  min-height: 500px;
  margin: 23px 23px 0px -23px;
  box-sizing: border-box;
  background-size: cover;
  position: relative;
  border-radius: 30px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 342px;
    width: 342px;
    height: 372px;
    max-height: 372px;
    min-height: 372px;
    margin: 0px;
    border-radius: 20px;
    overflow: hidden;
  }
`;

export const CoverFrameAbsolute = styled(CoverFrameAnimation)`
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
`;

export const CoverFrameInnerContent = styled(ColumnDisplay)`
  padding: 84px;
`;

export const LogoWrapper = styled(ColumnDisplay)`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 15%;
`;

export const WelcomeText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 14px;
  }
`;

export const HeadingText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: ${({ theme }) => theme.white()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 32px;
  }
`;

export const LineSeparator = styled.div`
  width: 124px;
  height: 1px;
  background: ${({ theme }) => theme.white()};
`;

export const ParagraphWhite = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.white()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 14px;
    max-width: 290px;
  }
`;

export const FormWrapper = styled(ColumnDisplay)`
  width: 50%;
  padding: 70px 0px 40px 76px;
  box-sizing: border-box;
  height: 100%;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0px;
    margin: 0px;
    width: 100%;
  }
`;

export const MobileFormContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0 4px 8px 0 rgba(33, 53, 102, 0.1), 0 6px 20px 0 rgba(33, 53, 102, 0.15);
  border-radius: 24px;
  position: relative;
  margin-bottom: 40px;
`;

export const LabelBold = styled.div`
  font-size: 24px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.spaceCodet()};

  @media all and (max-width: ${TABLET_L_BREAKPOINT}px) {
    font-size: 16px;
  }
`;

export const LabelGray = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(132, 145, 161, 1);

  @media all and (max-width: ${TABLET_L_BREAKPOINT}px) {
    font-size: 14px;
  }
`;

export const LabelFaded = styled(ColumnDisplay)`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: rgba(87, 96, 106, 1);
`;

export const GradientHeadingExtended = styled(GradientHeading)`
  font-size: 48px;
  margin: 0;

  @media all and (max-width: ${TABLET_L_BREAKPOINT}px) {
    font-size: 32px;
  }
`;

export const CompanyLogo = styled.img`
  width: 140px;
  height: auto;
  object-fit: cover;

  @media all and (max-width: ${TABLET_L_BREAKPOINT}px) {
    width: 80px;
    height: auto;
  }
`;
