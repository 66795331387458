import { IVolunteerCharitySelectors } from "./volunteer-events-charity.type";

const volunteerCharitySelectors: IVolunteerCharitySelectors = {
  selectData: (state) => state.volunteerCharity.data,
  selectDto: (state) => state.volunteerCharity.dto,
  selectCustomVolunteerEventDto: (state) => state.volunteerCharity.customVolunteerEventDto,
  selectInProgress: (state) => state.volunteerCharity.inProgress,
  selectEndReached: (state) => state.volunteerCharity.isEndReached,
  selectIsFetched: (state) => state.volunteerCharity.isFetched,
  selectPage: (state) => state.volunteerCharity.page,
  selectResults: (state) => state.volunteerCharity.results,
  selectError: (state) => state.volunteerCharity.error,
};

export default volunteerCharitySelectors;
