import {
  Charity,
  CharityAdminInfoDto,
  ConfirmCharityRegistrationDto,
  InviteAdminsProps,
  ISearchCharitiesParams,
  ISearchCharitiesResponse,
  IUpdateCharityUNGoalsProps,
  RegisterCharityRequestDto,
  RegisterCharityResponseDto,
} from "types/charity";
import { ServerResponse } from "types/common";
import { genUrlParams } from "utils/helper";

import client from "./client";

export const registerCharity = async (
  body: RegisterCharityRequestDto,
): Promise<ServerResponse<RegisterCharityResponseDto>> =>
  client.post("/charity/initiateRegistration", body);

export const confirmCharityRegistration = async (
  body: CharityAdminInfoDto,
): Promise<ServerResponse<ServerResponse<ConfirmCharityRegistrationDto>>> =>
  client.post("/volunteer/charity/registration/confirm", body);

export const confirmCharityAdminRegistration = async (body: { token: string }): Promise<any> =>
  client.post("/charity/admin/confirmRegistration", body);

export const setCharityDetails = async (
  body: FormData,
): Promise<ServerResponse<ServerResponse<Charity>>> => client.post("/charity/setDetails", body);

export const changeCharityMission = async (
  missionStatement: string,
): Promise<ServerResponse<Charity>> =>
  (await client.patch("/charity/mission", { missionStatement })).data;

export const getCharityById = async (id: string): Promise<Charity> =>
  (await client.get(`/charity/${id}`)).data?.data;

export const searchCharities = async (
  params: ISearchCharitiesParams,
): Promise<ServerResponse<ServerResponse<ISearchCharitiesResponse>>> =>
  client.get(genUrlParams("/search/charities", params));

export const updateCharityUNGoals = async (
  body: IUpdateCharityUNGoalsProps,
): Promise<ServerResponse<Charity>> => (await client.put("/charity/goals", body)).data;

export const inviteCharityAdmin = async (params: InviteAdminsProps): Promise<any> =>
  client.post("/charity/admin/invite", params);
