import { getCharityById as getCharityByIdApi } from "api/charity";
import * as api from "api/user";
import { combineEpics, ofType } from "redux-observable";
import CustomHistory from "router/history";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { getCharityById } from "store/charity/charity.action";
import { getCompanyById } from "store/company/company.action";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { hideFullScreenLoader } from "store/ui-components/ui-components.actions";
import routes from "utils/routes";

import {
  editEmployeeDetails,
  fetchUser,
  toggleGiftMatchNotification,
  updateInterests,
  verifyEmail,
} from "./user.action";
import * as C from "./user.constants";
import * as T from "./user.type";

const fetchUserEpic: AppEpic = (action$: Observable<T.IFetchUser>, $state) =>
  action$.pipe(
    ofType(C.FETCH_USER),
    mergeMap((action) =>
      from(api.fetchUser()).pipe(
        mergeMap((response) => {
          if (response?.data) {
            const { role } = $state.value.auth;
            if (role === "charity") {
              return from(getCharityByIdApi(response?.data?.data.charityId)).pipe(
                mergeMap((value) => {
                  CustomHistory.navigate(
                    `${routes.CHARITY.DETAILS}/${response?.data.data.charityId}`,
                  );
                  return of(
                    fetchUser.success(response.data.data),
                    getCharityById.success(value),
                    hideFullScreenLoader(),
                  );
                }),
              );
            }
            return of(
              fetchUser.success(response.data.data),
              getCompanyById.request(response.data.data.companyId),
              hideFullScreenLoader(),
            );
          }
          return of(
            handleError({ action, error: null }),
            fetchUser.failure(),
            hideFullScreenLoader(),
          );
        }),
        catchError((error) =>
          of(fetchUser.failure(), handleError({ action, error }), hideFullScreenLoader()),
        ),
      ),
    ),
  );

const verifyEmailEpic: AppEpic = (action$: Observable<T.IVerifyEmail>) =>
  action$.pipe(
    ofType(C.VERIFY_EMAIL),
    mergeMap((action) =>
      from(api.verifyEmail(action.payload)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(verifyEmail.success(response.data));
          }
          return of(handleError({ action, error: null }), verifyEmail.failure());
        }),
        catchError((error) => of(verifyEmail.failure(), handleError({ action, error }))),
      ),
    ),
  );

const editEmployeeDetailsEpic: AppEpic = (action$: Observable<T.IEditEmployeeDetails>) =>
  action$.pipe(
    ofType(C.EDIT_EMPLOYEE_DETAILS),
    mergeMap((action) =>
      from(api.editEmployeeDetails(action.payload)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(editEmployeeDetails.success());
          }
          return of(handleError({ action, error: null }), editEmployeeDetails.failure());
        }),
        catchError((error) => of(editEmployeeDetails.failure(), handleError({ action, error }))),
      ),
    ),
  );

const updateInterestsEpic: AppEpic = (action$: Observable<T.IUpdateInterests>) =>
  action$.pipe(
    ofType(C.UPDATE_INTERESTS),
    mergeMap((action) =>
      from(api.updateInterests(action.payload)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(updateInterests.success(action.payload));
          }
          return of(handleError({ action, error: null }), updateInterests.failure());
        }),
        catchError((error) => of(updateInterests.failure(), handleError({ action, error }))),
      ),
    ),
  );

const toggleGiftMatchNotificationEpic: AppEpic = (
  action$: Observable<T.IToggleGiftMatchNotification>,
) =>
  action$.pipe(
    ofType(C.TOGGLE_GIFT_MATCH_NOTIFICATION),
    mergeMap((action) =>
      from(api.toggleGiftMatchNotification(action.payload)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(toggleGiftMatchNotification.success(action.payload));
          }
          return of(handleError({ action, error: null }), toggleGiftMatchNotification.failure());
        }),
        catchError((error) =>
          of(toggleGiftMatchNotification.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export default combineEpics(
  fetchUserEpic,
  verifyEmailEpic,
  editEmployeeDetailsEpic,
  updateInterestsEpic,
  toggleGiftMatchNotificationEpic,
);
