import { PencilIcon } from "@primer/octicons-react";
import SmallButton from "components/buttons/small";
import React from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { DropdownItem } from "types/content";

import * as S from "./edit-dropdown.styled";

interface IEditDropdown {
  items: DropdownItem[];
}

const EditDropdown: React.FC<IEditDropdown> = ({ items }): JSX.Element => {
  const dispatch = useDispatch();
  const [editDropdownOpen, openEditDropdown] = React.useState<boolean>(false);

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const onItemClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: DropdownItem,
  ): void => {
    e.preventDefault();
    if (!item.isDisabled) {
      openEditDropdown(!editDropdownOpen);
      item.onClick(dispatch);
    }
  };

  return (
    <S.EditButtonWrapper
      justifyContent="flex-end"
      alignItems="flex-end"
      tabIndex={0}
      onBlur={() => openEditDropdown(false)}
    >
      <S.EditButtonDropdownWrapper>
        <S.EditDropdown isOpen={editDropdownOpen}>
          {items.map((item) => (
            <S.EditDropdownItem
              key={item.id}
              onMouseDown={(e) => onItemClick(e, item)}
              isDisabled={item.isDisabled}
            >
              {item.text}
            </S.EditDropdownItem>
          ))}
        </S.EditDropdown>
        <SmallButton
          label={!isTablet ? "Edit" : ""}
          leadingIcon={PencilIcon}
          onClick={() => openEditDropdown(!editDropdownOpen)}
          sx={{ height: 32 }}
        />
      </S.EditButtonDropdownWrapper>
    </S.EditButtonWrapper>
  );
};

export default EditDropdown;
