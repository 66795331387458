import { CharityIcon } from "icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Charity } from "types/charity";
import { genRandomString, verifyImg } from "utils/helper";
import routes from "utils/routes";

import * as S from "../charity-card.styled";
import * as T from "../charity-card.type";

const CharityEmployeeCard: React.FC<Charity & T.ICharityCardProps> = ({
  id,
  logoUrl,
  name,
  lookingFor,
  address,
  location,
  isEveryOrg = false,
  isBigCard = false,
  slug,
  backFromHome,
}): JSX.Element => {
  const navigate = useNavigate();

  const [imgValid, setImgValid] = React.useState<boolean>(true);

  const onCardClick = (): void => {
    if (!isEveryOrg) {
      navigate(`${routes.CHARITY.DETAILS}/${id}`);
    } else {
      navigate(`${routes.EVERY_DOT_ORG.CHARITY_DETAILS}/${id || slug}`, {
        state: { backFromHome },
      });
    }
  };

  React.useEffect(() => {
    verifyImg(logoUrl, setImgValid);
  }, [logoUrl]);

  return (
    <S.CharityCardContainer onClick={onCardClick} isBigCard={isBigCard}>
      <S.CharityCardGradientContainer />
      <S.CharityCardInnerContainer
        alignItems="flex-start"
        justifyContent="space-between"
        isBigCard={isBigCard}
      >
        {logoUrl && imgValid ? <S.CharityImg src={logoUrl} alt="" /> : <CharityIcon />}
        <S.Separator height={12} />
        <S.CharityName>{name}</S.CharityName>
        <S.Separator height={30} />
        <S.Separator height={6} />
        <S.CharityAddress>{location || address || ""}</S.CharityAddress>
        <S.Separator height={30} />
        {!isEveryOrg && (
          <S.RowDisplay gridGap={7}>
            {lookingFor?.map((tag) => (
              <S.Tag key={genRandomString()}>{tag}</S.Tag>
            ))}
          </S.RowDisplay>
        )}
      </S.CharityCardInnerContainer>
    </S.CharityCardContainer>
  );
};

export default CharityEmployeeCard;
