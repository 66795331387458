import * as C from "./ui-components.constants";
import * as T from "./ui-components.types";

export const uiComponentsInitial: T.IUIComponentsState = {
  dialog: {},
  dialogHistory: [],
  dropdown: {},
  input: {},
  checkbox: {},
  toast: {
    isShown: false,
    message: null,
    type: "success",
  },
  fullScreenLoader: {
    isLoading: false,
  },
};

const uiComponentsReducer = (
  state: T.IUIComponentsState,
  action: T.UIComponentAction,
): T.IUIComponentsState => {
  if (!state) {
    return uiComponentsInitial;
  }

  switch (action.type) {
    case C.UPDATE_DIALOG_STATE:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          [action.payload.key]: {
            isOpened: action.payload.isOpened,
            properties: action.payload.properties,
          },
        },
        dialogHistory: [
          ...state.dialogHistory,
          { key: action.payload.key, state: { isOpened: action.payload.isOpened } },
        ],
      };
    case C.RESET_DIALOG_STATE_HISTORY:
      return {
        ...state,
        dialogHistory: [],
      };
    case C.UPDATE_CHECKBOX_STATE:
      return {
        ...state,
        checkbox: {
          ...state.checkbox,
          [action.payload.key]: {
            ...action.payload,
          },
        },
      };
    case C.UPDATE_DROPDOWN_STATE:
      return {
        ...state,
        dropdown: {
          ...state.dropdown,
          [action.payload.key]: {
            ...action.payload,
          },
        },
      };
    case C.UPDATE_INPUT_STATE:
      return {
        ...state,
        input: {
          ...state.input,
          [action.payload.key]: {
            ...action.payload,
          },
        },
      };
    case C.UPDATE_TOAST_STATE:
      return {
        ...state,
        toast: action.payload,
      };
    case C.UPDATE_LOADER_STATE:
      return {
        ...state,
        fullScreenLoader: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default uiComponentsReducer;
