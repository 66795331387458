export const STORE_AUTH_TOKEN = "STORE_AUTH_TOKEN";

export const SIGN_IN_CREDENTIALS = "SIGN_IN_CREDENTIALS";
export const SIGN_IN_CREDENTIALS_SUCCESS = "SIGN_IN_CREDENTIALS_SUCCESS";
export const SIGN_IN_CREDENTIALS_FAILURE = "SIGN_IN_CREDENTIALS_FAILURE";

export const SIGN_IN_CODE = "SIGN_IN_CODE";
export const SIGN_IN_CODE_SUCCESS = "SIGN_IN_CODE_SUCCESS";
export const SIGN_IN_CODE_FAILURE = "SIGN_IN_CODE_FAILURE";

export const SEND_CODE_EMPLOYEE = "SEND_CODE_EMPLOYEE";
export const SEND_CODE_EMPLOYEE_SUCCESS = "SEND_CODE_EMPLOYEE_SUCCESS";
export const SEND_CODE_EMPLOYEE_FAILURE = "SEND_CODE_EMPLOYEE_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_NEW_PASSWORD_SUCCESS = "SET_NEW_PASSWORD_SUCCESS";
export const SET_NEW_PASSWORD_FAILURE = "SET_NEW_PASSWORD_FAILURE";

export const RESEND_CODE = "RESEND_CODE";
export const RESEND_CODE_SUCCESS = "RESEND_CODE_SUCCESS";
export const RESEND_CODE_FAILURE = "RESEND_CODE_FAILURE";

export const RESEND_VERIFICATION_EMAIL = "RESEND_VERIFICATION_EMAIL";
export const RESEND_VERIFICATION_EMAIL_SUCCESS = "RESEND_VERIFICATION_EMAIL_SUCCESS";
export const RESEND_VERIFICATION_EMAIL_FAILURE = "RESEND_VERIFICATION_EMAIL_FAILURE";

export const LOGOUT = "LOGOUT";
