import * as api from "api/charity";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";

import { searchCharities, showMoreSearchedCharities } from "./charities-search.action";
import * as C from "./charities-search.constants";
import * as T from "./charities-search.type";

const searchCharitiesEpic: AppEpic = (action$: Observable<T.ISearchCharitiesRequest>, $state) =>
  action$.pipe(
    ofType(C.SEARCH_CHARITIES),
    mergeMap((action) =>
      from(api.searchCharities({ ...action.payload, page: 1 })).pipe(
        mergeMap((response) => {
          if (response) {
            return of(searchCharities.success(response.data.data));
          }
          return of(searchCharities.failure());
        }),
        catchError((error) => of(searchCharities.failure(), handleError({ action, error }))),
      ),
    ),
  );

const showMoreSearchedCharitiesEpic: AppEpic = (
  action$: Observable<T.IShowMoreSearchedCharitiesRequest>,
  $state,
) =>
  action$.pipe(
    ofType(C.SHOW_MORE_SEARCHED_CHARITIES),
    mergeMap((action) => {
      const { q, goal, lookingFor, state, page } = $state.value.charitiesSearch;
      return from(api.searchCharities({ q, goal, lookingFor, state, page })).pipe(
        mergeMap((response) => {
          if (response) {
            return of(showMoreSearchedCharities.success(response.data.data.charities));
          }
          return of(showMoreSearchedCharities.failure());
        }),
        catchError((error) => of(searchCharities.failure(), handleError({ action, error }))),
      );
    }),
  );

export default combineEpics(searchCharitiesEpic, showMoreSearchedCharitiesEpic);
