import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import ControlledSelect from "components/controlled-select";
import Input from "components/input";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams, useSearchParams } from "react-router-dom";
import CustomHistory from "router/history";
import authSelectors from "store/auth/auth.selector";
import { confirmRegistration, saveRegistrationDTO } from "store/company/company.action";
import colors from "theme/colors";
import { DepartmentType } from "types/department";
import { NAME_REG } from "utils/constants";
import { DEPARTMENTS_LIST } from "utils/content";
import routes from "utils/routes";
import * as yup from "yup";

import * as S from "./create-account.styled";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .matches(NAME_REG, "First name must be valid.")
    .min(2, "First name must have at least 2 characters."),
  lastName: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .matches(NAME_REG, "Last name must be valid.")
    .min(2, "Last name must have at least 2 characters."),
  department: yup.string().required("Please fill in all data."),
});

const CreateEmployeeAccount: React.FC<{}> = (): JSX.Element => {
  const params = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const inProgress = useSelector(authSelectors.selectInProgress);

  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);
  const departmentRef = React.useRef(null);

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const { companyName } = params;

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { firstName: "", lastName: "", department: "Human Resources (HR)" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ firstName, lastName, department }) => {
    dispatch(
      saveRegistrationDTO({ firstName, lastName, department: department as DepartmentType, token }),
    );
    CustomHistory.navigate(`/company/${companyName}/interests/employee/${token}`);
  };

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <div />
      <S.FormWrapper>
        <S.GradientHeading>
          Hello<S.IconText> 👋 </S.IconText>Nice to meet you
        </S.GradientHeading>
        <S.Separator height={48} />
        <Text
          sx={{
            textAlign: "center",
            lineHeight: "24px",
            color: colors.gunmetal(),
          }}
        >
          We have successfully validated your email!
          {!isTablet && <br />}
          Please enter some basic information about yourself.
        </Text>

        <S.Separator height={70} />

        <Input
          topLabel="First Name"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          ref={firstNameRef}
          errorMessage={errors?.firstName?.message}
          onEnter={() => lastNameRef?.current?.focus()}
          name="firstName"
        />
        <S.Separator height={40} />
        <Input
          topLabel="Last Name"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          ref={lastNameRef}
          errorMessage={errors?.lastName?.message}
          name="lastName"
          onEnter={() => departmentRef?.current?.focus()}
        />
        <S.Separator height={40} />
        <ControlledSelect
          items={DEPARTMENTS_LIST}
          control={control}
          sx={{ width: "100%", minWidth: "100%" }}
          name="department"
          ref={departmentRef}
        />
        <S.Separator height={70} />
        <PrimaryButton
          label="Continue"
          onClick={handleSubmit(onSubmit)}
          isLoading={inProgress}
          sx={{ width: "100%", minWidth: "100%" }}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={companyName} showMargin />
    </S.BubbleBackground>
  );
};

export default CreateEmployeeAccount;
