export const REGISTER_COMPANY = "REGISTER_COMPANY";
export const REGISTER_COMPANY_SUCCESS = "REGISTER_COMPANY_SUCCESS";
export const REGISTER_COMPANY_FAILURE = "REGISTER_COMPANY_FAILURE";

export const REGISTER_EMPLOYEE = "REGISTER_EMPLOYEE";
export const REGISTER_EMPLOYEE_SUCCESS = "REGISTER_EMPLOYEE_SUCCESS";
export const REGISTER_EMPLOYEE_FAILURE = "REGISTER_EMPLOYEE_FAILURE";

export const CONFIRM_REGISTRATION = "CONFIRM_REGISTRATION";
export const CONFIRM_REGISTRATION_SUCCESS = "CONFIRM_REGISTRATION_SUCCESS";
export const CONFIRM_REGISTRATION_FAILURE = "CONFIRM_REGISTRATION_FAILURE";

export const SAVE_REGISTRATION_DTO = "SAVE_REGISTRATION_DTO";
export const RESET_REGISTRATION_DTO = "RESET_REGISTRATION_DTO";

export const CHECK_COMPANY_INFO = "CHECK_COMPANY_INFO";
export const CHECK_COMPANY_INFO_SUCCESS = "CHECK_COMPANY_INFO_SUCCESS";
export const CHECK_COMPANY_INFO_FAILURE = "CHECK_COMPANY_INFO_FAILURE";

export const CHECK_COMPANY_EXISTS = "CHECK_COMPANY_EXISTS";
export const CHECK_COMPANY_EXISTS_SUCCESS = "CHECK_COMPANY_EXISTS_SUCCESS";
export const CHECK_COMPANY_EXISTS_FAILURE = "CHECK_COMPANY_EXISTS_FAILURE";

export const GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_FAILURE = "GET_COMPANY_BY_ID_FAILURE";

export const GET_COMPANY_WALL = "GET_COMPANY_WALL";
export const GET_COMPANY_WALL_SUCCESS = "GET_COMPANY_WALL_SUCCESS";
export const GET_COMPANY_WALL_FAILURE = "GET_COMPANY_WALL_FAILURE";

export const ADD_TO_COMPANY_WALL = "ADD_TO_COMPANY_WALL";
export const ADD_TO_COMPANY_WALL_SUCCESS = "ADD_TO_COMPANY_WALL_SUCCESS";
export const ADD_TO_COMPANY_WALL_FAILURE = "ADD_TO_COMPANY_WALL_FAILURE";

export const REMOVE_FROM_COMPANY_WALL = "REMOVE_FROM_COMPANY_WALL";
export const REMOVE_FROM_COMPANY_WALL_SUCCESS = "REMOVE_FROM_COMPANY_WALL_SUCCESS";
export const REMOVE_FROM_COMPANY_WALL_FAILURE = "REMOVE_FROM_COMPANY_WALL_FAILURE";

export const GET_DASHBOARD_SUMMARY = "GET_DASHBOARD_SUMMARY";
export const GET_DASHBOARD_SUMMARY_SUCCESS = "GET_DASHBOARD_SUMMARY_SUCCESS";
export const GET_DASHBOARD_SUMMARY_FAILURE = "GET_DASHBOARD_SUMMARY_FAILURE";

export const GET_ANALYTIC_DASHBOARD_SUMMARY = "GET_ANALYTIC_DASHBOARD_SUMMARY";
export const GET_ANALYTIC_DASHBOARD_SUMMARY_SUCCESS = "GET_ANALYTIC_DASHBOARD_SUMMARY_SUCCESS";
export const GET_ANALYTIC_DASHBOARD_SUMMARY_FAILURE = "GET_ANALYTIC_DASHBOARD_SUMMARY_FAILURE";

export const GET_EMPLOYEE_INTERESTS = "GET_EMPLOYEE_INTERESTS";
export const GET_EMPLOYEE_INTERESTS_SUCCESS = "GET_EMPLOYEE_INTERESTS_SUCCESS";
export const GET_EMPLOYEE_INTERESTS_FAILURE = "GET_EMPLOYEE_INTERESTS_FAILURE";

export const GET_CHARITIES_SUPPORTED = "GET_CHARITIES_SUPPORTED";
export const GET_CHARITIES_SUPPORTED_SUCCESS = "GET_CHARITIES_SUPPORTED_SUCCESS";
export const GET_CHARITIES_SUPPORTED_FAILURE = "GET_CHARITIES_SUPPORTED_FAILURE";

export const GET_DONATION_ANALYTICS = "GET_DONATION_ANALYTICS";
export const GET_DONATION_ANALYTICS_SUCCESS = "GET_DONATION_ANALYTICS_SUCCESS";
export const GET_DONATION_ANALYTICS_FAILURE = "GET_DONATION_ANALYTICS_FAILURE";

export const GET_GIFT_MATCHING_ANALYTICS = "GET_GIFT_MATCHING_ANALYTICS";
export const GET_GIFT_MATCHING_ANALYTICS_SUCCESS = "GET_GIFT_MATCHING_ANALYTICS_SUCCESS";
export const GET_GIFT_MATCHING_ANALYTICS_FAILURE = "GET_GIFT_MATCHING_ANALYTICS_FAILURE";

export const GET_VOLUNTEERING_ANALYTICS = "GET_VOLUNTEERING_ANALYTICS";
export const GET_VOLUNTEERING_ANALYTICS_SUCCESS = "GET_VOLUNTEERING_ANALYTICS_SUCCESS";
export const GET_VOLUNTEERING_ANALYTICS_FAILURE = "GET_VOLUNTEERING_ANALYTICS_FAILURE";

export const SELECT_ADVANCED_ANALYTICS = "SELECT_ADVANCED_ANALYTICS";

export const UPDATE_COMPANY_LOGO = "UPDATE_COMPANY_LOGO";
export const UPDATE_COMPANY_LOGO_SUCCESS = "UPDATE_COMPANY_LOGO_SUCCESS";
export const UPDATE_COMPANY_LOGO_FAILURE = "UPDATE_COMPANY_LOGO_FAILURE";

export const DELETE_COMPANY_LOGO = "DELETE_COMPANY_LOGO";
export const DELETE_COMPANY_LOGO_SUCCESS = "DELETE_COMPANY_LOGO_SUCCESS";
export const DELETE_COMPANY_LOGO_FAILURE = "DELETE_COMPANY_LOGO_FAILURE";

export const CONFIRM_FINCH_REGISTRATION = "CONFIRM_FINCH_REGISTRATION";
export const CONFIRM_FINCH_REGISTRATION_SUCCESS = "CONFIRM_FINCH_REGISTRATION_SUCCESS";
export const CONFIRM_FINCH_REGISTRATION_FAILURE = "CONFIRM_FINCH_REGISTRATION_FAILURE";
