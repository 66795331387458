import styled from "styled-components";
import { RowDisplay } from "theme/global-style";

export { RowDisplay } from "theme/global-style";

export const FileUploadWrapper = styled(RowDisplay)<{ isErrorMode: boolean }>`
  width: 100%;
  max-width: 100%;
  min-height: 53px;
  border: 1px solid
    ${({ theme, isErrorMode }) => (!isErrorMode ? theme.lightSilver() : theme.bordo())};
  border-radius: 6px;
  color: ${({ theme }) => theme.mantee()};
  box-sizing: border-box;
  padding: 12px;
`;

export const NoFileChoosenText = styled(RowDisplay)<{
  isErrorMode?: boolean;
  isDisabled?: boolean;
  isUrlView?: boolean;
}>`
  color: ${({ theme, isErrorMode, isUrlView }) =>
    !isErrorMode ? (isUrlView ? theme.trueBlue() : theme.mantee()) : theme.bordo()};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  max-width: 100%;
  opacity: ${({ isDisabled }) => (!isDisabled ? 1 : 0.5)};
  text-decoration: ${({ isUrlView }) => (isUrlView ? "underline" : undefined)};
`;
