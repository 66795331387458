export const FETCH_VOLUNTEER_EVENTS = "FETCH_VOLUNTEER_EVENTS";
export const FETCH_VOLUNTEER_EVENTS_SUCCESS = "FETCH_VOLUNTEER_EVENTS_SUCCESS";
export const FETCH_VOLUNTEER_EVENTS_FAILURE = "FETCH_VOLUNTEER_EVENTS_FAILURE";

export const FETCH_CHARITY_VOLUNTEER_EVENTS = "FETCH_CHARITY_VOLUNTEER_EVENTS";
export const FETCH_CHARITY_VOLUNTEER_EVENTS_SUCCESS = "FETCH_CHARITY_VOLUNTEER_EVENTS_SUCCESS";
export const FETCH_CHARITY_VOLUNTEER_EVENTS_FAILURE = "FETCH_CHARITY_VOLUNTEER_EVENTS_FAILURE";

export const SAVE_VOLUNTEER_EVENT_DTO = "SAVE_VOLUNTEER_EVENT_DTO";
export const RESET_VOLUNTEER_EVENT_DTO = "RESET_VOLUNTEER_EVENT_DTO";

export const SAVE_CUSTOM_VOLUNTEER_EVENT_DTO = "SAVE_CUSTOM_VOLUNTEER_EVENT_DTO";
export const RESET_CUSTOM_VOLUNTEER_EVENT_DTO = "RESET_CUSTOM_VOLUNTEER_EVENT_DTO";

export const POST_VOLUNTEER_EVENT = "POST_VOLUNTEER_EVENT";
export const POST_VOLUNTEER_EVENT_SUCCESS = "POST_VOLUNTEER_EVENT_SUCCESS";
export const POST_VOLUNTEER_EVENT_FAILURE = "POST_VOLUNTEER_EVENT_FAILURE";

export const POST_CUSTOM_VOLUNTEER_EVENT = "POST_CUSTOM_VOLUNTEER_EVENT";
export const POST_CUSTOM_VOLUNTEER_EVENT_SUCCESS = "POST_CUSTOM_VOLUNTEER_EVENT_SUCCESS";
export const POST_CUSTOM_VOLUNTEER_EVENT_FAILURE = "POST_CUSTOM_VOLUNTEER_EVENT_FAILURE";

export const EDIT_VOLUNTEER_EVENT = "EDIT_VOLUNTEER_EVENT";
export const EDIT_VOLUNTEER_EVENT_SUCCESS = "EDIT_VOLUNTEER_EVENT_SUCCESS";
export const EDIT_VOLUNTEER_EVENT_FAILURE = "EDIT_VOLUNTEER_EVENT_FAILURE";
