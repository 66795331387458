import styled from "styled-components";
import { ColumnDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ColumnDisplay,
  GradientHeading,
  LinkText,
  Paragraph,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.y;

export const LabelGray = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(132, 145, 161, 1);

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 14px;
  }
`;

export const LabelFaded = styled(ColumnDisplay)`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: rgba(87, 96, 106, 1);
`;
