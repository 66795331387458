import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import LogoAndCompany from "components/logo-and-company";
import NavigationProgressTracker from "components/navigation-progress-tracker";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { registerCharity } from "store/charity/charity.action";
import charitySelectors from "store/charity/charity.selector";
import colors from "theme/colors";
import { EIN_NUMBER_REG, EMAIL_REG } from "utils/constants";
import { CHARITY_REGISTRATION_PAGES } from "utils/content";
import * as yup from "yup";

import * as S from "../charity-auth.styled";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REG, "E-mail is not valid.")
    .required("Please fill in all data."),
  password: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .min(8, "Password must have at least 8 characters."),
  repeatedPassword: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
  einNumber: yup
    .string()
    .trim()
    .matches(EIN_NUMBER_REG, "Please enter valid EIN number.")
    .required("Please fill in all data."),
});

const RegisterCharityPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const repeatedPasswordRef = React.useRef(null);
  const einNumberRef = React.useRef(null);

  const inProgress = useSelector(charitySelectors.selectInProgress);

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({
    defaultValues: { email: "", password: "", repeatedPassword: "", einNumber: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ email, password, einNumber }) => {
    dispatch(
      registerCharity.request({ adminEmail: email, adminPassword: password, ein: einNumber }),
    );
  };

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <NavigationProgressTracker items={CHARITY_REGISTRATION_PAGES} activeItemIndex={0} />
      <S.FormWrapper>
        <S.GradientHeading style={{ width: "118%" }}>
          Register a charity on Fundwurx
        </S.GradientHeading>
        <S.Separator height={28} />
        <Text sx={{ textAlign: "center", fontSize: 16, color: colors.gunmetal(), margin: "-8px" }}>
          Please enter the e-mail associated with the nonprofit
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          organization you're creating an account for.
        </Text>
        <S.Separator height={70} />

        <Input
          topLabel="E-mail"
          isRequired
          bottomLabel="Charity e-mail."
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          ref={emailRef}
          onFocus={() => {
            emailRef?.current?.setSelectionRange(0, 1);
          }}
          onEnter={() => passwordRef?.current?.focus()}
          errorMessage={errors?.email?.message}
          name="email"
        />
        <S.Separator height={40} />
        <Input
          topLabel="Password"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          type="password"
          control={control}
          ref={passwordRef}
          errorMessage={errors?.password?.message}
          name="password"
          onEnter={() => repeatedPasswordRef?.current?.focus()}
        />
        <S.Separator height={40} />
        <Input
          topLabel="Repeat password"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          type="password"
          ref={repeatedPasswordRef}
          errorMessage={errors?.repeatedPassword?.message}
          name="repeatedPassword"
          onEnter={() => einNumberRef?.current?.focus()}
        />
        <S.Separator height={40} />
        <Input
          topLabel="EIN number"
          placeholder="XX-YYYYYYY"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          type="text"
          ref={einNumberRef}
          onChange={(val) => {
            if (val?.length > 2 && !val.includes("-")) {
              setValue("einNumber", `${val.slice(0, 2)}-${val?.slice(2, val.length) ?? ""}`);
            }
          }}
          errorMessage={errors?.einNumber?.message}
          name="einNumber"
          onEnter={handleSubmit(onSubmit)}
        />
        <S.Separator height={52} />
        <PrimaryButton
          label="Next"
          onClick={handleSubmit(onSubmit)}
          isLoading={inProgress}
          sx={{ width: "100%", minWidth: "100%" }}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};
export default RegisterCharityPage;
