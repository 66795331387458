import background from "assets/images/cover-1.png";
import styled from "styled-components";
import breakpoints from "utils/breakpoints";

import { ColumnDisplay, RowDisplay } from "../create-account/create-account.styled";

export {
  BubbleBackground,
  ColumnDisplay,
  GradientHeading,
  Paragraph,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const DoubleFormWrapper = styled(RowDisplay)`
  width: 1129px;
  min-width: 1000px;
  max-width: calc(100% - 16px);
  height: 80vh;
  max-height: 740px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  box-sizing: border-box;
  border-radius: 50px;
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 4px 45px rgba(33, 53, 102, 0.1);
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column-reverse;
    align-items: center;
    min-width: 320px;
    max-width: calc(100% - 2 * 12px);
    width: 366px;
    padding: 50px 22px 38px 22px;
    padding-bottom: 0px;
    margin: 0px;
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
    height: 100vh;
    max-height: 100vh;
  }
`;

export const DesktopSeparator = styled.div`
  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

export const CoverFrame = styled(ColumnDisplay)`
  max-width: 50%;
  aspect-ratio: 540 / 694;
  max-height: calc(100% - 2 * 23px);
  min-height: 500px;
  margin: 23px 23px 0px -23px;
  box-sizing: border-box;
  background-size: cover;
  background-image: url(${background});

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 342px;
    width: 342px;
    height: 372px;
    max-height: 372px;
    min-height: 372px;
    margin: 0px;
    border-radius: 20px;
    overflow: hidden;
  }
`;

export const CoverFrameInnerContent = styled(ColumnDisplay)`
  padding: 84px;
`;

export const WelcomeText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 14px;
  }
`;

export const HeadingText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: ${({ theme }) => theme.white()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 32px;
  }
`;

export const LineSeparator = styled.div`
  width: 124px;
  height: 1px;
  background: ${({ theme }) => theme.white()};
`;

export const ParagraphWhite = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.white()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 14px;
    max-width: 290px;
  }
`;

export const FormWrapper = styled(ColumnDisplay)`
  width: 50%;
  padding: 70px 0px 40px 76px;
  box-sizing: border-box;
  height: 100%;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0px;
    margin: 0px;
    width: 100%;
  }
`;

export const MobileFormContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 4px 45px rgba(33, 53, 102, 0.1);
  padding: 12px;
  padding-bottom: 28px;
  border-radius: 24px;
  position: relative;
`;

export const TermsOfServiceLink = styled.a`
  color: ${({ theme }) => theme.trueBlue()};
`;

export const ResendVerificationLink = styled.span`
  color: ${({ theme }) => theme.trueBlue()};
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
`;
