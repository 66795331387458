import PrimaryButton from "components/buttons/primary";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import volunteerEventDetailsSelectors from "store/volunteer-event-details/volunteer-event-details.selector";
import colors from "theme/colors";
import routes from "utils/routes";

import * as S from "../dialogs.styled";

const DIALOG_KEY = "REQUESTED_INTRO";

const RequestedIntroDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, DIALOG_KEY),
  );

  const volunteerEvent = useSelector(volunteerEventDetailsSelectors.selectData);

  const onClose = (): void => {
    navigate(routes.COMPANY.VOLUNTEERING);
    dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
  };

  return isOpened ? (
    <S.DialogBackground>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={40} style={{ paddingTop: 60 }}>
          <S.GradientHeading fontSize={32}>Check your inbox!</S.GradientHeading>
          <S.Separator height={40} />
          <S.Paragraph fontSize={20} lineHeight={24} color={colors.charcoalGray(0.5)}>
            {`We sent an email introducing you to the volunteer coordinators at ${volunteerEvent?.charity?.name}.`}
          </S.Paragraph>
          <S.Separator height={24} />
          <S.Paragraph fontSize={20} lineHeight={24} color={colors.charcoalGray(0.5)}>
            After you specify a date and time for the event be sure to return to Fundwurx to publish
            the event!
          </S.Paragraph>
          <S.Separator height={40} />
          <PrimaryButton
            label="Discover More Volunteer Events"
            sx={{ width: "100%", maxWidth: "395px" }}
            onClick={onClose}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default RequestedIntroDialog;
