import styled from "styled-components";
import { ColumnDisplay } from "theme/global-style";

export { ClickableWrapper, RowDisplay } from "theme/global-style";

export const ToastWrapper = styled(ColumnDisplay)`
  position: fixed;
  top: 0;
  margin-top: 24px;
  width: 100vw;
  z-index: 5;

  animation-name: opacity-animation;
  animation-duration: 500ms;

  @keyframes opacity-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
