import unGoalsImg from "assets/images/un-goals.png";
import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ClickableWrapper,
  ColumnDisplay,
  FormWrapper,
  LinkWrapper,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.desktopM.x;
export const MOBILE_BREAKPOINT = breakpoints.iPhone13Pro.x;

export const CharityViewContainer = styled(ColumnDisplay)`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${({ theme }) => theme.aliceBlue()};
`;

export const CharityViewInnerContainer = styled(ColumnDisplay)`
  width: calc(100vw - 2 * 152px);
  height: 100%;
  max-width: 1424px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: calc(100% - 2 * 24px);
  }
`;

export const RowDisplayFullWidth = styled(RowDisplay)`
  width: 100%;
  flex-wrap: wrap;
`;

export const DetailsText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const GradientHeadingExtended = styled(GradientHeading)`
  margin: 0px;
  text-align: start;
`;

export const FormsContainer = styled(RowDisplay)`
  align-items: flex-start;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const MissionStatementContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);
  border-radius: 12px;
  flex-shrink: 1;
  max-width: 940px;
  width: 50vw;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 30px 38px 37px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    max-width: 100%;
    min-width: 240px;
  }

  @media all and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 16px;
  }
`;

export const LeftContentContainer = styled(ColumnDisplay)`
  flex-shrink: 1;
  max-width: 940px;
  height: 100%;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    max-width: calc(100vw - 2 * 24px);
  }
`;

export const RightFormsContainer = styled(ColumnDisplay)`
  position: relative;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: row;
    align-items: flex-start;
  }

  @media all and (max-width: ${breakpoints.iPhone13Pro.y}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CharityImg = styled.img`
  width: 72px;
  max-width: 72px;
  min-width: 72px;
  height: auto;
  object-fit: cover;
`;

export const MissionStatementHeading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.gunmetal()};

  display: flex;
  align-items: center;
`;

export const MissionStatementTextarea = styled.textarea`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  word-break: break-word;
  color: ${({ theme }) => theme.gunmetal()};

  width: 100%;
  max-width: 100%;
`;

export const MissionStatementParagraph = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  word-break: break-word;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const MissionStatementButton = styled.button<{ isSave?: boolean }>`
  border-radius: 6px;
  padding: 6px 11px 6px 11px;
  border: 1px solid rgba(27, 31, 36, 0.15);
  margin-left: 16px;
  min-width: 98px;
  min-height: 32px;

  font-size: 12px;
  font-weight: 600;
  color: ${({ theme, isSave }) => (isSave ? theme.saveGreen() : theme.gunmetal())};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;

  cursor: pointer;
`;

export const ShowMoreLink = styled(RowDisplayFullWidth)`
  color: ${({ theme }) => theme.unitedNationsBlue()};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
`;

export const MissionStatementBottomDisplay = styled(RowDisplay)`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const UNGoalContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  padding: 16px;
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);
  border-radius: 12px;
  box-sizing: border-box;
  width: 100%;
  max-width: 450px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

export const UNGoalText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.unitedNationsBlue()};
`;

export const CharityInfoContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);
  border-radius: 12px;
  flex-shrink: 1;
  width: 450px;
  box-sizing: border-box;
  padding: 30px;
  max-width: 100%;

  @media all and (max-width: ${breakpoints.iPhone13Pro.y}px) {
    width: 100%;
    max-width: 100%;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    min-width: 320px;
    max-width: 50%;
    height: 100%;
  }
`;

export const CharityInfoText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  max-width: calc(100% - 30px);
  color: ${({ theme }) => theme.gunmetal()};
  word-break: break-word;
`;

export const UNGoalGradientContainer = styled(ColumnDisplay)`
  background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
  padding: 4px 8px 4px 8px;
  border-radius: 48px;
  color: ${({ theme }) => theme.white()};
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
`;

export const CharityInfoTextBold = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  max-width: calc(100% - 30px);
  word-break: break-word;
`;

export const CharityInfoSeparator = styled.div`
  width: calc(100% + 57px);
  margin-left: -30px;
  height: 1px;
  background: ${({ theme }) => theme.gunmetal(0.1)};
`;

export const UNGoalsImg = styled.div`
  width: 63px;
  height: 36px;
  background-image: url(${unGoalsImg});
`;

export const DonationText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  word-break: break-word;
  color: ${({ theme }) => theme.black(0.4)};
`;

export const DonationTextBold = styled.span`
  font-weight: 600;
`;

export const CharityDetailsText = styled.div<{
  textTransform?: string;
  textColor?: string;
  opacity?: number;
}>`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme, textColor = theme.blackCoral() }) => textColor};
  opacity: ${({ opacity = 0.5 }) => opacity};
  text-transform: ${({ textTransform = "uppercase" }) => textTransform};
`;

export const DropdownLabelBold = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const FormParagraph = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const DropdownLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.quartz()};
`;

export const EmptyContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);
  border-radius: 12px;
  flex-shrink: 1;
  max-width: 940px;
  width: 100%;
  height: 420px;
  align-self: center;
  box-sizing: border-box;
  padding: 30px 30px 38px 37px;
  overflow: hidden;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    max-width: 100%;
  }

  @media all and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 16px;
  }
`;

export const EmptyHeading = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.spaceCodet()};
`;

export const DropdownFooterWrapper = styled(ColumnDisplay)`
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  padding-right: 16px;
  position: sticky;
  bottom: 0px;
  background: ${({ theme }) => theme.white()};
`;

export const ConfirmDropdownButton = styled.div<{ isCancel?: boolean }>`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme, isCancel }) => (!isCancel ? theme.unitedNationsBlue() : theme.gunmetal(0.2))};
`;

export const EmptySubheading = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.blackCoral()};
`;

export const VolonteerPostContainer = styled(RowDisplay)`
  width: 100%;
`;
