import * as C from "./employee-dashboard-summary.constants";
import * as T from "./employee-dashboard-summary.type";

export const fetchUserDashboardSummary = {
  request: (): T.IFetchEmployeeDashboardSummary => ({
    type: C.FETCH_USER_DONATIONS_SUMMARY,
  }),
  success: (
    payload: T.IFetchEmployeeDashboardSummarySuccess["payload"],
  ): T.IFetchEmployeeDashboardSummarySuccess => ({
    type: C.FETCH_USER_DONATIONS_SUMMARY_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchEmployeeDashboardSummaryFailure => ({
    type: C.FETCH_USER_DONATIONS_SUMMARY_FAILURE,
  }),
};
