import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ColumnDisplay,
  FormWrapper,
  GradientHeading,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;
