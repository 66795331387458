import { FormControl, Select, Text } from "@primer/react";
import React from "react";
import { Controller } from "react-hook-form";
import colors from "theme/colors";
import { Separator } from "theme/global-style";

import * as T from "./controlled-select.type";

const ControlledSelect: React.FC<T.IControlledSelect> = React.forwardRef<any, T.IControlledSelect>(
  (
    {
      items,
      control,
      sx,
      name,
      label,
      size,
      placeholder,
      onSelect,
      required = false,
      errorMessage,
    },
    ref,
  ): JSX.Element => (
    <FormControl sx={sx}>
      <FormControl.Label
        visuallyHidden={!label}
        sx={{ textAlign: "start", fontWeight: "600", fontSize: 15, color: colors.gunmetal() }}
      >
        {label}
        {label && required && <span style={{ color: colors.bordo() }}>{" *"}</span>}
      </FormControl.Label>
      <Separator height={8} />

      {control ? (
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange: formChange } }) => (
            <Select
              ref={ref}
              value={value}
              sx={sx}
              validationStatus={errorMessage ? "error" : null}
              onChange={(e) => {
                formChange(e);
                if (onSelect) {
                  onSelect(e.target.value);
                }
              }}
              block
              size={size || "large"}
              placeholder={placeholder}
              required={required}
            >
              {items.map((option) => (
                <Select.Option key={option.id} value={option.value}>
                  {option.text}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      ) : (
        <Select
          ref={ref}
          block
          sx={sx}
          size={size ?? "large"}
          placeholder={placeholder}
          onChange={(e) => {
            if (onSelect) {
              onSelect(e.target.value);
            }
          }}
        >
          {items.map((option) => (
            <Select.Option key={option.id} value={option.value}>
              {option.text}
            </Select.Option>
          ))}
        </Select>
      )}

      {errorMessage && <Text sx={{ fontSize: 12, color: colors.bordo() }}>{errorMessage}</Text>}
    </FormControl>
  ),
);
export default ControlledSelect;
