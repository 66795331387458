export const FETCH_PAST_COMPANY_VOLUNTEER_EVENTS = "FETCH_PAST_COMPANY_VOLUNTEER_EVENTS";
export const FETCH_PAST_COMPANY_VOLUNTEER_EVENTS_SUCCESS =
  "FETCH_PAST_COMPANY_VOLUNTEER_EVENTS_SUCCESS";
export const FETCH_PAST_COMPANY_VOLUNTEER_EVENTS_FAILURE =
  "FETCH_PAST_COMPANY_VOLUNTEER_EVENTS_FAILURE";

export const REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS = "REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS";
export const REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS_SUCCESS =
  "REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS_SUCCESS";
export const REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS_FAILURE =
  "REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS_FAILURE";
