import { ArcElement, Chart as ChartJS, ChartOptions, Tooltip } from "chart.js";
import CustomLegend, { LegendItem } from "components/custom-graph-legend";
import React from "react";
import { generateDatasetColors } from "utils/graphs";

import * as S from "./pie-chart.styled";

interface PieChartProps {
  data: any;
  title: string;
}

const pieOptions: ChartOptions<"doughnut"> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },
  cutout: "65%",
};

ChartJS.register(ArcElement, Tooltip);
ChartJS.defaults.color = "#1E343B";

const PieChart: React.FC<PieChartProps> = ({ data, title }) => {
  let legendData: LegendItem[] = [];

  const pieLabels = Object.entries(data || {}).map(([key, value]) => `${key} (${value}%)`);

  const pieData = {
    labels: pieLabels,
    datasets: [
      {
        data: Object.values(data || {}),
        backgroundColor: generateDatasetColors(pieLabels),
        borderWidth: 0,
        spacing: 10,
        borderRadius: 10,
      },
    ],
  };

  if (pieData.datasets && Array.isArray(pieData.datasets) && pieData.datasets.length > 0) {
    const firstDataset = pieData.datasets[0];
    if (firstDataset.backgroundColor && Array.isArray(firstDataset.backgroundColor)) {
      legendData = firstDataset.backgroundColor.map((color, index) => ({
        label: (pieData.labels?.[index] as string) || "",
        color: color || "",
      }));
    }
  }

  return (
    <S.MainContainer>
      <S.PieChartTitle>{title}</S.PieChartTitle>
      <S.InnerContainer>
        <S.PieChartContainer data={pieData} options={pieOptions} />
        <CustomLegend legendData={legendData} />
      </S.InnerContainer>
    </S.MainContainer>
  );
};

export default PieChart;
