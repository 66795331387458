import { IUIComponentsSelectors } from "./ui-components.types";

const uiComponentsSelectors: IUIComponentsSelectors = {
  selectDialogState: (state, key) => state.uiComponents.dialog?.[key]?.isOpened ?? false,
  selectLastOpenedDialog: (state) =>
    state.uiComponents.dialogHistory.filter((item) => item.state.isOpened).pop()?.key ?? null,
  selectPreviouslyOpenedDialog: (state) =>
    state.uiComponents.dialogHistory.filter((item) => item.state.isOpened).reverse()[1]?.key ??
    null,
  selectDropdownState: (state, key) => state.uiComponents.dropdown?.[key],
  selectInputState: (state, key) => state.uiComponents.input?.[key],
  selectCheckboxState: (state, key) => state.uiComponents.checkbox?.[key]?.values ?? [],
  selectDialogProperties: (state, key) => state.uiComponents.dialog?.[key]?.properties ?? null,
  selectToastState: (state) => state.uiComponents.toast,
  selectLoaderState: (state) => state.uiComponents.fullScreenLoader?.isLoading ?? false,
};

export default uiComponentsSelectors;
