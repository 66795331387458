import * as C from "./volunteer-scheduled.constants";
import * as T from "./volunteer-scheduled.type";

export const fetchVolunteerScheduledEvents = {
  request: (
    payload: T.IFetchVolunteerScheduledEvents["payload"],
  ): T.IFetchVolunteerScheduledEvents => ({
    type: C.FETCH_VOLUNTEER_SCHEDULED_EVENTS,
    payload,
  }),
  success: (
    payload: T.IFetchVolunteerScheduledEventsSuccess["payload"],
  ): T.IFetchVolunteerScheduledEventsSuccess => ({
    type: C.FETCH_VOLUNTEER_SCHEDULED_EVENTS_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.IFetchVolunteerScheduledEventsFailure["payload"],
  ): T.IFetchVolunteerScheduledEventsFailure => ({
    type: C.FETCH_VOLUNTEER_SCHEDULED_EVENTS_FAILURE,
    payload,
  }),
};

export const fetchMoreVolunteerScheduledEvents = {
  request: (
    payload: T.IFetchMoreVolunteerScheduledEvents["payload"],
  ): T.IFetchMoreVolunteerScheduledEvents => ({
    type: C.FETCH_MORE_VOLUNTEER_SCHEDULED_EVENTS,
    payload,
  }),
  success: (
    payload: T.IFetchMoreVolunteerScheduledEventsSuccess["payload"],
  ): T.IFetchMoreVolunteerScheduledEventsSuccess => ({
    type: C.FETCH_MORE_VOLUNTEER_SCHEDULED_EVENTS_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.IFetchMoreVolunteerScheduledEventsFailure["payload"],
  ): T.IFetchMoreVolunteerScheduledEventsFailure => ({
    type: C.FETCH_MORE_VOLUNTEER_SCHEDULED_EVENTS_FAILURE,
    payload,
  }),
};
