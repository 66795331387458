import styled from "styled-components";
import colors from "theme/colors";
import { ColumnDisplay, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  ClickableWrapper,
  ColumnDisplay,
  LinkText,
  Paragraph,
  RowDisplay,
  Separator,
  Tag,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPadMini.x;

export const PC_SMALL_BREAKPOINT = breakpoints.desktopM.x;

export const ContainerBackground = styled(ColumnDisplay)<{ background?: string }>`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  justify-content: space-between;

  background: ${({ theme, background }) => background ?? theme.white()};
`;

export const RootWrapper = styled(ColumnDisplay)`
  width: 100%;
`;

export const TopContainer = styled(ColumnDisplay)`
  padding: 0px 0px 40px 0px;
  background: ${({ theme }) => theme.aliceBlue()};
  width: 100%;
`;

export const TopInnerContainer = styled(ColumnDisplay)`
  width: calc(100vw - 2 * 24px);
  min-width: 300px;
  max-width: 1708px;
`;

export const TopContainerGradientSeparator = styled.div`
  background: linear-gradient(90deg, rgba(51, 234, 241, 0.5) 0%, rgba(86, 145, 234, 0.5) 100%);
  width: 100%;
  height: 2px;
`;

export const TabContainer = styled(RowDisplay)`
  margin-top: 50px;
  width: 100%;
  max-width: 1431px;
`;

export const GradientContainerWrapper = styled(ColumnDisplay)`
  padding: 2px;
  padding-top: 0px;
  background: linear-gradient(90deg, rgba(51, 234, 241, 0.5) 0%, rgba(86, 145, 234, 0.5) 100%);
  width: calc(100% - 2 * 108px);
  border-radius: 0px 0px 30px 25px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: calc(100% - 2 * 24px);
  }
`;

export const GradientContainerWhiteWrapper = styled(RowDisplay)`
  background: ${({ theme }) => theme.white()};
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 30px 25px;
`;

export const GradientContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.cultured()};
  padding: 40px 40px 36px 40px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px 0px 30px 25px;
  position: relative;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 16px;
  }
`;

export const GradientContainerHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.spaceCodet()};
`;

export const SectionContainer = styled(ColumnDisplay)`
  text-align: left;
  align-self: flex-start;
`;

export const SectionGradientHeading = styled.div<{ hideMargins?: boolean; mobileMargins?: number }>`
  background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  margin-left: ${({ hideMargins }) => (hideMargins ? 40 : 150)}px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-left: ${({ hideMargins, mobileMargins }) => (hideMargins ? mobileMargins ?? 0 : 16)}px;
    margin-right: ${({ hideMargins, mobileMargins }) => (hideMargins ? mobileMargins ?? 0 : 16)}px;
  }
`;

export const SectionGradientInnerHeading = styled(SectionGradientHeading)`
  max-width: 1800px;
`;

export const AdminControlsContainer = styled(ColumnDisplay)`
  text-align: left;
  width: 100%;
  max-width: 1431px;
`;

export const SectionGradientSubHeading = styled.div<{ hideMargins?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.blueJeans()};
  margin-left: ${({ hideMargins }) => (hideMargins ? 40 : 150)}px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-left: ${({ hideMargins }) => (hideMargins ? 0 : 16)}px;
    margin-right: ${({ hideMargins }) => (hideMargins ? 0 : 16)}px;
  }
`;

export const HorizontalScrollContainer = styled(ColumnDisplay)`
  overflow-x: scroll;
  max-width: 100%;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  ::-webkit-scrollbar {
    display: none;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 16px;
  }
`;

export const VerticalScrollContainer = styled(RowDisplay)`
  max-width: 100%;
  height: auto;
  padding: 40px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 16px;
  }
`;

export const CompanyCharityCardContainer = styled(RowDisplay)`
  width: calc(100% - 2 * 108px);
  margin-left: 150px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: calc(100% - 2 * 24px);
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const MatchesText = styled(RowDisplay)`
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  color: ${({ theme }) => theme.blackCoral(0.6)};
  display: flex;
  align-self: start;
  margin-left: 100px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-left: 16px;
  }
`;

export const NoMatchesHeading = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.spaceCodet()};
`;

export const NoMatchesSubheading = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
`;

export const BackText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const TAB_GROUP_STYLE = {
  borderColor: colors.lightSilver(),
  background: colors.brightGray(),
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "6px",
};

export const SELECTED_TAB_STYLE = {
  padding: "0px",
  fontSize: "18px",
  borderColor: colors.unitedNationsBlue(),
  borderWidth: "3px",
  borderStyle: "solid",
  borderRight: `3px solid ${colors.unitedNationsBlue()} !important`,
  borderRadius: "6px !important",
  color: colors.charcoalGray(),
  ":hover": {
    borderColor: `${colors.unitedNationsBlue()} !important`,
    borderWidth: "3px",
    borderStyle: "solid",
    borderRight: `3px solid ${colors.unitedNationsBlue()} !important`,
    borderRadius: "6px !important",
    background: `${colors.ghostWhite()} !important`,
    color: `${colors.charcoalGray()} !important`,
  },
};

export const UNSELECTED_TAB_STYLE = {
  padding: "0px",
  fontSize: "18px",
  color: colors.charcoalGray(),
  borderColor: "transparent",
  outline: "none",
  background: "transparent !important",
  ":hover": {
    border: "none",
    background: `${colors.ghostWhite()} !important`,
    color: `${colors.charcoalGray()} !important`,
  },
};
