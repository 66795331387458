import { ICompanyPastVolunteerEventsSelectors } from "./company-past-volunteer-events.type";

const companyPastVolunteerEventsSelectors: ICompanyPastVolunteerEventsSelectors = {
  selectData: (state) => state.companyPastVolunteerEvents.data,
  selectDto: (state) => state.companyPastVolunteerEvents.dto,
  selectInProgress: (state) => state.companyPastVolunteerEvents.inProgress,
  selectEndReached: (state) => state.companyPastVolunteerEvents.isEndReached,
  selectIsFetched: (state) => state.companyPastVolunteerEvents.isFetched,
  selectPage: (state) => state.companyPastVolunteerEvents.page,
  selectResults: (state) => state.companyPastVolunteerEvents.results,
  selectError: (state) => state.companyPastVolunteerEvents.error,
};

export default companyPastVolunteerEventsSelectors;
