import { InfoIcon } from "@primer/octicons-react";
import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  AnalyticPaddingContainer,
  ColumnDisplay,
  LineSeparator,
  LinkText,
  RowDisplay,
  SearchAndFilterContainer,
  SearchAndFilterInnerContainer,
  Separator,
  Table,
  TabSwitch,
  TabSwitchText,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPhone8.y;

export const ManagePeoplePageWrapper = styled(ColumnDisplay)`
  height: 100vh;
  max-height: 100vh;
  flex: 1;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0px 150px 85px 150px;
  box-sizing: border-box;
  padding-bottom: 85px;
  overflow-y: scroll;
  background: ${({ theme }) => theme.aliceBlue()};

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 15px 20px 85px 20px;
  }
`;

export const GradientHeadingExtended = styled(GradientHeading)`
  margin: 0;
`;

export const TotalUsersText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.blueJeans()};
`;

export const PaginationWrapper = styled(RowDisplay)`
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.white()};
  height: 56px;
  border: 1px solid ${({ theme }) => theme.gunmetal(0.1)};
  border-radius: 0px 0px 12px 12px;
  margin-top: 3px;

  a,
  span {
    display: unset !important;
    padding: 8px 16px;
  }
`;

export const WhiteBgContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  margin-bottom: 30px;

  background: ${({ theme }) => theme.white()};
`;

export const PaddingContainer = styled.div`
  margin: 20px 30px 20px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media all and (max-width: ${breakpoints.iPadMini.x}px) {
    margin: 10px;
    justify-content: center;
  }
`;

export const Paragraph = styled.div`
  font-size: 22px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;

  color: ${({ theme }) => theme.blackCoral(0.9)};

  @media all and (max-width: ${breakpoints.iPadMini.x}px) {
    font-size: 18px;
  }
`;

export const AnalyticDisplayContainer = styled.div<{ width?: string; fullWidth?: boolean }>`
  border-radius: 8px;
  padding: 10px 20px 10px 20px;
  border: 1px solid ${({ theme }) => theme.separatorGray()};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  flex-shrink: unset !important;
  display: flex;
  align-items: center;
  position: relative;

  width: ${({ fullWidth, width }) => width || (fullWidth ? "unset" : "100%")};
  max-width: ${({ fullWidth }) => (fullWidth ? "unset" : "442px")};
  flex-wrap: wrap;
  position: relative;

  @media all and (max-width: ${breakpoints.iPadMini.x}px) {
    padding: 10px;
    width: -webkit-fill-available;
  }
`;

export const AnalyticDisplayParagraph = styled.span<{ fontWeight?: string }>`
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  line-height: 17px;
  margin-bottom: 5px;
  margin-top: 5px;
  overflow-wrap: break-word;

  font-weight: ${({ fontWeight }) => fontWeight || "400"};

  color: ${({ theme }) => theme.blackCoral()};
`;

export const AnalyticDisplayInfoIcon = styled(InfoIcon)`
  margin-left: 8px;
  margin-bottom: 2px;

  cursor: pointer;
`;

export const AnalyticNumber = styled.div<{ numberFontSize?: string; color?: string }>`
  color: ${({ theme, color }) => color || theme.blackCoral()};
  font-weight: 800;
  font-size: ${({ numberFontSize }) => numberFontSize || "40px"};
  line-height: 48px;
`;

export const AnalyticGradientNumber = styled(GradientHeading)<{ numberFontSize?: string }>`
  font-weight: 800;
  font-size: ${({ numberFontSize }) => numberFontSize || "40px"};
  line-height: 48px;

  margin: 0 !important;
`;

export const AnalyticPercentageContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 15px;

  gap: 10px;
  flex: wrap;
`;

export const AnalyticPercentage = styled.div<{
  backgroundColor?: string;
  color?: string;
  isClickable?: boolean;
}>`
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
  font-size: 16px;
  line-height: 20px;
  cursor: ${({ isClickable }) => isClickable && "pointer"};

  background: ${({ theme, backgroundColor }) => backgroundColor || theme.unitedNationsBlue(0.2)};
  color: ${({ theme, color }) => color || theme.unitedNationsBlue()};
`;

export const ColumnDisplayAnalytics = styled(ColumnDisplay)`
  align-items: flex-start;
  flex-shrink: unset !important;
`;

export const RowDisplayAnalytics = styled(RowDisplay)`
  justify-content: flex-start;
  grid-gap: 30px;
  flex-shrink: unset !important;
`;

export const NoDataText = styled(ColumnDisplay)`
  color: ${({ theme }) => theme.gunmetal(0.5)};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
`;

export const DonationsMatchingForm = styled(ColumnDisplay)<{ isDisabled?: boolean }>`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.white()};
  ${({ isDisabled }) => isDisabled && "pointer-events: none;"};
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);
  border-radius: 0px 0px 12px 12px;
  padding: 40px;
  box-sizing: border-box;
`;

export const ResetFilters = styled.div<{ isDisabled: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin-left: 20px;
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  color: ${({ isDisabled, theme }) => (isDisabled ? "rgba(94, 107, 122, 0.2)" : theme.gunmetal())};
`;
