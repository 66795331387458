import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import ControlledSelect from "components/controlled-select";
import Input from "components/input";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams, useSearchParams } from "react-router-dom";
import { confirmRegistration } from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import colors from "theme/colors";
import { NAME_REG } from "utils/constants";
import { DEPARTMENTS_LIST } from "utils/content";
import * as yup from "yup";

import * as S from "../e-mail-validated/e-mail-validated.styled";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .min(2, "First name must have at least 2 characters.")
    .matches(NAME_REG, "First name must be valid.")
    .required("Please fill in all data."),
  lastName: yup
    .string()
    .trim()
    .min(2, "Last name must have at least 2 characters.")
    .matches(NAME_REG, "Last name must be valid.")
    .required("Please fill in all data."),
  department: yup.string().required("Please fill in all data."),
});

const EmailValidatedSuperAdmin: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const { companyName } = params;

  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);
  const departmentRef = React.useRef(null);

  const inProgress = useSelector(companySelectors.selectInProgress);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      department: "Human Resources (HR)",
    },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ firstName, lastName, department }) => {
    dispatch(
      confirmRegistration.request({
        companyName,
        role: "admin",
        dto: { firstName, lastName, department, token },
      }),
    );
  };

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <div />
      <S.FormWrapper>
        <S.GradientHeading>E-mail validated</S.GradientHeading>
        <S.Separator height={24} />
        <Text sx={{ textAlign: "center", fontSize: 16, color: colors.gunmetal() }}>
          We have successfully validated your email! {!isTablet && <br />}
          Please enter some basic information about yourself to create your super-admin account.
        </Text>

        <S.Separator height={70} />
        <Input
          topLabel="First Name"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          ref={firstNameRef}
          errorMessage={errors?.firstName?.message}
          onEnter={() => lastNameRef?.current?.focus()}
          name="firstName"
        />
        <S.Separator height={40} />
        <Input
          topLabel="Last Name"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          ref={lastNameRef}
          errorMessage={errors?.lastName?.message}
          name="lastName"
          onEnter={() => departmentRef?.current?.focus()}
        />
        <S.Separator height={40} />
        <ControlledSelect
          items={DEPARTMENTS_LIST}
          label="What department you work in?"
          control={control}
          sx={{ width: "100%", minWidth: "100%" }}
          name="department"
          ref={departmentRef}
        />
        <S.Separator height={57} />
        <PrimaryButton
          label="Continue"
          onClick={handleSubmit(onSubmit)}
          isLoading={inProgress}
          sx={{ width: "100%", minWidth: "100%" }}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default EmailValidatedSuperAdmin;
