import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { VOLUNTEER_EVENTS_PER_PAGE } from "utils/constants";

import * as C from "./company-volunteer-events.constants";
import * as T from "./company-volunteer-events.type";

export const companyVolunteerEventsInitial: T.ICompanyVolunteerEventsState = {
  data: [],
  inProgress: false,
  isFetched: false,
  isCached: false,
  isEndReached: false,
  page: 0,
  results: 0,
  error: null,
  dto: null,
  filters: {
    grant: null,
    city: "",
    state: null,
    groupSize: [],
    location: [],
  },
};

const companyVolunteerEventsReducer = (
  state: T.ICompanyVolunteerEventsState,
  action: T.CompanyVolunteerEventsAction | T.RefreshCompanyVolunteerEventsAction | ILogout,
): T.ICompanyVolunteerEventsState => {
  if (!state) {
    return companyVolunteerEventsInitial;
  }

  switch (action.type) {
    case C.FETCH_COMPANY_VOLUNTEER_EVENTS:
      return {
        ...state,
        inProgress: true,
      };
    case C.FETCH_COMPANY_VOLUNTEER_EVENTS_SUCCESS: {
      const newData = action.payload.data;
      const existingData = state.data || [];
      const combinedData = existingData.concat(newData);
      const combinedResults = combinedData.length;

      return {
        ...state,
        data: combinedData,
        page: action.payload.page,
        results: state.results ?? 0 + combinedResults,
        inProgress: false,
        isFetched: true,
        isEndReached: newData.length < VOLUNTEER_EVENTS_PER_PAGE || newData.length === 0,
      };
    }
    case C.FETCH_COMPANY_VOLUNTEER_EVENTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case C.REFRESH_COMPANY_VOLUNTEER_EVENTS:
      return {
        ...state,
        inProgress: true,
      };
    case C.REFRESH_COMPANY_VOLUNTEER_EVENTS_SUCCESS: {
      const newData = action.payload.data;
      return {
        ...state,
        ...action.payload,
        inProgress: false,
        isEndReached: newData.length < VOLUNTEER_EVENTS_PER_PAGE || newData.length === 0,
      };
    }
    case C.REFRESH_COMPANY_VOLUNTEER_EVENTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case C.SET_VOLUNTEER_EVENT_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case C.SAVE_VOLUNTEER_EVENT_DTO:
      return {
        ...state,
        dto: {
          ...state.dto,
          ...action.payload,
        },
      };
    case LOGOUT:
      return companyVolunteerEventsInitial;
    default:
      return state;
  }
};

export default companyVolunteerEventsReducer;
