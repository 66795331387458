import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/buttons/primary";
import FixedHeader from "components/fixed-header";
import Input from "components/input";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { checkCompanyExists } from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import { checkIntoVolunteerScheduledEvent } from "store/event-check-in/event-check-in.action";
import { showFullScreenLoader } from "store/ui-components/ui-components.actions";
import { EMAIL_REG } from "utils/constants";
import * as yup from "yup";

import * as S from "./check-in.styled";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REG, "E-mail is not valid.")
    .required("Please fill in all data."),
});

const CheckInPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const params = useParams();

  const isMobile = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const { companyName, charityName, eventName, id } = params;
  const logo = useSelector(companySelectors.selectLogoUrl);

  React.useEffect(() => {
    if (companyName) {
      dispatch(showFullScreenLoader());
      dispatch(checkCompanyExists.request({ name: companyName }));
    }
  }, []);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ email }) => {
    if (id && email) {
      dispatch(checkIntoVolunteerScheduledEvent.request({ id, email, companyName }));
    }
  };

  return (
    <S.BubbleBackground justifyContent={isMobile ? "flex-start" : "space-between"} gridGap={16}>
      {!isMobile ? <FixedHeader name={companyName} /> : <S.Separator height={1} />}
      <S.FormWrapperWithPadding>
        <S.ColumnDisplay width="100%" gridGap={16}>
          {isMobile && (
            <S.RowDisplay>
              <LogoAndCompany
                mobileFontSize={14}
                companyName={companyName}
                showVersion={false}
                isFullWidth
              />
            </S.RowDisplay>
          )}
          <S.CheckInGradientHeading>
            {eventName.charAt(0).toUpperCase() + eventName.slice(1)} Volunteer Event with{" "}
            {charityName}
          </S.CheckInGradientHeading>
          {!isMobile && <S.ForText>for</S.ForText>}
          {logo && <S.CompanyLogo src={logo} />}
          <S.Separator height={isMobile ? 1 : 10} />
          <S.CheckInParagraph>Please enter your email to check-in to the event!</S.CheckInParagraph>
          <S.Separator height={isMobile ? 1 : 10} />
          <Input
            topLabel="E-mail"
            isRequired
            sx={{ width: "100%", minWidth: "100%" }}
            control={control}
            errorMessage={errors?.email?.message}
            name="email"
          />
          <S.Separator height={isMobile ? 0.5 : 50} />
          <PrimaryButton
            label="Check-in here!"
            onClick={handleSubmit(onSubmit)}
            sx={{ width: "100%", minWidth: "100%" }}
          />
        </S.ColumnDisplay>
        <S.Separator height={isMobile ? 0.5 : 20} />
      </S.FormWrapperWithPadding>
      {!isMobile && <LogoAndCompany companyName={companyName} showMargin />}
    </S.BubbleBackground>
  );
};

export default CheckInPage;
