import { XIcon } from "@primer/octicons-react";
import SecondaryButton from "components/buttons/secondary";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelPendingCompanyVolunteerEvent } from "store/company-pending-volunteer-events/company-pending-volunteer-events.action";
import { respondToDonation } from "store/donations/donations.action";
import donationsSelectors from "store/donations/donations.selector";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import colors from "theme/colors";

import * as S from "../dialogs.styled";

const DIALOG_KEY = "CANCEL_EVENT";
const buttonProps = { width: "184px", height: "50px" };

const CancelEventDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, DIALOG_KEY),
  );
  const inProgress = useSelector((state: IAppState) => donationsSelectors.selectInProgress(state));

  const properties = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogProperties(state, DIALOG_KEY),
  );

  const closeDialog = (): void => {
    if (!inProgress) {
      dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
    }
  };

  const onApprove = (): void => {
    if (properties?.id) {
      dispatch(cancelPendingCompanyVolunteerEvent.request({ id: properties?.id }));
      dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
    }
  };

  return isOpened ? (
    <S.DialogBackground>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20}>
          <S.CloseIconContainer>
            <S.CloseIconButton type="button" onClick={closeDialog}>
              <XIcon />
            </S.CloseIconButton>
          </S.CloseIconContainer>
          <S.GradientHeading fontSize={28}>
            Are you sure you
            <br /> want to cancel this event?
          </S.GradientHeading>
          <S.Separator height={40} />
          <S.RowDisplay gridGap={16}>
            <SecondaryButton label="Yes" sx={buttonProps} onClick={onApprove} />
            <SecondaryButton
              label="No"
              sx={{ ...buttonProps, color: colors.bordo() }}
              onClick={closeDialog}
            />
          </S.RowDisplay>
          <S.Separator height={20} />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default CancelEventDialog;
