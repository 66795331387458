import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";

import advancedAnalyticsEpic from "./advanced-analytics/advanced-analytics.epic";
import advancedAnalyticsReducer from "./advanced-analytics/advanced-analytics.reducer";
import authEpic from "./auth/auth.epic";
import authReducer from "./auth/auth.reducer";
import charityListEpic from "./charities/charities.epic";
import charityListReducer from "./charities/charities.reducer";
import charitiesSearchEpic from "./charities-search/charities-search.epic";
import charitiesSearchReducer from "./charities-search/charities-search.reducer";
import charityEpic from "./charity/charity.epic";
import charityReducer from "./charity/charity.reducer";
import companyEpic from "./company/company.epic";
import companyReducer from "./company/company.reducer";
import companyActiveVolunteerEventsEpic from "./company-active-volunteer-events/company-active-volunteer-events.epic";
import companyActiveVolunteerEventsReducer from "./company-active-volunteer-events/company-active-volunteer-events.reducer";
import companyDonationsEpic from "./company-donations/company-donations.epic";
import companyDonationsReducer from "./company-donations/company-donations.reducer";
import companyPastVolunteerEventsEpic from "./company-past-volunteer-events/company-past-volunteer-events.epic";
import companyPastVolunteerEventsReducer from "./company-past-volunteer-events/company-past-volunteer-events.reducer";
import companyPendingVolunteerEventsEpic from "./company-pending-volunteer-events/company-pending-volunteer-events.epic";
import companyPendingVolunteerEventsReducer from "./company-pending-volunteer-events/company-pending-volunteer-events.reducer";
import companyVolunteerEventsEpic from "./company-volunteer-events/company-volunteer-events.epic";
import companyVolunteerEventsReducer from "./company-volunteer-events/company-volunteer-events.reducer";
import donationsEpic from "./donations/donations.epic";
import donationsReducer from "./donations/donations.reducer";
import employeeDashboardSummaryEpic from "./employee-dashboard-summary/employee-dashboard-summary.epic";
import employeeDashboardSummaryReducer from "./employee-dashboard-summary/employee-dashboard-summary.reducer";
import errorHandlerEpic from "./error-handler/error-handler.epic";
import { checkIntoVolunteerEventEpic } from "./event-check-in/event-check-in.epic";
import checkIntoVolunteerEventReducer from "./event-check-in/event-check-in.reducer";
import everyOrgEpic from "./every-org/every-org.epic";
import everyOrgReducer from "./every-org/every-org.reducer";
import everyOrgSearchEpic from "./every-org-search/every-org-search.epic";
import everyOrgSearchReducer from "./every-org-search/every-org-search.reducer";
import finchEpic from "./finch/finch.epic";
import finchReducer from "./finch/finch.reducer";
import giftMatchEpic from "./gift-matching/gift-matching.epic";
import giftMatchReducer from "./gift-matching/gift-matching.reducer";
import managePeopleEpic from "./manage-people/manage-people.epic";
import managePeopleReducer from "./manage-people/manage-people.reducer";
import paymentEpic from "./payment/payment.epic";
import paymentReducer from "./payment/payment.reducer";
import registrationReducer from "./registration/registration.reducer";
import uiComponentsReducer from "./ui-components/ui-components.reducer";
import userEpic from "./user/user.epic";
import userReducer from "./user/user.reducer";
import userDonationsEpic from "./user-donations/user-donations.epic";
import userDonationsReducer from "./user-donations/user-donations.reducer";
import volunteerAttendeesEpic from "./volunteer-attendees/volunteer-attendees.epic";
import volunteerAttendeesReducer from "./volunteer-attendees/volunteer-attendees.reducer";
import volunteerEventDetailsEpic from "./volunteer-event-details/volunteer-event-details.epic";
import volunteerEventDetailsReducer from "./volunteer-event-details/volunteer-event-details.reducer";
import volunteerChartyEpic from "./volunteer-events-charity/volunteer-events-charity.epic";
import volunteerCharityReducer from "./volunteer-events-charity/volunteer-events-charity.reducer";
import volunteerScheduledEpic from "./volunteer-scheduled/volunteer-scheduled.epic";
import volunteerScheduledReducer from "./volunteer-scheduled/volunteer-scheduled.reducer";

export const rootReducer = combineReducers({
  advancedAnalytics: advancedAnalyticsReducer,
  auth: authReducer,
  registration: registrationReducer,
  user: userReducer,
  uiComponents: uiComponentsReducer,
  company: companyReducer,
  charity: charityReducer,
  charityList: charityListReducer,
  payment: paymentReducer,
  managePeople: managePeopleReducer,
  charitiesSearch: charitiesSearchReducer,
  giftMatch: giftMatchReducer,
  donations: donationsReducer,
  userDonations: userDonationsReducer,
  employeeDashboardSummary: employeeDashboardSummaryReducer,
  companyDonations: companyDonationsReducer,
  everyOrg: everyOrgReducer,
  everyOrgSearch: everyOrgSearchReducer,
  finch: finchReducer,
  volunteerCharity: volunteerCharityReducer,
  volunteerScheduled: volunteerScheduledReducer,
  volunteerAttendees: volunteerAttendeesReducer,
  volunteerEventDetails: volunteerEventDetailsReducer,
  companyVolunteerEvents: companyVolunteerEventsReducer,
  companyActiveVolunteerEvents: companyActiveVolunteerEventsReducer,
  companyPastVolunteerEvents: companyPastVolunteerEventsReducer,
  companyPendingVolunteerEvents: companyPendingVolunteerEventsReducer,
  checkIntoVolunteerEvent: checkIntoVolunteerEventReducer,
});

export const rootEpic = combineEpics(
  authEpic,
  companyEpic,
  charityEpic,
  userEpic,
  errorHandlerEpic,
  charityListEpic,
  paymentEpic,
  managePeopleEpic,
  charitiesSearchEpic,
  giftMatchEpic,
  donationsEpic,
  userDonationsEpic,
  employeeDashboardSummaryEpic,
  companyDonationsEpic,
  everyOrgEpic,
  everyOrgSearchEpic,
  finchEpic,
  volunteerChartyEpic,
  volunteerScheduledEpic,
  volunteerAttendeesEpic,
  volunteerEventDetailsEpic,
  companyVolunteerEventsEpic,
  companyActiveVolunteerEventsEpic,
  companyPastVolunteerEventsEpic,
  companyPendingVolunteerEventsEpic,
  checkIntoVolunteerEventEpic,
  advancedAnalyticsEpic,
);
