import { IVolunteerEventDetailsSelectors } from "./volunteer-event-details.type";

const volunteerEventDetailsSelectors: IVolunteerEventDetailsSelectors = {
  selectData: (state) => state.volunteerEventDetails.data,
  selectInProgress: (state) => state.volunteerEventDetails.inProgress,
  selectIsFetched: (state) => state.volunteerEventDetails.isFetched,
  selectError: (state) => state.volunteerEventDetails.error,
};

export default volunteerEventDetailsSelectors;
