import * as C from "./company.constants";
import * as T from "./company.type";

export const registerCompany = {
  request: (payload: T.IRegisterCompanyRequest["payload"]): T.IRegisterCompanyRequest => ({
    type: C.REGISTER_COMPANY,
    payload,
  }),
  success: (payload: T.IRegisterCompanySuccess["payload"]): T.IRegisterCompanySuccess => ({
    type: C.REGISTER_COMPANY_SUCCESS,
    payload,
  }),
  failure: (payload: T.IRegisterCompanyFailure["payload"]): T.IRegisterCompanyFailure => ({
    type: C.REGISTER_COMPANY_FAILURE,
    payload,
  }),
};

export const registerEmployee = {
  request: (payload: T.IRegisterEmployeeRequest["payload"]): T.IRegisterEmployeeRequest => ({
    type: C.REGISTER_EMPLOYEE,
    payload,
  }),
  success: (payload: T.IRegisterEmployeeSuccess["payload"]): T.IRegisterEmployeeSuccess => ({
    type: C.REGISTER_EMPLOYEE_SUCCESS,
    payload,
  }),
  failure: (payload: T.IRegisterEmployeeFailure["payload"]): T.IRegisterEmployeeFailure => ({
    type: C.REGISTER_EMPLOYEE_FAILURE,
    payload,
  }),
};

export const confirmRegistration = {
  request: (payload: T.IConfirmRegistrationRequest["payload"]): T.IConfirmRegistrationRequest => ({
    type: C.CONFIRM_REGISTRATION,
    payload,
  }),
  success: (payload: T.IConfirmRegistrationSuccess["payload"]): T.IConfirmRegistrationSuccess => ({
    type: C.CONFIRM_REGISTRATION_SUCCESS,
    payload,
  }),
  failure: (): T.IConfirmRegistrationFailure => ({
    type: C.CONFIRM_REGISTRATION_FAILURE,
  }),
};

export const saveRegistrationDTO = (
  payload: T.ISaveRegistrationDto["payload"],
): T.ISaveRegistrationDto => ({
  payload,
  type: C.SAVE_REGISTRATION_DTO,
});

export const resetRegistrationDTO = (): T.IResetRegistrationDto => ({
  type: C.RESET_REGISTRATION_DTO,
});

export const checkCompanyInfo = {
  request: (payload: T.ICheckCompanyInfoRequest["payload"]): T.ICheckCompanyInfoRequest => ({
    type: C.CHECK_COMPANY_INFO,
    payload,
  }),
  success: (payload: T.ICheckCompanyInfoSuccess["payload"]): T.ICheckCompanyInfoSuccess => ({
    type: C.CHECK_COMPANY_INFO_SUCCESS,
    payload,
  }),
  failure: (): T.ICheckCompanyInfoFailure => ({
    type: C.CHECK_COMPANY_INFO_FAILURE,
  }),
};

export const checkCompanyExists = {
  request: (payload: T.ICheckCompanyExistsRequest["payload"]): T.ICheckCompanyExistsRequest => ({
    type: C.CHECK_COMPANY_EXISTS,
    payload,
  }),
  success: (payload: T.ICheckCompanyExistsSuccess["payload"]): T.ICheckCompanyExistsSuccess => ({
    type: C.CHECK_COMPANY_EXISTS_SUCCESS,
    payload,
  }),
  failure: (): T.ICheckCompanyExistsFailure => ({
    type: C.CHECK_COMPANY_EXISTS_FAILURE,
  }),
};

export const getCompanyById = {
  request: (payload: T.IGetCompanyByIdRequest["payload"]): T.IGetCompanyByIdRequest => ({
    type: C.GET_COMPANY_BY_ID,
    payload,
  }),
  success: (payload: T.IGetCompanyByIdSuccess["payload"]): T.IGetCompanyByIdSuccess => ({
    type: C.GET_COMPANY_BY_ID_SUCCESS,
    payload,
  }),
  failure: (): T.IGetCompanyByIdFailure => ({
    type: C.GET_COMPANY_BY_ID_FAILURE,
  }),
};

export const getDashboardSummary = {
  request: (payload?: T.IGetDashboardSummary["payload"]): T.IGetDashboardSummary => ({
    type: C.GET_DASHBOARD_SUMMARY,
    payload,
  }),
  success: (payload: T.IGetDashboardSummarySuccess["payload"]): T.IGetDashboardSummarySuccess => ({
    type: C.GET_DASHBOARD_SUMMARY_SUCCESS,
    payload,
  }),
  failure: (): T.IGetDashboardSummaryFailure => ({
    type: C.GET_DASHBOARD_SUMMARY_FAILURE,
  }),
};

export const getAnalyticDashboardSummary = {
  request: (
    payload?: T.IGetAnalyticDashboardSummary["payload"],
  ): T.IGetAnalyticDashboardSummary => ({
    type: C.GET_ANALYTIC_DASHBOARD_SUMMARY,
    payload,
  }),
  success: (
    payload: T.IGetAnalyticDashboardSummarySuccess["payload"],
  ): T.IGetAnalyticDashboardSummarySuccess => ({
    type: C.GET_ANALYTIC_DASHBOARD_SUMMARY_SUCCESS,
    payload,
  }),
  failure: (): T.IGetAnalyticDashboardSummaryFailure => ({
    type: C.GET_ANALYTIC_DASHBOARD_SUMMARY_FAILURE,
  }),
};

export const getEmployeeInterests = {
  request: (): T.IGetEmployeeInterests => ({
    type: C.GET_EMPLOYEE_INTERESTS,
  }),
  success: (
    payload: T.IGetEmployeeInterestsSuccess["payload"],
  ): T.IGetEmployeeInterestsSuccess => ({
    type: C.GET_EMPLOYEE_INTERESTS_SUCCESS,
    payload,
  }),
  failure: (): T.IGetEmployeeInterestsFailure => ({
    type: C.GET_EMPLOYEE_INTERESTS_FAILURE,
  }),
};

export const getCharitiesSupported = {
  request: (payload?: T.IGetCharitiesSupported["payload"]): T.IGetCharitiesSupported => ({
    type: C.GET_CHARITIES_SUPPORTED,
    payload,
  }),
  success: (
    payload: T.IGetCharitiesSupportedSuccess["payload"],
  ): T.IGetCharitiesSupportedSuccess => ({
    type: C.GET_CHARITIES_SUPPORTED_SUCCESS,
    payload,
  }),
  failure: (): T.IGetCharitiesSupportedFailure => ({
    type: C.GET_CHARITIES_SUPPORTED_FAILURE,
  }),
};

export const getDonationsAnalytics = {
  request: (payload?: T.IGetDonationsAnalytics["payload"]): T.IGetDonationsAnalytics => ({
    type: C.GET_DONATION_ANALYTICS,
    payload,
  }),
  success: (
    payload: T.IGetDonationsAnalyticsSuccess["payload"],
  ): T.IGetDonationsAnalyticsSuccess => ({
    type: C.GET_DONATION_ANALYTICS_SUCCESS,
    payload,
  }),
  failure: (): T.IGetDonationsAnalyticsFailure => ({
    type: C.GET_DONATION_ANALYTICS_FAILURE,
  }),
};

export const getGiftMatchingAnalytics = {
  request: (payload?: T.IGetGiftMatchingAnalytics["payload"]): T.IGetGiftMatchingAnalytics => ({
    type: C.GET_GIFT_MATCHING_ANALYTICS,
    payload,
  }),
  success: (
    payload: T.IGetGiftMatchingAnalyticsSuccess["payload"],
  ): T.IGetGiftMatchingAnalyticsSuccess => ({
    type: C.GET_GIFT_MATCHING_ANALYTICS_SUCCESS,
    payload,
  }),
  failure: (): T.IGetGiftMatchingAnalyticsFailure => ({
    type: C.GET_GIFT_MATCHING_ANALYTICS_FAILURE,
  }),
};

export const getVolunteeringAnalytics = {
  request: (payload?: T.IGetVolunteeringAnalytics["payload"]): T.IGetVolunteeringAnalytics => ({
    type: C.GET_VOLUNTEERING_ANALYTICS,
    payload,
  }),
  success: (
    payload: T.IGetVolunteeringAnalyticsSuccess["payload"],
  ): T.IGetVolunteeringAnalyticsSuccess => ({
    type: C.GET_VOLUNTEERING_ANALYTICS_SUCCESS,
    payload,
  }),
  failure: (): T.IGetVolunteeringAnalyticsFailure => ({
    type: C.GET_VOLUNTEERING_ANALYTICS_FAILURE,
  }),
};

export const updateCompanyLogo = {
  request: (payload: T.IUpdateCompanyLogo["payload"]): T.IUpdateCompanyLogo => ({
    type: C.UPDATE_COMPANY_LOGO,
    payload,
  }),
  success: (payload: T.IUpdateCompanyLogoSuccess["payload"]): T.IUpdateCompanyLogoSuccess => ({
    type: C.UPDATE_COMPANY_LOGO_SUCCESS,
    payload,
  }),
  failure: (): T.IUpdateCompanyLogoFailure => ({
    type: C.UPDATE_COMPANY_LOGO_FAILURE,
  }),
};

export const deleteCompanyLogo = {
  request: (): T.IDeleteCompanyLogo => ({
    type: C.DELETE_COMPANY_LOGO,
  }),
  success: (): T.IDeleteCompanyLogoSuccess => ({
    type: C.DELETE_COMPANY_LOGO_SUCCESS,
  }),
  failure: (): T.IDeleteCompanyLogoFailure => ({
    type: C.DELETE_COMPANY_LOGO_FAILURE,
  }),
};

export const confirmFinchRegistration = {
  request: (payload: T.IConfirmFinchRegiatration["payload"]): T.IConfirmFinchRegiatration => ({
    type: C.CONFIRM_FINCH_REGISTRATION,
    payload,
  }),
  success: (): T.IConfirmFinchRegiatrationSuccess => ({
    type: C.CONFIRM_FINCH_REGISTRATION_SUCCESS,
  }),
  failure: (): T.IConfirmFinchRegiatrationFailure => ({
    type: C.CONFIRM_FINCH_REGISTRATION_FAILURE,
  }),
};
