import PrimaryButton from "components/buttons/primary";
import SecondaryButton from "components/buttons/secondary";
import { CharityIcon } from "icons";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCharityList, removeFromCharityList } from "store/charities/charities.action";
import colors from "theme/colors";
import { Charity } from "types/charity";
import { genRandomString, verifyImg } from "utils/helper";
import routes from "utils/routes";

import * as S from "../charity-card.styled";
import * as T from "../charity-card.type";

const CharityAdminCard: React.FC<Charity & T.ICharityCardProps> = ({
  id,
  location,
  address,
  lookingFor,
  name,
  backed,
  irsCategory,
  isBigCard = false,
  isEveryOrg = false,
  backFromHome,
  logoUrl,
  slug,
}): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [imgValid, setImgValid] = React.useState<boolean>(true);

  const onCardClick = (): void => {
    if (!isEveryOrg) {
      navigate(`${routes.CHARITY.DETAILS}/${id}`);
    } else {
      navigate(`${routes.EVERY_DOT_ORG.CHARITY_DETAILS}/${id || slug}`, {
        state: { backFromHome },
      });
    }
  };

  React.useEffect(() => {
    verifyImg(logoUrl, setImgValid);
  }, [logoUrl]);

  return (
    <S.CharityCardContainer onClick={onCardClick} isBigCard={isBigCard}>
      <S.CharityCardGradientContainer />
      <S.CharityCardInnerContainer
        alignItems="flex-start"
        justifyContent="space-between"
        isBigCard={isBigCard}
      >
        {logoUrl && imgValid ? <S.CharityImg src={logoUrl} alt="" /> : <CharityIcon />}
        <S.Separator height={12} />
        <S.CharityName>{name}</S.CharityName>
        <S.Separator height={30} />
        <S.Separator height={6} />
        <S.CharityAddress>{isEveryOrg ? location : address}</S.CharityAddress>
        <S.Separator height={30} />
        {!isEveryOrg && (
          <>
            <S.RowDisplay gridGap={7}>
              {(lookingFor ?? []).map((tag) => (
                <S.Tag key={genRandomString()}>{tag}</S.Tag>
              ))}
            </S.RowDisplay>
            <S.Separator height={28} />
          </>
        )}

        {!backed ? (
          <PrimaryButton
            label="Add to wall"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(addToCharityList.request({ id, irsCategory }));
            }}
            sx={{ maxWidth: "100%", width: "100%" }}
          />
        ) : (
          <SecondaryButton
            label="Remove"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(removeFromCharityList.request({ id, irsCategory }));
            }}
            sx={{
              maxWidth: "100%",
              width: "100%",
              color: colors.bordo(),
            }}
          />
        )}
      </S.CharityCardInnerContainer>
    </S.CharityCardContainer>
  );
};

export default CharityAdminCard;
