import { ArrowDownIcon } from "@primer/octicons-react";
import { AIcon, DateSortIcon, ZIcon } from "icons";
import React from "react";
import * as S from "theme/global-style";
import { Order } from "types/common";

interface IAnimatedIconsProps {
  order: Order;
}

export const LetterSortIndicator: React.FC<IAnimatedIconsProps> = ({ order }): JSX.Element => (
  <S.RowDisplay gridGap={1}>
    <S.ColumnDisplay gridGap={1}>
      <S.TranslatedElement translateY={order === "asc" ? 0 : 6.5}>
        <AIcon />
      </S.TranslatedElement>
      <S.TranslatedElement translateY={order === "asc" ? 0 : -6.5}>
        <ZIcon />
      </S.TranslatedElement>
    </S.ColumnDisplay>
    <ArrowDownIcon />
  </S.RowDisplay>
);

export const AmountSortIndicator: React.FC<IAnimatedIconsProps> = ({ order }): JSX.Element => (
  <S.RowDisplay gridGap={1} style={{ transform: `rotate(${order === "asc" ? "0deg" : "180deg"})` }}>
    <DateSortIcon />
  </S.RowDisplay>
);
