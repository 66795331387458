import { MOBILE_SMALL_BREAKPOINT } from "components/fixed-header/fixed-header.style";
import styled from "styled-components";
import {
  GradientHeading,
  TABLET_BREAKPOINT,
  TABLET_L_BREAKPOINT,
} from "templates/charity-auth-form/charity-auth-form.styled";
import { FormWrapper } from "theme/global-style";

export { MOBILE_SMALL_BREAKPOINT, TABLET_BREAKPOINT };

export {
  BubbleBackground,
  ColumnDisplay,
  FormWrapper,
  GradientHeading,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const FormWrapperWithPadding = styled(FormWrapper)`
  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding-top: 22px;
  }
`;

export const CheckInGradientHeading = styled(GradientHeading)`
  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
    margin: 0;
  }
`;

export const DonationsCompleteText = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${({ theme }) => theme.metallicSilver()};
`;

export const CompanyLogo = styled.img`
  width: 95px;
  height: auto;
  object-fit: cover;

  @media all and (max-width: ${TABLET_L_BREAKPOINT}px) {
    width: 80px;
    height: auto;
  }
`;

export const CheckInParagraph = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.gunmetal()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 248px;
  }
`;

export const ForText = styled.span`
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  font-style: italic;

  margin-bottom: 10px;
`;
