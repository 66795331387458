import styled from "styled-components";
import { FormWrapper } from "theme/global-style";

export {
  BubbleBackground,
  ColumnDisplay,
  FormWrapper,
  GradientHeading,
  RowDisplay,
  Separator,
  TagContainer,
  TagText,
} from "theme/global-style";

export const InterestsFormContainer = styled(FormWrapper)`
  width: 80%;
  max-width: 1412px;
`;

export const InterestContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
`;

export const DialogLabelWarning = styled.div<{ fontSize?: number }>`
  font-weight: 400;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.warningRed()};
`;

export const SkipText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.unitedNationsBlue()};

  text-decoration: underline;
  cursor: pointer;
`;
