import * as C from "./user.constants";
import * as T from "./user.type";

export const fetchUser = {
  request: (): T.IFetchUser => ({
    type: C.FETCH_USER,
  }),
  success: (payload: T.IFetchUserSuccess["payload"]): T.IFetchUserSuccess => ({
    type: C.FETCH_USER_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchUserFailure => ({
    type: C.FETCH_USER_FAILURE,
  }),
};

export const verifyEmail = {
  request: (payload: T.IVerifyEmail["payload"]): T.IVerifyEmail => ({
    type: C.VERIFY_EMAIL,
    payload,
  }),
  success: (payload: T.IVerifyEmailSuccess["payload"]): T.IVerifyEmailSuccess => ({
    type: C.VERIFY_EMAIL_SUCCESS,
    payload,
  }),
  failure: (): T.IVerifyEmailFailure => ({
    type: C.VERIFY_EMAIL_FAILURE,
  }),
};

export const editEmployeeDetails = {
  request: (payload: T.IEditEmployeeDetails["payload"]): T.IEditEmployeeDetails => ({
    type: C.EDIT_EMPLOYEE_DETAILS,
    payload,
  }),
  success: (): T.IEditEmployeeDetailsSuccess => ({
    type: C.EDIT_EMPLOYEE_DETAILS_SUCCESS,
  }),
  failure: (): T.IEditEmployeeDetailsFailure => ({
    type: C.EDIT_EMPLOYEE_DETAILS_FAILURE,
  }),
};

export const updateInterests = {
  request: (payload: T.IUpdateInterests["payload"]): T.IUpdateInterests => ({
    type: C.UPDATE_INTERESTS,
    payload,
  }),
  success: (payload: T.IUpdateInterests["payload"]): T.IUpdateInterestsSuccess => ({
    type: C.UPDATE_INTERESTS_SUCCESS,
    payload,
  }),
  failure: (): T.IUpdateInterestsFailure => ({
    type: C.UPDATE_INTERESTS_FAILURE,
  }),
};

export const toggleGiftMatchNotification = {
  request: (
    payload: T.IToggleGiftMatchNotification["payload"],
  ): T.IToggleGiftMatchNotification => ({
    type: C.TOGGLE_GIFT_MATCH_NOTIFICATION,
    payload,
  }),
  success: (
    payload: T.IToggleGiftMatchNotificationSuccess["payload"],
  ): T.IToggleGiftMatchNotificationSuccess => ({
    type: C.TOGGLE_GIFT_MATCH_NOTIFICATION_SUCCESS,
    payload,
  }),
  failure: (): T.IToggleGiftMatchNotificationFailure => ({
    type: C.TOGGLE_GIFT_MATCH_NOTIFICATION_FAILURE,
  }),
};
