import * as api from "api/auth";
import { combineEpics, ofType } from "redux-observable";
import CustomHistory from "router/history";
import { from, Observable, of } from "rxjs";
import { catchError, exhaustMap, mergeMap } from "rxjs/operators";
import companySelectors from "store/company/company.selector";
import { handleError } from "store/error-handler/error-handler.action";
import { storeEmployeeDTO } from "store/registration/registration.action";
import { AppEpic } from "store/store.state";
import {
  hideFullScreenLoader,
  showFullScreenLoader,
  showToast,
} from "store/ui-components/ui-components.actions";
import { fetchUser } from "store/user/user.action";
import { getErrorCode, getErrorMessage, uriEncode } from "utils/helper";
import routes from "utils/routes";
import { ACCEPTED } from "utils/status-code";
import storageKeys from "utils/storage-keys";

import {
  resendCode,
  resendVerificationEmail,
  resetPassword,
  sendCodeEmployee,
  setNewPassword,
  signInCode,
  signInCredentials,
} from "./auth.action";
import * as C from "./auth.constants";
import authSelectors from "./auth.selector";
import * as T from "./auth.type";

const logoutEpic: AppEpic = (action$: Observable<T.ILogout>, state$) =>
  action$.pipe(
    ofType(C.LOGOUT),
    mergeMap(() => {
      const role = authSelectors.selectRole(state$.value);
      const companyName = companySelectors.selectName(state$.value);
      if (role === "admin") {
        CustomHistory.navigate(`/${uriEncode(companyName)}/${routes.COMPANY.LOGIN}`);
      } else if (role === "charity") {
        CustomHistory.navigate(routes.CHARITY.LOGIN);
      } else {
        CustomHistory.navigate(`/${uriEncode(companyName)}/${routes.EMPLOYEE.LOGIN}`);
      }
      return of(signInCredentials.failure());
    }),
  );

const signInCredentialsEpic: AppEpic = (action$: Observable<T.ISignInCredentials>) =>
  action$.pipe(
    ofType(C.SIGN_IN_CREDENTIALS),
    mergeMap((action) =>
      from(api.signInCredentials(action.payload)).pipe(
        mergeMap((response) => {
          if (response.data) {
            const token = response.data.data.accessToken;
            const role = response.data.data.role === "CHARITY_ADMIN" ? "charity" : "admin";
            localStorage.setItem(storageKeys.AUTH_TOKEN, token);
            localStorage.setItem(storageKeys.USER_ROLE, role);
            return of(signInCredentials.success({ token, role }), showFullScreenLoader());
          }
          return of(signInCredentials.failure());
        }),
        catchError((error) => of(signInCredentials.failure(), handleError({ action, error }))),
      ),
    ),
  );

const signInCodeEpic: AppEpic = (action$: Observable<T.ISignInCode>) =>
  action$.pipe(
    ofType(C.SIGN_IN_CODE),
    mergeMap((action) =>
      from(api.signInCode(action.payload)).pipe(
        exhaustMap((response) => {
          if (response.data) {
            localStorage.setItem(storageKeys.AUTH_TOKEN, response.data.data);
            localStorage.setItem(storageKeys.USER_ROLE, "employee");
            return of(
              showFullScreenLoader(),
              signInCode.success({ token: response.data.data, role: "employee" }),
              fetchUser.request(),
              hideFullScreenLoader(),
            );
          }
          return of(signInCode.failure());
        }),
        catchError((error) => of(signInCode.failure(), handleError({ action, error }))),
      ),
    ),
  );

const sendCodeEmployeeEpic: AppEpic = (action$: Observable<T.ISendCodeEmployee>) =>
  action$.pipe(
    ofType(C.SEND_CODE_EMPLOYEE),
    mergeMap((action) =>
      from(api.sendCodeEmployee(action.payload.companyName, action.payload)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            CustomHistory.navigate(
              `/${action.payload.companyName}${routes.EMPLOYEE.EMAIL_VERIFICATION}`,
            );
            return of(
              storeEmployeeDTO({ email: action.payload.email }),
              sendCodeEmployee.success(response?.data),
            );
          }
          return of(sendCodeEmployee.failure());
        }),
        catchError((error) => of(sendCodeEmployee.failure(), handleError({ action, error }))),
      ),
    ),
  );

const resetPasswordEpic: AppEpic = (action$: Observable<T.IResetPassword>) =>
  action$.pipe(
    ofType(C.RESET_PASSWORD),
    mergeMap((action) =>
      from(api.resetPassword(action.payload.email)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            CustomHistory.navigate(routes.COMPANY.RECOVER_PASSWORD_VALIDATION);
            return of(resetPassword.success());
          }
          return of(resetPassword.failure());
        }),
        catchError((error) => of(resetPassword.failure(), handleError({ action, error }))),
      ),
    ),
  );

const setNewPasswordEpic: AppEpic = (action$: Observable<T.ISetNewPassword>, state$) =>
  action$.pipe(
    ofType(C.SET_NEW_PASSWORD),
    mergeMap((action) =>
      from(api.setNewPassword(action.payload)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            if (action.payload.flow === "charity") {
              CustomHistory.navigate(routes.CHARITY.LOGIN);
            } else {
              const companyName = companySelectors.selectName(state$.value);
              if (companyName) {
                CustomHistory.navigate(`/${uriEncode(companyName)}/${routes.COMPANY.LOGIN}`);
              }
            }

            return of(
              setNewPassword.success(),
              showToast({ message: "Password was updated successfully.", type: "success" }),
            );
          }
          return of(setNewPassword.failure());
        }),
        catchError((error) => of(setNewPassword.failure(), handleError({ action, error }))),
      ),
    ),
  );

const resendCodeEpic: AppEpic = (action$: Observable<T.IResendCode>) =>
  action$.pipe(
    ofType(C.RESEND_CODE),
    mergeMap((action) =>
      from(api.resendCode(action.payload.email)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(
              resendCode.success(),
              showToast({ message: "New code is sent to your e-mail!", type: "success" }),
            );
          }
          return of(resendCode.failure());
        }),
        catchError((error) => {
          if (getErrorCode(error) === "AUTHENTICATION_BLOCKED") {
            return of(
              resendCode.failure(),
              showToast({ message: getErrorMessage(error), type: "failure" }),
            );
          }
          return of(resendCode.failure(), handleError({ action, error }));
        }),
      ),
    ),
  );

const resendVerificationCodeEpic: AppEpic = (action$: Observable<T.IResendVerificationLink>) =>
  action$.pipe(
    ofType(C.RESEND_VERIFICATION_EMAIL),
    mergeMap((action) =>
      from(api.resendVerificationEmail(action.payload.email)).pipe(
        mergeMap((response) => {
          if (response === ACCEPTED) {
            return of(
              resendVerificationEmail.success(),
              showToast({ message: "New e-mail has been sent.", type: "success" }),
            );
          }
          return of(resendCode.failure());
        }),
        catchError((error) => {
          if (getErrorCode(error) === "AUTHENTICATION_BLOCKED") {
            return of(
              resendVerificationEmail.failure(),
              showToast({ message: getErrorMessage(error), type: "failure" }),
            );
          }
          return of(resendVerificationEmail.failure(), handleError({ action, error }));
        }),
      ),
    ),
  );

export default combineEpics(
  signInCredentialsEpic,
  sendCodeEmployeeEpic,
  signInCodeEpic,
  resetPasswordEpic,
  setNewPasswordEpic,
  resendCodeEpic,
  logoutEpic,
  resendVerificationCodeEpic,
);
