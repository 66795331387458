import LogoAndCompany from "components/logo-and-company";
import NavigationProgressTracker from "components/navigation-progress-tracker";
import React from "react";
import colors from "theme/colors";
import { CHARITY_REGISTRATION_PAGES } from "utils/content";

import * as S from "../charity-auth.styled";

const CharityVerifiedPage: React.FC<{}> = (): JSX.Element => (
  <S.BubbleBackground justifyContent="space-between" gridGap={16}>
    <NavigationProgressTracker items={CHARITY_REGISTRATION_PAGES} activeItemIndex={1} />
    <S.FormWrapper>
      <S.GradientHeading>Email Verification Required</S.GradientHeading>
      <S.Separator height={40} />
      <S.Paragraph color={colors.gunmetal()} fontSize={16} lineHeight={24}>
        We’ve sent you a verification e-mail!
        <br />
        Please check your inbox and follow the instructions.
      </S.Paragraph>
      <S.Separator height={40} />
      <S.FadedBlueArea>
        <S.HeadingBlackThin>Charity verified</S.HeadingBlackThin>
        <S.Separator height={18} />
        <S.ParagraphThin>
          The charity was verified using <br /> Charitycheck and the current score is:
        </S.ParagraphThin>
        <S.Separator height={46} />
        <S.BlueText gridGap={12}>
          <div>In good standing </div>
          <div style={{ fontSize: 28 }}>✅</div>
        </S.BlueText>
      </S.FadedBlueArea>
      <S.Separator height={24} />
      <S.Paragraph>
        To continue <b>check your inbox</b> for verification e-mail.
      </S.Paragraph>
      <S.Separator height={24} />
    </S.FormWrapper>
    <LogoAndCompany companyName={null} showMargin />
  </S.BubbleBackground>
);

export default CharityVerifiedPage;
