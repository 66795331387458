import React from "react";
import { useParams } from "react-router-dom";
import CharityDetailsTemplate from "templates/charity-details";

const CharityDetailsPage: React.FC<{}> = (): JSX.Element => {
  const params = useParams();

  const { charityId } = params;

  return <CharityDetailsTemplate charityId={charityId} />;
};

export default CharityDetailsPage;
