import { ArrowLeftIcon } from "@primer/octicons-react";
import { Spinner } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import EveryDotOrgSearchInput from "components/everydotorg-search";
import FixedHeader from "components/fixed-header";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import companySelectors from "store/company/company.selector";
import { getEveryOrgCharities } from "store/every-org/every-org.action";
import everyOrgSelectors from "store/every-org/every-org.selector";
import { EVERY_DOT_ORG_CATEGORIES } from "utils/content";
import { capitalizeFirstLetter, uriDecode } from "utils/helper";
import { mapEveryCharityToCharity } from "utils/mapper";
import routes from "utils/routes";
import VerticalCharitySection from "views/charity-grid/components/vertical-charity-section";

import * as S from "./browse.styled";

const EveryDotOrgBrowseCharities: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const companyName = useSelector(companySelectors.selectName);

  const inProgress = useSelector(everyOrgSelectors.selectInProgress);
  const isEndReached = useSelector(everyOrgSelectors.selectIsEndReached);
  const isFetched = useSelector(everyOrgSelectors.selectIsFetched);
  const charities = useSelector(everyOrgSelectors.selectData);
  const cause = useSelector(everyOrgSelectors.selectSubCategory);
  const page = useSelector(everyOrgSelectors.selectPage);

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const { category, subcategory } = params;
  const categoryDecoded = uriDecode(category);
  const subcategoryDecoded = uriDecode(subcategory);

  const categoryInfo = EVERY_DOT_ORG_CATEGORIES[categoryDecoded]?.find(
    (item) => item.alias === subcategoryDecoded?.toLowerCase(),
  );

  React.useEffect(() => {
    if (cause !== subcategory) {
      dispatch(getEveryOrgCharities.request({ page: 1, cause: subcategory }));
    }
  }, [subcategory, cause]);

  return (
    <S.CategoriesContainer justifyContent="flex-start">
      <S.TopContainer alignItems="flex-start" justifyContent="flex-start">
        <FixedHeader name={companyName} />
        <S.Separator height={40} />
        <S.CenteredCategoriesContainer
          alignItems="flex-start"
          gridGap={40}
          justifyContent="flex-start"
        >
          <S.GradientHeadingStyled fontSize={32}>
            Find Nonprofits to support
          </S.GradientHeadingStyled>
          <EveryDotOrgSearchInput />
        </S.CenteredCategoriesContainer>
      </S.TopContainer>
      <S.ColumnDisplay alignItems="flex-start" width="100%">
        <S.Separator height={60} />

        <S.CenteredCategoriesContainer alignItems="flex-start" width="100%">
          {categoryInfo ? (
            <>
              <S.RowDisplay gridGap={20} flexWrap>
                <S.GradientHeadingStyled fontSize={45}>
                  {capitalizeFirstLetter(categoryDecoded)}
                </S.GradientHeadingStyled>
                <S.CategoryContainer>
                  <S.CategoryIcon>{categoryInfo.icon}</S.CategoryIcon>{" "}
                  <S.CategoryText>{categoryInfo.text}</S.CategoryText>
                </S.CategoryContainer>
              </S.RowDisplay>

              <S.Separator height={70} />
              <S.ClickableWrapper onClick={() => navigate(routes.EVERY_DOT_ORG.CATEGORIES)}>
                <S.RowDisplay gridGap={18}>
                  <ArrowLeftIcon size={25} />
                  <S.BackText>Back</S.BackText>
                </S.RowDisplay>
              </S.ClickableWrapper>
              <S.Separator height={30} />
            </>
          ) : (
            <S.ColumnDisplay width="100%" height={300} gridGap={20}>
              <S.NoMatchesHeading>No charities found</S.NoMatchesHeading>
              <S.NoMatchesSubheading>
                Please try searching for a different category
              </S.NoMatchesSubheading>
            </S.ColumnDisplay>
          )}
        </S.CenteredCategoriesContainer>
        {inProgress && (
          <S.ColumnDisplay width="100%" height={400}>
            <Spinner />
          </S.ColumnDisplay>
        )}
        {categoryInfo && (
          <S.CenteredCategoriesContainer alignItems="flex-start" width="100%">
            <VerticalCharitySection
              leftMargin={-40}
              leftMarginMobile={-20}
              gridGap={40}
              items={charities?.map(mapEveryCharityToCharity) || []}
              isAdminView={false}
              isFetching={false}
              isEveryOrg
              isTablet={isTablet}
            />
          </S.CenteredCategoriesContainer>
        )}

        {!isEndReached && isFetched && (
          <S.ColumnDisplay width="100%" height={240}>
            <PrimaryButton
              label="Show more"
              isLoading={inProgress}
              sx={{ maxWidth: "300px" }}
              onClick={() =>
                dispatch(getEveryOrgCharities.request({ page: page + 1, cause: subcategory }))
              }
            />
          </S.ColumnDisplay>
        )}
      </S.ColumnDisplay>
    </S.CategoriesContainer>
  );
};

export default EveryDotOrgBrowseCharities;
