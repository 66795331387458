import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { GRANT_STATUS_OPTION } from "types/volunteer";

import * as C from "./company-active-volunteer-events.constants";
import * as T from "./company-active-volunteer-events.type";

export const companyActiveVolunteerEventsInitial: T.ICompanyActiveVolunteerEventsState = {
  data: [],
  inProgress: false,
  isFetched: false,
  isCached: false,
  isEndReached: false,
  page: 0,
  results: 0,
  error: null,
  dto: null,
  filters: {
    grant: GRANT_STATUS_OPTION.NO,
    city: "",
    state: null,
    groupSize: [],
    location: [],
  },
};

const companyVolunteerEventsReducer = (
  state: T.ICompanyActiveVolunteerEventsState,
  action:
    | T.CompanyActiveVolunteerEventsAction
    | T.RefreshCompanyActiveVolunteerEventsAction
    | ILogout,
): T.ICompanyActiveVolunteerEventsState => {
  if (!state) {
    return companyActiveVolunteerEventsInitial;
  }

  switch (action.type) {
    case C.FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS:
      return {
        ...state,
        inProgress: true,
      };
    case C.FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_SUCCESS: {
      const newData = action.payload.data;
      const existingData = state.data || [];
      const combinedData = existingData.concat(newData);
      const combinedResults = combinedData.length;

      return {
        ...state,
        data: combinedData,
        page: action.payload.page,
        results: combinedResults,
        inProgress: false,
        isFetched: true,
        isEndReached: newData.length < 10 || newData.length === 0,
      };
    }
    case C.FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case C.REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS:
      return {
        ...state,
        inProgress: true,
      };
    case C.REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case C.REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case LOGOUT:
      return companyActiveVolunteerEventsInitial;
    default:
      return state;
  }
};

export default companyVolunteerEventsReducer;
