import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { CharityListAction } from "store/charities/charities.type";
import {
  ADD_TO_COMPANY_WALL_SUCCESS,
  REMOVE_FROM_COMPANY_WALL_SUCCESS,
} from "store/company/company.constants";
import { DONATION_SUCCESS } from "store/payment/payment.constants";
import { IDonationSuccess } from "store/payment/payment.type";

import { cacheCharity, clearCharityCache } from "./charity.cache";
import * as C from "./charity.constant";
import * as T from "./charity.type";

export const charityInitial: T.ICharityState = {
  data: null,
  inProgress: false,
  isCached: false,
  error: null,
};

const cache = (state: T.ICharityState) => cacheCharity(charityInitial, state);

const charityReducer = (
  state: T.ICharityState,
  action: T.CharityAction | CharityListAction | IDonationSuccess | ILogout,
): T.ICharityState => {
  if (!state) {
    return charityInitial;
  }

  switch (action.type) {
    case C.REGISTER_CHARITY:
    case C.CONFIRM_CHARITY_REGISTRATION:
    case C.CONFIRM_CHARITY_ADMIN_REGISTRATION:
    case C.SET_CHARITY_DETAILS:
    case C.GET_CHARITY_BY_ID:
    case C.CHANGE_CHARITY_MISSION:
      return {
        ...state,
        inProgress: true,
        error: null,
      };
    case C.REGISTER_CHARITY_SUCCESS:
    case C.CONFIRM_CHARITY_REGISTRATION_FAILURE:
    case C.CONFIRM_CHARITY_ADMIN_REGISTRATION_FAILURE:
    case C.SET_CHARITY_DETAILS_FAILURE:
    case C.CHANGE_CHARITY_MISSION_FAILURE:
      return {
        ...state,
        inProgress: false,
        error: null,
      };
    case C.CONFIRM_CHARITY_REGISTRATION_SUCCESS:
    case C.CONFIRM_CHARITY_ADMIN_REGISTRATION_SUCCESS:
      return cache({
        ...state,
        inProgress: false,
      });
    case C.SET_CHARITY_DETAILS_SUCCESS:
    case C.GET_CHARITY_BY_ID_SUCCESS:
    case C.CHANGE_CHARITY_MISSION_SUCCESS:
      return cache({
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        inProgress: false,
      });
    case C.GET_CHARITY_BY_ID_FAILURE:
      return cache({
        ...state,
        data: null,
        inProgress: false,
        error: action.payload ?? null,
      });
    case C.UPDATE_CHARITY_UN_GOALS:
      return {
        ...state,
        inProgress: true,
      };
    case C.UPDATE_CHARITY_UN_GOALS_SUCCESS:
      return cache({
        ...state,
        inProgress: false,
        data: {
          ...state.data,
          goals: action.payload.goals,
        },
      });
    case C.UPDATE_CHARITY_UN_GOALS_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case REMOVE_FROM_COMPANY_WALL_SUCCESS:
      return cache({
        ...state,
        data: {
          ...state.data,
          backed: false,
        },
        inProgress: false,
      });
    case ADD_TO_COMPANY_WALL_SUCCESS:
      return cache({
        ...state,
        data: {
          ...state.data,
          backed: true,
        },
        inProgress: false,
      });
    case C.REGISTER_CHARITY_FAILURE:
      return {
        ...state,
        error: action.payload ?? null,
        inProgress: false,
      };
    case DONATION_SUCCESS:
      return cache({
        ...state,
        inProgress: false,
      });
    case C.INVITE_CHARITY_ADMIN_SUCCESS:
      return {
        ...state,
        inProgress: false,
        error: null,
      };
    case C.INVITE_CHARITY_ADMIN_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case LOGOUT:
      return clearCharityCache(charityInitial);
    default:
      return state;
  }
};

export default charityReducer;
