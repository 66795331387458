import { ArrowLeftIcon } from "@primer/octicons-react";
import { Spinner } from "@primer/react";
import EveryDotOrgSearchInput from "components/everydotorg-search";
import FixedHeader from "components/fixed-header";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import companySelectors from "store/company/company.selector";
import {
  resetSearchTerm,
  searchEveryOrgCharities,
} from "store/every-org-search/every-org-search.action";
import everyOrgSearchSelectors from "store/every-org-search/every-org-search.selector";
import { IAppState } from "store/store.state";
import { mapSearchedCharityToCharity } from "utils/mapper";
import routes from "utils/routes";
import VerticalCharitySection from "views/charity-grid/components/vertical-charity-section";

import * as S from "./search.styled";

const EveryDotOrgSearchCharities: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { searchTerm } = params;

  const companyName = useSelector(companySelectors.selectName);

  const charities = useSelector((state: IAppState) =>
    everyOrgSearchSelectors.selectData(state, "screen"),
  );
  const storedSearchTerm = useSelector(everyOrgSearchSelectors.selectSearchedTerm);
  const inProgress = useSelector((state: IAppState) =>
    everyOrgSearchSelectors.selectInProgress(state, "screen"),
  );

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  React.useEffect(() => {
    if (searchTerm || searchTerm !== storedSearchTerm) {
      dispatch(searchEveryOrgCharities.request({ searchTerm, showOnPage: true }));
    }
  }, [searchTerm]);

  return (
    <S.CategoriesContainer justifyContent="flex-start">
      <S.TopContainer alignItems="flex-start" justifyContent="flex-start">
        <FixedHeader name={companyName} />
        <S.Separator height={40} />
        <S.CenteredCategoriesContainer
          alignItems="flex-start"
          gridGap={40}
          justifyContent="flex-start"
        >
          <S.GradientHeadingStyled fontSize={38}>
            Find Nonprofits to support
          </S.GradientHeadingStyled>
          <EveryDotOrgSearchInput />
        </S.CenteredCategoriesContainer>
      </S.TopContainer>
      <S.ColumnDisplay alignItems="flex-start" width="100%">
        <S.Separator height={60} />
        {inProgress ? (
          <S.ColumnDisplay width="100%" height={300}>
            <Spinner />
          </S.ColumnDisplay>
        ) : (
          <S.CenteredCategoriesContainer
            alignItems="flex-start"
            gridGap={40}
            justifyContent="flex-start"
          >
            <S.ClickableWrapper
              onClick={() => {
                navigate(routes.EVERY_DOT_ORG.CATEGORIES);
                dispatch(resetSearchTerm());
              }}
            >
              <S.RowDisplay gridGap={18}>
                <ArrowLeftIcon size={25} />
                <S.BackText>Back</S.BackText>
              </S.RowDisplay>
            </S.ClickableWrapper>
            <VerticalCharitySection
              leftMargin={-40}
              leftMarginMobile={-20}
              gridGap={40}
              items={charities?.map(mapSearchedCharityToCharity) || []}
              isAdminView={false}
              isFetching={false}
              isEveryOrg
              isTablet={isTablet}
            />
          </S.CenteredCategoriesContainer>
        )}
      </S.ColumnDisplay>
    </S.CategoriesContainer>
  );
};

export default EveryDotOrgSearchCharities;
