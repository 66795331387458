import PrimaryButton from "components/buttons/primary";
import FixedHeader from "components/fixed-header";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import * as S from "./welcome-finch.styled";

const WelcomeFinchPage: React.FC<{}> = (): JSX.Element => {
  const { companyName } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <FixedHeader name={companyName} />
      <S.FormWrapper>
        <S.GradientHeading fontSize={32}>Welcome to Fundwurx</S.GradientHeading>
        <S.Separator height={40} />
        <S.WelcomeFinchParagraph fontSize={16}>
          Please confirm this invitation in order to verify your
          <br />
          account and email. Once confirmed, you&apos;ll gain access to
          <br />
          the platform.
        </S.WelcomeFinchParagraph>

        <S.Separator height={20} />
        <PrimaryButton
          label="Confirm Account"
          onClick={() =>
            navigate(`/company/${companyName}/interests/employee/${token}`, {
              state: { isFinch: true },
            })
          }
          sx={{ width: "100%", maxWidth: 391 }}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default WelcomeFinchPage;
