import { Spinner } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import CharityCard from "components/charity-card";
import EditDropdown from "components/edit-dropdown";
import FixedHeader from "components/fixed-header";
import VolunteerEventCard from "components/volunteer-event-card";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDraggable } from "react-use-draggable-scroll";
import authSelectors from "store/auth/auth.selector";
import { getCharities, getMoreWallCharities } from "store/charities/charities.action";
import charityListSelectors from "store/charities/charities.selector";
import { getDashboardSummary } from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import { IAppState } from "store/store.state";
import {
  fetchMoreVolunteerScheduledEvents,
  fetchVolunteerScheduledEvents,
} from "store/volunteer-scheduled/volunteer-scheduled.action";
import volunteerScheduledSelectors from "store/volunteer-scheduled/volunteer-scheduled.selector";
import colors from "theme/colors";
import { VolunteerEventItem } from "types/volunteer";
import { MAX_PROMOTED_EVENTS } from "utils/constants";
import { genEditLogoDropdown } from "utils/content";
import { cutDecimals, genRandomString } from "utils/helper";

import * as S from "./home.styled";

const HomePage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(1);
  const [eventsPage, setEventsPage] = React.useState<number>(1);
  const charitiesScrollRef = React.useRef<HTMLDivElement>();
  const volunteercrollRef = React.useRef<HTMLDivElement>();

  const { events: charityScrollEvents } = useDraggable(charitiesScrollRef);
  const { events: volunteerScrollEvents } = useDraggable(volunteercrollRef);

  const companyName = useSelector(companySelectors.selectName);
  const logoUrl = useSelector(companySelectors.selectLogoUrl);
  const companySummary = useSelector(companySelectors.selectDashboardSummary);
  const inProgress = useSelector(companySelectors.selectInProgress);

  const role = useSelector(authSelectors.selectRole);

  const charityData = useSelector((state: IAppState) =>
    charityListSelectors.selectData(state, "wall"),
  );

  const isWallEndReached = useSelector((state: IAppState) =>
    charityListSelectors.selectIsWallEndReached(state),
  );

  const isEndReachedEvents = useSelector((state: IAppState) =>
    volunteerScheduledSelectors.selectEndReached(state, "ACTIVE"),
  );

  const volunteerEvents = useSelector((state: IAppState) =>
    volunteerScheduledSelectors.selectData(state, "ACTIVE"),
  );

  React.useEffect(() => {
    dispatch(getDashboardSummary.request());
    dispatch(getCharities.request({ tab: "wall" }));
    dispatch(fetchVolunteerScheduledEvents.request({ params: { page: 1 }, status: "ACTIVE" }));
  }, []);

  const loadMoreCharities = (): void => {
    if (!inProgress) {
      dispatch(getMoreWallCharities.request({ tab: "wall", page: page + 1 }));
      setPage(page + 1);
    }
  };

  const loadMoreEvents = (): void => {
    if (!inProgress) {
      dispatch(
        fetchMoreVolunteerScheduledEvents.request({
          params: { page: eventsPage + 1 },
          status: "ACTIVE",
        }),
      );
      setEventsPage(eventsPage + 1);
    }
  };

  const sections = Object.keys(charityData);
  const charities = sections.flatMap((key) => charityData[key]?.data) || [];

  return (
    <S.HomeContainer justifyContent="flex-start">
      <S.HomeInnerContainer justifyContent="flex-start" gridGap={28}>
        <S.HomeTopContainer justifyContent="flex-start" background={colors.white()}>
          <FixedHeader name={companyName} />
          <S.HomeFrame
            gridGap={40}
            isTransparent
            justifyContent="space-between"
            alignItems="center"
          >
            <S.HomeFrameFlexContainer style={{ gridColumnGap: 120, gridRowGap: 40 }} flexWrap>
              <S.RowDisplay gridGap={16}>
                <S.LogoWrapper>
                  {inProgress ? (
                    <S.LogoWrapper>
                      <Spinner />
                    </S.LogoWrapper>
                  ) : (
                    <>
                      {logoUrl ? (
                        <S.LogoWrapper>
                          {role === "admin" && (
                            <EditDropdown items={genEditLogoDropdown(logoUrl)} />
                          )}
                          <S.LogoStyled src={logoUrl} />
                        </S.LogoWrapper>
                      ) : (
                        <S.LogoBorderWrapper>
                          {role === "admin" && (
                            <EditDropdown items={genEditLogoDropdown(logoUrl)} />
                          )}
                          <S.DefaultLogoStyled />
                        </S.LogoBorderWrapper>
                      )}
                    </>
                  )}
                </S.LogoWrapper>
                <S.ColumnDisplay gridGap={8} alignItems="flex-start">
                  <S.Name>Fundwurx for</S.Name>
                  <S.GradientHeadingExtended fontSize={48}>{companyName}</S.GradientHeadingExtended>
                </S.ColumnDisplay>
              </S.RowDisplay>

              <S.BubblesContainer justifyContent="space-around" gridGap={20}>
                <S.HomeBubble gridGap={8}>
                  <S.HomeNumber>
                    {inProgress ? (
                      <Spinner />
                    ) : (
                      <>{cutDecimals(companySummary?.numCharities || 0)}</>
                    )}
                  </S.HomeNumber>
                  <S.HomeLabel>Total # of nonprofits supported</S.HomeLabel>
                </S.HomeBubble>

                <S.HomeBubble gridGap={8}>
                  <S.HomeNumber>
                    {inProgress ? (
                      <Spinner />
                    ) : (
                      <>{`$${cutDecimals(companySummary?.totalDonationValue || 0)}`}</>
                    )}
                  </S.HomeNumber>
                  <S.HomeLabel>Total amount donated by employees</S.HomeLabel>
                </S.HomeBubble>

                <S.HomeBubble gridGap={8}>
                  <S.HomeNumber style={{ fontWeight: "800" }}>
                    {inProgress ? (
                      <Spinner />
                    ) : (
                      <>{cutDecimals(companySummary?.numEmployeesDonated || 0)}</>
                    )}
                  </S.HomeNumber>
                  <S.HomeLabel>Total # of employees who donated </S.HomeLabel>
                </S.HomeBubble>
              </S.BubblesContainer>
            </S.HomeFrameFlexContainer>
          </S.HomeFrame>
        </S.HomeTopContainer>

        <S.HomeFrame isTransparent paddingBottom={0}>
          <S.RowDisplay flexWrap width="100%" justifyContent="flex-start" gridGap={16}>
            <S.GradientHeadingExtended fontSize={32}>
              {`Nonprofits promoted by ${companyName}`}
            </S.GradientHeadingExtended>
            {/* {charities?.length > MAX_CHARITIES_IN_HORIZONTAL_SECTION && (
              <S.DivLinkText
                fontSize={18}
                gridGap={4}
                onClick={() => navigate(routes.FIND_CHARITIES)}
              >
                <div>View All</div> <ArrowRightIcon size={24} />
              </S.DivLinkText>
            )} */}
          </S.RowDisplay>
        </S.HomeFrame>

        <S.CharitiesFrame
          justifyContent="flex-start"
          alignItems="flex-start"
          isTransparent
          ref={charitiesScrollRef}
          style={{ overflow: "scroll" }}
          {...charityScrollEvents}
        >
          <S.RowDisplay
            gridGap={40}
            flexWrap={false}
            alignItems="stretch"
            justifyContent="flex-start"
            style={{ cursor: "grab" }}
          >
            <S.CharityLeftOffset />
            {charities.map((card) => (
              <CharityCard
                {...{ ...card, backed: false }}
                key={genRandomString()}
                isAdminView={false}
                isEveryOrg={!!card.slug || false}
                backFromHome
              />
            ))}
            {!isWallEndReached && charities.length > 0 && (
              <PrimaryButton
                label=">"
                isLoading={inProgress}
                sx={{ maxWidth: "40px", marginTop: "auto", marginBottom: "auto" }}
                onClick={loadMoreCharities}
              />
            )}
          </S.RowDisplay>
        </S.CharitiesFrame>

        <S.HomeFrame justifyContent="flex-start" alignItems="flex-start" gridGap={20} isTransparent>
          <S.RowDisplay gridGap={16} flexWrap width="100%" justifyContent="flex-start">
            <S.GradientHeadingExtended fontSize={32}>
              Group Volunteer Days
            </S.GradientHeadingExtended>
          </S.RowDisplay>
        </S.HomeFrame>

        <S.CharitiesFrame
          justifyContent="flex-start"
          alignItems="flex-start"
          isTransparent
          ref={volunteercrollRef}
          style={{ overflow: "scroll" }}
          {...volunteerScrollEvents}
        >
          <S.RowDisplay
            gridGap={40}
            flexWrap={false}
            alignItems="stretch"
            justifyContent="flex-start"
            style={{ cursor: "grab" }}
          >
            <S.CharityLeftOffset />
            {volunteerEvents?.map((card) => (
              <VolunteerEventCard
                {...{ ...(card as VolunteerEventItem) }}
                key={genRandomString()}
                isAdminView={role === "admin"}
              />
            ))}
            {!isEndReachedEvents && volunteerEvents.length > 0 && (
              <PrimaryButton
                label=">"
                isLoading={inProgress}
                sx={{ maxWidth: "40px", marginTop: "auto", marginBottom: "auto" }}
                onClick={loadMoreEvents}
              />
            )}
          </S.RowDisplay>
        </S.CharitiesFrame>
      </S.HomeInnerContainer>
    </S.HomeContainer>
  );
};

export default HomePage;
