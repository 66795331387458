import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { roundNumber } from "utils/helper";

import * as C from "./employee-dashboard-summary.constants";
import * as T from "./employee-dashboard-summary.type";

export const employeeDashboardSummaryInitial: T.IEmployeeDashboardSummaryState = {
  data: {
    numCharities: 0,
    totalDonationValue: 0,
    totalMatchedValue: 0,
    companyGiftMatchLimit: 0,
  },
  inProgress: false,
  isFetched: false,
  error: null,
};

const employeeDashboardSummaryReducer = (
  state: T.IEmployeeDashboardSummaryState,
  action: T.EmployeeDashboardSummaryAction | ILogout,
): T.IEmployeeDashboardSummaryState => {
  if (!state) {
    return employeeDashboardSummaryInitial;
  }

  switch (action.type) {
    case C.FETCH_USER_DONATIONS_SUMMARY:
      return {
        ...state,
        inProgress: true,
      };
    case C.FETCH_USER_DONATIONS_SUMMARY_FAILURE:
      return {
        ...state,
        inProgress: true,
      };
    case C.FETCH_USER_DONATIONS_SUMMARY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        inProgress: false,
        isFetched: true,
      };
    case LOGOUT:
      return employeeDashboardSummaryInitial;
    default:
      return state;
  }
};

export default employeeDashboardSummaryReducer;
