import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/buttons/primary";
import ControlledSelect from "components/controlled-select";
import Input from "components/input";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import { editEmployeeDetails } from "store/user/user.action";
import userSelectors from "store/user/user.selector";
import { NAME_REG } from "utils/constants";
import { DEPARTMENTS_LIST } from "utils/content";
import * as yup from "yup";

import * as S from "../dialogs.styled";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .matches(NAME_REG, "First name must be valid.")
    .min(2, "First name must have at least 2 characters."),
  lastName: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .matches(NAME_REG, "Last name must be valid.")
    .min(2, "Last name must have at least 2 characters."),
  department: yup.string().required("Please fill in all data."),
});

const EditProfileDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, "EDIT_PROFILE"),
  );

  const employee = useSelector(userSelectors.selectData);

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: "EDIT_PROFILE", isOpened: false }));
  };

  const onConfirm = ({ firstName, lastName, department }): void => {
    dispatch(editEmployeeDetails.request({ firstName, lastName, department }));
    closeDialog();
  };

  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);
  const departmentRef = React.useRef(null);

  const {
    formState: { errors },
    setValue,
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      firstName: employee?.firstName,
      lastName: employee?.lastName,
      department: employee?.department,
    },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  React.useEffect(() => {
    setValue("firstName", employee?.firstName);
    setValue("lastName", employee?.lastName);
    setValue("department", employee?.department);
  }, [employee?.firstName, employee?.lastName, employee?.department]);

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper>
          <S.GradientHeading fontSize={32}>Edit profile</S.GradientHeading>
          <S.Separator height={38} />
          <S.DialogSubheading>Edit basic information about yourself.</S.DialogSubheading>
          <S.Separator height={58} />
          <Input
            topLabel="First name"
            isRequired
            sx={{ width: "100%", minWidth: "100%" }}
            control={control}
            ref={firstNameRef}
            errorMessage={errors?.firstName?.message}
            onEnter={() => lastNameRef?.current?.focus()}
            name="firstName"
          />
          <S.Separator height={40} />
          <Input
            topLabel="Last name"
            isRequired
            sx={{ width: "100%", minWidth: "100%" }}
            control={control}
            ref={lastNameRef}
            errorMessage={errors?.lastName?.message}
            name="lastName"
            onEnter={() => departmentRef?.current?.focus()}
          />
          <S.Separator height={40} />
          <ControlledSelect
            label="Department"
            items={DEPARTMENTS_LIST}
            control={control}
            sx={{ width: "100%", minWidth: "100%" }}
            name="department"
            ref={departmentRef}
          />
          <S.Separator height={70} />
          <PrimaryButton
            label="Save"
            onClick={handleSubmit(onConfirm)}
            isLoading={false}
            sx={{ width: "100%", minWidth: "100%" }}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default EditProfileDialog;
