import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  ClickableWrapper,
  ColumnDisplay,
  GradientHeading,
  LinkText,
  Paragraph,
  RowDisplay,
  Separator,
  Tag,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPadMini.x;

export const PC_SMALL_BREAKPOINT = breakpoints.desktopM.x;

export const MOBILE_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const SearchInputRow = styled(RowDisplay)`
  position: relative;
  min-width: 300px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    grid-gap: 16px;
  }
`;

export const GradientWrapper = styled.div`
  background: linear-gradient(90deg, rgba(51, 234, 241, 0.5) 0%, rgba(86, 145, 234, 0.5) 100%);
  padding: 5px;
  border-radius: 6px;
  max-width: 863px;
  width: 100%;
  height: 66px;
  box-sizing: border-box;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    border-radius: 6px;
  }
`;

export const InputContainer = styled(RowDisplay)`
  width: 100%;
  height: 56px;
  flex: 1;
  padding 16px;
  box-sizing: border-box;
  border-radius: 6px;
  background: ${({ theme }) => theme.white()};
`;

export const SearchInput = styled.input`
  display: flex;
  flex: 1;
  outline: none;
  border: none;
  min-width: 150px;

  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.charcoalGray(0.7)};

  &::placeholder {
    opacity: 0.5;
  }
`;

export const SearchDropdown = styled(RowDisplay)`
  position: absolute;
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);
  background: ${({ theme }) => theme.white()};
  max-height: 300px;
  overflow: auto;
  width: 100%;
  max-width: 862px;
  padding: 16px;
  box-sizing: border-box;
  top: 0;
  margin-top: 70px;
  border-radius: 12px;
`;

export const SearchItem = styled(RowDisplay)`
  background: ${({ theme }) => theme.white()};
  border-radius: 40px;
  box-sizing: border-box;
  padding: 8px;
  cursor: pointer;
  max-width: 100%;

  &:hover {
    background: ${({ theme }) => theme.grayX11(0.2)};
  }
`;

export const SearchedText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: calc(100% - 30px);
`;

export const SearchItemLogo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  object-fit: contain;
`;

export const SearchItemLogoWrapper = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.grayX11(0.5)};
  width: 30px;
  height: 30px;
  border-radius: 30px;
`;

export const SearchLoadingWrapper = styled(ColumnDisplay)`
  width: 100%;
  height: 300px;
`;
