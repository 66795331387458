import { SearchIcon } from "@primer/octicons-react";
import { ActionList, ActionMenu, FilteredSearch, TextInput } from "@primer/react";
import { HamburgerWhiteIcon } from "icons";
import React from "react";
import { Controller } from "react-hook-form";
import colors from "theme/colors";
import { genRandomString } from "utils/helper";

import * as S from "./filter-search.styled";
import * as T from "./filter-search.type";

// highlighting would take more time. There is no Primer component for this

const FilterSearch: React.FC<T.IFilterSearchProps> = ({
  filters,
  searchResults,
  control,
  name,
  initialValue,
  errorMessage,
  sx,
  size = "large",
  filtersDisabled = false,
  placeholder = "",
}): JSX.Element => (
  <S.ColumnDisplay style={{ width: "391px", ...sx }} gridGap={5}>
    <FilteredSearch sx={{ width: "391px", ...sx }}>
      <ActionMenu {...(filtersDisabled && { open: false })}>
        <ActionMenu.Button
          as="summary"
          icon={HamburgerWhiteIcon}
          sx={{
            background: colors.unitedNationsBlue(),
            border: "none",
            padding: size === "large" ? "15px" : "auto",
            height: size === "large" ? "auto" : "34px",
          }}
        >
          <div />
        </ActionMenu.Button>
        <ActionMenu.Overlay>
          <ActionList>
            {filters.map((filter) => (
              <ActionList.Item key={genRandomString()}>{filter}</ActionList.Item>
            ))}
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
      {control ? (
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange: formChange } }) => (
            <S.ColumnDisplay
              style={{ width: "100%" }}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <TextInput
                leadingVisual={SearchIcon}
                value={value || initialValue}
                sx={{
                  width: "calc(100% - 72px)",
                  overflow: "hidden",
                  ...(errorMessage && { border: `1px solid ${colors.bordo()}` }),
                }}
                onChange={formChange}
                block
                size={size || "large"}
                placeholder={placeholder}
              />
              {!!errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
            </S.ColumnDisplay>
          )}
        />
      ) : (
        <TextInput
          leadingVisual={SearchIcon}
          sx={{
            width: "calc(100% - 72px)",
            ...(errorMessage && { border: `1px solid ${colors.bordo()}` }),
          }}
          placeholder={placeholder}
        />
      )}
    </FilteredSearch>
    {searchResults.length > 0 && (
      <S.SearchDropdownStyled style={{ width: "391px", ...sx }}>
        {searchResults.map((result) => (
          <S.SearchResult>{result}</S.SearchResult>
        ))}
      </S.SearchDropdownStyled>
    )}
  </S.ColumnDisplay>
);

export default FilterSearch;
