import { AlertIcon, CheckCircleIcon, CircleSlashIcon, HourglassIcon } from "@primer/octicons-react";
import SmallButton from "components/buttons/small";
import React from "react";
import { useDispatch } from "react-redux";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import colors from "theme/colors";
import { GiftMatch, GiftMatchStatus } from "types/gift-match";
import { capitalizeFirstLetter } from "utils/helper";

import * as S from "./gift-match-status.styled";

interface IGiftMatchStatus {
  donation: Pick<GiftMatch, "status" | "id">;
  isAdminView: boolean;
  isTablet: boolean;
}

const GiftMatchStatusText: React.FC<IGiftMatchStatus> = ({
  donation,
  isAdminView,
  isTablet,
}): JSX.Element => {
  const dispatch = useDispatch();

  const approveDonation = () => {
    dispatch(
      updateDialogState({
        key: "APPROVE_DONATION",
        isOpened: true,
        properties: { id: donation.id },
      }),
    );
  };

  const declineDonation = () => {
    dispatch(
      updateDialogState({
        key: "DECLINE_DONATION",
        isOpened: true,
        properties: { id: donation.id },
      }),
    );
  };

  const genStatusColor = (status: GiftMatchStatus): string =>
    status
      ? status === "FULFILLED" || status === "FULFILLED_MANUALLY"
        ? colors.amazon()
        : status === "REFUNDED"
        ? colors.warning()
        : status === "PROCESSING"
        ? colors.charcoalGray(0.5)
        : status === "PENDING"
        ? colors.charcoalGray(0.5)
        : colors.bordo()
      : colors.charcoalGray(0.5);

  const icon = donation?.status ? (
    donation?.status === "FULFILLED" || donation.status === "FULFILLED_MANUALLY" ? (
      <CheckCircleIcon />
    ) : donation.status === "REFUNDED" ? (
      <AlertIcon />
    ) : donation.status === "PENDING" ? (
      <HourglassIcon />
    ) : donation.status === "PROCESSING" ? (
      <HourglassIcon />
    ) : (
      <CircleSlashIcon />
    )
  ) : null;

  const text = donation.status
    ? donation.status === "FULFILLED" || donation.status === "FULFILLED_MANUALLY"
      ? "Approved"
      : capitalizeFirstLetter(donation?.status?.toLowerCase())
    : "Not Requested";

  return (
    <>
      {donation?.status === "PENDING" && isAdminView ? (
        <S.RowDisplay gridGap={16} justifyContent="flex-start" flexWrap={isTablet}>
          <SmallButton
            label="APPROVE"
            onClick={approveDonation}
            sx={{ width: "98px", height: "32px", color: "rgba(45, 164, 78, 1)" }}
          />
          <SmallButton
            label="DECLINE"
            onClick={declineDonation}
            sx={{ width: "98px", height: "32px", color: colors.bordo() }}
          />
        </S.RowDisplay>
      ) : (
        <S.StatusText
          gridGap={6}
          justifyContent="flex-start"
          color={genStatusColor(donation.status)}
        >
          {icon}
          {text}
        </S.StatusText>
      )}
    </>
  );
};

export default GiftMatchStatusText;
