import { XIcon } from "@primer/octicons-react";
import PrimaryButton from "components/buttons/primary";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import colors from "theme/colors";

import * as S from "../dialogs.styled";

const DIALOG_KEY = "POST_EVENT_INSTRUCTIONS";

const PostEventInstructionsDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, DIALOG_KEY),
  );

  const handleCreatePost = (): void => {
    dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
    dispatch(updateDialogState({ key: "POST_CHARITY_EVENT_STEP_1", isOpened: true }));
  };

  const handleClose = (): void => {
    dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
  };

  return isOpened ? (
    <S.DialogBackground>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20}>
          <S.CloseIconContainer>
            <S.CloseIconButton type="button" onClick={handleClose}>
              <XIcon fill={colors.grayX11()} size={24} />
            </S.CloseIconButton>
          </S.CloseIconContainer>
          <S.InnerContainer>
            <S.GradientHeading fontSize={28}>
              Post a Group Volunteering
              <br />
              Day Request
            </S.GradientHeading>
            <S.Separator height={40} />
            <S.Paragraph fontSize={18} lineHeight={24} color={colors.black()} fontWeight="700">
              Post an event so companies can learn more about the amazing volunteer opportunities
              available at your organization!
            </S.Paragraph>
            <S.Separator height={24} />
            <S.Paragraph fontSize={16} lineHeight={24} color={colors.gunmetal()} fontWeight="500">
              This short form provides high-level information about the volunteering events you
              offer. <br />
              Companies can browse events and request to connect with you if they want to schedule a
              group volunteer day. Rest assured, you&apos;ll be able to delve into more specific
              details with the company later on.
            </S.Paragraph>
          </S.InnerContainer>
          <S.Separator height={40} />
          <PrimaryButton
            label="Create a post"
            sx={{ width: "100%", maxWidth: "395px" }}
            onClick={handleCreatePost}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default PostEventInstructionsDialog;
