import CharityCard from "components/charity-card";
import React from "react";
import { genRandomString } from "utils/helper";

import * as S from "../charity-grid.styled";
import * as T from "../charity-grid.type";

const VerticalCharitySection: React.FC<
  Omit<T.ICharitySectionVerticalProps, "isBacked" | "title">
> = ({
  isTablet,
  items,
  isAdminView = false,
  isEveryOrg = false,
  children,
  leftMargin = 60,
  leftMarginMobile = 0,
  gridGap = 20,
}): JSX.Element =>
  items?.length ? (
    <S.ColumnDisplay alignItems="flex-start" justifyContent="flex-start" style={{ width: "100%" }}>
      <div style={{ marginLeft: !isTablet ? 100 : 0 }}>{children}</div>
      <S.VerticalScrollContainer
        alignItems="stretch"
        justifyContent="flex-start"
        style={{ marginLeft: !isTablet ? leftMargin : leftMarginMobile }}
        gridGap={gridGap}
        flexWrap
      >
        {items.map((card) => (
          <CharityCard
            {...{ ...card, backed: false }}
            key={genRandomString()}
            isAdminView={isAdminView}
            isEveryOrg={isEveryOrg}
          />
        ))}
      </S.VerticalScrollContainer>
    </S.ColumnDisplay>
  ) : null;

export default VerticalCharitySection;
