import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { GRANT_STATUS_OPTION } from "types/volunteer";

import * as C from "./company-pending-volunteer-events.constants";
import * as T from "./company-pending-volunteer-events.type";

export const companyPendingVolunteerEventsInitial: T.ICompanyPendingVolunteerEventsState = {
  data: [],
  inProgress: false,
  isFetched: false,
  isCached: false,
  isEndReached: false,
  page: 0,
  results: 0,
  error: null,
  dto: null,
  filters: {
    grant: GRANT_STATUS_OPTION.NO,
    city: "",
    state: null,
    groupSize: [],
    location: [],
  },
};

const companyVolunteerEventsReducer = (
  state: T.ICompanyPendingVolunteerEventsState,
  action:
    | T.CompanyPendingVolunteerEventsAction
    | ILogout
    | T.CancelPendingVolunteerEventsAction
    | T.ActivatePendingVolunteerEvetAction
    | T.RefreshCompanyPendingVolunteerEventsAction,
): T.ICompanyPendingVolunteerEventsState => {
  if (!state) {
    return companyPendingVolunteerEventsInitial;
  }

  switch (action.type) {
    case C.FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS:
      return {
        ...state,
        inProgress: true,
      };
    case C.FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS_SUCCESS: {
      const newData = action.payload.data;
      const existingData = state.data || [];
      const combinedData = existingData.concat(newData);
      const combinedResults = combinedData.length;

      return {
        ...state,
        data: combinedData,
        page: action.payload.page,
        results: combinedResults,
        inProgress: false,
        isFetched: true,
        isEndReached: newData.length < 10 || newData.length === 0,
      };
    }
    case C.FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case C.ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT_SUCCESS: {
      const newData = state.data.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        data: newData,
      };
    }
    case C.CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT_SUCCESS: {
      const newData = state.data.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        data: newData,
      };
    }
    case C.REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS:
      return {
        ...state,
        inProgress: true,
      };
    case C.REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case C.REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case LOGOUT:
      return companyPendingVolunteerEventsInitial;
    default:
      return state;
  }
};

export default companyVolunteerEventsReducer;
