import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { contactSupport } from "utils/helper";

import * as S from "./company-not-found.style";

const CompanyNotFoundPage: React.FC<{}> = (): JSX.Element => (
  <S.BubbleBackground justifyContent="space-between" gridGap={24}>
    <div />
    <S.NotFoundContainer>
      <S.GradientHeading fontSize={180}>404</S.GradientHeading>
      <S.Separator height={40} />
      <S.NotFoundHeading>Company name provided in the URL does not exist.</S.NotFoundHeading>
      <S.Separator height={20} />
      <S.NotFoundParagraph>
        <span>
          Please contact the administrator of your company to send you a valid URL.
          <br />
          <br />
        </span>

        <S.RowDisplay gridGap={4} flexWrap>
          <div>If you keep facing the same issue, </div>
          <S.DivLinkText onClick={contactSupport} fontSize={16}>
            contact our support
          </S.DivLinkText>
          <div>.</div>
        </S.RowDisplay>
      </S.NotFoundParagraph>
      <S.Separator height={60} />
    </S.NotFoundContainer>
    <LogoAndCompany />
  </S.BubbleBackground>
);

export default CompanyNotFoundPage;
