export const GET_ADMIN_USERS = "GET_ADMIN_USERS";
export const GET_ADMIN_USERS_SUCCESS = "GET_ADMIN_USERS_SUCCESS";
export const GET_ADMIN_USERS_FAILURE = "GET_ADMIN_USERS_FAILURE";

export const GET_EMPLOYEE_USERS = "GET_EMPLOYEE_USERS";
export const GET_EMPLOYEE_USERS_SUCCESS = "GET_EMPLOYEE_USERS_SUCCESS";
export const GET_EMPLOYEE_USERS_FAILURE = "GET_EMPLOYEE_USERS_FAILURE";

export const GET_USERS_BY_PAGE = "GET_USERS_BY_PAGE";
export const GET_USERS_BY_PAGE_SUCCESS = "GET_USERS_BY_PAGE_SUCCESS";
export const GET_USERS_BY_PAGE_FAILURE = "GET_USERS_BY_PAGE_FAILURE";

export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_FAILURE = "UPDATE_USER_ROLE_FAILURE";

export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const REMOVE_USER_FAILURE = "REMOVE_USER_FAILURE";

export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";

export const GET_USER_STATISTICS = "GET_USER_STATISTICS";
export const GET_USER_STATISTICS_SUCCESS = "GET_USER_STATISTICS_SUCCESS";
export const GET_USER_STATISTICS_FAILURE = "GET_USER_STATISTICS_FAILURE";
