import React from "react";
import { useDispatch, useSelector } from "react-redux";
import charitySelectors from "store/charity/charity.selector";
import { fetchCharityVolunteerEvents } from "store/volunteer-events-charity/volunteer-events-charity.action";
import volunteerSelectors from "store/volunteer-events-charity/volunteer-events-charity.selector";
import CharityDetailsTemplate from "templates/charity-details";

const CharityHomePage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const charity = useSelector(charitySelectors.selectData);

  const isFetched = useSelector(volunteerSelectors.selectIsFetched);

  React.useEffect(() => {
    if (!isFetched && charity?.id) {
      dispatch(fetchCharityVolunteerEvents.request());
    }
  }, [isFetched, charity?.id]);

  return <CharityDetailsTemplate charityId={charity?.id} />;
};

export default CharityHomePage;
