import * as C from "./charities.constants";
import * as T from "./charities.type";

export const getCharities = {
  request: (payload: T.IGetCharitiesRequest["payload"]): T.IGetCharitiesRequest => ({
    type: C.GET_CHARITIES,
    payload,
  }),
  success: (payload: T.IGetCharitiesSuccess["payload"]): T.IGetCharitiesSuccess => ({
    type: C.GET_CHARITIES_SUCCESS,
    payload,
  }),
  failure: (): T.IGetCharitiesFailure => ({
    type: C.GET_CHARITIES_FAILURE,
  }),
};

export const getMoreCharities = {
  request: (payload?: T.IGetMoreCharitiesRequest["payload"]): T.IGetMoreCharitiesRequest => ({
    type: C.GET_MORE_CHARITIES,
    payload,
  }),
  success: (payload: T.IGetMoreCharitiesSuccess["payload"]): T.IGetMoreCharitiesSuccess => ({
    type: C.GET_MORE_CHARITIES_SUCCESS,
    payload,
  }),
  failure: (payload: T.IGetMoreCharitiesFailure["payload"]): T.IGetMoreCharitiesFailure => ({
    type: C.GET_MORE_CHARITIES_FAILURE,
    payload,
  }),
};

export const getMoreWallCharities = {
  request: (
    payload?: T.IGetMoreWallCharitiesRequest["payload"],
  ): T.IGetMoreWallCharitiesRequest => ({
    type: C.GET_MORE_WALL_CHARITIES,
    payload,
  }),
  success: (
    payload: T.IGetMoreWallCharitiesSuccess["payload"],
  ): T.IGetMoreWallCharitiesSuccess => ({
    type: C.GET_MORE_WALL_CHARITIES_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.IGetMoreWallCharitiesFailure["payload"],
  ): T.IGetMoreWallCharitiesFailure => ({
    type: C.GET_MORE_WALL_CHARITIES_FAILURE,
    payload,
  }),
};

export const addToCharityList = {
  request: (payload: T.IAddToCharityListRequest["payload"]): T.IAddToCharityListRequest => ({
    type: C.ADD_TO_COMPANY_WALL,
    payload,
  }),
  success: (payload: T.IAddToCharityListSuccess["payload"]): T.IAddToCharityListSuccess => ({
    type: C.ADD_TO_COMPANY_WALL_SUCCESS,
    payload,
  }),
  failure: (payload: T.IAddToCharityListFailure["payload"]): T.IAddToCharityListFailure => ({
    type: C.ADD_TO_COMPANY_WALL_FAILURE,
    payload,
  }),
};

export const addEveryOrgToCharityList = {
  request: (
    payload: T.IAddEveryOrgToCharityListRequest["payload"],
  ): T.IAddEveryOrgToCharityListRequest => ({
    type: C.ADD_EVERYORG_TO_COMPANY_WALL,
    payload,
  }),
  success: (
    payload: T.IAddEveryOrgToCharityListSuccess["payload"],
  ): T.IAddEveryOrgToCharityListSuccess => ({
    type: C.ADD_EVERYORG_TO_COMPANY_WALL_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.IAddEveryOrgToCharityListFailure["payload"],
  ): T.IAddEveryOrgToCharityListFailure => ({
    type: C.ADD_EVERYORG_TO_COMPANY_WALL_FAILURE,
    payload,
  }),
};

export const removeFromCharityList = {
  request: (
    payload: T.IRemoveFromCharityListRequest["payload"],
  ): T.IRemoveFromCharityListRequest => ({
    type: C.REMOVE_FROM_COMPANY_WALL,
    payload,
  }),
  success: (
    payload: T.IRemoveFromCharityListSuccess["payload"],
  ): T.IRemoveFromCharityListSuccess => ({
    type: C.REMOVE_FROM_COMPANY_WALL_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.IRemoveFromCharityListFailure["payload"],
  ): T.IRemoveFromCharityListFailure => ({
    type: C.REMOVE_FROM_COMPANY_WALL_FAILURE,
    payload,
  }),
};

export const removeEveryOrgFromCharityList = {
  request: (
    payload: T.IRemoveEveryOrgFromCharityListRequest["payload"],
  ): T.IRemoveEveryOrgFromCharityListRequest => ({
    type: C.REMOVE_EVERYORG_FROM_COMPANY_WALL,
    payload,
  }),
  success: (
    payload: T.IRemoveEveryOrgFromCharityListSuccess["payload"],
  ): T.IRemoveEveryOrgFromCharityListSuccess => ({
    type: C.REMOVE_EVERYORG_FROM_COMPANY_WALL_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.IRemoveEveryOrgFromCharityListFailure["payload"],
  ): T.IRemoveEveryOrgFromCharityListFailure => ({
    type: C.REMOVE_EVERYORG_FROM_COMPANY_WALL_FAILURE,
    payload,
  }),
};
