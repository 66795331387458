import { IUserSelectors } from "./user.type";

const userSelectors: IUserSelectors = {
  selectData: (state) => state.user.data,
  selectInProgress: (state) => state.user.inProgress,
  selectError: (state) => state.user.error,
  selectNotificationEnabled: (state) => state.user.data?.notificationsEnabled ?? false,
};

export default userSelectors;
