import { CalendarIcon } from "@primer/octicons-react";
import React from "react";
import { Control, Controller } from "react-hook-form";
import colors from "theme/colors";
import { formatDate } from "utils/helper";

import * as S from "./input-date-picker.styled";

interface ICustomInputProps {
  control: Control<any>;
  label?: string;
  name: string;
  error: string | null;
  topLabel?: string | null;
  isRequired?: boolean;
  onChange: (val: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const InputDatePicker: React.FC<ICustomInputProps> = ({
  control,
  name,
  error,
  label,
  topLabel,
  isRequired,
  onChange,
  onFocus,
  onBlur,
}): JSX.Element => {
  const currentDate = new Date().toISOString().split("T")[0];

  const [isFocused, focus] = React.useState<boolean>(false);

  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange: formChange } }) => (
        <S.ColumnDisplay
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ position: "relative" }}
        >
          {topLabel && (
            <S.DatePickerTopLabel>
              {topLabel}
              {topLabel !== null && (
                <>{isRequired && <span style={{ color: colors.bordo() }}>{" *"}</span>}</>
              )}
            </S.DatePickerTopLabel>
          )}
          <S.DateInputFrame
            justifyContent="space-between"
            isInvalid={!!error}
            isFocused={isFocused}
            tabIndex={0}
            onBlur={() => {
              focus(false);
              onBlur?.();
            }}
            onClick={() => {
              ref?.current?.showPicker();
              focus(true);
              onFocus?.();
            }}
          >
            <S.RowDisplay gridGap={8}>
              <S.DateInputLabel>{label}</S.DateInputLabel>
              <S.DateFormatted>
                {value ? formatDate(value, "MM-DD-YYYY") : "mm-dd-yyyy"}
              </S.DateFormatted>
            </S.RowDisplay>

            <CalendarIcon fill={!error?.length ? colors.gunmetal() : colors.bordo(0.2)} size={18} />
          </S.DateInputFrame>

          <S.TransparentInput
            ref={ref}
            type="date"
            value={value ?? ""}
            isHidden
            min={currentDate}
            onChange={(e) => {
              formChange(e);
              onChange(formatDate(e.target.value, "MM-DD-YYYY"));
            }}
            onFocus={() => {
              focus(true);
              onFocus?.();
            }}
            onBlur={() => {
              focus(false);
              onBlur?.();
            }}
          />
        </S.ColumnDisplay>
      )}
    />
  );
};

InputDatePicker.defaultProps = {
  label: "mm-dd-yyyy",
  isRequired: false,
  topLabel: null,
  onFocus: () => {},
  onBlur: () => {},
};

export default InputDatePicker;
