import styled from "styled-components";
import { ColumnDisplay, GradientHeading } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export { RowDisplay, Separator, Tag } from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.desktopM.x;

export const VolunteerEventCardContainer = styled(ColumnDisplay)`
  width: 260px;
  height: 237px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white()};
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-evenly;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);

  &:hover {
    opacity: 0.6;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 200px;
    height: 180px;
  }
`;

export const VolunteerEventCardTitle = styled(GradientHeading)`
  background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  max-width: 100%;
  word-break: break-word;
`;
