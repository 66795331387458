import * as api from "api/user";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";

import { fetchUserDonations } from "./user-donations.action";
import * as C from "./user-donations.constants";
import * as T from "./user-donations.type";

export const fetchUserDonationsEpic: AppEpic = (action$: Observable<T.IFetchUserDonations>) =>
  action$.pipe(
    ofType(C.FETCH_USER_DONATIONS),
    mergeMap((action) =>
      from(api.fetchUserDonations(action.payload)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchUserDonations.success(response.data));
          }
          return of(handleError({ action, error: null }), fetchUserDonations.failure());
        }),
        catchError((error) => of(fetchUserDonations.failure(), handleError({ action, error }))),
      ),
    ),
  );

export default combineEpics(fetchUserDonationsEpic);
