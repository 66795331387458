import { MOBILE_SMALL_BREAKPOINT } from "components/fixed-header/fixed-header.style";
import styled from "styled-components";
import { TABLET_BREAKPOINT } from "templates/charity-auth-form/charity-auth-form.styled";
import { FormWrapper } from "theme/global-style";

export { MOBILE_SMALL_BREAKPOINT, TABLET_BREAKPOINT };

export {
  BubbleBackground,
  ColumnDisplay,
  FormWrapper,
  GradientHeading,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const FormWrapperWithPadding = styled(FormWrapper)`
  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding-top: 22px;
  }
`;

export const CheckInGradientHeading = styled.span`
  margin-right: 0 !important;
  color: ${({ theme }) => theme.blueJeans()};
  font-size: 32px;
  text-align: center;
  font-weight: 800;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 10px;
  }
`;

export const CheckInParagraph = styled.div`
  font-size: 32px;
  text-align: center;
  color: ${({ theme }) => theme.unitedNationsBlue()};
  font-weight: 800;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 20px;
  }
`;
