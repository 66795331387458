import { IAdvancedAnalyticsSelectors } from "./advanced-analytics.type";

const advancedAnalyticsSelectors: IAdvancedAnalyticsSelectors = {
  selectInProgress: (state) => state.advancedAnalytics.inProgress,
  selectError: (state) => state.advancedAnalytics.error,
  selectAdvancedAnalytics: (state, { group, analyticsType }) =>
    state.advancedAnalytics?.advancedAnalytics?.[group]?.[analyticsType],
  selectGraphData: (state) => state.advancedAnalytics?.selectedGraphData,
};

export default advancedAnalyticsSelectors;
