import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export { ColumnDisplay, LinkText, RowDisplay, Separator, Table } from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPhone8.y;

export const VolunteeringPageWrapper = styled(ColumnDisplay)<{ isEmptyView?: boolean }>`
  height: 100%;
  max-height: 100vh;
  flex: 1;
  width: 100vw;
  max-width: 100vw;
  overflow-x: ${({ isEmptyView }) => (!isEmptyView ? "hidden" : undefined)};
  box-sizing: border-box;
  overflow-y: ${({ isEmptyView }) => (!isEmptyView ? "auto" : undefined)};
  background: ${({ theme }) => theme.aliceBlue()};
`;

export const VolunteeringHeading = styled(GradientHeading)`
  margin: 0px;

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    font-size: 24px;
    word-break: break-word;
    text-align: start;
  }
`;

export const TabSwitch = styled.div<{ isFirst?: boolean; isLast?: boolean; isSelected?: boolean }>`
  background: ${({ isSelected, theme }) => (isSelected ? "rgba(97, 133, 226, 1)" : theme.white())};
  color: ${({ isSelected, theme }) => (isSelected ? "rgba(97, 133, 226, 1)" : theme.white())};
  border: 1px solid
    ${({ isSelected }) => (isSelected ? "rgba(97, 133, 226, 1)" : "rgba(224, 224, 224, 1)")};
  box-sizing: border-box;
  ${({ isFirst }) => isFirst && "border-top-left-radius: 6px; border-bottom-left-radius: 6px;"}
  ${({ isLast }) => isLast && "border-top-right-radius: 6px; border-bottom-right-radius: 6px;"};
  padding: 8px 32px 8px 32px;
  cursor: pointer;
  text-align: center;
`;

export const TabSwitchText = styled.div<{ isSelected?: boolean }>`
  color: ${({ isSelected, theme }) => (isSelected ? theme.white() : theme.charcoalGray())};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

export const VolonteerEventContainer = styled(RowDisplay)`
  padding: 0 150px 85px 150px;

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 15px 20px 85px 20px;
  }
`;

export const SearchAndFilterMainContainer = styled(RowDisplay)`
  padding: 0px 150px 40px 150px;
  max-width: 1462px;

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 15px 20px 20px 20px;
  }
`;

export const WhiteBgContainer = styled.div`
  background: ${({ theme }) => theme.white()};
  justify-content: center;
  width: 100%;
`;

export const PagePaddingContainer = styled.div`
  padding: 0px 150px 0 150px;

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 15px 20px 85px 20px;
  }
`;

export const EmptyViewText = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 300px;
`;

export const SearchAndFilterContainer = styled(RowDisplay)`
  width: 100%;
  padding: 2px;
  border-radius: 12px;
  justify-content: flex-start;
  background: linear-gradient(
    180deg,
    #59aef9 0%,
    #4f7fe3 99.99%,
    ${({ theme }) => theme.unitedNationsBlue(0.43)} 100%
  );
`;

export const SearchAndFilterInnerContainer = styled(RowDisplay)`
  width: fill-available;
  padding: 15px;
  border-radius: 9px;

  background: ${({ theme }) => theme.white()};
`;

export const ResetFilters = styled.div<{ isDisabled: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  color: ${({ isDisabled, theme }) =>
    isDisabled ? "rgba(94, 107, 122, 0.2)" : theme.blackCoral()};
`;

export const MatchesNumber = styled.div`
  font-size: 22px;
  line-height: 26px;
  padding: 0px 150px 30px 150px;
  color: ${({ theme }) => theme.blackCoral(0.6)};

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 15px 20px 85px 20px;
  }
`;
