import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "@primer/react";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setNewPassword } from "store/auth/auth.action";
import authSelectors from "store/auth/auth.selector";
import colors from "theme/colors";
import * as yup from "yup";

import * as S from "./set-new-password.styled";

const schema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .min(8, "Password must have at least 8 characters."),
  repeatedPassword: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

interface ISetNewPasswordPage {
  flow: "company" | "charity";
}

const SetNewPasswordPage: React.FC<ISetNewPasswordPage> = ({ flow }): JSX.Element => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { password: "", repeatedPassword: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const passwordRef = React.useRef(null);
  const repeatedPasswordRef = React.useRef(null);

  const inProgress = useSelector(authSelectors.selectInProgress);

  const onSubmit = ({ password }) => {
    dispatch(setNewPassword.request({ password, resetToken: token, flow }));
  };

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <div />
      <S.FormWrapper>
        <S.GradientHeading>Set New Password</S.GradientHeading>
        <S.Separator height={32} />
        <Text
          sx={{
            textAlign: "center",
            fontSize: 16,
            color: colors.gunmetal(),
          }}
        >
          Please enter new password.
        </Text>
        <S.Separator height={40} />
        <Input
          topLabel="Password"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          type="password"
          control={control}
          ref={passwordRef}
          errorMessage={errors?.password?.message}
          name="password"
          onEnter={() => repeatedPasswordRef?.current?.focus()}
        />
        <S.Separator height={40} />
        <Input
          topLabel="Repeat password"
          isRequired
          sx={{ width: "100%", minWidth: "100%" }}
          control={control}
          type="password"
          ref={repeatedPasswordRef}
          errorMessage={errors?.repeatedPassword?.message}
          name="repeatedPassword"
          onEnter={handleSubmit(onSubmit)}
        />
        <S.Separator height={57} />
        <PrimaryButton
          label="Continue"
          onClick={handleSubmit(onSubmit)}
          sx={{ width: "100%", minWidth: "100%" }}
          isLoading={inProgress}
        />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin showVersion />
    </S.BubbleBackground>
  );
};

export default SetNewPasswordPage;
