export const GET_CHARITIES = "GET_CHARITIES";
export const GET_CHARITIES_SUCCESS = "GET_CHARITIES_SUCCESS";
export const GET_CHARITIES_FAILURE = "GET_CHARITIES_FAILURE";

export const GET_MORE_WALL_CHARITIES = "GET_MORE_WALL_CHARITIES";
export const GET_MORE_WALL_CHARITIES_SUCCESS = "GET_MORE_WALL_CHARITIES_SUCCESS";
export const GET_MORE_WALL_CHARITIES_FAILURE = "GET_MORE_WALL_CHARITIES_FAILURE";

export const GET_MORE_CHARITIES = "GET_MORE_CHARITIES";
export const GET_MORE_CHARITIES_SUCCESS = "GET_MORE_CHARITIES_SUCCESS";
export const GET_MORE_CHARITIES_FAILURE = "GET_MORE_CHARITIES_FAILURE";

export const ADD_TO_COMPANY_WALL = "ADD_TO_COMPANY_WALL";
export const ADD_TO_COMPANY_WALL_SUCCESS = "ADD_TO_COMPANY_WALL_SUCCESS";
export const ADD_TO_COMPANY_WALL_FAILURE = "ADD_TO_COMPANY_WALL_FAILURE";

export const ADD_EVERYORG_TO_COMPANY_WALL = "ADD_EVERYORG_TO_COMPANY_WALL";
export const ADD_EVERYORG_TO_COMPANY_WALL_SUCCESS = "ADD_EVERYORG_TO_COMPANY_WALL_SUCCESS";
export const ADD_EVERYORG_TO_COMPANY_WALL_FAILURE = "ADD_EVERYORG_TO_COMPANY_WALL_FAILURE";

export const REMOVE_FROM_COMPANY_WALL = "REMOVE_FROM_COMPANY_WALL";
export const REMOVE_FROM_COMPANY_WALL_SUCCESS = "REMOVE_FROM_COMPANY_WALL_SUCCESS";
export const REMOVE_FROM_COMPANY_WALL_FAILURE = "REMOVE_FROM_COMPANY_WALL_FAILURE";

export const REMOVE_EVERYORG_FROM_COMPANY_WALL = "REMOVE_EVERYORG_FROM_COMPANY_WALL";
export const REMOVE_EVERYORG_FROM_COMPANY_WALL_SUCCESS =
  "REMOVE_EVERYORG_FROM_COMPANY_WALL_SUCCESS";
export const REMOVE_EVERYORG_FROM_COMPANY_WALL_FAILURE =
  "REMOVE_EVERYORG_FROM_COMPANY_WALL_FAILURE";
