import { ICompanyPendingVolunteerEventsSelectors } from "./company-pending-volunteer-events.type";

const companyPendingVolunteerEventsSelectors: ICompanyPendingVolunteerEventsSelectors = {
  selectData: (state) => state.companyPendingVolunteerEvents.data,
  selectDto: (state) => state.companyPendingVolunteerEvents.dto,
  selectInProgress: (state) => state.companyPendingVolunteerEvents.inProgress,
  selectEndReached: (state) => state.companyPendingVolunteerEvents.isEndReached,
  selectIsFetched: (state) => state.companyPendingVolunteerEvents.isFetched,
  selectPage: (state) => state.companyPendingVolunteerEvents.page,
  selectResults: (state) => state.companyPendingVolunteerEvents.results,
  selectError: (state) => state.companyPendingVolunteerEvents.error,
};

export default companyPendingVolunteerEventsSelectors;
