import SecondaryButton from "components/buttons/secondary";
import { CalendarCheckIcon } from "icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "utils/routes";

import * as S from "./event-card.styled";

const buttonProps = { width: "173px", height: "28px" };

interface IEventCard {
  id: string;
  name: string;
  onEditEvent?: () => void;
}

const EventCard: React.FC<IEventCard> = ({ id, name, onEditEvent }) => {
  const navigate = useNavigate();

  return (
    <S.VolunteerEventCardContainer
      justifyContent="flex-start"
      onClick={() => navigate(`${routes.VOLUNTEER.EVENT}/${id}`)}
    >
      <CalendarCheckIcon />
      <S.VolunteerEventCardTitle>{name}</S.VolunteerEventCardTitle>
      {onEditEvent && (
        <SecondaryButton
          label="Edit Event"
          sx={buttonProps}
          onClick={(e) => {
            e.stopPropagation();
            onEditEvent();
          }}
        />
      )}
    </S.VolunteerEventCardContainer>
  );
};

EventCard.defaultProps = {
  onEditEvent: null,
};

export default EventCard;
