import { configureStore } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";

import { getCacheState } from "./store.cache";
import { rootEpic, rootReducer } from "./store.modules";

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), epicMiddleware],
  preloadedState: getCacheState(),
});

// @ts-ignore
epicMiddleware.run(rootEpic);

export default store;
