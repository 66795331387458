import styled from "styled-components";
import { ColumnDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export { ColumnDisplay, RowDisplay, Separator, Tag } from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPhone5.y;

export const VolunteerEventCardGradientContainer = styled(ColumnDisplay)`
  width: 100%;
  height: calc(100% + 3px);
  border-radius: 12px;
  position: absolute;
  transition: opacity 200ms ease-in-out;
  background: linear-gradient(
    180deg,
    #6feff4 0%,
    #6186e2 99.99%,
    ${({ theme }) => theme.unitedNationsBlue(0.43)} 100%
  );
  opacity: 0;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 318px;
    max-width: 318px;
  }
`;

export const VolunteerEventCardContainer = styled(ColumnDisplay)<{ isBigCard: boolean }>`
  width: ${({ isBigCard }) => (isBigCard ? 450 : 332)}px;
  max-width: ${({ isBigCard }) => (isBigCard ? 450 : 332)}px;
  min-height: 261px;
  border-radius: 12px;
  flex: 1;
  position: relative;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);

  &:hover {
    box-shadow: 0px 8px 40px rgba(66, 74, 83, 0.5), 0px 3px 8px rgba(27, 31, 36, 0.3);
  }

  &:hover ${VolunteerEventCardGradientContainer} {
    opacity: 1;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 318px;
    max-width: 318px;

    &:hover {
      box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);
    }
  }
`;

export const VolunteerEventCardInnerContainer = styled(ColumnDisplay)<{ isBigCard: boolean }>`
  width: ${({ isBigCard }) => (isBigCard ? 447 : 328)}px; 328px;
  max-width: ${({ isBigCard }) => (isBigCard ? 447 : 328)}px; 328px;
  min-height: 258px;
  height: 100%;
  box-sizing: border-box;
  padding: 18px;
  border-radius: 12px;
  position: relative;
  background: ${({ theme }) => theme.white()};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 315px;
    max-width: 315px;
  }
`;

export const VolunteerEventImg = styled.img`
  height: 44px;
  width: auto;
`;

export const VolunteerEventName = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.gunmetal()};
  max-width: 183px;
`;

export const VolunteerEventDesc = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const VolunteerEventAddress = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.blackCoral()};
`;

export const QRContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.ghostWhite()};
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.brightBlack(0.15)};
  gap: 6px;
  border-radius: 10px;
  cursor: pointer;

  color: ${({ theme }) => theme.unitedNationsBlue()};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
`;

export const EventBannerFull = styled.div`
  background: ${({ theme }) => theme.lightRed()};
  max-width: 108px;
  max-height: 34px;
  border-radius: 45px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;

  position: absolute;
  top: 100px;
  right: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  color: ${({ theme }) => theme.warningRed()};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;
