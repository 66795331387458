import { ICompanySelectors } from "./company.type";

const companySelectors: ICompanySelectors = {
  selectInProgress: (state) => state.company.inProgress,
  selectError: (state) => state.company.error,
  selectName: (state) => state.company?.data?.name ?? null,
  selectLogoUrl: (state) => state.company?.data?.logoUrl ?? null,
  selectBankAccountVerified: (state) => state.company?.data?.bankAccountVerified ?? false,
  selectDashboardSummary: (state) => state.company?.summary,
  selectFinchKeyAdded: (state) => state.company?.data?.finchKeyAdded ?? false,
  selectCalendar: (state) => state.company?.data?.calendarId ?? null,
  selectEmployeeInterests: (state) => state.company?.interests ?? null,
  selectCharitiesSupported: (state) => state.company?.charitiesSupported ?? null,
  selectDonationAnalytics: (state) => state.company?.donationAnalytics ?? null,
  selectGiftMatchingAnalytics: (state) => state.company?.giftMatchingAnalytics ?? null,
  selectVolunteeringAnalytics: (state) => state.company?.volunteeringAnalytics ?? null,
  selectOnboardingDto: (state) => state.company?.dto,
};

export default companySelectors;
