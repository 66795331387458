import LogoAndCompany from "components/logo-and-company";
import React from "react";

import * as S from "./not-found.style";

const NotFoundPage: React.FC<{}> = (): JSX.Element => (
  <S.BubbleBackground justifyContent="space-between" gridGap={24}>
    <div />
    <S.NotFoundContainer>
      <S.GradientHeading fontSize={180}>404</S.GradientHeading>
      <S.Separator height={40} />
      <S.NotFoundHeading>The page you requested could not be found.</S.NotFoundHeading>
      <S.Separator height={20} />
      <S.NotFoundParagraph>
        The page you are looking for might have been removed, had its name
        <br />
        changed, or is temporary unavailable.
      </S.NotFoundParagraph>
      <S.Separator height={60} />
    </S.NotFoundContainer>
    <LogoAndCompany />
  </S.BubbleBackground>
);

export default NotFoundPage;
