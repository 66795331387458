export const ERROR_MAPPER = {
  SOMETHING_WENT_WRONG: "Something went wrong.",
  INVALID_DATA_SENT: "Invalid data sent.",
  COMPANY_NAME_ALREADY_EXISTS: "Company with given name already exists.",
  COMPANY_DOMAIN_ALREADY_EXISTS: "Company with given domain already exists.",
  EMAIL_NOT_SENT: "E-mail could not be sent.",
  INVALID_VERIFICATION_LINK: "Provided verification link is not valid.",
  MISSING_ACCESS_TOKEN: "Access token is missing.",
  INVALID_ACCESS_TOKEN: "Access token is invalid.",
  NOT_AUTHORIZED: "Unauthorized access.",
  INVALID_CREDENTIALS: "User with given username and password does not exist.",
  COMPANY_NOT_EXISTS: "The requested company does not exist.",
  USER_NOT_EXISTS: "The requested user does not exist.",
  USER_ALREADY_EXISTS: "A verified user with the same email already exists.",
  CHARITY_EIN_ALREADY_EXISTS: "A charity with the same EIN already exists.",
  EMAIL_DOMAINS_NOT_MATCH: "E-mail domain must match company domain.",
  COMPANY_DOMAIN_GENERIC: "Company domain cannot be generic.",
  CANDID_API_ERROR: "Charity could not be verified. Please try again later.",
  CHARITY_NAME_ALREADY_EXISTS: "A charity with the same name already exists.",
  CHARITY_NOT_EXISTS: "The requested charity does not exist.",
  CANDID_CHARITY_DATA_ERROR: "The charity data was not found.",
  BACKED_CHARITIES_UNIQUE: "Charity already backed.",
  BACKED_CHARITIES_NOT_EXISTS: "Charity is not backed by current company.",
  CODE_EXPIRED: "The authentication code has expired.",
  INVALID_DATE_OF_BIRTH:
    "The date is missing, invalid, the format is not YYYY-MM-DD, or the age requirement of '18' was not met.",
  STRIPE_ERROR: "Error when interacting with Stripe API.",
  UNABLE_TO_RECEIVE_DONATIONS: "The requested charity can not receive donations currently.",
  INVALID_STRIPE_SIGNATURE: "Invalid Stripe webhooks signature.",
  UNSUPPORTED_EVENT_TYPE: "Unsupported Stripe webhooks event type signature.",
  CHARITY_NOT_APPLICABLE_ERROR:
    "At this time Fundwurx only allows 501(c)(3) Public organizations on the platform, sorry for the inconvenience.",
  INVALID_DONATION_VALUE:
    "The donation value is invalid. Check value format and minimum and maximum values.",
  INVALID_SEARCH_QUERY_LENGTH: "Search query must be between 3 and 50 characters long.",
  FILES_MISSING: "Files from the request fields are missing or invalid.",
  INVALID_GIFTMATCH_VALUE:
    "The gift matching value is invalid. Check value format and minimum and maximum values.",
  INVALID_GIFTMATCH_FREQUENCY: "The gift matching frequency is invalid.",
  GIFT_MATCH_NOT_EXISTS: "Gift match program for the requested company does not exist.",
  GIFT_MATCH_NOT_APPLICABLE: "Gift match program not active or invalid donation amount.",
  GIFT_MATCH_DONATION_NOT_EXISTS: "Requested pending gift match donation not found.",
  PAYMENTS_NOT_SETUP: "Company has not set up the payment methods.",
  GIFT_MATCH_ALREADY_EXISTS: "Gift Match program already exists.",
  EVERY_ORG_API_ERROR: "Error when interacting with Every.org API.",
  INVALID_EVERY_ORG_CAUSE: "Invalid Every.org cause received.",
  EXPIRED_VERIFICATION_LINK: "Provided verification link has expired, please request a new one.",
  USER_NOT_VERIFIED: "Please verify your account before logging in.",
  INVALID_DATE_RANGE: "Provided date range is invalid.",
  INVALID_SORT_BY_CRITERIA: "Provided sorting criteria is invalid.",
  CHARITY_NOT_EXISTS_CONTACT_SUPPORT:
    "A charity with the requested EIN does not exist in our system. If you have any questions please email an administrator for further assistance.",
  FINCH_ERROR: "Error when interacting with Finch API.",
  UNHANDLED_VALIDATION: "Unhandled validation for provided request data.",
  PAYMENT_DATA_NOT_EXISTS: "The reuqest payment data does not exist.",
  SERVICE_NOT_AVAILABLE: "Error when interacting with utility service.",
  VOLUNTEER_EVENT_NOT_EXISTS: "The requested volunteer event does not exist.",
  MISSING_REQUIRED_DATA: "The request is missing required fields, or fields are empty.",
  INVALID_VOLUNTEERS_NEEDED_RANGE: "The volunteers needed range is invalid.",
  INVALID_VOLUNTEER_EVENT_TIME: "The volunteer event time is invalid.",
  MALFORMED_REQUEST: "The request is malformed.",
  AWS_ERROR: "Error when interacting with AWS.",
  CHARITY_STANDING_ERROR: "The charity is not in good standing.",
  AUTHENTICATION_BLOCKED: "Blocked from authenticating.",
  INVALID_EMAIL_FORMAT: "Invalid e-mail format.",
  INVALID_PASSWORD_LENGTH: "Password must be at least 8 characters long.",
  INVALID_EIN: "Invalid EIN received.",
  INVALID_PHONE_NUMBER: "Invalid phone number received.",
  INVALID_URL_FORMAT: "Invalid URL format received.",
  INVALID_UN_GOALS: "Invalid UN goals received.",
  INVALID_COMPANY_NAME: "Company name is invalid.",
  INVALID_COMPANY_DOMAIN: "Company domain is invalid.",
  INVALID_DEPARTMENTS: "Invalid departments received.",
  INVALID_NAME_RECEIVED: "First name and last name can't be the same as organization name.",
  SEON_API_ERROR:
    "We couldn’t complete your request at this time and require additional verification. We will reach out to confirm your information. Thank you.",
  AML_CHECK_FAILED:
    "We couldn’t complete your request at this time and require additional verification. We will reach out to confirm your information. Thank you.",
  FILE_NOT_EXISTS: "The requested file does not exist.",
  WRONG_AUTHORIZATION_METHOD: "Please use another authorization method to sign in.",
  VOLUNTEER_EVENT_NOT_AVAILABLE: "The requested volunteer event is not available at the moment.",
  ALREADY_CHECKED_IN: "You have already checked in for this volunteer event.",
  VOLUNTEER_EVENT_FULL:
    "Unfortunately, this event is at capacity. Please sign up for a different event.",
  CHARITY_HIDDEN: "This charity is not currently available on the platform.",
};

export const mapErrorCode = (code: string): string =>
  ERROR_MAPPER[code] ?? ERROR_MAPPER.SOMETHING_WENT_WRONG;
