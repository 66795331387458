import styled from "styled-components";
import { ColumnDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export { ColumnDisplay, RowDisplay, Separator } from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.iPadMini.x;

export const HorizontalScrollContainer = styled(ColumnDisplay)<{ paddingLeft?: number }>`
  overflow-x: scroll;
  max-width: 100%;
  width: 100%;
  height: auto;
  padding: 40px;
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}px;`}
  box-sizing: border-box;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  ::-webkit-scrollbar {
    display: none;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 16px;
  }
`;
