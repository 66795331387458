import { Tooltip } from "@primer/react";
import React from "react";

import * as S from "../manage-people.styled";
import { IAnalyticDisplay } from "../manage-people.type";

const AnalyticDisplay: React.FC<IAnalyticDisplay> = ({
  number,
  percent,
  paragraph,
  children,
  color,
  percentBackgroundColor,
  fullWidth,
  width,
  fontWeight,
  numberFontSize,
  percentTextColor,
  secondPercent,
  secondPercentBackgroundColor,
  secondPercentTextColor,
  infoText,
  onPercentClick,
}): JSX.Element => (
  <S.AnalyticDisplayContainer width={width || ""} fullWidth={fullWidth || false}>
    <S.RowDisplayAnalytics>
      {children}
      <S.ColumnDisplayAnalytics>
        <S.AnalyticDisplayParagraph fontWeight={fontWeight || ""}>
          {paragraph}
          {infoText && (
            <Tooltip text={infoText} sx={{ borderRadius: 6, textAlign: "center" }}>
              <S.AnalyticDisplayInfoIcon size={14} />
            </Tooltip>
          )}
        </S.AnalyticDisplayParagraph>
        {color ? (
          <S.AnalyticNumber numberFontSize={numberFontSize || ""} color={color}>
            {number}
          </S.AnalyticNumber>
        ) : (
          <S.AnalyticGradientNumber numberFontSize={numberFontSize || ""}>
            {number}
          </S.AnalyticGradientNumber>
        )}
      </S.ColumnDisplayAnalytics>
      <S.AnalyticPercentageContainer>
        {percent && (
          <S.AnalyticPercentage
            onClick={() => onPercentClick && onPercentClick()}
            color={percentTextColor || color}
            backgroundColor={percentBackgroundColor}
            isClickable={!!onPercentClick}
          >
            {percent}
          </S.AnalyticPercentage>
        )}
        {secondPercent && (
          <S.AnalyticPercentage
            color={secondPercentTextColor || color}
            backgroundColor={secondPercentBackgroundColor}
          >
            {secondPercent}
          </S.AnalyticPercentage>
        )}
      </S.AnalyticPercentageContainer>
    </S.RowDisplayAnalytics>
  </S.AnalyticDisplayContainer>
);

export default AnalyticDisplay;
