import * as api from "api/volunteer";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { refreshActiveCompanyVolunteerEvents } from "store/company-active-volunteer-events/company-active-volunteer-events.action";
import { refreshVolunteerEvents } from "store/company-volunteer-events/company-volunteer-events.action";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { showToast } from "store/ui-components/ui-components.actions";
import { DELETED } from "utils/status-code";

import {
  activatePendingCompanyVolunteerEvent,
  cancelPendingCompanyVolunteerEvent,
  fetchPendingCompanyVolunteerEvents,
  refreshPendingCompanyVolunteerEvents,
} from "./company-pending-volunteer-events.action";
import * as C from "./company-pending-volunteer-events.constants";
import * as T from "./company-pending-volunteer-events.type";

export const fetchCompanyPendingVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IFetchPendingVolunteerEvents>,
) =>
  action$.pipe(
    ofType(C.FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS),
    mergeMap((action) =>
      from(api.getScheduledVolunteerEvents(action.payload.params, action.payload.status)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchPendingCompanyVolunteerEvents.success(response.data));
          }
          return of(
            handleError({ action, error: null }),
            fetchPendingCompanyVolunteerEvents.failure(),
          );
        }),
        catchError((error) =>
          of(fetchPendingCompanyVolunteerEvents.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export const refreshCompanyPendingVolunteerEventsEpic: AppEpic = (
  action$: Observable<T.IRefreshPendingVolunteerEvents>,
) =>
  action$.pipe(
    ofType(C.REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS),
    mergeMap((action) =>
      from(api.getScheduledVolunteerEvents(action.payload.params, action.payload.status)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(refreshPendingCompanyVolunteerEvents.success(response.data));
          }
          return of(
            handleError({ action, error: null }),
            refreshPendingCompanyVolunteerEvents.failure(),
          );
        }),
        catchError((error) =>
          of(refreshPendingCompanyVolunteerEvents.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export const cancelPendingVolunteerEventEpic: AppEpic = (
  action$: Observable<T.ICancelPendingVolunteerEvent>,
) =>
  action$.pipe(
    ofType(C.CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT),
    mergeMap((action) =>
      from(api.removeVolunteerEvent(action.payload.id)).pipe(
        mergeMap((response) => {
          if (response === DELETED) {
            return of(
              cancelPendingCompanyVolunteerEvent.success(action.payload),
              showToast({ message: "Event canceled.", type: "success" }),
              refreshVolunteerEvents.request({ page: 1 }),
            );
          }
          return of(
            handleError({ action, error: null }),
            cancelPendingCompanyVolunteerEvent.failure(action.payload),
          );
        }),
        catchError((error) =>
          of(
            cancelPendingCompanyVolunteerEvent.failure(action.payload),
            handleError({ action, error }),
          ),
        ),
      ),
    ),
  );

export const activatePendingVolunteerEventEpic: AppEpic = (
  action$: Observable<T.IActivatePendingVolunteerEvent>,
) =>
  action$.pipe(
    ofType(C.ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT),
    mergeMap((action) => {
      const { id, ...activateEventData } = action.payload; // Exclude 'id' property;
      return from(api.activateVolunteerEvent(action.payload.id, activateEventData)).pipe(
        mergeMap((response) => {
          if (response === 200) {
            return of(
              activatePendingCompanyVolunteerEvent.success(action.payload),
              showToast({ message: "Event activated.", type: "success" }),
              refreshPendingCompanyVolunteerEvents.request({
                params: { page: 1 },
                status: "PENDING",
              }),
              refreshActiveCompanyVolunteerEvents.request({
                params: { page: 1 },
                status: "ACTIVE",
              }),
            );
          }
          return of(
            handleError({ action, error: null }),
            activatePendingCompanyVolunteerEvent.failure(action.payload),
          );
        }),
        catchError((error) =>
          of(
            activatePendingCompanyVolunteerEvent.failure(action.payload),
            handleError({ action, error }),
          ),
        ),
      );
    }),
  );

export default combineEpics(
  fetchCompanyPendingVolunteerEventsEpic,
  cancelPendingVolunteerEventEpic,
  activatePendingVolunteerEventEpic,
  refreshCompanyPendingVolunteerEventsEpic,
);
