import React, { useEffect, useRef, useState } from "react";
import { Control, Controller } from "react-hook-form";
import colors from "theme/colors";
import { SelectItem } from "types/content";

import * as S from "./custom-multiselect-component.styled";

interface ControlledSelectProps {
  items: SelectItem[];
  placeholder?: string;
  name: string;
  control: Control<any>;
  onSelect?: (value: SelectItem[]) => void;
}

const ControlledCustomMultiselect: React.FC<ControlledSelectProps> = ({
  items,
  placeholder,
  name,
  control,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        selectContainerRef.current &&
        !selectContainerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleSelect = (selectedValues: SelectItem[], value: string, formChange: any): void => {
    const existingIndex = selectedValues.findIndex((item) => item.value === value);
    if (existingIndex !== -1) {
      formChange(selectedValues.filter((_, index) => index !== existingIndex));
    } else {
      const newItem = items.find((item) => item.value === value);
      if (newItem) {
        formChange([...selectedValues, newItem]);
      }
    }
  };

  const handleSelect = (selectedValues: Array<SelectItem>): void => {
    setIsOpen(false);
    if (onSelect) {
      onSelect(selectedValues);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value: selectedValues, onChange: formChange } }) => (
        <S.RelativeContainer ref={selectContainerRef}>
          <S.SelectContainer>
            <S.SelectedValue onClick={() => setIsOpen(!isOpen)}>
              <S.PlaceholderText>
                {selectedValues?.length > 0 ? (
                  <>
                    {placeholder} <S.SelectedNumber>({selectedValues.length})</S.SelectedNumber>
                  </>
                ) : (
                  placeholder || "Select..."
                )}
              </S.PlaceholderText>
              <S.ArrowIcon />
            </S.SelectedValue>
          </S.SelectContainer>
          {isOpen && (
            <S.SelectList>
              {items.map((item, index) => (
                <S.Option
                  key={index}
                  onClick={() => toggleSelect(selectedValues, item.value, formChange)}
                >
                  <S.Checkbox
                    checked={selectedValues?.some(
                      (selectedItem: SelectItem) => selectedItem.value === item.value,
                    )}
                  />
                  <span>{item.text}</span>
                </S.Option>
              ))}
              <S.ButtonContainer>
                <S.TextButton color={colors.gunmetal(0.2)} onClick={() => setIsOpen(false)}>
                  Cancel
                </S.TextButton>
                <S.TextButton onClick={() => handleSelect(selectedValues)}>Confirm</S.TextButton>
              </S.ButtonContainer>
            </S.SelectList>
          )}
        </S.RelativeContainer>
      )}
    />
  );
};

ControlledCustomMultiselect.defaultProps = {
  onSelect: undefined,
  placeholder: undefined,
};

export default ControlledCustomMultiselect;
