import PrimaryButton from "components/buttons/primary";
import React from "react";
import { useDispatch } from "react-redux";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import { resetVolunteerEventDto } from "store/volunteer-events-charity/volunteer-events-charity.action";

import * as S from "./post-event-card.styled";

const PostEventCard: React.FC = () => {
  const dispatch = useDispatch();

  const handlePostEventClick = () => {
    dispatch(resetVolunteerEventDto());
    dispatch(
      updateDialogState({
        key: "POST_EVENT_INSTRUCTIONS",
        isOpened: true,
      }),
    );
  };

  return (
    <S.VolunteerEventCardContainer>
      <S.CalendarIconStyled />
      <PrimaryButton
        label="Post Event"
        onClick={handlePostEventClick}
        sx={{ width: "100%", maxWidth: "176px" }}
      />
    </S.VolunteerEventCardContainer>
  );
};

export default PostEventCard;
