import { IManagePeopleSelectors } from "./manage-people.type";

const managePeopleSelectors: IManagePeopleSelectors = {
  selectData: (state, key) => state.managePeople[key].data,
  selectTableEndReached: (state, key) => state.managePeople[key].isEndReached,
  selectTableProgress: (state, key) => state.managePeople[key].inProgress,
  selectTableFetched: (state, key) => state.managePeople[key].isFetched,
  selectTablePage: (state, key) => state.managePeople[key].page,
  selectNumAdmins: (state) => state.managePeople.admins.results,
  selectNumEmployees: (state) => state.managePeople.employees.results,
  selectInProgress: (state) => state.managePeople.inProgress,
  selectIsFetched: (state) => state.managePeople.isFetched,
  selectStatistics: (state) => state.managePeople.statistics,
  selectError: (state) => state.managePeople.error,
};

export default managePeopleSelectors;
