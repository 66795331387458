import { AdminTabType, Charity, EmployeeTabType } from "types/charity";
import { CharityListPagination, CharityWallListPagination } from "types/company-wall";
import { genUrlParams } from "utils/helper";

import client from "./client";

export const getCharities = async (
  tab: EmployeeTabType | AdminTabType,
  params?: Partial<CharityListPagination | CharityWallListPagination>,
): Promise<{ [key: string]: Charity[] }> => {
  const baseUrl = tab === "wall" || tab === "company" ? "/company/wall" : "charity/list";
  return (await client.get(params ? genUrlParams(baseUrl, params) : baseUrl)).data.data.data;
};

export const addToCharityList = async (id: string): Promise<any> =>
  (await client.post("/company/wall", { id })).status;

export const removeFromCharityList = async (id: string): Promise<number> =>
  (await client.delete("/company/wall", { data: { id } })).status;

export const addToEveryOrgCharityList = async (slug: string): Promise<any> =>
  (await client.post("/company/wall", { slug })).status;

export const removeEveryOrgFromCharityList = async (slug: string): Promise<number> =>
  (await client.delete("/company/wall", { data: { slug } })).status;
