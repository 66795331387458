import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import * as C from "./volunteer-event-details.constants";
import * as T from "./volunteer-event-details.type";

export const volunteerEventDetailsInitial: T.IVolunteerEventDetailsState = {
  data: null,
  inProgress: false,
  isFetched: false,
  error: null,
};

const volunteerEventDetailsReducer = (
  state: T.IVolunteerEventDetailsState,
  action: T.VolunteerEventDetailsAction | ILogout,
): T.IVolunteerEventDetailsState => {
  if (!state) {
    return volunteerEventDetailsInitial;
  }

  switch (action.type) {
    case C.FETCH_VOLUNTEER_EVENT:
      return {
        ...state,
        inProgress: true,
      };
    case C.FETCH_VOLUNTEER_EVENT_SUCCESS:
      return {
        ...state,
        data: { ...action.payload, attends: state.data?.attends ?? false },
        inProgress: false,
        isFetched: true,
        error: null,
      };
    case C.FETCH_VOLUNTEER_EVENT_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
        error: action.payload ?? null,
      };
    case C.ATTEND_VOLUNTEER_EVENT:
      return {
        ...state,
        data: {
          ...state.data,
          attends: true,
        },
      };
    case C.ATTEND_VOLUNTEER_EVENT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          attends: false,
        },
      };
    case C.UNATTEND_VOLUNTEER_EVENT:
      return {
        ...state,
        data: { ...state.data, attends: false },
      };
    case C.UNATTEND_VOLUNTEER_EVENT_FAILURE:
      return {
        ...state,
        data: { ...state.data, attends: true },
      };
    case C.REQUEST_VOLUNTEER_EVENT_INTRO:
      return {
        ...state,
        inProgress: true,
      };
    case C.REQUEST_VOLUNTEER_EVENT_INTRO_SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: {
          ...state.data,
          status: "PENDING",
        },
      };
    case C.REQUEST_VOLUNTEER_EVENT_INTRO_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.FETCH_VOLUNTEER_EVENT_ATTENDING:
      return {
        ...state,
        inProgress: true,
      };
    case C.FETCH_VOLUNTEER_EVENT_ATTENDING_SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: { ...state.data, attends: action.payload },
      };
    case C.FETCH_VOLUNTEER_EVENT_ATTENDING_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case LOGOUT:
      return volunteerEventDetailsInitial;
    default:
      return state;
  }
};

export default volunteerEventDetailsReducer;
