import { XIcon } from "icons";
import React from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import companySelectors from "store/company/company.selector";
import { IAppState } from "store/store.state";
import { showToast, updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import colors from "theme/colors";

import * as S from "../dialogs.styled";

const DIALOG_KEY = "QR_CODE";

const QRCodeDialog: React.FC = () => {
  const dispatch = useDispatch();
  const companyName = useSelector(companySelectors.selectName);

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, DIALOG_KEY),
  );
  const properties = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogProperties(state, DIALOG_KEY),
  );

  const eventId = properties?.id || "";
  const eventName = encodeURIComponent(properties?.eventName || "");
  const charityName = encodeURIComponent(properties?.charityName || "");

  const isMobile = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: DIALOG_KEY, isOpened: false }));
  };

  const handleDownload = (): void => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    const padding = 60;
    const textSpace = 150;

    img.onload = () => {
      canvas.width = img.width + 2 * padding;
      canvas.height = img.height + 2 * padding + textSpace;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.font = "bold 20px arial";
      ctx.fillText("QR Code Check-In For Your Event", canvas.width / 2, 50);
      ctx.font = "20px arial";
      ctx.fillText(`${properties?.eventName}`, canvas.width / 2, 100);
      ctx.font = "15px Arial";
      ctx.fillText(`${properties?.date}`, canvas.width / 2, 130);
      ctx.fillText(`${properties?.startTime} - ${properties?.endTime}`, canvas.width / 2, 160);
      ctx.drawImage(img, padding, padding + textSpace, img.width, img.height);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const handleCopy = (): void => {
    const qrCodeValue = `${window.__RUNTIME_CONFIG__.REACT_APP_URL}${companyName}/${charityName}/${eventName}/${eventId}/check-in`;
    navigator.clipboard
      .writeText(qrCodeValue)
      .then(() => dispatch(showToast({ message: "Copied!", type: "success" })))
      .catch(() =>
        dispatch(showToast({ message: "Failed to copy QR code to clipboard", type: "failure" })),
      );
  };

  const handlePrint = (): void => {
    const svg = document.getElementById("QRCode");
    if (!svg) {
      return;
    }

    const printWindow = window.open("", "QR Code Check-In For Your Event", "width=600,height=600");
    if (printWindow) {
      printWindow.document.write(
        "<html><head><title>QR Code Check-In For Your Event</title><style>",
      );
      printWindow.document.write(
        "body { display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center; height: 95vh; font-family: Arial; }",
      );
      printWindow.document.write("p { font-size: 20px; margin: 7px; }");
      printWindow.document.write(
        ".heading { max-width: 500px; font-size: 30px; font-weight: 800; margin-bottom: 50px; }",
      );
      printWindow.document.write("</style></head><body>");
      printWindow.document.write("<p class='heading'>QR Code Check-In For Your Event</p>");
      printWindow.document.write(
        `<p style='font-size: 30px; font-weight: 500'>${properties?.eventName}</p>`,
      );
      printWindow.document.write(`<p>${properties?.date}</p>`);
      printWindow.document.write(
        `<p style='margin-bottom: 50px'>${properties?.startTime} - ${properties?.endTime}</p>`,
      );
      printWindow.document.write(svg.outerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    }
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20}>
          <S.CloseIconContainer>
            <S.CloseIconButton type="button" onClick={closeDialog}>
              <XIcon />
            </S.CloseIconButton>
          </S.CloseIconContainer>
          <S.GradientHeading fontSize={28}>QR Code Check-In For Your Event</S.GradientHeading>
          <S.Separator height={isMobile ? 40 : 80} />
          <QRCode
            id="QRCode"
            size={256}
            style={{ height: "auto", width: "275px" }}
            value={`${window.__RUNTIME_CONFIG__.REACT_APP_URL}${companyName}/${charityName}/${eventName}/${eventId}/check-in`}
            fgColor={colors.unitedNationsBlue()}
            viewBox="0 0 256 256"
          />
          <S.Separator height={20} />
          <S.RowDisplay gridGap={15}>
            <S.LinkText onClick={handleDownload}>Download</S.LinkText>
            <S.BlueText>|</S.BlueText>
            <S.LinkText onClick={handleCopy}>Copy</S.LinkText>
            <S.BlueText>|</S.BlueText>
            <S.LinkText onClick={handlePrint}>Print</S.LinkText>
            <div> </div>
          </S.RowDisplay>
          <S.Separator height={isMobile ? 20 : 50} />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default QRCodeDialog;
