export default {
  white: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`, // #FFFFFF
  ghostWhite: (opacity = 1) => `rgba(246, 248, 250, ${opacity})`, // #F6F8FA
  black: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`, // #000000
  blackCoral: (opacity = 1) => `rgba(87, 96, 106, ${opacity})`, // #57606A
  unitedNationsBlue: (opacity = 1) => `rgba(97, 134, 226, ${opacity})`, // #6185E2
  blueYonder: (opacity = 1) => `rgba(73, 103, 182, ${opacity})`, // #4967B6
  charcoalGray: (opacity = 1) => `rgba(36, 41, 47, ${opacity})`, // #24292F
  tealDeer: (opacity = 1) => `rgba(141, 227, 181, ${opacity})`, // #8DE3B5
  bordo: (opacity = 1) => `rgba(207, 34, 46, ${opacity})`, // #CF222E
  paleRobinEggBlue: (opacity = 1) => `rgba(141, 227, 217, ${opacity})`, // #8DE3D9
  aliceBlue: (opacity = 1) => `rgba(242, 247, 252, ${opacity})`, // #F2F7FC
  blueJeans: (opacity = 1) => `rgba(89, 174, 248, ${opacity})`, // #59AEF8
  gunmetal: (opacity = 1) => `rgba(30, 52, 59, ${opacity})`, // #1E343B
  spaceCodet: (opacity = 1) => `rgba(19, 44, 95, ${opacity})`, // #132C5F
  mantee: (opacity = 1) => `rgba(154, 160, 167, ${opacity})`, // #9AA0A7
  eerieBlack: (opacity = 1) => `rgba(28, 27, 31, ${opacity})`, // #1C1B1F
  quartz: (opacity = 1) => `rgba(73, 69, 79, ${opacity})`, // #49454F
  grayX11: (opacity = 1) => `rgba(183, 187, 192, ${opacity})`, // #B7BBC0
  lightSilver: (opacity = 1) => `rgba(208, 215, 222, ${opacity})`, // #D0D7DE
  trueBlue: (opacity = 1) => `rgba(9, 105, 218, ${opacity})`, // #0969DA
  amazon: (opacity = 1) => `rgba(64, 132, 93, ${opacity})`, // #40845D
  metallicSilver: (opacity = 1) => `rgba(164, 172, 179, ${opacity})`, // #A4ACB3
  hanBlue: (opacity = 1) => `rgba(75, 114, 218, ${opacity})`, // #4B72DA
  richBlack: (opacity = 1) => `rgba(2, 10, 26, ${opacity})`, // #020A1A
  silverFoil: (opacity = 1) => `rgba(171, 176, 181, ${opacity})`, // #ABB0B5
  lightSteelBlue: (opacity = 1) => `rgba(167, 196, 229, ${opacity})`, // #A7C4E5
  cultured: (opacity = 1) => `rgba(245, 245, 245, ${opacity})`, // #F5F5F5
  brightGray: (opacity = 1) => `rgba(234, 238, 242, ${opacity})`, // #EAEEF2
  warning: (opacity = 1) => `rgba(184, 153, 29, ${opacity})`, // #B8991D
  warningRed: (opacity = 1) => `rgba(207, 34, 46, ${opacity})`, // #CF222E
  lightRed: (opacity = 1) => `rgba(255, 235, 233, ${opacity})`, // #FFEBE9
  disabledGray: (opacity = 1) => `rgba(175,184,193, ${opacity})`, // #AFB8C1
  brightBlack: (opacity = 1) => `rgba(27, 31, 36, ${opacity})`, // 1B1F24
  grey: (opacity = 1) => `rgba(110, 119, 129, ${opacity})`, // #6E7781
  separatorGray: (opacity = 1) => `rgba(242, 242, 242, ${opacity})`, // #F2F2F2
  lightGreen: (opacity = 1) => `rgba(112, 223, 210, ${opacity})`, // #70DFD2
  green: (opacity = 1) => `rgba(99, 204, 191, ${opacity})`, // #63CCBF
  lightPurple: (opacity = 1) => `rgba(155, 92, 255, ${opacity})`, // #9B5CFF
  waikawaGrey: (opacity = 1) => `rgba(90, 107, 143, ${opacity})`, // #5A6B8F
  saveGreen: (opacity = 1) => `rgba(74, 194, 107, ${opacity})`, // #4AC26B
  transparent: "transparent",
};
