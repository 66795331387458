import * as api from "api/charity";
import { combineEpics, ofType } from "redux-observable";
import CustomHistory from "router/history";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap, tap } from "rxjs/operators";
import { signInCredentials } from "store/auth/auth.action";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import {
  hideFullScreenLoader,
  showFullScreenLoader,
  showToast,
  updateDialogState,
} from "store/ui-components/ui-components.actions";
import { fetchUser } from "store/user/user.action";
import { LOKKING_FOR_MAPPER } from "utils/content";
import { mapErrorCode } from "utils/error-mapper";
import { getErrorCode } from "utils/helper";
import routes from "utils/routes";
import { ACCEPTED } from "utils/status-code";
import storageKeys from "utils/storage-keys";

import * as actions from "./charity.action";
import * as C from "./charity.constant";
import * as T from "./charity.type";

const registerCharityEpic: AppEpic = (action$: Observable<T.IRegisterCharity>) =>
  action$.pipe(
    ofType(C.REGISTER_CHARITY),
    mergeMap((action) =>
      from(api.registerCharity(action.payload)).pipe(
        mergeMap((response) => {
          if (response.data.data) {
            CustomHistory.navigate(routes.CHARITY.VERIFICATION_SUCESS);
            return of(actions.registerCharity.success(response.data));
          }
          return of(handleError({ action, error: null }), actions.registerCharity.failure());
        }),
        catchError((error) => {
          const code = getErrorCode(error);
          if (code === "CHARITY_STANDING_ERROR" || code === "CHARITY_NOT_APPLICABLE_ERROR") {
            CustomHistory.navigate(routes.CHARITY.VERIFICATION_FAILED);
            return of(actions.registerCharity.failure(error));
          }
          return of(actions.registerCharity.failure(), handleError({ action, error }));
        }),
      ),
    ),
  );

const confirmCharityRegistrationEpic: AppEpic = (
  action$: Observable<T.IConfirmCharityRegistration>,
) =>
  action$.pipe(
    ofType(C.CONFIRM_CHARITY_REGISTRATION),
    mergeMap((action) =>
      from(api.confirmCharityRegistration(action.payload)).pipe(
        mergeMap((response) => {
          if (response.data.data.charityAdmin) {
            const token = response.data.data.accessToken;
            const role = "charity";
            localStorage.setItem(storageKeys.AUTH_TOKEN, token);
            localStorage.setItem(storageKeys.USER_ROLE, role);
            return of(
              signInCredentials.success({ token, role }),
              fetchUser.request(),
              actions.confirmCharityRegistration.success(response.data.data.charityAdmin),
            );
          }
          return of(
            handleError({ action, error: null }),
            actions.confirmCharityRegistration.failure(),
          );
        }),
        catchError((error) =>
          of(actions.confirmCharityRegistration.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

const confirmCharityAdminRegistrationEpic: AppEpic = (
  action$: Observable<T.IConfirmCharityAdminRegistration>,
) =>
  action$.pipe(
    ofType(C.CONFIRM_CHARITY_ADMIN_REGISTRATION),
    mergeMap((action) =>
      from(api.confirmCharityAdminRegistration(action.payload)).pipe(
        mergeMap((response) => {
          if (response.status === 200) {
            CustomHistory.navigate(routes.CHARITY.LOGIN);
            return of(actions.confirmCharityAdminRegistration.success());
          }
          return of(
            handleError({ action, error: null }),
            actions.confirmCharityAdminRegistration.failure(),
          );
        }),
        catchError((error) =>
          of(actions.confirmCharityAdminRegistration.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

const setCharityDetailsEpic: AppEpic = (action$: Observable<T.ISetCharityDetails>) =>
  action$.pipe(
    ofType(C.SET_CHARITY_DETAILS),
    mergeMap((action) => {
      const formData = new FormData();
      const lookingFor = action.payload.lookingFor ?? [];
      const goals = action.payload.goals ?? [];
      formData.append(
        "lookingFor",
        lookingFor
          .map((item, i) =>
            i === lookingFor.length - 1 ? LOKKING_FOR_MAPPER[item] : `${LOKKING_FOR_MAPPER[item]}|`,
          )
          .join(""),
      );
      formData.append("supportStatement", JSON.stringify(action.payload.supportStatement));
      formData.append("personalStatement", JSON.stringify(action.payload.personalStatement));
      formData.append(
        "goals",
        goals.map((item, i) => (i === goals.length - 1 ? item : `${item}|`)).join(""),
      );
      formData.append("w9Document", action.payload.w9Document);
      formData.append("voidedCheck", action.payload.voidedCheck);

      return from(api.setCharityDetails(formData)).pipe(
        mergeMap((response) => {
          CustomHistory.navigate(`${routes.CHARITY.DETAILS}/${response.data.data?.id}`);
          return of(actions.setCharityDetails.success(response.data.data), fetchUser.request());
        }),
        catchError((error) =>
          of(actions.setCharityDetails.failure(), handleError({ action, error })),
        ),
      );
    }),
  );

const changeCharityMissionEpic: AppEpic = (action$: Observable<T.IChangeCharityMission>) =>
  action$.pipe(
    ofType(C.CHANGE_CHARITY_MISSION),
    mergeMap((action) =>
      from(api.changeCharityMission(action.payload.missionStatement)).pipe(
        mergeMap((response) => {
          if (response) {
            return of(actions.changeCharityMission.success(response.data));
          }
          return of(actions.changeCharityMission.failure());
        }),
        catchError((error) => of(actions.changeCharityMission.failure())),
        tap(() => hideFullScreenLoader()),
      ),
    ),
  );

const getCharityByIdEpic: AppEpic = (action$: Observable<T.IGetCharityById>, state$) =>
  action$.pipe(
    ofType(C.GET_CHARITY_BY_ID),
    mergeMap((action) =>
      from(api.getCharityById(action.payload)).pipe(
        tap(() => showFullScreenLoader()),
        mergeMap((response) => {
          if (response) {
            return of(actions.getCharityById.success(response), hideFullScreenLoader());
          }
          return of(actions.getCharityById.failure(null), hideFullScreenLoader());
        }),
        catchError((error) => of(actions.getCharityById.failure(error), hideFullScreenLoader())),
        tap(() => hideFullScreenLoader()),
      ),
    ),
  );

const updateCharityUNGoalsEpic: AppEpic = (action$: Observable<T.IUpdateCharityUNGoals>, state$) =>
  action$.pipe(
    ofType(C.UPDATE_CHARITY_UN_GOALS),
    mergeMap((action) =>
      from(api.updateCharityUNGoals(action.payload)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(actions.updateCharityUNGoals.success(response.data), hideFullScreenLoader());
          }
          return of(actions.updateCharityUNGoals.failure(null), hideFullScreenLoader());
        }),
        catchError((error) =>
          of(actions.updateCharityUNGoals.failure(error), hideFullScreenLoader()),
        ),
      ),
    ),
  );

const inviteCharityAdminEpic: AppEpic = (action$: Observable<T.IInviteAdmins>, state$) =>
  action$.pipe(
    ofType(C.INVITE_CHARITY_ADMIN),
    mergeMap((action) =>
      from(api.inviteCharityAdmin(action.payload)).pipe(
        mergeMap((response) => {
          if (response.status === ACCEPTED) {
            return of(
              showToast({ message: "Invite successfully sent!", type: "success" }),
              updateDialogState({ key: "INVITE_ADMINS", isOpened: false }),
              actions.inviteCharityAdmin.success(),
            );
          }
          return of(actions.inviteCharityAdmin.failure());
        }),
        catchError((error) =>
          of(actions.inviteCharityAdmin.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export default combineEpics(
  registerCharityEpic,
  confirmCharityRegistrationEpic,
  confirmCharityAdminRegistrationEpic,
  setCharityDetailsEpic,
  getCharityByIdEpic,
  updateCharityUNGoalsEpic,
  inviteCharityAdminEpic,
  changeCharityMissionEpic,
);
