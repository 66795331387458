import { CheckCircleIcon, TabExternalIcon } from "@primer/octicons-react";
import React from "react";
import { useDispatch } from "react-redux";
import { getTaxDocument } from "store/gift-matching/gift-matching.action";
import colors from "theme/colors";
import { GiftMatch } from "types/gift-match";

import * as S from "../gift-matching.styled";

const GiftMatchReceiptUrl: React.FC<{ donation: GiftMatch; isTablet: boolean }> = ({
  donation,
  isTablet,
}): JSX.Element => {
  const dispatch = useDispatch();

  const viewReceiptFromUrl = (
    <S.RowDisplay justifyContent={isTablet ? "flex-start" : "center"} gridGap={6} flexWrap>
      <TabExternalIcon fill={colors.unitedNationsBlue()} />
      <S.LinkText href={donation?.receiptUrl} target="_blank">
        View Receipt
      </S.LinkText>
    </S.RowDisplay>
  );

  const receiptFromAPI = (
    <S.DivLinkText
      isDisabled={donation.verified}
      gridGap={8}
      justifyContent={isTablet ? "flex-start" : "center"}
      onClick={() => dispatch(getTaxDocument.request({ donationId: donation.id }))}
    >
      <TabExternalIcon fill={colors.unitedNationsBlue()} />
      <div>View Receipt</div>
    </S.DivLinkText>
  );

  const disabledReceiptUrl = (
    <S.LinkText href={donation?.receiptUrl} target="_blank" isDisabled>
      View Receipt
    </S.LinkText>
  );

  const verifiedReceipt = (
    <S.VerifiedText gridGap={6} justifyContent={isTablet ? "flex-start" : "center"}>
      <CheckCircleIcon fill={colors.amazon()} />
      <div>Verified</div>
    </S.VerifiedText>
  );

  return donation.status === "PENDING" ? (
    <>{donation.verified ? verifiedReceipt : receiptFromAPI}</>
  ) : (
    <>
      {donation.receiptUrl && donation.status !== "FULFILLED_MANUALLY"
        ? viewReceiptFromUrl
        : disabledReceiptUrl}
    </>
  );
};

export default GiftMatchReceiptUrl;
