import styled from "styled-components";
import breakpoints from "utils/breakpoints";

import { ColumnDisplay } from "./dropdown-checkbox.styled";

export { ColumnDisplay, RowDisplay, Separator } from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const DropdownWrapper = styled.div`
  width: 100%;
  height: 40px;
`;

export const DropdownInnerContainer = styled.div<{
  isVisible: boolean;
  duration: number;
  height?: number;
}>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height || 420}px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  max-height: ${({ isVisible, height }) => (isVisible ? height || "600px" : "0")};
  background: ${({ theme }) => theme.white()};
  ${({ isVisible }) => isVisible && "z-index: 1;"}
  border: 1px solid ${({ theme }) => theme.lightSilver()};
  border-radius: 6px;
  user-select: none;
  overflow: hidden;
`;

export const DropdownContent = styled.div<{
  isVisible: boolean;
}>`
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 260px;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  overflow: ${({ isVisible }) => (isVisible ? "scroll" : "hidden")};

  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
    align-items: normal;
    width: inherit;
  }
`;

export const DropdownItem = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  min-height: 20px;
  padding: 4px;
  cursor: pointer;
`;

export const DropdownText = styled.div<{ isOpened: boolean; isSelected: boolean }>`
  font-style: normal;
  ffont-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.charcoalGray() : theme.charcoalGray(0.5)};
`;

export const HorizontalSeparator = styled.div`
  width: calc(100% + 32px);
  height: 1px;
  margin-left: -16px;
  ${({ theme }) => `background: ${theme.grayX11()}`};
`;

export const DropdownItemSelected = styled.div<{ isOpened: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  height: 20px;
  margin: 10px 12px 10px 12px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.white()};
`;

export const CheckboxText = styled.div<{ opacity?: number }>`
  font-style: normal;
  ffont-weight: 400;
  font-size: 14px;
  color: ${({ theme, opacity = 1 }) => theme.charcoalGray(opacity)};
`;

export const CheckboxSquare = styled(ColumnDisplay)<{ isSelected: boolean }>`
  height: 17px;
  width: 17px;
  border-radius: 6px;
  border: 1px solid
    ${({ theme, isSelected }) => (isSelected ? theme.trueBlue() : theme.lightSilver())};
  background: ${({ theme, isSelected }) => (isSelected ? theme.trueBlue() : theme.white())};
`;
