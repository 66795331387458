import { cacheSlice } from "store/store.cache";

import { IRegistrationState } from "./registration.type";

export const cacheRegistration = (
  initialState: IRegistrationState,
  state: IRegistrationState,
): IRegistrationState => {
  const cachedState: IRegistrationState = {
    ...initialState,
    employeeDto: {
      ...initialState.employeeDto,
      email: state.employeeDto?.email ?? null,
    },
  };

  cacheSlice("registration", cachedState);

  return {
    ...state,
    isCached: true,
  };
};

export const clearRegistrationCache = (initialState: IRegistrationState): IRegistrationState => {
  cacheSlice("registration", initialState);
  return initialState;
};
