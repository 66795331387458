import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { inviteCharityAdmin } from "store/charity/charity.action";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import { EMAIL_REG, NAME_REG } from "utils/constants";
import * as yup from "yup";

import * as S from "../dialogs.styled";

const MODAL_KEY = "INVITE_ADMINS";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .min(2, "First name must have at least 2 characters.")
    .matches(NAME_REG, "First name must be valid.")
    .required("Please fill in all data."),
  lastName: yup
    .string()
    .trim()
    .min(2, "Last name must have at least 2 characters.")
    .matches(NAME_REG, "Last name must be valid.")
    .required("Please fill in all data."),
  email: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .matches(EMAIL_REG, "E-mail is not valid."),
});

const InviteAdminsDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, MODAL_KEY),
  );

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    resetField,
    clearErrors,
  } = useForm({
    defaultValues: { firstName: "", lastName: "", email: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  React.useEffect(() => {
    if (isOpened) {
      const fieldsToReset: Array<"firstName" | "lastName" | "email"> = [
        "firstName",
        "lastName",
        "email",
      ];
      fieldsToReset.forEach((field) => resetField(field));
      fieldsToReset.forEach((field) => clearErrors(field));
    }
  }, [isOpened]);

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: MODAL_KEY, isOpened: false }));
  };

  const onSubmit = ({ firstName, lastName, email }): void => {
    dispatch(inviteCharityAdmin.request({ firstName, lastName, email }));
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20} paddingTop={48}>
          <S.GradientHeading fontSize={28}>Invite admin</S.GradientHeading>
          <S.Separator height={58} />
          <Input
            topLabel="First Name"
            isRequired
            sx={{ width: "100%", minWidth: "100%" }}
            control={control}
            errorMessage={errors?.firstName?.message}
            name="firstName"
          />
          <S.Separator height={40} />
          <Input
            topLabel="Last Name"
            isRequired
            sx={{ width: "100%", minWidth: "100%" }}
            control={control}
            errorMessage={errors?.lastName?.message}
            name="lastName"
          />
          <S.Separator height={40} />
          <Input
            topLabel="E-mail"
            isRequired
            bottomLabel="Please enter nonprofit associated e-mail."
            sx={{ width: "100%", minWidth: "100%" }}
            control={control}
            onEnter={handleSubmit(onSubmit)}
            errorMessage={errors?.email?.message}
            name="email"
          />
          <S.Separator height={52} />
          <PrimaryButton
            label="Invite"
            sx={{ width: "100%", minWidth: "100%" }}
            onClick={handleSubmit(onSubmit)}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default InviteAdminsDialog;
