import styled from "styled-components";
import { ColumnDisplay, Paragraph, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export {
  BubbleBackground,
  ColumnDisplay,
  FormWrapper,
  GradientHeading,
  LinkWrapper,
  Paragraph,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const HeadingBlack = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  margin: -8px;
  color: ${({ theme }) => theme.charcoalGray()};
`;

export const HeadingBlackThin = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: ${({ theme }) => theme.eerieBlack()};
`;

export const ParagraphThin = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.quartz()};
`;

export const FadedRedArea = styled(ColumnDisplay)`
  width: 100%;
  max-width: 100%;
  min-height: 83px;
  border-radius: 24px;
  padding: 8px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.bordo(0.1)};
`;

export const BordoText = styled(RowDisplay)`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: ${({ theme }) => theme.bordo()};
`;

export const ErrorText = styled(Paragraph)``;

export const FadedBlueArea = styled(ColumnDisplay)`
  width: 100%;
  max-width: 100%;
  padding: 30px 0px;
  box-sizing: border-box;
  border-radius: 24px;
  background: ${({ theme }) => theme.unitedNationsBlue(0.09)};
`;

export const BlueText = styled(BordoText)`
  color: ${({ theme }) => theme.unitedNationsBlue()};
`;

export const TermsOfServiceLink = styled.a`
  color: ${({ theme }) => theme.trueBlue()};
`;
