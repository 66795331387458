import * as api from "api/company";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";

import { getCompanyDonations } from "./company-donations.action";
import * as C from "./company-donations.constants";
import * as T from "./company-donations.type";

const getCompanyDonationsEpic: AppEpic = (action$: Observable<T.IGetCompanyDonations>, $state) =>
  action$.pipe(
    ofType(C.GET_COMPANY_DONATIONS),
    mergeMap((action) =>
      from(api.getAllDonations(action.payload.page)).pipe(
        mergeMap((response) => {
          if (response) {
            return of(getCompanyDonations.success({ ...response.data, page: action.payload.page }));
          }
          return of(getCompanyDonations.failure());
        }),
        catchError((error) => of(getCompanyDonations.failure(), handleError({ action, error }))),
      ),
    ),
  );

export default combineEpics(getCompanyDonationsEpic);
