import * as C from "./ui-components.constants";
import * as T from "./ui-components.types";

export const updateDialogState = (
  payload: T.IUpdateDialogState["payload"],
): T.IUpdateDialogState => ({
  type: C.UPDATE_DIALOG_STATE,
  payload,
});

export const resetDialogStateHistory = (): T.IResetDialogStateHistory => ({
  type: C.RESET_DIALOG_STATE_HISTORY,
});

export const updateDropdownState = (
  payload: T.IUpdateDropdownState["payload"],
): T.IUpdateDropdownState => ({
  type: C.UPDATE_DROPDOWN_STATE,
  payload,
});

export const updateInputState = (payload: T.IUpdateInputState["payload"]): T.IUpdateInputState => ({
  type: C.UPDATE_INPUT_STATE,
  payload,
});

export const updateCheckboxState = (
  payload: T.IUpdateCheckboxState["payload"],
): T.IUpdateCheckboxState => ({
  type: C.UPDATE_CHECKBOX_STATE,
  payload,
});

export const showToast = (
  payload: Omit<T.IUpdateToastState["payload"], "isShown">,
): T.IUpdateToastState => ({
  type: C.UPDATE_TOAST_STATE,
  payload: {
    ...payload,
    isShown: true,
  },
});

export const hideToast = (): T.IUpdateToastState => ({
  type: C.UPDATE_TOAST_STATE,
  payload: {
    isShown: false,
    message: null,
    type: "success",
  },
});

export const showFullScreenLoader = (): T.IUpdateFullScreenLoaderState => ({
  type: C.UPDATE_LOADER_STATE,
  payload: { isLoading: true },
});

export const hideFullScreenLoader = (): T.IUpdateFullScreenLoaderState => ({
  type: C.UPDATE_LOADER_STATE,
  payload: { isLoading: false },
});
