import React from "react";
import { useNavigate, useParams } from "react-router-dom";

interface IRedirectRoute {
  redirectTo: string;
}

const RedirectRoute: React.FC<IRedirectRoute> = ({ redirectTo }): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const { companyName } = params;

  React.useEffect(() => {
    navigate(`/${companyName}${redirectTo}`);
  }, []);

  return null;
};

export default RedirectRoute;
