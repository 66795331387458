import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip } from "chart.js";
import CustomLegend, { LegendItem } from "components/custom-graph-legend";
import { generateDatasetColors } from "utils/graphs";

import * as S from "./bar-chart.styled";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

interface PieChartProps {
  title: string;
  data: any;
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      border: { dash: [6, 6], display: false },
      grid: {
        display: true,
      },
      ticks: {
        padding: 15,
        callback(value) {
          return `${value}%`;
        },
        stepSize: 25,
        min: 0,
        max: 100,
        font: {
          size: 12,
        },
      },
    },
    x: {
      border: { display: false },
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

const BarChart = ({ title, data }: PieChartProps) => {
  let legendData: LegendItem[] = [];

  const barChartLabels = Object.entries(data || {}).map(([key, _]) => key);

  const barChartData = {
    labels: barChartLabels,
    datasets: [
      {
        data: Object.entries(data || {}).map(([_, value]) => value),
        backgroundColor: generateDatasetColors(barChartLabels),
        borderWidth: 0,
        borderRadius: {
          topLeft: 8,
          topRight: 8,
        },
        barThickness: 32,
      },
    ],
  };

  if (
    barChartData.datasets &&
    Array.isArray(barChartData.datasets) &&
    barChartData.datasets.length > 0
  ) {
    const firstDataset = barChartData.datasets[0];
    if (firstDataset.backgroundColor && Array.isArray(firstDataset.backgroundColor)) {
      legendData = firstDataset.backgroundColor.map((color: string, index: number) => ({
        label: (barChartData.labels?.[index] as string) || "",
        color: color || "",
      }));
    }
  }

  return (
    <S.MainContainer>
      <S.BarChartTitle>{title}</S.BarChartTitle>
      <S.BarChartContainer data={barChartData} options={options} />
      <CustomLegend
        flexDirection="row"
        fontSize="12px"
        color="#939393"
        dotSize="14px"
        legendData={legendData}
      />
    </S.MainContainer>
  );
};

export default BarChart;
