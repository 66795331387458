import { IAuthSelectors } from "./auth.type";

const authSelectors: IAuthSelectors = {
  selectIsCached: (state) => state.auth.isCached,
  selectInProgress: (state) => state.auth.inProgress,
  selectError: (state) => state.auth.error,
  selectToken: (state) => state.auth.token,
  selectRole: (state) => state.auth.role,
  selectLastCompanyCheck: (state) => state.auth.lastCompanyCheck,
};

export default authSelectors;
