import { yupResolver } from "@hookform/resolvers/yup";
import { PencilIcon, TableIcon } from "@primer/octicons-react";
import { Pagination } from "@primer/react";
import SmallButton from "components/buttons/small";
import CustomSearchInput from "components/custom-search-input/custom-search-input";
import React from "react";
import Highlighter from "react-highlight-words";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  getAdminUsers,
  getEmployeeUsers,
  getUsersByPage,
} from "store/manage-people/manage-people.action";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import userSelectors from "store/user/user.selector";
import colors from "theme/colors";
import { Order } from "types/common";
import { IGetUsersProps, ManagePeopleTableSortBy } from "types/company";
import { UsersTableSort } from "types/content";
import { CompanyUser } from "types/user";
import { MAX_TABLE_COLUMNS, MIN_Q_LENGTH } from "utils/constants";
import { MANAGE_PEOPLE_TABLE_HEADING } from "utils/content";
import { capitalizeFirstLetter, genRandomString } from "utils/helper";
import * as yup from "yup";

import * as S from "../manage-people.styled";
import { IManagePeopleTableProps } from "../manage-people.type";

const parseRole = (role: string): string =>
  capitalizeFirstLetter(role || "")
    ?.replaceAll("_", " ")
    .replaceAll("#", " ");

const schema = yup.object().shape({
  q: yup.string().optional().nullable().default(null),
});

const ManagePeopleTable: React.FC<IManagePeopleTableProps> = ({
  manageRole,
  items,
  totalItemsNum,
  displayPagination,
  currentPage,
}): JSX.Element => {
  const dispatch = useDispatch();

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const admin = useSelector(userSelectors.selectData);

  const [searchedText, searchText] = React.useState<string>("");
  const [tableRows, setTableRows] = React.useState<UsersTableSort[]>(MANAGE_PEOPLE_TABLE_HEADING);
  const [sortedBy, sortBy] = React.useState<ManagePeopleTableSortBy>(null);

  const highlightedText = searchedText?.length < 3 ? "" : searchedText;
  const highlightStyle = { fontWeight: "700", background: colors.transparent };

  const isNoneditable = (user: CompanyUser): boolean =>
    (admin?.role === "ADMIN" && user.role === "SUPER_ADMIN") || admin?.id === user?.id;

  const {
    formState: { errors },
    control,
    resetField,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { q: "" },
    mode: "onSubmit",
  });

  React.useEffect(() => {
    if (items?.length === 0 && totalItemsNum > 0 && !!admin?.id) {
      dispatch(getUsersByPage.request({ page: currentPage - 1, role: manageRole }));
    }
  }, [items?.length]);

  const initialFetch = (): void => {
    if (manageRole === "admins") {
      dispatch(getAdminUsers.request({}));
    } else if (manageRole === "employees") {
      dispatch(getEmployeeUsers.request({}));
    }
  };

  const onSubmit = (payload: Partial<IGetUsersProps>): void => {
    if (manageRole === "admins") {
      dispatch(getAdminUsers.request(payload));
    } else if (manageRole === "employees") {
      dispatch(getEmployeeUsers.request(payload));
    }
  };

  const onSubmitCallback = (payload: Partial<IGetUsersProps>) =>
    handleSubmit((params) => {
      const { q, ...restPayload } = payload || {};
      const updatedQ = q?.length > 2 ? q : null;
      const updatedPayload = { ...(params as IGetUsersProps), ...restPayload, q: updatedQ };

      if (!searchedText && !sortedBy) {
        initialFetch();
      } else {
        onSubmit(updatedPayload);
      }
    });

  const onSort = (item: UsersTableSort): void => {
    if (item.sortBy) {
      sortBy(item?.sortBy);
      if (item.sortBy === sortedBy) {
        const mappedRows = tableRows.map((row) =>
          row.sortBy === item.sortBy
            ? { ...row, orderBy: (row.orderBy === "asc" ? "desc" : "asc") as Order }
            : row,
        );
        setTableRows(mappedRows);

        const order = mappedRows.find((row) => row.sortBy === sortedBy)?.orderBy ?? "asc";
        onSubmitCallback({ sortBy: item.sortBy, orderBy: order })();
      } else {
        const order = tableRows.find((row) => row.sortBy === sortedBy)?.orderBy ?? "asc";
        onSubmitCallback({ sortBy: item.sortBy, orderBy: order })();
      }
    }
  };

  return (
    <>
      <S.Separator height={32} />
      <S.SearchAndFilterContainer>
        <S.SearchAndFilterInnerContainer justifyContent="flex-start" gridGap={0} flexWrap>
          <CustomSearchInput
            control={control}
            name="q"
            label="Search by employee or email"
            error={errors?.q?.message}
            onChange={(q) => {
              searchText(q);
              onSubmitCallback({ q })();
            }}
          />
        </S.SearchAndFilterInnerContainer>
      </S.SearchAndFilterContainer>
      {totalItemsNum > 0 ? (
        <S.ColumnDisplay
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ maxWidth: "100%", overflowX: "auto" }}
        >
          {isTablet ? (
            <S.ColumnDisplay gridGap={16}>
              {items.map((user) => (
                <S.Table.Wrapper key={genRandomString()}>
                  <S.Table.Body>
                    <S.Table.Row>
                      <S.Table.Heading style={{ textAlign: "center" }}>
                        {tableRows[0].label}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={!displayPagination} isFirstElem showTopBorder>
                        {!!user?.firstName || !!user?.firstName
                          ? `${user?.firstName} ${user?.lastName}`
                          : "Not Provided"}
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading
                        style={{ textAlign: "center" }}
                        key={genRandomString()}
                        hideRadius
                      >
                        {tableRows[1].label}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={displayPagination}>{user?.email}</S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading
                        style={{ textAlign: "center" }}
                        key={genRandomString()}
                        hideRadius
                      >
                        {tableRows[2].label}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={displayPagination}>
                        {user?.department || "Not Provided"}
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading
                        key={genRandomString()}
                        hideRadius
                        style={{ textAlign: "center" }}
                        showBottomRadius={isNoneditable(user)}
                      >
                        {tableRows[3].label}
                      </S.Table.Heading>
                      <S.Table.Data isCentered hideBorder={displayPagination}>
                        {parseRole(user?.role)}
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading
                        style={{ textAlign: "center" }}
                        key={genRandomString()}
                        hideRadius
                      >
                        {tableRows[4].label}
                      </S.Table.Heading>
                      <S.Table.Data isCentered hideBorder={displayPagination}>
                        {user?.verified ? "Registered" : "Invited"}
                      </S.Table.Data>
                    </S.Table.Row>

                    {!isNoneditable(user) && (
                      <S.Table.Row>
                        <S.Table.Heading
                          style={{ textAlign: "center" }}
                          key={genRandomString()}
                          hideRadius
                          showBottomRadius
                        >
                          {tableRows[5].label}
                        </S.Table.Heading>
                        <S.Table.Data hideBorder={displayPagination}>
                          <S.RowDisplay gridGap={12} justifyContent="flex-start" flexWrap>
                            {user?.verified && (
                              <SmallButton
                                label="Edit role"
                                onClick={() =>
                                  dispatch(
                                    updateDialogState({
                                      key: "EDIT_ROLE",
                                      isOpened: true,
                                      properties: { userId: user?.id, role: user?.role },
                                    }),
                                  )
                                }
                                leadingIcon={PencilIcon}
                                sx={{ height: 32 }}
                              />
                            )}
                            <SmallButton
                              label="Remove"
                              onClick={() =>
                                dispatch(
                                  updateDialogState({
                                    key: "REMOVE_EMPLOYEE",
                                    isOpened: true,
                                    properties: { userId: user?.id, role: user?.role },
                                  }),
                                )
                              }
                              sx={{
                                height: 32,
                                color: colors.bordo(),
                                ...(!user?.verified && { alignSelf: "center", width: 200 }),
                              }}
                            />
                          </S.RowDisplay>
                        </S.Table.Data>
                      </S.Table.Row>
                    )}
                  </S.Table.Body>
                </S.Table.Wrapper>
              ))}
            </S.ColumnDisplay>
          ) : (
            <>
              <S.Table.Wrapper>
                <S.Table.Body>
                  <S.Table.Row>
                    {tableRows.map((item) => (
                      <S.Table.Heading
                        style={{ textAlign: "center", cursor: item?.sortBy ? "pointer" : "auto" }}
                        key={genRandomString()}
                        onClick={() => onSort(item)}
                      >
                        <S.RowDisplay gridGap={8}>
                          {item.label}
                          {item?.sortBy && item?.renderIcon(item.orderBy)}
                        </S.RowDisplay>
                      </S.Table.Heading>
                    ))}
                  </S.Table.Row>

                  {items.map((user) => (
                    <S.Table.Row key={genRandomString()}>
                      <S.Table.Data hideBorder={displayPagination}>
                        <Highlighter
                          searchWords={[highlightedText]}
                          autoEscape
                          textToHighlight={
                            !!user?.firstName || !!user?.firstName
                              ? `${user?.firstName} ${user?.lastName}`
                              : "Not Provided"
                          }
                          highlightStyle={highlightStyle}
                        />
                      </S.Table.Data>
                      <S.Table.Data>
                        <Highlighter
                          searchWords={[highlightedText]}
                          autoEscape
                          textToHighlight={user?.email}
                          highlightStyle={highlightStyle}
                        />
                      </S.Table.Data>
                      <S.Table.Data>{user?.department || "Not Provided"}</S.Table.Data>
                      <S.Table.Data isCentered>{parseRole(user?.role)}</S.Table.Data>
                      <S.Table.Data isCentered>
                        {user?.verified ? "Registered" : "Invited"}
                      </S.Table.Data>
                      {!isNoneditable(user) ? (
                        <S.Table.Data
                          hideBorder={displayPagination}
                          style={{ maxWidth: 248, boxSizing: "border-box" }}
                        >
                          <S.RowDisplay gridGap={12} justifyContent="flex-start">
                            {user?.verified && (
                              <SmallButton
                                label="Edit role"
                                onClick={() =>
                                  dispatch(
                                    updateDialogState({
                                      key: "EDIT_ROLE",
                                      isOpened: true,
                                      properties: { userId: user?.id, role: user?.role },
                                    }),
                                  )
                                }
                                leadingIcon={PencilIcon}
                                sx={{ height: 32 }}
                              />
                            )}
                            <SmallButton
                              label="Remove"
                              onClick={() =>
                                dispatch(
                                  updateDialogState({
                                    key: "REMOVE_EMPLOYEE",
                                    isOpened: true,
                                    properties: { userId: user?.id, role: user?.role },
                                  }),
                                )
                              }
                              sx={{
                                height: 32,
                                color: colors.bordo(),
                                ...(!user?.verified && { alignSelf: "center", width: 200 }),
                              }}
                            />
                          </S.RowDisplay>
                        </S.Table.Data>
                      ) : (
                        <S.Table.Data />
                      )}
                    </S.Table.Row>
                  ))}
                </S.Table.Body>
              </S.Table.Wrapper>
            </>
          )}
          {displayPagination && (
            <S.PaginationWrapper>
              <Pagination
                pageCount={Math.ceil(totalItemsNum / MAX_TABLE_COLUMNS)}
                currentPage={currentPage}
                onPageChange={(e, n) => {
                  e.preventDefault();
                  if (n !== currentPage) {
                    dispatch(getUsersByPage.request({ page: n, role: manageRole }));
                  }
                }}
              />
            </S.PaginationWrapper>
          )}
        </S.ColumnDisplay>
      ) : (
        <S.DonationsMatchingForm style={{ height: 150, minHeight: 150 }}>
          <S.NoDataText gridGap={16}>
            <TableIcon size={48} />
            No data to display.
          </S.NoDataText>
        </S.DonationsMatchingForm>
      )}
    </>
  );
};

export default ManagePeopleTable;
