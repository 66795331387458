import * as api from "api/company-wall";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, debounceTime, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { changeEveryOrgCharityDetails } from "store/every-org/every-org.action";
import { AppEpic } from "store/store.state";
import { showToast } from "store/ui-components/ui-components.actions";
import { DEBOUNCE_TIME } from "utils/constants";
import { ACCEPTED, DELETED } from "utils/status-code";

import {
  addEveryOrgToCharityList,
  addToCharityList,
  getCharities,
  getMoreCharities,
  getMoreWallCharities,
  removeEveryOrgFromCharityList,
  removeFromCharityList,
} from "./charities.action";
import * as C from "./charities.constants";
import * as T from "./charities.type";

const getCharitiesEpic: AppEpic = (action$: Observable<T.IGetCharitiesRequest>) =>
  action$.pipe(
    ofType(C.GET_CHARITIES),
    mergeMap((action) =>
      from(api.getCharities(action.payload.tab)).pipe(
        mergeMap((response) => {
          if (response) {
            return of(getCharities.success({ tab: action.payload.tab, data: response }));
          }
          return of(getCharities.failure());
        }),
        catchError((error) => of(getCharities.failure(), handleError({ action, error }))),
      ),
    ),
  );

const getMoreWallCharitiesEpic: AppEpic = (action$: Observable<T.IGetMoreWallCharitiesRequest>) =>
  action$.pipe(
    ofType(C.GET_MORE_WALL_CHARITIES),
    mergeMap((action) =>
      from(api.getCharities(action.payload.tab, { page: action.payload.page })).pipe(
        mergeMap((response) => {
          if (response) {
            return of(getMoreWallCharities.success({ tab: action.payload.tab, data: response }));
          }
          return of(getMoreWallCharities.failure(action.payload));
        }),
        catchError((error) =>
          of(getMoreWallCharities.failure(action.payload), handleError({ action, error })),
        ),
      ),
    ),
  );

const getMoreCharitiesEpic: AppEpic = (action$: Observable<T.IGetMoreCharitiesRequest>) =>
  action$.pipe(
    debounceTime(DEBOUNCE_TIME),
    ofType(C.GET_MORE_CHARITIES),
    mergeMap((action) =>
      from(
        api.getCharities(action.payload.tab, {
          after: action.payload.after,
          category: action.payload.category,
        }),
      ).pipe(
        mergeMap((response) => {
          if (response) {
            return of(getMoreCharities.success({ tab: action.payload.tab, data: response }));
          }
          return of(getMoreCharities.failure(action.payload));
        }),
        catchError((error) =>
          of(getMoreCharities.failure(action.payload), handleError({ action, error })),
        ),
      ),
    ),
  );

const addToCharityWallEpic: AppEpic = (action$: Observable<T.IAddToCharityListRequest>) =>
  action$.pipe(
    ofType(C.ADD_TO_COMPANY_WALL),
    mergeMap((action) =>
      from(api.addToCharityList(action.payload.id)).pipe(
        mergeMap((response) => {
          if (response) {
            return of(
              addToCharityList.success(action.payload),
              showToast({ type: "success", message: "Charity added to wall." }),
            );
          }
          return of(addToCharityList.failure(action.payload));
        }),
        catchError((error) =>
          of(addToCharityList.failure(action.payload), handleError({ action, error })),
        ),
      ),
    ),
  );

const removeFromCharityWallEpic: AppEpic = (action$: Observable<T.IRemoveFromCharityListRequest>) =>
  action$.pipe(
    ofType(C.REMOVE_FROM_COMPANY_WALL),
    mergeMap((action) =>
      from(api.removeFromCharityList(action.payload.id)).pipe(
        mergeMap(() =>
          of(
            removeFromCharityList.success(action.payload),
            showToast({ type: "success", message: "Charity removed from wall." }),
          ),
        ),
        catchError((error) =>
          of(removeFromCharityList.failure(action.payload), handleError({ action, error })),
        ),
      ),
    ),
  );

const addEveryOrgToCharityWallEpic: AppEpic = (
  action$: Observable<T.IAddEveryOrgToCharityListRequest>,
) =>
  action$.pipe(
    ofType(C.ADD_EVERYORG_TO_COMPANY_WALL),
    mergeMap((action) =>
      from(api.addToEveryOrgCharityList(action.payload.slug)).pipe(
        mergeMap((response) => {
          if (response) {
            return of(
              addEveryOrgToCharityList.success(action.payload),
              showToast({ type: "success", message: "Charity added to wall." }),
              changeEveryOrgCharityDetails.request({ slug: action.payload.slug, backed: true }),
              getCharities.request({ tab: "wall" }),
            );
          }
          return of(addEveryOrgToCharityList.failure(action.payload));
        }),
        catchError((error) =>
          of(addEveryOrgToCharityList.failure(action.payload), handleError({ action, error })),
        ),
      ),
    ),
  );

const removeEveryOrgFromCharityWallEpic: AppEpic = (
  action$: Observable<T.IRemoveEveryOrgFromCharityListRequest>,
) =>
  action$.pipe(
    ofType(C.REMOVE_EVERYORG_FROM_COMPANY_WALL),
    mergeMap((action) =>
      from(api.removeEveryOrgFromCharityList(action.payload.slug)).pipe(
        mergeMap((response) => {
          if (response === DELETED) {
            return of(
              removeEveryOrgFromCharityList.success(action.payload),
              showToast({ type: "success", message: "Charity removed from wall." }),
              changeEveryOrgCharityDetails.request({ slug: action.payload.slug, backed: false }),
              getCharities.request({ tab: "wall" }),
            );
          }
          return of(removeEveryOrgFromCharityList.failure(action.payload));
        }),
        catchError((error) =>
          of(removeEveryOrgFromCharityList.failure(action.payload), handleError({ action, error })),
        ),
      ),
    ),
  );

export default combineEpics(
  getCharitiesEpic,
  addToCharityWallEpic,
  removeFromCharityWallEpic,
  getMoreCharitiesEpic,
  addEveryOrgToCharityWallEpic,
  removeEveryOrgFromCharityWallEpic,
  getMoreWallCharitiesEpic,
);
