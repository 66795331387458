import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { inviteUser } from "store/manage-people/manage-people.action";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import { EMAIL_REG } from "utils/constants";
import * as yup from "yup";

import * as S from "../dialogs.styled";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REG, "E-mail is not valid.")
    .required("Please fill in all data."),
});

const AddEmployeeDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, "ADD_EMPLOYEE"),
  );

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: "ADD_EMPLOYEE", isOpened: false }));
  };

  const onSubmit = ({ email }): void => {
    dispatch(inviteUser.request({ email, role: "EMPLOYEE" }));
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper>
          <S.GradientHeading fontSize={28}>Add employee</S.GradientHeading>
          <S.Separator height={58} />
          <Input
            topLabel="E-mail"
            bottomLabel="Company e-mail."
            isRequired
            sx={{ width: "100%", minWidth: "100%" }}
            control={control}
            onEnter={handleSubmit(onSubmit)}
            errorMessage={errors?.email?.message}
            name="email"
          />
          <S.Separator height={52} />
          <PrimaryButton
            label="Confirm"
            sx={{ width: "100%", minWidth: "100%" }}
            onClick={handleSubmit(onSubmit)}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default AddEmployeeDialog;
