import { LogoIcon } from "icons";
import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ClickableWrapper,
  ColumnDisplay,
  DivLinkText,
  FormWrapper,
  LinkText,
  LinkWrapper,
  PaginationWrapper,
  RowDisplay,
  Separator,
  Table,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.desktopS.x;
export const MOBILE_BREAKPOINT = breakpoints.iPhone13Pro.x;

export const HomeContainer = styled(ColumnDisplay)`
  height: 100vh;
  max-height: 100vh;
  min-width: 321px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${({ theme }) => theme.aliceBlue()};
`;

export const HomeInnerContainer = styled(ColumnDisplay)`
  width: calc(100vw - 2 * 152px);
  height: 100%;
  max-width: 1424px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: calc(100% - 2 * 24px);
  }
`;

export const HomeTopContainer = styled(ColumnDisplay)<{ background?: string }>`
  width: 100vw;
  background: ${({ theme, background = theme.transparent }) => background};
`;

export const HomeFrame = styled(ColumnDisplay)<{ isTransparent?: boolean; paddingBottom?: number }>`
  background: ${({ theme, isTransparent }) => (isTransparent ? theme.transparent : theme.white())};
  width: calc(100vw - 8.796vw);
  margin-left: 8.796vw;
  max-width: calc(100vw - 8.796vw);
  min-width: 320px;
  box-sizing: border-box;
  padding: 40px;
  padding-bottom: ${({ paddingBottom = 40 }) => paddingBottom}px;
  ${({ isTransparent }) => !isTransparent && "box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);"}
  border-radius: 12px;

  ::-webkit-scrollbar {
    display: none;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100vw - 2 * 20px);
    max-width: calc(100vw - 2 * 20px);
  }
`;

export const CharitiesFrame = styled(ColumnDisplay)<{ isTransparent?: boolean }>`
  background: ${({ theme, isTransparent }) => (isTransparent ? theme.transparent : theme.white())};
  width: 100vw;
  max-width: 100vw;
  min-width: 320px;
  box-sizing: border-box;
  padding: 40px;
  ${({ isTransparent }) => !isTransparent && "box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);"}
  border-radius: 12px;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  ::-webkit-scrollbar {
    display: none;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0px;
    padding-bottom: 20px;
    width: 100vw;
  }
`;

export const CharityLeftOffset = styled.div`
  width: calc(8.796vw - 40px);

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 0px;
    padding: 20px;
  }
`;

export const HomeFrameFlexContainer = styled(RowDisplay)`
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const BubblesContainer = styled(RowDisplay)`
  flex: 1;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-wrap: wrap;
  }
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.gunmetal()};
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const Role = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.blackCoral(0.6)};
`;

export const HomeBubble = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  border-radius: 24px;
  border: 1px solid rgba(233, 235, 236, 1);
  padding: 16px;
  padding-bottom: 20px;
  text-align: center;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);
  position: relative;
`;

export const HomeNumber = styled(GradientHeading)`
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 0em;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HomeLabel = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.blackCoral()};
  max-width: 182px;
`;

export const HomeBottomLabel = styled(HomeLabel)`
  font-size: 8.5px;
  position: absolute;
  bottom: 0;
  margin-bottom: 4px;
`;

export const RecentActivityHeading = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.blackCoral(0.5)};
`;

export const EmptyContainer = styled(ColumnDisplay)`
  min-height: 352px;
  width: 100%;
  text-align: center;
`;

export const EmptyHeading = styled.div`
  color: ${({ theme }) => theme.metallicSilver()};
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.3px;
`;

export const EmptySubheading = styled.div`
  color: ${({ theme }) => theme.metallicSilver()};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
  max-width: 308px;
`;

export const ComingSoonBubble = styled(ColumnDisplay)`
  background: rgba(152, 242, 175, 0.3);
  width: 150px;
  height: 25px;
  border-radius: 20px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: rgba(64, 132, 93, 1);
  text-align: center;
`;

export const GradientHeadingExtended = styled(GradientHeading)`
  margin: 0px;
  max-width: 100%;
  text-align: left;
`;

export const LogoWrapper = styled(ColumnDisplay)`
  position: relative;
  width: 140px;
  height: 140px;
  border: none;
  background-size: auto 140px;
  background-position: center;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 86px;
    height: 86px;
  }
`;

export const LogoBorderWrapper = styled(ColumnDisplay)`
  position: relative;
  width: 140px;
  height: 140px;
  border-radius: 140px;
  border: 1px solid #1e343b1a;
  background: ${({ theme }) => theme.aliceBlue()};
  background-size: auto 140px;
  background-position: center;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 86px;
    height: 86px;
    border-radius: 86px;
  }
`;

export const DefaultLogoStyled = styled(LogoIcon)`
  width: 62px;
  height: 76px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 36px;
    max-width: 48px;
  }
`;

export const LogoStyled = styled.img`
  width: 140px;
  height: auto;
  object-fit: cover;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 86px;
    height: auto;
  }
`;
