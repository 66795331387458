import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import * as C from "./volunteer-attendees.constants";
import * as T from "./volunteer-attendees.type";

export const volunteerAttendeesInitial: T.IVolunteerAttendeesState = {
  data: [],
  inProgress: false,
  isFetched: false,
  isEndReached: false,
  page: 0,
  results: 0,
  error: null,
  event: null,
};

const volunteerAttendeesReducer = (
  state: T.IVolunteerAttendeesState,
  action: T.VolunteerAttendeesAction | ILogout,
): T.IVolunteerAttendeesState => {
  if (!state) {
    return volunteerAttendeesInitial;
  }

  switch (action.type) {
    case C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE:
      return {
        ...state,
        inProgress: true,
        page: action.payload.page,
        event: {
          ...state.event,
          id: action.payload.id,
        },
      };
    case C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        results: action.payload.results,
        page: action.payload.page,
        isEndReached: action.payload.results === action.payload?.data?.length,
        inProgress: false,
        isFetched: true,
      };
    case C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO_SUCCESS:
      return {
        ...state,
        event: action.payload,
      };
    case LOGOUT:
      return volunteerAttendeesInitial;
    default:
      return state;
  }
};

export default volunteerAttendeesReducer;
