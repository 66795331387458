import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import { cachePayment, clearPaymentCache } from "./payment.cache";
import * as C from "./payment.constants";
import * as T from "./payment.type";

export const paymentInitial: T.IPaymentState = {
  clientSecret: null,
  donatedAmount: 0,
  inProgress: false,
  error: null,
};

const cache = (state: T.IPaymentState) => cachePayment(paymentInitial, state);

const paymentReducer = (
  state: T.IPaymentState,
  action: T.PaymentAction | ILogout,
): T.IPaymentState => {
  if (!state) {
    return paymentInitial;
  }

  switch (action.type) {
    case C.GET_PAYMENT_INTENT:
      return cache({
        ...state,
        donatedAmount: action.payload.amount,
        inProgress: true,
      });
    case C.SETUP_PAYMENT_METHOD:
      return {
        ...state,
        inProgress: true,
      };
    case C.GET_PAYMENT_INTENT_SUCCESS:
    case C.SETUP_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        inProgress: false,
        ...action.payload,
      };
    case C.GET_PAYMENT_INTENT_FAILURE:
    case C.SETUP_PAYMENT_METHOD_FAILURE:
      return cache({
        ...state,
        inProgress: false,
        donatedAmount: 0,
      });
    case C.CLEAR_CLIENT_SECRET:
      return cache({
        ...state,
        clientSecret: null,
      });
    case LOGOUT:
      return clearPaymentCache(paymentInitial);
    default:
      return state;
  }
};

export default paymentReducer;
