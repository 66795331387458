import * as C from "./user-donations.constants";
import * as T from "./user-donations.type";

export const fetchUserDonations = {
  request: (payload: T.IFetchUserDonations["payload"]): T.IFetchUserDonations => ({
    type: C.FETCH_USER_DONATIONS,
    payload,
  }),
  success: (payload: T.IFetchUserDonationsSuccess["payload"]): T.IFetchUserDonationsSuccess => ({
    type: C.FETCH_USER_DONATIONS_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchUserDonationsFailure => ({
    type: C.FETCH_USER_DONATIONS_FAILURE,
  }),
};
