export const FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS = "FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS";
export const FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_SUCCESS =
  "FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_SUCCESS";
export const FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_FAILURE =
  "FETCH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_FAILURE";

export const REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS = "REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS";
export const REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_SUCCESS =
  "REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_SUCCESS";
export const REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_FAILURE =
  "REFRESH_ACTIVE_COMPANY_VOLUNTEER_EVENTS_FAILURE";
