import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, Radio, RadioGroup, registerPortalRoot, Spinner } from "@primer/react";
import AddressAutocompleteInput from "components/address-autocomplete-input";
import PrimaryButton from "components/buttons/primary";
import ControlledSelect from "components/controlled-select";
import Input from "components/input";
import { XIcon } from "icons";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import { fetchVolunteerEvent } from "store/volunteer-event-details/volunteer-event-details.action";
import volunteerEventDetailsSelectors from "store/volunteer-event-details/volunteer-event-details.selector";
import { saveVolunteerEventDto } from "store/volunteer-events-charity/volunteer-events-charity.action";
import volunteerSelectors from "store/volunteer-events-charity/volunteer-events-charity.selector";
import colors from "theme/colors";
import {
  GRANT_OPTIONS,
  GRANT_STATUS_OPTION,
  VolunteerEvent,
  VolunteerEventDto,
  VolunteerEventLocation,
} from "types/volunteer";
import {
  EVENT_LOCATION_MAPPER,
  LocatonInputValue,
  REVERSE_EVENT_LOCATION_MAPPER,
  VOLUNTEER_EVENT_PLACES_LIST,
} from "utils/content";
import { genVolunteerEventSchema } from "utils/validation";

import * as S from "../dialogs.styled";

const CONFITMATION_MODAL_KEY = "CLOSE_POST_CHARITY_EVENT";
const STEP_2_MODAL_KEY = "POST_CHARITY_EVENT_STEP_2";

const defaultValues = {
  title: "",
  description: "",
  location: "",
  address: "",
  note: "",
  grantAmount: "",
};

const PostVolunteerEventStep1Dialog: React.FC = () => {
  const dispatch = useDispatch();
  const prevOpenedDialog = useSelector(uiComponentsSelectors.selectPreviouslyOpenedDialog);
  const storedData = useSelector(volunteerSelectors.selectDto);
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, "POST_CHARITY_EVENT_STEP_1"),
  );
  const properties = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogProperties(state, "POST_CHARITY_EVENT_STEP_1"),
  );
  const volunteerEvent = useSelector(volunteerEventDetailsSelectors.selectData);
  const inProgress = useSelector(volunteerEventDetailsSelectors.selectInProgress);
  const charityEvent = properties?.event || null;

  const persistData =
    (prevOpenedDialog === CONFITMATION_MODAL_KEY || prevOpenedDialog === STEP_2_MODAL_KEY) &&
    !!storedData;

  const [selectedGrantOption, setSelectedGrantOption] = React.useState<GRANT_OPTIONS>(
    persistData ? storedData?.grant : GRANT_STATUS_OPTION.YES,
  );

  const [editData, setEditData] = React.useState<VolunteerEvent | null>(null);
  const [selectedPlace, setSelectedPlace] = React.useState<LocatonInputValue | null>(null);
  const [mounted, setMounted] = React.useState<boolean>(false);
  const persistedData = storedData
    ? {
        title: storedData.name,
        description: storedData?.description,
        location: storedData?.location as string,
        address: storedData?.address,
        note: storedData?.note,
        grant: storedData?.grant,
        grantAmount: storedData?.grantAmount?.toString(),
      }
    : defaultValues;

  const grantRef = React.useRef<HTMLInputElement>(null);
  const titleRef = React.useRef<HTMLInputElement>(null);
  const descriptionRef = React.useRef<HTMLInputElement>(null);
  const addressRef = React.useRef<HTMLInputElement>(null);
  const noteRef = React.useRef<HTMLInputElement>(null);
  const outerContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (charityEvent && charityEvent.id) {
      dispatch(fetchVolunteerEvent.request({ id: charityEvent.id }));
    }
  }, [charityEvent]);

  React.useEffect(() => {
    if (charityEvent && !inProgress) {
      setEditData(volunteerEvent);
    }
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    resetField,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: persistData ? persistedData : defaultValues,
    resolver: yupResolver(genVolunteerEventSchema(selectedGrantOption)),
    mode: "onTouched",
  });

  React.useEffect(() => {
    if (isOpened && !persistData) {
      const fieldsToReset: Array<
        "title" | "description" | "location" | "address" | "note" | "grantAmount"
      > = ["title", "description", "location", "address", "note", "grantAmount"];
      reset();
      fieldsToReset.forEach((field) => resetField(field));
      fieldsToReset.forEach((field) => clearErrors(field));
      setSelectedGrantOption(GRANT_STATUS_OPTION.YES);
      setSelectedPlace(null);
      setEditData(null);
    }
  }, [isOpened]);

  React.useEffect(() => {
    if (!inProgress && editData) {
      setValue("title", editData.name || "");
      setValue("description", editData.description || "");
      setValue("location", REVERSE_EVENT_LOCATION_MAPPER[editData?.location[0]]);
      setSelectedPlace(REVERSE_EVENT_LOCATION_MAPPER[editData?.location[0]]);
      setValue("address", editData.address || "");
      setValue("note", editData.note || "");
      setSelectedGrantOption(editData?.grant);
      setValue("grantAmount", editData?.grantAmount?.toString() || "");
    }
  }, [editData]);

  React.useEffect(() => {
    if (
      selectedPlace === "Virtual" ||
      selectedPlace === "Company Office" ||
      selectedPlace === "Virtual or Company Office"
    ) {
      setValue("address", "");
      clearErrors("address");
    }
  }, [selectedPlace]);

  React.useEffect(() => {
    // Primer hack: added for opening autocomplete in dialogs
    if (outerContainerRef.current instanceof HTMLElement) {
      registerPortalRoot(outerContainerRef.current, "outerContainer");
      setMounted(true);
    } else {
      setMounted(false);
    }
  }, [isOpened]);

  const closeDialog = (): void => {
    const dto: Partial<VolunteerEventDto> = {
      name: getValues("title"),
      description: getValues("description"),
      location: getValues("location") as VolunteerEventLocation,
      address: getValues("address"),
      note: getValues("note"),
      grantAmount: getValues("grantAmount") ? Number(getValues("grantAmount")) : null,
    };
    dispatch(saveVolunteerEventDto(dto));
    dispatch(updateDialogState({ key: "POST_CHARITY_EVENT_STEP_1", isOpened: false }));
    dispatch(updateDialogState({ key: "CLOSE_POST_CHARITY_EVENT", isOpened: true }));
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value as GRANT_OPTIONS; // Cast value to GRANT_OPTIONS
    setSelectedGrantOption(value);

    if (value === GRANT_STATUS_OPTION.NO) {
      clearErrors("grantAmount");
      resetField("grantAmount");
    }
  };

  const handlePlaceChange = (location: LocatonInputValue): void => {
    setSelectedPlace(location);
  };

  const onNext = (): void => {
    dispatch(updateDialogState({ key: "POST_CHARITY_EVENT_STEP_1", isOpened: false }));
    if (editData) {
      dispatch(
        updateDialogState({
          key: "POST_CHARITY_EVENT_STEP_2",
          isOpened: true,
          properties: { editData },
        }),
      );
    } else {
      dispatch(updateDialogState({ key: "POST_CHARITY_EVENT_STEP_2", isOpened: true }));
    }
  };

  const onSubmit = ({ title, description, location, address, note, grantAmount }): void => {
    const dto: Partial<VolunteerEventDto> = {
      name: title,
      description,
      location: EVENT_LOCATION_MAPPER[location],
      address,
      note,
      grant: selectedGrantOption,
      grantAmount,
    };
    dispatch(saveVolunteerEventDto(dto));
    onNext();
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20} ref={outerContainerRef}>
          <S.CloseIconContainer>
            <S.CloseIconButton type="button" onClick={closeDialog}>
              <XIcon />
            </S.CloseIconButton>
          </S.CloseIconContainer>
          <S.GradientHeading fontSize={28}>
            {charityEvent ? "Edit" : "Post"} a Group Volunteering
            <br />
            Day Request
          </S.GradientHeading>
          {!!charityEvent && !editData ? (
            <>
              <S.Separator height={20} />
              <Spinner />
              <S.Separator height={20} />
            </>
          ) : (
            <>
              <S.Separator height={40} />
              <S.ColumnDisplay
                gridGap={30}
                style={{ maxWidth: "395px", width: "100%", boxSizing: "border-box" }}
              >
                <Input
                  ref={titleRef}
                  name="title"
                  topLabel="Title of the Volunteer Event"
                  sx={{ width: "100%", alignSelf: "center" }}
                  isRequired
                  control={control}
                  errorMessage={errors?.title?.message}
                />
                <Input
                  ref={descriptionRef}
                  name="description"
                  control={control}
                  topLabel="Describe what the volunteers would do:"
                  sx={{
                    maxWidth: "100%",
                    minHeight: "93px",
                    minWidth: "100%",
                    boxSizing: "border-box",
                  }}
                  onEnter={handleSubmit(onSubmit)}
                  isRequired
                  multiline
                  errorMessage={errors?.description?.message}
                />
                <ControlledSelect
                  items={VOLUNTEER_EVENT_PLACES_LIST}
                  control={control}
                  label="Where does the event take location?"
                  sx={{ width: "100%", minWidth: "100%" }}
                  name="location"
                  required
                  placeholder="Select a location"
                  onSelect={handlePlaceChange}
                  errorMessage={errors?.location?.message}
                />
                {mounted ? (
                  <AddressAutocompleteInput
                    ref={addressRef}
                    isRequired={
                      selectedPlace !== "Virtual" &&
                      selectedPlace !== "Company Office" &&
                      selectedPlace !== "Virtual or Company Office"
                    }
                    name="address"
                    control={control}
                    topLabel="Address of the event"
                    placeholder={
                      selectedPlace === "Virtual" ||
                      selectedPlace === "Company Office" ||
                      selectedPlace === "Virtual or Company Office"
                        ? "No address required (input disabled)"
                        : "Street Address, City, State, ZIP Code"
                    }
                    onEnter={handleSubmit(onSubmit)}
                    errorMessage={errors?.address?.message}
                    isOptionalVisible={false}
                    isDisabled={
                      selectedPlace === "Virtual" ||
                      selectedPlace === "Company Office" ||
                      selectedPlace === "Virtual or Company Office"
                    }
                  />
                ) : null}
                <Input
                  ref={noteRef}
                  name="note"
                  control={control}
                  topLabel="Event notes"
                  placeholder="Enter important information or links for the event like what to wear, where to park, etc."
                  sx={{ width: "100%", alignSelf: "center" }}
                  onEnter={handleSubmit(onSubmit)}
                  multiline
                  errorMessage={errors?.note?.message}
                  isOptionalVisible={false}
                />
                <div style={{ width: "100%" }}>
                  <RadioGroup
                    name="RequireAGrant"
                    sx={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <RadioGroup.Label
                      sx={{
                        fontSize: 15,
                        color: colors.gunmetal(),
                        marginBottom: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Do you require a grant in order to <br /> run a volunteering event?
                      <span style={{ color: colors.bordo() }}>{" *"}</span>
                    </RadioGroup.Label>
                    <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                      <FormControl>
                        <Radio
                          value={GRANT_STATUS_OPTION.YES}
                          checked={selectedGrantOption === GRANT_STATUS_OPTION.YES}
                          sx={{ borderColor: colors.lightSilver() }}
                          onChange={handleRadioChange}
                          defaultChecked
                        />
                        <FormControl.Label>Yes</FormControl.Label>
                      </FormControl>
                      <FormControl>
                        <Radio
                          value={GRANT_STATUS_OPTION.NO}
                          checked={selectedGrantOption === GRANT_STATUS_OPTION.NO}
                          sx={{ borderColor: colors.lightSilver() }}
                          onChange={handleRadioChange}
                        />
                        <FormControl.Label>No</FormControl.Label>
                      </FormControl>
                      <FormControl>
                        <Radio
                          value={GRANT_STATUS_OPTION.PREFERRED}
                          checked={selectedGrantOption === GRANT_STATUS_OPTION.PREFERRED}
                          sx={{ borderColor: colors.lightSilver() }}
                          onChange={handleRadioChange}
                        />
                        <FormControl.Label>Preferred but not necessary</FormControl.Label>
                      </FormControl>
                    </div>
                  </RadioGroup>
                </div>
                <Input
                  ref={grantRef}
                  name="grantAmount"
                  placeholder="$ Amount of grant requested"
                  control={control}
                  sx={{ width: "100%", alignSelf: "center" }}
                  onEnter={handleSubmit(onSubmit)}
                  topLabel=""
                  isDisabled={selectedGrantOption === GRANT_STATUS_OPTION.NO}
                  isOptionalVisible={false}
                  errorMessage={errors?.grantAmount?.message}
                />
              </S.ColumnDisplay>
              <S.Separator height={50} />
              <PrimaryButton
                label="Next"
                onClick={handleSubmit(onSubmit)}
                sx={{ width: "100%", maxWidth: "395px", alignSelf: "center" }}
              />
            </>
          )}
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default PostVolunteerEventStep1Dialog;
