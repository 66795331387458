import { CheckIcon, TriangleDownIcon, TriangleUpIcon } from "@primer/octicons-react";
import React from "react";
import colors from "theme/colors";
import { CheckboxItem } from "types/content";
import { constrainText, genRandomString } from "utils/helper";

import * as S from "./dropdown-checkbox.styled";

const ANIMATION_DURATION = 100;

interface PropsT {
  data: CheckboxItem[];
  separatedOption?: CheckboxItem;
  placeholder?: string;
  height?: number;
  footer?: JSX.Element;
  displayHandler?: {
    isOpened: boolean;
    open: (isOpened: boolean) => void;
  };
  onSelect: (value: string) => void;
}

const DropdownCheckbox: React.FC<PropsT> = ({
  data,
  separatedOption,
  placeholder,
  height,
  footer,
  displayHandler,
  onSelect,
}): JSX.Element => {
  const [isOpened, open] = React.useState<boolean>(false);
  const [contentDisplayed, displayContent] = React.useState<boolean>(isOpened);
  const [selectedOptions, setSelectedOptions] = React.useState<string>("");

  const formatSelectedOptions = (selectedItem?: string): void => {
    const selectedArray = (separatedOption ? [separatedOption, ...data] : data)
      .map((opt) => (opt.isSelected ? opt.text : null))
      .filter((opt) => opt);

    setSelectedOptions(
      constrainText(
        !selectedItem ? selectedArray.join(", ") : [...selectedArray, selectedItem].join(", "),
        30,
      ),
    );
  };

  React.useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        displayContent(isOpened);
      }, ANIMATION_DURATION);
    } else {
      displayContent(isOpened);
    }
  }, [isOpened]);

  React.useEffect(() => {
    formatSelectedOptions();
  }, [data]);

  React.useEffect(() => {
    open(displayHandler?.isOpened);
  }, [displayHandler?.isOpened]);

  const onDisplaChange = (_isOpened: boolean): void => {
    open(_isOpened);
    if (displayHandler) {
      displayHandler?.open(_isOpened);
    }
  };

  return (
    <S.DropdownWrapper onBlur={() => onDisplaChange(false)} tabIndex={0}>
      <S.DropdownInnerContainer height={height} isVisible={isOpened} duration={ANIMATION_DURATION}>
        <S.DropdownItemSelected onClick={() => onDisplaChange(!isOpened)} isOpened={isOpened}>
          <S.CheckboxText opacity={selectedOptions?.length ? 1 : 0.5}>
            {selectedOptions || placeholder}
          </S.CheckboxText>
          <S.ColumnDisplay gridGap={0}>
            <TriangleUpIcon size={12} />
            <TriangleDownIcon size={12} />
          </S.ColumnDisplay>
        </S.DropdownItemSelected>
        <S.HorizontalSeparator />
        <S.DropdownContent isVisible={contentDisplayed}>
          {data.map((item) => (
            <S.DropdownItem key={genRandomString()} onClick={() => onSelect(item.value)}>
              <S.RowDisplay justifyContent="flex-start" gridGap={8}>
                <S.CheckboxSquare isSelected={item.isSelected}>
                  <CheckIcon fill={colors.white()} />
                </S.CheckboxSquare>
                <S.CheckboxText>{item.value}</S.CheckboxText>
              </S.RowDisplay>
            </S.DropdownItem>
          ))}
          {!!separatedOption && (
            <S.DropdownItem key={genRandomString()} onClick={() => onSelect(separatedOption.value)}>
              <S.HorizontalSeparator />
              <S.Separator height={12} />
              <S.RowDisplay alignItems="flex-start" justifyContent="flex-start" gridGap={8}>
                <S.CheckboxSquare isSelected={separatedOption.isSelected}>
                  <CheckIcon fill={colors.white()} />
                </S.CheckboxSquare>
                <S.CheckboxText>{separatedOption.value}</S.CheckboxText>
              </S.RowDisplay>
              <S.Separator height={8} />
            </S.DropdownItem>
          )}
          {footer}
        </S.DropdownContent>
      </S.DropdownInnerContainer>
    </S.DropdownWrapper>
  );
};

DropdownCheckbox.defaultProps = {
  separatedOption: null,
  height: null,
  placeholder: "",
  footer: null,
  displayHandler: null,
};

export default DropdownCheckbox;
