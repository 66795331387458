export default {
  COMPANY: {
    REGISTER: "/admin/register",
    CREATE_ACCOUNT: "/register/step2",
    EMAIL_VALIDATED: "/company/:companyName/confirmRegistration/admin",
    EMAIL_VALIDATED_SUPER_ADMIN: "/company/:companyName/confirmRegistration/superAdmin",
    LOGIN: "/admin/login",
    MANAGE_PEOPLE: "/manage-people",
    RECOVER_PASSWORD: "/recover-password",
    RECOVER_PASSWORD_VALIDATION: "/validate-password-recovery",
    SET_NEW_PASSWORD: "/company/auth/setNewPassword",
    GIFT_MATCHING: "/gift-matching",
    DASHBOARD: "/company/dashboard/:year",
    CALENDAR: "/company/calendar",
    ALL_DONATIONS: "/company/dashboard/donations",
    VOLUNTEERING: "/volunteering",
  },
  EMPLOYEE: {
    REGISTER: "/employee/register",
    CREATE_ACCOUNT: "/company/:companyName/confirmRegistration/employee",
    ONBOARDING_INTERESTS: "/company/:companyName/interests/employee/:token",
    LOGIN: "/employee/login",
    EMAIL_VERIFICATION: "/employee/login/step2",
    DASHBOARD: "/dashboard",
    WELCOME_FINCH: "/company/:companyName/confirmRegistration/finchEmployee",
  },
  CHARITY: {
    LANDING: "/charity/landing",
    REGISTER: "/charity/register",
    CONFIRM_ADMIN_REGISTRATION: "/charity/confirmRegistration/charityAdmin",
    LOGIN: "/charity/login",
    SET_NEW_PASSWORD: "/charity/auth/setNewPassword",
    VERIFICATION_FAILED: "/charity/verification-failed",
    VERIFICATION_SUCESS: "/charity/verified",
    VERIFY_IDENTITY: "/charity/confirmRegistration/admin",
    DETAILS: "/charity",
    HOME: "/home",
  },
  DONATION: {
    SUCCESS: "donation/success",
    GIFT_MATCHING_FAILED: "/company-match-failed",
  },
  EVERY_DOT_ORG: {
    CATEGORIES: "/everydotorg/categories",
    BROWSE: "/everydotorg/browse",
    SEARCH: "/everydotorg/search",
    CHARITY_DETAILS: "/everydotorg/charity",
    DONATION_SUCCESS: "/everydotorg/donation-success",
  },
  VOLUNTEER: {
    EVENT: "/volunteer-event",
    EVENT_ATTENDEES: "/volunteer-event-attendees",
  },
  OLD: {
    EMPLOYEE_LOGIN: "/:companyName/login",
    EMPLOYEE_REGISTER: "/:companyName/register",
    ADMIN_LOGIN: "/login",
    ADMIN_REGISTER: "/register",
  },
  LANDING: "/landing",
  EMAIL_SENT: "/email-sent",
  ROOT: "/",
  FIND_CHARITIES: "/find-charities",
  HOME: "/home",
  NOT_FOUND: "/404",
  COMPANY_NOT_FOUND: "/domain-not-found",
  OTHER: "*",
  CHECK_IN: "/:companyName/:charityName/:eventName/:id/check-in",
  CHECK_IN_SUCCESS: ":companyName/check-in-success",
};
