import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/buttons/primary";
import ControlledSelect from "components/controlled-select";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import companySelectors from "store/company/company.selector";
import { updateUserRole } from "store/manage-people/manage-people.action";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import userSelectors from "store/user/user.selector";
import { SelectItem } from "types/content";
import { UserRole } from "types/user";
import { ADMIN_ROLES_LIST, EMPLOYEE_ROLES_LIST, SUPER_ADMIN_ROLES_LIST } from "utils/content";
import * as yup from "yup";

import * as S from "../dialogs.styled";

const schema = yup.object().shape({
  role: yup.string(),
});

const filterRoleList = (adminRole: UserRole, list: SelectItem[]): SelectItem[] =>
  adminRole === "ADMIN" ? list.filter((item) => item.value !== "Super admin") : list;

const EditRoleDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, "EDIT_ROLE"),
  );

  const properties = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogProperties(state, "EDIT_ROLE"),
  );

  const companyName = useSelector(companySelectors.selectName);

  const admin = useSelector(userSelectors.selectData);

  const roleList = filterRoleList(
    admin?.role,
    properties?.role === "ADMIN"
      ? ADMIN_ROLES_LIST
      : properties?.role === "SUPER_ADMIN"
      ? SUPER_ADMIN_ROLES_LIST
      : EMPLOYEE_ROLES_LIST,
  );

  const { control, handleSubmit } = useForm({
    defaultValues: { role: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const closeDialog = (): void => {
    dispatch(updateDialogState({ key: "EDIT_ROLE", isOpened: false }));
  };

  const onSubmit = ({ role }): void => {
    const parsedRole = ((role || roleList[0]?.value) as string).toUpperCase().replaceAll(" ", "_");
    if (properties?.role !== parsedRole) {
      dispatch(
        updateUserRole.request({
          companyName,
          userId: properties.userId,
          role: parsedRole as UserRole,
        }),
      );
    } else {
      dispatch(updateDialogState({ key: "EDIT_ROLE", isOpened: false }));
    }
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper>
          <S.GradientHeading fontSize={28}>Edit role</S.GradientHeading>
          <S.Separator height={58} />
          <ControlledSelect
            items={roleList}
            control={control}
            label="Role"
            sx={{ width: "100%", minWidth: "100%" }}
            name="role"
          />
          <S.Separator height={52} />
          <PrimaryButton
            label="Confirm"
            sx={{ width: "100%", minWidth: "100%" }}
            onClick={handleSubmit(onSubmit)}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default EditRoleDialog;
