import { HomeIcon, OrganizationIcon } from "@primer/octicons-react";
import {
  CalendarMenuIcon,
  DonationsIcon,
  FourSquaresIcon,
  PapersMatchIcon,
  PeopleMenuIcon,
  VolunteeringIcon,
} from "icons";
import { AmountSortIndicator, LetterSortIndicator } from "icons/animated-icons";
import { MatchingOption } from "pages/admin/gift-matching/gift-matching.type";
import CustomHistory from "router/history";
import { logout } from "store/auth/auth.action";
import { deleteCompanyLogo } from "store/company/company.action";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import { DropdownItem, GiftMatchTableSort, SelectItem, UsersTableSort } from "types/content";
import { DonationFrequency } from "types/gift-match";
import {
  GRANT_STATUS_OPTION,
  ManagePeopleTableType,
  VolunteerEventLocation,
  VolunteerTable,
} from "types/volunteer";

import { LAUNCH_DARKLY_FLAGS } from "./constants";
import { genRandomString, getCurrYear } from "./helper";
import routes from "./routes";
import storageKeys from "./storage-keys";

export const DEPARTMENTS_LIST: SelectItem[] = [
  { text: "Human Resources (HR)", value: "Human Resources (HR)", id: genRandomString() },
  { text: "Finance", value: "Finance", id: genRandomString() },
  { text: "Accounting", value: "Accounting", id: genRandomString() },
  { text: "Sales", value: "Sales", id: genRandomString() },
  { text: "Marketing", value: "Marketing", id: genRandomString() },
  {
    text: "Information Technology (IT)",
    value: "Information Technology (IT)",
    id: genRandomString(),
  },
  {
    text: "Operations and Supply Chain",
    value: "Operations and Supply Chain",
    id: genRandomString(),
  },
  { text: "Customer Service", value: "Customer Service", id: genRandomString() },
  { text: "Legal", value: "Legal", id: genRandomString() },
  {
    text: "Administration and Office Management",
    value: "Administration and Office Management",
    id: genRandomString(),
  },
  {
    text: "Research and Development (R&D)",
    value: "Research and Development (R&D)",
    id: genRandomString(),
  },
  { text: "Public Relations (PR)", value: "Public Relations (PR)", id: genRandomString() },
];

export const EMPLOYEE_ROLES_LIST: SelectItem[] = [
  { text: "Admin", value: "Admin", id: genRandomString() },
  { text: "Super admin", value: "Super admin", id: genRandomString() },
];

export const ADMIN_ROLES_LIST: SelectItem[] = [
  { text: "Employee", value: "Employee", id: genRandomString() },
  { text: "Super admin", value: "Super admin", id: genRandomString() },
];

export const SUPER_ADMIN_ROLES_LIST: SelectItem[] = [
  { text: "Employee", value: "Employee", id: genRandomString() },
  { text: "Admin", value: "Admin", id: genRandomString() },
];

export const UN_GOAL_LIST: SelectItem[] = [
  { text: "No Poverty", value: "No Poverty", id: genRandomString() },
  { text: "Zero Hunger", value: "Zero Hunger", id: genRandomString() },
  {
    text: "Good Health and Well-being",
    value: "Good Health and Well-being",
    id: genRandomString(),
  },
  {
    text: "Quality Education",
    value: "Quality Education",
    id: genRandomString(),
  },
  { text: "Gender Equality", value: "Gender Equality", id: genRandomString() },
  {
    text: "Clean Water & Sanitation",
    value: "Clean Water & Sanitation",
    id: genRandomString(),
  },
  {
    text: "Affordable & Clean Energy",
    value: "Affordable & Clean Energy",
    id: genRandomString(),
  },
  {
    text: "Decent Work and Economic Growth",
    value: "Decent Work and Economic Growth",
    id: genRandomString(),
  },
  {
    text: "Industry, Innovation, and Infrastructure",
    value: "Industry, Innovation, and Infrastructure",
    id: genRandomString(),
  },
  {
    text: "Reduced Inequalities",
    value: "Reduced Inequalities",
    id: genRandomString(),
  },
  {
    text: "Sustainable Cities and Communities",
    value: "Sustainable Cities and Communities",
    id: genRandomString(),
  },
  {
    text: "Responsible Consumption and Production",
    value: "Responsible Consumption and Production",
    id: genRandomString(),
  },
  {
    text: "Climate Action",
    value: "Climate Action",
    id: genRandomString(),
  },
  {
    text: "Life Below Water",
    value: "Life Below Water",
    id: genRandomString(),
  },
  {
    text: "Life on Land",
    value: "Life on Land",
    id: genRandomString(),
  },
  {
    text: "Peace, Justice, and Strong Institutions",
    value: "Peace, Justice, and Strong Institutions",
    id: genRandomString(),
  },
  {
    text: "Partnerships for the goals",
    value: "Partnerships for the goals",
    id: genRandomString(),
  },
  {
    text: "Not Applicable",
    value: "Not Applicable",
    id: genRandomString(),
  },
];

export const VOLUNTEER_EVENT_PLACES_LIST: SelectItem[] = [
  { text: "In-person", value: "In-person", id: genRandomString() },
  { text: "Virtual", value: "Virtual", id: genRandomString() },
  { text: "Company Office", value: "Company Office", id: genRandomString() },
  {
    text: "In-person or Company Office",
    value: "In-person or Company Office",
    id: genRandomString(),
  },
  { text: "Virtual or Company Office", value: "Virtual or Company Office", id: genRandomString() },
];

export const VOLUNTEER_NUMBER: SelectItem[] = [
  { text: "1-10", value: "1-10", id: genRandomString() },
  { text: "11-25", value: "11-25", id: genRandomString() },
  { text: "26-50", value: "26-50", id: genRandomString() },
  { text: "51-100", value: "51-100", id: genRandomString() },
  { text: "100+", value: "100+", id: genRandomString() },
  {
    text: "Any group size is sufficient.",
    value: "Any group size is sufficient.",
    id: genRandomString(),
  },
];

export const VOLUNTEER_NUMBER_FOR_FILTER: SelectItem[] = [
  { text: "1-10", value: "1-10", id: genRandomString() },
  { text: "11-25", value: "11-25", id: genRandomString() },
  { text: "26-50", value: "26-50", id: genRandomString() },
  { text: "51-100", value: "51-100", id: genRandomString() },
  { text: "100+", value: "100+", id: genRandomString() },
];

export const GRANT_REQUIRED: SelectItem[] = [
  { text: "Grant required", value: GRANT_STATUS_OPTION.YES, id: genRandomString() },
  { text: "Grant not required", value: GRANT_STATUS_OPTION.NO, id: genRandomString() },
  { text: "Grant preferred", value: GRANT_STATUS_OPTION.PREFERRED, id: genRandomString() },
];

export const VOLUNTEER_TIME_FLEXIBILITY: SelectItem[] = [
  {
    text: "We are flexible and can schedule a date/time that works with the company.",
    value: "We are flexible and can schedule a date/time that works with the company.",
    id: genRandomString(),
  },
  {
    text: "We have a set day every week or month that we need volunteers for.",
    value: "We have a set day every week or month that we need volunteers for.",
    id: genRandomString(),
  },
  {
    text: "We only need volunteers for major events or activities that are occasional.",
    value: "We only need volunteers for major events or activities that are occasional.",
    id: genRandomString(),
  },
];

export type LocatonInputValue =
  | "In-person"
  | "Virtual"
  | "Company Office"
  | "In-person or Company Office"
  | "Virtual or Company Office";

export const EVENT_LOCATION_MAPPER: Record<LocatonInputValue, VolunteerEventLocation> = {
  "In-person": "IN_PERSON",
  "In-person or Company Office": "IN_PERSON|COMPANY_OFFICE",
  "Company Office": "COMPANY_OFFICE",
  Virtual: "VIRTUAL",
  "Virtual or Company Office": "VIRTUAL|COMPANY_OFFICE",
};

export const REVERSE_EVENT_LOCATION_MAPPER: Record<VolunteerEventLocation, LocatonInputValue> = {
  IN_PERSON: "In-person",
  "IN_PERSON|COMPANY_OFFICE": "In-person or Company Office",
  COMPANY_OFFICE: "Company Office",
  VIRTUAL: "Virtual",
  "VIRTUAL|COMPANY_OFFICE": "Virtual or Company Office",
};

export const TIME_ZONES: SelectItem[] = [
  {
    text: "Eastern (EST)",
    value: "US/Eastern",
    id: genRandomString(),
  },
  {
    text: "Central (CT)",
    value: "US/Central",
    id: genRandomString(),
  },
  {
    text: "Mountain (MT)",
    value: "US/Mountain",
    id: genRandomString(),
  },
  {
    text: "Pacific (PST)",
    value: "US/Pacific",
    id: genRandomString(),
  },
];

export const genEditLogoDropdown = (logoUrl: string | null): DropdownItem[] => [
  {
    id: genRandomString(),
    text: "Upload company logo",
    onClick: (dispatch) =>
      dispatch(updateDialogState({ key: "UPLOAD_COMPANY_LOGO", isOpened: true })),
  },
  {
    id: genRandomString(),
    text: "Remove logo",
    onClick: (dispatch) => {
      dispatch(deleteCompanyLogo.request());
    },
    isDisabled: !logoUrl,
  },
];

export const MANAGE_PEOPLE_TABLE_HEADING: UsersTableSort[] = [
  {
    label: "Full Name",
    sortBy: "name",
    orderBy: "asc",
    renderIcon: (order) => <LetterSortIndicator order={order} />,
  },
  { label: "Email" },
  { label: "Department" },
  { label: "Role" },
  {
    label: "Status",
    sortBy: "status",
    orderBy: "asc",
    renderIcon: (order) => <LetterSortIndicator order={order} />,
  },
  { label: "Actions" },
];

export const COMPANY_DONATIONS_TABLE_HEADING: string[] = [
  "First name",
  "Last name",
  "Department",
  "Charity",
  "Note",
  "Date",
  "Amount",
];

export const CHARITY_REGISTRATION_PAGES: string[] = [
  "Create Account",
  "E-mail Verification",
  "Profile Info",
];

export const VOLUNTEER_EVENT_ATTENDEES_HEADINGS: string[] = ["First name", "Last name", "Email"];

export const VOLUNTEERING_TABS: { text: string; table: VolunteerTable }[] = [
  { text: "Discover events", table: "events" },
  { text: "Pending volunteer requests", table: "pending" },
  { text: "Active events", table: "active" },
  { text: "Past events", table: "past" },
];

export const MANAGE_PEOPLE_MENU: DropdownItem[] = [
  {
    id: genRandomString(),
    text: "Home",
    icon: <HomeIcon size={24} fill="#8491A1" />,
    onClick: () => {
      CustomHistory.navigate(routes.HOME);
    },
  },
  {
    id: genRandomString(),
    text: "Impact Dashboard",
    icon: <FourSquaresIcon width={24} fill="#8491A1" />,
    onClick: () => {
      CustomHistory.navigate(`company/dashboard/${getCurrYear()}`);
    },
  },
  {
    id: genRandomString(),
    text: "Volunteering",
    icon: <VolunteeringIcon fill="#8491A1" width={24} />,
    onClick: () => {
      CustomHistory.navigate("/volunteering?view=events");
    },
  },
  {
    id: genRandomString(),
    text: "Donations",
    icon: <DonationsIcon fill="#8491A1" width={24} />,
    onClick: () => {
      CustomHistory.navigate(routes.EVERY_DOT_ORG.CATEGORIES);
    },
  },
  {
    id: genRandomString(),
    text: "Corporate Matching",
    icon: <PapersMatchIcon width={24} fill="#8491A1" />,
    onClick: () => {
      CustomHistory.navigate(routes.COMPANY.GIFT_MATCHING);
    },
  },
  {
    id: LAUNCH_DARKLY_FLAGS.findCharities,
    text: "Find Charities",
    icon: <OrganizationIcon size={20} fill="#8491A1" />,
    onClick: () => {
      CustomHistory.navigate(routes.FIND_CHARITIES);
    },
  },
  {
    id: genRandomString(),
    text: "CSR Calendar",
    icon: <CalendarMenuIcon width={24} fill="#8491A1" />,
    onClick: () => {
      CustomHistory.navigate(routes.COMPANY.CALENDAR);
    },
  },
  {
    id: genRandomString(),
    text: "Manage People",
    icon: <PeopleMenuIcon width={24} fill="#8491A1" />,
    onClick: () => {
      CustomHistory.navigate(routes.COMPANY.MANAGE_PEOPLE);
    },
  },
];

export const GIFT_MATCHING_TABLE_HEADING: GiftMatchTableSort[] = [
  {
    label: "First name",
    sortBy: "firstName",
    orderBy: "asc",
    renderIcon: (order) => <LetterSortIndicator order={order} />,
  },
  {
    label: "Last name",
    sortBy: "lastName",
    orderBy: "asc",
    renderIcon: (order) => <LetterSortIndicator order={order} />,
  },
  {
    label: "Charity",
    sortBy: "charityName",
    orderBy: "asc",
    renderIcon: (order) => <LetterSortIndicator order={order} />,
  },
  { label: "Charity Check PDF", sortBy: null, orderBy: null },
  {
    label: "Date",
    sortBy: "date",
    orderBy: "asc",
    renderIcon: (order) => <AmountSortIndicator order={order} />,
  },
  {
    label: "Gift Match Usage",
    sortBy: null,
    orderBy: null,
  },
  {
    label: "Donation Amount",
    sortBy: "amount",
    orderBy: "asc",
    renderIcon: (order) => <AmountSortIndicator order={order} />,
  },
  { label: "Donation Receipt", sortBy: null, orderBy: null },
  { label: "Status", sortBy: null, orderBy: null },
];

export const GIFT_MATCHING_ACTIVITY_TABLE_HEADING: string[] = [
  "Name of Nonprofit",
  "Date",
  "Donation Amount",
  "Tax Receipt",
  "Gift Match Status",
];

export const PROFILE_MENU: DropdownItem[] = [
  {
    id: genRandomString(),
    text: "My profile",
    onClick: () => {
      CustomHistory.navigate(routes.EMPLOYEE.DASHBOARD);
    },
  },
  {
    id: genRandomString(),
    text: "Log out",
    onClick: (dispatch) => {
      localStorage.removeItem(storageKeys.AUTH_TOKEN);
      localStorage.removeItem(storageKeys.USER_ROLE);
      dispatch(logout());
    },
  },
];

export const EMPLOYEE_BURGER_MENU: DropdownItem[] = [
  {
    id: genRandomString(),
    text: "Home",
    icon: <HomeIcon size={24} fill="#8491A1" />,
    onClick: () => {
      CustomHistory.navigate(routes.HOME);
    },
  },
  {
    id: genRandomString(),
    text: "Donations",
    icon: <PapersMatchIcon width={24} fill="#8491A1" />,
    onClick: () => {
      CustomHistory.navigate(routes.EVERY_DOT_ORG.CATEGORIES);
    },
  },
  {
    id: LAUNCH_DARKLY_FLAGS.findCharities,
    text: "Find Charities",
    icon: <OrganizationIcon size={20} fill="#8491A1" />,
    onClick: () => {
      CustomHistory.navigate(routes.FIND_CHARITIES);
    },
  },
];

export const CHARITY_PROFILE_MENU: DropdownItem[] = [
  {
    id: genRandomString(),
    text: "Log out",
    onClick: (dispatch) => {
      localStorage.removeItem(storageKeys.AUTH_TOKEN);
      localStorage.removeItem(storageKeys.USER_ROLE);
      dispatch(logout());
    },
  },
];

export const LOKKING_FOR_MAPPER = {
  Donations: "Donations",
  "Board members": "Board",
  "Skill-based volunteers": "Volunteers",
};

export const US_STATES_LIST = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" },
];

export const EVERY_DOT_ORG_CATEGORIES: Record<
  string,
  { icon?: string; text: string; alias?: string }[]
> = {
  animals: [
    { icon: "🐈", text: "Cats", alias: "cats" },
    { icon: "🐶", text: "Dogs", alias: "dogs" },
    { icon: "🦌", text: "Wildlife", alias: "wildlife" },
  ],
  "diverse led organizations": [
    { text: "AAPI-Led", alias: "aapi-led" },
    { text: "Black Led", alias: "black-led" },
    { text: "Indigenous Led", alias: "indigenous-led" },
    { text: "Latine Led", alias: "latine-led" },
    { text: "Women Led", alias: "women-led" },
  ],
  "education & science": [
    { icon: "🏫", text: "Libraries", alias: "libraries" },
    { icon: "🏛", text: "Museums", alias: "museums" },
    { icon: "📖", text: "Research", alias: "research" },
    { icon: "🧪", text: "Science", alias: "science" },
    { icon: "🛰", text: "Space", alias: "space" },
  ],
  environment: [
    { icon: "🌡", text: "Climate", alias: "climate" },
    { icon: "🌊", text: "Oceans", alias: "oceans" },
    { icon: "🏞", text: "Parks", alias: "parks" },
    { icon: "💦", text: "Water", alias: "water" },
    { icon: "🔥", text: "Wildfires", alias: "wildfires" },
  ],
  health: [
    { text: "Autism", alias: "autism" },
    { icon: "🧬", text: "Cancer", alias: "cancer" },
    { icon: "👨🏼‍⚕", text: "Disabilities", alias: "disabilities" },
    { icon: "😷", text: "Disease", alias: "disease" },
    { icon: "🧠", text: "Mental Health", alias: "mental-health" },
  ],
  "human services": [
    { text: "Adoption", alias: "adoption" },
    { icon: "🏘", text: "Housing", alias: "housing" },
    { icon: "🍽", text: "Food Security", alias: "food-security" },
    { text: "Immigrants", alias: "immigrants" },
    { text: "Poverty", alias: "poverty" },
  ],
  religion: [
    { icon: "☸️", text: "Buddhism", alias: "buddhism" },
    { icon: "✝️", text: "Christianity", alias: "christianity" },
    { icon: "🕉", text: "Hinduism", alias: "hinduism" },
    { icon: "☪️", text: "Islam", alias: "islam" },
    { icon: "✡️", text: "Judaism", alias: "judaism" },
  ],
  "social justice": [
    { icon: "⚥", text: "Gender Equality", alias: "gender-equality" },
    { icon: "🏳️‍🌈", text: "LGBTQ", alias: "lgbt" },
    { text: "Racial Justice", alias: "racial-justice" },
    { icon: "⚧️", text: "Transgender", alias: "transgender" },
    { icon: "🗳️", text: "Voting Rights", alias: "votingrights" },
  ],
  "the arts": [
    { icon: "🎨", text: "Art", alias: "art" },
    { icon: "💃", text: "Dance", alias: "dance" },
    { icon: "🎥", text: "Film & TV", alias: "filmandtv" },
    { icon: "🎶", text: "Music", alias: "music" },
    { icon: "📻", text: "Radio", alias: "radio" },
  ],
  other: [
    { icon: "🏋🏽‍♀️", text: "Athletics", alias: "athletics" },
    { icon: "⚖️", text: "Legal", alias: "legal" },
    { text: "Indigenous Peoples", alias: "indigenous-peoples" },
    { text: "Veterans", alias: "veterans" },
  ],
};

export const FREQUENCY_MAPPER: Record<DonationFrequency, MatchingOption> = {
  YEAR: "Per year",
  DONATION: "Per donation",
  MONTH: "Per month",
};

export const MANAGE_PEOPLE_TABS: { text: string; table: ManagePeopleTableType }[] = [
  { text: "Admin users", table: "admins" },
  { text: "Employees", table: "employees" },
];

export const ADVANCED_ANALYTICS = [
  {
    text: "Donations - analytics for employees who donated at least once",
    value: "Donations - analytics for employees who donated at least once",
    id: genRandomString(),
  },
  {
    text: "Gift Matching - analytics for employees who requested an ON or OFF platform match at least once",
    value:
      "Gift Matching - analytics for employees who requested an ON or OFF platform match at least once",
    id: genRandomString(),
  },
  {
    text: "Volunteering - analytics for employees who signed up for a volunteer event at least once",
    value:
      "Volunteering - analytics for employees who signed up for a volunteer event at least once",
    id: genRandomString(),
  },
];

export const AdvancedAnalyticsTitles = Object.freeze({
  DONATIONS: "WHO ARE THE EMPLOYEES DONATING?",
  GIFT_MATCHES: "WHO ARE THE EMPLOYEES REQUESTING A MATCH?",
  VOLUNTEERING: "WHO ARE THE EMPLOYEES VOLUNTEERING?",
});

export const AdvancedAnalyticsEmployeesText = Object.freeze({
  DONATIONS: "Employees Donated at Least Once ",
  GIFT_MATCHES: "Employees Requested a Match at Least Once ",
  VOLUNTEERING: "Employees Registered to Volunteer at Least Once ",
});

export const INTERESTS_LIST: string[] = [
  "Cats",
  "Dogs",
  "Wildlife",
  "AAPI-Led",
  "Black-Led",
  "Indigenous-Led",
  "Latin-Led",
  "Women-Led",
  "Libraries",
  "Museums",
  "Research",
  "Science",
  "Space",
  "Climate",
  "Ocean",
  "Parks",
  "Water",
  "Wildfires",
  "Autism",
  "Cancer",
  "Disabilities",
  "Disease",
  "Mental Health",
  "Adoption",
  "Housing",
  "Food-security",
  "Immigrants",
  "Poverty",
  "Seniors",
  "Veterans",
  "Buddhism",
  "Christianity",
  "Hinduism",
  "Islam",
  "Judaism",
  "Gender Equality",
  "LGTBQ",
  "Racial Justice",
  "Transgender",
  "Voting rights",
  "Art",
  "Dance",
  "Film & TV",
  "Music",
  "Radio",
  "Athletics",
  "Legal",
  "Indigenous-People",
];
