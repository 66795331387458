import { ArrowRightIcon } from "@primer/octicons-react";
import CharityCard from "components/charity-card";
import React from "react";
import { useNavigate } from "react-router-dom";
import HorizontalDraggableScroll from "templates/horizontal-draggable-scroll";
import { genRandomString } from "utils/helper";
import routes from "utils/routes";

import * as S from "../charity-grid.styled";
import * as T from "../charity-grid.type";

const MAX_CHARITIES_IN_HORIZONTAL_SECTION = 5;

const HorizontalCharitySection: React.FC<T.ICharitySectionProps> = ({
  items,
  title,
  isAdminView = false,
  isBacked,
}): JSX.Element => {
  const navigate = useNavigate();

  return items?.length ? (
    <S.SectionContainer
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ width: "100%" }}
    >
      <S.RowDisplay gridGap={20} flexWrap>
        {!!title && (
          <S.SectionGradientHeading hideMargins mobileMargins={20}>
            {title}
          </S.SectionGradientHeading>
        )}

        {items?.length >= MAX_CHARITIES_IN_HORIZONTAL_SECTION && (
          <S.LinkText onClick={() => navigate(`${routes.FIND_CHARITIES}?category=${title}`)}>
            View All <ArrowRightIcon />
          </S.LinkText>
        )}
      </S.RowDisplay>

      <HorizontalDraggableScroll alignItems="flex-start">
        <>
          {items.slice(0, MAX_CHARITIES_IN_HORIZONTAL_SECTION).map((card) => (
            <CharityCard
              {...{ ...card, backed: isBacked }}
              key={genRandomString()}
              isAdminView={isAdminView}
            />
          ))}
        </>
      </HorizontalDraggableScroll>
    </S.SectionContainer>
  ) : null;
};

export default HorizontalCharitySection;
