import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import * as C from "./finch.constants";
import * as T from "./finch.type";

export const finchInitial: T.IFinchState = {
  inProgress: false,
  error: null,
};

const finchReducer = (state: T.IFinchState, action: T.FinchAction | ILogout): T.IFinchState => {
  if (!state) {
    return finchInitial;
  }

  switch (action.type) {
    case C.INITIALIZE_FINCH:
      return {
        ...state,
        inProgress: true,
      };
    case C.INITIALIZE_FINCH_SUCCESS:
      return {
        ...state,
        inProgress: false,
      };
    case C.INITIALIZE_FINCH_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case LOGOUT:
      return finchInitial;
    default:
      return state;
  }
};

export default finchReducer;
