import { ArrowLeftIcon, MailIcon } from "@primer/octicons-react";
import { Spinner } from "@primer/react";
import GrayButton from "components/buttons/gray";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { getCharities, getMoreCharities } from "store/charities/charities.action";
import charityListSelectors from "store/charities/charities.selector";
import { showMoreSearchedCharities } from "store/charities-search/charities-search.action";
import charitiesSearchSelectors from "store/charities-search/charities-search.selector";
import companySelectors from "store/company/company.selector";
import { IAppState } from "store/store.state";
import colors from "theme/colors";
import { AdminTabType, EmployeeTabType } from "types/charity";
import { CHARITY_MAILTO, MAX_CHARITIES_LENGTH } from "utils/constants";
import routes from "utils/routes";

import * as S from "./charity-grid.styled";
import * as T from "./charity-grid.type";
import AdminControls from "./components/admin-controls";
import EmployeeControls from "./components/employee-controls";
import HorizontalCharitySection from "./components/horizontal-charity-section";
import VerticalCharitySection from "./components/vertical-charity-section";

const CharityGridView: React.FC<T.ICharityGridProps> = ({ isAdminView = false }): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedEmployeeTab, selectEmployeeTab] = React.useState<EmployeeTabType>("you");
  const [selectedAdminTab, selectAdminTab] = React.useState<AdminTabType>("add_charity");
  const category = location.search.replace("?category=", "").replaceAll("%20", " ");

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const tab = isAdminView ? selectedAdminTab : selectedEmployeeTab;
  const isBacked = isAdminView && selectedAdminTab === "wall";

  const charities = useSelector((state: IAppState) => charityListSelectors.selectData(state, tab));
  const isFetched = useSelector(charityListSelectors.selectIsFetched);
  const loadingSections = useSelector(charityListSelectors.selectLoadingSections);
  const charitiesLoading = useSelector(charityListSelectors.selectInProgress);

  const searchedCharities = useSelector(charitiesSearchSelectors.selectData);
  const isSearched = useSelector(charitiesSearchSelectors.selectIsSearched);
  const matches = useSelector(charitiesSearchSelectors.selectMatches);
  const isSearchEndReached = useSelector(charitiesSearchSelectors.selectIsEndReached);
  const searchInProgress = useSelector(charitiesSearchSelectors.selectInProgress);

  const showSearch = isSearched && tab !== "company" && tab !== "wall";

  const inProgress = charitiesLoading || searchInProgress;

  const charityNum = Object.keys(charities || {})?.flatMap(
    (key) => (charities || {})?.[key]?.data,
  )?.length;

  const companyName = useSelector(companySelectors.selectName);

  React.useEffect(() => {
    if (!inProgress && !isFetched?.includes(tab)) {
      dispatch(getCharities.request({ tab }));
    }
  }, [tab]);

  const charitiesDisplay: JSX.Element = (
    <>
      {category || showSearch || searchInProgress ? (
        <>
          {searchInProgress && matches === 0 ? (
            <Spinner size="large" />
          ) : (
            <>
              {(showSearch || (category && !charities[category]?.data?.length)) && (
                <>
                  {!matches || (category && !charities[category]?.data?.length) ? (
                    <S.ColumnDisplay>
                      {searchInProgress ? (
                        <Spinner size="large" />
                      ) : (
                        <S.ColumnDisplay gridGap={24} style={{ minHeight: 480 }}>
                          <S.NoMatchesHeading>
                            No charities matched the search criteria
                          </S.NoMatchesHeading>
                          <S.NoMatchesSubheading>
                            Please try searching for a different organization or refer a nonprofit
                            below!
                          </S.NoMatchesSubheading>
                          <GrayButton
                            label="Refer a charity"
                            onClick={(e) => {
                              window.location.href = CHARITY_MAILTO;
                              e.preventDefault();
                            }}
                            sx={{ width: "173px" }}
                            leadingIcon={MailIcon}
                          />
                        </S.ColumnDisplay>
                      )}
                    </S.ColumnDisplay>
                  ) : (
                    <S.MatchesText>{`${matches} charities matches`}</S.MatchesText>
                  )}
                </>
              )}
              {showSearch ? (
                <>
                  <VerticalCharitySection
                    items={searchedCharities}
                    isAdminView={isAdminView}
                    isFetching={false}
                    isTablet={isTablet}
                  />
                  <S.Separator height={40} />
                  {!isSearchEndReached && (
                    <>
                      <GrayButton
                        label="Show more"
                        isLoading={searchInProgress}
                        onClick={() => dispatch(showMoreSearchedCharities.request())}
                      />
                      <S.Separator height={40} />
                    </>
                  )}
                </>
              ) : (
                <>
                  <VerticalCharitySection
                    items={charities[category]?.data}
                    isAdminView={isAdminView}
                    isFetching={inProgress}
                    isTablet={isTablet}
                  >
                    <S.ColumnDisplay gridGap={60} alignItems="flex-start">
                      <S.ClickableWrapper onClick={() => navigate(routes.FIND_CHARITIES)}>
                        <S.RowDisplay gridGap={18}>
                          <ArrowLeftIcon size={25} />
                          <S.BackText>User profile</S.BackText>
                        </S.RowDisplay>
                      </S.ClickableWrapper>
                      <S.SectionGradientHeading
                        hideMargins
                        mobileMargins={20}
                        style={{
                          marginLeft: 0,
                          alignSelf: "start",
                          fontSize: 40,
                          lineHeight: "40px",
                        }}
                      >
                        {category}
                      </S.SectionGradientHeading>
                    </S.ColumnDisplay>
                  </VerticalCharitySection>
                  {charities[category]?.data?.length >= MAX_CHARITIES_LENGTH &&
                    !charities[category]?.isEndReached && (
                      <S.ColumnDisplay style={{ paddingBottom: 40 }}>
                        <GrayButton
                          label="Show more"
                          sx={{ width: "174px" }}
                          isLoading={false}
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(
                              getMoreCharities.request({
                                tab,
                                category,
                                after: charities[category]?.lastId,
                              }),
                            );
                          }}
                        />
                      </S.ColumnDisplay>
                    )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {Object.keys(charities || {}).map((key) => {
            const section = (charities || {})?.[key];
            const sectionData = section?.data || [];
            return (
              <S.ColumnDisplay width="100%">
                <HorizontalCharitySection
                  items={sectionData}
                  title={key}
                  isAdminView={isAdminView}
                  isFetching={loadingSections.includes(key)}
                  isBacked={isBacked}
                />
                <S.Separator height={40} />
                {sectionData.length > 7 && !section?.isEndReached && (
                  <GrayButton
                    label="Show more"
                    onClick={() =>
                      dispatch(
                        getMoreCharities.request({
                          tab,
                          category: key,
                          after: section?.lastId,
                        }),
                      )
                    }
                  />
                )}
                <S.Separator height={40} />
              </S.ColumnDisplay>
            );
          })}
        </>
      )}
    </>
  );

  return (
    <S.ContainerBackground
      background={
        !isAdminView && selectedEmployeeTab === "company" ? colors.aliceBlue() : colors.white()
      }
    >
      <S.RootWrapper>
        {!isAdminView ? (
          <>
            <EmployeeControls
              selectedTab={selectedEmployeeTab}
              selectTab={selectEmployeeTab}
              companyName={companyName}
              charitiesNum={charityNum}
            />
            {selectedEmployeeTab === "you" && <S.Separator height={60} />}
            {charitiesDisplay}
          </>
        ) : (
          <>
            <AdminControls
              selectedTab={selectedAdminTab}
              selectTab={selectAdminTab}
              companyName={companyName}
              charitiesNum={charityNum}
            />
            <S.Separator height={60} />
            {charitiesDisplay}
          </>
        )}
        {!charityNum && (
          <S.ColumnDisplay>
            {inProgress ? (
              <Spinner size="large" />
            ) : (
              <S.ColumnDisplay>
                <S.NoMatchesHeading>No charities matched the search criteria</S.NoMatchesHeading>
                <S.NoMatchesSubheading>
                  Please try searching for a different organization or refer a nonprofit below!
                </S.NoMatchesSubheading>
                <GrayButton
                  label="Refer a charity"
                  onClick={(e) => {
                    window.location.href = CHARITY_MAILTO;
                    e.preventDefault();
                  }}
                  sx={{ width: "173px" }}
                  leadingIcon={MailIcon}
                />
              </S.ColumnDisplay>
            )}
          </S.ColumnDisplay>
        )}
      </S.RootWrapper>
    </S.ContainerBackground>
  );
};

export default CharityGridView;
