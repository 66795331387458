import * as C from "./registration.constants";
import * as T from "./registration.type";

export const storeCompanyDTO = (payload: T.IStoreCompanyDTO["payload"]): T.IStoreCompanyDTO => ({
  type: C.STORE_COMPANY_DTO,
  payload,
});

export const storeEmployeeDTO = (payload: T.IStoreEmployeeDTO["payload"]): T.IStoreEmployeeDTO => ({
  type: C.STORE_EMPLOYEE_DTO,
  payload,
});

export const storeAdminDTO = (payload: T.IStoreAdminDTO["payload"]): T.IStoreAdminDTO => ({
  type: C.STORE_ADMIN_DTO,
  payload,
});
