import * as api from "api/finch";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { showToast } from "store/ui-components/ui-components.actions";
import { ACCEPTED } from "utils/status-code";

import { initializeFinch } from "./finch.action";
import * as C from "./finch.constants";
import * as T from "./finch.type";

const initializeFinchEpic: AppEpic = (action$: Observable<T.IInitializeFinch>) =>
  action$.pipe(
    ofType(C.INITIALIZE_FINCH),
    mergeMap((action) =>
      from(api.initializeFinch(action.payload)).pipe(
        mergeMap((initialized) => {
          if (initialized === ACCEPTED) {
            return from(api.inviteEmployees()).pipe(
              mergeMap((invited) => {
                if (invited === ACCEPTED) {
                  return of(
                    initializeFinch.success(),
                    showToast({
                      type: "success",
                      message:
                        "HR System successfully connected. Invites have been sent to imported employees.",
                    }),
                  );
                }
                return of(initializeFinch.failure());
              }),
              catchError((error) => of(initializeFinch.failure(), handleError({ action, error }))),
            );
          }
          return of(initializeFinch.failure());
        }),
        catchError((error) => of(initializeFinch.failure(), handleError({ action, error }))),
      ),
    ),
  );

export default combineEpics(initializeFinchEpic);
