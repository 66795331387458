import React, { useEffect, useRef, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { SelectItem } from "types/content";

import * as S from "../custom-multiselect-component/custom-multiselect-component.styled";

interface ControlledSelectProps {
  items: SelectItem[];
  name: string;
  control: Control<any>;
  placeholder?: string;
  onSelect?: (value: SelectItem) => void;
}

const ControlledCustomSelect: React.FC<ControlledSelectProps> = ({
  items,
  name,
  placeholder,
  control,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectContainerRef.current &&
        !selectContainerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (value: SelectItem, formChange: any): void => {
    setIsOpen(false);
    formChange(value);
    if (onSelect) {
      onSelect(value);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange: formChange } }) => (
        <S.RelativeContainer ref={selectContainerRef}>
          <S.SelectContainer>
            <S.SelectedValue onClick={() => setIsOpen(!isOpen)}>
              {value !== null ? (
                <S.SelectedValueText>{value?.text}</S.SelectedValueText>
              ) : (
                <S.PlaceholderText>{placeholder}</S.PlaceholderText> || "Select..."
              )}
              <S.ArrowIcon />
            </S.SelectedValue>
          </S.SelectContainer>
          {isOpen && (
            <S.SelectList>
              {items.map((item, index) => (
                <S.Option key={index} onClick={() => handleSelect(item, formChange)}>
                  <span>{item.text}</span>
                </S.Option>
              ))}
            </S.SelectList>
          )}
        </S.RelativeContainer>
      )}
    />
  );
};

ControlledCustomSelect.defaultProps = {
  onSelect: undefined,
  placeholder: undefined,
};

export default ControlledCustomSelect;
