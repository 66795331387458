import * as C from "./volunteer-events-charity.constants";
import * as T from "./volunteer-events-charity.type";

export const fetchVolunteerEvents = {
  request: (payload: T.IFetchVolunteerEvents["payload"]): T.IFetchVolunteerEvents => ({
    type: C.FETCH_VOLUNTEER_EVENTS,
    payload,
  }),
  success: (
    payload: T.IFetchVolunteerEventsSuccess["payload"],
  ): T.IFetchVolunteerEventsSuccess => ({
    type: C.FETCH_VOLUNTEER_EVENTS_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchVolunteerEventsFailure => ({
    type: C.FETCH_VOLUNTEER_EVENTS_FAILURE,
  }),
};

export const saveVolunteerEventDto = (
  payload: T.ISaveVolunteerEventDto["payload"],
): T.ISaveVolunteerEventDto => ({
  payload,
  type: C.SAVE_VOLUNTEER_EVENT_DTO,
});

export const resetVolunteerEventDto = (): T.IResetVolunteerEventDto => ({
  type: C.RESET_VOLUNTEER_EVENT_DTO,
});

export const saveCustomVolunteerEventDto = (
  payload: T.ISaveCustomVolunteerEventDto["payload"],
): T.ISaveCustomVolunteerEventDto => ({
  payload,
  type: C.SAVE_CUSTOM_VOLUNTEER_EVENT_DTO,
});

export const resetCustomVolunteerEventDto = (): T.IResetCustomVolunteerEventDto => ({
  type: C.RESET_CUSTOM_VOLUNTEER_EVENT_DTO,
});

export const postVolunteerEvent = {
  request: (payload: T.IPostVolunteerEvent["payload"]): T.IPostVolunteerEvent => ({
    type: C.POST_VOLUNTEER_EVENT,
    payload,
  }),
  success: (payload: T.IPostVolunteerEventSuccess["payload"]): T.IPostVolunteerEventSuccess => ({
    type: C.POST_VOLUNTEER_EVENT_SUCCESS,
    payload,
  }),
  failure: (): T.IPostVolunteerEventFailure => ({
    type: C.POST_VOLUNTEER_EVENT_FAILURE,
  }),
};

export const postCustomVolunteerEvent = {
  request: (payload: T.IPostCustomVolunteerEvent["payload"]): T.IPostCustomVolunteerEvent => ({
    type: C.POST_CUSTOM_VOLUNTEER_EVENT,
    payload,
  }),
  success: (
    payload: T.IPostCustomVolunteerEventSuccess["payload"],
  ): T.IPostCustomVolunteerEventSuccess => ({
    type: C.POST_CUSTOM_VOLUNTEER_EVENT_SUCCESS,
    payload,
  }),
  failure: (): T.IPostCustomVolunteerEventFailure => ({
    type: C.POST_CUSTOM_VOLUNTEER_EVENT_FAILURE,
  }),
};

export const editVolunteerEvent = {
  request: (payload: T.IEditVolunteerEvent["payload"]): T.IEditVolunteerEvent => ({
    type: C.EDIT_VOLUNTEER_EVENT,
    payload,
  }),
  success: (payload: T.IEditVolunteerEventSuccess["payload"]): T.IEditVolunteerEventSuccess => ({
    type: C.EDIT_VOLUNTEER_EVENT_SUCCESS,
    payload,
  }),
  failure: (): T.IEditVolunteerEventFailure => ({
    type: C.EDIT_VOLUNTEER_EVENT_FAILURE,
  }),
};

export const fetchCharityVolunteerEvents = {
  request: (): T.IFetchCharityVolunteerEvents => ({
    type: C.FETCH_CHARITY_VOLUNTEER_EVENTS,
  }),
  success: (
    payload: T.IFetchCharityVolunteerEventsSuccess["payload"],
  ): T.IFetchCharityVolunteerEventsSuccess => ({
    type: C.FETCH_CHARITY_VOLUNTEER_EVENTS_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchCharityVolunteerEventsFailure => ({
    type: C.FETCH_CHARITY_VOLUNTEER_EVENTS_FAILURE,
  }),
};
