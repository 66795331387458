import * as C from "./every-org.constants";
import * as T from "./every-org.type";

export const getEveryOrgCharities = {
  request: (payload: T.IGetEveryOrgCharities["payload"]): T.IGetEveryOrgCharities => ({
    type: C.GET_EVERY_ORG_CHARITIES,
    payload,
  }),
  success: (
    payload: T.IGetEveryOrgCharitiesSuccess["payload"],
  ): T.IGetEveryOrgCharitiesSuccess => ({
    type: C.GET_EVERY_ORG_CHARITIES_SUCCESS,
    payload,
  }),
  failure: (): T.IGetEveryOrgCharitiesFailure => ({
    type: C.GET_EVERY_ORG_CHARITIES_FAILURE,
  }),
};

export const getEveryOrgCharityDetails = {
  request: (payload: T.IGetEveryOrgCharityDetails["payload"]): T.IGetEveryOrgCharityDetails => ({
    type: C.GET_EVERY_ORG_CHARITY_DETAILS,
    payload,
  }),
  success: (
    payload: T.IGetEveryOrgCharityDetailsSuccess["payload"],
  ): T.IGetEveryOrgCharityDetailsSuccess => ({
    type: C.GET_EVERY_ORG_CHARITY_DETAILS_SUCCESS,
    payload,
  }),
  failure: (): T.IGetEveryOrgCharityDetailsFailure => ({
    type: C.GET_EVERY_ORG_CHARITY_DETAILS_FAILURE,
  }),
};

export const changeEveryOrgCharityDetails = {
  request: (
    payload: T.IChangeEveryOrgCharityDetails["payload"],
  ): T.IChangeEveryOrgCharityDetails => ({
    type: C.CHANGE_EVERY_ORG_DETAILS,
    payload,
  }),
};
