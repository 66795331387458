import SmallButton from "components/buttons/small";
import styled from "styled-components";
import { ColumnDisplay, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ClickableWrapper,
  ColumnDisplay,
  FormWrapper,
  LinkText,
  LinkWrapper,
  PaginationWrapper,
  RowDisplay,
  Separator,
  Table,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.desktopS.x;
export const MOBILE_BREAKPOINT = breakpoints.iPhone13Pro.x;

export const EmployeeDashboardContainer = styled(ColumnDisplay)`
  height: 100vh;
  max-height: 100vh;
  min-width: 321px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${({ theme }) => theme.aliceBlue()};
`;

export const EmployeeDashboardInnerContainer = styled(ColumnDisplay)`
  width: calc(100vw - 2 * 152px);
  height: 100%;
  max-width: 1424px;
  margin-top: 40px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: calc(100% - 2 * 24px);
  }
`;

export const RowDisplayFullWidth = styled(RowDisplay)`
  width: 100%;
  flex-wrap: wrap;
`;

export const UserProfileText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const DashboardWhiteFrame = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);
  border-radius: 12px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 20px;
  }
`;

export const DashboardWhiteFrameFlexContainer = styled(RowDisplay)`
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const EmployeeName = styled.div`
  background: ${({ theme }) => theme.white()};
  color: ${({ theme }) => theme.gunmetal()};
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const EmployeeRole = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.blackCoral(0.6)};
`;

export const EmployeeDashboardBubble = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  border-radius: 24px;
  border: 1px solid rgba(233, 235, 236, 1);
  padding: 16px;
  padding-bottom: 20px;
  text-align: center;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);
  position: relative;
`;

export const EmployeeDashboardNumber = styled(RowDisplay)<{ color: string }>`
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 0em;
  color: ${({ color }) => color};
`;

export const EmployeeDashboardLabel = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.blackCoral()};
`;

export const EmployeeDashboardBottomLabel = styled(EmployeeDashboardLabel)`
  font-size: 8.5px;
  position: absolute;
  bottom: 0;
  margin-bottom: 4px;
`;

export const RecentActivityHeading = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.blackCoral(0.5)};
`;

export const EmptyContainer = styled(ColumnDisplay)`
  min-height: 352px;
  width: 100%;
  text-align: center;
`;

export const EmptyHeading = styled.div`
  color: ${({ theme }) => theme.metallicSilver()};
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.3px;
`;

export const EmptySubheading = styled.div`
  color: ${({ theme }) => theme.metallicSilver()};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
  max-width: 308px;
`;

export const CustomButton = styled(SmallButton)`
  height: 32px;
`;

export const UserInterestsContainer = styled.div`
  display: flex;
  margin: 10px;
`;
