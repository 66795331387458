import * as api from "api/every";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, debounceTime, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";

import { resetSearchTerm, searchEveryOrgCharities } from "./every-org-search.action";
import * as C from "./every-org-search.constants";
import * as T from "./every-org-search.type";

const searchEveryOrgCharitiesEpic: AppEpic = (action$: Observable<T.ISearchEveryOrgCharities>) =>
  action$.pipe(
    ofType(C.SEARCH_EVERY_ORG_CHARITIES),
    debounceTime(1000),
    mergeMap((action) =>
      action.payload.searchTerm.length
        ? from(api.searchEveryDotOrgCharities(action.payload.searchTerm)).pipe(
            mergeMap((response) => {
              if (response) {
                return of(
                  searchEveryOrgCharities.success({
                    data: response.nonprofits,
                    searchTerm: action.payload.searchTerm,
                    showOnPage: action.payload.showOnPage,
                  }),
                );
              }
              return of(searchEveryOrgCharities.failure());
            }),
            catchError((error) =>
              of(searchEveryOrgCharities.failure(), handleError({ action, error })),
            ),
          )
        : of(resetSearchTerm()),
    ),
  );

export default combineEpics(searchEveryOrgCharitiesEpic);
