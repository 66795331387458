export default {
  // desktop
  desktopXL: {
    x: 1920,
    y: 1080,
  },
  desktopL: {
    x: 1600,
    y: 900,
  },
  desktopM: {
    x: 1440,
    y: 900,
  },
  desktopS: {
    x: 1280,
    y: 1024,
  },
  // tablet
  iPadMini: {
    x: 768,
    y: 1024,
  },
  iPadPro: {
    x: 1024,
    y: 1366,
  },
  samsungGalaxyTab10: {
    x: 900,
    y: 1280,
  },
  // mobile
  iPhone5: {
    x: 320,
    y: 568,
  },
  iPhone8: {
    x: 375,
    y: 736,
  },
  iPhone13Pro: {
    x: 390,
    y: 844,
  },
  iPhoneXR: {
    x: 414,
    y: 896,
  },
  googlePixelXL: {
    x: 412,
    y: 732,
  },
  samsungGalaxyS9: {
    x: 360,
    y: 740,
  },
};
