import * as C from "./donations.constants";
import * as T from "./donations.type";

export const matchDonation = {
  request: (payload: T.IMatchDonationRequest["payload"]): T.IMatchDonationRequest => ({
    type: C.MATCH_DONATION,
    payload,
  }),
  success: (): T.IMatchDonationSuccess => ({
    type: C.MATCH_DONATION_SUCCESS,
  }),
  failure: (): T.IMatchDonationFailure => ({
    type: C.MATCH_DONATION_FAILURE,
  }),
};

export const matchEveryDonation = {
  request: (payload: T.IMatchEveryDonationRequest["payload"]): T.IMatchEveryDonationRequest => ({
    type: C.MATCH_EVERY_DONATION,
    payload,
  }),
  success: (): T.IMatchEveryDonationSuccess => ({
    type: C.MATCH_EVERY_DONATION_SUCCESS,
  }),
  failure: (): T.IMatchEveryDonationFailure => ({
    type: C.MATCH_EVERY_DONATION_FAILURE,
  }),
};

export const respondToDonation = {
  request: (payload: T.IRespondToDonationRequest["payload"]): T.IRespondToDonationRequest => ({
    type: C.RESPOND_TO_DONATION,
    payload,
  }),
  success: (payload: T.IRespondToDonationSuccess["payload"]): T.IRespondToDonationSuccess => ({
    type: C.RESPOND_TO_DONATION_SUCCESS,
    payload,
  }),
  failure: (): T.IRespondToDonationFailure => ({
    type: C.RESPOND_TO_DONATION_FAILURE,
  }),
};

export const getDonations = {
  request: (payload: T.IGetDonationsRequest["payload"]): T.IGetDonationsRequest => ({
    type: C.GET_DONATIONS,
    payload,
  }),
  success: (payload: T.IGetDonationsSuccess["payload"]): T.IGetDonationsSuccess => ({
    type: C.GET_DONATIONS_SUCCESS,
    payload,
  }),
  failure: (payload: T.IGetDonationsFailure["payload"]): T.IGetDonationsFailure => ({
    type: C.GET_DONATIONS_FAILURE,
    payload,
  }),
};
