import * as C from "./charity.constant";
import * as T from "./charity.type";

export const registerCharity = {
  request: (payload: T.IRegisterCharity["payload"]): T.IRegisterCharity => ({
    type: C.REGISTER_CHARITY,
    payload,
  }),
  success: (payload: T.IRegisterCharitySuccess["payload"]): T.IRegisterCharitySuccess => ({
    type: C.REGISTER_CHARITY_SUCCESS,
    payload,
  }),
  failure: (payload?: T.IRegisterCharityFailure["payload"]): T.IRegisterCharityFailure => ({
    type: C.REGISTER_CHARITY_FAILURE,
    payload,
  }),
};

export const confirmCharityRegistration = {
  request: (payload: T.IConfirmCharityRegistration["payload"]): T.IConfirmCharityRegistration => ({
    type: C.CONFIRM_CHARITY_REGISTRATION,
    payload,
  }),
  success: (
    payload: T.IConfirmCharityRegistrationSuccess["payload"],
  ): T.IConfirmCharityRegistrationSuccess => ({
    type: C.CONFIRM_CHARITY_REGISTRATION_SUCCESS,
    payload,
  }),
  failure: (): T.IConfirmCharityRegistrationFailure => ({
    type: C.CONFIRM_CHARITY_REGISTRATION_FAILURE,
  }),
};

export const confirmCharityAdminRegistration = {
  request: (
    payload: T.IConfirmCharityAdminRegistration["payload"],
  ): T.IConfirmCharityAdminRegistration => ({
    type: C.CONFIRM_CHARITY_ADMIN_REGISTRATION,
    payload,
  }),
  success: (): T.IConfirmCharityAdminRegistrationSuccess => ({
    type: C.CONFIRM_CHARITY_ADMIN_REGISTRATION_SUCCESS,
  }),
  failure: (): T.IConfirmCharityAdminRegistrationFailure => ({
    type: C.CONFIRM_CHARITY_ADMIN_REGISTRATION_FAILURE,
  }),
};

export const setCharityDetails = {
  request: (payload: T.ISetCharityDetails["payload"]): T.ISetCharityDetails => ({
    type: C.SET_CHARITY_DETAILS,
    payload,
  }),
  success: (payload: T.ISetCharityDetailsSuccess["payload"]): T.ISetCharityDetailsSuccess => ({
    type: C.SET_CHARITY_DETAILS_SUCCESS,
    payload,
  }),
  failure: (): T.ISetCharityDetailsFailure => ({
    type: C.SET_CHARITY_DETAILS_FAILURE,
  }),
};

export const changeCharityMission = {
  request: (payload: T.IChangeCharityMission["payload"]): T.IChangeCharityMission => ({
    type: C.CHANGE_CHARITY_MISSION,
    payload,
  }),
  success: (
    payload: T.IChangeCharityMissionSuccess["payload"],
  ): T.IChangeCharityMissionSuccess => ({
    type: C.CHANGE_CHARITY_MISSION_SUCCESS,
    payload,
  }),
  failure: (): T.IChangeCharityMissionFailure => ({
    type: C.CHANGE_CHARITY_MISSION_FAILURE,
  }),
};

export const getCharityById = {
  request: (payload: T.IGetCharityById["payload"]): T.IGetCharityById => ({
    type: C.GET_CHARITY_BY_ID,
    payload,
  }),
  success: (payload: T.IGetCharityByIdSuccess["payload"]): T.IGetCharityByIdSuccess => ({
    type: C.GET_CHARITY_BY_ID_SUCCESS,
    payload,
  }),
  failure: (payload?: T.IGetCharityByIdFailure["payload"]): T.IGetCharityByIdFailure => ({
    type: C.GET_CHARITY_BY_ID_FAILURE,
    payload,
  }),
};

export const updateCharityUNGoals = {
  request: (payload: T.IUpdateCharityUNGoals["payload"]): T.IUpdateCharityUNGoals => ({
    type: C.UPDATE_CHARITY_UN_GOALS,
    payload,
  }),
  success: (
    payload: T.IUpdateCharityUNGoalsSuccess["payload"],
  ): T.IUpdateCharityUNGoalsSuccess => ({
    type: C.UPDATE_CHARITY_UN_GOALS_SUCCESS,
    payload,
  }),
  failure: (
    payload?: T.IUpdateCharityUNGoalsFailure["payload"],
  ): T.IUpdateCharityUNGoalsFailure => ({
    type: C.UPDATE_CHARITY_UN_GOALS_FAILURE,
    payload,
  }),
};

export const inviteCharityAdmin = {
  request: (payload: T.IInviteAdmins["payload"]): T.IInviteAdmins => ({
    type: C.INVITE_CHARITY_ADMIN,
    payload,
  }),
  success: (): T.IInviteAdminsSuccess => ({
    type: C.INVITE_CHARITY_ADMIN_SUCCESS,
  }),
  failure: (): T.IInviteAdminsFailure => ({
    type: C.INVITE_CHARITY_ADMIN_FAILURE,
  }),
};
