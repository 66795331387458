import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { GRANT_STATUS_OPTION, VolunteerEventItem } from "types/volunteer";
import { mapVolunteerEventToListEvent } from "utils/mapper";

import * as C from "./volunteer-events-charity.constants";
import * as T from "./volunteer-events-charity.type";

export const volunteerCharityInitial: T.IVolunteerCharityState = {
  data: [],
  inProgress: false,
  isFetched: false,
  isCached: false,
  isEndReached: false,
  page: 0,
  results: 0,
  error: null,
  dto: null,
  customVolunteerEventDto: null,
  filters: {
    grant: GRANT_STATUS_OPTION.NO,
    city: "",
    state: null,
    groupSize: [],
    location: [],
  },
};

const volunteerCharityReducer = (
  state: T.IVolunteerCharityState,
  action: T.VolunteerCharityAction | ILogout,
): T.IVolunteerCharityState => {
  if (!state) {
    return volunteerCharityInitial;
  }

  switch (action.type) {
    case C.FETCH_CHARITY_VOLUNTEER_EVENTS:
      return {
        ...state,
        inProgress: true,
      };
    case C.FETCH_CHARITY_VOLUNTEER_EVENTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        page: action.payload.page,
        results: action.payload.results,
        inProgress: false,
        isFetched: true,
      };
    case C.FETCH_CHARITY_VOLUNTEER_EVENTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case C.SAVE_VOLUNTEER_EVENT_DTO:
      return {
        ...state,
        dto: {
          ...state.dto,
          ...action.payload,
        },
      };
    case C.RESET_VOLUNTEER_EVENT_DTO:
      return {
        ...state,
        dto: null,
      };
    case C.SAVE_CUSTOM_VOLUNTEER_EVENT_DTO:
      return {
        ...state,
        customVolunteerEventDto: {
          ...state.customVolunteerEventDto,
          ...action.payload,
        },
      };
    case C.RESET_CUSTOM_VOLUNTEER_EVENT_DTO:
      return {
        ...state,
        customVolunteerEventDto: null,
      };
    case C.POST_VOLUNTEER_EVENT:
    case C.POST_CUSTOM_VOLUNTEER_EVENT:
      return {
        ...state,
        inProgress: true,
      };
    case C.POST_VOLUNTEER_EVENT_SUCCESS:
    case C.POST_CUSTOM_VOLUNTEER_EVENT_SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: [mapVolunteerEventToListEvent(action.payload), ...state.data],
      };
    case C.POST_VOLUNTEER_EVENT_FAILURE:
    case C.POST_CUSTOM_VOLUNTEER_EVENT_FAILURE:
      return {
        ...state,
        inProgress: false,
      };
    case C.EDIT_VOLUNTEER_EVENT_SUCCESS: {
      const eventId = action.payload.id;
      const updatedEvent = action.payload;
      const updatedData = state.data.map((event: VolunteerEventItem) => {
        if (event.id === eventId) {
          return mapVolunteerEventToListEvent(updatedEvent);
        }
        return event;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case LOGOUT:
      return volunteerCharityInitial;
    default:
      return state;
  }
};

export default volunteerCharityReducer;
