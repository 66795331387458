import { IRegistrationSelectors, IRegistrationState } from "./registration.type";

const registrationSelectors: IRegistrationSelectors = {
  selectIsCached: (state) => state.registration.isCached,
  selectInProgress: (state) => state.registration.inProgress,
  selectError: (state) => state.registration.error,
  selectCompanyDTO: (state) => state.registration.companyDto,
  selectEmployeeDTO: (state) => state.registration.employeeDto,
  selectAdminDTO: (state) => state.registration.adminDto,
};

export default registrationSelectors;
