import PrimaryButton from "components/buttons/primary";
import SecondaryButton from "components/buttons/secondary";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import AuthFormTemplate from "templates/auth-form";
import { contactSupport } from "utils/helper";
import routes from "utils/routes";

import * as S from "./landing.styled";

const LandingPage: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();

  const { companyName } = params;

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const elementSX: React.CSSProperties = {
    width: !isTablet ? 391 : 280,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const gapBig = isTablet ? 40 : 60;

  return (
    <AuthFormTemplate>
      <>
        <S.Separator height={gapBig} />
        <S.LabelGray>Select your role to proceed to login:</S.LabelGray>
        <S.Separator height={gapBig} />
        <PrimaryButton
          label="I'm an employee"
          onClick={() => navigate(`/${companyName}${routes.EMPLOYEE.LOGIN}`)}
          sx={elementSX}
        />
        <S.Separator height={20} />
        <S.LabelFaded width={391} style={elementSX}>
          or
        </S.LabelFaded>
        <S.Separator height={20} />
        <SecondaryButton
          label="I'm an administrator"
          onClick={() => navigate(`/${companyName}${routes.COMPANY.LOGIN}`)}
          sx={elementSX}
        />
        <S.Separator height={12} />
        <S.Paragraph fontSize={12} style={elementSX}>
          Are you having difficulties to proceed? {isTablet && <br />}
          <span>
            <S.LinkText onClick={contactSupport} fontSize={12}>
              Contact support here.
            </S.LinkText>
          </span>
        </S.Paragraph>
      </>
    </AuthFormTemplate>
  );
};

export default LandingPage;
