import styled from "styled-components";
import { RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ColumnDisplay,
  FormWrapper,
  GradientHeading,
  Paragraph,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

type ChipProps = {
  isSelected: boolean;
  padding?: number;
  type: "left" | "right" | "middle";
  isErrorMode?: boolean;
};

const ChipLeft = `
  border-radius: 6px 0px 0px 6px;
`;

const ChipRight = `
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
`;

const ChipMiddle = `
  background: ${({ theme }) => theme.white()};
  border-left: transparent;
  border-right: transparent;
`;

const CHIP_MAPPER: Record<ChipProps["type"], string> = {
  left: ChipLeft,
  middle: ChipMiddle,
  right: ChipRight,
};

export const ChipStyled = styled(RowDisplay)<ChipProps & { isDisabled?: boolean }>`
  padding: ${({ padding }) => padding ?? 20}px;
  flex-shrink: 1;
  flex-wrap: wrap;
  ${({ isDisabled }) => !isDisabled && "cursor: pointer;"}
  background: ${({ theme, isSelected }) =>
    !isSelected ? theme.white() : theme.unitedNationsBlue(0.2)};
  border: 1px solid
    ${({ isSelected, theme, isErrorMode }) =>
      !isErrorMode ? (isSelected ? theme.unitedNationsBlue() : theme.grayX11()) : theme.bordo()};
  ${({ type }) => CHIP_MAPPER[type]}
`;

export const ChipText = styled.div<{ isSelected: boolean; fontSize?: number }>`
  font-style: normal;
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  line-height: 20px;
  color: ${({ theme, isSelected }) => (isSelected ? theme.charcoalGray() : theme.mantee())};

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 10px;
    line-height: 14px;
  }
`;
