import { UserWithCaptain } from "pages/volunteer/attendees/volunteer-event-attendees.type";

import { Charity } from "./charity";
import { IPaginationProps, ListingRespone } from "./common";
import { User } from "./user";

export type ScheduledVolunteerEventStatus = "PENDING" | "ACTIVE" | "ACTIVE/PAST";
export type VolunteerEventStatus = "AVAILABLE" | "UNAVAILABLE" | "PENDING" | "ACTIVE" | "PAST";
export type GRANT_OPTIONS = "Yes" | "No" | "Preferred";

export enum GRANT_STATUS_OPTION {
  YES = "Yes",
  NO = "No",
  PREFERRED = "Preferred",
}

export type VolunteerEvent = {
  id: string;
  name: string;
  description: string;
  contactEmail: string;
  impactMetric: string;
  address: string;
  note: string;
  waiver: boolean;
  status: VolunteerEventStatus;
  eventTime: string;
  volunteersNeeded: string | number;
  grant: GRANT_OPTIONS;
  grantAmount: number | string | null;
  createdAt: string;
  updatedAt: string;
  charity: Charity;
  startDateTime: string;
  endDateTime: string;
  availableSpots: number;
  location?: string[];
  atCapacity?: boolean;
};

export type VolunteerEventItem = VolunteerEvent & {
  id: string;
  name: string;
  status: VolunteerEventStatus;
  createdAt: string;
  updatedAt: string;
  charityName: string;
  charityLogoUrl: string;
  charityLocation: string;
  startDateTime: string;
  endDateTime: string;
  attending: boolean;
};

export type VolunteerEventLocation =
  | "IN_PERSON"
  | "IN_PERSON|COMPANY_OFFICE"
  | "VIRTUAL"
  | "COMPANY_OFFICE"
  | "VIRTUAL|COMPANY_OFFICE";

export type VolunteerEventDto = {
  name: string;
  description: string;
  contactEmail: string;
  impactMetric: string;
  address: string;
  location: VolunteerEventLocation;
  note: string;
  waiver: File | null;
  eventTime: string;
  volunteersNeeded: string | number;
  grant: GRANT_OPTIONS;
  grantAmount: number | null;
};

export type CustomVolunteerEventDto = {
  name: string;
  description: string;
  impactMetric: string;
  address: string;
  note: string;
  date: string;
  volunteersNeeded: string | number;
  charityName: string;
  ein: string;
  startTime: string;
  endTime: string;
  timezone: string;
  availableSpots: string;
};

export type VolunteerEventsFilters = {
  grant: GRANT_OPTIONS | null;
  city: string;
  state: string;
  groupSize: string[];
  location: VolunteerEventLocation[];
};

export type ActivateVolunteerEventParams = {
  date: string;
  startTime: string;
  endTime: string;
  timezone: string;
  availableSpots: number;
};

export type UserEmailDTO = {
  email: string;
};

export type VolunteerEventsFiltersDto = Partial<IPaginationProps & VolunteerEventsFilters>;

export type GetVolunteerEventsResponse = ListingRespone<VolunteerEventItem>;

export type GetScheduledVolunteerEventsResponse = ListingRespone<VolunteerEventItem>;

export type GetVolunteerAtendanceResponse = ListingRespone<UserWithCaptain>;

export type VolunteerTable = "events" | "pending" | "active" | "past";

export type ManagePeopleTableType = "admins" | "employees";
