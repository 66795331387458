import { IEmployeeDashboardSummarySelectors } from "./employee-dashboard-summary.type";

const employeeDashboardSummarySelectors: IEmployeeDashboardSummarySelectors = {
  selectData: (state) => state.employeeDashboardSummary.data,
  selectInProgress: (state) => state.employeeDashboardSummary.inProgress,
  selectIsFetched: (state) => state.employeeDashboardSummary.isFetched,
  selectError: (state) => state.employeeDashboardSummary.error,
};

export default employeeDashboardSummarySelectors;
