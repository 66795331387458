import * as C from "./gift-matching.constants";
import * as T from "./gift-matching.type";

export const toggleGiftMatch = {
  request: (): T.IToggleGiftMatchRequest => ({
    type: C.TOGGLE_GIFT_MATCH,
  }),
  success: (): T.IToggleGiftMatchSuccess => ({
    type: C.TOGGLE_GIFT_MATCH_SUCCESS,
  }),
  failure: (): T.IToggleGiftMatchFailure => ({
    type: C.TOGGLE_GIFT_MATCH_FAILURE,
  }),
};

export const getCompanyGiftMatch = {
  request: (): T.IGetCompanyGiftMatchRequest => ({
    type: C.GET_COMPANY_GIFT_MATCH,
  }),
  success: (payload: T.IGetCompanyGiftMatchSuccess["payload"]): T.IGetCompanyGiftMatchSuccess => ({
    type: C.GET_COMPANY_GIFT_MATCH_SUCCESS,
    payload,
  }),
  failure: (): T.IGetCompanyGiftMatchFailure => ({
    type: C.GET_COMPANY_GIFT_MATCH_FAILURE,
  }),
};

export const setCompanyGiftMatch = {
  request: (payload: T.ISetCompanyGiftMatchRequest["payload"]): T.ISetCompanyGiftMatchRequest => ({
    type: C.SET_COMPANY_GIFT_MATCH,
    payload,
  }),
  success: (payload: T.ISetCompanyGiftMatchSuccess["payload"]): T.ISetCompanyGiftMatchSuccess => ({
    type: C.SET_COMPANY_GIFT_MATCH_SUCCESS,
    payload,
  }),
  failure: (): T.ISetCompanyGiftMatchFailure => ({
    type: C.SET_COMPANY_GIFT_MATCH_FAILURE,
  }),
};

export const getW9FormLink = {
  request: (payload: T.IGetW9FormLinkRequest["payload"]): T.IGetW9FormLinkRequest => ({
    type: C.GET_W9_FORM_LINK,
    payload,
  }),
  success: (payload: T.IGetW9FormLinkSuccess["payload"]): T.IGetW9FormLinkSuccess => ({
    type: C.GET_W9_FORM_LINK_SUCCESS,
    payload,
  }),
  failure: (): T.IGetW9FormLinkFailure => ({
    type: C.GET_W9_FORM_LINK_FAILURE,
  }),
};

export const submitOffPlatformDonation = {
  request: (payload: T.ISubmitOffPlatformDonation["payload"]): T.ISubmitOffPlatformDonation => ({
    type: C.SUBMIT_OFF_PLATFORM_DONATION,
    payload,
  }),
  success: (): T.ISubmitOffPlatformDonationSuccess => ({
    type: C.SUBMIT_OFF_PLATFORM_DONATION_SUCCESS,
  }),
  failure: (): T.IGetCompanyGiftMatchFailure => ({
    type: C.GET_COMPANY_GIFT_MATCH_FAILURE,
  }),
};

export const getTaxDocument = {
  request: (payload: T.IGetTaxDocument["payload"]): T.IGetTaxDocument => ({
    type: C.GET_TAX_DOCUMENT,
    payload,
  }),
  success: (payload: T.IGetTaxDocumentSuccess["payload"]): T.IGetTaxDocumentSuccess => ({
    type: C.GET_TAX_DOCUMENT_SUCCESS,
    payload,
  }),
  failure: (): T.IGetTaxDocumentFailure => ({
    type: C.GET_TAX_DOCUMENT_FAILURE,
  }),
};
