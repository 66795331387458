import { Text } from "@primer/react";
import LogoAndCompany from "components/logo-and-company";
import React from "react";
import { useMediaQuery } from "react-responsive";
import colors from "theme/colors";

import * as S from "./recover-password-validation.styled";

const RecoverPasswordValidationPage: React.FC<{}> = (): JSX.Element => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <div />
      <S.FormWrapper>
        <S.GradientHeading>Recover password</S.GradientHeading>
        <S.Separator height={32} />
        <Text
          sx={{
            textAlign: "center",
            fontSize: 16,
            color: colors.gunmetal(),
            marginLeft: "-8px",
            marginRight: "-8px",
          }}
        >
          An e-mail has been sent. Please follow the link in the
          {!isTablet && <br />}
          e-mail to recover the password.
        </Text>
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin showVersion />
    </S.BubbleBackground>
  );
};

export default RecoverPasswordValidationPage;
