import { Button, Spinner } from "@primer/react";
import React from "react";
import colors from "theme/colors";

import { IButtonProps } from "../button.type";

const GrayButton: React.FC<IButtonProps> = ({
  onClick,
  isDisabled,
  isLoading,
  label,
  sx,
  leadingIcon,
  trailingIcon,
}): JSX.Element => (
  <Button
    onClick={onClick}
    disabled={isDisabled || isLoading}
    variant="outline"
    leadingIcon={leadingIcon}
    trailingIcon={trailingIcon}
    sx={{
      width: 391,
      color: isDisabled ? colors.charcoalGray(0.5) : colors.unitedNationsBlue(),
      background: colors.ghostWhite(),
      borderRadius: 50,
      height: 42,
      opacity: isDisabled || isLoading ? 0.5 : 1,
      ":hover": {
        color: `${!isDisabled ? colors.unitedNationsBlue() : colors.charcoalGray(0.5)} !important`,
        background: `${colors.ghostWhite()} !important`,
      },
      ...sx,
    }}
  >
    {isLoading ? <Spinner size="small" sx={{ marginTop: "4px" }} /> : label}
  </Button>
);

export default GrayButton;
