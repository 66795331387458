import PrimaryButton from "components/buttons/primary";
import SmallButton from "components/buttons/small";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import { updateInterests } from "store/user/user.action";
import userSelectors from "store/user/user.selector";
import { SelectedInterestStyles } from "theme/global-style";
import { MAX_SELECTED_INTERESTS } from "utils/constants";
import { INTERESTS_LIST } from "utils/content";

import * as S from "../dialogs.styled";

const UpdateInterestsDialog: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, "UPDATE_INTERESTS"),
  );

  const user = useSelector(userSelectors.selectData);

  const [tempInterests, setTempInterests] = React.useState(user?.interests || []);
  const [showWarning, setShowWarning] = React.useState(false);

  useEffect(() => {
    setTempInterests(user?.interests || []);
  }, [user?.interests]);

  const closeDialog = (): void => {
    setShowWarning(false);
    dispatch(updateDialogState({ key: "UPDATE_INTERESTS", isOpened: false }));
  };

  const selectInterest = (interest: string): void => {
    if (tempInterests.includes(interest)) {
      if (showWarning && tempInterests.length <= MAX_SELECTED_INTERESTS) {
        setShowWarning(false);
      }
      setTempInterests(tempInterests.filter((i) => i !== interest));
    } else {
      if (tempInterests.length >= MAX_SELECTED_INTERESTS) {
        setShowWarning(true);
        return;
      }
      setTempInterests([...tempInterests, interest]);
    }
  };

  const onConfirm = (): void => {
    dispatch(updateInterests.request({ interests: tempInterests }));
    closeDialog();
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapperWide>
          <S.GradientHeading>Add interests</S.GradientHeading>
          <S.Separator height={20} />
          {showWarning ? (
            <S.DialogLabelWarning>
              Maximum amount of interests that can be selected is {MAX_SELECTED_INTERESTS}
            </S.DialogLabelWarning>
          ) : (
            <S.DialogLabel>Please select up to {MAX_SELECTED_INTERESTS} interests</S.DialogLabel>
          )}
          <S.Separator height={40} />
          <S.InterestContainer>
            {INTERESTS_LIST.map((interest) => (
              <SmallButton
                label={interest}
                onClick={() => selectInterest(interest)}
                sx={tempInterests.includes(interest) ? SelectedInterestStyles : null}
              />
            ))}
          </S.InterestContainer>
          <S.Separator height={40} />
          <PrimaryButton label="Save Interests" onClick={onConfirm} isLoading={false} />
        </S.DialogInnerWrapperWide>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default UpdateInterestsDialog;
