import storageKeys from "utils/storage-keys";

import { advancedAnalyticsInitial } from "./advanced-analytics/advanced-analytics.reducer";
import { authInitial } from "./auth/auth.reducer";
import { charityListInitial } from "./charities/charities.reducer";
import { charitiesSearchInitial } from "./charities-search/charities-search.reducer";
import { charityInitial } from "./charity/charity.reducer";
import { companyInitial } from "./company/company.reducer";
import { companyActiveVolunteerEventsInitial } from "./company-active-volunteer-events/company-active-volunteer-events.reducer";
import { companyDonationsInitial } from "./company-donations/company-donations.reducer";
import { companyPastVolunteerEventsInitial } from "./company-past-volunteer-events/company-past-volunteer-events.reducer";
import { companyPendingVolunteerEventsInitial } from "./company-pending-volunteer-events/company-pending-volunteer-events.reducer";
import { companyVolunteerEventsInitial } from "./company-volunteer-events/company-volunteer-events.reducer";
import { donationsInitial } from "./donations/donations.reducer";
import { employeeDashboardSummaryInitial } from "./employee-dashboard-summary/employee-dashboard-summary.reducer";
import { checkIntoEventInitial } from "./event-check-in/event-check-in.reducer";
import { everyOrgInitial } from "./every-org/every-org.reducer";
import { everyOrgSearchInitial } from "./every-org-search/every-org-search.reducer";
import { finchInitial } from "./finch/finch.reducer";
import { giftMatchInitial } from "./gift-matching/gift-matching.reducer";
import { managePeopleInitial } from "./manage-people/manage-people.reducer";
import { paymentInitial } from "./payment/payment.reducer";
import { registrationInitial } from "./registration/registration.reducer";
import { CacheState, IAppState, StoreKey, StoreSlice } from "./store.state";
import { uiComponentsInitial } from "./ui-components/ui-components.reducer";
import { userInitial } from "./user/user.reducer";
import { userDonationsInitial } from "./user-donations/user-donations.reducer";
import { volunteerAttendeesInitial } from "./volunteer-attendees/volunteer-attendees.reducer";
import { volunteerEventDetailsInitial } from "./volunteer-event-details/volunteer-event-details.reducer";
import { volunteerCharityInitial } from "./volunteer-events-charity/volunteer-events-charity.reducer";
import { volunteerScheduledInitial } from "./volunteer-scheduled/volunteer-scheduled.reducer";

const initialState: IAppState = {
  advancedAnalytics: advancedAnalyticsInitial,
  auth: authInitial,
  registration: registrationInitial,
  company: companyInitial,
  charityList: charityListInitial,
  charity: charityInitial,
  charitiesSearch: charitiesSearchInitial,
  user: userInitial,
  uiComponents: uiComponentsInitial,
  payment: paymentInitial,
  managePeople: managePeopleInitial,
  giftMatch: giftMatchInitial,
  donations: donationsInitial,
  userDonations: userDonationsInitial,
  employeeDashboardSummary: employeeDashboardSummaryInitial,
  companyDonations: companyDonationsInitial,
  everyOrg: everyOrgInitial,
  everyOrgSearch: everyOrgSearchInitial,
  finch: finchInitial,
  volunteerCharity: volunteerCharityInitial,
  volunteerScheduled: volunteerScheduledInitial,
  volunteerAttendees: volunteerAttendeesInitial,
  volunteerEventDetails: volunteerEventDetailsInitial,
  companyVolunteerEvents: companyVolunteerEventsInitial,
  companyActiveVolunteerEvents: companyActiveVolunteerEventsInitial,
  companyPastVolunteerEvents: companyPastVolunteerEventsInitial,
  companyPendingVolunteerEvents: companyPendingVolunteerEventsInitial,
  checkIntoVolunteerEvent: checkIntoEventInitial,
};

export const getCacheState = (): IAppState => {
  const jsonString = localStorage.getItem(storageKeys.ROOT_STATE);

  if (jsonString) {
    const cacheState: CacheState = JSON.parse(jsonString);

    if (cacheState) {
      const filledState = cacheState;
      Object.entries(cacheState).forEach(([key]) => {
        filledState[key] = {
          ...initialState[key],
          ...cacheState[key],
        };
      });
      return { ...initialState, ...filledState };
    }
  }
  return initialState;
};

export const cacheSlice = (key: StoreKey, slice: StoreSlice): void => {
  const jsonString = localStorage.getItem(storageKeys.ROOT_STATE);

  const cacheState: CacheState = jsonString ? JSON.parse(jsonString) : null;

  const updatedState: CacheState = cacheState
    ? {
        ...cacheState,
        [key]: {
          ...initialState[key],
          ...slice,
        },
      }
    : {
        [key]: {
          ...initialState[key],
          ...slice,
        },
      };

  localStorage.setItem(storageKeys.ROOT_STATE, JSON.stringify(updatedState));
};

export const removeSliceFromCache = (key: StoreKey): void => {
  localStorage.removeItem(key);
};
