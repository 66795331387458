import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowLeftIcon } from "@primer/octicons-react";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import {
  resendCode,
  resendVerificationEmail,
  sendCodeEmployee,
  signInCode,
} from "store/auth/auth.action";
import authSelectors from "store/auth/auth.selector";
import registrationSelectors from "store/registration/registration.selector";
import AuthFormTemplate from "templates/auth-form";
import { EMAIL_REG } from "utils/constants";
import routes from "utils/routes";
import * as yup from "yup";

import * as S from "./employee-login.verification";

const schema = yup.object().shape({
  code: yup
    .string()
    .trim()
    .matches(/^[0-9]*$/, "Please enter only digits, without spaces.")
    .min(6, "Must be exactly 6 digits.")
    .max(6, "Must be exactly 6 digits.")
    .required("Code is required."),
});

const EmployeeVerificationPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailRef = React.useRef(null);
  const params = useParams();

  const { companyName } = params;

  const inProgress = useSelector(authSelectors.selectInProgress);

  const employeeDto = useSelector(registrationSelectors.selectEmployeeDTO);

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${S.MOBILE_BREAKPOINT}px)`,
  });

  const elementSX: React.CSSProperties = {
    width: 280,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const elementSXDesktop: React.CSSProperties = {
    width: 391,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const gapBig = isTablet ? 0 : 30;

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { code: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const onSubmit = ({ code }) => {
    dispatch(signInCode.request({ code, email: employeeDto.email }));
  };

  return (
    <AuthFormTemplate>
      <>
        <S.Separator height={gapBig} />
        <S.ClickableWrapper onClick={() => navigate(`/${companyName}${routes.LANDING}`)}>
          <S.RowDisplay gridGap={16}>
            <S.LabelGray>
              <ArrowLeftIcon size={24} />
            </S.LabelGray>
            <S.LabelGray>Employee login</S.LabelGray>
          </S.RowDisplay>
        </S.ClickableWrapper>

        <S.Separator height={30} />
        <S.ColumnDisplay
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ width: "100%" }}
        >
          <Input
            topLabel="Verification code"
            isRequired
            sx={isMobile ? { ...elementSX, minWidth: "100%" } : elementSXDesktop}
            control={control}
            errorMessage={errors?.code?.message}
            onEnter={handleSubmit(onSubmit)}
            name="code"
          />
          <S.Separator height={8} />
          <S.Paragraph
            fontSize={12}
            style={
              isMobile
                ? { ...elementSX, minWidth: "100%", textAlign: "start" }
                : { ...elementSXDesktop, textAlign: "start" }
            }
          >
            <span>Didn’t receive the code?</span>
            <S.ResendCodeText
              onClick={() => dispatch(resendCode.request({ email: employeeDto.email }))}
            >
              {" "}
              Resend code
            </S.ResendCodeText>
          </S.Paragraph>

          <S.Separator height={30} />
          <PrimaryButton
            label="Login"
            sx={isMobile ? { ...elementSX, minWidth: "100%" } : elementSXDesktop}
            isLoading={inProgress}
            onClick={handleSubmit(onSubmit)}
          />
          <S.Separator height={16} />
          {/* <S.ParagraphExtended
            fontSize={12}
            style={{
              ...(isMobile
                ? {
                    ...elementSX,
                    minWidth: "100%",
                  }
                : elementSXDesktop),
            }}
          >
            <span>Don’t have an account yet?</span>
            <span>
              &nbsp;
              <S.LinkText
                fontSize={12}
                onClick={() => navigate(`/${companyName}${routes.EMPLOYEE.REGISTER}`)}
              >
                Create one by clicking here!
              </S.LinkText>
            </span>
          </S.ParagraphExtended> */}
          <S.Separator height={isTablet ? 60 : 0} />
        </S.ColumnDisplay>
      </>
    </AuthFormTemplate>
  );
};

export default EmployeeVerificationPage;
