import * as api from "api/payment";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";

import { createPaymentIntent, setupPaymentMethod } from "./payment.action";
import * as C from "./payment.constants";
import * as T from "./payment.type";

const createPaymentIntentEpic: AppEpic = (action$: Observable<T.ICreatePaymentIntentRequest>) =>
  action$.pipe(
    ofType(C.GET_PAYMENT_INTENT),
    mergeMap((action) =>
      from(api.getPaymentIntent(action.payload)).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(createPaymentIntent.success(response.data));
          }
          return of(createPaymentIntent.failure());
        }),
        catchError((error) => of(createPaymentIntent.failure(), handleError({ action, error }))),
      ),
    ),
  );

const setupPaymentMethodEpic: AppEpic = (action$: Observable<T.ISetupPaymentMethodRequest>) =>
  action$.pipe(
    ofType(C.SETUP_PAYMENT_METHOD),
    mergeMap((action) =>
      from(api.setupPaymentMethod()).pipe(
        mergeMap((response) => {
          if (response.data) {
            return of(setupPaymentMethod.success(response.data));
          }
          return of(setupPaymentMethod.failure());
        }),
        catchError((error) => of(setupPaymentMethod.failure(), handleError({ action, error }))),
      ),
    ),
  );

export default combineEpics(createPaymentIntentEpic, setupPaymentMethodEpic);
