import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import { cacheUser, clearUserCache } from "./user.cache";
import * as C from "./user.constants";
import * as T from "./user.type";

export const userInitial: T.IUserState = {
  data: null,
  persistedData: null,
  inProgress: false,
  isCached: false,
  error: null,
};

const cache = (state: T.IUserState) => cacheUser(userInitial, state);

const userReducer = (state: T.IUserState, action: T.UserAction | ILogout): T.IUserState => {
  if (!state) {
    return userInitial;
  }

  switch (action.type) {
    case C.FETCH_USER:
    case C.VERIFY_EMAIL:
      return {
        ...state,
        inProgress: true,
      };
    case C.FETCH_USER_SUCCESS:
      return cache({
        ...state,
        data: action.payload,
        inProgress: false,
      });
    case C.EDIT_EMPLOYEE_DETAILS:
      return cache({
        ...state,
        persistedData: state.data,
        data: { ...state.data, ...action.payload },
      });
    case C.EDIT_EMPLOYEE_DETAILS_FAILURE:
      return cache({
        ...state,
        persistedData: state.data,
        data: { ...state.data, ...state.persistedData },
        inProgress: false,
      });
    case C.TOGGLE_GIFT_MATCH_NOTIFICATION_SUCCESS:
      return cache({
        ...state,
        data: {
          ...state.data,
          notificationsEnabled: action.payload,
        },
      });
    case C.FETCH_USER_FAILURE:
    case C.VERIFY_EMAIL_FAILURE:
    case C.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        inProgress: false,
      };
    case C.UPDATE_INTERESTS:
      return {
        ...state,
        persistedData: state.data,
        inProgress: true,
      };
    case C.UPDATE_INTERESTS_SUCCESS:
      return cache({
        ...state,
        data: { ...state.data, ...action.payload },
        inProgress: false,
      });
    case C.UPDATE_INTERESTS_FAILURE:
      return cache({
        ...state,
        persistedData: state.data,
        data: { ...state.data, ...state.persistedData },
        inProgress: false,
      });
    case LOGOUT:
      return clearUserCache(userInitial);
    default:
      return state;
  }
};

export default userReducer;
