import React from "react";
import { genRandomString } from "utils/helper";

import * as S from "./navigation-progress-tracker";

interface INavigationProgressTrackerProps {
  items: string[];
  activeItemIndex: number;
}

const NavigationProgressTracker: React.FC<INavigationProgressTrackerProps> = ({
  items,
  activeItemIndex,
}): JSX.Element => (
  <S.ProgressCounterWrapper alignItems="flex-start">
    {(items || []).map((item, i) => (
      <S.ColumnDisplay key={genRandomString()} gridGap={8}>
        <S.ProgressCounter isActive={i <= activeItemIndex} />
        <S.ProgressCounterText isActive={i <= activeItemIndex}>{item}</S.ProgressCounterText>
      </S.ColumnDisplay>
    ))}
  </S.ProgressCounterWrapper>
);

export default NavigationProgressTracker;
