import styled from "styled-components";
import { ColumnDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ColumnDisplay,
  DivLinkText,
  GradientHeading,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.samsungGalaxyTab10.x;

export const EmailText = styled.span`
  color: ${({ theme }) => theme.unitedNationsBlue()};
`;

export const ResendCodeText = styled(EmailText)`
  color: ${({ theme }) => theme.unitedNationsBlue()};
  cursor: pointer;
`;

export const WelcomeFinchText = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${({ theme }) => theme.metallicSilver()};
`;

export const WelcomeFinchParagraph = styled.div<{ fontSize?: number }>`
  font-size: ${({ fontSize = 14 }) => fontSize}px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.15px;
  text-align: center;
  color: ${({ theme }) => theme.gunmetal(0.9)};
`;

export const WelcomeFinchParagraphBold = styled.span`
  font-weight: 700;
`;

export const FormWrapper = styled(ColumnDisplay)<{ overflow?: "hidden" | "scroll" | "visible" }>`
  max-width: calc(100% - 46px);
  min-width: 366px;
  padding: 72px 88px 34px 88px;
  box-sizing: border-box;
  border-radius: 40px;
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 4px 45px rgba(33, 53, 102, 0.1);
  position: relative;
  overflow: ${({ overflow = "hidden" }) => overflow};

  @media all and (max-width: ${breakpoints.samsungGalaxyTab10.x}px) {
    min-width: 320px;
    max-width: calc(100% - 2 * 12px);
    width: 366px;
    padding: 50px 22px 38px 22px;
  }
`;
