import * as api from "api/user";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";

import { fetchUserDashboardSummary } from "./employee-dashboard-summary.action";
import * as C from "./employee-dashboard-summary.constants";
import * as T from "./employee-dashboard-summary.type";

export const fetchEmployeeDashboardSummaryEpic: AppEpic = (
  action$: Observable<T.IFetchEmployeeDashboardSummary>,
) =>
  action$.pipe(
    ofType(C.FETCH_USER_DONATIONS_SUMMARY),
    mergeMap((action) =>
      from(api.fetchEmployeeDashboardSummary()).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchUserDashboardSummary.success(response.data));
          }
          return of(handleError({ action, error: null }), fetchUserDashboardSummary.failure());
        }),
        catchError((error) =>
          of(fetchUserDashboardSummary.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export default combineEpics(fetchEmployeeDashboardSummaryEpic);
