import { LogoIcon } from "icons";
import React from "react";

import * as S from "./progress-bar.style";
import * as T from "./progress-bar.type";

const ProgressBar: React.FC<T.IProgressBar> = ({
  width = 260,
  duration = 2500,
  showLogo,
  text = "Please wait...",
}): JSX.Element => (
  <S.ColumnDisplay gridGap={34}>
    {showLogo && <LogoIcon />}
    <S.ProgressBarWrapper width={width}>
      <S.ProgressBarIndicator width={width} duration={duration} />
    </S.ProgressBarWrapper>
    <S.Paragraph>{text}.</S.Paragraph>
  </S.ColumnDisplay>
);

export default ProgressBar;
