import { Link, Text } from "@primer/react";
import React from "react";
import colors from "theme/colors";
import { normalizeLink } from "utils/helper";

import * as T from "./external-link.type";

const ExternalLink: React.FC<T.IExternalLinkProps> = ({
  href,
  text,
  isDisabled,
  fontSize,
  isRoute = false,
  callback,
}): JSX.Element =>
  !isDisabled ? (
    <Link
      href={!isRoute ? normalizeLink(href) : href}
      onClick={callback}
      target="_blank"
      sx={{ fontSize: fontSize ?? 16, color: colors.unitedNationsBlue(), maxWidth: "100%" }}
    >
      {text}
    </Link>
  ) : (
    <Text
      sx={{ color: colors.black(0.3), fontWeight: "600", fontSize: fontSize ?? 16 }}
      onClick={callback}
    >
      {text}
    </Text>
  );

export default ExternalLink;
