import * as api from "api/volunteer";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { refreshActiveCompanyVolunteerEvents } from "store/company-active-volunteer-events/company-active-volunteer-events.action";
import { refreshPendingCompanyVolunteerEvents } from "store/company-pending-volunteer-events/company-pending-volunteer-events.action";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { showToast, updateDialogState } from "store/ui-components/ui-components.actions";
import { getErrorMessage } from "utils/helper";
import { ACCEPTED, DELETED } from "utils/status-code";

import {
  attendVolunteerEvent,
  fetchVolunteerEvent,
  fetchVolunteerEventAttending,
  requestVolunteerEventIntro,
  unattendVolunteerEvent,
} from "./volunteer-event-details.action";
import * as C from "./volunteer-event-details.constants";
import * as T from "./volunteer-event-details.type";

export const fetchVolunteerEventEpic: AppEpic = (
  action$: Observable<T.IFetchVolunteerEvent>,
  state$,
) =>
  action$.pipe(
    ofType(C.FETCH_VOLUNTEER_EVENT),
    mergeMap((action) =>
      from(
        api.getVolunteerEvent(action.payload.id, state$.value.auth.role, action.payload.isGlobal),
      ).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchVolunteerEvent.success(response.data));
          }
          return of(handleError({ action, error: null }), fetchVolunteerEvent.failure(null));
        }),
        catchError((error) =>
          of(fetchVolunteerEvent.failure(getErrorMessage(error)), handleError({ action, error })),
        ),
      ),
    ),
  );

export const attentVolunteerEventEpic: AppEpic = (action$: Observable<T.IAttendVolunteerEvent>) =>
  action$.pipe(
    ofType(C.ATTEND_VOLUNTEER_EVENT),
    mergeMap((action) =>
      from(api.attendScheduledVolunteerEvent(action.payload.id, action.payload.captain)).pipe(
        mergeMap((response) => {
          if (response === ACCEPTED) {
            return of(
              attendVolunteerEvent.success(action.payload),
              showToast({ message: "Signed up successfully!", type: "success" }),
              refreshActiveCompanyVolunteerEvents.request({
                params: { page: 1 },
                status: "ACTIVE",
              }),
            );
          }
          return of(
            handleError({ action, error: null }),
            attendVolunteerEvent.failure(action.payload),
          );
        }),
        catchError((error) =>
          of(attendVolunteerEvent.failure(action.payload), handleError({ action, error })),
        ),
      ),
    ),
  );

export const unattentVolunteerEventEpic: AppEpic = (
  action$: Observable<T.IUnattendVolunteerEvent>,
) =>
  action$.pipe(
    ofType(C.UNATTEND_VOLUNTEER_EVENT),
    mergeMap((action) =>
      from(api.unattendScheduledVolunteerEvent(action.payload.id)).pipe(
        mergeMap((response) => {
          if (response === DELETED) {
            return of(
              unattendVolunteerEvent.success(action.payload),
              showToast({ message: "Signed out successfully!", type: "success" }),
              refreshActiveCompanyVolunteerEvents.request({
                params: { page: 1 },
                status: "ACTIVE",
              }),
            );
          }
          return of(
            handleError({ action, error: null }),
            unattendVolunteerEvent.failure(action.payload),
          );
        }),
        catchError((error) =>
          of(unattendVolunteerEvent.failure(action.payload), handleError({ action, error })),
        ),
      ),
    ),
  );

export const requestVolunteerEventIntroEpic: AppEpic = (
  action$: Observable<T.IRequestVolunteerEventIntro>,
) =>
  action$.pipe(
    ofType(C.REQUEST_VOLUNTEER_EVENT_INTRO),
    mergeMap((action) =>
      from(api.reguestVolunteerEventIntro(action.payload.id)).pipe(
        mergeMap((response) => {
          if (response === ACCEPTED) {
            return of(
              requestVolunteerEventIntro.success(action.payload),
              updateDialogState({ key: "REQUESTED_INTRO", isOpened: true }),
              refreshPendingCompanyVolunteerEvents.request({
                params: { page: 1 },
                status: "PENDING",
              }),
            );
          }
          return of(handleError({ action, error: null }), requestVolunteerEventIntro.failure());
        }),
        catchError((error) =>
          of(requestVolunteerEventIntro.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export const fetchVolunteerEventAttendingEpic: AppEpic = (
  action$: Observable<T.IFetchVolunteerEventAttending>,
) =>
  action$.pipe(
    ofType(C.FETCH_VOLUNTEER_EVENT_ATTENDING),
    mergeMap((action) =>
      from(api.getScheduledVolunteerEventAttending(action.payload.id)).pipe(
        mergeMap((response) => of(fetchVolunteerEventAttending.success(response.data))),
        catchError((error) =>
          of(fetchVolunteerEventAttending.failure(action.payload), handleError({ action, error })),
        ),
      ),
    ),
  );

export default combineEpics(
  fetchVolunteerEventEpic,
  attentVolunteerEventEpic,
  unattentVolunteerEventEpic,
  requestVolunteerEventIntroEpic,
  fetchVolunteerEventAttendingEpic,
);
