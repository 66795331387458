import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/buttons/primary";
import Input from "components/input";
import { XIcon } from "icons";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "store/store.state";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";
import volunteerEventDetailsSelectors from "store/volunteer-event-details/volunteer-event-details.selector";
import { saveCustomVolunteerEventDto } from "store/volunteer-events-charity/volunteer-events-charity.action";
import volunteerSelectors from "store/volunteer-events-charity/volunteer-events-charity.selector";
import { CustomVolunteerEventDto } from "types/volunteer";
import { CUSTOM_VOLUNTEER_EVENT_SCHEMA_1 } from "utils/validation";

import * as S from "../dialogs.styled";

const CONFITMATION_MODAL_KEY = "CLOSE_POST_CHARITY_EVENT";
const STEP_1_MODAL_KEY = "POST_CUSTOM_VOLUNTEER_EVENT_STEP_1";

const defaultValues = {
  charityName: "",
  ein: "",
  eventName: "",
  description: "",
  note: "",
};

const PostCustomVolunteerEventStep1Dialog: React.FC = () => {
  const dispatch = useDispatch();
  const prevOpenedDialog = useSelector(uiComponentsSelectors.selectPreviouslyOpenedDialog);
  const storedData = useSelector(volunteerSelectors.selectCustomVolunteerEventDto);

  const isOpened = useSelector((state: IAppState) =>
    uiComponentsSelectors.selectDialogState(state, STEP_1_MODAL_KEY),
  );
  const inProgress = useSelector(volunteerEventDetailsSelectors.selectInProgress);

  const persistData =
    (prevOpenedDialog === CONFITMATION_MODAL_KEY ||
      prevOpenedDialog === "POST_CUSTOM_VOLUNTEER_EVENT_STEP_2") &&
    !!storedData;

  const [editData, setEditData] = React.useState<CustomVolunteerEventDto | null>(null);
  const persistedData = storedData
    ? {
        charityName: storedData?.charityName,
        ein: storedData?.ein,
        eventName: storedData?.name,
        description: storedData?.description,
        note: storedData?.note,
      }
    : defaultValues;

  const descriptionRef = React.useRef<HTMLInputElement>(null);
  const noteRef = React.useRef<HTMLInputElement>(null);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    resetField,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: persistData ? persistedData : defaultValues,
    resolver: yupResolver(CUSTOM_VOLUNTEER_EVENT_SCHEMA_1),
    mode: "onTouched",
  });

  React.useEffect(() => {
    if (isOpened && !persistData) {
      const fieldsToReset: Array<"charityName" | "ein" | "eventName" | "description" | "note"> = [
        "charityName",
        "ein",
        "eventName",
        "description",
        "note",
      ];
      reset();
      fieldsToReset.forEach((field) => resetField(field));
      fieldsToReset.forEach((field) => clearErrors(field));
      setEditData(null);
    }
  }, [isOpened]);

  React.useEffect(() => {
    if (!inProgress && editData) {
      setValue("charityName", editData.name || "");
      setValue("ein", editData.name || "");
      setValue("eventName", editData.name || "");
      setValue("description", editData.description || "");
      setValue("note", editData.note || "");
    }
  }, [editData]);

  const closeDialog = (): void => {
    const dto: Partial<CustomVolunteerEventDto> = {
      charityName: getValues("charityName"),
      ein: getValues("ein"),
      name: getValues("eventName"),
      description: getValues("description"),
      note: getValues("note"),
    };
    dispatch(saveCustomVolunteerEventDto(dto));
    dispatch(updateDialogState({ key: STEP_1_MODAL_KEY, isOpened: false }));
    dispatch(updateDialogState({ key: "CLOSE_POST_CHARITY_EVENT", isOpened: true }));
  };

  const onNext = (): void => {
    dispatch(updateDialogState({ key: STEP_1_MODAL_KEY, isOpened: false }));
    if (editData) {
      dispatch(
        updateDialogState({
          key: "POST_CUSTOM_VOLUNTEER_EVENT_STEP_2",
          isOpened: true,
          properties: { editData },
        }),
      );
    } else {
      dispatch(updateDialogState({ key: "POST_CUSTOM_VOLUNTEER_EVENT_STEP_2", isOpened: true }));
    }
  };

  const onSubmit = ({ charityName, ein, eventName, description, note }): void => {
    const dto: Partial<CustomVolunteerEventDto> = {
      charityName,
      ein,
      name: eventName,
      description,
      note,
    };
    dispatch(saveCustomVolunteerEventDto(dto));
    onNext();
  };

  return isOpened ? (
    <S.DialogBackground onClick={closeDialog}>
      <S.DialogScrollWrapper onClick={(e) => e.stopPropagation()}>
        <S.DialogInnerWrapper padding={20}>
          <S.CloseIconContainer>
            <S.CloseIconButton type="button" onClick={closeDialog}>
              <XIcon />
            </S.CloseIconButton>
          </S.CloseIconContainer>
          <S.GradientHeading fontSize={28}>Post Volunteer Event</S.GradientHeading>
          <S.Separator height={40} />
          <S.ColumnDisplay
            gridGap={30}
            style={{ maxWidth: "395px", width: "100%", boxSizing: "border-box" }}
          >
            <Input
              name="charityName"
              topLabel="Name of Nonprofit"
              sx={{ width: "100%", alignSelf: "center" }}
              isRequired
              control={control}
              errorMessage={errors?.charityName?.message}
            />
            <Input
              name="ein"
              topLabel="Nonprofit EIN Number"
              placeholder="23-456789"
              sx={{ width: "100%", alignSelf: "center" }}
              isRequired
              control={control}
              errorMessage={errors?.ein?.message}
            />
            <Input
              name="eventName"
              topLabel="Volunteer Event Name"
              sx={{ width: "100%", alignSelf: "center" }}
              isRequired
              control={control}
              errorMessage={errors?.eventName?.message}
            />
            <Input
              ref={descriptionRef}
              name="description"
              control={control}
              topLabel="Describe what volunteers will do:"
              sx={{
                maxWidth: "100%",
                minHeight: "93px",
                minWidth: "100%",
                boxSizing: "border-box",
              }}
              onEnter={handleSubmit(onSubmit)}
              isRequired
              multiline
              errorMessage={errors?.description?.message}
            />
            <Input
              ref={noteRef}
              name="note"
              control={control}
              topLabel="Event notes"
              placeholder="Enter important information or links for the event like what to wear, where to park, etc."
              sx={{ width: "100%", alignSelf: "center" }}
              onEnter={handleSubmit(onSubmit)}
              multiline
              errorMessage={errors?.note?.message}
              isOptionalVisible={false}
            />
          </S.ColumnDisplay>
          <S.Separator height={50} />
          <PrimaryButton
            label="Next"
            onClick={handleSubmit(onSubmit)}
            sx={{ width: "100%", maxWidth: "395px", alignSelf: "center" }}
          />
        </S.DialogInnerWrapper>
      </S.DialogScrollWrapper>
    </S.DialogBackground>
  ) : null;
};

export default PostCustomVolunteerEventStep1Dialog;
