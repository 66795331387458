import * as C from "./payment.constants";
import * as T from "./payment.type";

export const createPaymentIntent = {
  request: (payload: T.ICreatePaymentIntentRequest["payload"]): T.ICreatePaymentIntentRequest => ({
    type: C.GET_PAYMENT_INTENT,
    payload,
  }),
  success: (payload: T.ICreatePaymentIntentSuccess["payload"]): T.ICreatePaymentIntentSuccess => ({
    type: C.GET_PAYMENT_INTENT_SUCCESS,
    payload,
  }),
  failure: (): T.ICreatePaymentIntentFailure => ({
    type: C.GET_PAYMENT_INTENT_FAILURE,
  }),
};

export const setupPaymentMethod = {
  request: (): T.ISetupPaymentMethodRequest => ({
    type: C.SETUP_PAYMENT_METHOD,
  }),
  success: (payload: T.ISetupPaymentMethodSuccess["payload"]): T.ISetupPaymentMethodSuccess => ({
    type: C.SETUP_PAYMENT_METHOD_SUCCESS,
    payload,
  }),
  failure: (): T.ISetupPaymentMethodFailure => ({
    type: C.SETUP_PAYMENT_METHOD_FAILURE,
  }),
};

export const donationSucceed = (payload: T.IDonationSuccess["payload"]): T.IDonationSuccess => ({
  type: C.DONATION_SUCCESS,
  payload,
});

export const clearClientSecret = (): T.IClearClientSecret => ({
  type: C.CLEAR_CLIENT_SECRET,
});
