import { IUserDonationsSelectors } from "./user-donations.type";

const userDonationsSelectors: IUserDonationsSelectors = {
  selectData: (state) => state.userDonations.data,
  selectInProgress: (state) => state.userDonations.inProgress,
  selectEndReached: (state) => state.userDonations.isEndReached,
  selectIsFetched: (state) => state.userDonations.isFetched,
  selectPage: (state) => state.userDonations.page,
  selectResults: (state) => state.userDonations.results,
  selectError: (state) => state.userDonations.error,
};

export default userDonationsSelectors;
