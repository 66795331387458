import ProgressBar from "components/progress-bar";
import React from "react";
import { useSelector } from "react-redux";
import uiComponentsSelectors from "store/ui-components/ui-components.selectors";

import * as S from "./full-sreen-loader.styled";

const FullScreenLoader: React.FC<{}> = (): JSX.Element => {
  const isVisible = useSelector(uiComponentsSelectors.selectLoaderState);

  return isVisible ? (
    <S.FullScreenLoaderContainer>
      <ProgressBar showLogo text="Data is loading..." />
    </S.FullScreenLoaderContainer>
  ) : null;
};

export default FullScreenLoader;
