import * as C from "./finch.constants";
import * as T from "./finch.type";

export const initializeFinch = {
  request: (payload: T.IInitializeFinch["payload"]): T.IInitializeFinch => ({
    type: C.INITIALIZE_FINCH,
    payload,
  }),
  success: (): T.IInitializeFinchSuccess => ({
    type: C.INITIALIZE_FINCH_SUCCESS,
  }),
  failure: (): T.IInitializeFinchFailure => ({
    type: C.INITIALIZE_FINCH_FAILURE,
  }),
};
