import * as C from "./company-pending-volunteer-events.constants";
import * as T from "./company-pending-volunteer-events.type";

export const fetchPendingCompanyVolunteerEvents = {
  request: (
    payload: T.IFetchPendingVolunteerEvents["payload"],
  ): T.IFetchPendingVolunteerEvents => ({
    type: C.FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS,
    payload,
  }),
  success: (
    payload: T.IFetchPendingVolunteerEventsSuccess["payload"],
  ): T.IFetchPendingVolunteerEventsSuccess => ({
    type: C.FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchPendingVolunteerEventsFailure => ({
    type: C.FETCH_PENDING_COMPANY_VOLUNTEER_EVENTS_FAILURE,
  }),
};

export const refreshPendingCompanyVolunteerEvents = {
  request: (
    payload: T.IRefreshPendingVolunteerEvents["payload"],
  ): T.IRefreshPendingVolunteerEvents => ({
    type: C.REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS,
    payload,
  }),
  success: (
    payload: T.IRefreshPendingVolunteerEventsSuccess["payload"],
  ): T.IRefreshPendingVolunteerEventsSuccess => ({
    type: C.REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS_SUCCESS,
    payload,
  }),
  failure: (): T.IRefreshPendingVolunteerEventsFailure => ({
    type: C.REFRESH_PENDING_COMPANY_VOLUNTEER_EVENTS_FAILURE,
  }),
};

export const activatePendingCompanyVolunteerEvent = {
  request: (
    payload: T.IActivatePendingVolunteerEvent["payload"],
  ): T.IActivatePendingVolunteerEvent => ({
    type: C.ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT,
    payload,
  }),
  success: (
    payload: T.IActivatePendingVolunteerEventSuccess["payload"],
  ): T.IActivatePendingVolunteerEventSuccess => ({
    type: C.ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.IActivatePendingVolunteerEventFailure["payload"],
  ): T.IActivatePendingVolunteerEventFailure => ({
    type: C.ACTIVATE_PENDING_COMPANY_VOLUNTEER_EVENT_FAILURE,
    payload,
  }),
};

export const cancelPendingCompanyVolunteerEvent = {
  request: (
    payload: T.ICancelPendingVolunteerEvent["payload"],
  ): T.ICancelPendingVolunteerEvent => ({
    type: C.CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT,
    payload,
  }),
  success: (
    payload: T.ICancelPendingVolunteerEventSuccess["payload"],
  ): T.ICancelPendingVolunteerEventSuccess => ({
    type: C.CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT_SUCCESS,
    payload,
  }),
  failure: (
    payload: T.ICancelPendingVolunteerEventFailure["payload"],
  ): T.ICancelPendingVolunteerEventFailure => ({
    type: C.CANCEL_PENDING_COMPANY_VOLUNTEER_EVENT_FAILURE,
    payload,
  }),
};
