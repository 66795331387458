import { ServerResponse } from "types/common";
import {
  AdvancedAnalyticsRequest,
  AnalyticDashboardSummary,
  CharitiesSupported,
  Company,
  CompanyDTO,
  CompanyExistsDTO,
  CompanyInfoRequestDTO,
  CompanyInfoResponseDTO,
  ConfirmFinchRegistrationProps,
  DashboardSummary,
  Donations,
  EmployeeInterest,
  IGetUsersProps,
  InviteUsersDTO,
} from "types/company";
import { EmployeeDTO } from "types/employee";
import {
  CompanyDonations,
  CreateUserDTO,
  GetUsersResponseDto,
  GetUsersStatisticsDto,
  User,
} from "types/user";
import { genUrlParams, uriEncode } from "utils/helper";

import client from "./client";

export const registerCompany = async (body: CompanyDTO): Promise<ServerResponse<Company>> =>
  client.post("/company/initiateRegistration", body);

export const registerEmployee = async (
  companyName: string,
  body: EmployeeDTO,
): Promise<ServerResponse<boolean>> =>
  client.post(`/company/${companyName}/employee/initiateRegistration`, body);

export const confirmRegistration = async (
  companyName: string,
  body: CreateUserDTO,
): Promise<ServerResponse<User>> =>
  client.post(`/company/${companyName}/confirmRegistration`, body);

export const checkCompanyInfo = async (
  body: CompanyInfoRequestDTO,
): Promise<ServerResponse<CompanyInfoResponseDTO>> => client.post("/company/info/check", body);

export const checkCompanyExists = async (name: string): Promise<ServerResponse<CompanyExistsDTO>> =>
  (await client.get(genUrlParams("/company/info/exists", { name: uriEncode(name) }))).data;

export const getCompanyById = async (id: string): Promise<ServerResponse<Company>> =>
  (await client.get(`/company/${id}`)).data;

export const getUserStatistics = async (): Promise<ServerResponse<GetUsersStatisticsDto>> =>
  (await client.get("/company/users/statistics")).data;

export const getUsers = async (
  props?: IGetUsersProps,
): Promise<ServerResponse<GetUsersResponseDto>> =>
  (await client.get(props ? genUrlParams("/company/users", props) : "/company/users")).data;

export const removeUser = async (
  companyName: string,
  userId: string,
): Promise<ServerResponse<boolean>> =>
  (await client.delete(`/company/${uriEncode(companyName)}/employee/${userId}`)).data;

export const updateUserRole = async (
  companyName: string,
  userId: string,
  role: string,
): Promise<ServerResponse<boolean>> =>
  (await client.patch(`/company/${uriEncode(companyName)}/employee/${userId}`, { role })).data;

export const inviteUser = async (body: InviteUsersDTO): Promise<ServerResponse<boolean>> =>
  (await client.post("/company/users/invite", body)).data;

export const getCompanyDashboard = async (
  year: number,
): Promise<ServerResponse<DashboardSummary>> =>
  (await client.get(genUrlParams("/company/dashboard", { year }))).data;

export const getCompanyAnalyticsImpact = async (
  year: number,
): Promise<ServerResponse<AnalyticDashboardSummary>> =>
  (await client.get(genUrlParams("/analytics/company/impact", { year }))).data;

export const getEmployeeInterests = async (): Promise<ServerResponse<EmployeeInterest[]>> =>
  (await client.get("/analytics/company/interests")).data;

export const getCharitiesSupported = async (
  year: number,
): Promise<ServerResponse<CharitiesSupported>> =>
  (await client.get(genUrlParams("/analytics/company/charitiesSupported", { year }))).data;

export const getDonations = async (year: number): Promise<ServerResponse<Donations>> =>
  (await client.get(genUrlParams("/analytics/company/donations", { year }))).data;

export const getGiftMatchingAnalytics = async (year: number): Promise<any> =>
  client.get(genUrlParams("/analytics/company/giftMatching", { year }));

export const getVolunteeringAnalytics = async (year: number): Promise<any> =>
  client.get(genUrlParams("/analytics/company/volunteering", { year }));

export const getAdvancedAnalytics = async ({
  group,
  analyticsType,
  props,
}: AdvancedAnalyticsRequest): Promise<any> =>
  client.get(genUrlParams(`/analytics/company/${group}/employees/${analyticsType}`, props));

export const getAllDonations = async (
  page: number,
): Promise<ServerResponse<{ donations: CompanyDonations[]; results: number }>> =>
  (await client.get(genUrlParams("/company/dashboard/allDonations", { page }))).data;

export const upateCompanyLogo = async (body: FormData): Promise<ServerResponse<string>> =>
  (await client.post("/company/logo", body)).data;

export const deleteCompanyLogo = async (): Promise<number> =>
  (await client.delete("/company/logo")).status;

export const confirmFinchRegistration = async (
  props: ConfirmFinchRegistrationProps,
): Promise<ServerResponse<User>> =>
  (
    await client.post(
      genUrlParams(`/company/${props.companyName}/confirmFinchRegistration`, {
        token: props.token,
        interests: props.interests,
      }),
    )
  ).data;
