import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import { cacheGiftMatch, cleariftMatchCache } from "./gift-matching.cache";
import * as C from "./gift-matching.constants";
import * as T from "./gift-matching.type";

export const giftMatchInitial: T.IGiftMatchState = {
  inProgress: false,
  isCached: false,
  isFetched: false,
  error: null,
  data: null,
};

const cache = (state: T.IGiftMatchState) => cacheGiftMatch(giftMatchInitial, state);

const giftMatchrReducer = (
  state: T.IGiftMatchState,
  action: T.GiftMatchingAction | ILogout,
): T.IGiftMatchState => {
  if (!state) {
    return giftMatchInitial;
  }

  switch (action.type) {
    case C.GET_COMPANY_GIFT_MATCH:
    case C.SET_COMPANY_GIFT_MATCH:
    case C.SUBMIT_OFF_PLATFORM_DONATION:
      return {
        ...state,
        inProgress: true,
      };
    case C.SUBMIT_OFF_PLATFORM_DONATION_FAILURE:
    case C.SUBMIT_OFF_PLATFORM_DONATION_SUCCESS:
      return {
        ...state,
        inProgress: false,
      };
    case C.GET_COMPANY_GIFT_MATCH_SUCCESS:
      return cache({
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isFetched: true,
        inProgress: false,
      });
    case C.SET_COMPANY_GIFT_MATCH_SUCCESS:
      return cache({
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      });
    case C.GET_COMPANY_GIFT_MATCH_FAILURE:
      return {
        ...state,
        isFetched: true,
        inProgress: false,
      };
    case C.TOGGLE_GIFT_MATCH:
      return cache({
        ...state,
        inProgress: true,
        data: {
          ...state.data,
          active: !state.data.active,
        },
      });
    case C.TOGGLE_GIFT_MATCH_SUCCESS:
      return {
        ...state,
        inProgress: false,
      };
    case C.TOGGLE_GIFT_MATCH_FAILURE:
      return cache({
        ...state,
        inProgress: false,
        data: {
          ...state.data,
          active: !state.data.active,
        },
      });
    case LOGOUT:
      return cleariftMatchCache(giftMatchInitial);
    default:
      return state;
  }
};

export default giftMatchrReducer;
