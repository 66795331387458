import * as api from "api/volunteer";
import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { handleError } from "store/error-handler/error-handler.action";
import { AppEpic } from "store/store.state";
import { showToast, updateDialogState } from "store/ui-components/ui-components.actions";
import { ACCEPTED } from "utils/status-code";

import {
  fetchVolunteerEventAttendance,
  fetchVolunteerEventAttendanceInfo,
  sendEmailMessageToAttendees,
} from "./volunteer-attendees.action";
import * as C from "./volunteer-attendees.constants";
import * as T from "./volunteer-attendees.type";

export const fetchVolunteerEventAttendanceEpic: AppEpic = (
  action$: Observable<T.IFetchVolunteerEventAttendance>,
) =>
  action$.pipe(
    ofType(C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE),
    mergeMap((action) =>
      from(api.getScheduledVolunteerAttendance(action.payload.id, action.payload.page ?? 1)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchVolunteerEventAttendance.success(response.data));
          }
          return of(handleError({ action, error: null }), fetchVolunteerEventAttendance.failure());
        }),
        catchError((error) =>
          of(fetchVolunteerEventAttendance.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export const fetchVolunteerEventAttendanceInfoEpic: AppEpic = (
  action$: Observable<T.IFetchVolunteerEventAttendanceInfo>,
  state$,
) =>
  action$.pipe(
    ofType(C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO),
    mergeMap((action) =>
      from(api.getVolunteerEvent(action.payload.id, state$.value.auth.role)).pipe(
        mergeMap((response) => {
          if (response?.data) {
            return of(fetchVolunteerEventAttendanceInfo.success(response.data));
          }
          return of(
            handleError({ action, error: null }),
            fetchVolunteerEventAttendanceInfo.failure(),
          );
        }),
        catchError((error) =>
          of(fetchVolunteerEventAttendanceInfo.failure(), handleError({ action, error })),
        ),
      ),
    ),
  );

export const sendEmailMessageToAttendeesEpic: AppEpic = (
  action$: Observable<T.IMessageAttendees>,
) =>
  action$.pipe(
    ofType(C.MESSAGE_ATTENDEES),
    mergeMap((action) =>
      from(api.sendEmailMessageToAttendees(action.payload.id, action.payload.text)).pipe(
        mergeMap((response) => {
          if (response === ACCEPTED) {
            return of(
              sendEmailMessageToAttendees.success(action.payload),
              updateDialogState({ key: "MESSAGE_EMPLOYEES", isOpened: false }),
              showToast({ message: "Message sent!", type: "success" }),
            );
          }
          return of(
            handleError({ action, error: null }),
            updateDialogState({ key: "MESSAGE_EMPLOYEES", isOpened: false }),
            showToast({ message: "Failed to send message!", type: "failure" }),
            sendEmailMessageToAttendees.failure(action.payload),
          );
        }),
        catchError((error) =>
          of(sendEmailMessageToAttendees.failure(action.payload), handleError({ action, error })),
        ),
      ),
    ),
  );

export default combineEpics(
  fetchVolunteerEventAttendanceEpic,
  fetchVolunteerEventAttendanceInfoEpic,
  sendEmailMessageToAttendeesEpic,
);
