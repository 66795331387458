import * as C from "./volunteer-attendees.constants";
import * as T from "./volunteer-attendees.type";

export const fetchVolunteerEventAttendance = {
  request: (
    payload: T.IFetchVolunteerEventAttendance["payload"],
  ): T.IFetchVolunteerEventAttendance => ({
    type: C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE,
    payload,
  }),
  success: (
    payload: T.IFetchVolunteerEventAttendanceSuccess["payload"],
  ): T.IFetchVolunteerEventAttendanceSuccess => ({
    type: C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchVolunteerEventAttendanceFailure => ({
    type: C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_FAILURE,
  }),
};

export const fetchVolunteerEventAttendanceInfo = {
  request: (
    payload: T.IFetchVolunteerEventAttendanceInfo["payload"],
  ): T.IFetchVolunteerEventAttendanceInfo => ({
    type: C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO,
    payload,
  }),
  success: (
    payload: T.IFetchVolunteerEventAttendanceInfoSuccess["payload"],
  ): T.IFetchVolunteerEventAttendanceInfoSuccess => ({
    type: C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO_SUCCESS,
    payload,
  }),
  failure: (): T.IFetchVolunteerEventAttendanceInfoFailure => ({
    type: C.FETCH_VOLUNTEER_SCHEDULED_EVENT_ATTENDANCE_INFO_FAILURE,
  }),
};

export const sendEmailMessageToAttendees = {
  request: (payload: T.IMessageAttendees["payload"]): T.IMessageAttendees => ({
    type: C.MESSAGE_ATTENDEES,
    payload,
  }),
  success: (payload: T.IMessageAttendeesSuccess["payload"]): T.IMessageAttendeesSuccess => ({
    type: C.MESSAGE_ATTENDEES_SUCCESS,
    payload,
  }),
  failure: (payload: T.IMessageAttendeesFailure["payload"]): T.IMessageAttendeesFailure => ({
    type: C.MESSAGE_ATTENDEES_FAILURE,
    payload,
  }),
};
