import SecondaryButton from "components/buttons/secondary";
import FixedHeader from "components/fixed-header";
import LogoAndCompany from "components/logo-and-company";
import { ArrowDownGradientIcon } from "icons";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import routes from "utils/routes";

import * as S from "../donation-success/donation-success.styled";

const DonationMatchFailed: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();
  const { companyName } = useParams();

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  return (
    <S.BubbleBackground justifyContent="space-between" gridGap={16}>
      <FixedHeader name={companyName} />
      <S.FormWrapper>
        <S.DonationsCompleteText>🛑 Gift Match Error</S.DonationsCompleteText>

        <S.Separator height={20} />
        <ArrowDownGradientIcon />
        <S.Separator height={40} />

        <S.GradientHeading>
          Oops! It looks like your donation doesn’t fit your company’s gift matching criteria
        </S.GradientHeading>
        <S.Separator height={40} />
        <S.RowDisplay gridGap={24}>
          <SecondaryButton
            label="Go to My Profile"
            onClick={() => navigate(routes.EMPLOYEE.DASHBOARD)}
            sx={{ width: isTablet ? "100px" : "184px" }}
          />
          <SecondaryButton
            label="Go to Homepage"
            onClick={() => navigate(routes.HOME)}
            sx={{ width: isTablet ? "100px" : "184px" }}
          />
        </S.RowDisplay>
        <S.Separator height={40} />
        <S.DonationsCompleteParagraph fontSize={12}>
          Contact your company admin if you have any questions
        </S.DonationsCompleteParagraph>

        <S.Separator height={20} />
      </S.FormWrapper>
      <LogoAndCompany companyName={null} showMargin />
    </S.BubbleBackground>
  );
};

export default DonationMatchFailed;
