import { ServerResponse } from "types/common";
import * as T from "types/gift-match";
import { genUrlParams, uriEncode } from "utils/helper";

import client from "./client";

export const toggleGiftMatch = async (
  companyName: string,
  active: boolean,
): Promise<ServerResponse<T.ToggleGiftMatchResponsetDto>> =>
  client.patch(`/giftMatch/${uriEncode(companyName)}/toggle`, { active });

export const getCompanyGiftMatch = async (
  companyName: string,
): Promise<ServerResponse<ServerResponse<T.CompanyGiftMatch>>> =>
  client.get(`/giftMatch/${uriEncode(companyName)}`);

export const setCompanyGiftMatch = async (
  companyName: string,
  body: T.CompanyGiftMatch,
): Promise<number> => (await client.put(`/giftMatch/${uriEncode(companyName)}`, body)).status;

export const submitOffPlatformDonation = async (body: FormData): Promise<number> =>
  (await client.post("/user/donations/offPlatform", body)).status;

export const redirectToW9 = async (data: T.W9Dto): Promise<ServerResponse<string>> =>
  (
    await client.get(
      data?.ein
        ? genUrlParams("/charity/document", { ein: data?.ein })
        : `/charity/${data?.charityId}/w9`,
    )
  ).data;

export const redirectToReceipt = async (id: string): Promise<ServerResponse<string>> =>
  (await client.get(`/user/donations/offPlatform/${id}/receipt`)).data;
