import background from "assets/images/circle-background.png";
import styled, { createGlobalStyle } from "styled-components";
import breakpoints from "utils/breakpoints";
import { isString } from "utils/helper";

import colors from "./colors";

const TABLET_BREAKPOINT = breakpoints.iPadMini.x;

export const SelectedInterestStyles = {
  color: colors.ghostWhite(),
  background: "linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%)",
  height: 42,
  ":hover": {
    color: colors.ghostWhite(),
    background: "linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%)",
  },
};

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    min-width: 320px;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  @media all and (max-width: 320px) {
    body {
      overflow-x: scroll;
    }
  }

  table {
    box-sizing: border-box;
    overflow: hidden;
    border-spacing: 0;
    width: 100%;

    @media all and (max-width: ${breakpoints.iPadPro.x}px) {
      max-width: 100%;
      box-sizing: border-box;
      
    }
  }

  table th:first-child {
    border-left: 1px solid ${colors.gunmetal(0.1)};
  }

  table th:last-child {
    border-right: 1px solid ${colors.gunmetal(0.1)};
  }

  table td:first-child {
    border-left: 1px solid ${colors.gunmetal(0.1)};
  }

  table td:last-child {
    border-right: 1px solid ${colors.gunmetal(0.1)};
  }

  th {
    background: rgba(97, 133, 226, 1);
    text-align: start;
    color: ${colors.white()};
    padding: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid transparent;

    @media all and (max-width: ${breakpoints.iPadPro.x}px) {
      font-size: 14px;
    }
  }

  td {
    width: 305px;
    text-align: start;
    padding: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #24292f;
    border: 1px solid ${colors.gunmetal(0.1)};
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    background: ${colors.white()};

    @media all and (max-width: ${breakpoints.iPadPro.x}px) {
      font-size: 14px;
    }
  }

  * {
    flex-shrink: 0;
  }
`;

interface IAlign {
  alignItems?: "flex-start" | "flex-end" | "center" | "stretch";
}

interface IJustify {
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around";
}

interface IGridGap {
  gridGap?: number;
}

interface IFlexWrap {
  flexWrap?: boolean;
}

interface ISize {
  width?: number | string;
  height?: number | string;
}

export const RowDisplay = styled.div<IAlign & IJustify & IGridGap & IFlexWrap & ISize>`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignItems = "center" }) => alignItems};
  justify-content: ${({ justifyContent = "center" }) => justifyContent};
  gap: ${({ gridGap = 0 }) => gridGap}px;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? "wrap" : "nowrap")};
  ${({ height }) => height && `height: ${isString(height) ? height : `${height}px`};`}
  ${({ width }) => width && `width: ${isString(width) ? width : `${width}px`};`}
`;

export const ColumnDisplay = styled.div<IAlign & IJustify & IGridGap & ISize>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems = "center" }) => alignItems};
  justify-content: ${({ justifyContent = "center" }) => justifyContent};
  gap: ${({ gridGap = 0 }) => gridGap}px;
  ${({ height }) => height && `height: ${isString(height) ? height : `${height}px`};`}
  ${({ width }) => width && `width: ${isString(width) ? width : `${width}px`};`}
`;

export const Separator = styled.div<
  { width: number; height?: number } | { width?: number; height: number }
>`
  ${({ width }) => width && `width: ${width}px;`}
  ${({ height }) => height && `margin-top: ${height}px;`}
`;

export const BubbleBackground = styled(ColumnDisplay)`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  background-size: cover;
  background-image: url(${background});
`;

export const FormWrapper = styled(ColumnDisplay)<{ overflow?: "hidden" | "scroll" | "visible" }>`
  width: 573px;
  max-width: calc(100% - 46px);
  min-width: 366px;
  padding: 72px 88px 34px 88px;
  box-sizing: border-box;
  border-radius: 40px;
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 4px 45px rgba(33, 53, 102, 0.1);
  position: relative;
  overflow: ${({ overflow = "hidden" }) => overflow};

  @media all and (max-width: ${breakpoints.samsungGalaxyTab10.x}px) {
    min-width: 320px;
    max-width: calc(100% - 2 * 12px);
    width: 366px;
    padding: 50px 22px 38px 22px;
  }
`;

export const GradientHeading = styled.span<{ fontSize?: number }>`
  background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-style: normal;
  font-weight: 800;
  font-size: ${({ fontSize }) => fontSize ?? 32}px;
  text-align: center;
  margin: -8px;

  @media all and (max-width: ${breakpoints.samsungGalaxyTab10.x}px) {
    font-size: ${({ fontSize }) => fontSize - 2 ?? 38}px;
    line-height: 40px;
  }
`;

export const ClickableWrapper = styled.div`
  cursor: pointer;
`;

export const Table = {
  Wrapper: styled.table``,
  Body: styled.tbody``,
  Row: styled.tr``,
  Heading: styled.th<{ hideRadius?: boolean; showBottomRadius?: boolean }>`
    text-transform: capitalize;
  `,
  Data: styled.td<{
    hideBorder?: boolean;
    isFirstElem?: boolean;
    isLastElem?: boolean;
    showTopBorder?: boolean;
    columnSelected?: boolean;
    isCentered?: boolean;
  }>`
    ${({ hideBorder }) => hideBorder && "border-radius: 0px !important;"}
    ${({ isCentered }) => isCentered && "text-align: center !important;"}
    ${({ showTopBorder, theme }) =>
      showTopBorder && `border-top: 1px solid ${theme.gunmetal(0.1)};`}
    ${({ columnSelected, isLastElem, theme }) =>
      columnSelected &&
      `
      border-left-color: ${theme.unitedNationsBlue()} !important;
      border-right-color: ${theme.unitedNationsBlue()} !important;
     ${isLastElem && `border-bottom-color: ${theme.unitedNationsBlue()} !important;`}
    `}
  `,
};

export const Paragraph = styled.p<{
  fontSize?: number;
  fontWeight?: "400" | "500" | "600" | "700" | "800";
  lineHeight?: number;
  textAlign?: "center" | "start" | "end";
  color?: string;
}>`
  margin: 0;
  padding: 0;
  font-size: ${({ fontSize = 14 }) => fontSize}px;
  line-height: ${({ lineHeight = 16 }) => lineHeight}px;
  font-weight: ${({ fontWeight = "400" }) => fontWeight};
  text-align: ${({ textAlign = "center" }) => textAlign};
  color: ${({ color = colors.charcoalGray() }) => color};
  word-break: break-word;
`;

export const LinkWrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

export const LinkText = styled.a<{ isDisabled?: boolean; fontSize?: number }>`
  font-size: ${({ fontSize = 14 }) => fontSize}px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ isDisabled }) =>
    isDisabled ? colors.charcoalGray(0.5) : colors.unitedNationsBlue()};
  ${({ isDisabled }) => !isDisabled && "cursor: pointer;"}

  &:hover {
    ${({ isDisabled }) => !isDisabled && "text-decoration: underline;"}
  }
`;

export const DivLinkText = styled(RowDisplay)<{ isDisabled?: boolean; fontSize?: number }>`
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ isDisabled }) =>
    isDisabled ? colors.charcoalGray(0.5) : colors.unitedNationsBlue()};
  ${({ isDisabled }) => !isDisabled && "cursor: pointer; text-decoration: underline;"}

  &:hover {
    ${({ isDisabled }) => !isDisabled && "text-decoration: underline;"}
  }
`;

export const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
  width: 100%;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const PaginationWrapper = styled(RowDisplay)<{ hideBorder?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.white()};
  height: 56px;
  border: 1px solid ${({ theme, hideBorder }) => theme.gunmetal(hideBorder ? 0 : 0.1)};
  border-top: none;
  border-radius: 0px 0px 12px 12px;
  max-width: 100%;

  a,
  span {
    display: unset !important;
    padding: 8px 16px;
  }
`;

export const Tag = styled.div<{ isClickable?: boolean }>`
  background: ${({ theme }) => theme.unitedNationsBlue(0.2)};
  border-radius: 38px;
  padding: 5px 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.charcoalGray()};
  ${({ isClickable }) => isClickable && "cursor: pointer;"}
`;

export const TranslatedElement = styled.div<{ translateY: number }>`
  transform: translateY(${({ translateY }) => translateY}px);
  display: flex;
`;

export const TabSwitch = styled.div<{ isFirst?: boolean; isLast?: boolean; isSelected?: boolean }>`
  background: ${({ isSelected, theme }) => (isSelected ? "rgba(97, 133, 226, 1)" : theme.white())};
  color: ${({ isSelected, theme }) => (isSelected ? "rgba(97, 133, 226, 1)" : theme.white())};
  border: 1px solid
    ${({ isSelected }) => (isSelected ? "rgba(97, 133, 226, 1)" : "rgba(224, 224, 224, 1)")};
  box-sizing: border-box;
  ${({ isFirst }) => isFirst && "border-top-left-radius: 6px; border-bottom-left-radius: 6px;"}
  ${({ isLast }) => isLast && "border-top-right-radius: 6px; border-bottom-right-radius: 6px;"};
  padding: 8px 32px 8px 32px;
  cursor: pointer;
  text-align: center;
`;

export const TabSwitchText = styled.div<{ isSelected?: boolean }>`
  color: ${({ isSelected, theme }) => (isSelected ? theme.white() : theme.charcoalGray())};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

export const LineSeparator = styled.div<{ color?: string; opacity?: number }>`
  width: 100%;
  height: 1px;
  background: ${({ theme, color }) => color || theme.separatorGray()};
  opacity: ${({ opacity }) => opacity || 1};

  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SearchAndFilterContainer = styled(RowDisplay)`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 16px;
  border-radius: 12px 12px 0px 0px;
  justify-content: flex-start;
  background ${({ theme }) => theme.white()};
`;

export const SearchAndFilterInnerContainer = styled(RowDisplay)`
  max-width: 100%;
  width: 100%;
`;

export const BackIndicator = styled(RowDisplay)<{ color?: string; alignSelf?: string }>`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  color: ${({ color }) => color || "rgba(80, 135, 231, 1)"};
  cursor: pointer;
  grid-gap: 16px;
  align-self: ${({ alignSelf }) => alignSelf || "start"};
  margin-top: -48px;
  margin-bottom: 24px;
`;

export const NoDataText = styled(ColumnDisplay)`
  color: ${({ theme }) => theme.gunmetal(0.5)};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
`;

export const NoDataForm = styled(ColumnDisplay)<{ isDisabled?: boolean }>`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.white()};
  ${({ isDisabled }) => isDisabled && "pointer-events: none;"};
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);
  border-radius: 0px 0px 12px 12px;
  padding: 40px;
  box-sizing: border-box;
`;

export const AnalyticPaddingContainer = styled.div`
  margin: 20px 30px 20px 50px;
  display: flex;
  gap: 20px;
  justify-content: flex-start;

  @media all and (max-width: ${breakpoints.desktopM.x}px) {
    flex-wrap: wrap;
  }

  @media all and (max-width: ${breakpoints.iPadMini.x}px) {
    margin: 10px 20px 10px 20px;
    gap: 20px;
  }
`;

export const TagText = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const TagContainer = styled(RowDisplay)<{ isSelected?: boolean }>`
  height: auto;
  min-height: 51px;
  padding: 12px 32px 12px 32px;
  box-sizing: border-box;
  border-radius: 20px;
  background: ${({ theme, isSelected }) =>
    isSelected ? "linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%)" : theme.white()};
  cursor: pointer;
  box-shadow: 0px 8px 24px 0px rgba(66, 74, 83, 0.12);
  text-align: center;

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
    ${TagText} {
      background: ${theme.white()};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  `}

  &:hover {
    background: linear-gradient(180deg, #59aef9 23.33%, #4f7fe3 77.78%);
  }

  &:hover ${TagText} {
    background: ${({ theme }) => theme.white()};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    min-height: 60px;
    box-shadow: 0px 8px 12px 0px rgba(66, 74, 83, 0.12);
  }
`;
