import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";

import * as C from "./event-check-in.constants";
import * as T from "./event-check-in.type";

const defaultDataState = {
  inProgress: false,
  isFetched: false,
};

export const checkIntoEventInitial = {
  inProgress: false,
  isFetched: false,
};

const checkIntoVolunteerEventReducer = (
  state: T.ICheckIntoEventState,
  action: T.CheckIntoVolunteerEventAction | ILogout,
): T.ICheckIntoEventState => {
  if (!state) {
    return checkIntoEventInitial;
  }

  switch (action.type) {
    case C.CHECK_INTO_VOLUNTEER_SCHEDULED_EVENT:
      return {
        ...state,
        inProgress: true,
      };
    case C.CHECK_INTO_VOLUNTEER_SCHEDULED_EVENT_SUCCESS:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case C.CHECK_INTO_VOLUNTEER_SCHEDULED_EVENT_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case LOGOUT:
      return defaultDataState;
    default:
      return state;
  }
};

export default checkIntoVolunteerEventReducer;
