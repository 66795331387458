import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowLeftIcon } from "@primer/octicons-react";
import PrimaryButton from "components/buttons/primary";
import ExternalLink from "components/external-link";
import Input from "components/input";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { signInCredentials } from "store/auth/auth.action";
import CharityAuthFormTemplate from "templates/charity-auth-form";
import { EMAIL_REG } from "utils/constants";
import routes from "utils/routes";
import * as yup from "yup";

import * as S from "./charity-login.styled";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REG, "E-mail is not valid.")
    .required("E-mail is required."),
  password: yup
    .string()
    .trim()
    .required("Please fill in all data.")
    .min(8, "Password must have at least 8 characters."),
});

const CharityLoginPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const { companyName } = params;

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const elementSX: React.CSSProperties = {
    width: !isTablet ? 391 : 280,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const elementSXDesktop: React.CSSProperties = {
    width: 391,
    maxWidth: "calc(100% - 2 * 22px)",
    boxSizing: "border-box",
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${S.MOBILE_BREAKPOINT}px)`,
  });

  const gapBig = isTablet ? 40 : 60;

  const onSubmit = ({ email, password }) => {
    dispatch(signInCredentials.request({ email, password }));
  };

  return (
    <CharityAuthFormTemplate>
      <>
        <S.Separator height={gapBig} />
        <S.ClickableWrapper onClick={() => navigate(routes.CHARITY.LANDING)}>
          <S.RowDisplay gridGap={16}>
            <S.LabelGray>
              <ArrowLeftIcon size={24} />
            </S.LabelGray>
            <S.LabelGray>Back</S.LabelGray>
          </S.RowDisplay>
        </S.ClickableWrapper>

        <S.Separator height={gapBig} />
        <S.ColumnDisplay
          alignItems="flex-start"
          justifyContent="flex-start"
          gridGap={20}
          style={{ width: "100%" }}
        >
          <Input
            topLabel="E-mail"
            placeholder="admin@company.com"
            isRequired
            sx={isMobile ? { ...elementSX, minWidth: "100%" } : elementSXDesktop}
            control={control}
            ref={emailRef}
            errorMessage={errors?.email?.message}
            onEnter={() => passwordRef?.current?.focus()}
            name="email"
          />
          <Input
            topLabel="Password"
            placeholder="Enter password"
            isRequired
            sx={isMobile ? { ...elementSX, minWidth: "100%" } : elementSXDesktop}
            type="password"
            control={control}
            ref={passwordRef}
            errorMessage={errors?.password?.message}
            name="password"
            onEnter={handleSubmit(onSubmit)}
          />
          <S.ColumnDisplay
            style={isMobile ? { ...elementSX, minWidth: "100%" } : elementSXDesktop}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <ExternalLink
              href={routes.COMPANY.RECOVER_PASSWORD}
              text="Forgot password?"
              fontSize={12}
              isDisabled={false}
              isRoute
            />
          </S.ColumnDisplay>
        </S.ColumnDisplay>
        <S.Separator height={30} />
        <PrimaryButton
          label="Login"
          onClick={handleSubmit(onSubmit)}
          sx={
            isMobile ? { ...elementSX, minWidth: "100%", marginBottom: "20px" } : elementSXDesktop
          }
        />
      </>
    </CharityAuthFormTemplate>
  );
};

export default CharityLoginPage;
