import { LOGOUT } from "store/auth/auth.constants";
import { ILogout } from "store/auth/auth.type";
import { GRANT_STATUS_OPTION } from "types/volunteer";

import * as C from "./company-past-volunteer-events.constants";
import * as T from "./company-past-volunteer-events.type";

export const companyPastVolunteerEventsInitial: T.ICompanyPastVolunteerEventsState = {
  data: [],
  inProgress: false,
  isFetched: false,
  isCached: false,
  isEndReached: false,
  page: 0,
  results: 0,
  error: null,
  dto: null,
  filters: {
    grant: GRANT_STATUS_OPTION.NO,
    city: "",
    state: null,
    groupSize: [],
    location: [],
  },
};

const companyPastVolunteerEventsReducer = (
  state: T.ICompanyPastVolunteerEventsState,
  action: T.CompanyPastVolunteerEventsAction | T.RefreshCompanyPastVolunteerEventsAction | ILogout,
): T.ICompanyPastVolunteerEventsState => {
  if (!state) {
    return companyPastVolunteerEventsInitial;
  }

  switch (action.type) {
    case C.FETCH_PAST_COMPANY_VOLUNTEER_EVENTS:
      return {
        ...state,
        inProgress: true,
      };
    case C.FETCH_PAST_COMPANY_VOLUNTEER_EVENTS_SUCCESS: {
      const newData = action.payload.data;
      const existingData = state.data || [];
      const combinedData = existingData.concat(newData);
      const combinedResults = combinedData.length;

      return {
        ...state,
        data: combinedData,
        page: action.payload.page,
        results: combinedResults,
        inProgress: false,
        isFetched: true,
        isEndReached: newData.length < 10 || newData.length === 0,
      };
    }
    case C.FETCH_PAST_COMPANY_VOLUNTEER_EVENTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case C.REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS:
      return {
        ...state,
        inProgress: true,
      };
    case C.REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case C.REFRESH_PAST_COMPANY_VOLUNTEER_EVENTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        isFetched: true,
      };
    case LOGOUT:
      return companyPastVolunteerEventsInitial;
    default:
      return state;
  }
};

export default companyPastVolunteerEventsReducer;
