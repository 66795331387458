import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  ColumnDisplay,
  GradientHeading,
  LinkText,
  RowDisplay,
  Separator,
  Table,
} from "theme/global-style";

export const CalendarPageWrapper = styled(ColumnDisplay)`
  height: 100vh;
  max-height: 100vh;
  flex: 1;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0px 150px 85px 150px;
  box-sizing: border-box;
  padding-bottom: 85px;
  overflow-y: scroll;
  background: ${({ theme }) => theme.aliceBlue()};

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    padding: 0px 20px 85px 20px;
  }
`;

export const CalendarInnerContainer = styled(ColumnDisplay)`
  max-width: 1431px;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
`;

export const CalendarHeading = styled(GradientHeading)`
  margin: 0px;

  @media all and (max-width: ${breakpoints.iPadPro.x}px) {
    font-size: 24px;
    word-break: break-word;
    text-align: start;
  }
`;
