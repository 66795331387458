import { IPaginationProps, ServerResponse } from "types/common";
import { AuthRole } from "types/user";
import * as T from "types/volunteer";
import {
  GetScheduledVolunteerEventsResponse,
  GetVolunteerAtendanceResponse,
  GetVolunteerEventsResponse,
  ScheduledVolunteerEventStatus,
  VolunteerEvent,
  VolunteerEventsFiltersDto,
} from "types/volunteer";
import { genUrlParams } from "utils/helper";
import { StatusCode } from "utils/status-code";

import client from "./client";

// general
export const getVolunteerEvents = async (
  params: VolunteerEventsFiltersDto,
): Promise<ServerResponse<GetVolunteerEventsResponse>> =>
  (await client.get(genUrlParams("volunteer/events", params))).data;

// scheduled (company + employee)
export const getScheduledVolunteerEvents = async (
  params: IPaginationProps,
  status: ScheduledVolunteerEventStatus,
): Promise<ServerResponse<GetScheduledVolunteerEventsResponse>> =>
  (await client.get(genUrlParams(`volunteer/events/scheduled/${status}`, params))).data;

export const getVolunteerEvent = async (
  id: string,
  role: AuthRole,
  isGlobal?: boolean,
): Promise<ServerResponse<VolunteerEvent>> =>
  (
    await client.get(
      `volunteer/event/${role === "charity" ? "" : isGlobal ? "" : "scheduled/"}${id}`,
    )
  ).data;

export const getScheduledVolunteerEventAttending = async (
  id: string,
): Promise<ServerResponse<boolean>> =>
  (await client.get(`volunteer/event/scheduled/${id}/attending`)).data;

export const attendScheduledVolunteerEvent = async (
  id: string,
  captain: boolean,
): Promise<number> =>
  (await client.post(`volunteer/event/scheduled/${id}/attend`, { captain })).status;

export const unattendScheduledVolunteerEvent = async (id: string): Promise<number> =>
  (await client.delete(`volunteer/event/scheduled/${id}/attend`)).status;

export const reguestVolunteerEventIntro = async (id: string): Promise<StatusCode> =>
  (await client.post(`volunteer/event/${id}/intro`)).status as StatusCode;

export const sendEmailMessageToAttendees = async (id: string, text: string): Promise<StatusCode> =>
  (await client.post(`volunteer/event/scheduled/${id}/messages`, { text })).status as StatusCode;

// attendees
export const getScheduledVolunteerAttendance = async (
  id: string,
  page: number,
): Promise<ServerResponse<GetVolunteerAtendanceResponse>> =>
  (await client.get(`volunteer/event/scheduled/${id}/attendance?page=${page}`)).data;

// charity
export const postVolunteerEvent = async (body: FormData): Promise<ServerResponse<VolunteerEvent>> =>
  (await client.post("volunteer/event", body)).data;

// admin
export const postCustomVolunteerEvent = async (
  body: T.CustomVolunteerEventDto,
): Promise<ServerResponse<VolunteerEvent>> => (await client.post("company/event", body)).data;

export const editVolunteerEvent = async (
  id: string,
  body: FormData,
): Promise<ServerResponse<VolunteerEvent>> =>
  (await client.put(`volunteer/event/${id}`, body)).data;

export const getCharityVolunteerEvents = async (): Promise<
  ServerResponse<GetVolunteerEventsResponse>
> => (await client.get("volunteer/charity/events")).data;

export const removeVolunteerEvent = async (eventId: string): Promise<number> =>
  (await client.delete(`/volunteer/event/scheduled/${eventId}`)).status;

export const activateVolunteerEvent = async (
  eventId: string,
  body: T.ActivateVolunteerEventParams,
): Promise<number> =>
  (await client.post(`/volunteer/event/scheduled/${eventId}/activate`, body)).status;

export const checkIntoVolunteerEvent = async (eventId: string, email: string): Promise<number> =>
  (await client.post(`/volunteer/event/scheduled/${eventId}/checkIn`, { email })).status;
