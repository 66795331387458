import { IVolunteerScheduledSelectors } from "./volunteer-scheduled.type";

const volunteerScheduledSelectors: IVolunteerScheduledSelectors = {
  selectData: (state, status) => state.volunteerScheduled.data[status].data,
  selectInProgress: (state, status) => state.volunteerScheduled.data[status].inProgress,
  selectEndReached: (state, status) => state.volunteerScheduled.data[status].isEndReached,
  selectIsFetched: (state, status) => state.volunteerScheduled.data[status].isFetched,
  selectPage: (state, status) => state.volunteerScheduled.data[status].page,
  selectResults: (state, status) => state.volunteerScheduled.data[status].results ?? 0,
  selectError: (state, status) => state.volunteerScheduled.data[status].error,
};

export default volunteerScheduledSelectors;
