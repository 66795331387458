import React from "react";
import CharityGridView from "views/charity-grid";

interface IFindCharitiesPage {
  isAdminView: boolean;
}

const FindCharitiesPage: React.FC<IFindCharitiesPage> = ({ isAdminView }): JSX.Element => (
  <CharityGridView isAdminView={isAdminView} />
);

export default FindCharitiesPage;
